import { useTranslation } from "react-i18next";
import { IUserProps } from "../components/common/interfaces/IUserProps";
import { TranslationKeyEnum } from "../features/translations/TranslationKeyEnum";
import { useWarningNotification, useTryCatchJsonByAction, BaseResponseModel } from "../utils/fetchUtils";
import { BasicUserDto, BirthdayEventDto, FileResponse, ResponseMessage, UpdateUserRequest, UserClient, UserResponse } from "./user-management-service";

const userClient = new UserClient();

export function useGetUsers(): () => Promise<UserResponse[]> {
	const tryCatchAction = useTryCatchJsonByAction();
	async function getUsers(): Promise<UserResponse[]> {
		const bindedAction = getUsersAction.bind(null);
		const getUsersResponse = await tryCatchAction(bindedAction);
		return getUsersResponse ? getUsersResponse.response : [];
	}
	return getUsers;
}

const getUsersAction = (): Promise<UserResponse[]> => {
	return userClient.getAll();
}

export function useGetDeactivatedUsers(): () => Promise<UserResponse[]> {
	const tryCatchAction = useTryCatchJsonByAction();
	async function getUsers(): Promise<UserResponse[]> {
		const bindedAction = getDeactivatedUsersAction.bind(null);
		const getUsersResponse = await tryCatchAction(bindedAction);
		return getUsersResponse ? getUsersResponse.response : [];
	}
	return getUsers;
}

const getDeactivatedUsersAction = (): Promise<UserResponse[]> => {
	return userClient.getDeactivated();
}

const getAllUsersAction = (): Promise<UserResponse[]> => {
	return userClient.getAllUsers();
}

export function useGetApprover(): (userId: string) => Promise<IUserProps>{
	const showWarningNotification = useWarningNotification();
	const tryCatchAction = useTryCatchJsonByAction(false);
	const { t } = useTranslation();
	async function getApprover(userId: string): Promise<IUserProps> {
		const bindedAction = getApproverAction.bind(null, userId);
		const getApproverResponse = await tryCatchAction(bindedAction);
		if(!getApproverResponse.success)
			showWarningNotification(t(TranslationKeyEnum.employeeDoesNotHaveApproverAssigned))
			
		return getApproverResponse ? getApproverResponse.response : {};
	}
	return getApprover;
}

const getApproverAction = (userId: string): Promise<UserResponse> => {
	return userClient.getById(userId);
}

export function useGetUserForActivation(): (userId: string) => Promise<IUserProps> {
	const tryCatchAction = useTryCatchJsonByAction();
	async function getUserForActivation(userId: string): Promise<IUserProps> {
		const bindedAction = getUserForActivationAction.bind(null, userId);
		const getUserResponse = await tryCatchAction(bindedAction);
		return getUserResponse ? getUserResponse.response : {};
	}
	return getUserForActivation;
}

const getUserForActivationAction = (userId: string): Promise<UserResponse> => userClient.getUserForActivation(userId);

export function useGetUser(): (userId: string) => Promise<IUserProps> {
	const tryCatchAction = useTryCatchJsonByAction();
	async function getUser(userId: string): Promise<IUserProps> {
		const bindedAction = getUserAction.bind(null, userId);
		const getUserResponse = await tryCatchAction(bindedAction);
		return getUserResponse ? getUserResponse.response : {};
	}
	return getUser;
}

const getUserAction = (userId: string): Promise<UserResponse> => {
	return userClient.getById(userId);
}

export function useUpdateMgmtUser(showMessage: boolean = true):(user: UpdateUserRequest) => Promise<BaseResponseModel>{
	const tryCatchAction = useTryCatchJsonByAction(showMessage);
    async function handleUpdateMgmtUser(user: UpdateUserRequest): Promise<BaseResponseModel>{
        const bindedAction = updateEmployeeAction.bind(null, user);
        const updateResponse = await tryCatchAction(bindedAction); 
        return {success: updateResponse.success, errors: updateResponse.errors}
    }
    return handleUpdateMgmtUser;
}

const updateEmployeeAction = (user: UpdateUserRequest): Promise<ResponseMessage> => {
    return userClient.update(user);
}

export function useGetAllUsers(): () => Promise<BasicUserDto[]> {
	const tryCatchAction = useTryCatchJsonByAction();
	async function getAllUsers(): Promise<BasicUserDto[]> {
		const bindedAction = getAllUsersAction.bind(null);
		const getUsers = await tryCatchAction(bindedAction);
		return getUsers ? getUsers.response : [];
	}
	return getAllUsers;
}

export function useGetNextBirthdays(): (numberOfUsers: number) => Promise<BirthdayEventDto[]> {
	const tryCatchAction = useTryCatchJsonByAction();
	async function getNextBirthdays(numberOfUsers: number): Promise<BirthdayEventDto[]> {
		const bindedAction = getNextBirthdaysAction.bind(null, numberOfUsers);
		const getNextBirthdaysResponse = await tryCatchAction(bindedAction);
		return getNextBirthdaysResponse ? getNextBirthdaysResponse.response : [];
	}
	return getNextBirthdays;
}

const getNextBirthdaysAction = (numberOfUsers: number): Promise<BirthdayEventDto[]> => userClient.getNextBirthdays(numberOfUsers);

export function useGetDocument(): (id: string, name: string) => Promise<FileResponse>{
	const tryCatchAction = useTryCatchJsonByAction();
	async function getDocument(id: string, name: string): Promise<FileResponse>{
		const bindedAction = getDocumentAction.bind(null, id, name);
		const getDocumentResponse = await tryCatchAction(bindedAction);
		return getDocumentResponse ? getDocumentResponse.response : undefined;
	}
	return getDocument;
}

const getDocumentAction = (id: string, name: string): Promise<FileResponse> => userClient.getDocument(id, name);