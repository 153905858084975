import * as React from 'react';
import { Routes, Route } from "react-router-dom";
import { routeEnum } from "../../routes/routeEnum";
import { getTokenFromStorage } from '../../utils/storageUtils/storageUtils';
import { useNavigate } from 'react-router-dom';
import { PermissionsEnum } from '../Permission/PermisionEnum';
import { atom, createStore, Provider, useAtom } from 'jotai';
import { ThemeProvider } from '@emotion/react';
import { defaultTheme } from '../../styles/themes';
import Home from "../Home/Home";
import AdminConsole from "../AdminConsole/AdminConsole";
import Restricted from '../Permission/Restricted';
import EmployeeProfile from '../EmployeeProfile/EmployeeProfile';
import TimeOff from '../TimeOff/TimeOff';
import Employees from '../Employees/Employees';
import PendingRequests from '../PendingRequests/PendingRequests';
import CalendarContainer from '../Calendar/CalendarContainer';
import WorkOrders from '../WorkOrders/WorkOrders';
import ManageWorkOrders from '../WorkOrders/ManageWorkOrders/ManageWorkOrders';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import MeetingCalendar from '../../components/MeetingCalendar/MeetingCalendar';
import dayjs from 'dayjs';
import 'dayjs/locale/sr';
import { LanguageEnum } from '../../features/translations/LanguageEnum';
import { useTranslation } from 'react-i18next';
import { userAtom } from '../AppContainer/AppContainer';
import { ISummaryFormStoreProps } from '../TimeOff/PolicyEventsSummary/ISummaryFormStoreProps';

export const summaryData = atom({ policy: {}, userId: "" } as ISummaryFormStoreProps)
const AppContent = () => {
    const [user] = useAtom(userAtom);
    const navigate = useNavigate();
    const myStore = createStore();
    const { i18n } = useTranslation();
    dayjs.Ls.en.weekStart = 1;

    React.useEffect(() => {
        !getTokenFromStorage() && navigate("/");
    }, [])

    React.useEffect((): void => {
        dayjs.locale(i18n.language === LanguageEnum.Serbian ? 'sr' : 'en')
    }, [i18n.language])

    return <Provider store={myStore}>
        <ThemeProvider theme={defaultTheme()}>
            <ScrollToTop />
            <Routes>
                <Route path={`/${[routeEnum.HOME]}`} element={<Home />} />
                <Route path={`/${[routeEnum.PROFILE]}`} element={<EmployeeProfile isLoggedUserProfile={true} />} />
                <Route path={`/${[routeEnum.TIMEOFF]}`} element={<TimeOff {...user} />} />
                <Route path={`/${[routeEnum.WORK_ORDERS]}`} element={<WorkOrders {...user} />} />
                <Route path={`/${[routeEnum.MANAGE_WORK_ORDERS]}`} element={<ManageWorkOrders />} />
                <Route path={`/${[routeEnum.EMPLOYEES]}`} element={<Employees />} />
                <Route path={`/${[routeEnum.EMPLOYEES]}/:id`} element={<EmployeeProfile />} />
                <Route path={`/${[routeEnum.CALENDAR]}`} element={<CalendarContainer />} />
                <Route path={`/${[routeEnum.CALENDAR]}/:tab`} element={<CalendarContainer />} />
                <Route path={`/${[routeEnum.CALENDAR]}/:tab/:meetingId/:roomId`} element={<CalendarContainer />} />
                <Route path={`/${[routeEnum.PENDINGREQUESTS]}`} element={<Restricted permission={PermissionsEnum.AllowTimeOffApproverPermission}><PendingRequests /></Restricted>} />
                <Route path={`/${[routeEnum.PENDINGREQUESTS]}/:id`} element={<Restricted permission={PermissionsEnum.AllowTimeOffApproverPermission}><PendingRequests /></Restricted>} />
                <Route path={`/${[routeEnum.ADMIN]}`} element={<Restricted permission={PermissionsEnum.AdminConsolePermission}><AdminConsole /></Restricted>} />
                <Route path={`/${[routeEnum.MEETING_ROOMS_MOBILE]}`} element={<MeetingCalendar />} />
                <Route path={`/${[routeEnum.MEETING_ROOMS]}`} element={<MeetingCalendar />} />
            </Routes>
        </ThemeProvider>
    </Provider>
}

export default AppContent;