import React from 'react';
import NavbarHeader from './NavbarHeader/NavbarHeader';
import NavbarItems from './NavbarItems/NavbarItems';
import UserMenu from './UserMenu/UserMenu';
import PermissionContext from '../Permission/PermissionContext';
import HamburgerMenu from './HamburgerMenu/HamburgerMenu';
import { Box } from '@mui/material';
import { SPACING_SMALL } from '../../utils/cssUtils';
import { PermissionsEnum } from '../Permission/PermisionEnum';

export default function Navbar() {
    const urlString = window.location.pathname.split('/');
    const PAGE_NAME_INDEX = 1;

    const { isAllowed } = React.useContext(PermissionContext);

    const [currentTab, setCurrentTab] = React.useState<string>(urlString[PAGE_NAME_INDEX]);
    const [isUserMenuOpen, setIsUserMenuOpen] = React.useState<boolean>(false);
    const [isLanguageMenuOpen, setIsLanguageMenuOpen] = React.useState<boolean>(false);
    const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = React.useState<boolean>(false);
    const [hasAdminPermission, setHasAdminPermission] = React.useState<boolean>(false);
    const [hasApproverPermission, setHasApproverPermission] = React.useState<boolean>(false);
    const [hasWorkOrdersPermission, setHasWorkOrderPermission] = React.useState<boolean>(false);

    React.useEffect(() => {
        Promise.all([isAllowed(PermissionsEnum.AdminConsolePermission), isAllowed(PermissionsEnum.AllowTimeOffApproverPermission), isAllowed(PermissionsEnum.ManageWorkOrderPermission)])
            .then(values => {
                setHasAdminPermission(values[0]);
                setHasApproverPermission(values[1]);
                setHasWorkOrderPermission(values[2]);
            })
    }, []);

    return (
        <Box
            position={{ xl: "absolute", lg: "absolute", md: "absolute" }}
            width={{ xl: "20%", lg: "20%", md: "20%" }}
            marginTop={SPACING_SMALL}
            sx={{ cursor: "pointer", zIndex: 1 }}
        >
            <NavbarHeader isHamburgerMenuOpen={isHamburgerMenuOpen} setIsHamburgerMenuOpen={setIsHamburgerMenuOpen} setCurrentTab={setCurrentTab} isMobile={false} />
            {
                isHamburgerMenuOpen ?
                    <HamburgerMenu
                        hasAdminPermission={hasAdminPermission}
                        hasApproverPermission={hasApproverPermission}
                        hasWorkOrdersPermission={hasWorkOrdersPermission}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        isHamburgerMenuOpen={isHamburgerMenuOpen}
                        setIsHamburgerMenuOpen={setIsHamburgerMenuOpen}
                    />
                    :
                    <Box display={{ xl: "inherit", lg: "inherit", md: "inherit", sm: "none", xs: "none" }}>
                        <NavbarItems
                            currentTab={currentTab}
                            setCurrentTab={setCurrentTab}
                            hasApproverPermission={hasApproverPermission}
                            hasWorkOrdersPermission={hasWorkOrdersPermission}
                            isUserMenuOpen={isUserMenuOpen}
                            setIsUserMenuOpen={setIsUserMenuOpen}
                            setIsLanguageMenuOpen={setIsLanguageMenuOpen}
                            isMobile={false}
                        />
                        <UserMenu
                            isUserMenuOpen={isUserMenuOpen}
                            setIsUserMenuOpen={setIsUserMenuOpen}
                            isLanguageMenuOpen={isLanguageMenuOpen}
                            setIsLanguageMenuOpen={setIsLanguageMenuOpen}
                            hasAdminPermission={hasAdminPermission}
                            setCurrentTab={setCurrentTab}
                            isMobile={false}
                        />
                    </Box>
            }
        </Box>
    )
}