import React from "react";
import EditableDataGrid from "../EditableDataGrid/EditableDataGrid";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import ConfirmDialog from "../Dialogs/ConfirmDialog/ConfirmDialog";
import DeactivatedWorkOrderTypes from "./DeactivatedWorkOrderTypes";
import { useTranslation } from "react-i18next";
import { TranslationKeyEnum } from "../../features/translations/TranslationKeyEnum";
import { Typography, Box, Tooltip, Button } from "@mui/material";
import { GridActionsCellItem, GridColDef, GridRowId, GridRowModes, GridRowModesModel, GridToolbarContainer } from '@mui/x-data-grid';
import { useErrorNotification } from "../../utils/fetchUtils";
import { CARD_BOX_SHADOW, SPACING_EXTRA_SMALL } from "../../utils/cssUtils";
import { getTextTransformationAccordingToLanguage } from '../../features/translations/helpers';
import { CreateTypeOfWorkRequest, StatusEnum, TypeOfWorkDto, UpdateStatusTypeOfWorkRequest } from "../../API/work-order-service";
import { useCreateTypeOfWork, useDeactivateTypeOfWork, useGetTypesOfWorkByStatus, useUpdateTypeOfWork } from "../../API/work-order-actions";

const FIRST_PAGE = 0;
const EMPTY_TYPE_ID = '';
const EMPTY_TYPE_NAME = '';

function AdminWorkOrderTypes() {
    const { t } = useTranslation();
    const getWorkOrderTypesByStatus = useGetTypesOfWorkByStatus();
    const createTypeOfWork = useCreateTypeOfWork();
    const updateTypeOfWork = useUpdateTypeOfWork();
    const deactivatedTypeOfWork = useDeactivateTypeOfWork();
    const showErrorNotification = useErrorNotification();

    const [id, setId] = React.useState(EMPTY_TYPE_ID);
    const [page, setPage] = React.useState(FIRST_PAGE);
    const [rows, setRows] = React.useState<TypeOfWorkDto[]>([]);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [editedRow, setEditedRow] = React.useState<TypeOfWorkDto>();
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
    const [isDeleteApproved, setIsDeleteApproved] = React.useState(false);
    const [isWorkOrderTypesDataLoading, setIsWorkOrderTypesDataLoading] = React.useState(true);
    const [rerender, setRerender] = React.useState(false);

    React.useEffect(() => {
        const fetchData = async () => {
            let allWorkOrderTypes = await getWorkOrderTypesByStatus(StatusEnum.Active);
            if (!allWorkOrderTypes) return;
            setRows(allWorkOrderTypes);
            setIsWorkOrderTypesDataLoading(false);
        }
        fetchData();
    }, [rerender])

    React.useEffect(() => {
        const deleteData = async () => {
            await deactivatedTypeOfWork(new UpdateStatusTypeOfWorkRequest({ id: id }));
            setRerender(!rerender);
            // set id to empty string so we are not accessing id of the deleted type which will cause error
            setId(EMPTY_TYPE_ID);
            // set value to false so user can try to delete another type, otherwise nothing will happen because value of isDeleteApproved is true
            setIsDeleteApproved(false);
        }
        isDeleteApproved && deleteData();
    }, [isDeleteApproved])

    const AddToolbar = () => {
        const handleClick = () => {
            // returning user to first page because we are adding new created type to start of the array
            page > FIRST_PAGE && setPage(FIRST_PAGE);
            // checking if there is some type being created because only one type can be created at a time
            // if there is no type being created we are adding one empty type and set its mode to edit
            if (rows.every((workOrderType: TypeOfWorkDto) => workOrderType.name !== EMPTY_TYPE_NAME)) {
                setRows((oldRows) => [new TypeOfWorkDto({ id: EMPTY_TYPE_ID, name: EMPTY_TYPE_NAME }), ...oldRows]);
                setRowModesModel((oldModel) => ({ ...oldModel, [EMPTY_TYPE_ID]: { mode: GridRowModes.Edit, fieldToFocus: 'name' } }));
            } else showErrorNotification(t(TranslationKeyEnum.finishWorkOrderTypeCreation));
        }

        return (
            <GridToolbarContainer sx={{ padding: SPACING_EXTRA_SMALL }}>
                <Tooltip title={t(TranslationKeyEnum.add)}>
                    <Button variant="contained" size="medium" startIcon={<AddIcon />} sx={{ marginLeft: "auto", textTransform: getTextTransformationAccordingToLanguage() }} onClick={handleClick} disabled={isWorkOrderTypesDataLoading}>
                        {t(TranslationKeyEnum.addworkOrderType)}
                    </Button>
                </Tooltip>
            </GridToolbarContainer>
        );
    }

    const handleEditClick = (id: GridRowId) => () => {
        let editedType = rows.find((type: TypeOfWorkDto) => type.id === id);
        setEditedRow(editedType);
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    }

    const handleSaveClick = (id: GridRowId) => () => setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });

    const handleCancelClick = (id: GridRowId) => () => {
        // checking if focused type id is empty if it is we are removing that item from data grid
        if (id === EMPTY_TYPE_ID) {
            let removeEmptyIdType = rows.filter((row: TypeOfWorkDto) => row.id !== EMPTY_TYPE_NAME);
            setRows(removeEmptyIdType);
        }
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });
    };

    const processRowCreateOrUpdate = async (workOrderType: TypeOfWorkDto) => {
        if (workOrderType.name === editedRow?.name) return showErrorNotification(t(TranslationKeyEnum.sameWorkOrderTypeName));
        if (workOrderType.name === EMPTY_TYPE_NAME) {
            // removing added type if validation is not satisfied
            // if we did not remove it user can edit or delete that type and error will occure because that type have empty id
            let removeEmptyIdType = rows.filter((row: TypeOfWorkDto) => row.id !== EMPTY_TYPE_ID);
            setRows(removeEmptyIdType);
            return showErrorNotification(t(TranslationKeyEnum.typeOfWorkNameEmpty))
        }

        let createOrUpdate = workOrderType.id === EMPTY_TYPE_ID ? await createTypeOfWork(new CreateTypeOfWorkRequest({ name: workOrderType.name })) : await updateTypeOfWork(workOrderType);
        if (!createOrUpdate) {
            // if we got error message from backend we are removing edited row from data grid
            // otherwise field will be shown in data grid with empty name values and mode setted to view
            let removeEmptyIdType = rows.filter((row: TypeOfWorkDto) => row.id !== EMPTY_TYPE_ID);
            return setRows(removeEmptyIdType);
        };
        setRerender(!rerender);
        // adding added or updated row to data grid
        const updatedRow = new TypeOfWorkDto({ ...workOrderType });
        setRows(rows.map((row) => (row.id === workOrderType.id ? updatedRow : row)));
        return updatedRow;
    };

    // removing edited element from data grid if error occurs
    const onProcessRowUpdateError = () => setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Id', width: 130 },
        { field: 'name', headerName: t(TranslationKeyEnum.name), width: 130, editable: true, flex: 1 },
        {
            field: 'actions', type: "actions", sortable: false, width: 80, minWidth: 80, flex: .3, align: "right", getActions: (params: any) => {
                const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
                return isInEditMode
                    ? [<Tooltip title={t(TranslationKeyEnum.save)}><GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(params.id)} /></Tooltip>,
                    <Tooltip title={t(TranslationKeyEnum.cancel)}><GridActionsCellItem icon={<CancelIcon />} label="Cancel" onClick={handleCancelClick(params.id)} /></Tooltip>]
                    : [<Tooltip title={t(TranslationKeyEnum.edit)}><GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={handleEditClick(params.id)} /></Tooltip>,
                    <Tooltip title={t(TranslationKeyEnum.delete)}><GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => setIsDeleteDialogOpen(true)} /></Tooltip>]
            }
        }
    ];

    return (
        <Box>
            <Box sx={{ boxShadow: CARD_BOX_SHADOW }}>
                {
                    isDeleteDialogOpen &&
                    <ConfirmDialog
                        dialogOpen={isDeleteDialogOpen}
                        setDialogOpen={setIsDeleteDialogOpen}
                        title={t(TranslationKeyEnum.confirmDelete)}
                        description={t(TranslationKeyEnum.deleteWorkOrderTypeDescription)}
                        isActionConfirmed={setIsDeleteApproved}
                    />
                }
                <Box>
                    <Typography variant="h5" sx={{ padding: SPACING_EXTRA_SMALL }}>{t(TranslationKeyEnum.workOrdersTypes)}</Typography>
                </Box>
                <EditableDataGrid
                    rows={rows}
                    columns={columns}
                    page={page}
                    setPage={setPage}
                    setRows={setRows}
                    setId={setId}
                    rowModesModel={rowModesModel}
                    setRowModesModel={setRowModesModel}
                    processRowUpdate={processRowCreateOrUpdate}
                    onProcessRowUpdateError={onProcessRowUpdateError}
                    toolbar={AddToolbar}
                    isLoading={isWorkOrderTypesDataLoading}
                />
            </Box>
            <DeactivatedWorkOrderTypes rerenderTypesOfWork={rerender} setRerenderTypesOfWork={setRerender} />
        </Box>
    )
}

export default AdminWorkOrderTypes