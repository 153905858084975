import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import WorkOffIcon from '@mui/icons-material/WorkOff';
import CreateEditWorkOrder from '../../WorkOrders/CreateEditWorkOrder/CreateEditWorkOrder';
import PermissionContext from '../../Permission/PermissionContext';
import { Typography, Box, Button } from '@mui/material';
import { TranslationKeyEnum } from "../../../features/translations/TranslationKeyEnum"
import { useTranslation } from "react-i18next";
import { BORDER_RADIUS_MEDIUM, CARD_BODY_HEIGHT, CARD_BOX_SHADOW, CARD_FOOTER_HEIGHT, CARD_HEADER_HEIGHT, CARD_HEIGHT, LIGHT_BG_COLOR, LIGHT_BORDER, MIN_CARD_BODY_HEIGHT, MIN_CARD_FOOTER_HEIGHT, MIN_CARD_HEADER_HEIGHT, MIN_CARD_HEIGHT, PRIMARY_BG_COLOR, SPACING_SMALL } from '../../../utils/cssUtils';
import { useGetNextWorkOrders } from '../../../API/work-order-actions';
import { BasicWorkOrderDto } from '../../../API/work-order-service';
import { getUserIdFromStorage } from '../../../utils/storageUtils/storageUtils';
import { getTextTransformationAccordingToLanguage } from '../../../features/translations/helpers';
import { Link } from 'react-router-dom';
import { SPACING_EXTRA_SMALL } from '../../../utils/cssUtils';
import { PermissionsEnum } from '../../Permission/PermisionEnum';
import { getShortDateAndDayOfTheWeek } from '../../../utils/dateUtils';

export const WorkOrdersCard = () => {
    const { t } = useTranslation();
    const { isAllowed } = React.useContext(PermissionContext);
    const getNextWorkOrders = useGetNextWorkOrders();

    const [nextWorkOrders, setNextWorkOrders] = React.useState<BasicWorkOrderDto[]>([]);
    const [selectedWorkOrderId, setSelectedWorkOrderId] = React.useState<string>("");
    const [isSupervisor, setIsSupervisor] = React.useState<boolean>(false);
    const [isWorkOrderFormOpen, setIsWorkOrderFormOpen] = React.useState<boolean>(false);
    const [rerender, setRerender] = React.useState<boolean>(false);

    React.useEffect(() => {
        const fetchWorkOrders = async () => {
            let userId = getUserIdFromStorage()!;
            if(!userId) return;
            let workOrdersResponse = await getNextWorkOrders(userId, 2);
            if (!workOrdersResponse) return;
            workOrdersResponse.length === 1 && workOrdersResponse.push(new BasicWorkOrderDto());
            setNextWorkOrders(workOrdersResponse);
        }

        fetchWorkOrders();
    }, [rerender]);

    React.useEffect(() => {
        const checkPermsission = async () => {
            setIsSupervisor(await isAllowed(PermissionsEnum.ManageWorkOrderPermission))
        }
        checkPermsission();
    }, [])

    const handleOpenWorkOrderForm = (id: string) => {
        setSelectedWorkOrderId(id);
        setIsWorkOrderFormOpen(true);
    }

    return (
        <Box sx={{ height: CARD_HEIGHT, minHeight: MIN_CARD_HEIGHT, borderRadius: BORDER_RADIUS_MEDIUM, boxShadow: CARD_BOX_SHADOW, backgroundColor: PRIMARY_BG_COLOR, marginBottom: SPACING_SMALL }}>
            {/* Card Header */}
            <Box height={CARD_HEADER_HEIGHT} minHeight={MIN_CARD_HEADER_HEIGHT} display="flex" alignItems="center" justifyContent="center" borderBottom={LIGHT_BORDER}>
                <Typography sx={{ textTransform: "uppercase" }} variant="subtitle2">{t(TranslationKeyEnum.workOrdersTab)}</Typography>
            </Box>
            {/* Card Body */}
            <Box height={CARD_BODY_HEIGHT} minHeight={MIN_CARD_BODY_HEIGHT} display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderBottom={LIGHT_BORDER}>
                {
                    nextWorkOrders?.length
                        ? nextWorkOrders.map((workOrder: BasicWorkOrderDto, index) =>
                            workOrder.id ? <Box height="50%" width="100%" display="flex" padding={SPACING_EXTRA_SMALL} alignItems="center" borderBottom={index === 0 ? LIGHT_BORDER : ""} color="grey" sx={{ cursor: "pointer" }} onClick={() => handleOpenWorkOrderForm(workOrder.id!)}>
                                <Typography width="86%">
                                    <Box display="inline" color={LIGHT_BG_COLOR}>{t(TranslationKeyEnum.workOrder)}&nbsp;</Box>
                                    ∙ {workOrder.startDate && getShortDateAndDayOfTheWeek(new Date(workOrder.startDate))}
                                    {workOrder.endDate?.toString() !== workOrder.startDate?.toString() && "- " + getShortDateAndDayOfTheWeek(new Date(workOrder.endDate!))}&nbsp;
                                    ∙&nbsp;{t(TranslationKeyEnum.at)} [{workOrder.locationCode}]
                                </Typography>
                                <ArrowForwardIcon sx={{ width: "14%" }} fontSize="medium" color="primary" />
                            </Box>
                                :
                                <Box height="50%" />
                        )
                        : <Box display="flex" flexDirection="column" alignItems="center" gap={SPACING_EXTRA_SMALL}>
                            <WorkOffIcon color="action" />
                            <Typography variant="body2" align="center" color="grey">{t(TranslationKeyEnum.noNextWorkOrders)}</Typography>
                        </Box>
                }
            </Box>
            {/* Card Footer */}
            <Box width="100%" height={CARD_FOOTER_HEIGHT} minHeight={MIN_CARD_FOOTER_HEIGHT} display="flex" alignItems="center" justifyContent="center">
                <Button variant="text" sx={{ textTransform: getTextTransformationAccordingToLanguage() }} size="small" component={Link} to="/calendar/1">
                    {t(TranslationKeyEnum.viewCalendar)}
                    <Link to="/calendar" style={{ display: "flex", marginLeft: "1vh" }}>
                        <ArrowForwardIcon fontSize="small" color="primary" />
                    </Link>
                </Button>
            </Box>
            {
                isWorkOrderFormOpen && <CreateEditWorkOrder isOpen={isWorkOrderFormOpen} setIsOpen={setIsWorkOrderFormOpen} isSupervisor={isSupervisor} workOrderId={selectedWorkOrderId} rerender={rerender} setRerender={setRerender} />
            }
        </Box>
    );
}