import CloseIcon from '@mui/icons-material/Close';
import companyLogo from "../../assets/images/ant.png"
import { ICON_COLOR, LIGHT_BG_COLOR, SPACING_LARGE, SPACING_MEDIUM, SPACING_SMALL } from "../../utils/cssUtils";
import { IPopupNotificationProps } from "./IPopupNotificationProps"
import { Box, Typography, Dialog, Avatar, Button } from '@mui/material';
import { useTranslation } from "react-i18next";
import { TranslationKeyEnum } from "../../features/translations/TranslationKeyEnum";
import { getTextTransformationAccordingToLanguage } from '../../features/translations/helpers';

export default function PopupNotification({ open, setIsOpen, title, description, content, handlePopupClose }: IPopupNotificationProps) {
  const { t } = useTranslation();

  const handleClose = () => {
    setIsOpen(false);
    handlePopupClose();
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: { xl: "30vw", lg: "40vw", md: "50vw", sm: "70vw", xs: "90vw" },
          }
        },
      }}>
      <Box position="absolute" right="0" padding={SPACING_SMALL} sx={{ cursor: "pointer" }} onClick={handleClose}>
        <CloseIcon sx={{ color: ICON_COLOR }} />
      </Box>
      <Box padding={`${SPACING_MEDIUM} ${SPACING_LARGE}`} display="flex" flexDirection="column" alignItems="center" gap={SPACING_SMALL}>
        <Box display="flex">
          <Avatar sx={{ marginRight: SPACING_SMALL, marginTop: "auto", marginBottom: "auto" }} src={companyLogo} />
          <Typography variant="h6" margin="auto 0">{t(TranslationKeyEnum.companyName)}</Typography>
        </Box>
        <Typography align="center" variant="h6">{title}</Typography>
        <Typography variant="body2" marginRight="auto">{description}</Typography>
        {
          content.length
          && <ul>
            {
              content.map((c: string) => <li><Typography variant="body2">{c}</Typography></li>)
            }
          </ul>
        }
        <Button variant="contained" sx={{ backgroundColor: LIGHT_BG_COLOR, textTransform: getTextTransformationAccordingToLanguage() }} onClick={handleClose}>
          {t(TranslationKeyEnum.newVersionPopup)}
        </Button>
      </Box>
    </Dialog>
  )
}