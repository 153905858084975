import styled from "@emotion/styled";
import { TextField } from "@mui/material";
import { PRIMARY_BG_COLOR } from "../../utils/cssUtils";

export const StyledReadonlyTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
        backgroundColor: PRIMARY_BG_COLOR,
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#c0c0c0 !important",
    },
});