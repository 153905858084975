import React from 'react';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PolicyEventsSummary from '../PolicyEventsSummary/PolicyEventsSummary';
import { Box, Button, Typography, Chip, Paper, ClickAwayListener, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BORDER_RADIUS_MEDIUM, LIGHT_BORDER, CARD_BOX_SHADOW, SPACING_MEDIUM, SPACING_SMALL, APP_BG_COLOR, INFO_CARD_HEIGTH, INFO_CARD_MIN_HEIGTH, INFO_CARD_MIN_WIDTH, INFO_CARD_WIDTH, INFO_CARD_WIDTH_SM, INFO_CARD_WIDTH_XS, SPACING_EXTRA_SMALL } from '../../../utils/cssUtils';
import { ITimeOffCard } from './ITimeOffCard';
import { TranslationKeyEnum } from '../../../features/translations/TranslationKeyEnum';
import { useStore } from 'jotai';
import { summaryData } from '../../AppContent/AppContent';
import { getTextTransformationAccordingToLanguage } from '../../../features/translations/helpers';

export default function TimeOffCard({ policy, policyName, totalDays, usedDays, reservedDays, endDate, isDaysInfinity, userId, isForUser }: ITimeOffCard) {
    const { t } = useTranslation();
    const [isAvailableDaysCardOpen, setIsAvailableDaysCardOpen] = React.useState(false);

    const policySummaryStore = useStore();
    const [isPolicySummaryOpen, setIsPolicySummaryOpen] = React.useState(false)

    const getAvailableNumberOfDays = (totalDays: number, usedDays: number, reservedDays: number) => {
        return totalDays - usedDays - reservedDays;
    }

    const getNumberOfTakenDays = (usedDays: number, reservedDays: number) => {
        return usedDays + reservedDays;
    }

    return (
        <Box
            height={INFO_CARD_HEIGTH}
            minHeight={INFO_CARD_MIN_HEIGTH}
            width={{ xl: INFO_CARD_WIDTH, lg: INFO_CARD_WIDTH, md: INFO_CARD_WIDTH, sm: INFO_CARD_WIDTH_SM, xs: INFO_CARD_WIDTH_XS }}
            minWidth={INFO_CARD_MIN_WIDTH}
            borderRadius={BORDER_RADIUS_MEDIUM}
            boxShadow={CARD_BOX_SHADOW}
            marginTop={{ xl: "0", lg: "0", md: "0", sm: SPACING_SMALL, xs: SPACING_SMALL }}
            sx={{ backgroundColor: APP_BG_COLOR }}
        >
            <Box height="50%" borderBottom={LIGHT_BORDER} display="flex" justifyContent="space-between" padding={SPACING_SMALL}>
                <Box display="flex" justifyContent="center" alignItems="center" width="20%">
                    <BeachAccessIcon color="action" fontSize="large" />
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="60%" sx={{ cursor: "pointer" }}>
                    <Tooltip title={policyName}>
                        <Typography variant="h6" maxWidth="90%" textAlign="center" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" >{policyName}</Typography>
                    </Tooltip>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center" width="20%">
                    <Chip label={t(TranslationKeyEnum.days)} sx={{ textTransform: "capitalize", minWidth: "55px" }} />
                </Box>
            </Box>
            <Box height="50%" display="flex" justifyContent="space-evenly" alignItems="center">
                {/* Available Days */}
                <Box>
                    <Box display="flex" justifyContent="center" sx={{ cursor: "pointer" }} onClick={() => setIsAvailableDaysCardOpen(!isAvailableDaysCardOpen)}>
                        <Typography variant="h6" textAlign="center">{isDaysInfinity ? "N/A" : getAvailableNumberOfDays(totalDays, usedDays, reservedDays)}</Typography>
                        <Box display="flex" alignItems="center"> <ExpandMoreIcon color="action" /></Box>
                        {/* Available days card */}
                        {
                            isAvailableDaysCardOpen &&
                            <ClickAwayListener onClickAway={() => setIsAvailableDaysCardOpen(false)}>
                                <Paper sx={{ width: "30vh", boxShadow: CARD_BOX_SHADOW, position: "absolute", marginTop: SPACING_MEDIUM, zIndex: "1" }}>
                                    <Box padding={SPACING_EXTRA_SMALL} display="flex" justifyContent="center" alignContent="center" sx={{ borderBottom: LIGHT_BORDER }} >
                                        <Typography variant="overline">{t(TranslationKeyEnum.availableDays)}</Typography>
                                    </Box>
                                    <Box padding={`${SPACING_EXTRA_SMALL} 0`} display="flex" justifyContent="space-between" sx={{ borderBottom: LIGHT_BORDER }} margin="0 auto" width="80%">
                                        <Box>
                                            <Typography variant="body2">{t(TranslationKeyEnum.daysPerPolicy)}</Typography>
                                            <Typography variant="caption" color="gray" textTransform="capitalize">{t(TranslationKeyEnum.workDays)}</Typography>
                                        </Box>
                                        <Typography variant="body2">{totalDays}</Typography>
                                    </Box>
                                    <Box padding={`${SPACING_EXTRA_SMALL} 0`} display="flex" justifyContent="space-between" sx={{ borderBottom: LIGHT_BORDER }} margin="0 auto" width="80%">
                                        <Box>
                                            <Typography variant="body2">{t(TranslationKeyEnum.expiresOn)}</Typography>
                                            <Typography variant="caption" color="gray">{t(TranslationKeyEnum.date)}</Typography>
                                        </Box>
                                        <Typography variant="body2">{new Date(endDate).toLocaleDateString("en-GB")}</Typography>
                                    </Box>
                                    <Box padding={`${SPACING_EXTRA_SMALL} 0`} display="flex" justifyContent="space-between" margin="0 auto" width="80%">
                                        <Box>
                                            <Typography variant="body2">{t(TranslationKeyEnum.available)}</Typography>
                                            <Typography variant="caption" color="gray">{t(TranslationKeyEnum.today)}</Typography>
                                        </Box>
                                        <Typography variant="body2">{isDaysInfinity ? "N/A" : getAvailableNumberOfDays(totalDays, usedDays, reservedDays)}</Typography>
                                    </Box>
                                    <Box padding={`0 0 ${SPACING_EXTRA_SMALL} 0`} width="80%" margin="0 auto">
                                        <Button variant="outlined" sx={{ textTransform: getTextTransformationAccordingToLanguage(), width: "100%" }}
                                            onClick={() => { setIsPolicySummaryOpen(true); policySummaryStore.set(summaryData, { policy: policy, userId: userId }); }}>
                                            {t(TranslationKeyEnum.viewPolicySummary)}
                                        </Button>
                                    </Box>
                                </Paper>
                            </ClickAwayListener>
                        }
                    </Box>
                    <Typography variant="subtitle1" color="gray" textTransform="uppercase" textAlign="center">{t(TranslationKeyEnum.available)}</Typography>
                </Box>
                {/* Taken Days */}
                <Box display="flex" flexDirection="column">
                    <Typography variant="h6" textAlign="center">{getNumberOfTakenDays(usedDays, reservedDays)}</Typography>
                    <Typography variant="subtitle1" color="gray" textTransform="uppercase" textAlign="center">{t(TranslationKeyEnum.taken)}</Typography>
                </Box>
            </Box>
            {isPolicySummaryOpen && <PolicyEventsSummary isOpen={isPolicySummaryOpen} setIsOpen={setIsPolicySummaryOpen} userId={userId} isForUser={isForUser} />}
        </Box>
    )
}