import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { TranslationKeyEnum } from "../../features/translations/TranslationKeyEnum";

export function useAddEditBankHolidayFormValidationSchema() {
    const { t } = useTranslation();

    return Yup.object({
        name: Yup.string().required(t(TranslationKeyEnum.required)),
        startDate: Yup.date().required(t(TranslationKeyEnum.required)),
        endDate: Yup.date().required(t(TranslationKeyEnum.required)),
    })
}