import i18n from "../../features/translations/i18n";
import { languages } from "../../features/translations/LanguageTypes";
import { LanguageType } from "../../features/translations/LanguageTypes";
import { Box, Typography, MenuList, MenuItem } from "@mui/material";

const LANGUAGE = "lang";

const LanguagePicker = () => {
	const handleLanguageChange = (language: LanguageType) => {
		i18n.changeLanguage(language.language);
		localStorage.setItem(LANGUAGE, JSON.stringify(language));
	}

	return (
		<MenuList>
			{
				languages.map((language) =>
					<MenuItem onClick={() => handleLanguageChange(language)} key={language.label}>
						<Box width="30px" minWidth="30px" marginRight="1vh">
							<img src={language.img} alt={language.alt} />
						</Box>
						<Typography variant="body2">{language.label}</Typography>
					</MenuItem>)
			}
		</MenuList>
	);
}

export default LanguagePicker;