import CloseIcon from '@mui/icons-material/Close';
import { Dialog, Box } from '@mui/material';
import { IMapDialogProps } from './IMapDialogProps';
import { ICON_COLOR, SPACING_SMALL } from '../../../../utils/cssUtils';

export default function MapDialog({ isOpen, setIsOpen, coordinates }: IMapDialogProps) {
    //removes every character except numbers, dots and commas
    const coordinatesRegex = /[^0-9.,]/g;
    const trimmedCoordinates = coordinates.replace(coordinatesRegex, "");
    return (
        <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
            <Box sx={{ cursor: "pointer", position: "absolute", right: "0", padding: SPACING_SMALL }} onClick={() => setIsOpen(false)}>
                <CloseIcon sx={{ color: ICON_COLOR }} />
            </Box>
            <Box>
                <iframe
                    width="800px"
                    height="500px"
                    src={`https://maps.google.com/maps?q=${trimmedCoordinates}&hl=en&z=14&output=embed`}
                >
                </iframe>
            </Box>
        </Dialog>
    )
}