import { Button, Box } from '@mui/material';
import { SPACING_EXTRA_SMALL } from '../../../utils/cssUtils';
import { IFormFooterProps } from './IFormFooterProps';

function StepperFormFooter({ isFormLoading, secondButtonLabel, secondButtonIcon }: IFormFooterProps) {
  return (
    <Box display="flex" justifyContent="flex-end" mt={SPACING_EXTRA_SMALL}>
      <Button data-test-id="submit" variant="contained" type="submit" disabled={isFormLoading}>
        {secondButtonLabel}{secondButtonIcon}
      </Button>
    </Box>
  )
}

export default StepperFormFooter