import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { TranslationKeyEnum } from "../../../features/translations/TranslationKeyEnum";

const MINIMUM_YEARS_OF_SERVICE_VALUE = 0;

export function useVacationInfoValidationSchema() {
    const { t } = useTranslation();

    return Yup.object({
        approver: Yup.string().required(t(TranslationKeyEnum.required)),
        yearsOfService: Yup.number()
            .positive()
            .min(MINIMUM_YEARS_OF_SERVICE_VALUE, t(TranslationKeyEnum.minimumInputValue, { fieldName: t(TranslationKeyEnum.yearsOfService), minimumLength: MINIMUM_YEARS_OF_SERVICE_VALUE }))
            .required(t(TranslationKeyEnum.required))
    })
}