import React from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio'
import MenuItem from '@mui/material/MenuItem';
import RadioGroup from '@mui/material/RadioGroup'
import PalletteButton from '../../Buttons/PalletteButton';
import CloseIcon from '@mui/icons-material/Close';
import DropdownErrorText from '../../DropdownErrorText/DropdownErrorText';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FormikValues, useFormik } from "formik";
import { BasicTimeOffPolicyDto, ColorEnum, CopyTimeOffPolicyRequest, CreateTimeOffPolicyRequest, UpdateTimeOffPolicyRequest } from '../../../API/time-off-service';
import { TranslationKeyEnum } from "../../../features/translations/TranslationKeyEnum";
import { Box, Stack, FormControl, InputLabel, Typography, ClickAwayListener, TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { colors, IAddEditPolicyFormProps, IPolicyModel } from "./IAddEditPolicyFormProps";
import { usePolicyFormikForm } from './policyFormikForm';
import { useGetPolicy, useGetPolicyForActivation } from '../../../API/time-off-actions';
import { ADMIN_CONSOLE_INPUT_LABEL_WIDTH, ICON_COLOR, SPACING_EXTRA_SMALL, SPACING_MEDIUM, SPACING_SMALL } from '../../../utils/cssUtils';
import { StyledSelectField } from '../../StyledSelect/StyledSelect';
import { StyledTextField } from '../../StyledTextfield/StyledTextField';
import { getDateOrNull } from '../../../containers/TimeOff/helpers';
import dayjs from 'dayjs';

const EMPTY_POLICY_ID = "00000000-0000-0000-0000-000000000000";

function AddEditPolicyFormDialog(props: IAddEditPolicyFormProps) {
    const { t } = useTranslation();
    const { open, onClose, onAddSubmit, onEditSubmit, policiesModel, policyId, isAdd, isActivation, isCopyPolicy, onCopySubmit, usersForCopyPolicy } = props;
    const formikPolicy = usePolicyFormikForm();
    const [policy, setPolicy] = React.useState<BasicTimeOffPolicyDto>(new BasicTimeOffPolicyDto({ dependsOnPolicy: "", color: ColorEnum.Undefined, name: "", id: "", startDate: new Date(), endDate: new Date(), isActive: true, allowNegativeBalance: false, requiresApproval: false, infiniteDays: false }));
    const policyForm: FormikValues = useFormik(formikPolicy(policy, handleSubmit));
    const getPolicy = useGetPolicy();
    const getPolicyForActivation = useGetPolicyForActivation();
    const [isStartDateOpen, setIsStartDateOpen] = React.useState<boolean>(false);
    const [isEndDateOpen, setIsEndDateOpen] = React.useState<boolean>(false);

    async function handleSubmit() {
        var model = isAdd ? policyForm.values as CreateTimeOffPolicyRequest : policyForm.values as UpdateTimeOffPolicyRequest;
        model.dependsOnPolicy = model.dependsOnPolicy === "" ? EMPTY_POLICY_ID : model.dependsOnPolicy;
        model.isActive = true;

        if (isCopyPolicy && onCopySubmit) {
            let copyPolicyModel = new CopyTimeOffPolicyRequest();
            copyPolicyModel.name = policyForm.values.name;
            copyPolicyModel.startDate = policyForm.values.startDate;
            copyPolicyModel.endDate = policyForm.values.endDate;
            copyPolicyModel.isActive = true;
            copyPolicyModel.dependsOnPolicy = policyForm.values.dependsOnPolicy ? policyForm.values.dependsOnPolicy : EMPTY_POLICY_ID;
            copyPolicyModel.allowNegativeBalance = policy.allowNegativeBalance;
            copyPolicyModel.requiresApproval = policy.requiresApproval;
            copyPolicyModel.infiniteDays = policy.infiniteDays;
            copyPolicyModel.color = policy.color;
            copyPolicyModel.usersWithTotalDays = usersForCopyPolicy && usersForCopyPolicy;
            onCopySubmit(copyPolicyModel);
        } else if (!isAdd) {
            (policyForm.values as UpdateTimeOffPolicyRequest).id = policyId;
            onEditSubmit(model);
        }
        else {
            onAddSubmit(model);
        }
        policyForm.resetForm();
    };

    function handleDatesChange(form: FormikValues, selectedDate: Date, property: string) {
        return property === "startDate" ? form.setFieldValue("startDate", selectedDate) : form.setFieldValue("endDate", selectedDate);
    }

    function handleClose() {
        policyForm.resetForm();
        setPolicy(new BasicTimeOffPolicyDto({ dependsOnPolicy: '', color: ColorEnum.Undefined, name: '', id: '', startDate: new Date(), endDate: new Date(), isActive: true, allowNegativeBalance: false, requiresApproval: false, infiniteDays: false }));
        onClose();
    };

    const resetValuesForPolicyType = () => {
        policyForm.setFieldValue("infiniteDays", false)
        policyForm.setFieldValue("allowNegativeBalance", false)
        policyForm.setFieldValue("dependsOnCheck", false)
        policyForm.setFieldValue("dependsOnPolicy", "")
    }

    const handleSwitchRadioButton = (currentButton: string, currentValue: boolean) => {
        resetValuesForPolicyType()
        policyForm.setFieldValue(currentButton, currentValue)
    }

    React.useEffect(() => {
        policyForm.resetForm();
        const fetchData = async () => {
            const policy = isActivation ? await getPolicyForActivation(policyId) : await getPolicy(policyId);
            setPolicy(policy);
        }

        if (props.policyId !== "")
            fetchData();

    }, [props.policyId])

    React.useEffect(() => {
        policyForm.resetForm();
        !isCopyPolicy && policyForm.setFieldValue("color", policy.color === "Undefined" ? "" : policy.color);
        !isCopyPolicy && policyForm.setFieldValue("name", policy.name);
        !isCopyPolicy && policyForm.setFieldValue("startDate", policy.startDate);
        !isCopyPolicy && policyForm.setFieldValue("endDate", policy.endDate);
        policyForm.setFieldValue("dependsOnPolicy", policy.dependsOnPolicy);
        policyForm.setFieldValue("infiniteDays", policy.infiniteDays);
        policyForm.setFieldValue("allowNegativeBalance", policy.allowNegativeBalance);
        policyForm.setFieldValue("dependsOnCheck", policy.dependsOnPolicy === undefined || policy.dependsOnPolicy === EMPTY_POLICY_ID || policy.dependsOnPolicy.length === 0 ? false : true);
    }, [policy])

    // Enabling depends on fields if we are adding new policy or copy policy that is depend on some other policy
    const checkIsDependsOnDisabled = (): boolean => !(isAdd || (isCopyPolicy && policy.dependsOnPolicy !== EMPTY_POLICY_ID));

    return (
        <Dialog open={open} onClose={handleClose}>
            <Box position="absolute" right="0" padding={SPACING_SMALL} sx={{ cursor: "pointer" }} onClick={handleClose}>
                <CloseIcon sx={{ color: ICON_COLOR }} />
            </Box>
            <Box width={{ xl: "50vw", lg: "50vw", md: "60vw", sm: "70vw", xs: "80vw" }} padding={SPACING_MEDIUM} display="flex" flexDirection="column">
                <Typography variant="h5">{isCopyPolicy ? t(TranslationKeyEnum.copyPolicy) : t(TranslationKeyEnum.addOrChangePolicy)}</Typography>
                <form onSubmit={policyForm.handleSubmit}>
                    <Stack>
                        <Box sx={{ padding: `${SPACING_MEDIUM} 0` }}>
                            <StyledTextField
                                label={t(TranslationKeyEnum.policyName)}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                id="name"
                                name="name"
                                type="text"
                                value={policyForm.values.name}
                                onChange={policyForm.handleChange}
                                error={policyForm.touched.name && Boolean(policyForm.errors.name)}
                                helperText={policyForm.touched.name && policyForm.errors.name}
                                sx={{ width: "100%" }}
                            />
                        </Box>
                        <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="" name="radio-buttons-group" >
                            <FormControlLabel
                                value="regularPolicy" checked={!policyForm.values.infiniteDays && !policyForm.values.allowNegativeBalance && !policyForm.values.dependsOnCheck} disabled={!isAdd} control={<Radio onChange={(e) => handleSwitchRadioButton("regularPolicy", e.target.checked)} />} label={t(TranslationKeyEnum.regularyPolicy)}
                            />
                            <FormControlLabel
                                value="infiniteDays" checked={policyForm.values.infiniteDays} disabled={!isAdd} control={<Radio onChange={(e) => handleSwitchRadioButton("infiniteDays", e.target.checked)} />} label={t(TranslationKeyEnum.policyInfiniteDays)}
                            />
                            <FormControlLabel
                                value="allowNegativeBalance" checked={policyForm.values.allowNegativeBalance} disabled={!isAdd} control={<Radio onChange={(e) => handleSwitchRadioButton("allowNegativeBalance", e.target.checked)} />} label={t(TranslationKeyEnum.policyAllowNegative)}
                            />
                            <Box display="flex" alignItems="center" padding={`${SPACING_EXTRA_SMALL} 0`}>
                                <Box>
                                    <FormControlLabel
                                        value="dependsOnCheck" checked={policyForm.values.dependsOnCheck} disabled={checkIsDependsOnDisabled()} control={<Radio onChange={(e) => handleSwitchRadioButton("dependsOnCheck", e.target.checked)} />} label={t(TranslationKeyEnum.policyDependsOn)}
                                    />
                                </Box>
                                <Box>
                                    <FormControl size="small">
                                        <InputLabel shrink>{t(TranslationKeyEnum.policy)}</InputLabel>
                                        <StyledSelectField
                                            value={policyForm.values.dependsOnPolicy}
                                            label={t(TranslationKeyEnum.policy)}
                                            onChange={(e: any) => {
                                                policyForm.setFieldValue("dependsOnPolicy", e.target.value);
                                                policyForm.setFieldValue("dependsOnCheck", true);
                                            }}
                                            error={policyForm.touched.dependsOnPolicy && Boolean(policyForm.errors.dependsOnPolicy)}
                                            name="dependsOnPolicy"
                                            disabled={policyForm.values.allowNegativeBalance || policyForm.values.infiniteDays || checkIsDependsOnDisabled()}
                                            notched={true}
                                            displayEmpty={true}
                                            sx={{ minWidth: "200px" }}
                                            renderValue={() =>
                                                policyForm.values.dependsOnPolicy !== "" ? policiesModel.find((policy: IPolicyModel) => policy.id === policyForm.values.dependsOnPolicy)?.name : t(TranslationKeyEnum.selectPolicy)
                                            }
                                        >
                                            {policiesModel.map(x =>
                                                <MenuItem key={x.id} value={x.id}>
                                                    {x.name}
                                                </MenuItem>
                                            )}
                                        </StyledSelectField>
                                    </FormControl>
                                </Box>
                            </Box>
                        </RadioGroup>
                        <Box display="flex" justifyContent="space-between" padding={`${SPACING_MEDIUM} 0`}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <ClickAwayListener onClickAway={() => setIsStartDateOpen(false)}>
                                    <Box width={ADMIN_CONSOLE_INPUT_LABEL_WIDTH}>
                                        <DatePicker
                                            label={t(TranslationKeyEnum.startDate)}
                                            value={dayjs(policyForm.values.startDate)}
                                            onChange={(selectedDate) => { handleDatesChange(policyForm, getDateOrNull(selectedDate)!, "startDate"); setIsStartDateOpen(false); }}
                                            open={isStartDateOpen}
                                            slots={{ textField: StyledTextField as React.ElementType<TextFieldProps> }}
                                            slotProps={{
                                                textField: {
                                                    sx: { width: "100%", input: { cursor: "pointer" } },
                                                    autoComplete: "off",
                                                    onClick: () => setIsStartDateOpen(!isStartDateOpen),
                                                    error: policyForm.touched.startDate && Boolean(policyForm.errors.startDate),
                                                    helperText: policyForm.touched.startDate && policyForm.errors.startDate
                                                },
                                            }}
                                            format="DD/MM/YYYY"
                                        />
                                    </Box>
                                </ClickAwayListener>
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <ClickAwayListener onClickAway={() => setIsEndDateOpen(false)}>
                                    <Box width={ADMIN_CONSOLE_INPUT_LABEL_WIDTH}>
                                        <DatePicker
                                            label={t(TranslationKeyEnum.endDate)}
                                            value={dayjs(policyForm.values.endDate)}
                                            open={isEndDateOpen}
                                            onChange={(selectedDate) => { handleDatesChange(policyForm, getDateOrNull(selectedDate)!, "endDate"); setIsEndDateOpen(false) }}
                                            slots={{ textField: StyledTextField as React.ElementType<TextFieldProps> }}
                                            slotProps={{
                                                textField: {
                                                    sx: { width: "100%", input: { cursor: "pointer" } },
                                                    autoComplete: "off",
                                                    onClick: () => setIsEndDateOpen(!isEndDateOpen),
                                                    error: policyForm.touched.endDate && Boolean(policyForm.errors.endDate),
                                                    helperText: policyForm.touched.endDate && policyForm.errors.endDate
                                                },
                                            }}
                                            format="DD/MM/YYYY"
                                        />
                                    </Box>
                                </ClickAwayListener>
                            </LocalizationProvider>
                        </Box>
                        <FormControl sx={{ width: "150px" }}>
                            <InputLabel shrink id="demo-controlled-open-select-label">
                                {t(TranslationKeyEnum.policyColor)}
                            </InputLabel>
                            <StyledSelectField
                                id="color"
                                name="color"
                                value={policyForm.values.color}
                                label={t(TranslationKeyEnum.policyColor)}
                                onChange={policyForm.handleChange}
                                error={policyForm.touched.color && Boolean(policyForm.errors.color)}
                                notched={true}
                                displayEmpty={true}
                                renderValue={() =>
                                    policyForm.values.color ?
                                        <PalletteButton colorName={colors.find(color => color === policyForm.values.color)} />
                                        :
                                        t(TranslationKeyEnum.selectColor)
                                }
                            >
                                {colors.filter(c => c !== "Undefined").map(x =>
                                    <MenuItem value={x} id={x}>
                                        <PalletteButton colorName={x} />
                                    </MenuItem>
                                )}
                            </StyledSelectField>
                            {policyForm.errors.color && policyForm.touched.color && <DropdownErrorText />}
                        </FormControl>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: SPACING_EXTRA_SMALL }}>
                            <Button color="inherit" onClick={onClose}>
                                {t(TranslationKeyEnum.cancel)}
                            </Button>
                            <Button type="submit" variant="contained">{t(TranslationKeyEnum.submit)}</Button>
                        </Box>
                    </Stack>
                </form>
            </Box>
        </Dialog>
    );
}

export default AddEditPolicyFormDialog;