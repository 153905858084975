import React from "react";
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import CloseIcon from '@mui/icons-material/Close';
import Loader from "../../../components/Loader/Loader";
import RequestCreatedAlert from "./RequestCreatedAlert/RequestCreatedAlert";
import Calendar from "../../../components/common/Calendar/Calendar";
import useMediaQuery from '@mui/material/useMediaQuery';
import * as Sentry from "@sentry/react";
import { FormikValues, useFormik } from "formik";
import { CreateTimeOffRequest, GroupDataDto, TimeOffPolicyVM, UserDto } from "../../../API/time-off-service";
import { DateRange } from "../../../components/common/DateRange/DateRange";
import { gerParentAndChildPolicyPair, getDateOrNull, getNumberOfPotentialRemainderDaysInPolicy, isInParentRange, isOverlappedWithMyRequests, isOverlappedWithRequestDateRanges, isOverlappedWithUserRequests, isThereOnlyOnePolicy, useGetNumberOfBusinessDaysExcludingBankHolidays, useValidatePartialPolicy, useValidatePolicyRange, useValidateSinglePolicy } from "../helpers";
import { useCreateRequest, useGetPolicies } from "../actions";
import { useRequestTimeOffFormikForm } from "../RequestTimeOff/requestTimeOffFormikForm";
import { Grid, Box, Typography, Dialog, FormControl, InputLabel, MenuItem, Button, TextFieldProps } from '@mui/material';
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { BORDER_RADIUS_MEDIUM, DARK_BOX_SHADOW, SPACING_LARGE, SPACING_MEDIUM, SPACING_SMALL, APP_BG_COLOR, NOTIFICATION_WARNING_BG_COLOR, BORDER_RADIUS_SMALL, LIGHT_BG_COLOR, NOTIFICATION_ERROR_BG_COLOR, ICON_COLOR } from "../../../utils/cssUtils";
import { TranslationKeyEnum } from "../../../features/translations/TranslationKeyEnum";
import { useTranslation } from "react-i18next";
import { ICreateTimeOffRequestDialog } from "./ICreateTimeOffRequestDialog";
import { useGetUser } from "../../../API/user-actions";
import { ICalendarEvent } from "../../../components/common/Calendar/ICalendarProps";
import { useGetCalendarEvents } from "../../../components/ReadOnlyCalendar/actions";
import { addDays, subtractDays, temporaryEventColor, temporaryEventId, temporaryEventTextColor, toStringYYYYMMDD } from "../../../components/common/Calendar/helpers";
import { useGetTimeOffUser } from "../../../API/time-off-actions";
import { StyledTextField } from "../../../components/StyledTextfield/StyledTextField";
import { StyledSelectField } from "../../../components/StyledSelect/StyledSelect";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";

export default function CreateTimeOffRequestDialog({ userId, isOpen, setIsOpen, isForUser }: ICreateTimeOffRequestDialog) {
    const { t } = useTranslation();
    const getPolicies = useGetPolicies(isForUser ? userId! : "");
    const getUser = useGetUser();
    const getTimeOffUser = useGetTimeOffUser();
    const getCalendarEvents = useGetCalendarEvents();
    const validatePartialPolicy = useValidatePartialPolicy();
    const validateSinglePolicy = useValidateSinglePolicy();
    const validatePolicyRange = useValidatePolicyRange();
    const createRequest = useCreateRequest(isForUser === true ? userId : "");
    const formikTimeOffRequest = useRequestTimeOffFormikForm();
    const getNumberOfBusinessDaysExcludingBankHolidays = useGetNumberOfBusinessDaysExcludingBankHolidays();
    const timeOffRequestForm: FormikValues = useFormik(formikTimeOffRequest(handleSubmit));
    const isMobile = useMediaQuery('(max-width:900px)');

    const [userGroups, setUserGroups] = React.useState<GroupDataDto[]>([]);
    const [policies, setPolicies] = React.useState<TimeOffPolicyVM[]>([]);
    const [selectedPolicy, setSelectedPolicy] = React.useState<TimeOffPolicyVM>();
    const [parentPolicy, setParentPolicy] = React.useState<TimeOffPolicyVM>();
    const [childPolicy, setChildPolicy] = React.useState<TimeOffPolicyVM>();
    const [startDate, setStartDate] = React.useState<Date | null>(null);
    const [startDateError, setStartDateError] = React.useState<boolean>(false);
    const [endDate, setEndDate] = React.useState<Date | null>(null);
    const [isStartDateOpen, setIsStartDateOpen] = React.useState<boolean>(false);
    const [isEndDateOpen, setIsEndDateOpen] = React.useState<boolean>(false);
    const [endDateError, setEndDateError] = React.useState<boolean>(false);
    const [numberOfDays, setNumberOfDays] = React.useState<number>(0);
    const [numberOfDaysError, setNumberOfDaysError] = React.useState<boolean>(false);
    const [isSelectDataLoading, setIsSelectDataLoading] = React.useState<boolean>(true);
    const [isOverlapping, setIsOverlapping] = React.useState<boolean>(false);
    const [approverFullname, setApproverFullname] = React.useState<string>("");
    const [userFullname, setUserFullname] = React.useState<string>("");
    const [isRequestCreated, setIsRequestCreated] = React.useState<boolean>(false);
    const [events, setEvents] = React.useState<ICalendarEvent[]>([]);
    const [validationErrorText, setValidationErrorText] = React.useState<string | null>(null);

    // gets all policies
    React.useEffect((): void => {
        const fetchPolicies = async () => {
            let policies = await getPolicies(userId);
            if (!policies.policies) return;
            setUserGroups(policies.groupDataDtos!);
            setPolicies(policies.policies);
            setIsSelectDataLoading(false);
        }
        fetchPolicies();
    }, [])
    // checks for dates overlapping and calculating number of time off days
    React.useEffect((): void => {
        setIsOverlapping(false);
        const validateData = async (): Promise<void> => {
            const selectedStartDate = getDateOrNull(startDate);
            const selectedEndDate = getDateOrNull(endDate);

            const numberOfDays = await getNumberOfBusinessDaysExcludingBankHolidays(new DateRange(selectedStartDate!, selectedEndDate!), isForUser ? userId! : "");
            setNumberOfDays(numberOfDays);

            if(numberOfDays > 0){
                setNumberOfDaysError(false)
            }

            if (isOverlappedWithRequestDateRanges(new DateRange(selectedStartDate!, selectedEndDate!), events, userGroups)) {
                setIsOverlapping(true);
            }
        }
        startDate && endDate && validateData();
    }, [startDate, endDate])

    // search for selected policy
    React.useEffect((): void => {
        let findSelectedPolicy = policies.find((policy: TimeOffPolicyVM) => timeOffRequestForm.values.policy === policy.id);
        setSelectedPolicy(findSelectedPolicy);
    }, [timeOffRequestForm.values.policy])

    // number of time off days validator
    React.useEffect((): void => {
        if (!selectedPolicy) return;

        const validateData = async (): Promise<void> => {
            const selectedStartDate = getDateOrNull(startDate);
            const selectedEndDate = getDateOrNull(endDate);
            selectedStartDate && setStartDateError(false);
            selectedEndDate && setEndDateError(false);
            if (isForUser ? isOverlappedWithUserRequests(new DateRange(selectedStartDate!, selectedEndDate!), events, userId) : isOverlappedWithMyRequests(new DateRange(selectedStartDate!, selectedEndDate!), events)) {
                return setValidationErrorText(t(TranslationKeyEnum.alreadySelectedDates));
            }

            const numberOfDays = await getNumberOfBusinessDaysExcludingBankHolidays(new DateRange(selectedStartDate!, selectedEndDate!), userId)
            setNumberOfDays(numberOfDays);

            if (selectedPolicy.infiniteDays) {
                const errorMessage = validatePolicyRange(selectedPolicy, selectedStartDate!, selectedEndDate!);
                return setValidationErrorText(errorMessage);
            } 

            if (numberOfDays <= 0) {
                setNumberOfDaysError(true);
                setValidationErrorText(t(TranslationKeyEnum.zeroWorkDaysSelected));
                return;
            }

            let parentPolicy = policies.find(p => selectedPolicy?.dependsOnPolicyId === p.id);
            let childPolicy = policies.find(p => p.dependsOnPolicyId === selectedPolicy?.id);
            setParentPolicy(parentPolicy);
            setChildPolicy(childPolicy);

            if (isThereOnlyOnePolicy(parentPolicy!, childPolicy!)) {
                const errorMessage = validateSinglePolicy(selectedPolicy, numberOfDays, selectedStartDate!, selectedEndDate!)
                setValidationErrorText(errorMessage);
                return;
            }

            const [parent, child] = gerParentAndChildPolicyPair(parentPolicy ?? null, selectedPolicy, childPolicy ?? null);
            const check = isInParentRange(selectedPolicy, selectedStartDate!, selectedEndDate!);

            switch (check) {
                case 0:
                    return setValidationErrorText(t(TranslationKeyEnum.policyValidRange, { startDate: (parent.startDate!.toLocaleDateString('en-GB').replace(/\//g, "-")), endDate: (parent.endDate!.toLocaleDateString('en-GB').replace(/\//g, "-")) }));
                case 1:
                    const potentialRemainderDaysInParentPolicy = getNumberOfPotentialRemainderDaysInPolicy(parent, numberOfDays);
                    const errorMessageOnlyParent = potentialRemainderDaysInParentPolicy < 0 ? t(TranslationKeyEnum.noAvailableDays) : null;
                    // if invalid => check if the request is overlapping with the parent-child overlapping range
                    if (errorMessageOnlyParent && selectedEndDate! >= child.startDate!) {
                        if (selectedStartDate! < child.startDate!) {
                            const modifiedParent = new TimeOffPolicyVM({
                                startDate: parent.startDate,
                                endDate: subtractDays(child.startDate!, 1),
                                totalDays: parent.totalDays,
                                usedDays: parent.usedDays,
                                reservedDays: parent.reservedDays,
                                allowNegativeBalance: parent.allowNegativeBalance
                            })
                            const errorMessageMaybePartialPair = (await validatePartialPolicy(modifiedParent, selectedStartDate!, selectedEndDate!))
                                && (await validatePartialPolicy(child, selectedStartDate!, selectedEndDate!))
                                ? null
                                : t(TranslationKeyEnum.noAvailableDays);
                            setValidationErrorText(errorMessageMaybePartialPair);
                            return;
                        } else {
                            const potentialRemainderDaysInChildPolicy = getNumberOfPotentialRemainderDaysInPolicy(child, Math.abs(potentialRemainderDaysInParentPolicy));
                            const errorMessageParentAndChild = potentialRemainderDaysInChildPolicy < 0 ? t(TranslationKeyEnum.noAvailableDays) : null;
                            setValidationErrorText(errorMessageParentAndChild);
                            return;
                        }
                    }
                    setValidationErrorText(errorMessageOnlyParent);
                    return;
                case 2:
                    const isParentPartValid = await validatePartialPolicy(parent, selectedStartDate!, selectedEndDate!);
                    if (isParentPartValid) {
                        const isChildPartValid = await validatePartialPolicy(child, addDays(parent.endDate!, 1), selectedEndDate!);
                        setValidationErrorText(!isChildPartValid ? t(TranslationKeyEnum.noAvailableDays) : null);
                        return;
                    } else {
                        const potentialRemainderDaysInParentPolicy = getNumberOfPotentialRemainderDaysInPolicy(parent, numberOfDays);
                        const potentialRemainderDaysInChildPolicy = getNumberOfPotentialRemainderDaysInPolicy(child, Math.abs(potentialRemainderDaysInParentPolicy));
                        const errorMessageParentAndChild = potentialRemainderDaysInChildPolicy < 0 ? t(TranslationKeyEnum.noAvailableDays) : null;
                        setValidationErrorText(errorMessageParentAndChild);
                        return;
                    }
                case 3:
                    const errorMessageSinglePolicy = validateSinglePolicy(selectedPolicy, numberOfDays, selectedStartDate!, selectedEndDate!)
                    setValidationErrorText(errorMessageSinglePolicy);
                    return;
                case -1:
                default:
                    Sentry.captureException(`Case that is not covered happened while selecting time off request dates.\nPolicy: ${selectedPolicy.name!}\nParent policy: ${parentPolicy?.name}\nChild policy: ${childPolicy?.name}\nStart date: ${startDate}\nEnd date: ${endDate}`);
                    break;
            }
        }
        startDate && endDate && selectedPolicy && validateData();
    }, [startDate, endDate, selectedPolicy])

    // gets time off user so we can find his approver and get approver fullname
    React.useEffect((): void => {
        const fetchUser = async (): Promise<void> => {
            if (!userId) return;
            let timeOffUser: UserDto = await getTimeOffUser(userId);
            if (!timeOffUser) return;
            let getApprover = await getUser(timeOffUser.approverId!);
            if (!getApprover) return;
            setApproverFullname(`${getApprover.firstName} ${getApprover.lastName}`)
            setUserFullname(`${timeOffUser.firstName} ${timeOffUser.lastName}`)
        }
        fetchUser();
    }, [userId])

    React.useEffect((): void => {
        const fetchData = async (): Promise<void> => setEvents(await getCalendarEvents(userId));
        fetchData();
    }, [])

    React.useEffect((): void => {
        resetValues();
    }, [isRequestCreated])

    async function handleSubmit(): Promise<void> {
        let request: CreateTimeOffRequest = new CreateTimeOffRequest();
        request.policyId = selectedPolicy?.id;
        request.startDate = new Date(startDate!);
        request.endDate = new Date(endDate!);
        request.requestComment = timeOffRequestForm.values.comment ? timeOffRequestForm.values.comment : selectedPolicy?.name;
        request.overrideOverLapping = true;
        setIsRequestCreated(true);
        await createRequest(request);
    }

    const handleStartDatePick = (newValue: Date | null): void => {
        if (newValue?.toString() === "Invalid Date") return;
        newValue?.toString() !== "Invalid Date" && setStartDateError(false);
        //  if there is a end date value we check if start date value is greater than end date
        //  if that is the case we set end date value to be same as start date value
        let newEndDate = !endDate || (endDate && getDateOrNull(newValue)! > endDate) ? getDateOrNull(newValue) : getDateOrNull(endDate);
        if (!endDate || (endDate && getDateOrNull(newValue)! > endDate)) setEndDate(newValue);
        setStartDate(newValue);

        const newStartDate = getDateOrNull(newValue)
        if (endDate && newStartDate! > endDate) {
            setEndDateError(false);
            setNumberOfDays(0);
            setEvents([...events.filter(e => e.id !== temporaryEventId)])
        }
        addOrReplaceTemporaryEvent(newStartDate, newEndDate);
    }

    const handleEndDatePick = (newValue: Date | null): void => {
        if (newValue?.toString() === "Invalid Date") return;
        newValue?.toString() !== "Invalid Date" && setEndDateError(false);
        setEndDate(newValue);
        addOrReplaceTemporaryEvent(getDateOrNull(startDate), getDateOrNull(newValue));
    }

    const handleCloseRequestForm = (): void => {
        resetValues();
        isRequestCreated && setIsRequestCreated(false);
        setIsOpen(false);
    }

    const resetValues = (): void => {
        timeOffRequestForm.setFieldValue("policy", "", false);
        timeOffRequestForm.setFieldValue("comment", "");
        timeOffRequestForm.setTouched({}, false);
        setStartDate(null);
        setEndDate(null);
        setIsOverlapping(false);
        setStartDateError(false);
        setEndDateError(false);
        setValidationErrorText("");
    }

    const isButtonDisalbed = (): boolean => {
        if (startDateError || endDateError || numberOfDays === 0 || !numberOfDays || validationErrorText || numberOfDaysError) return true;
        return false;
    }

    const checkForErrors = (): boolean => {
        if (((Boolean(timeOffRequestForm.touched.policy) && timeOffRequestForm.errors.policy) || startDateError || endDateError || validationErrorText || numberOfDaysError)) return true;
        return false;
    }

    const selectDateRange = (start: Date, end: Date): void => {
        setStartDate(start)
        setEndDate(end)
    }

    const addOrReplaceTemporaryEvent = (start: Date | null, end: Date | null) => {
        start && end && setEvents([...events.filter(e => e.id !== temporaryEventId), {
            id: temporaryEventId,
            title: t(TranslationKeyEnum.selectedDateRange),
            start: toStringYYYYMMDD(start),
            end: toStringYYYYMMDD(addDays(end, 1)),
            allDay: true,
            color: temporaryEventColor,
            textColor: temporaryEventTextColor,
            display: 'background',
            classNames: ["selected-date-range"]
        } as ICalendarEvent])
    }

    return (
        <Dialog onClose={handleCloseRequestForm} open={isOpen} sx={{ marginBottom: isRequestCreated ? "20vh" : "0vh" }}>
            {
                isRequestCreated ?
                    // Request created alert
                    <RequestCreatedAlert setIsOpen={setIsOpen} numberOfDays={numberOfDays} setIsRequestCreated={setIsRequestCreated} /> :
                    // Create request form
                    <Box width="80%" height="90vh" maxHeight="calc(100% - 50px)" position="fixed" top="0" left="0" marginTop={SPACING_LARGE} marginLeft="10%" sx={{ backgroundColor: APP_BG_COLOR, overflowY: "auto" }} borderRadius={BORDER_RADIUS_MEDIUM}>
                        {/* Time Off Corner Icon */}
                        <Box width="70px" height="70px" position="fixed" boxShadow={DARK_BOX_SHADOW} borderRadius={BORDER_RADIUS_MEDIUM} marginLeft={SPACING_LARGE} sx={{ backgroundColor: APP_BG_COLOR, transform: "translateY(-50%)" }}>
                            <Box display="flex" justifyContent="center">
                                <BeachAccessIcon color="action" fontSize="large" sx={{ marginTop: "16px" }} />
                            </Box>
                        </Box>
                        <Box position="absolute" right="0" padding={SPACING_SMALL} sx={{ cursor: "pointer" }} onClick={handleCloseRequestForm}>
                            <CloseIcon sx={{ color: ICON_COLOR }} />
                        </Box>
                        {/* Request data */}
                        <Grid container padding={SPACING_LARGE} spacing={4}>
                            <Grid item xl={4} lg={4} md={5} sm={12} xs={12}>
                                <Typography variant="h6" textTransform="capitalize">{t(TranslationKeyEnum.requestTimeOff)} {isForUser && ` - ${userFullname}`}</Typography>
                                {
                                    <Typography variant="body2" color="gray">{isForUser ?
                                        t(TranslationKeyEnum.createRequestForUserLabel, { userFullname: userFullname })
                                        :
                                        t(TranslationKeyEnum.createRequestLabel, { approverFullname: approverFullname })}
                                    </Typography>
                                }
                                <form onSubmit={timeOffRequestForm.handleSubmit}>
                                    <FormControl fullWidth sx={{ marginTop: SPACING_SMALL, marginBottom: SPACING_SMALL }}>
                                        <InputLabel shrink>{t(TranslationKeyEnum.typeOfAbsence)}</InputLabel>
                                        <StyledSelectField
                                            id="policy"
                                            name="policy"
                                            label={t(TranslationKeyEnum.typeOfAbsence)}
                                            value={timeOffRequestForm.values.policy}
                                            onChange={timeOffRequestForm.handleChange}
                                            error={timeOffRequestForm.touched.policy && Boolean(timeOffRequestForm.errors.policy)}
                                            fullWidth
                                            notched={true}
                                            displayEmpty={true}
                                            renderValue={() => timeOffRequestForm.values.policy ?
                                                policies.find((policy: TimeOffPolicyVM) => timeOffRequestForm.values.policy === policy.id)?.name
                                                :
                                                t(TranslationKeyEnum.selectTypeOfAbsence)
                                            }
                                        >
                                            {
                                                isSelectDataLoading ? <Box height={30}><Loader size={30} /></Box> : policies?.map((policy: TimeOffPolicyVM) => <MenuItem value={policy.id} key={policy.id}>{policy.name}</MenuItem>)
                                            }
                                        </StyledSelectField>
                                    </FormControl>
                                    <StyledTextField
                                        id="comment"
                                        name="comment"
                                        label={t(TranslationKeyEnum.comment)}
                                        multiline
                                        rows={3}
                                        fullWidth
                                        value={timeOffRequestForm.values.comment}
                                        onChange={timeOffRequestForm.handleChange}
                                        sx={{ marginBottom: SPACING_SMALL }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Box display="flex" justifyContent="space-between" gap={1} marginBottom={SPACING_SMALL}>
                                            {
                                                isMobile
                                                    ? <Box>
                                                        <DatePicker
                                                            label={t(TranslationKeyEnum.startingFrom)}
                                                            format="DD/MM/YYYY"
                                                            value={startDate}
                                                            onChange={(newValue) => { handleStartDatePick(newValue); }}
                                                            closeOnSelect={true}
                                                            slots={{ textField: StyledTextField as React.ElementType<TextFieldProps> }}
                                                            slotProps={{
                                                                textField: {
                                                                    sx: { svg: { color: LIGHT_BG_COLOR }, input: { cursor: "pointer" } },
                                                                    InputLabelProps: { shrink: true },
                                                                    autoComplete: "off",
                                                                },
                                                            }}
                                                            onError={(error) => error !== null && setStartDateError(true)}
                                                        />
                                                    </Box>
                                                    : <CustomDatePicker
                                                        label={t(TranslationKeyEnum.startingFrom)}
                                                        value={startDate}
                                                        inputFormat="DD/MM/YYYY"
                                                        handleDatePick={handleStartDatePick}
                                                        isOpen={isStartDateOpen}
                                                        setIsOpen={setIsStartDateOpen}
                                                        setDateError={setStartDateError}
                                                        shouldDisableDate={(day: Date) => false}
                                                    />
                                            }
                                            {
                                                isMobile
                                                    ? <DatePicker
                                                        label={t(TranslationKeyEnum.ending)}
                                                        format="DD/MM/YYYY"
                                                        value={endDate}
                                                        onChange={(newValue) => { handleEndDatePick(newValue); }}
                                                        closeOnSelect={true}
                                                        slots={{ textField: StyledTextField as React.ElementType<TextFieldProps> }}
                                                        slotProps={{
                                                            textField: {
                                                                sx: { svg: { color: LIGHT_BG_COLOR }, input: { cursor: "pointer" } },
                                                                InputLabelProps: { shrink: true },
                                                                autoComplete: "off",
                                                            },
                                                        }}
                                                        shouldDisableDate={(day: Date) => !!startDate && startDate > day}
                                                        onError={(error) => error !== null && setEndDateError(true)}
                                                        componentsProps={{
                                                            textField: {
                                                                InputProps: { readOnly: true },
                                                            },
                                                        }} />
                                                    : <CustomDatePicker
                                                        label={t(TranslationKeyEnum.ending)}
                                                        value={endDate}
                                                        inputFormat="DD/MM/YYYY"
                                                        handleDatePick={handleEndDatePick}
                                                        isOpen={isEndDateOpen}
                                                        setIsOpen={setIsEndDateOpen}
                                                        setDateError={setEndDateError}
                                                        shouldDisableDate={(day: Date) => !!startDate && startDate > day}
                                                    />
                                            }
                                        </Box>
                                    </LocalizationProvider>
                                    {/* Errors */}
                                    {
                                        (checkForErrors()) && <Box borderRadius={BORDER_RADIUS_SMALL} marginBottom={SPACING_SMALL} padding={`${SPACING_SMALL} ${SPACING_LARGE}`} sx={{ backgroundColor: NOTIFICATION_ERROR_BG_COLOR }} display="flex" flexDirection="column">
                                            {validationErrorText && <Typography variant="body2" color="error" sx={{ listStyleType: "disc", display: "list-item" }}>{validationErrorText}</Typography>}
                                            {
                                                (Boolean(timeOffRequestForm.touched.policy) && timeOffRequestForm.errors.policy) &&
                                                <Typography variant="body2" color="error" sx={{ listStyleType: "disc", display: "list-item" }}>
                                                    {t(TranslationKeyEnum.selectTypeOfAbsence)}
                                                </Typography>}
                                            {startDateError && <Typography variant="body2" color="error" sx={{ listStyleType: "disc", display: "list-item" }}>{t(TranslationKeyEnum.invalidStartDate)}</Typography>}
                                            {endDateError && <Typography variant="body2" color="error" sx={{ listStyleType: "disc", display: "list-item" }}>{t(TranslationKeyEnum.invalidEndDate)}</Typography>}
                                        </Box>
                                    }
                                    {/* Warnings */}
                                    {
                                        isOverlapping && <Box borderRadius={BORDER_RADIUS_SMALL} padding={SPACING_SMALL} marginBottom={SPACING_MEDIUM} sx={{ backgroundColor: NOTIFICATION_WARNING_BG_COLOR }}>
                                            <Typography variant="body2" textAlign="center">{t(TranslationKeyEnum.requestOverlaps)}</Typography>
                                        </Box>
                                    }
                                    {
                                        parentPolicy && <Box borderRadius={BORDER_RADIUS_SMALL} padding={SPACING_SMALL} marginBottom={SPACING_MEDIUM} sx={{ backgroundColor: NOTIFICATION_WARNING_BG_COLOR }}>
                                            <Typography variant="body2" textAlign="center">{t(TranslationKeyEnum.dependantPoliciesInfo, { parent: parentPolicy?.name, child: selectedPolicy?.name })}</Typography>
                                        </Box>
                                    }
                                    {
                                        childPolicy && <Box borderRadius={BORDER_RADIUS_SMALL} padding={SPACING_SMALL} marginBottom={SPACING_MEDIUM} sx={{ backgroundColor: NOTIFICATION_WARNING_BG_COLOR }}>
                                            <Typography variant="body2" textAlign="center">{t(TranslationKeyEnum.dependantPoliciesInfo, { parent: selectedPolicy?.name, child: childPolicy?.name })}</Typography>
                                        </Box>
                                    }
                                    {/* Buttons */}
                                    <Box display="flex" justifyContent="flex-end" marginTop={SPACING_SMALL} gap={4} marginBottom={SPACING_SMALL}>
                                        <Button sx={{ color: LIGHT_BG_COLOR }} onClick={handleCloseRequestForm}>{t(TranslationKeyEnum.cancel)}</Button>
                                        <Button type="submit" variant="contained" sx={{ backgroundColor: LIGHT_BG_COLOR }} disabled={isButtonDisalbed()}>{t(TranslationKeyEnum.requestWorkDays, { workDays: numberOfDays })}</Button>
                                    </Box>
                                </form>
                            </Grid>
                            {/* Calendar */}
                            <Grid item xl={8} lg={8} md={7} display={{ xl: "block", lg: "block", md: "block", sm: "none", xs: "none" }}><Calendar selectable events={events} selectDateRange={selectDateRange} /></Grid>
                        </Grid>
                    </Box>
            }
        </Dialog>
    );
}