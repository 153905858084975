import { CancelTimeOffRequest, RequestClient, RequestDto, RequestStatusEnum, ResponseMessage, UserWithManyGroupsDto } from "../../API/time-off-service";
import { DateRange } from "../../components/common/DateRange/DateRange";
import { useGetNumberOfBusinessDaysExcludingBankHolidays } from "../TimeOff/helpers";
import { useTryCatchJsonByAction } from "../../utils/fetchUtils";

const requestClient = new RequestClient();
const getRequestsAction = (): Promise<RequestDto[]> => requestClient.getPendingForApprover();
const getRequestAction = (requestId: string): Promise<RequestDto> => requestClient.get(requestId);
const getRequestsForUserAction = (): Promise<RequestDto[]> => requestClient.getPendingForUser();
const getAllRequestsForApproverAction = (): Promise<RequestDto[]> => requestClient.getAllRequestsForApprover();
const getUserRequestsForApproverAction = (userId: string): Promise<RequestDto[]> => requestClient.getUserRequestsForApprover(userId);
const cancelRequestAction = (request: CancelTimeOffRequest): Promise<ResponseMessage> => requestClient.cancelRequest(request);
const cancelApprovedRequestAction = (request: CancelTimeOffRequest): Promise<ResponseMessage> => requestClient.cancelApprovedRequest(request);
const sortByStartDateAsc = (a: RequestDto, b: RequestDto) => a.startDate! >= b.startDate! ? 1 : -1;
const sortByEndDateAsc = (a: RequestDto, b: RequestDto) => a.endDate! >= b.endDate! ? 1 : -1;
const sortByStartDateDesc = (a: RequestTableVM, b: RequestTableVM) => a.startDate! >= b.startDate! ? -1 : 1;
const sortByStartDateDescTableRowModel = (a: RequestUserTableRow, b: RequestUserTableRow) => a.startDate! >= b.startDate ? -1 : 1;

export class RequestTableVM {
    public id: string;
    public relatedId: string | null;
    public firstName: string;
    public lastName: string;
    public numberOfDays: number;
    public startDate: Date;
    public endDate: Date;
    public image: string;
    public status: RequestStatusEnum;

    public constructor(request: RequestDto, numberOfDays: number) {
        this.id = request.id!;
        this.relatedId = request.relatedToRequestId ?? null;
        this.firstName = request.user?.firstName!;
        this.lastName = request.user?.lastName!;
        this.image = request.user?.image!;
        this.numberOfDays = numberOfDays;
        this.startDate = request.startDate!;
        this.endDate = request.endDate!;
        this.status = request.status!;
    }
}

export class RequestUserTableRow {
    public id: string;
    public user: UserWithManyGroupsDto;
    public policyName: string;
    public comment: string;
    public numberOfDays: number;
    public startDate: Date;
    public endDate: Date;
    public status: RequestStatusEnum;
    public relatedId: string | null;

    public constructor(request: RequestDto, numberOfDays: number) {
        this.id = request.id!;
        this.user = request.user!;
        this.policyName = request.policy!.name!;
        this.comment = request.requestComment!;
        this.numberOfDays = numberOfDays;
        this.startDate = request.startDate!;
        this.endDate = request.endDate!;
        this.status = request.status!;
        this.relatedId = request.relatedToRequestId ?? null;
    }
}

export class RequestResultVM {
    public tableData: RequestUserTableRow[];
    public requests: RequestDto[];

    public constructor(tableData: RequestUserTableRow[], requests: RequestDto[]) {
        this.tableData = tableData;
        this.requests = requests;
    }
}

export function useGetRequest(): (requestId: string) => Promise<RequestDto>{
    const tryCatchAction = useTryCatchJsonByAction();
    
    async function getRequest(requestId: string): Promise<RequestDto>{
        const bindedGetRequestAction = getRequestAction.bind(null, requestId);
        const requestModel = await tryCatchAction(bindedGetRequestAction);
        return requestModel.response ?? {};
    }
    return getRequest;
}

export function useGetRequests(): () => Promise<RequestTableVM[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    const getNumberOfBusinessDaysExcludingBankHolidays = useGetNumberOfBusinessDaysExcludingBankHolidays();

    async function getRequests(): Promise<RequestTableVM[]> {
        const bindedGetRequestsAction = getRequestsAction.bind(null);
        const requestsModel = await tryCatchAction(bindedGetRequestsAction);
        let requests: RequestDto[] = requestsModel.response ?? [];
        requests = requests.sort(sortByStartDateAsc).sort(sortByEndDateAsc);
        const promises = requests.map(request => getNumberOfBusinessDaysExcludingBankHolidays(new DateRange(request.startDate!, request.endDate!), request.user!.id!));
        const results = await Promise.all(promises)
        const formattedRequests: RequestTableVM[] = [];

        // bind related requests
        for (let index = 0; index < requests.length; index++) {
            if (requests[index].relatedToRequestId) {
                const relatedRequest = formattedRequests.find(e => e.id === requests[index].relatedToRequestId)
                relatedRequest!.relatedId = requests[index].id;
                relatedRequest!.endDate = requests[index].endDate!;
                relatedRequest!.numberOfDays += results[index];
            } else {
                formattedRequests.push(new RequestTableVM(requests[index], results[index]))
            }
        }

        return formattedRequests.sort(sortByStartDateDesc);
    }

    return getRequests;
}

export function useGetUserRequestsForApprover(userId: string): () => Promise<RequestResultVM> {
    const tryCatchAction = useTryCatchJsonByAction();
    const getNumberOfBusinessDaysExcludingBankHolidays = useGetNumberOfBusinessDaysExcludingBankHolidays();

    async function getRequests(): Promise<RequestResultVM> {
        const bindedGetRequestsAction = getUserRequestsForApproverAction.bind(null, userId);
        const requestsModel = await tryCatchAction(bindedGetRequestsAction);
        let requests: RequestDto[] = (requestsModel.response ?? []);
        requests = requests.sort(sortByStartDateAsc)
        const promises = requests.map(request => getNumberOfBusinessDaysExcludingBankHolidays(new DateRange(request.startDate!, request.endDate!), request.userId));
        const results = await Promise.all(promises)
        const formattedRequests: RequestUserTableRow[] = [];

        // bind related requests
        for (let index = 0; index < requests.length; index++) {
            if (requests[index].relatedToRequestId) {
                const relatedRequest = formattedRequests.find(e => e.id === requests[index].relatedToRequestId)
                relatedRequest!.endDate = requests[index].endDate!;
                relatedRequest!.numberOfDays += results[index];
                relatedRequest!.relatedId = requests[index].id;
                relatedRequest!.policyName = `${relatedRequest!.policyName}, ${requests[index].policy!.name}`;
            } else {
                formattedRequests.push(new RequestUserTableRow(requests[index], results[index]))
            }

        }

        formattedRequests.sort(sortByStartDateDescTableRowModel)
        return new RequestResultVM(formattedRequests, requests);
    }

    return getRequests;
}

export function useGetAllUserRequestsForApprover(): () => Promise<RequestResultVM> {
    const tryCatchAction = useTryCatchJsonByAction();
    const getNumberOfBusinessDaysExcludingBankHolidays = useGetNumberOfBusinessDaysExcludingBankHolidays();

    async function getRequests(): Promise<RequestResultVM> {
        const bindedGetRequestsAction = getAllRequestsForApproverAction.bind(null);
        const requestsModel = await tryCatchAction(bindedGetRequestsAction);
        let requests: RequestDto[] = (requestsModel.response ?? []);
        requests = requests.sort(sortByStartDateAsc)
        const promises = requests.map(request => getNumberOfBusinessDaysExcludingBankHolidays(new DateRange(request.startDate!, request.endDate!), request.userId));
        const results = await Promise.all(promises)
        const formattedRequests: RequestUserTableRow[] = [];

        // bind related requests
        for (let index = 0; index < requests.length; index++) {
            if (requests[index].relatedToRequestId) {
                const relatedRequest = formattedRequests.find(e => e.id === requests[index].relatedToRequestId)
                relatedRequest!.endDate = requests[index].endDate!;
                relatedRequest!.numberOfDays += results[index];
                relatedRequest!.relatedId = requests[index].id;
                relatedRequest!.policyName = `${relatedRequest!.policyName}, ${requests[index].policy!.name}`;
            } else {
                formattedRequests.push(new RequestUserTableRow(requests[index], results[index]))
            }
        }

        formattedRequests.sort(sortByStartDateDescTableRowModel)
        return new RequestResultVM(formattedRequests, requests);
    }

    return getRequests;
}

export function useGetRequestsForUser(): () => Promise<RequestUserTableRow[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    const getNumberOfBusinessDaysExcludingBankHolidays = useGetNumberOfBusinessDaysExcludingBankHolidays();

    async function getRequests(): Promise<RequestUserTableRow[]> {
        const bindedGetRequestsAction = getRequestsForUserAction.bind(null);
        const requestsModel = await tryCatchAction(bindedGetRequestsAction);
        let requests: RequestDto[] = (requestsModel.response ?? []);
        requests = requests.sort(sortByStartDateAsc)
        const promises = requests.map(request => getNumberOfBusinessDaysExcludingBankHolidays(new DateRange(request.startDate!, request.endDate!)));
        const results = await Promise.all(promises)
        const formattedRequests: RequestUserTableRow[] = [];

        // bind related requests
        for (let index = 0; index < requests.length; index++) {
            if (requests[index].relatedToRequestId) {
                const relatedRequest = formattedRequests.find(e => e.id === requests[index].relatedToRequestId)
                relatedRequest!.endDate = requests[index].endDate!;
                relatedRequest!.numberOfDays += results[index];
                relatedRequest!.relatedId = requests[index].id;
                relatedRequest!.policyName = `${relatedRequest!.policyName}, ${requests[index].policy!.name}`;
            } else {
                formattedRequests.push(new RequestUserTableRow(requests[index], results[index]))
            }

        }

        return formattedRequests.sort(sortByStartDateDescTableRowModel);
    }

    return getRequests;
}

export function useCancelRequest(): (request: CancelTimeOffRequest) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();

    async function cancelRequest(request: CancelTimeOffRequest): Promise<boolean> {
        const bindedCancelRequestAction = cancelRequestAction.bind(null, request);
        const result = await tryCatchAction(bindedCancelRequestAction);
        return result.success;
    }

    return cancelRequest;
}

export function useApprovedCancelRequest(): (request: CancelTimeOffRequest) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();

    async function cancelApprovedRequest(request: CancelTimeOffRequest): Promise<boolean> {
        const bindedCancelApprovedRequestAction = cancelApprovedRequestAction.bind(null, request);
        const result = await tryCatchAction(bindedCancelApprovedRequestAction);
        return result.success;
    }

    return cancelApprovedRequest;
}


