import { useTranslation } from "react-i18next";
import { TranslationKeyEnum } from "../../features/translations/TranslationKeyEnum";
import { Typography, Box } from "@mui/material";
import { PermissionsEnum } from "../Permission/PermisionEnum";
import { BORDER_PRIMARY_COLOR, BORDER_RADIUS_MEDIUM, BORDER_RADIUS_SMALL, LINE_WIDTH, PRIMARY_BG_COLOR, SPACING_EXTRA_SMALL, SPACING_MEDIUM, SPACING_SMALL } from "../../utils/cssUtils";
import React from "react";
import AdminEmployees from "../../components/AdminEmployees/AdminEmployees";
import AdminOffices from "../../components/AdminOffices/AdminOffices";
import AdminPositions from "../../components/AdminPositions/AdminPositions";
import AdminPolicies from "../../components/AdminPolicies/AdminPolicies";
import AdminWorkOrderTypes from "../../components/AdminWorkOrderTypes/AdminWorkOrderTypes";
import AdminNewsFeed from "../../components/AdminNewsFeed/AdminNewsFeed";
import Groups from "../../components/AdminGroups/Groups";
import BankHolidays from "../../components/AdminBankHolidays/BankHolidays";
import AdminMeetingRooms from "../../components/AdminMeetingRooms/AdminMeetingRooms";
import PermissionContext from "../Permission/PermissionContext";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Tabs from '@mui/material/Tabs';

function AdminConsole() {
  const { t } = useTranslation();
  const [navigationTabs, setNavigationTabs] = React.useState<string[]>();
  const [selectedNavigationTab, setSelectedNavigationTab] = React.useState<string>();
  const { isAllowed } = React.useContext(PermissionContext);

  const renderContent = (): JSX.Element => {
    switch (selectedNavigationTab) {
      case TranslationKeyEnum.employees: return <AdminEmployees />
      case TranslationKeyEnum.offices: return <AdminOffices />
      case TranslationKeyEnum.positions: return <AdminPositions />
      case TranslationKeyEnum.policies: return <AdminPolicies />
      case TranslationKeyEnum.groups: return <Groups />
      case TranslationKeyEnum.bankHolidays: return <BankHolidays />
      case TranslationKeyEnum.workOrdersTypes: return <AdminWorkOrderTypes />
      case TranslationKeyEnum.meetingRooms: return <AdminMeetingRooms />
      case TranslationKeyEnum.newsFeed: return <AdminNewsFeed />
      default: return <></>
    }
  }

  React.useEffect(() => {
    let tempArray: string[] = [];
    const checkPermission = async () => {
      (await isAllowed(PermissionsEnum.ManageUsersPermission)) && tempArray.push(TranslationKeyEnum.employees);
      (await isAllowed(PermissionsEnum.ManageOfficePermission)) && tempArray.push(TranslationKeyEnum.offices);
      (await isAllowed(PermissionsEnum.ManagePositionPermission)) && tempArray.push(TranslationKeyEnum.positions);
      (await isAllowed(PermissionsEnum.ManageWorkOrderPermission)) && tempArray.push(TranslationKeyEnum.workOrdersTypes);
      (await isAllowed(PermissionsEnum.ManageRoomPermission)) && tempArray.push(TranslationKeyEnum.meetingRooms);
      (await isAllowed(PermissionsEnum.ManageNewsFeedPermission)) && tempArray.push(TranslationKeyEnum.newsFeed);;      
      const finalArray = (await isAllowed(PermissionsEnum.ManageTimeOffPermission)) ? tempArray.concat([TranslationKeyEnum.policies, TranslationKeyEnum.groups, TranslationKeyEnum.bankHolidays]) : tempArray;
      setNavigationTabs(finalArray);
      setSelectedNavigationTab(finalArray[0])
    }
    checkPermission();
  }, []);

  React.useEffect(() => {
    if (navigationTabs !== undefined)
      setSelectedNavigationTab(navigationTabs![0]);
  }, [navigationTabs]);

  return (
    <Box display="flex" flexDirection="column" gap={SPACING_SMALL}>
      <Box>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={SPACING_EXTRA_SMALL} marginBottom={SPACING_EXTRA_SMALL}>
          <AdminPanelSettingsIcon fontSize="large" color="primary" />
          <Typography variant="h5" textAlign="center">{t(TranslationKeyEnum.adminConsole)}</Typography>
          <Typography variant="body2" textAlign="center">{t(TranslationKeyEnum.adminConsoleDescription)}</Typography>
          <Box width={LINE_WIDTH} border={BORDER_PRIMARY_COLOR} />
        </Box>
      </Box>
      <Box height="86%" borderRadius={BORDER_RADIUS_MEDIUM}>
        <Box>
          <TabContext value={selectedNavigationTab ?? ""}>
            <Box sx={{ maxWidth: { xl: "1000px", lg: "800px", md: "700px", sm: "600px" } }}>
              <Tabs variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                {
                  navigationTabs! && navigationTabs!.map((item: string) => <Tab onClick={() => setSelectedNavigationTab(item)} key={item} value={item} label={t(item)} />)
                }
              </Tabs>
            </Box>
            <Box marginTop={SPACING_MEDIUM} sx={{ backgroundColor: PRIMARY_BG_COLOR, borderRadius: BORDER_RADIUS_SMALL }} >
              {selectedNavigationTab! && renderContent()}
            </Box>
          </TabContext>
        </Box>
      </Box>
    </Box>
  )
}

export default AdminConsole