import { Dialog, Box, Typography, FormControl, InputLabel, Avatar, MenuItem } from '@mui/material';
import { ITimeOffMonthlySummaryProps } from './ITimeOffMonthlySummaryProps';
import { useTranslation } from 'react-i18next';
import { ICON_COLOR, PRIMARY_BG_COLOR, SPACING_EXTRA_SMALL, SPACING_MEDIUM, SPACING_SMALL } from '../../../utils/cssUtils';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CircularProgress from '@mui/material/CircularProgress';
import { getMonthNameAndYear } from '../../../utils/dateUtils';
import { OPERATIONS_ENUM, changeMonth } from '../../WorkOrders/helpers';
import { StyledReadonlyTextField } from '../../../components/StyledReadonlyTextField/StyledReadonlyTextField';
import { TranslationKeyEnum } from '../../../features/translations/TranslationKeyEnum';
import React from 'react';
import { GroupDto, TimeOffSummaryDto, TimeOffSummaryFilter } from '../../../API/time-off-service';
import { StyledSelectField } from '../../../components/StyledSelect/StyledSelect';
import { StyledDataGrid } from '../../../components/StyledDataGrid/StyledDataGrid';
import { LanguageEnum } from '../../../features/translations/LanguageEnum';
import { srRS } from '../../../features/translations/DataGrid/srRS';
import { GridColDef, GridColumnVisibilityModel, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridValidRowModel } from '@mui/x-data-grid';
import { useGetMonthlyTimeOffSummary, useGetPolicyNames } from '../../../API/time-off-actions';
import { useGetGroups } from '../../../components/AdminGroups/actions';

export default function TimeOffMonthlySummary({ isOpen, setIsOpen }: ITimeOffMonthlySummaryProps) {
    const { t, i18n } = useTranslation();
    const getPolicyNames = useGetPolicyNames();
    const getMonthlySummary = useGetMonthlyTimeOffSummary();
    const getGroups = useGetGroups();

    const [selectedMonth, setSelectedMonth] = React.useState<Date>(new Date());
    const [search, setSearch] = React.useState<string>("");
    const [group, setGroup] = React.useState<GroupDto>(new GroupDto());
    const [groups, setGroups] = React.useState<GroupDto[]>([]);
    const [isDataLoading, setIsDataLoading] = React.useState<boolean>(true);
    const [isGroupDataLoading, setIsGroupDataLoading] = React.useState<boolean>(true);
    const [rows, setRows] = React.useState<GridValidRowModel[]>([]);
    const [policyNames, setPolicyNames] = React.useState<string[]>([]);
    const [rowsData, setRowsData] = React.useState<TimeOffSummaryDto[]>([]);
    const [displayedRows, setDisplayedRows] = React.useState<TimeOffSummaryDto[]>([]);
    const [columnVisibilityModel, setColumnVisibilityModel] =
        React.useState<GridColumnVisibilityModel>({
            id: false,
        });

    const allUsersGroup = new GroupDto({
        id: "all-users-group",
        name: "All users",
        members: []
    });

    const getTogglableColumns = (columns: GridColDef[]) => {
        // hide the column with field `id` from list of togglable columns
        return columns
            .filter((column) => column.field !== 'id')
            .map((column) => column.field);
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Id', hideable: true },
        {
            field: 'name', headerName: t(TranslationKeyEnum.name), description: t(TranslationKeyEnum.name), flex: .4, minWidth: 260,
            renderCell: (values) => {
                return (
                    <Box display="flex" gap={SPACING_SMALL} alignItems="center">
                        <Avatar variant="rounded" sx={{ width: 30, height: 30 }} src={values.row.avatar && `data:image/png;base64,${values.row.avatar}`} />
                        <Typography variant="body2">{values.row.name}</Typography>
                    </Box>
                );
            }
        },
        ...policyNames.map(policy => {
            return {
                field: policy, headerName: policy, description: policy, flex: .4, minWidth: 200,
                renderCell: (values: any) => {
                    return (
                        <Box display="flex" gap={SPACING_SMALL} alignItems="center">
                            <Typography variant="body2">{values.row[policy]}</Typography>
                        </Box>
                    );
                }
            }
        })
    ];

    React.useEffect(() => {
        const fetchData = async () => {
            setIsDataLoading(true);
            Promise.all([
                getPolicyNames(),
                getMonthlySummary(new TimeOffSummaryFilter({ startDate: new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1), endDate: new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0) }))])
                .then((values) => {
                    setPolicyNames(values[0]);
                    setRowsData(values[1]);
                    setDisplayedRows(values[1]);
                    setIsDataLoading(false);
                });
        }
        fetchData();
    }, [selectedMonth]);

    React.useEffect(() => {
        setRows([
            ...displayedRows.map((monthlySummary: TimeOffSummaryDto) => {
                return {
                    id: monthlySummary.id,
                    avatar: monthlySummary.avatar,
                    name: monthlySummary.fullName,
                    ...policyNames.reduce((obj, policy) => {
                        let count = monthlySummary.timeOffsCountByPolicy![policy.toLowerCase()];
                        return {
                            ...obj,
                            [policy]: count,
                        };
                    }, {})
                }
            })
        ])
    }, [displayedRows]);

    React.useEffect(() => {
        let filterSearch = rowsData.filter(row => row.fullName?.toLowerCase()?.includes(search.toLowerCase()));
        setDisplayedRows(group.name && group.name !== allUsersGroup.name ? filterSearch.filter(employee => group?.members!.some(groupMember => employee.id === groupMember.id)) : filterSearch);
    }, [search, group]);

    React.useEffect(() => {
        const fetchGroups = async () => {
            let allGroups = await getGroups();
            if (!allGroups) return;
            setGroups([allUsersGroup].concat(allGroups));
            setIsGroupDataLoading(false);
        };
        fetchGroups();
    }, [])

    return (
        <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
            <Box padding={`${SPACING_EXTRA_SMALL} ${SPACING_MEDIUM}`} height="80vh" width={{ xl: "70vw", lg: "72vw", md: "80vw", xs: "86vw", sm: "90vw" }}>
                <Box sx={{ cursor: "pointer", position: "absolute", right: SPACING_EXTRA_SMALL, top: { xl: SPACING_MEDIUM, lg: SPACING_MEDIUM, md: "3.8vh", sm: "1.8vh", xs: "1.8vh" } }} onClick={() => setIsOpen(false)}>
                    <CloseIcon sx={{ color: ICON_COLOR }} />
                </Box>
                <Box display="flex" gap={SPACING_MEDIUM} alignItems="center" flexDirection={{ xl: "row", lg: "row", md: "row", sm: "column", xs: "column" }}>
                    <Box display="flex" alignItems="center" justifyContent="center" gap={SPACING_SMALL} width={{ xl: "30%", lg: "30%", md: "40%", sm: "50%", xs: "100%" }}>
                        <Typography variant="h5" textTransform="capitalize" width={{ xl: "12vw", lg: "16vw", md: "20vw", sm: "30vw", xs: "30ww" }}>
                            {getMonthNameAndYear(selectedMonth)}
                        </Typography>
                        <ChevronLeftIcon color="action" sx={{ cursor: "pointer" }} onClick={() => changeMonth(OPERATIONS_ENUM.SUBTRACT, selectedMonth, setSelectedMonth)} />
                        <ChevronRightIcon color="action" sx={{ cursor: "pointer" }} onClick={() => changeMonth(OPERATIONS_ENUM.ADD, selectedMonth, setSelectedMonth)} />
                    </Box>
                    <Box display="flex" gap={SPACING_SMALL} alignItems="center" width={{ xl: "60%", lg: "60%", md: "50%", sm: "100%", xs: "100%" }} flexDirection={{ xl: "row", lg: "row", md: "row", sm: "row", xs: "column" }}>
                        <StyledReadonlyTextField
                            id="search"
                            name="search"
                            placeholder={(t(TranslationKeyEnum.searchEmployee))}
                            label={t(TranslationKeyEnum.search)}
                            value={search}
                            onChange={(event: any) => setSearch(event.target.value)}
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            disabled={isDataLoading}
                            sx={{ width: { xl: "50%", lg: "50%", md: "50%", sm: "50%", xs: "90%" } }}
                        />
                        <FormControl fullWidth sx={{ marginTop: SPACING_SMALL, marginBottom: SPACING_SMALL, width: { xl: "50%", lg: "50%", md: "50%", sm: "50%", xs: "90%" } }}>
                            <InputLabel shrink>{t(TranslationKeyEnum.groups)}</InputLabel>
                            <StyledSelectField
                                id="group"
                                name="group"
                                label={t(TranslationKeyEnum.groups)}
                                size="small"
                                value={group}
                                onChange={(event: any) => setGroup(groups.find(group => group.id === event.target.value)!)}
                                fullWidth
                                notched={true}
                                displayEmpty={true}
                                renderValue={() => group?.name ?
                                    groups.find((g: GroupDto) => group.id === g.id)?.name
                                    :
                                    <InputLabel sx={{ color: "#C8C8C8" }}>{t(TranslationKeyEnum.selectGroup)}</InputLabel>
                                }
                            >
                                {
                                    isGroupDataLoading ? <Box display="flex" justifyContent="center"><CircularProgress /></Box> : groups?.map((group: GroupDto) => <MenuItem value={group.id} key={group.id}>{group.name}</MenuItem>)
                                }
                            </StyledSelectField>
                        </FormControl>
                    </Box>
                </Box>
                <StyledDataGrid
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                        setColumnVisibilityModel(newModel)
                    }
                    localeText={i18n.language === LanguageEnum.Serbian ? srRS.components.MuiDataGrid.defaultProps.localeText : undefined}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                    slotProps={{
                        columnsPanel: {
                            getTogglableColumns,
                        },
                    }}
                    hideFooter
                    columns={columns}
                    rows={rows}
                    disableRowSelectionOnClick
                    sx={{ backgroundColor: PRIMARY_BG_COLOR, marginTop: SPACING_SMALL, height: { xl: "84%", lg: "84%", md: "84%", sm: "70%", xs: "70%" } }}
                    loading={isDataLoading}
                />
            </Box>
        </Dialog>
    )
}