import { useEffect } from "react";

export default (isExternalView: boolean, intervalTime: number) => {
    useEffect(() => {
        if (!isExternalView) {
            return;
        }
        const interval = setInterval(() => {
            window.location.reload(); // reloads the window
        }, intervalTime);

        return () => {
            clearInterval(interval); // clear interval on component unmount
        };
    }, [isExternalView, intervalTime]);
}