import { Box, Skeleton, Typography } from "@mui/material";
import { SPACING_LARGE } from "../../../utils/cssUtils";
import { IAccordionSkeletonProps } from "./IAccordionSkeletonProps";

export default (props: IAccordionSkeletonProps) => {
    return (
        <Box>
            <Typography variant="h6">{props.title}</Typography>
            <Box display="flex" flexDirection="column" gap={.5}>
                {Array.from(Array(3)).map((_, index) => (
                    <Box key={index}>
                        <Skeleton variant="rectangular" height={SPACING_LARGE} />
                    </Box>
                ))}
            </Box>
        </Box>
    )
}