import CircleIcon from '@mui/icons-material/Circle';
import CalendarSkeleton from "../common/Calendar/CalendarSkeleton";
import Calendar from "../../components/common/Calendar/Calendar";
import AddIcon from '@mui/icons-material/Add';
import CreateTimeOffRequestDialog from '../../containers/TimeOff/CreateTimeOffRequestDialog/CreateTimeOffRequestDialog';
import { useEffect, useState } from "react";
import { Chip, Box, Button, Skeleton } from "@mui/material";
import { ICalendarEvent } from "../../components/common/Calendar/ICalendarProps";
import { useGetCalendarEvents, useGetPolicies } from "./actions";
import { colourPairs } from "../common/Calendar/helpers";
import { BasicTimeOffPolicyDto } from "../../API/time-off-service";
import { IReadOnlyCalendarProps } from "./IReadOnlyCalendarProps";
import { CARD_BOX_SHADOW, LIGHT_BG_COLOR, SPACING_EXTRA_SMALL, SPACING_MEDIUM, SPACING_SMALL } from '../../utils/cssUtils';
import { useTranslation } from 'react-i18next';
import { TranslationKeyEnum } from '../../features/translations/TranslationKeyEnum';
import { getTextTransformationAccordingToLanguage } from '../../features/translations/helpers';
import { getUserIdFromStorage } from '../../utils/storageUtils/storageUtils';

export default ({ skeletonWidth }: IReadOnlyCalendarProps): JSX.Element => {
    const { t } = useTranslation();
    const getCalendarEvents = useGetCalendarEvents();
    const getPolicies = useGetPolicies();

    const [events, setEvents] = useState<ICalendarEvent[]>([]);
    const [policies, setPolicies] = useState<BasicTimeOffPolicyDto[]>([]);
    const [calendarLoading, setCalendarLoading] = useState<boolean>(true);
    const [isCreateTimeOffOpen, setIsCreateTimeOffOpen] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            Promise.all([getCalendarEvents(), getPolicies()]).then((values) => {
                setEvents(values[0]);
                setPolicies(values[1]);
            }).then(() => setCalendarLoading(false));
        }
        fetchData();
    }, [])

    return !calendarLoading
        ? <Box display="flex" flexDirection="column" gap={SPACING_SMALL}>
            <Button variant="contained" size="small" startIcon={<AddIcon />} sx={{ marginLeft: "auto", backgroundColor: LIGHT_BG_COLOR, textTransform: getTextTransformationAccordingToLanguage() }} onClick={() => setIsCreateTimeOffOpen(true)}>
                {t(TranslationKeyEnum.requestTimeOff)}
            </Button>
            <Box boxShadow={CARD_BOX_SHADOW}><Calendar events={events} /></Box>
            <Box>
                {
                    policies.map(policy =>
                        <Chip
                            sx={{ marginRight: SPACING_EXTRA_SMALL, marginBottom: SPACING_EXTRA_SMALL }}
                            icon={<CircleIcon style={{ color: colourPairs[policy.color!.toString()].dot }} />}
                            label={policy.name}
                            variant="outlined"
                        />)
                }
            </Box>
            {
                isCreateTimeOffOpen && <CreateTimeOffRequestDialog userId={getUserIdFromStorage()!} isOpen={isCreateTimeOffOpen} setIsOpen={setIsCreateTimeOffOpen} isForUser={false} />
            }
        </Box>
        : <Box>
            <Skeleton variant="rectangular" height={SPACING_MEDIUM} width="6vw" sx={{ marginLeft: "auto", marginBottom: SPACING_SMALL }} />
            <CalendarSkeleton skeletonWidth={skeletonWidth} />
        </Box>
}