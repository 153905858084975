import React from "react";
import NavbarHeader from "../NavbarHeader/NavbarHeader";
import NavbarItems from "../NavbarItems/NavbarItems";
import UserMenu from "../UserMenu/UserMenu";
import { Drawer, Box } from '@mui/material';
import { IHamburgerMenu } from "./IHamburgerMenu";
import { SPACING_EXTRA_SMALL } from "../../../utils/cssUtils";

export default function HamburgerMenu({ hasAdminPermission, hasApproverPermission, hasWorkOrdersPermission, currentTab, setCurrentTab, isHamburgerMenuOpen, setIsHamburgerMenuOpen }: IHamburgerMenu) {
    const [isUserMenuOpen, setIsUserMenuOpen] = React.useState<boolean>(false);
    const [isLanguageMenuOpen, setIsLanguageMenuOpen] = React.useState<boolean>(false);
    return (
        <>
            <Drawer open={isHamburgerMenuOpen} onClose={() => setIsHamburgerMenuOpen(false)} PaperProps={{ sx: { width: { sm: "50vw", xs: "80vw" } } }}>
                <Box marginLeft={SPACING_EXTRA_SMALL}>
                    <NavbarHeader isHamburgerMenuOpen={isHamburgerMenuOpen} setIsHamburgerMenuOpen={setIsHamburgerMenuOpen} setCurrentTab={setCurrentTab} isMobile={true} />
                    <NavbarItems
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        hasApproverPermission={hasApproverPermission}
                        hasWorkOrdersPermission={hasWorkOrdersPermission}
                        setIsHamburgerMenuOpen={setIsHamburgerMenuOpen}
                        isUserMenuOpen={isUserMenuOpen}
                        setIsUserMenuOpen={setIsUserMenuOpen}
                        setIsLanguageMenuOpen={setIsLanguageMenuOpen}
                        isMobile={true}
                    />
                    <UserMenu
                        isUserMenuOpen={isUserMenuOpen}
                        setIsUserMenuOpen={setIsUserMenuOpen}
                        isLanguageMenuOpen={isLanguageMenuOpen}
                        setIsLanguageMenuOpen={setIsLanguageMenuOpen}
                        hasAdminPermission={hasAdminPermission}
                        setCurrentTab={setCurrentTab}
                        isMobile={true}
                        setIsHamburgerMenuOpen={setIsHamburgerMenuOpen}
                    />
                </Box>
            </Drawer>
        </>
    )
}