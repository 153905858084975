import { AxiosRequestConfig } from "axios";
import { getApiKeyFromStorage, getTokenFromStorage } from "../utils/storageUtils/storageUtils";
import appSettings from "../settings/appSettings";
import { getLanguage } from "../features/translations/i18n";

export abstract class BaseClass {
    protected async transformOptions(originalOptions: AxiosRequestConfig<any>): Promise<AxiosRequestConfig> {
		const token =  getTokenFromStorage();
		const apiKey = getApiKeyFromStorage();
		const language = getLanguage();
		originalOptions.withCredentials = true;
		
        return Promise.resolve({
			...originalOptions,
			headers: {
				...originalOptions.headers,
				"Authorization": `Bearer ${token}`,
				"X-API-Key": apiKey ?? '',
				"Accept-Language": language,
			},
        });
	}

	protected getBaseUrl(defaultUrl: string, baseUrl?: string): string {
		return appSettings.getApiUrl()
    }

	protected getNotificationsServiceBaseUrl(defaultUrl: string, baseUrl?: string): string {
		return appSettings.getNotificationsApiUrl()
    }
}