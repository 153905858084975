import React from 'react';
import CalendarIcon from '../CalendarIcon/CalendarIcon'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Typography, Tooltip } from '@mui/material'
import { BankHolidayDto } from '../../../API/time-off-service';
import { SPACING_SMALL } from '../../../utils/cssUtils'
import { DateRange } from '../../../components/common/DateRange/DateRange';
import { useTranslation } from 'react-i18next';
import { TranslationKeyEnum } from '../../../features/translations/TranslationKeyEnum';
import { getBankHolidaysForUser } from '../helpers';
import { IMultipleDaysTimeOffCard } from './IMultipleDaysTimeOffCard';

export default function MultipleDaysTimeOffCard({ userId, startDate, endDate, policyName }: IMultipleDaysTimeOffCard) {
    const { t } = useTranslation();
    const [userBankHolidays, setUserBankHolidays] = React.useState<BankHolidayDto[]>([])

    React.useEffect(() => {
        const fetchBankHolidays = async () => {
            let holidays;
            if (!userId) return;
            holidays = await getBankHolidaysForUser(userId);
            if (!holidays) return;
            setUserBankHolidays(holidays);
        }
        fetchBankHolidays();
    }, []);

    const getNumberOfDays = (range: DateRange): string => {
        let numberOfDays = range.numberOfBusinessDaysInBetween();
        for (const holiday of userBankHolidays as BankHolidayDto[]) {
            if (range.startDate > holiday.endDate!) break;
            let holidayRange = new DateRange(holiday.startDate!, holiday.endDate!);
            if (range.isOverlapped(holidayRange))
                numberOfDays -= range.numberOfBusinessDaysThatOverlapWith(holidayRange);
        }
        return numberOfDays.toString();
    }

    return (
        <Box display="flex" flexDirection={{ xl: "row", lg: "row", md: "column", sm: "row", xs: "column" }}>
            <Box display="flex">
                <CalendarIcon date={startDate} />
                <ArrowForwardIcon sx={{ margin: { xl: `auto ${SPACING_SMALL}`, lg: "auto .4vh", md: "auto .4vh", sm: "auto .4vh", xs: "auto .4vh" } }} fontSize="large" />
                <CalendarIcon date={endDate} />
            </Box>
            <Box marginLeft={{ xl: SPACING_SMALL, lg: SPACING_SMALL, md: "0vh", sm: SPACING_SMALL, xs: "0vh" }} display="flex" flexDirection="column" justifyContent="center">
                <Tooltip title={policyName} placement="top-start">
                    <Typography variant="h6" textOverflow="ellipsis" width={{ xl: "10vw", lg: "10vw", md: "20vw", sm: "26vw", xs: "60vw" }} whiteSpace="nowrap" overflow="hidden">
                        {policyName}
                    </Typography>
                </Tooltip>
                <Typography variant="body2">{getNumberOfDays(new DateRange(startDate, endDate))} {t(TranslationKeyEnum.workDays)} </Typography>
            </Box>
        </Box>
    )
}