import { useGetReservationsByRoom, useGetReservationsByRoomFromExternalView } from "../../API/work-order-actions";
import { ReservationEventDto } from "../../API/work-order-service";
import { ICalendarEvent } from "../common/Calendar/ICalendarProps";
import { mapReservationVMsToCalendarEvents } from "../common/Calendar/helpers";

export function useGetCalendarEventsForRoom(isExternalView: boolean): (id: string) => Promise<[ICalendarEvent[], ReservationEventDto[]]> {
    const getReservationsByRoom = useGetReservationsByRoom();
    const getReservationsByRoomFromExternalView = useGetReservationsByRoomFromExternalView();
    const getReservationsByRoomAction = isExternalView ? getReservationsByRoomFromExternalView : getReservationsByRoom;

    async function getWeekcalendarEventsForAllWorkOrders(id: string): Promise<[ICalendarEvent[], ReservationEventDto[]]> {
        const reservations = await getReservationsByRoomAction(id);
        const reservationEvents = reservations && mapReservationVMsToCalendarEvents(reservations)
        return [reservationEvents, reservations];
    }

    return getWeekcalendarEventsForAllWorkOrders;
}