import { ResponseMessage, UpdatePasswordRequest, UserClient } from "../../API/identity-service";
import { useTryCatchJsonByAction } from "../../utils/fetchUtils";
const client = new UserClient();

export function useUpdatePasswordAction() : (currentPassword: string, newPassword: string) => Promise<ResponseMessage> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function updatePassword(currentPassword: string, newPassword: string): Promise<ResponseMessage> {
        const bindedAction = updatePasswordAction.bind(null, currentPassword, newPassword);
        const updatePasswordResponse = await tryCatchAction(bindedAction);
        return updatePasswordResponse.response;
    }
    return updatePassword;
}

export const updatePasswordAction = async (currentPassword: string, newPassword: string): Promise<ResponseMessage> => {
	let request = new UpdatePasswordRequest({
        currentPassword: currentPassword,
        newPassword: newPassword,
    });
    return await client.updatePassword(request);
}