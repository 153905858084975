import React from 'react';
import { useGetLastArticles } from '../../../API/newsFeedActions';
import { ArticleVM } from '../../../API/user-management-service';
import { Box, Avatar, Typography, Skeleton } from '@mui/material';
import { PRIMARY_BG_COLOR, CARD_BOX_SHADOW, SPACING_SMALL, CARD_FULL_BODY_HEIGHT, SPACING_EXTRA_SMALL, DOUBLE_CARD_BODY_SIZE, SPACING_MEDIUM, BORDER_RADIUS_MEDIUM } from '../../../utils/cssUtils';
import { useTranslation } from 'react-i18next';
import { TranslationKeyEnum } from '../../../features/translations/TranslationKeyEnum';

const NUMBER_OF_ARTICLES = 3;

export const NewsFeed = () => {
    const { t } = useTranslation();
    const getLastArticles = useGetLastArticles();
    const [articles, setArticles] = React.useState<ArticleVM[]>([]);
    const [isArticlesDataLoading, setIsArticleDataLoading] = React.useState<boolean>(true);

    React.useEffect(() => {
        let fetchData = async () => {
            setIsArticleDataLoading(true);
            let lastArticles = await getLastArticles(NUMBER_OF_ARTICLES);
            if (!lastArticles) return setIsArticleDataLoading(false);
            setArticles(lastArticles);
            setIsArticleDataLoading(false);
        };
        fetchData();
    }, []);

    return (
        <>
            {
                isArticlesDataLoading
                    ? <Skeleton sx={{ width: "150px", height: "60px" }} />
                    : articles.length > 0
                    && <Typography variant="h5" sx={{ marginBottom: SPACING_EXTRA_SMALL }}>{t(TranslationKeyEnum.newsFeed)}</Typography>
            }
            {
                isArticlesDataLoading
                    ? <Box display="flex" flexDirection="column" gap={SPACING_SMALL}>
                        <Skeleton variant="rectangular" sx={{ minHeight: { xl: CARD_FULL_BODY_HEIGHT, lg: CARD_FULL_BODY_HEIGHT, md: DOUBLE_CARD_BODY_SIZE, sm: "60vh", xs: "60vh" } }} />
                        <Skeleton variant="rectangular" sx={{ minHeight: { xl: CARD_FULL_BODY_HEIGHT, lg: CARD_FULL_BODY_HEIGHT, md: DOUBLE_CARD_BODY_SIZE, sm: "60vh", xs: "60vh" } }} />
                    </Box>
                    : articles?.map(article => {
                        return <Box display="flex" flexDirection="column" justifyContent="center" gap={SPACING_SMALL}
                            sx={{
                                borderRadius: BORDER_RADIUS_MEDIUM,
                                boxShadow: CARD_BOX_SHADOW,
                                padding: SPACING_MEDIUM,
                                backgroundColor: PRIMARY_BG_COLOR,
                                marginBottom: SPACING_SMALL,
                                minHeight: { xl: CARD_FULL_BODY_HEIGHT, lg: CARD_FULL_BODY_HEIGHT, md: DOUBLE_CARD_BODY_SIZE, sm: "60vh", xs: "60vh" },
                            }}>
                            <Typography variant="h6">{article.title}</Typography>
                            <Box display="flex" alignItems="center" gap={SPACING_EXTRA_SMALL}>
                                <Avatar src={`data:image/png;base64,${article.image}`} sx={{ width: 26, height: 26 }} variant="rounded" />
                                <Typography variant="body1" color="grey">{article.description}</Typography>
                            </Box>
                            <Typography variant="body1">{article.body}</Typography>
                        </Box>
                    })
            }
        </>
    );
}