import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, Radio, RadioGroup, FormControlLabel, FormControl, Typography, FormHelperText, Button } from '@mui/material';
import { TranslationKeyEnum } from '../../../features/translations/TranslationKeyEnum';
import { ICON_COLOR, LIGHT_BG_COLOR, SPACING_EXTRA_SMALL, SPACING_MEDIUM, SPACING_SMALL } from '../../../utils/cssUtils';
import { useTranslation } from 'react-i18next';
import { WeekCalendarFilltersDialogProps } from './WeekCalendarFilltersDialogProps';
import { FilterOptionEnum } from '../ReadOnlyWeekCalendar';

export default function WeekCalendarFilltersDialog({ open, setOpen, filter, setFilter, selectedFilter, setSelectedFilter }: WeekCalendarFilltersDialogProps) {
    const { t } = useTranslation();

    const handleClose = () => {
        setOpen(false);
        setFilter(selectedFilter);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setFilter((event.target as HTMLInputElement).value as FilterOptionEnum);

    const handleSaveFilters = () => {
        setSelectedFilter(filter);
        setOpen(false);
    }

    return (
        <Dialog open={open} onClose={handleClose}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: { xl: "30vw", lg: "40vw", md: "50vw", sm: "70vw", xs: "90vw" },
                    }
                },
            }}>
            <Box sx={{ cursor: "pointer", position: "absolute", right: "0", padding: SPACING_MEDIUM }} onClick={handleClose}>
                <CloseIcon sx={{ color: ICON_COLOR }} />
            </Box>
            <Box padding={SPACING_MEDIUM} display="flex" flexDirection="column" gap={SPACING_MEDIUM}>
                <Typography variant="h5">{t(TranslationKeyEnum.filters)}</Typography>
                <FormControl>
                    <Typography variant="body2" marginBottom={SPACING_SMALL}>{t(TranslationKeyEnum.displayInformation)}</Typography>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={filter}
                        onChange={handleChange}
                    >
                        <Box display="flex" alignItems="center">
                            <FormControlLabel value={FilterOptionEnum.ShowAllUsers} control={<Radio />} label={t(TranslationKeyEnum.showAllUsers)} />
                            <Typography variant="body1" sx={{ color: "grey", position: "relative", right: SPACING_EXTRA_SMALL }}>({t(TranslationKeyEnum.default)})</Typography>
                        </Box>
                        <FormHelperText sx={{ width: "86%", margin: "0 auto", marginTop: "-1vh" }}>{t(TranslationKeyEnum.showAllUsersDescription)}</FormHelperText >
                        <FormControlLabel value={FilterOptionEnum.ShowAll} control={<Radio />} label={t(TranslationKeyEnum.showWorkOrders)} />
                        <FormControlLabel value={FilterOptionEnum.ShowBusyUsers} control={<Radio />} label={t(TranslationKeyEnum.showUsersWithWorkOrder)} />
                        <FormHelperText sx={{ width: "86%", margin: "0 auto", marginTop: "-1vh" }}>{t(TranslationKeyEnum.showUsersWithWorkOrderDescription)}</FormHelperText >
                        <FormControlLabel value={FilterOptionEnum.ShowFreeUsers} control={<Radio />} label={t(TranslationKeyEnum.showAvailableUsers)} />
                        <FormHelperText sx={{ width: "86%", margin: "0 auto", marginTop: "-1vh" }}>{t(TranslationKeyEnum.showAvailableUsersDescription)}</FormHelperText >
                    </RadioGroup>
                </FormControl>
                <Button variant="contained" sx={{ marginLeft: "auto", backgroundColor: LIGHT_BG_COLOR }} onClick={() => handleSaveFilters()}>{t(TranslationKeyEnum.saveFilters)}</Button>
            </Box>
        </Dialog>
    )
}