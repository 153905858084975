import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import i18n from '../../features/translations/i18n';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useTranslation } from 'react-i18next';
import { GridActionsCellItem, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { LanguageEnum } from '../../features/translations/LanguageEnum';
import { ROWS_PER_PAGE_OPTIONS } from '../common/Constants/PaginationConstants';
import { srRS } from '../../features/translations/DataGrid/srRS';
import { Tooltip, Box } from '@mui/material';
import { TranslationKeyEnum } from '../../features/translations/TranslationKeyEnum';
import { SPACING_SMALL } from '../../utils/cssUtils';
import { StyledDataGrid } from '../StyledDataGrid/StyledDataGrid';
import { useActivateTypeOfWork, useGetTypesOfWorkByStatus } from '../../API/work-order-actions';
import { StatusEnum, TypeOfWorkDto, UpdateStatusTypeOfWorkRequest } from '../../API/work-order-service';
import { IDeactivatedWorkOrderTypesProps } from './IDeactivatedWorkOrderTypesProps';

export default function DeactivatedWorkOrderTypes({ rerenderTypesOfWork, setRerenderTypesOfWork }: IDeactivatedWorkOrderTypesProps) {
    const { t } = useTranslation();
    const getDeactivatedTypeOfWorks = useGetTypesOfWorkByStatus();
    const activateTypeOfWork = useActivateTypeOfWork();

    const [page, setPage] = React.useState<number>(0);
    const [pageSize, setPageSize] = React.useState<number>(ROWS_PER_PAGE_OPTIONS[0]);
    const [rows, setRows] = React.useState<TypeOfWorkDto[]>([]);
    const [isDeactivatedTypeOfWorksDataLoading, setIsDeactivatedTypeOfWorksDataLoading] = React.useState<boolean>(true);
    const [rerender, setRerender] = React.useState<boolean>(false);

    const handlePaginationModelChange = (model: GridPaginationModel) => {
        setPage(model.page);
        setPageSize(model.pageSize);
    }

    React.useEffect(() => {
        const fetchData = async () => {
            let getTypesOfWork = await getDeactivatedTypeOfWorks(StatusEnum.Inactive);
            if (!getTypesOfWork) return;
            setRows(getTypesOfWork);
            setIsDeactivatedTypeOfWorksDataLoading(false);
        }
        fetchData();
    }, [rerender, rerenderTypesOfWork])

    const handleActivateTypeOfWork = async (params: any) => {
        let activate = await activateTypeOfWork(new UpdateStatusTypeOfWorkRequest({ id: params.id }));
        if (!activate) return;
        setRerender(!rerender);
        setRerenderTypesOfWork(!rerenderTypesOfWork)
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Id', width: 130 },
        { field: 'name', headerName: t(TranslationKeyEnum.name), width: 130, editable: true, flex: 1 },
        {
            field: 'actions', type: "actions", sortable: false, width: 80, minWidth: 80, flex: .3, align: "right", renderCell: (params: any) => {
                return <Box sx={{ display: 'flex', marginLeft: "auto", flexDirection: 'row', justifyContent: 'space-around' }}>
                    <Tooltip title={t(TranslationKeyEnum.activate)}><GridActionsCellItem icon={<RestartAltIcon />} label={t(TranslationKeyEnum.delete)} onClick={() => handleActivateTypeOfWork(params)} /></Tooltip>
                </Box>
            }
        }
    ];

    return (
        <Box marginTop={SPACING_SMALL}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="h5">{t(TranslationKeyEnum.deactivatedTypesOfWork)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <StyledDataGrid
                        columnVisibilityModel={{
                            id: false,
                        }}
                        localeText={i18n.language === LanguageEnum.Serbian ? srRS.components.MuiDataGrid.defaultProps.localeText : undefined}
                        autoHeight={true}
                        rows={rows}
                        columns={columns}
                        paginationModel={{ page, pageSize }}
                        onPaginationModelChange={handlePaginationModelChange}
                        pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
                        pagination
                        hideFooterSelectedRowCount={true}
                        loading={isDeactivatedTypeOfWorksDataLoading}
                        disableRowSelectionOnClick
                    />
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}
