export const TRANSLATIONS_SR = {
    panosLabel: 'Panos Intranet',
    signIn: 'Prijavi se',
    email: 'E-mail',
    emailPlaceholder: 'Unesite E-mail',
    emailError: 'E-mail adresa nije validna',
    password: 'Lozinka',
    passwordMustMatch: 'Lozinke moraju da se podudaraju',
    passwordMustntMatch: 'Lozinka ne sme da odgovara vašoj trenutnoj lozinki',
    passwordPlaceholder: 'Unesite lozinku',
    passwordMax: 'Maksimalna dužina lozinke iznosi {{maximumLength}} karaktera',
    passwordMin: 'Minimalna dužina lozinke iznosi {{minimumLength}} karaktera',
    currentPassword: 'Trenutna lozinka',
    currentPasswordPlaceholder: 'Unesite svoju trenutnu lozinku',
    newPassword: 'Nova lozinka',
    newPasswordPlaceholder: 'Unesite novu lozinku',
    confirmNewPassword: 'Potvrda nove lozinke',
    confirmNewPasswordPlaceholder: 'Potvrdite svoju lozinku',
    firstName: 'Ime',
    lastName: 'Prezime',
    name: 'Naziv',
    phone: 'Telefon',
    invalidPhoneNumber: 'Format broja telefona nije ispravan',
    address: 'Adresa',
    dateOfBirth: 'Datum rođenja',
    invalidDateOfBirth: 'Nije moguće izabrati datum iz budućnosti',
    startWorkingDate: 'Datum početka rada',
    role: 'Rola',
    actions: 'Akcije',
    status: 'Status',
    required: 'Obavezno polje',
    submit: 'Potvrdi',
    home: 'Početna',
    people: 'Zaposleni',
    logout: 'Izloguj se',
    copyEmail: 'Kopiraj E-mail',
    emailCopied: 'E-mail kopiran!',
    searchEmployee: 'Pretraži po imenu i prezimenu...',
    office: 'Kancelarija',
    offices: 'Kancelarije',
    position: 'Pozicija',
    positions: 'Pozicije',
    employees: 'Zaposleni',
    employeesPageDescription: 'Ovde su svi zaposleni u vašoj kompaniji',
    noUsers: 'Trenutno ne postoji nijedan korsinik sa izabranim karakteristikama',
    noOffice: 'Trenutno ne postoji nijedna kreirana kancelarija',
    noUsersInOffice: 'Trenutno nema zaposlenih sa izabranim filterima u ovoj kancelariji',
    resetFilters: 'Resetuj filtere',
    loggedIn: 'Uspešno ste ulogavani!',
    somethingWentWrong: 'Nešto nije u redu',
    somethingWrongWithRequest: "Nešto nije u redu sa pozivom",
    userUnauthorizedError: 'Morate biti autentifikovani da biste pristupili ovom resursu',
    userForbiddenError: 'Nažalost, nemate pristup ovom resursu',
    actionCompleted: 'Akcija uspešno izvršena',
    adminConsole: 'Admin Konzola',
    adminConsoleDescription: 'Ovo je Vaša administratorska konzola, pazite šta menjate',
    mainInfo: 'Osnovne Informacije',
    additionalInfo: 'Dodatne Informacije',
    permissions: 'Permisije',
    vacation: 'Slobodni dani',
    back: 'Nazad',
    next: 'Nastavi',
    add: 'Dodaj',
    earlierPositions: 'Ranije pozicije',
    positionName: 'Naziv pozicije',
    workStartDate: 'Datum početka rada',
    workEndDate: 'Datum završetka rada',
    startDate: 'Datum početka',
    endDate: 'Datum završetka',
    addOffice: 'Dodaj kancelariju',
    addPosition: 'Dodaj poziciju',
    removePosition: 'Ukloni poziciju',
    selectEmployeePermissions: 'Izaberi permisije zaposlenog',
    selectApprover: 'Izaberi nadređenog zaposlenom',
    selectPolicy: 'Izaberi polisu',
    selectColor: 'Izaberi boju',
    yearsOfService: 'Godine radnog staža',
    edit: 'Izmeni',
    delete: 'Obriši',
    save: 'Sačuvaj',
    numberOfEmployees: 'Broj zaposlenih',
    confirmDelete: 'Da li ste sigurni?',
    deleteEmployeeDescription: 'Uklonićete korisnika {{fullname}}. Da li ste sigurni? Imate mogućnost da obnovite uklonjenog korisnika kasnije ako to želite.',
    deleteOfficeDescription: 'Kancelarija može biti obrisana samo ukoliko nijedan korisnik nije dodat u datu kancelariju.',
    deletePositionDescription: 'Pozicija može biti obrisana samo ukoliko nijedan korisnik nije dodat u datu poziciju.',
    deletePolicyDescription: 'Polisa može biti obrisana samo ukoliko nijedan korisnik nije dodat u datu polisu.',
    deleteUserFromPolicy: 'Zaposleni će biti uklonjen iz polise',
    deleteGroupDescription: 'Grupa može biti obrisana samo ukoliko nijedan korisnik nije dodat u datu grupu.',
    deleteBankHolidayDescription: 'Praznik može biti obrisan samo ukoliko nijedan korisnik nije dodat u dati praznik.',
    uploadImage: 'Dodaj sliku',
    removeImage: 'Ukloni sliku',
    invalidImageFormat: 'Format slike nije podržan. Podržani formati su .jpg, .jpeg, .png .webp',
    maximumImageSize: 'Datoteka je prevelika. Veličina slike ne sme biti veća od 2MB',
    ok: 'OK',
    yesRemove: 'Da, obriši',
    cancel: 'Poništi',
    calendar: 'Kalendar',
    viewCalendar: 'Pogledaj kalendar',
    selectedDateRange: 'Izabrani datumi',
    timeOffTab: 'Slobodni dani',
    timeOff: 'Slobodan dan',
    workOrdersTab: 'Radni nalozi',
    workOrder: 'Radni nalog',
    workOrdersTabContent: 'Trenutno nema radnih naloga',
    pendingRequestsTab: 'Zahtevi na čekanju',
    pendingRequestsPageDescription: 'Zahtevi na čekanju u Vašoj kompaniji.',
    close: 'Zatvori',
    officeNameAndAddressEmpty: 'Molimo unesite naziv i adresu kancelarije',
    finishOfficeCreation: 'Nije moguće dodati novu kancelariju dok se ne završi sa dodavanjem prethodne',
    positionNameEmpty: 'Naziv pozicije ne sme biti prazan',
    samePositionName: 'Naziv pozicije ne može biti isti kao pre izmene',
    currentPosition: 'Trenutna pozicija',
    noCurrentPositionDefinedLabel: 'Jedna pozicija mora biti definisana kao trenutna pozicija',
    noCurrentPositionDefinedDescription: 'Molimo Vas da izaberete jednu od pozicija kao trenutnu poziciju i probate ponovo',
    createRequest: 'Zatraži odsustvo',
    policy: 'Polisa',
    policies: 'Polise',
    comment: 'Komentar',
    description: 'Opis',
    numberOfBusinessDays: 'Broj radnih dana',
    zeroWorkDaysSelected: 'Morate izabrati više od 0 radnih dana.',
    wantToProceed: 'Da li ste sigurni da želite da nastavite?',
    groupRequestsOverlap: 'Traženi datumi se poklapaju sa drugim članovima Vaše grupe.',
    usersGroupRequestsOverlap: 'Traženi datumi se poklapaju sa drugim članovima korisnikove grupe.',
    noAvailableDays: 'Nemate dovoljno dostupnih dana na izabranoj polisi.',
    alreadySelectedDates: 'Zahtev za tražene datume je već poslat.',
    dependantPoliciesInfo: 'Ukoliko su obe polise validne za izabrane datume, slobodni dani će prvo biti uzeti sa {{parent}} polise i onda sa {{child}} polise.',
    policyValidRange: 'Izabrana polisa je validna od {{startDate}} do {{endDate}}.',
    addedDays: 'Broj dodatih dana',
    policySummary: 'Izveštaj',
    changedBy: 'Promenu izvršio',
    daysChange: 'Broj dana',
    daysChangePolicySummary: 'Promena u danima',
    availableDays: 'Dostupni dani',
    totalDays: 'Ukupan broj dana',
    usedDays: 'Iskorišćeni dani',
    modificationDate: 'Datum izmene',
    pendingRequests: 'Zahtevi na čekanju',
    pendingRequestsTimeOff: 'Zahtevi na čekanju za slobodne dane',
    myRequests: 'Moji zahtevi',
    open: 'Otvori',
    leaveRequest: 'Zahtev za odsustvo',
    cancelRequest: 'Zahtev za otkazivanje',
    minimumInputLength: 'Minimalna dužina inputa za {{fieldName}} polje je {{minimumLength}} karaktera',
    maximumInputLength: 'Maksimalna dužina inputa za {{fieldName}} polje je {{maximumLength}} karaktera',
    minimumInputValue: 'Minimalna vrednost inputa za {{fieldName}} polje je {{minimumLength}}',
    policyLoading: 'Polise se učitavaju...',
    addUser: 'Dodaj korisnika',
    addEmployee: 'Dodaj novog zaposlenog',
    users: 'Korisnici',
    groups: 'Grupe',
    groupsLoading: 'Grupe se učitavaju...',
    addOrChangePolicy: 'Dodaj/Izmeni polisu',
    addPolicy: 'Dodaj polisu',
    policyName: 'Ime polise',
    policyDependsOn: 'Zavisi od',
    policyAllowNegative: 'Dozvoljen odlazak u minus',
    policyInfiniteDays: 'Beskonačni dani',
    policyStartDate: 'Datum početka',
    policyEndDate: 'Datum završetka',
    policyColor: 'Boja',
    groupHasMembers: 'Grupa se ne može izbrisati sve dok u njoj ima članova',
    addGroup: 'Dodaj grupu',
    editGroup: 'Izmeni grupu',
    editEmployee: 'Izmeni infomacije o zaposlenom',
    mustBePresentLabel: 'Broj članova koji moraju biti prisutni',
    mustBePresentError: 'Broj članova koji moraju biti prisutni mora biti manji od ukupnog broja članova',
    requirement: 'Uslov',
    bankHolidays: 'Državni praznici',
    bankHolidaysLoading: 'Državni praznici se učitavaju...',
    editBankHoliday: 'Izmeni državni praznik',
    addBankHoliday: 'Dodaj državni praznik',
    phoneNumber: 'Broj telefona',
    birthdate: 'Rođendan',
    dateOfEmployment: 'Datum zapošljavanja',
    notifications: 'Notifikacije',
    markAllAsRead: 'Označi sve kao pročitano',
    requestNotExist: 'Birani zahtev više ne postoji',
    allNotifications: 'To su sve Vaše notifikacije u prethodnih 30 dana',
    dashboard: 'Naslovna',
    search: 'Pretraži',
    you: 'Ti',
    company: 'Kompanija',
    companyName: 'AntyPlanner',
    language: 'Jezik',
    welcomeBack: 'Dobrodošao nazad, {{firstName}}',
    upcomingTimeOff: 'Predstojeća odsustva',
    upcomingTimeOffToday: 'Danas',
    upcomingTimeOffNextDays: 'Narednih 7 dana',
    upcomingTimeOffLinkLabel: 'Pogledaj ko je odsutan ove nedelje',
    whatIsNew: 'Ovo su novosti u {{companyName}}',
    workDay: 'radni dan',
    workDays: 'radnih dana',
    workDaysRequestHistory: 'Radni dani',
    nextTimeOff: 'Naredni godišnji odmori',
    timeOffCardDescription: 'Lista predstavlja Vaše nadolazeće slobodne dane',
    viewAllTimeOffRequests: 'Pregled svih zahteva',
    sayCongrats: 'Čestitaj',
    noPendingNotifications: 'Nemate notifikacija na čekanju.',
    notificationsUnavailable: 'Notifikacije su trenutno nedostupne.',
    remove: 'Obriši',
    userLabel: 'Korisnik',
    locationLabel: 'Lokacija',
    hireDateLabel: 'Datum zaposlenja',
    all: "Sve",
    approver: 'Nadređeni',
    workInformation: 'Poslovne Informacije',
    basicInformation: 'Osnovne informacije u vezi sa pozicijom korisnika',
    personalInformation: 'Lične informacije',
    basicPersonalInformation: 'Lične informacije o korisniku',
    timeOffInformation: 'Koliko slobodnih dana je ostalo korisniku i istorija korisnikovih slobodnih dana',
    addTimeOffDays: 'Dodaj slobodne dane zaposlenom',
    daysPerPolicy: 'Ukupan broj dana',
    expiresOn: 'Važi do',
    date: 'Datum',
    available: 'Raspoloživo',
    employeeAvailable: 'Dostupan',
    today: 'Danas',
    taken: 'Iskorišćeno',
    workOrdersInfo: 'Informacije o radnim nalozima i danima koji su potrebni za njihovo izvršavanje',
    finished: 'završeno',
    canceled: 'otkazano',
    days: 'dani',
    daysRequested: 'Zahtevani dani',
    viewRequest: 'Vidi zahtev',
    viewCancellationRequest: 'Vidi zahtev za otkazivanje',
    upcomingHolidays: 'Predstojeći praznici',
    loginGreetings: 'Pozdrav, ko je to?',
    forgotPassword: 'Zaboravili ste lozinku?',
    forgotPasswordPlaceholder: 'Ako Vaš nalog postoji, poslaćemo Vam privremenu lozinku na Vašu adresu e-pošte',
    login: 'uloguj se',
    changePassword: 'Promena lozinke',
    requestTimeOff: 'Zahtev za odsustvo',
    createRequestLabel: 'Zahtev će biti prosleđen svima koji imaju dozvolu da odobre Vaše odsustvo, uključujući Vašeg supervizora {{approverFullname}}',
    createRequestForUserLabel: 'Zahtev za odsustvo za {{userFullname}} će biti napravljen i automatski odobren. Izabrani broj dana biće oduzet od broja raspoloživih dana.',
    typeOfAbsence: 'Tip odsustva',
    startingFrom: 'Od',
    ending: 'Do',
    policyNotEnoughDays: 'Nemate dovoljno slobodnih danana izabranom tipu odsustva',
    selectTypeOfAbsence: 'Izaberite tip odsustva',
    selectOffice: 'Izaberite kancelariju',
    selectPosition: 'Izaberite poziciju',
    requestOverlaps: 'Zahtev se preklapa sa ostalim zaposlenima iz Vaše grupe.',
    requestWorkDays: 'Zatraži {{workDays}} radnih dana',
    hooray: 'Hooray!',
    successfullRequest: 'Uspešno ste kreirali zahtev za odsustvo ({{numberOfDays}} radnih dana). Sada se možete opustiti i sačekati odobrenje Vašeg zahteva',
    done: 'gotovo',
    invalidStartDate: 'Nevalidan datum početka zahteva za odsustvo',
    invalidEndDate: 'Nevalidan datum završetka zahteva za odsustvo',
    userRequestedTimeOff: '{{fullname}} je zatražio odsustvo',
    userRequestedTimeOffCancellationFirstPart: '{{fullname}} želi da',
    userRequestedTimeOffCancellationSecondPart: 'otkaže',
    userRequestedTimeOffCancellationThirdPart: 'zahtev za odsustvo',
    decline: 'odbij',
    approve: 'odobri',
    approveCancellation: 'Potvrdi otkazivanje',
    relatedRequest: 'Ovaj zahtev koristi dve polise kombinovano pošto nije bilo dovoljno dana na izabranoj polisi',
    newsFeed: 'Najnovije vesti',
    requestPending: 'Zahtev čeka odobrenje',
    requestApproved: 'Odobren zahtev',
    requestAlreadyResolved: 'Zahtev je već rešen',
    numberOfDaysTaken: 'Broj uzetih dana',
    viewTimeOffMonthlySummary: 'Mesečni pregled iskorištenih dana',
    viewPolicySummary: 'Pregled polisa',
    deactivatedUsers: 'Deaktivirani korisnici',
    deactivatedPositions: 'Deaktivirane pozicije',
    deactivatedPolicies: 'Deaktivirane polise',
    activate: 'Aktiviraj',
    numberOfWorkingDays: 'Broj radnih dana',
    timeOffPolicy: 'Polisa slobodnih dana',
    removeRequest: 'Obriši zahtev?',
    cancellationRequest: 'Otkaži zahtev?',
    removeRequestText: 'Zahtev će biti obrisan. Da li ste sigurni da želite da nastavite?',
    cancellationRequestText: 'Zahtev za otkazivanje će biti prosleđen Vašem nadređenom. Da li želite da nastavite?',
    removeApproval: 'Da, obriši',
    cancellationRequestApproval: 'Da, poništi zahtev',
    myTimeOffRequests: 'Moji zahtevi za odsustvo',
    employeeTimeOffRequests: 'Zahtevi za odsustvo',
    allTimeOffRequests: 'Svi zahtevi za odsustvo',
    profile: 'Profil',
    noTimeOffs: 'Svi zaposleni su tu i spremni za posao!',
    typeOfWork: 'Tip posla',
    goingWith: 'Zajedno sa',
    monthlySummary: 'Mesečni pregled',
    monthlySummaryDescription: 'Ova lista predstavlja količinu posla koju si obavio/la ovoga meseca.',
    viewWorkOrderHistory: 'Pregled svih radnih naloga',
    noDoneWorkOrdersThisMonth: 'Nema završenih radnih naloga ovog meseca.',
    noWorkOrdersThisMonth: 'Nemate radnih naloga u ovom mesecu, možete se opustiti i popiti kafu.',
    viewUserMonthlySummary: 'Mesečni pregled po korisniku',
    manageWorkOrders: 'Upravljaj radnim nalozima',
    manageWorkOrdersLabel: 'Ovde se nalaze svi radni nalozi unutar kompanije.',
    viewMore: 'Vidi više',
    newWorkOrder: 'Novi radni nalog',
    summary: 'Pregled',
    selectGroup: 'Izaberi grupu',
    workOrdersTypes: 'Tipovi radnih naloga',
    addworkOrderType: 'Dodaj tip radnog naloga',
    finishWorkOrderTypeCreation: 'Nije moguće dodati novi tip radnog naloga dok se ne završi sa dodavanjem prethodnog',
    deleteWorkOrderTypeDescription: 'Da li ste sigurni da želite da obrišete izabrani tip radnog naloga?',
    workOrderNumber: 'Broj radnog naloga',
    workOrderNumberPlaceholder: 'Broj radnog naloga će biti autogenerisan',
    selectTypeOfWork: 'Izaberi tip posla',
    locationCode: 'Kod lokacije',
    coordinates: 'Koordinate',
    supervisors: 'Supervizori',
    selectSupervisors: 'Izaberi supervizore',
    createWorkOrderLabel: 'Notifikaciju o ovom radnom nalogu će dobiti svi uključeni.',
    assignedTo: 'Dodeljeno',
    mapLinkLabel: 'Link ka mapi će biti generisan ovde.',
    selectAssignees: 'Izaberi zaposlene',
    workOrderReceived: 'Vreme prijema radnog naloga',
    deviceToBeChanged: 'Uređaj koji je potrebno zameniti',
    orderSentBy: 'Zahtev poslao',
    deviceOnLocation: 'Lokacija uređaja',
    createWorkOrder: 'Kreiraj radni nalog',
    updateWorkOrder: 'Izmeni radni nalog',
    mustBeCompletedBy: 'Mora biti završeno do',
    sameWorkOrderTypeName: 'Naziv tipa radnog naloga ne može biti isti kao pre izmene',
    typeOfWorkNameEmpty: 'Naziv tipa radnog naloga ne sme biti prazan',
    deactivatedTypesOfWork: 'Deaktivirani tipovi radnih naloga',
    city: 'Grad',
    employeeDoesNotHaveApproverAssigned: 'Zaposleni nema dodeljenog nadređenog',
    requestComment: 'Komentar zahteva',
    responseComment: 'Komentar odobrenja',
    at: 'u',
    noNextWorkOrders: 'Nemate predstojećih radnih naloga.',
    daysOff: 'Slobodni dani',
    regularPolicy: 'Regularna polisa',
    filters: 'Filteri',
    displayInformation: 'Prikaz informacija',
    showWorkOrders: 'Prikaži radne naloge',
    default: 'Automatski izabrano',
    showUsersWithWorkOrder: 'Prikaži korisnike sa radnim nalozima',
    showUsersWithWorkOrderDescription: 'Prikaži korisnike koji imaju dodeljen radni nalog na izabrani dan.',
    showAvailableUsers: 'Prikaži dostupne zaposlene',
    showAvailableUsersDescription: 'Prikaži korisnike koji nemaju dodeljen radni nalog ili slobodan dan na izabrani dan.',
    showAllUsers: 'Prikaži sve korisnike',
    showAllUsersDescription: 'Prikaži sve korisnike i njihov radni status za svaki dan.',
    saveFilters: 'Primeni filter',
    currentSelectedFilter: 'Trenutno izabrani filter',
    meetingRooms: 'Sale za sastanke',
    finishMeetingRoomCreation: 'Nije moguće dodati novu salu za sastanke dok se ne završi sa dodavanjem prethodne',
    addMeetingRoom: 'Dodaj salu za sastanke',
    deactivatedMeetingRooms: 'Deaktivirane sale za sastanke',
    meetingRoomNameEmpty: 'Naziv sale za sastanke ne sme biti prazan',
    sameMeetingRoomName: 'Naziv sale za sastanak ne može biti isti kao pre izmene',
    selectRoom: 'Izaberi salu za sastanke',
    reserveRoom: 'Rezerviši salu',
    meetingRoom: 'Sala za sastanak',
    meetingOrganizer: 'Organizator',
    title: 'Naziv',
    addMeeting: 'Dodaj sastanak',
    personalNo: 'JMBG',
    passportNumber: 'Broj pasoša',
    passportValidUntil: 'Pasoš važi do', 
    contractExpiration: 'Datum isteka ugovora',
    idCardNumber: 'Broj lične karte',
    idCardValidUntil: 'Lična karta važi do',
    doctorAppointment: 'Lekarski pregled',
    certificateValidUntil: 'Sertifikat važi do',
    dragAndDrop: 'Prevuci ili dodaj fajl',
    medicalInsuranceNumber: 'LBO',
    religiousDay: 'Slava',
    employee: 'Zaposleni',
    newVersionPopup: 'Isprobaj novu verziju',
    scheduledMeetings: 'predstojeći sastanci',
    noscheduledMeetings: 'Nemate predstojećih sastanaka.',
    upcomingBirthdays: 'predstojeći rođendani',
    noUpcomingBirthdays: 'Nemate predstojećih rođendana.',
    approvesTimeOff: 'Odobrava odsustvo',
    additionalPersonalInformation: 'Dodatne informacije o korisniku',
    basicInformationTitle: 'Osnovne informacije',
    positionsInCompany: 'Pozicije u kompaniji',
    present: 'trenutno',
    documents: 'Dokumenti',
    copyPolicy: 'Kopiraj polisu',
    accessToApplication: 'Omogućiti pristup aplikaciji',
    copyBankHoliday: 'Kopiraj državni praznik',
    addArticle: 'Dodaj vest',
    articleTitle: 'Naslov',
    articleText: 'Tekst',
    deleteArticleDescription: 'Da li ste sigurni da želite da obrišete izabranu vest?',
    editArticle: 'Izmeni vest',
    userUpdatedSuccessfully: 'Korisnik je uspešno ažuriran',
    chooseFile: 'Izaberi fajl',
    maxFileSize: 'maksimalna veličina fajla je 2MB',
    acceptedExtensions: 'dozvoljene ekstenzije: .jpeg, .png, .jpg, .webp, .pdf, .docs, .docx, .txt, .xlsx, .xml i .csv',
    fileValidationMessage: 'Fajl {{fileName}} prevazilazi dozvoljenu veličinu ili poseduje nedozvoljenu ekstenziju.',
    duplicatefileValidationMessage: 'Fajl {{fileName}} je već otpremljen ili izabran.',
    openMap: 'Otvori mapu',
};