import React from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import CoffeeIcon from '@mui/icons-material/Coffee';
import MobiledataOffIcon from '@mui/icons-material/MobiledataOff';
import PopupNotification from '../PopupNotification/PopupNotification';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Avatar } from '@mui/material';
import { TranslationKeyEnum } from '../../features/translations/TranslationKeyEnum';
import { useNavigate } from 'react-router-dom';
import { useGetNotifications, useUpdateNotification, useMarkAllAsReadAction } from '../../API/notification-actions';
import { NotificationDto } from '../../API/notification-service';
import { getLanguage } from '../../features/translations/i18n';
import { getUserIdFromStorage } from '../../utils/storageUtils/storageUtils';
import { PRIMARY_BG_COLOR, CARD_BOX_SHADOW, DOUBLE_CARD_SIZE, BORDER_RADIUS_MEDIUM, SPACING_SMALL, CARD_HEADER_HEIGHT, LIGHT_BORDER, SPACING_EXTRA_LARGE, LIGHT_BG_COLOR, MIN_DOUBLE_CARD_SIZE, MIN_CARD_HEADER_HEIGHT, DOUBLE_CARD_BODY_SIZE, MIN_DOBULE_CARD_BODY_SIZE, SPACING_LARGE, SPACING_EXTRA_SMALL, DASHBOARD_ICON_SIZE, CARD_FOOTER_HEIGHT, MIN_CARD_FOOTER_HEIGHT } from '../../utils/cssUtils';
import { INotificationResponseModel } from './INotificationResponseModel';
import { getTextTransformationAccordingToLanguage } from '../../features/translations/helpers';
import Button from '@mui/material/Button';

function NotificationCenter() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const getNotification = useGetNotifications();
    const updateNotification = useUpdateNotification();
    const markAllAsReadNotifications = useMarkAllAsReadAction();

    const [rerender, setRerender] = React.useState(false);
    const [notificationsData, setNotifictionsData] = React.useState<INotificationResponseModel>({ notifications: [], success: false });
    const [popupNotifications, setPopupNotifications] = React.useState<NotificationDto[]>([]);
    const [currentPopupNotification, setCurrentPopupNotification] = React.useState<NotificationDto>();
    const [popupTitle, setPopupTitle] = React.useState<string>("");
    const [popupDescription, setPopupDescription] = React.useState<string>("");
    const [popupContent, setPopuContent] = React.useState<string[]>([]);
    const [isNotificationPopupOpen, setIsNotificationPopupOpen] = React.useState<boolean>(false);
    const [isNotificationsLoading, setIsNotificationsLoading] = React.useState<boolean>(true);
    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number;
        mouseY: number;
        index: number;
    } | null>(null);


    React.useEffect(() => {
        const fetchData = async () => {
            let getUserId = getUserIdFromStorage();
            if (!getUserId) return;
            let notificationsResponse = await getNotification(getUserId);
            if (!notificationsResponse) return;
            const notifications: NotificationDto[] = [];
            const popupNotifications: NotificationDto[] = [];
            notificationsResponse.notifications.forEach((notification: NotificationDto) => {
                if (notification.type !== "popup") notifications.push(notification);
                else popupNotifications.push(notification);
            });
            setNotifictionsData({ success: notificationsResponse.success, notifications: notifications });
            setPopupNotifications(popupNotifications);
            setIsNotificationsLoading(false);
        }
        fetchData();
    }, [rerender]);

    React.useEffect(() => {
        if (popupNotifications.length) {
            let currentNotification = popupNotifications[0];
            setCurrentPopupNotification(currentNotification);
            let { title, description, content } = JSON.parse(currentNotification.messages[getLanguage()]);
            setPopupTitle(title);
            setPopupDescription(description);
            setPopuContent(content);
            setIsNotificationPopupOpen(true);
        }
    }, [popupNotifications])


    const handleNotificationStatusChange = async (notifications: NotificationDto[], index: number) => {
        let update = await updateNotification(notifications[index]);
        update && setRerender(!rerender);
    }

    const handleAllNotificationStatusChange = async (notifications: NotificationDto[]) => {
        let update = await markAllAsReadNotifications(notifications.map(notification => notification._id));
        update && setRerender(!rerender);
    }

    const handleContextMenu = (event: React.MouseEvent, indexOfElement: number) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                    index: indexOfElement,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };

    const getCardBodyStyling = (notifications: NotificationDto[]) => {
        return notifications && notifications.length ? { display: "", alignItems: "", justifyContent: "", overflowY: "auto" } : { display: "flex", alignItems: "center", justifyContent: "center" }
    }

    const handlePopupClose = async () => {
        if (!currentPopupNotification) return;
        let update = await updateNotification(currentPopupNotification);
        if (!update) return;
        setCurrentPopupNotification(undefined);
        setRerender(!rerender);
    }

    const getNumberOfNotificationsLabel = (numberOfNotifications: number): string => {
        return numberOfNotifications > 0
            ? numberOfNotifications > 9
                ? "(9+)"
                : `(${numberOfNotifications})`
            : "";
    }

    return (
        <Box sx={{ height: DOUBLE_CARD_SIZE, minHeight: MIN_DOUBLE_CARD_SIZE, borderRadius: BORDER_RADIUS_MEDIUM, boxShadow: CARD_BOX_SHADOW, backgroundColor: PRIMARY_BG_COLOR, overflowY: "auto" }}>
            {/* Card Header */}
            <Box height={CARD_HEADER_HEIGHT} minHeight={MIN_CARD_HEADER_HEIGHT} display="flex" alignItems="center" justifyContent="center" borderBottom={LIGHT_BORDER}>
                <Typography sx={{ textTransform: "uppercase" }} variant="subtitle2">
                    {t(TranslationKeyEnum.notifications)} {getNumberOfNotificationsLabel(notificationsData?.notifications.length)}
                </Typography>
            </Box>
            {/* Card Body */}
            {
                isNotificationsLoading
                    ? <Box display="flex" alignItems="center" justifyContent="center" height={DOUBLE_CARD_BODY_SIZE} minHeight={MIN_DOBULE_CARD_BODY_SIZE}><CircularProgress /></Box>
                    : <Box height={DOUBLE_CARD_BODY_SIZE} minHeight={MIN_DOBULE_CARD_BODY_SIZE} sx={getCardBodyStyling(notificationsData.notifications)}>
                        {
                            notificationsData.success
                                ? notificationsData.notifications && notificationsData.notifications.length > 0
                                    ? <Box minHeight="90px">
                                        {
                                            notificationsData?.notifications?.filter(n => n.url !== "popup").map((notification: NotificationDto, index: number) =>
                                                <Box
                                                    sx={{ display: "flex", alignItems: "center", padding: SPACING_SMALL, height: SPACING_EXTRA_LARGE, minHeight: "86px" }}
                                                    borderBottom={LIGHT_BORDER}
                                                    onClick={(event) => { event.stopPropagation(); notification.url && navigate(notification.url); handleNotificationStatusChange(notificationsData.notifications, index) }}
                                                > 
                                                    <Avatar variant="rounded" sx={{ width: 28, height: 28 }} />
                                                    <Box width="80%" onContextMenu={(event) => handleContextMenu(event, index)} paddingLeft={SPACING_SMALL}>
                                                        <Typography variant="body2" color="grey" sx={{ cursor: "pointer" }}>{notification.messages && notification.messages[getLanguage()]}</Typography>
                                                    </Box>
                                                    <ArrowForwardIcon sx={{ color: LIGHT_BG_COLOR, cursor: "pointer", marginLeft: SPACING_SMALL }} />
                                                </Box>
                                            )}
                                    </Box>
                                    // No pending notifications
                                    : <Box marginBottom={SPACING_EXTRA_LARGE}>
                                        <Box display="flex" justifyContent="center" marginBottom={SPACING_EXTRA_SMALL}>
                                            <CoffeeIcon color="action" sx={{ fontSize: DASHBOARD_ICON_SIZE }} />
                                        </Box>
                                        <Typography variant="body2" textAlign="center" color="gray" padding={`0 ${SPACING_SMALL}`}>
                                            {t(TranslationKeyEnum.noPendingNotifications)}
                                        </Typography>
                                    </Box>
                                // Notification service unavailable
                                :
                                <Box marginBottom={SPACING_EXTRA_LARGE}>
                                    <Box display="flex" justifyContent="center" marginBottom={SPACING_EXTRA_SMALL}>
                                        <MobiledataOffIcon color="action" sx={{ fontSize: DASHBOARD_ICON_SIZE }} />
                                    </Box>
                                    <Typography variant="body2" textAlign="center" color="gray" padding={`0 ${SPACING_SMALL}`}>
                                        {t(TranslationKeyEnum.notificationsUnavailable)}
                                    </Typography>
                                </Box>
                        }
                    </Box>
            }
            {/* Card Footer */}
            <Box width="100%" height={CARD_FOOTER_HEIGHT} minHeight={MIN_CARD_FOOTER_HEIGHT} display="flex" alignItems="center" justifyContent="center" borderTop={LIGHT_BORDER}>
                <Button variant="text" sx={{ textTransform: getTextTransformationAccordingToLanguage() }} size="small" onClick={() => { handleAllNotificationStatusChange(notificationsData.notifications);}}>
                    {t(TranslationKeyEnum.markAllAsRead)}
                </Button>
            </Box>
            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
            >
                <MenuItem onClick={(event) => { event.stopPropagation(); handleNotificationStatusChange(notificationsData.notifications, contextMenu?.index!); handleClose() }}>
                    <Typography variant="body2">{t(TranslationKeyEnum.remove)}</Typography>
                </MenuItem>
            </Menu>
            {
                isNotificationPopupOpen &&
                <PopupNotification
                    open={isNotificationPopupOpen}
                    setIsOpen={setIsNotificationPopupOpen}
                    title={popupTitle}
                    description={popupDescription}
                    content={popupContent}
                    handlePopupClose={handlePopupClose}
                />
            }
        </Box>
    )
}

export default NotificationCenter