import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Avatar from '@mui/material/Avatar';
import PeopleIcon from '@mui/icons-material/People';
import useDebounce from '../../helpers/hooks';
import { Box, Typography } from '@mui/material';
import { GridColDef } from "@mui/x-data-grid";
import { TranslationKeyEnum } from "../../features/translations/TranslationKeyEnum";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useSearchUsers, useGetOfficesBasicInformation } from "./actions";
import { EmployeeDto, OfficeDto, PositionResponse } from "../../API/user-management-service";
import { useGetPositions } from "../../API/position-actions";
import { PRIMARY_BG_COLOR, BORDER_PRIMARY_COLOR, SPACING_EXTRA_SMALL, LINE_WIDTH, BORDER_RADIUS_SMALL, SPACING_SMALL, SPACING_MEDIUM } from "../../utils/cssUtils";
import { useNavigate } from "react-router-dom";
import { routeEnum } from "../../routes/routeEnum";
import { StyledDataGrid } from '../../components/StyledDataGrid/StyledDataGrid';
import { StyledTextField } from '../../components/StyledTextfield/StyledTextField';
import { StyledSelectField } from '../../components/StyledSelect/StyledSelect';
import "../../features/translations/i18n";
import { LanguageEnum } from '../../features/translations/LanguageEnum';
import { srRS } from '../../features/translations/DataGrid/srRS';

export default function Employees() {
    const { t, i18n } = useTranslation();
    const getOffices = useGetOfficesBasicInformation();
    const getPositions = useGetPositions();
    const searchUsers = useSearchUsers();
    const navigate = useNavigate();

    const [users, setUsers] = useState<EmployeeDto[]>([]);
    const [allOffices, setAllOffices] = useState<OfficeDto[]>([]);
    const [allPositions, setAllPositions] = useState<string[]>([]);
    const [searchString, setSearchString] = useState("");
    const [position, setPosition] = useState("");
    const [office, setOffice] = useState("");
    const [usersLoading, setUsersLoading] = useState<boolean>(true);
    const debouncedValue = useDebounce<string>(searchString, 500);

    useEffect(() => {
        const fetchData = async () => {
            setUsersLoading(true);
            const searchResult = await searchUsers(undefined, searchString, position, office, undefined, undefined);
            setUsers(searchResult.items as EmployeeDto[]);
            setUsersLoading(false);
        }
        fetchData();
    }, [debouncedValue, position, office])

    useEffect(() => {
        const fetchData = async () => {
            Promise.all([getOffices(), getPositions()]).then((values) => {
                setAllOffices(values[0]);
                setAllPositions(values[1].map((position: PositionResponse) => position.name));
            });
        }

        fetchData();
    }, [])

    const columns: GridColDef[] = [
        {
            field: 'fullName', headerName: t(TranslationKeyEnum.userLabel), description: t(TranslationKeyEnum.userLabel), flex: 1, minWidth: 200, renderCell: (cellValues) => {
                return (
                    <Box data-test-id={`${cellValues.row.email}`} sx={{ display: 'flex', alignItems: "center", flexDirection: 'row', justifyContent: 'space-between', cursor: "pointer" }} onClick={() => { navigate(`/${routeEnum.EMPLOYEES}/${cellValues.row.id}`) }}>
                        <Avatar variant="rounded" sx={{ width: 30, height: 30 }} src={cellValues.row.image && `data:image/png;base64,${cellValues.row.image}`} />
                        <Typography paddingLeft={2} variant="body2" textAlign="center">{cellValues.row.fullName}</Typography>
                    </Box>
                );
            }
        },
        {
            field: 'email', headerName: t(TranslationKeyEnum.email), description: t(TranslationKeyEnum.email), flex: 1, minWidth: 200, renderCell: (cellValues) => {
                return (
                    <Typography variant="body2" textAlign="center" color="primary">{cellValues.value}</Typography>
                );
            }
        },
        { field: 'location', headerName: t(TranslationKeyEnum.locationLabel), description: t(TranslationKeyEnum.locationLabel), flex: 1, minWidth: 120 },
        { field: 'position', headerName: t(TranslationKeyEnum.position), description: t(TranslationKeyEnum.position), flex: 1, minWidth: 120 },
        { field: 'hireDate', headerName: t(TranslationKeyEnum.hireDateLabel), description: t(TranslationKeyEnum.hireDateLabel), flex: 1, minWidth: 120 }
    ];

    const handleOfficeChange = (event: any) => {
        setOffice(event.target.value);
    };

    const handlePositionChange = (event: SelectChangeEvent) => {
        setPosition(event.target.value);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(event.target.value);
    };

    return <Box height="90vh">
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={SPACING_EXTRA_SMALL} marginBottom={SPACING_MEDIUM}>
            <PeopleIcon fontSize="large" color="primary" />
            <Typography variant="h5" textAlign="center">{t(TranslationKeyEnum.employees)}</Typography>
            <Typography variant="body2" textAlign="center">{t(TranslationKeyEnum.employeesPageDescription)}</Typography>
            <Box width={LINE_WIDTH} border={BORDER_PRIMARY_COLOR} />
        </Box>
        <Box sx={{ backgroundColor: PRIMARY_BG_COLOR, borderRadius: BORDER_RADIUS_SMALL }}>
            <Box width={{ xl: "74%", lg: "74%", md: "90%", sm: "100%", xs: "100%" }} display="flex" flexDirection={{ xl: "row", lg: "row", md: "row", sm: "column", xs: "column" }} padding={SPACING_SMALL} gap={SPACING_SMALL}>
                <StyledTextField
                    name="search"
                    label={t(TranslationKeyEnum.search)}
                    placeholder={(t(TranslationKeyEnum.searchEmployee))}
                    size="small"
                    fullWidth
                    onChange={handleSearchChange}
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: { xl: "30%", lg: "30%", md: "30%", sm: "100%", xs: "100%" } }}
                />
                <FormControl size="small" fullWidth={true} sx={{ width: { xl: "30%", lg: "30%", md: "30%", sm: "100%", xs: "100%" } }}>
                    <InputLabel shrink>{t(TranslationKeyEnum.office)}</InputLabel>
                    <StyledSelectField
                        value={office}
                        label={t(TranslationKeyEnum.office)}
                        onChange={(event) => handleOfficeChange(event)}
                        notched={true}
                        displayEmpty={true}
                        renderValue={() =>
                            office ? office : <InputLabel sx={{ color: "#C8C8C8" }}>{t(TranslationKeyEnum.selectOffice)}</InputLabel>
                        }
                    >
                        {allOffices?.map((office, index) => {
                            return (
                                <MenuItem key={index} value={office.name}>{office.name}</MenuItem>
                            )
                        })}
                        <MenuItem key="none" value="">{t(TranslationKeyEnum.all)}</MenuItem>
                    </StyledSelectField>
                </FormControl>
                <FormControl size="small" fullWidth={true} sx={{ width: { xl: "30%", lg: "30%", md: "30%", sm: "100%", xs: "100%" } }}>
                    <InputLabel shrink>{t(TranslationKeyEnum.position)}</InputLabel>
                    <Select
                        value={position}
                        label={t(TranslationKeyEnum.position)}
                        onChange={handlePositionChange}
                        notched={true}
                        displayEmpty={true}
                        renderValue={() =>
                            position ? position : <InputLabel sx={{ color: "#C8C8C8" }}>{t(TranslationKeyEnum.selectPosition)}</InputLabel>
                        }
                    >
                        {allPositions?.map((position, index) => {
                            return (
                                <MenuItem key={index} value={position}>{position}</MenuItem>
                            )
                        })}
                        <MenuItem key="none" value="">{t(TranslationKeyEnum.all)}</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <StyledDataGrid
                rows={users}
                columns={columns}
                loading={usersLoading}
                disableRowSelectionOnClick
                sx={{ height: { xl: "64vh", lg: "64vh", md: "64vh", sm: "44vh", xs: "40vh" } }}
                localeText={i18n.language === LanguageEnum.Serbian ? srRS.components.MuiDataGrid.defaultProps.localeText : undefined}
                hideFooter={true}
            />
        </Box>
    </Box>
}