import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LogoutIcon from '@mui/icons-material/Logout';
import LanguageIcon from '@mui/icons-material/Language';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LanguagePicker from '../../../components/LanguagePicker/LanguagePicker';
import { Paper, MenuList, MenuItem, ClickAwayListener, } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TranslationKeyEnum } from '../../../features/translations/TranslationKeyEnum';
import { LIGHT_BOX_SHADOW, SPACING_EXTRA_SMALL, SPACING_SMALL } from '../../../utils/cssUtils';
import { IUserMenu } from './IUserMenu';
import { routeEnum } from '../../../routes/routeEnum';
import { removeUserFromStorage } from '../../../utils/storageUtils/storageUtils';

export default function UserMenu({ isUserMenuOpen, setIsUserMenuOpen, isLanguageMenuOpen, setIsLanguageMenuOpen, hasAdminPermission, setCurrentTab, isMobile, setIsHamburgerMenuOpen }: IUserMenu) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    
    const handleLogout = () => {
        removeUserFromStorage();
        navigate("/");
    }

    return (
        <>
            {/* Menu Items */}
            {
                isUserMenuOpen &&
                <ClickAwayListener touchEvent={false} onClickAway={() => { setIsUserMenuOpen(false); setIsLanguageMenuOpen(false); }} >
                    <Paper elevation={3} sx={{ width: "76%", marginLeft: "auto", marginTop: SPACING_EXTRA_SMALL, boxShadow: LIGHT_BOX_SHADOW }}>
                        <MenuList>
                                {
                                    hasAdminPermission
                                    && <MenuItem dense onClick={() => { setIsUserMenuOpen(false); navigate(routeEnum.ADMIN); setCurrentTab(""); setIsHamburgerMenuOpen && setIsHamburgerMenuOpen(false); }}>
                                        <AdminPanelSettingsIcon data-test-id="adminConsoleMenuItem" color="action" sx={{ marginRight: SPACING_SMALL }} />
                                        {t(TranslationKeyEnum.adminConsole)}
                                    </MenuItem>
                                }
                            <MenuItem dense onClick={(event) => { setIsLanguageMenuOpen(!isLanguageMenuOpen); event.stopPropagation(); }}>
                                <LanguageIcon color="action" sx={{ marginRight: SPACING_SMALL }} />
                                {t(TranslationKeyEnum.language)}
                                <ChevronRightIcon color="action" sx={{ marginLeft: "auto" }} />
                            </MenuItem>
                            <MenuItem dense onClick={() => { setIsUserMenuOpen(false); handleLogout() }}>
                                <LogoutIcon color="action" sx={{ marginRight: SPACING_SMALL }} />
                                {t(TranslationKeyEnum.logout)}
                            </MenuItem>
                        </MenuList>
                    </Paper>
                </ClickAwayListener>
            }
            {/* Language Picker */}
            {
                isLanguageMenuOpen &&
                <ClickAwayListener touchEvent={false} onClickAway={() => { setIsLanguageMenuOpen(false) }} >
                    {
                        isMobile ?
                            <Paper sx={{ width: "50%", marginLeft: "auto", boxShadow: LIGHT_BOX_SHADOW, }}>
                                <LanguagePicker />
                            </Paper>
                            :
                            <Paper sx={{
                                width: { xl: "50%", lg: "50%", md: "70%", sm: "50%", xs: "30%" }, boxShadow: LIGHT_BOX_SHADOW, padding: SPACING_EXTRA_SMALL, position: "absolute",
                                right: { xl: "-50%", lg: "-50%", md: "-70%", sm: "-50%", xs: "0%" }, bottom: { xl: "-9%", lg: "-9%", md: "-9%", sm: "-9%" }
                            }}>
                                <LanguagePicker />
                            </Paper>
                    }
                </ClickAwayListener>
            }
        </>
    )
}
