import { FormControl, InputLabel, MenuItem, Box } from '@mui/material';
import { FormikValues, useFormik } from 'formik';
import { IVacationFormProps } from './IVacationFormProps';
import { TranslationKeyEnum } from '../../../features/translations/TranslationKeyEnum';
import { useTranslation } from 'react-i18next';
import { IApproverProps } from '../../common/CreateEditUserForms/IApproverProps';
import { useVacationInfoFormikForm } from './vacationFormikForm';
import { useUpdateTimeOffUser } from '../../../API/time-off-actions';
import { organizeEmployeesData, useGetAllApproverIds, useGetApprovers } from '../../common/CreateEditUserForms/actions';
import DropdownErrorText from '../../DropdownErrorText/DropdownErrorText';
import StepperFormFooter from '../StepperFormFooter/FormFooter';
import Loader from '../../Loader/Loader';
import { StyledSelectField } from '../../StyledSelect/StyledSelect';
import { StyledTextField } from '../../StyledTextfield/StyledTextField';
import React from 'react';
import { ADMIN_CONSOLE_INPUT_LABEL_WIDTH, SPACING_SMALL } from '../../../utils/cssUtils';

function VacationForm({ user, closeCreateUserForm }: IVacationFormProps) {
  const { t } = useTranslation();
  const getApproverIds = useGetAllApproverIds();
  const getApprovers = useGetApprovers();
  const updateApproverAndYearsOfWork = useUpdateTimeOffUser();
  const formikVacationInfo = useVacationInfoFormikForm();
  const [employeeDropdownData, setEmployeeDropdownData] = React.useState([] as IApproverProps[]);
  const [isAprroversDataLoading, setIsApproversDataLoading] = React.useState(true);
  const [isFormLoading, setIsFormLoading] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      let approverIds = await getApproverIds();
      let approvers = await getApprovers(approverIds);
      approverIds && organizeEmployeesData(approvers, setEmployeeDropdownData, setIsApproversDataLoading);
    }
    fetchData();
  }, [])

  const vacationInfoForm: FormikValues = useFormik(formikVacationInfo(handleSubmit));

  async function handleSubmit() {
    setIsFormLoading(true);
    let update = await updateApproverAndYearsOfWork(user, vacationInfoForm.values.approver, vacationInfoForm.values.yearsOfService);
    setIsFormLoading(false);
    update && closeCreateUserForm();
  }

  return (
    <form onSubmit={vacationInfoForm.handleSubmit}>
      <Box display="flex" justifyContent="space-between">
        <FormControl data-test-id="approverDropdown" sx={{ width: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, marginTop: SPACING_SMALL }}>
          <InputLabel shrink>{t(TranslationKeyEnum.approver)}</InputLabel>
          <StyledSelectField
            id="approver"
            name="approver"
            label={t(TranslationKeyEnum.approver)}
            value={vacationInfoForm.values.approver}
            onChange={vacationInfoForm.handleChange}
            error={vacationInfoForm.touched.approver && Boolean(vacationInfoForm.errors.approver)}
            disabled={isFormLoading}
            notched={true}
            displayEmpty={true}
            renderValue={() =>
              vacationInfoForm.values.approver
                ? employeeDropdownData.find((approver: IApproverProps) => approver.id === vacationInfoForm.values.approver)?.fullname
                : t(TranslationKeyEnum.selectApprover)
            }
          >
            {
              isAprroversDataLoading ? <Box height={30}><Loader size={30} /></Box> : employeeDropdownData.map((s: IApproverProps) => <MenuItem value={s.id} key={s.id}>{s.fullname}</MenuItem>)
            }
          </StyledSelectField>
          {vacationInfoForm.errors.approver && vacationInfoForm.touched.approver && <DropdownErrorText />}
        </FormControl>
        <StyledTextField
          id="yearsOfService"
          name="yearsOfService"
          type="number"
          label={t(TranslationKeyEnum.yearsOfService)}
          value={vacationInfoForm.values.yearsOfService}
          onChange={vacationInfoForm.handleChange}
          error={vacationInfoForm.touched.yearsOfService && Boolean(vacationInfoForm.errors.yearsOfService)}
          helperText={vacationInfoForm.touched.yearsOfService && vacationInfoForm.errors.yearsOfService}
          sx={{ width: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, marginTop: SPACING_SMALL }}
          disabled={isFormLoading}
          InputProps={{
            inputProps: { min: 0 },
          }}
          InputLabelProps={{ shrink: true }}
        />
        {isFormLoading && <Loader size={50} />}
      </Box>
      <StepperFormFooter isFormLoading={isFormLoading} secondButtonLabel={t(TranslationKeyEnum.submit)} />
    </form>
  )
}

export default VacationForm;