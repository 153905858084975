import { ForgotPasswordRequest, ResponseMessage, UserClient } from "../../API/identity-service";
import { useTryCatchJsonByAction } from "../../utils/fetchUtils";

const client = new UserClient();

export function useForgotPasswordAction() : (email: string) => Promise<ResponseMessage> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function forgotPassword(email: string): Promise<ResponseMessage> {
        const bindedAction = forgotPasswordAction.bind(null, email);
        const forgotPasswordResponse = await tryCatchAction(bindedAction);
        return forgotPasswordResponse.response;
    }
    return forgotPassword;
}

export const forgotPasswordAction = async (email: string): Promise<ResponseMessage> => {
	let request = new ForgotPasswordRequest({
        email: email,
    });

    return await client.forgotPassword(request);
}