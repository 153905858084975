import { createTheme, ThemeProvider } from '@mui/material/styles';
import { IPalletteButton } from './IPalletteButton';
import Button from '@mui/material/Button';
import { purple, lightGreen, lightBlue, teal, indigo, lime } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    Purple: {
      main: purple[100],
      contrastText: '#4A148C',
    },
    LightGreen: {
      main: lightGreen[100],
      contrastText: '#1B5E20',
    },
    LightBlue: {
      main: lightBlue[100],
      contrastText: '#01579B',
    },
    Teal: {
      main: teal[100],
      contrastText: '#004D40',
    },
    Indigo: {
      main: indigo[100],
      contrastText: '#1A237E',
    },
    Lime: {
      main: lime[100],
      contrastText: '#827717',
    },
    Undefined: {
      main: '#fff',
      contrastText: '#fff',
    },
  },
});

declare module '@mui/material/styles' {
  interface Palette {
    Purple: Palette['primary'];
    LightGreen: Palette['primary'];
    LightBlue: Palette['primary'];
    Teal: Palette['primary'];
    Indigo: Palette['primary'];
    Lime: Palette['primary'];
    Undefined: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    Purple?: PaletteOptions['primary'];
    LightGreen?: PaletteOptions['primary'];
    LightBlue?: PaletteOptions['primary'];
    Teal?: PaletteOptions['primary'];
    Indigo?: PaletteOptions['primary'];
    Lime?: PaletteOptions['primary'];
    Undefined?: PaletteOptions['primary'];
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    Purple: true;
    LightGreen: true;
    LightBlue: true;
    Teal: true;
    Indigo: true;
    Lime: true;
    Undefined: true;
  }
}

export default function PalletteButton(props: IPalletteButton) {
  return (
    <ThemeProvider theme={theme}>
      <Button color={props.colorName} variant="contained" size="small" sx={{ display: 'block', height: '1.375rem', minWidth: '1.375rem', width: '1.375rem', borderRadius: '50%' }} />
    </ThemeProvider>
  )
}