import { useTryCatchJsonByAction } from "../utils/fetchUtils";
import { ArticleClient, ArticleDto, ArticleVM, CreateArticleRequest, ResponseMessage, UpdateArticleDto } from "./user-management-service";

const articleClient = new ArticleClient();

export function useGetArticles(): () => Promise<ArticleDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getArticles(): Promise<ArticleDto[]> {
        const bindedAction = getArticlesAction.bind(null);
        const getArticles = await tryCatchAction(bindedAction);
        return getArticles ? getArticles.response : [];
    }
    return getArticles;
}

const getArticlesAction = (): Promise<ArticleDto[]> => {
    return articleClient.getAll();
}

export function useGetLastArticles(): (numberOfArticles: number) => Promise<ArticleVM[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getLastArticles(numberOfArticles: number): Promise<ArticleVM[]> {
        const bindedAction = getLastArticlesAction.bind(null, numberOfArticles);
        const getArticles = await tryCatchAction(bindedAction);
        return getArticles ? getArticles.response : [];
    }
    return getLastArticles;

}
const getLastArticlesAction = (numberOfArticles: number): Promise<ArticleVM[]> => {
    return articleClient.getLast(numberOfArticles);
}

export function useGetArticle(): (articleId: string) => Promise<ArticleDto> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getArticle(articleId: string): Promise<ArticleDto> {
        const bindedAction = getArticleAction.bind(null, articleId);
        const getArticle = await tryCatchAction(bindedAction);
        return getArticle ? getArticle.response : {};
    }
    return getArticle;
}

const getArticleAction = (articleId: string): Promise<ArticleDto> => {
    return articleClient.get(articleId);
}

export function useCreateArticle(): (article: CreateArticleRequest) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleCreateArticle(article: CreateArticleRequest): Promise<boolean> {
        const bindedAction = createArticleAction.bind(null, article);
        const createArticle = await tryCatchAction(bindedAction);
        return createArticle.success;
    }
    return handleCreateArticle;
}

const createArticleAction = (article: CreateArticleRequest): Promise<ResponseMessage> => {
    return articleClient.insert(article);
}

export function useUpdateArticle(): (article: UpdateArticleDto) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleUpdateArticle(article: UpdateArticleDto): Promise<boolean> {
        const bindedAction = updateArticleAction.bind(null, article);
        const updateArticle = await tryCatchAction(bindedAction);
        return updateArticle.success;
    }
    return handleUpdateArticle;
}

const updateArticleAction = (article: UpdateArticleDto): Promise<ResponseMessage> => {
    return articleClient.update(article);
}

export function useDeleteArticle(): (articleId: string) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function deleteArticle(articleId: string): Promise<boolean> {
        const bindedAction = deleteArticleAction.bind(null, articleId);
        let getDeleteResponse = await tryCatchAction(bindedAction);
        return getDeleteResponse.success;
    }

    return deleteArticle;
}

const deleteArticleAction = (articleId: string): Promise<ResponseMessage> => {
    return articleClient.remove(articleId);
}