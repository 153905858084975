import React from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { IAddEditGroupFormProps } from "./IAddEditGroupFormProps"
import { useTranslation } from "react-i18next";
import { TranslationKeyEnum } from "../../features/translations/TranslationKeyEnum";
import { Typography } from "@mui/material";
import { GridColDef, GridRowSelectionModel, GridRowId, GridPaginationModel } from "@mui/x-data-grid";
import { useGetAllUsers } from "../../API/user-actions";
import { Box } from "@mui/material";
import { IEmployeesTableProps } from "../AdminEmployees/IEmployeesTableProps";
import { CreateGroupRequest, GroupDto, UpdateGroupRequest, UserDto } from "../../API/time-off-service";
import { LanguageEnum } from "../../features/translations/LanguageEnum";
import { srRS } from "../../features/translations/DataGrid/srRS";
import { useAddEditGroupFormikForm } from "./addEditFormikForm";
import { FormikValues, useFormik } from "formik";
import { ROWS_PER_PAGE_OPTIONS } from "../common/Constants/PaginationConstants";
import { ADMIN_CONSOLE_INPUT_LABEL_WIDTH, ICON_COLOR, SPACING_MEDIUM, SPACING_SMALL } from "../../utils/cssUtils";
import { StyledDataGrid } from "../StyledDataGrid/StyledDataGrid";
import { StyledTextField } from "../StyledTextfield/StyledTextField";

export default (props: IAddEditGroupFormProps) => {
    const { t, i18n } = useTranslation();
    const [users, setUsers] = React.useState<IEmployeesTableProps[]>();
    const [group, setGroup] = React.useState<GroupDto>(new GroupDto());
    const getUsers = useGetAllUsers();
    const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const formikGroupForm = useAddEditGroupFormikForm();
    const [page, setPage] = React.useState<number>(0);
    const [pageSize, setPageSize] = React.useState<number>(ROWS_PER_PAGE_OPTIONS[0]);
    const [isUsersDataLoading, setIsUsersDataLoading] = React.useState<boolean>(true);

    const handlePaginationModelChange = (model: GridPaginationModel) => {
        setPage(model.page);
        setPageSize(model.pageSize);
    }

    const handleSubmit = async () => {
        const request = group.id ? new UpdateGroupRequest() : new CreateGroupRequest();
        request.init({
            id: groupForm.values.id,
            members: users!.filter(g => selectionModel.includes(g.id as GridRowId)) as UserDto[],
            name: groupForm.values.name,
            mustBePresent: groupForm.values.mustBePresent
        });
        if (await props.submit(request)) {
            resetForm();
        }
    }

    const handleClose = () => {
        resetForm();
        props.close();
    }

    const resetForm = () => {
        setGroup(new GroupDto());
        setSelectionModel([]);
        groupForm.resetForm();
    }

    const groupForm: FormikValues = useFormik(formikGroupForm(group, handleSubmit));

    React.useEffect(() => {
        const fetchData = async () => {
            setUsers(await getUsers());
            setIsUsersDataLoading(false);
        }
        fetchData();
    }, [])

    React.useEffect(() => {
        groupForm.setFieldValue("membersCount", selectionModel.length)
    }, [selectionModel.length])

    React.useEffect(() => {
        const tempGroup = props.group === undefined ? new GroupDto() : props.group;
        const tempSelectionModel = props.group === undefined ? [] : props.group!.members!.map(m => m.id!);
        setGroup(tempGroup)
        setSelectionModel(tempSelectionModel)
        groupForm.setFieldValue("id", tempGroup.id)
        groupForm.setFieldValue("name", tempGroup.name)
        groupForm.setFieldValue("mustBePresent", tempGroup.mustBePresent)
        groupForm.setFieldValue("membersCount", tempSelectionModel.length)
    }, [props.group?.id])

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID' },
        { field: 'firstName', headerName: t(TranslationKeyEnum.firstName), flex: 1 },
        { field: 'lastName', headerName: t(TranslationKeyEnum.lastName), flex: 1 },
        { field: 'position', headerName: t(TranslationKeyEnum.position), flex: 1 },
    ];

    return (
        <Dialog open={props.open} onClose={handleClose}>
            <Box position="absolute" right="0" padding={SPACING_SMALL} sx={{ cursor: "pointer" }} onClick={handleClose}>
                <CloseIcon sx={{ color: ICON_COLOR }} />
            </Box>
            <Box width={{ xl: "50vw", lg: "50vw", md: "60vw", sm: "70vw", xs: "80vw" }} padding={SPACING_SMALL}>
                <Typography variant="h5" marginBottom={SPACING_SMALL}>
                    {t(group.id ? TranslationKeyEnum.editGroup : TranslationKeyEnum.addGroup)}
                </Typography>
                <form onSubmit={groupForm.handleSubmit}>
                    <Box display="flex" justifyContent="space-between" marginBottom={SPACING_SMALL}>
                        <StyledTextField
                            label={t(TranslationKeyEnum.name)}
                            variant="outlined"
                            sx={{ width: ADMIN_CONSOLE_INPUT_LABEL_WIDTH }}
                            name="name"
                            id="name"
                            type="text"
                            value={groupForm.values.name}
                            onChange={groupForm.handleChange}
                            error={groupForm.touched.name && Boolean(groupForm.errors.name)}
                            helperText={groupForm.touched.name && groupForm.errors.name}
                            InputLabelProps={{ shrink: true }}
                        />
                        <StyledTextField
                            label={t(TranslationKeyEnum.mustBePresentLabel)}
                            variant="outlined"
                            sx={{ width: ADMIN_CONSOLE_INPUT_LABEL_WIDTH }}
                            type={'number'}
                            InputProps={{ inputProps: { min: 0 } }}
                            name="mustBePresent"
                            value={groupForm.values.mustBePresent}
                            onChange={groupForm.handleChange}
                            error={groupForm.touched.mustBePresent && Boolean(groupForm.errors.mustBePresent)}
                            helperText={groupForm.touched.mustBePresent && groupForm.errors.mustBePresent}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Box>
                    <Typography variant="h5" marginBottom={SPACING_SMALL}>{t(TranslationKeyEnum.employees)}</Typography>
                    <Box height="50vh" overflow="auto">
                        <StyledDataGrid
                            columnVisibilityModel={{
                                id: false,
                            }}
                            localeText={i18n.language === LanguageEnum.Serbian ? srRS.components.MuiDataGrid.defaultProps.localeText : undefined}
                            rows={users ?? []}
                            autoHeight={true}
                            columns={columns}
                            paginationModel={{ page, pageSize }}
                            onPaginationModelChange={handlePaginationModelChange}
                            pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
                            pagination
                            checkboxSelection
                            onRowSelectionModelChange={(newSelectionModel) => {
                                setSelectionModel(newSelectionModel)
                            }}
                            rowSelectionModel={selectionModel}
                            loading={isUsersDataLoading}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: SPACING_MEDIUM }}>
                        <Button color="inherit" onClick={handleClose}>
                            {t(TranslationKeyEnum.cancel)}
                        </Button>
                        <Button type="submit" variant="contained">{t(TranslationKeyEnum.submit)}</Button>
                    </Box>
                </form>
            </Box>
        </Dialog>
    )
}