import { Box, ClickAwayListener, TextFieldProps } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { StyledTextField } from '../StyledTextfield/StyledTextField';
import { LIGHT_BG_COLOR } from '../../utils/cssUtils';
import { ICustomDatePickerProps } from './ICustomDatePickerProps';
import dayjs from 'dayjs';

export default function CustomDatePicker({ label, value, inputFormat, handleDatePick, isOpen, isDisabled, setIsOpen, setDateError, shouldDisableDate }: ICustomDatePickerProps) {
    return (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
            <Box>
                <DatePicker
                    label={label}
                    format={inputFormat}
                    value={value && dayjs(value) as unknown as Date}
                    onChange={(newValue) => { handleDatePick(newValue); setIsOpen(false); }}
                    open={isOpen}
                    slots={{ textField: StyledTextField as React.ElementType<TextFieldProps> }}
                    slotProps={{
                        textField: {
                            sx: { svg: { color: LIGHT_BG_COLOR }, input: { cursor: "pointer" }, width: "100%" },
                            InputLabelProps: { shrink: true },
                            autoComplete: "off",
                            onClick: () => setIsOpen(!isOpen),
                            disabled: isDisabled
                        },
                    }}
                    shouldDisableDate={shouldDisableDate}
                    onError={(error) => error !== null && setDateError && setDateError(true)}
                    disabled={isDisabled}
                />
            </Box>
        </ClickAwayListener>
    );
}
