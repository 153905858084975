import { Dispatch, SetStateAction } from "react";

export enum OPERATIONS_ENUM {
    ADD = "add",
    SUBTRACT = "subtract"
};

export const changeMonth = (operation: string, selectedMonth: Date, setSelectedMonth: Dispatch<SetStateAction<Date>>): void => {
    operation === OPERATIONS_ENUM.ADD
        ? setSelectedMonth(new Date(selectedMonth.setMonth(selectedMonth.getMonth() + 1)))
        : setSelectedMonth(new Date(selectedMonth.setMonth(selectedMonth.getMonth() - 1)));
}