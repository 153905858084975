import { LanguageEnum } from "./LanguageEnum";
import imgSerbian from "../../assets/icons/rs.svg";
import imgGb from "../../assets/icons/gb.svg";

export type LanguageType = {
    language: LanguageEnum,
    img: string,
    alt: string,
    label: string,
    locale: string,
};

export const languages:LanguageType[] = [
    { language: LanguageEnum.Serbian, img: imgSerbian, alt: "srpski", label: "Srpski", locale: "sr-Latn-RS"},
    { language: LanguageEnum.English, img: imgGb, alt: "english", label: "English", locale: "en-US"}
];