import React from 'react';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import CloseIcon from '@mui/icons-material/Close';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ConstructionIcon from '@mui/icons-material/Construction';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import useMediaQuery from '@mui/material/useMediaQuery';
import DropdownErrorText from '../../../components/DropdownErrorText/DropdownErrorText';
import Loader from '../../../components/Loader/Loader';
import CustomDatePicker from '../../../components/CustomDatePicker/CustomDatePicker';
import MapDialog from './MapDialog/MapDialog';
import dayjs from 'dayjs';
import { Dialog, Box, Typography, FormControl, InputLabel, Avatar, MenuItem, AvatarGroup, Tooltip, Button, Chip, ClickAwayListener, ChipPropsColorOverrides, Checkbox, InputAdornment, OutlinedInput, ListItemText, TextFieldProps } from '@mui/material';
import { ICreateEditWorkOrderProps } from './ICreateEditWorkOrderProps';
import { APP_BG_COLOR, BORDER_RADIUS_MEDIUM, DARK_BOX_SHADOW, ICON_COLOR, LIGHT_BG_COLOR, PRIMARY_BG_COLOR, SPACING_EXTRA_SMALL, SPACING_LARGE, SPACING_MEDIUM, SPACING_SMALL } from '../../../utils/cssUtils';
import { StyledReadonlyTextField } from '../../../components/StyledReadonlyTextField/StyledReadonlyTextField';
import { useTranslation } from 'react-i18next';
import { TranslationKeyEnum } from '../../../features/translations/TranslationKeyEnum';
import { StyledSelectField } from '../../../components/StyledSelect/StyledSelect';
import { StyledTextField } from '../../../components/StyledTextfield/StyledTextField';
import { SxProps, } from '@mui/system';
import { OverridableStringUnion } from '@mui/types';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useWorkOrderForm } from './useWorkOrderForm';
import { FormikValues, useFormik } from 'formik';
import { MenuProps } from '../../../components/CreateUserForm/PermissionsForm/permissionFormManuProps';
import { CreateWorkOrderRequest, Status, StatusEnum, TypeOfWorkDto, UpdateWorkOrderRequest, UserDto, UserWorkOrderDto } from '../../../API/work-order-service';
import { ERROR_MESSAGE } from '../../../styles/colors';
import { useCreateWorkOrder, useGetTypesOfWorkByStatus, useUpdateWorkOrder, useGetTypeOfWork, useGetAll, useGetEmployeesByWorkOrderId } from '../../../API/work-order-actions';
import { compareDates, isDateYearOne } from '../../../utils/dateUtils';

interface StatusProps {
  label: string,
  value: any,
  icon: React.ReactElement<any, string | React.JSXElementConstructor<any>>,
  sx: SxProps,
  arrowColor: string,
  color?: OverridableStringUnion<"default" | "success" | "primary" | "secondary" | "error" | "info" | "warning", ChipPropsColorOverrides>,
}

class Assignee extends UserDto {
  public isAssigned = false;
  constructor(e: UserDto) {
    super(e);
    this.isAssigned = false;
  }
}

const STATUSES: StatusProps[] = [
  { label: "To Do", value: Status.ToDo, icon: <ConstructionIcon />, sx: { borderColor: "black", cursor: "pointer", width: "fit-content" }, arrowColor: "black" },
  { label: "In Progress", value: Status.InProgress, icon: <AccessTimeIcon style={{ color: "purple" }} />, sx: { color: "purple", borderColor: "purple", cursor: "pointer", width: "fit-content" }, arrowColor: "purple" },
  { label: "Done", value: Status.Done, icon: <CheckCircleIcon color="success" />, sx: { cursor: "pointer", width: "fit-content" }, color: 'success', arrowColor: "green" },
  { label: "Invoiced", value: Status.Invoiced, icon: < PriceCheckIcon />, sx: { cursor: "pointer", width: "fit-content" }, color: "primary", arrowColor: "blue" },
  { label: "Cancelled", value: Status.Cancelled, icon: < CancelIcon />, sx: { cursor: "pointer", width: "fit-content" }, color: "error", arrowColor: "red" },
]
const DEFAULT_STATUS: StatusProps = STATUSES[0];

export default function CreateEditWorkOrder({ isOpen, setIsOpen, isSupervisor, workOrderId, rerender, setRerender, handleCloseWorkOrderForm }: ICreateEditWorkOrderProps) {
  const { t } = useTranslation();
  const getAll = useGetAll();
  const getEmployeesByWorkOrderId = useGetEmployeesByWorkOrderId();
  const createWorkOrder = useCreateWorkOrder();
  const updateWorkOrder = useUpdateWorkOrder();
  const getTypeOfWork = useGetTypeOfWork();
  const getActiveTypesOfWork = useGetTypesOfWorkByStatus();
  const formikWorkOrderForm = useWorkOrderForm(workOrderId);
  const workOrderForm: FormikValues = useFormik(formikWorkOrderForm(handleSubmit));
  const isMobile = useMediaQuery('(max-width:900px)');
  const [typesOfWork, setTypesOfWork] = React.useState<TypeOfWorkDto[]>([]);
  const [deactivatedTypeOfWorkName, setDeactivatedTypeOfWorkName] = React.useState<string>("");
  const [status, setStatus] = React.useState<StatusProps>(DEFAULT_STATUS);
  const [employees, setEmployees] = React.useState<UserDto[]>([]);
  const [employeesWithIsAssignedProp, setEmployeesWithIsAssignedProp] = React.useState<Assignee[]>([])
  const [search, setSearch] = React.useState<string>("");

  const [isDataLoading, setIsDataLoading] = React.useState<boolean>(true);
  const [isStatusMenuOpen, setIsStatusMenuOpen] = React.useState<boolean>(false);
  const [isEditAssigneesMenuOpen, setIsEditAsssigneesMenuOpen] = React.useState<boolean>(false);
  const [isWorkOrderReceivedDateOpen, setIsWorkOrderReceivedDateOpen] = React.useState<boolean>(false);
  const [isStartDateOpen, setIsStartDateOpen] = React.useState<boolean>(false);
  const [isEndDateOpen, setIsEndDateOpen] = React.useState<boolean>(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = React.useState<boolean>(false)
  const [isMapOpen, setIsMapOpen] = React.useState<boolean>(false);

  React.useEffect((): void => {
    const fetchTypesOfWork = async () => {
      Promise.all([getActiveTypesOfWork(StatusEnum.Active), workOrderId === "" ? getAll() : getEmployeesByWorkOrderId(workOrderId)]).then(values => {
        setTypesOfWork(values[0]);
        setEmployees(values[1]);
        setIsDataLoading(false);
      });
    }
    fetchTypesOfWork();
  }, [])

  React.useEffect((): void => {
    employees && setEmployeesWithIsAssignedProp(employees.map(employee => {
      let newAssignee = new Assignee(employee);
      newAssignee.isAssigned = !!workOrderForm.values.assignees.find((assignee: UserWorkOrderDto) => employee.id === assignee.user!.id);
      return newAssignee;
    }))
  }, [employees, workOrderForm.values.assignees.length])

  React.useEffect(() => {
    workOrderForm.values.status !== "" && setStatus(STATUSES.find(s => s.value === workOrderForm.values.status)!)
  }, [workOrderForm.values.status])

  //in case where selected type of work is deactivated we are fetching deactivated type of work and returnign it's name
  React.useEffect(() => {
    const fetchDeactivatedTypeOfWork = async (id: string) => {
      let type = await getTypeOfWork(id);
      if (!type) return setDeactivatedTypeOfWorkName("unknown");
      setDeactivatedTypeOfWorkName(type.name!)
    }
    workOrderForm.values.typeOfWorkId !== "" && typesOfWork.find(type => type.id === workOrderForm.values.typeOfWorkId) === undefined && fetchDeactivatedTypeOfWork(workOrderForm.values.typeOfWorkId);
  }, [workOrderForm.values.typeOfWorkId])

  async function handleSubmit() {
    let supervisors = workOrderForm.values.supervisors.map((s: any) => JSON.parse(s).id);
    let workOrderAssignees: string[] = employeesWithIsAssignedProp.filter((assignee: Assignee) => assignee.isAssigned).map(e => e.id!);

    const newWorkOrder = new CreateWorkOrderRequest();
    newWorkOrder.typeOfWorkId = workOrderForm.values.typeOfWorkId;
    newWorkOrder.locationCode = workOrderForm.values.locationCode;
    newWorkOrder.address = workOrderForm.values.address;
    newWorkOrder.city = workOrderForm.values.city;
    newWorkOrder.coordinates = workOrderForm.values.coordinates;
    newWorkOrder.assignedTo = workOrderAssignees;
    newWorkOrder.supervisors = supervisors;
    newWorkOrder.description = workOrderForm.values.description;
    newWorkOrder.deviceOnLocation = workOrderForm.values.deviceOnLocation;
    newWorkOrder.deviceToBeChanged = workOrderForm.values.deviceToBeChanged;
    newWorkOrder.received = workOrderForm.values.received && new Date(workOrderForm.values.received);
    newWorkOrder.orderSentBy = workOrderForm.values.orderSentBy;
    newWorkOrder.startDate = new Date(workOrderForm.values.startDate);
    newWorkOrder.endDate = new Date(workOrderForm.values.endDate);

    setIsSubmitButtonDisabled(true);
    if (workOrderForm.values.id) {
      const updatedWorkOrder = new UpdateWorkOrderRequest(newWorkOrder);
      updatedWorkOrder.id = workOrderForm.values.id;
      updatedWorkOrder.status = status.value;
      await updateWorkOrder(updatedWorkOrder);
    } else {
      await createWorkOrder(newWorkOrder);
    }
    setRerender(!rerender);
    setIsOpen(false);
    setIsSubmitButtonDisabled(false);
  }

  const getFullnameOfAssignees = (assignees: Assignee[]): JSX.Element => <Box>{assignees.map((assignee: Assignee) => <Typography variant="body2"> {`${assignee.firstName} ${assignee.lastName}`}</Typography>)}</Box>

  /**
   * Disable dialog close and allow close search assignees tab on escape key
   * @param event - Event triggered by keyboard key press
   */
  const handleClose = (event: React.KeyboardEvent) => {
    if (event.key === "Escape") {
      event.stopPropagation();
      event.preventDefault();
      return isEditAssigneesMenuOpen && setIsEditAsssigneesMenuOpen(false);
    }
    setIsOpen(false);
    handleCloseWorkOrderForm && handleCloseWorkOrderForm();
  };

  return (
    <Dialog open={isOpen} onClose={(event: React.KeyboardEvent) => handleClose(event as React.KeyboardEvent)}>
      <form onSubmit={workOrderForm.handleSubmit}>
        <Box width="80%" height="90vh" maxHeight="calc(100% - 50px)" position="fixed" top="0" left="0" marginTop={SPACING_LARGE} marginLeft="10%" sx={{ backgroundColor: APP_BG_COLOR, overflowY: "auto" }} borderRadius={BORDER_RADIUS_MEDIUM}>
          {/* Work Order Corner Icon */}
          <Box width="70px" height="70px" position="fixed" zIndex={3} boxShadow={DARK_BOX_SHADOW} borderRadius={BORDER_RADIUS_MEDIUM} marginLeft={SPACING_LARGE} sx={{ backgroundColor: APP_BG_COLOR, transform: "translateY(-50%)" }}>
            <Box display="flex" justifyContent="center">
              <ContentPasteGoIcon color="action" fontSize="large" sx={{ marginTop: "16px" }} />
            </Box>
          </Box>
          <Box sx={{ cursor: "pointer", position: "absolute", right: "0", padding: SPACING_SMALL }} onClick={() => setIsOpen(false)}>
            <CloseIcon sx={{ color: ICON_COLOR }} />
          </Box>
          {/* Form Container */}
          <Box padding={SPACING_LARGE} marginTop={SPACING_EXTRA_SMALL} display="flex" gap={SPACING_MEDIUM} flexDirection={{ xl: "row", lg: "row", md: "column", sm: "column", xs: "column" }}>
            {/* Left form side */}
            <Box display="flex" flexDirection="column" gap={SPACING_SMALL} width={{ xl: "50%", lg: "50%", md: "100%", sm: "100%", xs: "100%" }}>
              <Typography variant="h6">{t(TranslationKeyEnum.workOrder)}</Typography>
              <Typography variant="body2">{t(TranslationKeyEnum.createWorkOrderLabel)}</Typography>
              {/* Form Fields */}
              <Box display="flex" flexDirection="column" gap={SPACING_MEDIUM} marginTop={SPACING_SMALL}>
                <StyledReadonlyTextField
                  id="workOrderNumber"
                  name="workOrderNumber"
                  placeholder={(t(TranslationKeyEnum.workOrderNumberPlaceholder))}
                  label={t(TranslationKeyEnum.workOrderNumber)}
                  value={workOrderForm.values.workOrderNumber}
                  InputLabelProps={{ shrink: true }}
                  disabled
                />
                <FormControl fullWidth>
                  <InputLabel shrink sx={workOrderForm.errors.typeOfWorkId && workOrderForm.touched.typeOfWorkId && { color: ERROR_MESSAGE }}>{t(TranslationKeyEnum.typeOfWork)}</InputLabel>
                  <StyledSelectField
                    id="typeOfWorkId"
                    name="typeOfWorkId"
                    label={t(TranslationKeyEnum.typeOfWork)}
                    value={workOrderForm.values.typeOfWorkId}
                    onChange={workOrderForm.handleChange}
                    fullWidth
                    notched={true}
                    error={workOrderForm.touched.typeOfWorkId && Boolean(workOrderForm.errors.typeOfWorkId)}
                    displayEmpty={true}
                    renderValue={() => workOrderForm.values.typeOfWorkId
                      ? typesOfWork.find(type => type.id === workOrderForm.values.typeOfWorkId)
                        ? typesOfWork.find(type => type.id === workOrderForm.values.typeOfWorkId)?.name
                        : deactivatedTypeOfWorkName
                      : <InputLabel sx={{ color: "#C8C8C8" }}>{t(TranslationKeyEnum.selectTypeOfWork)}</InputLabel>}
                    disabled={!isSupervisor}
                  >
                    {
                      isDataLoading ? <Box height={30}><Loader size={30} /></Box> : typesOfWork?.map((work) => <MenuItem value={work.id} key={work.id}>{work.name}</MenuItem>)
                    }
                  </StyledSelectField>
                  {workOrderForm.errors.typeOfWorkId && workOrderForm.touched.typeOfWorkId && <DropdownErrorText />}
                </FormControl>
                <StyledTextField
                  id="locationCode"
                  name="locationCode"
                  placeholder={(t(TranslationKeyEnum.locationCode))}
                  label={t(TranslationKeyEnum.locationCode)}
                  value={workOrderForm.values.locationCode}
                  onChange={workOrderForm.handleChange}
                  InputLabelProps={{ shrink: true }}
                  error={workOrderForm.touched.locationCode && Boolean(workOrderForm.errors.locationCode)}
                  helperText={workOrderForm.touched.locationCode && workOrderForm.errors.locationCode}
                  disabled={!isSupervisor}
                />
                <StyledTextField
                  id="address"
                  name="address"
                  placeholder={(t(TranslationKeyEnum.address))}
                  label={t(TranslationKeyEnum.address)}
                  value={workOrderForm.values.address}
                  onChange={workOrderForm.handleChange}
                  InputLabelProps={{ shrink: true }}
                  disabled={!isSupervisor}
                />
                <StyledTextField
                  id="city"
                  name="city"
                  placeholder={(t(TranslationKeyEnum.city))}
                  label={t(TranslationKeyEnum.city)}
                  value={workOrderForm.values.city}
                  onChange={workOrderForm.handleChange}
                  InputLabelProps={{ shrink: true }}
                  disabled={!isSupervisor}
                />
                <StyledTextField
                  id="coordinates"
                  name="coordinates"
                  placeholder={(t(TranslationKeyEnum.coordinates))}
                  label={t(TranslationKeyEnum.coordinates)}
                  value={workOrderForm.values.coordinates}
                  onChange={workOrderForm.handleChange}
                  InputLabelProps={{ shrink: true }}
                  disabled={!isSupervisor}
                />
              </Box>
              <Typography
                maxWidth="fit-content"
                variant="body1"
                marginTop={SPACING_SMALL}
                sx={{ color: workOrderForm.values.coordinates ? LIGHT_BG_COLOR : "grey", cursor: "pointer" }}
                onClick={() => workOrderForm.values.coordinates && setIsMapOpen(true)}>
                {
                  workOrderForm.values.coordinates
                    ? t(TranslationKeyEnum.openMap)
                    : t(TranslationKeyEnum.mapLinkLabel)
                }
              </Typography>
            </Box>
            {/* Right form side */}
            <Box display="flex" flexDirection="column" gap={SPACING_SMALL} width={{ xl: "50%", lg: "50%", md: "100%", sm: "100%", xs: "100%" }}>
              <FormControl fullWidth>
                <InputLabel shrink>{t(TranslationKeyEnum.supervisors)}</InputLabel>
                <StyledSelectField
                  id="supervisors"
                  name="supervisors"
                  value={workOrderForm.values.supervisors}
                  onChange={workOrderForm.handleChange}
                  fullWidth
                  multiple
                  input={<OutlinedInput id="select-multiple-chip" label={t(TranslationKeyEnum.supervisors)} />}
                  notched={true}
                  displayEmpty={true}
                  disabled={!isSupervisor}
                  renderValue={(selected: any) =>
                    workOrderForm.values.supervisors.length
                      ? <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selected.map((value: string) => { return <Chip key={value} label={`${JSON.parse(value).firstName} ${JSON.parse(value).lastName}`} /> })}</Box>
                      : <InputLabel sx={{ color: "#C8C8C8" }}>{t(TranslationKeyEnum.selectSupervisors)}</InputLabel>
                  }
                  MenuProps={MenuProps}
                >
                  {
                    isDataLoading ? <Box height={30}><Loader size={30} /></Box> : employees.filter((e: UserDto) => e.supervisor).map((supervisor: UserDto) => <MenuItem value={JSON.stringify(supervisor)} key={supervisor.id}>
                      <ListItemText primary={`${supervisor.firstName} ${supervisor.lastName}`} />
                    </MenuItem>)
                  }
                </StyledSelectField>
              </FormControl>
              <Box>
                <Box display="flex" flexDirection={{ xl: "row", lg: "row", md: "row", sm: "column", xs: "column" }} gap={SPACING_EXTRA_SMALL} justifyContent="space-between" width="100%">
                  <Box display="flex" gap={SPACING_EXTRA_SMALL} width="100%" >
                    <Box width="100%" >
                      <Box display="flex" flexDirection="column" gap={SPACING_MEDIUM} position="relative" >
                        <Box marginTop={SPACING_SMALL} display="flex" flexDirection="column" gap={SPACING_EXTRA_SMALL} marginBottom=".6vh">
                          <Typography variant="body2" >{t(TranslationKeyEnum.status)}</Typography>
                          <Chip
                            disabled={!workOrderForm.values.id}
                            label={status.label}
                            variant="outlined"
                            sx={status.sx}
                            icon={status.icon}
                            color={status.color}
                            // with delete icon property we can add another icon to the end of the chip
                            deleteIcon={<KeyboardArrowDownIcon style={{ color: status.arrowColor }} />}
                            onDelete={() => isSupervisor && setIsStatusMenuOpen(!isStatusMenuOpen)}
                            onClick={() => isSupervisor && setIsStatusMenuOpen(!isStatusMenuOpen)}
                          />
                          {
                            isStatusMenuOpen
                            && <ClickAwayListener onClickAway={() => setIsStatusMenuOpen(false)}>
                              <Box display="flex" flexDirection="column" gap={SPACING_EXTRA_SMALL} position="absolute" top="26%" zIndex={2} padding={SPACING_EXTRA_SMALL} sx={{ boxShadow: DARK_BOX_SHADOW, borderRadius: BORDER_RADIUS_MEDIUM, backgroundColor: PRIMARY_BG_COLOR }}>
                                {
                                  STATUSES.filter((s: StatusProps) => s.label !== status.label).map((s: StatusProps) => {
                                    return <Chip
                                      label={s.label}
                                      variant="outlined"
                                      color={s.color}
                                      icon={s.icon}
                                      sx={s.sx}
                                      onClick={() => { setStatus(s); setIsStatusMenuOpen(false); }}
                                    />
                                  })
                                }
                              </Box>
                            </ClickAwayListener>
                          }
                        </Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          {
                            isMobile
                              ? <Box>
                                <DatePicker
                                  label={t(TranslationKeyEnum.workOrderReceived)}
                                  format="DD/MM/YYYY"
                                  value={isDateYearOne(workOrderForm.values.received) ? null : dayjs(workOrderForm.values.received)}
                                  onChange={selectedDate => workOrderForm.setFieldValue("received", selectedDate)}
                                  closeOnSelect
                                  disabled={!isSupervisor}
                                  slots={{ textField: StyledTextField as React.ElementType<TextFieldProps> }}
                                  slotProps={{
                                    textField: {
                                      sx: { svg: { color: LIGHT_BG_COLOR }, input: { cursor: "pointer" }, width: "100%" },
                                      InputLabelProps: { shrink: true },
                                      autoComplete: "off",
                                      disabled: !isSupervisor
                                    },
                                  }}
                                />
                              </Box>
                              : <CustomDatePicker
                                label={t(TranslationKeyEnum.workOrderReceived)}
                                value={isDateYearOne(workOrderForm.values.received) ? null : workOrderForm.values.received}
                                inputFormat="DD/MM/YYYY"
                                handleDatePick={selectedDate => workOrderForm.setFieldValue("received", selectedDate)}
                                isOpen={isWorkOrderReceivedDateOpen}
                                setIsOpen={setIsWorkOrderReceivedDateOpen}
                                isDisabled={!isSupervisor}
                              />
                          }
                        </LocalizationProvider>
                        <StyledTextField
                          id="deviceToBeChanged"
                          name="deviceToBeChanged"
                          placeholder={(t(TranslationKeyEnum.deviceToBeChanged))}
                          label={t(TranslationKeyEnum.deviceToBeChanged)}
                          value={workOrderForm.values.deviceToBeChanged}
                          onChange={workOrderForm.handleChange}
                          InputLabelProps={{ shrink: true }}
                          disabled={!isSupervisor}
                          fullWidth
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          {
                            isMobile
                              ? <Box>
                                <DatePicker
                                  label={t(TranslationKeyEnum.startDate)}
                                  format="DD/MM/YYYY"
                                  value={dayjs(workOrderForm.values.startDate)}
                                  onChange={selectedDate => { workOrderForm.setFieldValue("startDate", selectedDate); workOrderForm.setFieldValue("endDate", selectedDate) }}
                                  closeOnSelect
                                  disabled={!isSupervisor}
                                  slots={{ textField: StyledTextField as React.ElementType<TextFieldProps> }}
                                  slotProps={{
                                    textField: {
                                      sx: { svg: { color: LIGHT_BG_COLOR }, input: { cursor: "pointer" }, width: "100%" },
                                      InputLabelProps: { shrink: true },
                                      autoComplete: "off",
                                      disabled: !isSupervisor
                                    },
                                  }}
                                />
                              </Box>
                              : <CustomDatePicker
                                label={t(TranslationKeyEnum.startDate)}
                                value={workOrderForm.values.startDate}
                                inputFormat="DD/MM/YYYY"
                                handleDatePick={selectedDate => { workOrderForm.setFieldValue("startDate", selectedDate); selectedDate! > workOrderForm.values.endDate && workOrderForm.setFieldValue("endDate", selectedDate) }}
                                isOpen={isStartDateOpen}
                                setIsOpen={setIsStartDateOpen}
                                isDisabled={!isSupervisor}
                              />
                          }
                        </LocalizationProvider>
                      </Box>
                    </Box>
                  </Box>
                  <Box width="100%">
                    <Box display="flex" flexDirection="column" gap={SPACING_MEDIUM} position="relative" width="100%">
                      <Box>
                        <Box marginTop={SPACING_SMALL} display="flex" flexDirection="column" gap={SPACING_EXTRA_SMALL} marginBottom=".8vh">
                          <Typography variant="body2">{t(TranslationKeyEnum.assignedTo)}:</Typography>
                          <Box display="flex" justifyContent="space-between">
                            <Tooltip title={getFullnameOfAssignees(employeesWithIsAssignedProp?.filter((assignee: Assignee) => assignee.isAssigned))}>
                              <AvatarGroup max={4} sx={{ marginRight: "auto", cursor: "pointer", position: "absolute" }}>
                                {
                                  employeesWithIsAssignedProp?.filter((assignee: Assignee) => assignee.isAssigned).map((assignee: Assignee) => {
                                    return <Avatar alt={`${assignee.firstName} ${assignee.lastName}`} src={assignee.image && `data:image/png;base64,${assignee.image}`}>
                                      {`${assignee.firstName?.charAt(0)}${assignee.lastName?.charAt(0)}`}
                                    </Avatar>
                                  })
                                }
                              </AvatarGroup>
                            </Tooltip>
                            <Button size="small" variant="text" sx={{ textTransform: "none", color: LIGHT_BG_COLOR, marginLeft: "auto" }} onClick={() => isSupervisor && setIsEditAsssigneesMenuOpen(!isEditAssigneesMenuOpen)}>
                              <Box display="flex">
                                <EditIcon fontSize="small" sx={{ color: LIGHT_BG_COLOR, marginRight: SPACING_EXTRA_SMALL }} />
                                {t(TranslationKeyEnum.edit)}
                              </Box>
                            </Button>
                          </Box>
                          {
                            isEditAssigneesMenuOpen && <ClickAwayListener onClickAway={() => setIsEditAsssigneesMenuOpen(false)} >
                              <Box width="100%" height="38vh" position="absolute" top="30%" zIndex={2} sx={{ boxShadow: DARK_BOX_SHADOW, borderRadius: BORDER_RADIUS_MEDIUM, padding: SPACING_SMALL, backgroundColor: PRIMARY_BG_COLOR, overflowY: "auto" }}>
                                <StyledTextField
                                  id="searchEmployee"
                                  name="searchEmployee"
                                  placeholder={(t(TranslationKeyEnum.searchEmployee))}
                                  label={t(TranslationKeyEnum.searchEmployee)}
                                  value={search}
                                  onChange={(event: any) => setSearch(event.target.value)}
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end" sx={{ cursor: "pointer" }} onClick={() => setSearch("")}>
                                        <CloseIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                {
                                  employeesWithIsAssignedProp?.filter((employee: Assignee) => {
                                    let fullname = `${employee.firstName} ${employee.lastName}`;
                                    return fullname.toLowerCase().includes(search.toLowerCase())
                                  }).map((employee: Assignee) => {
                                    return <Box key={employee.id} display="flex" alignItems="center" sx={{ cursor: "pointer" }} onClick={() => {
                                      employee.isAssigned = !employee.isAssigned;
                                      setEmployeesWithIsAssignedProp(employeesWithIsAssignedProp);
                                      setRerender(!rerender);
                                    }} >
                                      <Checkbox checked={employee.isAssigned} style={{ color: employee.isAssigned ? LIGHT_BG_COLOR : "grey" }} />
                                      <Typography variant="body1">{employee.firstName} {employee.lastName}</Typography>
                                    </Box>
                                  })
                                }
                              </Box>
                            </ClickAwayListener>
                          }
                        </Box>
                      </Box>
                      <StyledTextField
                        id="orderSentBy"
                        name="orderSentBy"
                        placeholder={(t(TranslationKeyEnum.orderSentBy))}
                        label={t(TranslationKeyEnum.orderSentBy)}
                        value={workOrderForm.values.orderSentBy}
                        onChange={workOrderForm.handleChange}
                        InputLabelProps={{ shrink: true }}
                        disabled={!isSupervisor}
                        fullWidth
                      />
                      <StyledTextField
                        id="deviceOnLocation"
                        name="deviceOnLocation"
                        placeholder={(t(TranslationKeyEnum.deviceOnLocation))}
                        label={t(TranslationKeyEnum.deviceOnLocation)}
                        value={workOrderForm.values.deviceOnLocation}
                        onChange={workOrderForm.handleChange}
                        InputLabelProps={{ shrink: true }}
                        disabled={!isSupervisor}
                        fullWidth
                      />
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {
                          isMobile
                            ? <Box>
                              <DatePicker
                                label={t(TranslationKeyEnum.endDate)}
                                format="DD/MM/YYYY"
                                value={workOrderForm.values.endDate && dayjs(workOrderForm.values.endDate)}
                                onChange={newValue => workOrderForm.setFieldValue("endDate", newValue)}
                                closeOnSelect
                                disabled={!isSupervisor}
                                shouldDisableDate={(day: Date) => compareDates(day, workOrderForm.values.startDate)}
                                slots={{ textField: StyledTextField as React.ElementType<TextFieldProps> }}
                                slotProps={{
                                  textField: {
                                    sx: { svg: { color: LIGHT_BG_COLOR }, input: { cursor: "pointer" }, width: "100%" },
                                    InputLabelProps: { shrink: true },
                                    autoComplete: "off",
                                    disabled: !isSupervisor
                                  },
                                }}
                              />
                            </Box>
                            : <CustomDatePicker
                              label={t(TranslationKeyEnum.endDate)}
                              value={workOrderForm.values.endDate}
                              inputFormat="DD/MM/YYYY"
                              handleDatePick={newValue => workOrderForm.setFieldValue("endDate", newValue)}
                              isOpen={isEndDateOpen}
                              setIsOpen={setIsEndDateOpen}
                              isDisabled={!isSupervisor}
                              shouldDisableDate={(day: Date) => compareDates(day, workOrderForm.values.startDate)}
                            />
                        }
                      </LocalizationProvider>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <StyledTextField
                id="description"
                name="description"
                label={t(TranslationKeyEnum.description)}
                multiline
                rows={5}
                fullWidth
                value={workOrderForm.values.description}
                onChange={workOrderForm.handleChange}
                InputLabelProps={{ shrink: true }}
                disabled={!isSupervisor}
                sx={{ marginTop: SPACING_SMALL }}
              />
              <Box display="flex" marginLeft="auto" gap={SPACING_EXTRA_SMALL}>
                <Button sx={{ color: LIGHT_BG_COLOR }} onClick={() => setIsOpen(false)}>{isSupervisor ? t(TranslationKeyEnum.cancel) : t(TranslationKeyEnum.close)}</Button>
                {
                  isSupervisor && <Button type="submit" variant="contained" sx={{ backgroundColor: LIGHT_BG_COLOR }} disabled={isDataLoading || isSubmitButtonDisabled}>{workOrderForm.values.id ? t(TranslationKeyEnum.updateWorkOrder) : t(TranslationKeyEnum.createWorkOrder)}</Button>
                }
              </Box>
            </Box>
          </Box>
        </Box>
      </form>
      {
        isMapOpen && <MapDialog isOpen={isMapOpen} setIsOpen={setIsMapOpen} coordinates={workOrderForm.values.coordinates} />
      }
    </Dialog>
  );
}