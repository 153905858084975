import { getTokenFromStorage } from "../utils/storageUtils/storageUtils";

const decodeBase64 = (base64: string) => {
    const padding = "=".repeat((4 - (base64.length % 4)) % 4);
    const base64Url = base64.replace(/-/g, "+").replace(/_/g, "/") + padding;
    return atob(base64Url);
};

export const getPropertyFromJwtToken = (propertyName: string) => {
    const token = getTokenFromStorage();
    if (!token) return "";
    const [headerB64, payloadB64, signature] = token.split(".");
    const payload = JSON.parse(decodeBase64(payloadB64));
    return payload[propertyName];
}