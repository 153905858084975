import React from "react";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { TranslationKeyEnum } from "../../features/translations/TranslationKeyEnum";
import { Typography, ClickAwayListener, Grid, Box, TextFieldProps } from "@mui/material";
import { GridColDef, GridPaginationModel, GridRowSelectionModel } from "@mui/x-data-grid";
import { useGetAllUsers } from "../../API/user-actions";
import { IEmployeesTableProps } from "../AdminEmployees/IEmployeesTableProps";
import { BankHolidayBasicDto, BankHolidayRequest, BankHolidayWithUsersDto } from "../../API/time-off-service";
import { LanguageEnum } from "../../features/translations/LanguageEnum";
import { srRS } from "../../features/translations/DataGrid/srRS";
import { useAddEditBankHolidayFormikForm } from "./addEditFormikForm";
import { FormikValues, useFormik } from "formik";
import { IAddEditBankHolidayFormProps } from "./IAddEditBankHolidayFormProps";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { addDays } from "../common/Calendar/helpers";
import { ROWS_PER_PAGE_OPTIONS } from "../common/Constants/PaginationConstants";
import { ICON_COLOR, SPACING_EXTRA_SMALL, SPACING_MEDIUM, SPACING_SMALL } from "../../utils/cssUtils";
import { StyledDataGrid } from "../StyledDataGrid/StyledDataGrid";
import { StyledTextField } from "../StyledTextfield/StyledTextField";
import { getDateOrNull } from "../../containers/TimeOff/helpers";

export default (props: IAddEditBankHolidayFormProps) => {
    const { t, i18n } = useTranslation();
    const [users, setUsers] = React.useState<IEmployeesTableProps[]>();
    const [bankHoliday, setBankHoliday] = React.useState<BankHolidayWithUsersDto>(new BankHolidayWithUsersDto({
        id: '',
        name: '',
        startDate: new Date(),
        endDate: new Date(),
    }));
    const getUsers = useGetAllUsers();
    const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const formikBankHolidayForm = useAddEditBankHolidayFormikForm();
    const [page, setPage] = React.useState<number>(0);
    const [pageSize, setPageSize] = React.useState<number>(ROWS_PER_PAGE_OPTIONS[0]);
    const [isStartDateOpen, setIsStartDateOpen] = React.useState<boolean>(false);
    const [isEndDateOpen, setIsEndDateOpen] = React.useState<boolean>(false);
    const [isUsersDataLoading, setIsUsersDataLoading] = React.useState<boolean>(true);

    const handlePaginationModelChange = (model: GridPaginationModel) => {
        setPage(model.page);
        setPageSize(model.pageSize);
    }

    const handleSubmit = async () => {
        const updateRequest = new BankHolidayBasicDto({
            id: bankHolidayForm.values.id,
            name: bankHolidayForm.values.name,
            startDate: bankHolidayForm.values.startDate,
            endDate: bankHolidayForm.values.endDate,
            users: selectionModel as string[]
        });
        const createRequest = new BankHolidayRequest({
            name: bankHolidayForm.values.name,
            startDate: bankHolidayForm.values.startDate,
            endDate: bankHolidayForm.values.endDate,
            users: selectionModel as string[]
        });
        if (bankHoliday.id && !props.isCopyBankHoliday ? await props.submitUpdate(updateRequest) : await props.submitCreate(createRequest)) {
            resetForm();
        }
    }

    const handleClose = () => {
        resetForm();
        props.close();
    }

    const resetForm = () => {
        setBankHoliday(new BankHolidayWithUsersDto());
        setSelectionModel([]);
        bankHolidayForm.resetForm();
    }

    const alignStartAndEndDate = (selectedDate: Date) => {
        bankHolidayForm.setFieldValue("endDate", new Date(selectedDate!));
        bankHolidayForm.setFieldValue("startDate", new Date(selectedDate!));
    }

    const bankHolidayForm: FormikValues = useFormik(formikBankHolidayForm(bankHoliday, handleSubmit));

    React.useEffect(() => {
        const fetchData = async () => {
            setUsers(await getUsers());
            setIsUsersDataLoading(false);
        };
        fetchData();
    }, [])

    React.useEffect(() => {
        const tempBankHoliday = props.bankHoliday === undefined ? new BankHolidayWithUsersDto({
            id: '',
            name: '',
            startDate: new Date(),
            endDate: new Date(),
        }) : props.bankHoliday;
        const tempSelectionModel = props.bankHoliday === undefined ? [] : props.bankHoliday!.users!.map(u => u.id!);
        setBankHoliday(tempBankHoliday)
        setSelectionModel(tempSelectionModel)
        bankHolidayForm.setFieldValue("id", tempBankHoliday.id)
        bankHolidayForm.setFieldValue("name", tempBankHoliday.name)
        // if we copy bank holiday we add one year to start and end date
        props.isCopyBankHoliday
            ? bankHolidayForm.setFieldValue("startDate", new Date(tempBankHoliday.startDate!.setFullYear(tempBankHoliday.startDate!.getFullYear() + 1)))
            : bankHolidayForm.setFieldValue("startDate", tempBankHoliday.startDate)
        props.isCopyBankHoliday
            ? bankHolidayForm.setFieldValue("endDate", new Date(tempBankHoliday.endDate!.setFullYear(tempBankHoliday.endDate!.getFullYear() + 1)))
            : bankHolidayForm.setFieldValue("endDate", tempBankHoliday.endDate)
    }, [props.bankHoliday?.id])

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID' },
        { field: 'firstName', headerName: t(TranslationKeyEnum.firstName), flex: 1 },
        { field: 'lastName', headerName: t(TranslationKeyEnum.lastName), flex: 1 },
        { field: 'position', headerName: t(TranslationKeyEnum.position), flex: 1 },
    ];

    return (
        <Grid>
            <Dialog open={props.open} onClose={handleClose}>
                <Box position="absolute" right="0" padding={SPACING_SMALL} sx={{ cursor: "pointer" }} onClick={handleClose}>
                    <CloseIcon sx={{ color: ICON_COLOR }} />
                </Box>
                <Box width={{ xl: "50vw", lg: "50vw", md: "60vw", sm: "70vw", xs: "80vw" }} padding={SPACING_MEDIUM}>
                    <Typography variant="h5" marginBottom={SPACING_MEDIUM}>
                        {t(bankHoliday.id
                            ? props.isCopyBankHoliday
                                ? TranslationKeyEnum.copyBankHoliday
                                : TranslationKeyEnum.editBankHoliday
                            : TranslationKeyEnum.addBankHoliday)}
                    </Typography>
                    <Box>
                        <form onSubmit={bankHolidayForm.handleSubmit}>
                            <Box display="flex" justifyContent="space-between" margin={`${SPACING_SMALL} 0`}>
                                <StyledTextField
                                    label={t(TranslationKeyEnum.name)}
                                    variant="outlined"
                                    style={{ width: "32%" }}
                                    name="name"
                                    id="name"
                                    type="text"
                                    value={bankHolidayForm.values.name}
                                    onChange={bankHolidayForm.handleChange}
                                    error={bankHolidayForm.touched.name && Boolean(bankHolidayForm.errors.name)}
                                    helperText={bankHolidayForm.touched.name && bankHolidayForm.errors.name}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <ClickAwayListener onClickAway={() => setIsStartDateOpen(false)}>
                                        <Box width="32%">
                                            <DatePicker
                                                label={t(TranslationKeyEnum.startDate)}
                                                value={dayjs(bankHolidayForm.values.startDate)}
                                                open={isStartDateOpen}
                                                onChange={(selectedDate) => { alignStartAndEndDate(getDateOrNull(selectedDate)!); setIsStartDateOpen(false); }}
                                                slots={{ textField: StyledTextField as React.ElementType<TextFieldProps> }}
                                                slotProps={{
                                                    textField: {
                                                        sx: { width: "100%", input: { cursor: "pointer" } },
                                                        autoComplete: "off",
                                                        onClick: () => setIsStartDateOpen(!isStartDateOpen)
                                                    },
                                                }}
                                                format="DD/MM/YYYY"
                                            />
                                        </Box>
                                    </ClickAwayListener>
                                    <ClickAwayListener onClickAway={() => setIsEndDateOpen(false)}>
                                        <Box width="32%">
                                            <DatePicker
                                                label={t(TranslationKeyEnum.endDate)}
                                                value={dayjs(bankHolidayForm.values.endDate) as unknown as Date}
                                                open={isEndDateOpen}
                                                onChange={(selectedDate) => { bankHolidayForm.setFieldValue("endDate", new Date(selectedDate!)); setIsEndDateOpen(false); }}
                                                slots={{ textField: TextField }}
                                                slotProps={{
                                                    textField: {
                                                        sx: { width: "100%", input: { cursor: "pointer" } },
                                                        autoComplete: "off",
                                                        onClick: () => setIsEndDateOpen(!isEndDateOpen)
                                                    },
                                                }}
                                                format="DD/MM/YYYY"
                                                shouldDisableDate={(day: Date) => bankHolidayForm.values.startDate > addDays(new Date(day), 1)}
                                            />
                                        </Box>
                                    </ClickAwayListener>
                                </LocalizationProvider>
                            </Box>
                            <Box height="50vh" overflow="auto">
                                <Grid container padding={SPACING_EXTRA_SMALL}>
                                    <Grid item xl={9} md={6}>
                                        <Typography variant="h5">{t(TranslationKeyEnum.employees)}</Typography>
                                    </Grid>
                                </Grid>
                                <StyledDataGrid
                                    columnVisibilityModel={{
                                        id: false,
                                    }}
                                    localeText={i18n.language === LanguageEnum.Serbian ? srRS.components.MuiDataGrid.defaultProps.localeText : undefined}
                                    rows={users ?? []}
                                    autoHeight={true}
                                    columns={columns}
                                    paginationModel={{ page, pageSize }}
                                    onPaginationModelChange={handlePaginationModelChange}
                                    pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
                                    pagination
                                    checkboxSelection
                                    onRowSelectionModelChange={(newSelectionModel: GridRowSelectionModel) => {
                                        setSelectionModel(newSelectionModel)
                                    }}
                                    rowSelectionModel={selectionModel}
                                    loading={isUsersDataLoading}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: SPACING_MEDIUM }}>
                                <Button color="inherit" onClick={handleClose}>
                                    {t(TranslationKeyEnum.cancel)}
                                </Button>
                                <Button type="submit" variant="contained">{t(TranslationKeyEnum.submit)}</Button>
                            </Box>
                        </form>
                    </Box>
                </Box>
            </Dialog>
        </Grid>
    );
}