import React from 'react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import WorkOrdersHistory from '../WorkOrdersHistory/WorkOrdersHistory';
import DoneWorkOrdersTable from '../MonthSummaryTable/DoneWorkOrdersTable';
import { Box, Typography, Button } from '@mui/material';
import { INFO_CARD_MIN_WIDTH, INFO_CARD_WIDTH, INFO_CARD_WIDTH_SM, INFO_CARD_WIDTH_XS, SPACING_EXTRA_SMALL, SPACING_MEDIUM, SPACING_SMALL } from '../../../utils/cssUtils';
import { useTranslation } from 'react-i18next';
import { TranslationKeyEnum } from '../../../features/translations/TranslationKeyEnum';
import { getTextTransformationAccordingToLanguage } from '../../../features/translations/helpers';
import { getMonthNameAndYear } from '../../../utils/dateUtils';
import { useGetMonthlySummaryForMe } from '../../../API/work-order-actions';
import { MonthlyUserSummaryFilter, SingleUserMonthlySummaryDto } from '../../../API/work-order-service';
import { isObjectEmpty } from '../../../components/common/UserProfile/helpers';
import { IMonthlySummaryProps } from './IMonthlySummaryProps';

interface IDoneWorkOrderProps {
    name: string,
    times: number
}

export default function MonthlySummary({rerender, setRerender}: IMonthlySummaryProps) {
    const { t } = useTranslation();
    const getMonthlySummaryForMe = useGetMonthlySummaryForMe();

    const [selectedMonth, setSelectedMonth] = React.useState<Date>(new Date());
    const [monthlySummaryData, setMonthlySummaryData] = React.useState<SingleUserMonthlySummaryDto>(new SingleUserMonthlySummaryDto({ workOrders: [], workOrdersCountByType: {} }));
    const [doneWorkOrders, setDoneWorkOrders] = React.useState<IDoneWorkOrderProps[]>([]);
    const [isWorkOrdersHistoryOpen, setIsWorkOdersHistoryOpen] = React.useState<boolean>(false);

    React.useEffect(() => {
        const fetchData = async () => {
            // we send first day of a month as start date and last day of the month as a end date
            let monthlySummary = await getMonthlySummaryForMe(new MonthlyUserSummaryFilter({ start: new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1), end: new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0) }))
            if (!monthlySummary) return;
            setMonthlySummaryData(monthlySummary);
        }
        fetchData();
    }, [selectedMonth, rerender])

    React.useEffect(() => {
        if (monthlySummaryData.workOrdersCountByType && !isObjectEmpty(monthlySummaryData.workOrdersCountByType)) {
            const data = Object.entries(monthlySummaryData.workOrdersCountByType!)
                .map(([name, times]) => ({ name, times }));
            setDoneWorkOrders(data);
        }
    }, [monthlySummaryData]);

    return (
        <Box
            width={{ xl: INFO_CARD_WIDTH, lg: INFO_CARD_WIDTH, md: INFO_CARD_WIDTH, sm: INFO_CARD_WIDTH_SM, xs: INFO_CARD_WIDTH_XS }}
            minWidth={INFO_CARD_MIN_WIDTH}
            display="flex"
            flexDirection="column"
            margin="auto"
            paddingTop={SPACING_MEDIUM}
            gap={SPACING_SMALL}
        >
            <Box display="flex" alignItems="center">
                <CalendarTodayIcon />
                <Typography variant="h6" sx={{ padding: `0 ${SPACING_EXTRA_SMALL}` }}>{t(TranslationKeyEnum.monthlySummary)}</Typography>
                <Typography variant="h6" color="grey" textTransform="capitalize">({getMonthNameAndYear(new Date())})</Typography>
            </Box>
            <Typography variant="body1" sx={{ display: "flex" }}>{t(TranslationKeyEnum.monthlySummaryDescription)}</Typography>
            <DoneWorkOrdersTable doneWorkOrders={doneWorkOrders} />
            <Button variant="outlined" size="small" sx={{ width: "70%", textTransform: getTextTransformationAccordingToLanguage() }} onClick={() => setIsWorkOdersHistoryOpen(true)}>
                {t(TranslationKeyEnum.viewWorkOrderHistory)}
            </Button>
            {
                isWorkOrdersHistoryOpen && <WorkOrdersHistory isOpen={isWorkOrdersHistoryOpen} setIsOpen={setIsWorkOdersHistoryOpen} data={monthlySummaryData} month={new Date()} rerender={rerender} setRerender={setRerender} />
            }
        </Box>
    )
}