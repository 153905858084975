import { FormikConfig, FormikValues } from "formik";

export function usePermissionInfoFormikForm(): (handleSubmit: () => void) => FormikConfig<FormikValues> {
    function permissionInfoFormikForm(handleSubmit: () => void): FormikConfig<FormikValues> {
        return {
            initialValues: {
                permissions: []
            },
            onSubmit: handleSubmit
        };
    }
    return permissionInfoFormikForm;
}