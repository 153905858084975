import React from "react";
import LanguagePicker from "../../components/LanguagePicker/LanguagePicker";
import Loader from "../../components/Loader/Loader";
import companyLogo from "../../assets/images/ant.png";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useHandleLoginFormSubmit } from "./actions";
import { useTranslation } from "react-i18next";
import { getUserFromStorage } from "../../utils/storageUtils/storageUtils";
import { routeEnum } from "../../routes/routeEnum";
import { AuthenticateRequest } from "../../API/identity-service";
import { TranslationKeyEnum } from "../../features/translations/TranslationKeyEnum";
import { Button, Box, Avatar, Typography, Paper, ClickAwayListener } from "@mui/material";
import { useLoginValidationSchemas } from "./loginValidationSchema";
import { getLanguage } from "../../features/translations/i18n";
import { languages, LanguageType } from "../../features/translations/LanguageTypes";
import { APP_BG_COLOR, CARD_BOX_SHADOW, LIGHT_BG_COLOR, SPACING_MEDIUM, SPACING_SMALL } from "../../utils/cssUtils";
import ForgotPasswordDialog from "../ForgotPassword/ForgotPasswordDialog";
import { StyledTextField } from "../../components/StyledTextfield/StyledTextField";

const COMPANY_NAME = "AntyPlanner";

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const validationSchemas = useLoginValidationSchemas();
  const user = getUserFromStorage();
  let language = getLanguage();

  const [isLoginFormLoading, setIsLoginFormLoading] = React.useState(false);
  const [isLanguagePickerOpen, setIsLanguagePickerOpen] = React.useState(false);
  const [currentlySelectedLanguage, setCurrentlySelectedLanguage] = React.useState<LanguageType | undefined>();
  const [isForgotPasswordDialogOpen, setIsForgotPasswordDialogOpen] = React.useState(false);

  const handleLogin = useHandleLoginFormSubmit(setIsLoginFormLoading);

  React.useEffect(() => {
    user && navigate(`/${routeEnum.HOME}`)
  }, [user])

  React.useEffect(() => {
    let findSelectedLanguage = languages.find((l: LanguageType) => l.language === language);
    setCurrentlySelectedLanguage(findSelectedLanguage);
  }, [language])

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    } as AuthenticateRequest,
    validationSchema: validationSchemas,
    onSubmit: handleLogin
  })

  const handleForgotPasswordDialogClose = () => {
    setIsForgotPasswordDialogOpen(false);
  }

  return (
    <Box sx={{ backgroundColor: APP_BG_COLOR }} height="100vh">
      <Box display="flex" justifyContent="center" position="relative" sx={{ transform: "translateY(40%)" }} padding={`0 ${SPACING_MEDIUM}`}>
        <Box display="flex" justifyContent="center" flexDirection="column" width={{ xl: "24vw", lg: "24vw", md: "36vw", sm: "46vw", xs: "80vw" }}>
          {/* Logo & App name */}
          <Box display="flex" flexDirection="row" justifyContent="center" marginBottom={SPACING_SMALL}>
            <Avatar sx={{ marginRight: SPACING_SMALL }} src={companyLogo} />
            <Typography variant="h6" margin="auto 0" textAlign="center">{COMPANY_NAME}</Typography>
          </Box>
          <Box display="flex" justifyContent="center" marginBottom={SPACING_MEDIUM} >
            <Typography variant="h6">{t(TranslationKeyEnum.loginGreetings)}</Typography>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Box display="flex" flexDirection="column" >
              <StyledTextField
                fullWidth
                id="email"
                name="email"
                type="email"
                label={t(TranslationKeyEnum.email)}
                placeholder="bruce@wayne.com"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                disabled={isLoginFormLoading}
                InputLabelProps={{ shrink: true }}
                sx={{ marginBottom: SPACING_SMALL }}
              />
              {isLoginFormLoading && <Box position="absolute" left="0" right="0" ><Loader size={100} /></Box>}
              <StyledTextField
                fullWidth
                id="password"
                name="password"
                type="password"
                label={t(TranslationKeyEnum.password)}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                disabled={isLoginFormLoading}
                InputLabelProps={{ shrink: true }}
                sx={{ marginBottom: SPACING_MEDIUM }}
              />
            </Box>
            <Typography variant="body2" color="#2196F3" sx={{ cursor: "pointer", marginBottom: SPACING_MEDIUM }} onClick={() => setIsForgotPasswordDialogOpen(true)}>{t(TranslationKeyEnum.forgotPassword)}</Typography>
            <Button fullWidth type="submit" variant="contained" disabled={isLoginFormLoading} sx={{ backgroundColor: LIGHT_BG_COLOR }}>
              {t(TranslationKeyEnum.login)}
            </Button>
          </form>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" position="absolute" right="0" top="0" padding={`${SPACING_MEDIUM}`} sx={{ cursor: "pointer" }}
        onClick={() => setIsLanguagePickerOpen(!isLanguagePickerOpen)} >
        <Box width="30px" minWidth="20px" marginRight="1vh">
          <img src={currentlySelectedLanguage?.img} alt={currentlySelectedLanguage?.alt} />
        </Box>
        <Typography variant="body2" marginRight="1vh" marginTop="4%">
          {currentlySelectedLanguage?.label}
        </Typography>
        <ExpandMoreIcon color="action" sx={{ marginTop: "2%" }} />
        {
          isLanguagePickerOpen &&
          <ClickAwayListener onClickAway={() => setIsLanguagePickerOpen(false)}>
            <Paper sx={{ boxShadow: CARD_BOX_SHADOW, padding: "1vh", position: "absolute", marginTop: "5vh", marginLeft: "-2vh" }}>
              <LanguagePicker />
            </Paper>
          </ClickAwayListener>
        }
      </Box>
      {
        isForgotPasswordDialogOpen &&
        <ClickAwayListener onClickAway={() => setIsForgotPasswordDialogOpen(false)}>
          <ForgotPasswordDialog open={isForgotPasswordDialogOpen} onClose={handleForgotPasswordDialogClose} />
        </ClickAwayListener>
      }
    </Box>
  );
}

export default Login;
