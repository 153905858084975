import { CreateTimeOffRequest, RequestClient, ResponseMessage, TimeOffPoliciesAndUserDataVM, TimeOffPolicyVM, UserClient, EventClient, PolicyClient, TimeOffPolicyUserDto, AssignPolicyRequest, UnassignPolicyRequest, CreateTimeOffRequestForUser, DeleteTimeOffPolicyRequest, BasicTimeOffPolicyDto, DocumentRequest, FileResponse, EventPolicySummaryDto } from "../../API/time-off-service";
import { useTryCatchJsonByAction } from "../../utils/fetchUtils";

const userClient = new UserClient();
const getPoliciesAction = (): Promise<TimeOffPoliciesAndUserDataVM> => userClient.getPolicies();
const getPoliciesByUserAction = (userId: string): Promise<TimeOffPoliciesAndUserDataVM> => userClient.getPoliciesByUser(userId);
const getBasicPoliciesByUserAction = (userId: string): Promise<TimeOffPolicyVM[]> => userClient.getBasicPoliciesByUser(userId);

const eventClient = new EventClient();
const getPolicyEventsAction = (policyId: string): Promise<EventPolicySummaryDto[]> => eventClient.getByUserAndPolicy(policyId);
const getPolicyEventsByUserAction = (userId: string, policyId: string): Promise<EventPolicySummaryDto[]> => eventClient.getByUserAndPolicy2(userId, policyId);

const requestClient = new RequestClient();
const createRequestAction = (model: CreateTimeOffRequest): Promise<ResponseMessage> => requestClient.create(model);
const createRequestForUserAction = (model: CreateTimeOffRequestForUser): Promise<ResponseMessage> => requestClient.createForUser(model);
const generateDocumentAction = (request: DocumentRequest): Promise<FileResponse> => requestClient.generateDocument(request);

const policyClient = new PolicyClient();
const getPolicyWithUsersAction = (): Promise<TimeOffPolicyUserDto[]> => policyClient.getPoliciesWithUsers();
const getDeactivatedPoliciesAction = (): Promise<BasicTimeOffPolicyDto[]> => policyClient.getDeactivated();
const assignPolicyAction = (request: AssignPolicyRequest): Promise<ResponseMessage> => policyClient.assignPolicyToUsers(request);
const unassignPolicyAction = (request: UnassignPolicyRequest): Promise<ResponseMessage> => policyClient.unassignUserFromPolicy(request);
const deletePolicyAction = (deleteRequest: DeleteTimeOffPolicyRequest): Promise<ResponseMessage> => policyClient.remove(deleteRequest);

export function useGetPolicies(userId?: string): (userId?: string) => Promise<TimeOffPoliciesAndUserDataVM> {
    const tryCatchAction = useTryCatchJsonByAction();

    async function getPolicies(): Promise<TimeOffPoliciesAndUserDataVM> {
        const bindedGetPoliciesAction = userId ? getPoliciesByUserAction.bind(null, userId) : getPoliciesAction.bind(null);
        const policiesModel = await tryCatchAction(bindedGetPoliciesAction);

        // set hours because of datetime comparison
        policiesModel.response.policies.forEach((policy: TimeOffPolicyVM) => {
            policy.startDate!.setHours(0, 0, 0);
            policy.endDate!.setHours(23, 59, 59);
        });

        return policiesModel.response ?? new TimeOffPoliciesAndUserDataVM();
    }

    return getPolicies;
}

export function useGetBasicPolicies(): (userId: string) => Promise<TimeOffPolicyVM[]> {
    const tryCatchAction = useTryCatchJsonByAction();

    async function getBasicPolicies(userId: string): Promise<TimeOffPolicyVM[]> {
        const bindedGetBasicPoliciesAction = getBasicPoliciesByUserAction.bind(null, userId);
        const policiesModel = await tryCatchAction(bindedGetBasicPoliciesAction);

        // set hours because of datetime comparison
        policiesModel.response.forEach((policy: TimeOffPolicyVM) => {
            policy.startDate!.setHours(0, 0, 0);
            policy.endDate!.setHours(23, 59, 59);
        });

        return policiesModel.response ?? [];
    }

    return getBasicPolicies;
}

export function useCreateRequest(userId?: string): (model: CreateTimeOffRequest) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();

    async function createRequest(model: CreateTimeOffRequest): Promise<boolean> {
        const bindedCreateRequestAction = userId
            ? createRequestForUserAction.bind(null, new CreateTimeOffRequestForUser({ ...model, userId: userId }))
            : createRequestAction.bind(null, model);
        const result = await tryCatchAction(bindedCreateRequestAction);

        return result.success;
    }

    return createRequest;
}

export function useGetPolicyEvents(userId?: string): (policyId: string) => Promise<EventPolicySummaryDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();

    async function getEvents(policyId: string): Promise<EventPolicySummaryDto[]> {
        const bindedGetEventsAction = userId ? getPolicyEventsByUserAction.bind(null, userId, policyId) : getPolicyEventsAction.bind(null, policyId);
        const eventsModel = await tryCatchAction(bindedGetEventsAction);
        return eventsModel.response ?? [];
    }

    return getEvents;
}


export function useGetPoliciesWithUsers(): () => Promise<TimeOffPolicyUserDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();

    async function getPolicyWithUsers(): Promise<TimeOffPolicyUserDto[]> {
        const bindedGetPolicyWithUsersAction = getPolicyWithUsersAction.bind(null);
        const policyWithUsersModel = await tryCatchAction(bindedGetPolicyWithUsersAction);
        return policyWithUsersModel.response ?? [];
    }

    return getPolicyWithUsers;
}

export function useGetDeactivatedPolicies(): () => Promise<BasicTimeOffPolicyDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();

    async function getDeactivatedPolicies(): Promise<BasicTimeOffPolicyDto[]> {
        const bindedGetDeactivatedPolicyAction = getDeactivatedPoliciesAction.bind(null);
        const policies = await tryCatchAction(bindedGetDeactivatedPolicyAction);
        return policies.response ?? [];
    }

    return getDeactivatedPolicies;
}

export function useAssignPolicy(): (model: AssignPolicyRequest) => Promise<ResponseMessage> {
    const tryCatchAction = useTryCatchJsonByAction();

    async function assignPolicyRequest(model: AssignPolicyRequest): Promise<ResponseMessage> {
        const bindedAssignPolicyAction = assignPolicyAction.bind(null, model);
        const result = await tryCatchAction(bindedAssignPolicyAction);
        return result.response;
    }

    return assignPolicyRequest;
}

export function useUnassignUserFromPolicy(): (model: UnassignPolicyRequest) => Promise<ResponseMessage> {
    const tryCatchAction = useTryCatchJsonByAction();

    async function unassignUserFromPolicyRequest(model: UnassignPolicyRequest): Promise<ResponseMessage> {
        const bindedAssignPolicyAction = unassignPolicyAction.bind(null, model);
        const result = await tryCatchAction(bindedAssignPolicyAction);
        return result.response;
    }

    return unassignUserFromPolicyRequest;
}


export function useDeleteTimeOffPolicy(): (deleteRequest: DeleteTimeOffPolicyRequest) => Promise<ResponseMessage> {
    const tryCatchAction = useTryCatchJsonByAction();

    async function deleteTimeOffPolicyRequest(deleteRequest: DeleteTimeOffPolicyRequest): Promise<ResponseMessage> {
        const bindedDeletePolicyAction = deletePolicyAction.bind(null, deleteRequest);
        const result = await tryCatchAction(bindedDeletePolicyAction);
        return result.response;
    }

    return deleteTimeOffPolicyRequest;
}

export function useGenerateDocument(): (request: DocumentRequest) => Promise<FileResponse> {
    const tryCatchAction = useTryCatchJsonByAction();

    async function generateDocument(request: DocumentRequest): Promise<FileResponse> {
        const bindedGenerateDocumentAction = generateDocumentAction.bind(null, request);
        const result = await tryCatchAction(bindedGenerateDocumentAction);
        return result.response;
    }

    return generateDocument;
}