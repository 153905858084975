import React from 'react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CircleIcon from '@mui/icons-material/Circle';
import { Box, Typography, Chip, Tooltip } from '@mui/material';
import { SPACING_EXTRA_SMALL, SPACING_MEDIUM, SPACING_SMALL } from '../../utils/cssUtils';
import { useTranslation } from 'react-i18next';
import { TranslationKeyEnum } from '../../features/translations/TranslationKeyEnum';
import { getLanguageLocale } from '../../features/translations/i18n';
import { IYearCalendarEventProps, IYearCalendarProps } from './IYearCalendarProps';

const WEEKDAY_COLOR = "#9E9E9E";
const WEEKEND_COLOR = "#FFB74D";
const TODAY_COLOR = "#2196F3";
const SATURDAY_NUMBER_IN_WEEK: number = 6;
const SUNDAY_NUMBER_IN_WEEK: number = 7;

interface IMonthProps {
    name: string,
    days: IDayProps[],
}

interface IDayProps {
    day: number,
    name: string,
    date: Date,
    dayNumberInWeek: number,
}

export default function YearCalendar({ calendarEvents }: IYearCalendarProps) {
    const { t } = useTranslation();
    const [selectedYear, setSelectedYear] = React.useState(new Date());
    const [yearInfo, setYearInfo] = React.useState<any[]>([]);

    React.useEffect(() => {
        // Gets current year with months and all days within the months
        const getYearInfo = () => {
            const selectedLanguage = getLanguageLocale();
            const currentYear = selectedYear.getFullYear();
            const yearInfo = Array.from({ length: 12 }, (_, month) => {
                const date = new Date(currentYear, month, 1);
                const monthName = date.toLocaleString(selectedLanguage, { month: 'long' });
                const daysInMonth = new Date(currentYear, month + 1, 0).getDate();

                const dates = Array.from({ length: daysInMonth }, (_, day) => {
                    const dayDate = new Date(currentYear, month, day + 1);
                    const dayName = dayDate.toLocaleString(selectedLanguage, { weekday: 'long' });
                    const dayNumberInWeek = (dayDate.getDay() || 7);

                    return {
                        day: day + 1,
                        name: dayName,
                        date: dayDate,
                        dayNumberInWeek,
                    };
                });

                return { name: monthName.charAt(0).toUpperCase() + monthName.slice(1), days: dates };
            });

            return yearInfo;
        };
        setYearInfo(getYearInfo());
    }, [selectedYear])

    const getDayBackground = (day: IDayProps): { background: string } => {
        let matchingEvent: { label: string, color: string } | undefined = { color: "", label: "" }
        for (let event of calendarEvents) {
            matchingEvent = event[day.date.toLocaleDateString()];
            if (matchingEvent !== undefined) break;
        }

        return matchingEvent
            ? { background: matchingEvent.color }
            : {
                background:
                    day.dayNumberInWeek === SATURDAY_NUMBER_IN_WEEK || day.dayNumberInWeek === SUNDAY_NUMBER_IN_WEEK
                        ? WEEKEND_COLOR
                        : WEEKDAY_COLOR,
            };
    };

    const handleClickEvent = (date: Date): void => {
        const matchingEvent = calendarEvents.find(event => event[date.toLocaleDateString()]);
        if (matchingEvent) {
            const { clickEvent } = matchingEvent[date.toLocaleDateString()] || {};
            clickEvent && clickEvent();
        }
    };

    const getTooltipText = (date: Date): string => {
        for (let event of calendarEvents) {
            let matchingEvent = event[date.toLocaleDateString()];
            if (matchingEvent?.tooltipText) {
                return matchingEvent.tooltipText;
            }
        }
        return "";
    }

    return (
        <Box display="flex" alignItems="center" flexDirection="column">
            <Box display="flex" alignItems="center" gap={SPACING_SMALL} margin={`${SPACING_MEDIUM} 0`}>
                <ChevronLeftIcon sx={{ cursor: "pointer", color: "black" }} onClick={() => setSelectedYear(new Date(selectedYear.setFullYear(selectedYear.getFullYear() - 1)))} />
                <Typography variant="h5">
                    {selectedYear.getFullYear()}
                </Typography>
                <ChevronRightIcon sx={{ cursor: "pointer", color: "black" }} onClick={() => setSelectedYear(new Date(selectedYear.setFullYear(selectedYear.getFullYear() + 1)))} />
            </Box>
            <Box display="flex" flexDirection="column" gap={SPACING_SMALL}>
                <Box textAlign="center">
                    {
                        yearInfo.map((month: IMonthProps) => {
                            return <Box display="inline-grid" marginBottom={SPACING_SMALL} margin={SPACING_EXTRA_SMALL}>
                                <Typography variant="body2" align="left">{month.name}</Typography>
                                <Box display="grid" gridTemplateColumns="repeat(7, 1fr)">
                                    {
                                        month.days.map((day: IDayProps, index: number) => {
                                            // Checks if current mapped day is today's day
                                            const isToday = new Date().toLocaleDateString() === day.date.toLocaleDateString();
                                            return <Tooltip title={getTooltipText(day.date)}>
                                                <Box
                                                    onClick={() => handleClickEvent(day.date)}
                                                    // Set position of the first day in month                                            
                                                    gridColumn={index === 0 ? day.dayNumberInWeek : 0}
                                                    width={isToday ? "15px" : "6px"}
                                                    height={isToday ? "15px" : "6px"}
                                                    margin={isToday ? "5px" : "10px"}
                                                    borderRadius="50px"
                                                    border={isToday ? "5px solid" + TODAY_COLOR : ""}
                                                    sx={isToday ? {} : getDayBackground(day)}
                                                    style={{ cursor: "pointer" }}
                                                />
                                            </Tooltip>
                                        })
                                    }
                                </Box>
                            </Box>
                        })
                    }
                </Box>
                <Box textAlign="center">
                    <Chip
                        sx={{ marginRight: SPACING_EXTRA_SMALL, marginBottom: SPACING_EXTRA_SMALL }}
                        icon={<CircleIcon style={{ color: TODAY_COLOR }} />}
                        label={t(TranslationKeyEnum.today)}
                        variant="outlined"
                    />
                    {
                        calendarEvents.length && calendarEvents.map((event: IYearCalendarEventProps) => <Chip
                            sx={{ marginRight: SPACING_EXTRA_SMALL, marginBottom: SPACING_EXTRA_SMALL }}
                            icon={<CircleIcon style={{ color: Object.values(event)[0]?.color }} />}
                            label={Object.values(event)[0]?.label}
                            variant="outlined"
                        />)
                    }
                </Box>
            </Box>
        </Box>
    )
}