import { Box, Chip, Dialog, Grid, Tooltip, Typography } from "@mui/material";
import { TranslationKeyEnum } from "../../../features/translations/TranslationKeyEnum";
import { ITimeOffRequestsProps } from "./ITimeOffRequestsProps";
import { RequestResultVM, RequestUserTableRow, useGetAllUserRequestsForApprover, useGetRequestsForUser, useGetUserRequestsForApprover } from "../../PendingRequests/action";
import { useTranslation } from "react-i18next";
import { StyledDataGrid } from "../../../components/StyledDataGrid/StyledDataGrid";
import { GridColDef, GridPaginationModel, GridRenderCellParams } from "@mui/x-data-grid";
import { APP_LEFT_AND_RIGHT_PADDING, BORDER_RADIUS_MEDIUM, BORDER_RADIUS_SMALL, ICON_COLOR, PAGE_SHADOW, PRIMARY_BG_COLOR, SPACING_SMALL } from "../../../utils/cssUtils";
import { ROWS_PER_PAGE_OPTIONS } from "../../../components/common/Constants/PaginationConstants";
import { LanguageEnum } from "../../../features/translations/LanguageEnum";
import { srRS } from "../../../features/translations/DataGrid/srRS";
import { DocumentRequest, RequestDto, RequestStatusEnum } from "../../../API/time-off-service";
import React from "react";
import i18n from "../../../features/translations/i18n";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Button from '@mui/material/Button';
import RemoveRequestAlert from "./RemoveRequestAlert";
import { useGenerateDocument, useGetBasicPolicies } from "../actions";
import { download } from "../../../utils/fetchUtils";
import { useGetUser } from "../../../API/user-actions";
import { useGetNumberOfBusinessDaysExcludingBankHolidays } from "../helpers";
import { DateRange } from "../../../components/common/DateRange/DateRange";
import { getUserIdFromStorage } from "../../../utils/storageUtils/storageUtils";
import { isDateYearOne } from "../../../utils/dateUtils";
import { getPropertyFromJwtToken } from "../../../helpers/getPropertyFromJwtToken";

export default (props: ITimeOffRequestsProps) => {
  const userIdFromStorage = getUserIdFromStorage();
  const getRequests = props.isJustForAdmin && !props.isForUser
    ? useGetAllUserRequestsForApprover()
    : !props.isJustForAdmin && !props.isForUser && userIdFromStorage !== props.userId!
      ? useGetUserRequestsForApprover(props.userId!)
      : useGetRequestsForUser()
  const [requests, setRequests] = React.useState<RequestUserTableRow[]>([]);
  const [requestsData, setRequestsData] = React.useState<RequestDto[]>([]);
  const [page, setPage] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(ROWS_PER_PAGE_OPTIONS[0]);
  const [isOpenRemoveRequestAlert, setIsOpenRemoveRequestAlert] = React.useState<boolean>(false);
  const [removeRequestParams, setRemoveRequestParams] = React.useState<GridRenderCellParams<any, any, any>>();
  const [isMyRequestsDataLoading, setIsMyRequestsDataLoading] = React.useState<boolean>(true);
  const generateDocument = useGenerateDocument();
  const getUser = useGetUser();
  const getPolicies = useGetBasicPolicies();
  const getNumberOfBusinessDaysExcludingBankHolidays = useGetNumberOfBusinessDaysExcludingBankHolidays();

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    setPage(model.page);
    setPageSize(model.pageSize);
  }

  const { t } = useTranslation();

  React.useEffect(() => {
    const fetchData = async () => {
      let allRequests = await getRequests();
      var allRequestsForState = props.isForUser ? allRequests as RequestUserTableRow[] : (allRequests as RequestResultVM).tableData;
      if (!allRequestsForState) return;
      setRequests(allRequestsForState);
      setIsMyRequestsDataLoading(false);
      setRequestsData((allRequests as RequestResultVM).requests);
    };
    fetchData();
  }, [isOpenRemoveRequestAlert]);

  const openPopup = (cellValues: GridRenderCellParams<any, any, any>): void => {
    setRemoveRequestParams(cellValues);
    setIsOpenRemoveRequestAlert(true);
  }

  const downloadDocument = async (id: string, relatedId: string, userId: string): Promise<void> => {
    const [user, policies] = await Promise.all([getUser(userId), getPolicies(userId)])
    const position = user.positions.find(position => position.endDate || isDateYearOne(position.endDate!))?.name!;
    const requests = requestsData.filter(request => request.id === id || request.id === relatedId);
    const workDays = await Promise.all(requests.map(request => getNumberOfBusinessDaysExcludingBankHolidays(new DateRange(request.startDate!, request.endDate!), userId)))
    const previousRequestsWorkDays = Object.fromEntries(
      await Promise.all(requests.map(async request => [
        request.id,
        (await Promise.all(requestsData
          .filter(otherRequest => otherRequest.userId === userId && otherRequest.policyId! === request.policyId! && otherRequest.startDate! < request.startDate! && otherRequest.status! === RequestStatusEnum.Approved)
          .map(request => getNumberOfBusinessDaysExcludingBankHolidays(new DateRange(request.startDate!, request.endDate!), userId))))
          .reduce((partialSum, a) => partialSum + a, 0)
      ])))
    const documentRequests: DocumentRequest[] = [];
    requests.forEach((request, index) => {
      if (request) {
        const policy = policies.find(policy => policy.id === request!.policy!.id)!;
        documentRequests.push(new DocumentRequest({
          replacements: {
            "_COMPANY_": getPropertyFromJwtToken("companyName"),
            "_POLICY_NAME_": request!.policy!.name!,
            "_AVAILABLE_DAYS_NUMBER_": policy.infiniteDays ? "N/A" : String(policy.totalDays! - previousRequestsWorkDays[request.id] - workDays[index]),
            "_POLICY_EXPIRATION_DATE_": request!.policy!.endDate!.toLocaleDateString('en-GB'),
            "_WORK_DAYS_NUMBER_": String(workDays[index]),
            "_TOTAL_DAYS_NUMBER_": policy.infiniteDays ? "N/A" : String(policy.totalDays!),
            "_START_DATE_": request!.startDate!.toLocaleDateString('en-GB'),
            "_END_DATE_": request!.endDate!.toLocaleDateString('en-GB'),
            "_CREATED_DATE_": new Date().toLocaleDateString('en-GB'),
            "_EMPLOYEE_NAME_": `${user.firstName} ${user.lastName}`,
            "_EMPLOYEE_ADDRESS_": user.address,
            "_POSITION_": position,
            "_UNIQUE_ID_": user.personalNo,
          }
        }))
      }
    });

    const files = await Promise.all(documentRequests.map(documentRequest => generateDocument(documentRequest)))
    files.forEach((file, index) => {
      download(file, `${documentRequests[index].replacements!["_POLICY_NAME_"]} ${documentRequests[index].replacements!["_EMPLOYEE_NAME_"]} ${documentRequests[index].replacements!["_START_DATE_"]} - ${documentRequests[index].replacements!["_END_DATE_"]}.docx`);
    });
  }

  const columns: GridColDef[] = [
    {
      field: "daysRequested",
      headerName: t(TranslationKeyEnum.daysRequested),
      description: t(TranslationKeyEnum.daysRequested),
      minWidth: 210,
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography variant="body2" textAlign="center" paddingRight={2}>{cellValues.row.startDate.toLocaleDateString('en-GB')}</Typography>
            <ArrowForwardIcon fontSize="small"></ArrowForwardIcon>
            <Typography variant="body2" textAlign="left" paddingLeft={2}>{cellValues.row.endDate.toLocaleDateString('en-GB')}</Typography>
          </Box>
        );
      },
    },
    { field: 'employee', headerName: t(TranslationKeyEnum.employee), description: t(TranslationKeyEnum.employee), flex: 1, minWidth: 200, renderCell: (cellValues) => <Box>{`${cellValues.row.user.firstName} ${cellValues.row.user.lastName}`}</Box> },
    {
      field: "comment",
      headerName: t(TranslationKeyEnum.comment),
      description: t(TranslationKeyEnum.comment),
      flex: 1,
      minWidth: 140,
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.value} placement="bottom-start">
            <div>{cellValues.value}</div>
          </Tooltip>
        );
      },
    },
    {
      field: "policyName",
      headerName: t(TranslationKeyEnum.timeOffPolicy),
      description: t(TranslationKeyEnum.timeOffPolicy),
      flex: 1,
      minWidth: 140,
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.value}>
            <div>{cellValues.value}</div>
          </Tooltip>
        );
      },
    },
    {
      field: "numberOfDays",
      headerName: t(TranslationKeyEnum.workDaysRequestHistory),
      description: t(TranslationKeyEnum.workDaysRequestHistory),
      flex: 1,
      minWidth: 100,
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.value}>
            <div>{cellValues.value}</div>
          </Tooltip>
        );
      },
    },
    {
      field: "status",
      headerName: t(TranslationKeyEnum.status),
      description: t(TranslationKeyEnum.status),
      minWidth: 60,
      flex: 1,
      renderCell: (cellValues) => {
        switch (cellValues.value) {
          case RequestStatusEnum.Approved:
            return <Chip label={cellValues.value} variant="outlined" color="success" icon={<CheckCircleIcon />} />
          case RequestStatusEnum.CancellationPending:
            return <Chip label="Cancellation pending" variant="outlined" color="warning" icon={<AccessTimeIcon />} />
          case RequestStatusEnum.Pending:
            return <Chip label={cellValues.value} variant="outlined" color="secondary" icon={<AccessTimeIcon />} />
          case RequestStatusEnum.Cancelled:
          case RequestStatusEnum.Rejected:
            return <Chip label={cellValues.value} variant="outlined" color="error" icon={<HighlightOffIcon />} />
          default:
            break;
        }
      }
    },
    {
      field: 'actions',
      headerName: "",
      flex: 1,
      minWidth: 60,
      sortable: false,
      renderCell: (cellValues) => {
        return props.isForUser
          ? (((cellValues.row["status"] === RequestStatusEnum.Approved) || (cellValues.row["status"] === RequestStatusEnum.Pending)) && <Tooltip title={cellValues.value}>
            <Button color="inherit"><ClearIcon onClick={() => openPopup(cellValues)} /></Button>
          </Tooltip>)
          : (((cellValues.row["status"] === RequestStatusEnum.Approved) && <Tooltip title={cellValues.value}>
            <Button color="inherit"><FileDownloadIcon onClick={() => downloadDocument(cellValues.row.id, cellValues.row.relatedId, cellValues.row.user.id)} /></Button>
          </Tooltip>))
      }
    },
  ];

  return (
    <Dialog open={props.isOpen} onClose={() => props.setIsOpen(false)} maxWidth="lg" fullWidth={true} >
      <Grid container>
        <Box position="absolute" right="0" padding={SPACING_SMALL} sx={{ cursor: "pointer" }} onClick={() => props.setIsOpen(false)}>
          <CloseIcon sx={{ color: ICON_COLOR }} />
        </Box>
        <Grid container height="70vh" sx={{ padding: SPACING_SMALL }}>
          <Grid item height={APP_LEFT_AND_RIGHT_PADDING} xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box display="flex" flexDirection="column" borderRadius={BORDER_RADIUS_SMALL}>
              <Typography variant="h5">
                {
                  props.isJustForAdmin && !props.isForUser
                    ? t(TranslationKeyEnum.allTimeOffRequests)
                    : !props.isJustForAdmin && !props.isForUser && userIdFromStorage !== props.userId!
                      ? t(TranslationKeyEnum.employeeTimeOffRequests)
                      : t(TranslationKeyEnum.myTimeOffRequests)
                }
              </Typography>
            </Box>
          </Grid>
          <Grid item marginTop={SPACING_SMALL} height="86%" xl={12} lg={12} md={12} sm={12} xs={12} sx={{ backgroundColor: PRIMARY_BG_COLOR }} borderRadius={BORDER_RADIUS_MEDIUM}>
            <Box height="100%">
              <StyledDataGrid
                sx={{ boxShadow: PAGE_SHADOW }}
                rows={requests}
                columns={columns}
                paginationModel={{ page, pageSize }}
                onPaginationModelChange={handlePaginationModelChange}
                pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
                pagination
                hideFooterSelectedRowCount={true}
                localeText={i18n.language === LanguageEnum.Serbian ? srRS.components.MuiDataGrid.defaultProps.localeText : undefined}
                loading={isMyRequestsDataLoading}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {
        isOpenRemoveRequestAlert && <RemoveRequestAlert
          isOpen={isOpenRemoveRequestAlert}
          setIsOpen={setIsOpenRemoveRequestAlert}
          cellValues={removeRequestParams!}
          title={(removeRequestParams!.row["status"] === RequestStatusEnum.Pending) ? t(TranslationKeyEnum.removeRequest) : t(TranslationKeyEnum.cancellationRequest)}
          question={removeRequestParams!.row["status"] === RequestStatusEnum.Pending ? t(TranslationKeyEnum.removeRequestText) : t(TranslationKeyEnum.cancellationRequestText)}
          affirmationMessage={removeRequestParams!.row["status"] === RequestStatusEnum.Pending ? t(TranslationKeyEnum.removeApproval) : t(TranslationKeyEnum.cancellationRequestApproval)}
          buttonColor={removeRequestParams!.row["status"] === RequestStatusEnum.Pending ? "error" : "primary"}
          requestStatus={removeRequestParams!.row["status"]}
        />
      }
    </ Dialog >
  );
};
