import React from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import CoffeeIcon from '@mui/icons-material/Coffee';
import DoneWorkOrdersTable from '../MonthSummaryTable/DoneWorkOrdersTable';
import CreateEditWorkOrder from '../CreateEditWorkOrder/CreateEditWorkOrder';
import PermissionContext from '../../Permission/PermissionContext';
import { Box, Typography, Dialog, Button } from '@mui/material';
import { IWorkOrdersHistoryProps } from './IWorkOrdersHistoryProps';
import { BORDER_RADIUS_SMALL, CARD_BOX_SHADOW, ICON_COLOR, LIGHT_BORDER, SPACING_EXTRA_SMALL, SPACING_MEDIUM, SPACING_SMALL } from '../../../utils/cssUtils';
import { StyledDataGrid } from '../../../components/StyledDataGrid/StyledDataGrid';
import { useTranslation } from 'react-i18next';
import { LanguageEnum } from '../../../features/translations/LanguageEnum';
import { srRS } from '../../../features/translations/DataGrid/srRS';
import { TranslationKeyEnum } from '../../../features/translations/TranslationKeyEnum';
import { GridColDef } from '@mui/x-data-grid';
import { getTextTransformationAccordingToLanguage } from '../../../features/translations/helpers';
import { getMonthNameAndYear } from '../../../utils/dateUtils';
import { OPERATIONS_ENUM, changeMonth } from '../helpers';
import { MonthlyUserSummaryFilter, SingleUserMonthlySummaryDto, WorkOrderDto } from '../../../API/work-order-service';
import { useGetMonthlySummaryForUser, useGetMonthlySummaryForMe } from '../../../API/work-order-actions';
import { PermissionsEnum } from '../../Permission/PermisionEnum';
import { isObjectEmpty } from '../../../components/common/UserProfile/helpers';
import { workOrdersStatusSwitch } from '../common';
import { useParams } from 'react-router-dom';
import { getUserIdFromStorage } from '../../../utils/storageUtils/storageUtils';

interface IDoneWorkOrderProps {
  name: string,
  times: number
}

interface IWorkOrdersTableProps {
  id?: string,
  name?: string,
  startDate?: string,
  endDate?: string,
  typeOfWork?: string,
  locationCode?: string,
  status?: string
}

export default function WorkOrdersHistory({ isOpen, setIsOpen, data, month, rerender, setRerender }: IWorkOrdersHistoryProps) {
  const { t, i18n } = useTranslation();
  const { isAllowed } = React.useContext(PermissionContext);
  const params = useParams();
  const getMonthlySummaryForMe = useGetMonthlySummaryForMe();
  const getMonthlySummary = useGetMonthlySummaryForUser();

  const [monthlySummaryData, setMonthlySummaryData] = React.useState<SingleUserMonthlySummaryDto>(data);
  const [selectedMonth, setSelectedMonth] = React.useState<Date>(month)
  const [doneWorkOrders, setDoneWorkOrders] = React.useState<IDoneWorkOrderProps[]>([]);
  const [rows, setRows] = React.useState<IWorkOrdersTableProps[]>([]);
  const [selectedWorkOrderId, setSelectedWorkOrderId] = React.useState<string>("");
  const [isWorkOrderFormOpen, setIsWorkOrderFormOpen] = React.useState<boolean>(false);
  const [isUserSupervisor, setIsUserSupervisor] = React.useState<boolean>(false);
  const [isDataLoading, setIsDataLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const fetchData = async () => {
      setIsDataLoading(true);
      let monthlySummary = !params.id || params.id === getUserIdFromStorage()
        ? await getMonthlySummaryForMe(new MonthlyUserSummaryFilter({ start: new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1), end: new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0) }))
        : await getMonthlySummary(new MonthlyUserSummaryFilter({ userId: params.id, start: new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1), end: new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0) }))
      if (!monthlySummary) return;
      setMonthlySummaryData(monthlySummary);
      mapManageWorkOrdersTableProps(monthlySummary.workOrders!);
      setIsDataLoading(false);
    }
    fetchData();
  }, [selectedMonth, rerender])

  React.useEffect(() => {
    if (monthlySummaryData.workOrdersCountByType && !isObjectEmpty(monthlySummaryData.workOrdersCountByType)) {
      const data = Object.entries(monthlySummaryData.workOrdersCountByType!)
        .map(([name, times]) => ({ name, times }));
      return setDoneWorkOrders(data);
    }
    setDoneWorkOrders([]);
  }, [monthlySummaryData]);


  React.useEffect(() => {
    const checkPermsission = async () => {
      setIsUserSupervisor(await isAllowed(PermissionsEnum.ManageWorkOrderPermission))
    }
    checkPermsission();
  }, [])

  const mapManageWorkOrdersTableProps = (allWorkOrders: WorkOrderDto[]): void => {
    setRows(allWorkOrders.map(workOrder => {
      return {
        id: workOrder.id,
        name: workOrder.name,
        startDate: workOrder.startDate?.toLocaleDateString(),
        endDate: workOrder.endDate?.toLocaleDateString(),
        typeOfWork: workOrder.typeOfWork?.name,
        locationCode: workOrder.locationCode,
        status: workOrder.status
      }
    }));
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id' },
    { field: 'name', headerName: t(TranslationKeyEnum.name), description: t(TranslationKeyEnum.name), flex: .3, minWidth: 200 },
    { field: 'startDate', headerName: t(TranslationKeyEnum.date), description: t(TranslationKeyEnum.date), flex: .2, minWidth: 120 },
    { field: 'endDate', headerName: t(TranslationKeyEnum.date), description: t(TranslationKeyEnum.date), flex: .2, minWidth: 120 },
    { field: 'typeOfWork', headerName: t(TranslationKeyEnum.typeOfWork), description: t(TranslationKeyEnum.typeOfWork), flex: .2, minWidth: 120 },
    { field: 'locationCode', headerName: t(TranslationKeyEnum.locationLabel), description: t(TranslationKeyEnum.locationLabel), flex: .250, minWidth: 200 },
    {
      field: 'status', headerName: t(TranslationKeyEnum.status), description: t(TranslationKeyEnum.status), flex: .250, disableColumnMenu: true, minWidth: 170, renderCell: (cellValues) => {
        return workOrdersStatusSwitch(cellValues.value);
      }
    },
    {
      field: 'button', headerName: "", align: "right", description: "", flex: .220, disableColumnMenu: true, sortable: false, minWidth: 200,
      renderCell: () => {
        return (
          <Button color="primary" size="small" sx={{ textTransform: getTextTransformationAccordingToLanguage() }} onClick={() => setIsWorkOrderFormOpen(true)}>
            <Box display="flex" gap=".8vh">
              <Typography variant="body2">{t(TranslationKeyEnum.viewMore)}</Typography>
              <ChevronRightIcon fontSize="small" />
            </Box>
          </Button>
        );
      }
    }
  ];

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <Box width="80vw" height="80vh" minHeight="480px" padding={SPACING_MEDIUM} display="flex" flexDirection="column" alignItems="content" gap={SPACING_SMALL}>
        <Box position="absolute" right={SPACING_EXTRA_SMALL} top={SPACING_SMALL} sx={{ cursor: "pointer" }} onClick={() => setIsOpen(false)}>
          <CloseIcon sx={{ color: ICON_COLOR }} />
        </Box>
        <Typography variant="body2" color="grey">{t(TranslationKeyEnum.summary)}</Typography>
        <Box display="flex" alignItems="center" gap={SPACING_SMALL}>
          <Typography variant="h5" textTransform="capitalize" width={{ xl: "12vw", lg: "16vw", md: "20vw", sm: "30vw", xs: "30ww" }}>
            {getMonthNameAndYear(selectedMonth)}
          </Typography>
          <ChevronLeftIcon color="action" sx={{ cursor: "pointer" }} onClick={() => changeMonth(OPERATIONS_ENUM.SUBTRACT, selectedMonth, setSelectedMonth)} />
          <ChevronRightIcon color="action" sx={{ cursor: "pointer" }} onClick={() => changeMonth(OPERATIONS_ENUM.ADD, selectedMonth, setSelectedMonth)} />
        </Box>
        <Box height="100%" display="flex" flexDirection="column" justifyContent="space-evenly">
          <DoneWorkOrdersTable doneWorkOrders={doneWorkOrders} tableSxProps={{ width: { xl: "22vw", lg: "30vw", md: "40vw", sm: "50vw", xs: "60vw" } }} />
          {
            monthlySummaryData?.workOrders?.length
              ? <Box sx={{ boxShadow: CARD_BOX_SHADOW, marginTop: SPACING_MEDIUM, borderRadius: BORDER_RADIUS_SMALL }}>
                <Typography variant="h6" padding={SPACING_SMALL} sx={{ borderBottom: LIGHT_BORDER, }}>{t(TranslationKeyEnum.workOrdersTab)}</Typography>
                <StyledDataGrid
                  columnVisibilityModel={{
                    id: false,
                  }}
                  localeText={i18n.language === LanguageEnum.Serbian ? srRS.components.MuiDataGrid.defaultProps.localeText : undefined}
                  autoHeight={true}
                  hideFooter
                  columns={columns}
                  rows={rows}
                  disableRowSelectionOnClick
                  sx={{ borderRadius: 0, border: "none" }}
                  onCellClick={(selectedCell) => setSelectedWorkOrderId(selectedCell.id.toString())}
                  slotProps={{
                    cell: {
                      onBlur: () => setSelectedWorkOrderId(""),
                    },
                  }}
                  loading={isDataLoading}
                />
              </Box>
              : <Box display="flex" flexDirection="column" alignItems="center" gap={SPACING_EXTRA_SMALL} marginTop={SPACING_MEDIUM}>
                <CoffeeIcon sx={{ color: "grey" }} />
                <Typography variant="body2" color="grey" align="center">{t(TranslationKeyEnum.noWorkOrdersThisMonth)}</Typography>
              </Box>
          }
        </Box>
      </Box>
      {
        isWorkOrderFormOpen && <CreateEditWorkOrder isOpen={isWorkOrderFormOpen} setIsOpen={setIsWorkOrderFormOpen} isSupervisor={isUserSupervisor} workOrderId={selectedWorkOrderId} rerender={rerender} setRerender={setRerender} />
      }
    </Dialog>
  )
}