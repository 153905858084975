import React from 'react';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CreateEditWorkOrder from '../../../WorkOrders/CreateEditWorkOrder/CreateEditWorkOrder';
import PermissionContext from '../../../Permission/PermissionContext';
import { Box, Typography, AvatarGroup, Avatar, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TranslationKeyEnum } from '../../../../features/translations/TranslationKeyEnum';
import { BORDER_RADIUS_MEDIUM, LIGHT_BORDER, CARD_BOX_SHADOW, SPACING_SMALL, APP_BG_COLOR, INFO_CARD_WIDTH, INFO_CARD_MIN_WIDTH, INFO_CARD_WIDTH_SM, INFO_CARD_WIDTH_XS, SPACING_EXTRA_SMALL, WORK_ORDER_CARD_MIN_HEIGTH } from '../../../../utils/cssUtils';
import { IWorkOrderCardProps } from './IWorkOrderCardProps';
import { UserWorkOrderDto } from '../../../../API/work-order-service';
import { PermissionsEnum } from '../../../Permission/PermisionEnum';
import { getShortDateAndDayOfTheWeek } from '../../../../utils/dateUtils';

export default function WorkOrderCard({ id, startDate, endDate, locationCode, typeOfWork, goingWith, rerender, setRerender }: IWorkOrderCardProps) {
    const { t } = useTranslation();
    const { isAllowed } = React.useContext(PermissionContext);

    const [isWorkOrderFormOpen, setIsWorkOrderFormOpen] = React.useState<boolean>(false);
    const [isUserSupervisor, setIsUserSupervisor] = React.useState<boolean>(false);

    React.useEffect(() => {
        const checkPermsission = async () => {
            setIsUserSupervisor(await isAllowed(PermissionsEnum.ManageWorkOrderPermission))
        }
        checkPermsission();
    }, [])

    const getFullnameOfAssignees = (assignees: UserWorkOrderDto[]): JSX.Element =>
        <Box>{assignees.map((assignee: UserWorkOrderDto) => <Typography variant="body2"> {`${assignee.user?.firstName} ${assignee.user?.lastName}`}</Typography>)}</Box>

    return (
        <>
            <Box
                minHeight={WORK_ORDER_CARD_MIN_HEIGTH}
                width={{ xl: INFO_CARD_WIDTH, lg: INFO_CARD_WIDTH, md: INFO_CARD_WIDTH, sm: INFO_CARD_WIDTH_SM, xs: INFO_CARD_WIDTH_XS }}
                minWidth={INFO_CARD_MIN_WIDTH}
                borderRadius={BORDER_RADIUS_MEDIUM}
                boxShadow={CARD_BOX_SHADOW}
                sx={{ backgroundColor: APP_BG_COLOR }}
            >
                {/* Card Header */}
                <Box borderBottom={LIGHT_BORDER} display="flex" justifyContent="space-between" padding={SPACING_SMALL}>
                    <Box display="flex" justifyContent="center" alignItems="center" width="20%">
                        <ContentPasteGoIcon color="action" fontSize="large" />
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="60%" sx={{ cursor: "pointer" }}>
                        <Typography variant="h6" textAlign="center" textTransform="capitalize">{t(TranslationKeyEnum.workOrder)}</Typography>
                        <Box display="flex" sx={{ align: "center" }}>
                            <Typography variant="body2" color="grey" textAlign="center">{startDate && getShortDateAndDayOfTheWeek(startDate)}</Typography>
                            {
                                endDate
                                && startDate?.toString() !== endDate.toString()
                                && <>
                                    <Typography variant="body2" color="grey">&nbsp;-&nbsp;</Typography>
                                    <Typography variant="body2" color="grey" textAlign="center">{getShortDateAndDayOfTheWeek(endDate)}</Typography>
                                </>
                            }
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="center" alignItems="center" width="20%" sx={{ cursor: "pointer" }} onClick={() => setIsWorkOrderFormOpen(true)}>
                        <OpenInNewIcon color="action" fontSize="large" />
                    </Box>
                </Box>
                {/* Card Body */}
                <Box padding={SPACING_SMALL} display="flex" flexDirection="column" justifyContent="space-between" gap={SPACING_SMALL}>
                    <Box display="flex" flexDirection="column" justifyContent="center" gap={SPACING_EXTRA_SMALL}>
                        <Typography variant="body1">{t(TranslationKeyEnum.locationLabel)}:</Typography>
                        <Typography variant="body2" color="grey">{locationCode}</Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="center" gap={SPACING_EXTRA_SMALL}>
                        <Typography variant="body1">{t(TranslationKeyEnum.typeOfWork)}:</Typography>
                        <Typography variant="body2" color="grey">{typeOfWork?.name}</Typography>
                    </Box>
                    {
                        goingWith?.length
                        && <Box display="flex" flexDirection="column" justifyContent="center" gap={SPACING_EXTRA_SMALL}>
                            <Typography variant="body1">{t(TranslationKeyEnum.goingWith)}:</Typography>
                            <Tooltip title={getFullnameOfAssignees(goingWith)}>
                                <AvatarGroup max={5} sx={{ marginRight: "auto", cursor: "pointer" }}>
                                    {
                                        goingWith?.map((workOrder: UserWorkOrderDto) => {
                                            return <Avatar alt={`${workOrder?.user?.firstName} ${workOrder?.user?.lastName}`} src={workOrder?.user?.image && `data:image/png;base64,${workOrder.user.image}`}>
                                                {`${workOrder?.user?.firstName?.charAt(0)}${workOrder?.user?.lastName?.charAt(0)}`}
                                            </Avatar>
                                        })
                                    }
                                </AvatarGroup>
                            </Tooltip>
                        </Box>
                    }
                </Box>
            </Box>
            {
                isWorkOrderFormOpen && <CreateEditWorkOrder isOpen={isWorkOrderFormOpen} setIsOpen={setIsWorkOrderFormOpen} isSupervisor={isUserSupervisor} workOrderId={id!} rerender={rerender} setRerender={setRerender} />
            }
        </>
    )
}