import axios, { AxiosError } from 'axios';
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';
import { BaseClass } from './BaseClass';

export const NOTIFICATION_STATUS: { [status: string]: string } = {
    READ: "read",
    UNDREAD: "unread"
}

export interface MessageDictionaryProps {
    [language: string]: string
}

export interface NotificationDto {
    _id: string,
    userId: string,
    messages: MessageDictionaryProps,
    url: string,
    status: string,
    type: string,
    creationDate: string,
}

export class NotificationClient extends BaseClass {
    private instance: AxiosInstance;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(baseUrl?: string, instance?: AxiosInstance) {
        super();
        this.instance = instance ? instance : axios.create();
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : this.getNotificationsServiceBaseUrl("")
    }

    getAll(userId: string, cancelToken?: CancelToken | undefined): Promise<NotificationDto[]> {
        // gets all users notifciations sorted by creation date (ascending)
        let url_ = this.baseUrl + "/notifications?userId=" + userId + "&status=unread&$sort[createdAt]=-1";
        url_ = url_.replace(/[?&]$/, "");

        let options_: AxiosRequestConfig = {
            method: "GET",
            url: url_,
            headers: {
                "Accept": "application/json"
            },
            cancelToken
        };

        return this.transformOptions(options_).then(transformedOptions_ => {
            return this.instance.request(transformedOptions_);
        }).catch((_error: any) => {
            if (isAxiosError(_error) && _error.response) {
                return _error.response;
            } else {
                throw _error;
            }
        }).then((_response: AxiosResponse) => {
            return this.processGetAll(_response);
        });
    }

    protected processGetAll(response: AxiosResponse): Promise<NotificationDto[]> {
        const status = response.status;

        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            return Promise.resolve<NotificationDto[]>(response.data.data);

        } else if (status === 400) {
            const _responseText = response.data;
            let result400: any = null;
            let resultData400 = _responseText;
            result400 = ErrorResponseModel.fromJS(resultData400);
            return throwException("A server side error occurred.", status, _responseText, _headers, result400);

        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<any[]>(null as any);
    }

    update(notification: NotificationDto, cancelToken?: CancelToken | undefined): Promise<boolean> {
        let url_ = this.baseUrl + "/notifications/" + notification._id;
        url_ = url_.replace(/[?&]$/, "");
        notification.status = NOTIFICATION_STATUS.READ;
        const content_ = notification;

        let options_: AxiosRequestConfig = {
            data: content_,
            method: "PUT",
            url: url_,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            cancelToken,
        };

        return this.transformOptions(options_).then(transformedOptions_ => {
            return this.instance.request(transformedOptions_);
        }).catch((_error: any) => {
            if (isAxiosError(_error) && _error.response) {
                return _error.response;
            } else {
                throw _error;
            }
        }).then((_response: AxiosResponse) => {
            return this.processUpdate(_response);
        });
    }

    protected processUpdate(response: AxiosResponse): Promise<boolean> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            return Promise.resolve<boolean>(true);

        } else if (status === 400) {
            const _responseText = response.data;
            let result400: any = null;
            let resultData400 = _responseText;
            result400 = ErrorResponseModel.fromJS(resultData400);
            return throwException("A server side error occurred.", status, _responseText, _headers, result400);

        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<boolean>(null as any);
    }

    markAllAsRead(updateNotificationRequest: string[], cancelToken?: CancelToken | undefined): Promise<boolean> {
        let url_ = this.baseUrl + "/notifications";
        url_ = url_.replace(/[?&]$/, "");

        let options_: AxiosRequestConfig = {
            data: { status: "read" },
            params: { query: updateNotificationRequest },
            method: "PATCH",
            url: url_,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            cancelToken
        };

        return this.transformOptions(options_).then(transformedOptions_ => {
            return this.instance.request(transformedOptions_);
        }).catch((_error: any) => {
            if (isAxiosError(_error) && _error.response) {
                return _error.response;
            } else {
                throw _error;
            }
        }).then((_response: AxiosResponse) => {
            return this.processUpdate(_response);
        });
    }

    protected processUpdateAll(response: AxiosResponse): Promise<boolean> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            return Promise.resolve<boolean>(true);

        } else if (status === 400) {
            const _responseText = response.data;
            let result400: any = null;
            let resultData400 = _responseText;
            result400 = ErrorResponseModel.fromJS(resultData400);
            return throwException("A server side error occurred.", status, _responseText, _headers, result400);

        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<boolean>(null as any);
    }
}

function isAxiosError(obj: any | undefined): obj is AxiosError {
    return obj && obj.isAxiosError === true;
}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): any {
    if (result !== null && result !== undefined)
        throw result;
    else
        throw new ApiException(message, status, response, headers, null);
}

export class ErrorResponseModel implements IErrorResponseModel {
    errors?: { [key: string]: string; };

    constructor(data?: IErrorResponseModel) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (_data["errors"]) {
                this.errors = {} as any;
                for (let key in _data["errors"]) {
                    if (_data["errors"].hasOwnProperty(key))
                        (<any>this.errors)![key] = _data["errors"][key];
                }
            }
        }
    }

    static fromJS(data: any): ErrorResponseModel {
        data = typeof data === 'object' ? data : {};
        let result = new ErrorResponseModel();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (this.errors) {
            data["errors"] = {};
            for (let key in this.errors) {
                if (this.errors.hasOwnProperty(key))
                    (<any>data["errors"])[key] = (<any>this.errors)[key];
            }
        }
        return data;
    }
}

export interface IErrorResponseModel {
    errors?: { [key: string]: string; };
}

export class ApiException extends Error {
    override message: string;
    status: number;
    response: string;
    headers: { [key: string]: any; };
    result: any;

    constructor(message: string, status: number, response: string, headers: { [key: string]: any; }, result: any) {
        super();

        this.message = message;
        this.status = status;
        this.response = response;
        this.headers = headers;
        this.result = result;
    }

    protected isApiException = true;

    static isApiException(obj: any): obj is ApiException {
        return obj.isApiException === true;
    }
}

export class ResponseMessage implements IResponseMessage {
    message?: string;

    constructor(data?: IResponseMessage) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.message = _data["message"];
        }
    }

    static fromJS(data: any): ResponseMessage {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseMessage();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["message"] = this.message;
        return data;
    }
}

export interface IResponseMessage {
    message?: string;
}