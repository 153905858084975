import { useCreateOffice, useDeleteOffice, useGetOffices, useUpdateOffice } from "../../API/office-actions";
import { useTranslation } from "react-i18next";
import { TranslationKeyEnum } from "../../features/translations/TranslationKeyEnum";
import { Typography, Box, Tooltip, Button } from "@mui/material";
import { GridActionsCellItem, GridColDef, GridRowId, GridRowModes, GridRowModesModel, GridToolbarContainer } from '@mui/x-data-grid';
import { OfficeRequest, OfficeResponse, UpdateOfficeRequest } from "../../API/user-management-service";
import { IOfficeTableProps } from "./IOfficeTableProps";
import { useErrorNotification } from "../../utils/fetchUtils";
import { CARD_BOX_SHADOW, SPACING_EXTRA_SMALL } from "../../utils/cssUtils";
import { getTextTransformationAccordingToLanguage } from '../../features/translations/helpers';
import EditableDataGrid from "../EditableDataGrid/EditableDataGrid";
import React from "react";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import ConfirmDialog from "../Dialogs/ConfirmDialog/ConfirmDialog";

const FIRST_PAGE = 0;
const MINIMAL_OFFICE_PROP_LENGHT = 0;
const EMPTY_OFFICE_ID = '';
const EMPTY_OFFICE_NAME = '';
const EMPTY_OFFICE_ADDRESS = '';

function AdminOffices() {
    const { t } = useTranslation();
    const getOffices = useGetOffices();
    const createOffice = useCreateOffice();
    const updateOffice = useUpdateOffice();
    const deleteOffice = useDeleteOffice();
    const showErrorNotification = useErrorNotification();

    const [id, setId] = React.useState(EMPTY_OFFICE_ID);
    const [page, setPage] = React.useState(FIRST_PAGE);
    const [rows, setRows] = React.useState<IOfficeTableProps[]>([]);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [rerender, setRerender] = React.useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
    const [isDeleteApproved, setIsDeleteApproved] = React.useState(false);
    const [isOfficesDataLoading, setIsOfficesDataLoading] = React.useState(true);

    React.useEffect(() => {
        const fetchData = async () => {
            let allOffices: OfficeResponse[] = await getOffices();
            let data = mapTableOffices(allOffices);
            if (!data) return;
            setRows(data);
            setIsOfficesDataLoading(false);
        }
        fetchData();
    }, [rerender]);

    React.useEffect(() => {
        const deleteData = async () => {
            await deleteOffice(id.toString());
            setRerender(!rerender);
            // setting id to empty string so we are not accessing id of the deleted office which will cause error
            setId(EMPTY_OFFICE_ID);
            // setting value to false so user can try to delete another office, otherwise nothing will happen because value of isDeleteApproved is true
            setIsDeleteApproved(false);
        }
        isDeleteApproved && deleteData();
    }, [isDeleteApproved]);

    function mapTableOffices(offices: OfficeResponse[]): IOfficeTableProps[] {
        return offices?.map((office: OfficeResponse) => {
            return {
                id: office.officeId,
                name: office.name,
                address: office.address,
                numberOfUsers: office.users?.length,
            } as IOfficeTableProps;
        });
    }

    const AddToolbar = () => {
        const handleClick = () => {
            // returning user to first page because we are adding new created office to start of the array
            page > FIRST_PAGE && setPage(FIRST_PAGE);
            // checking if there is some office being created because only one office can be created at a time
            // if there is no office being created we are adding one empty office and setting its mode to edit
            if (rows.every((office: IOfficeTableProps) => office.name !== EMPTY_OFFICE_NAME || office.address !== EMPTY_OFFICE_ADDRESS)) {
                setRows((oldRows) => [{ id: EMPTY_OFFICE_ID, name: EMPTY_OFFICE_NAME, address: EMPTY_OFFICE_ADDRESS, isNew: true }, ...oldRows,]);
                setRowModesModel((oldModel) => ({ ...oldModel, [EMPTY_OFFICE_ID]: { mode: GridRowModes.Edit, fieldToFocus: 'name' } }));
            } else showErrorNotification(t(TranslationKeyEnum.finishOfficeCreation));
        }

        return (
            <GridToolbarContainer sx={{ padding: SPACING_EXTRA_SMALL }}>
                <Tooltip title={t(TranslationKeyEnum.add)}>
                    <Button variant="contained" size="medium" startIcon={<AddIcon />} sx={{ marginLeft: "auto", textTransform: getTextTransformationAccordingToLanguage() }} onClick={handleClick} disabled={isOfficesDataLoading}>
                        {t(TranslationKeyEnum.addOffice)}
                    </Button>
                </Tooltip>
            </GridToolbarContainer>
        );
    }

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleCancelClick = (id: GridRowId) => () => {
        // checking if focused office id is empty if it is we are removing that item from data grid
        if (id === EMPTY_OFFICE_ID) {
            let removeEmptyIdOffice = rows.filter((row: IOfficeTableProps) => row.id !== EMPTY_OFFICE_ADDRESS);
            setRows(removeEmptyIdOffice);
        }
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });
    };

    const processRowCreateOrUpdate = async (office: IOfficeTableProps) => {
        // office validation
        if (office.name.length === MINIMAL_OFFICE_PROP_LENGHT || office.address.length === MINIMAL_OFFICE_PROP_LENGHT) {
            // removing added office if validation is not satisfied
            // if we did not remove it user can edit or delete that offce and error will occure because that office have empty id
            let removeEmptyIdOffice = rows.filter((row: IOfficeTableProps) => row.id !== EMPTY_OFFICE_ID);
            setRows(removeEmptyIdOffice);
            return showErrorNotification(t(TranslationKeyEnum.officeNameAndAddressEmpty));
        }

        // checks if office id is empty so we can make coresponding action, if id is empty that means that office is still being created
        let createOrUpdate = office.id === EMPTY_OFFICE_ID ? await createOffice(office as OfficeRequest) : await updateOffice(createUpdateOfficeRequestObject(office));
        if (!createOrUpdate) {
            // if we got error message from backend we are removing edited row from data grid
            // otherwise field will be shown in data grid with empty name values and mode setted to view
            let removeEmptyIdOffice = rows.filter((row: IOfficeTableProps) => row.id !== EMPTY_OFFICE_ID);
            return setRows(removeEmptyIdOffice);
        };
        setRerender(!rerender);
        // adding added or updated row to data grid
        const updatedRow = { ...office, isNew: false };
        setRows(rows.map((row) => (row.id === office.id ? updatedRow : row)));
        return updatedRow;
    };

    function createUpdateOfficeRequestObject(office: IOfficeTableProps): UpdateOfficeRequest {
        let updateOfficeObject = new UpdateOfficeRequest({ name: office.name, address: office.address });
        // if we pass office id to constructor officeId is set to undefined
        // so we are setting officeId value explicitly 
        updateOfficeObject.officeId = office.id;
        return updateOfficeObject;
    }

    // removing edited element from data grid if error occurs
    const onProcessRowUpdateError = () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Id', width: 130 },
        { field: 'name', headerName: t(TranslationKeyEnum.name), width: 130, editable: true, flex: 1 },
        { field: 'address', headerName: t(TranslationKeyEnum.address), width: 130, editable: true, flex: 1.5 },
        { field: 'numberOfUsers', headerName: t(TranslationKeyEnum.numberOfEmployees), width: 170, flex: .5 },
        {
            field: 'actions', type: "actions", sortable: false, width: 80, minWidth: 80, flex: .3, getActions: (params: any) => {
                const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <Tooltip title={t(TranslationKeyEnum.save)}><GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(params.id)} /></Tooltip>,
                        <Tooltip title={t(TranslationKeyEnum.cancel)}><GridActionsCellItem icon={<CancelIcon />} label="Cancel" onClick={handleCancelClick(params.id)} /></Tooltip>
                    ]
                }
                return [
                    <Tooltip title={t(TranslationKeyEnum.edit)}><GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={handleEditClick(params.id)} /></Tooltip>,
                    <Tooltip title={t(TranslationKeyEnum.delete)}><GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => setIsDeleteDialogOpen(true)} /></Tooltip>
                ]
            }
        }
    ];

    return (
        <Box sx={{ boxShadow: CARD_BOX_SHADOW }}>
            {
                isDeleteDialogOpen &&
                <ConfirmDialog
                    dialogOpen={isDeleteDialogOpen}
                    setDialogOpen={setIsDeleteDialogOpen}
                    title={t(TranslationKeyEnum.confirmDelete)}
                    description={t(TranslationKeyEnum.deleteOfficeDescription)}
                    isActionConfirmed={setIsDeleteApproved}
                />
            }
            <Box>
                <Typography variant="h5" sx={{ padding: SPACING_EXTRA_SMALL }}>{t(TranslationKeyEnum.offices)}</Typography>
            </Box>
            <EditableDataGrid
                rows={rows}
                columns={columns}
                page={page}
                setPage={setPage}
                setRows={setRows}
                setId={setId}
                rowModesModel={rowModesModel}
                setRowModesModel={setRowModesModel}
                processRowUpdate={processRowCreateOrUpdate}
                onProcessRowUpdateError={onProcessRowUpdateError}
                toolbar={AddToolbar}
                isLoading={isOfficesDataLoading}
            />
        </Box>
    )
}

export default AdminOffices