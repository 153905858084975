import React from 'react';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import PermissionContext from "../.././Permission/PermissionContext";
import CreateTimeOffRequestDialog from '../../TimeOff/CreateTimeOffRequestDialog/CreateTimeOffRequestDialog';
import TimeOffRequests from '../../TimeOff/RequestTimeOff/TimeOffRequests';
import { Grid, Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LINE_BORDER, SPACING_EXTRA_LARGE, SPACING_LARGE, SPACING_SMALL } from '../../../utils/cssUtils';
import { TranslationKeyEnum } from '../../../features/translations/TranslationKeyEnum';
import { useGetPolicies } from '../../TimeOff/actions';
import { TimeOffPolicyVM } from '../../../API/time-off-service';
import { ITimeOffInfo } from './ITimeOffInfo';
import { showListOfUserPolicies } from '../../TimeOff/helpers';
import { useContext } from 'react';
import { PermissionsEnum } from '../../Permission/PermisionEnum';
import { getUserIdFromStorage } from '../../../utils/storageUtils/storageUtils';
import { getTextTransformationAccordingToLanguage } from '../../../features/translations/helpers';

export default function TimeOffInfo({ userId }: ITimeOffInfo) {
    const { t } = useTranslation();
    const getPolicies = useGetPolicies(userId);
    const { isAllowed } = useContext(PermissionContext);
    const [isUserApprover, setIsUserApprover] = React.useState<boolean>(false);
    const [isOpenMyRequests, setIsOpenMyRequests] = React.useState<boolean>(false);
    const loggedUserId = getUserIdFromStorage();

    const [userPolicies, setUserPolicies] = React.useState<TimeOffPolicyVM[]>([]);
    const [isRequestFormOpen, setIsRequestFormOpen] = React.useState<boolean>(false);
    // these props trigger useEffect that fetches policy info after the request is created
    const [isRequestFormSubmited, setIsRequestFormSubmited] = React.useState<boolean>(false);

    React.useEffect(() => {
        const fetchPolicies = async () => {
            if (!userId) return;
            let timeOffPolicies = await getPolicies();
            if (!timeOffPolicies) return;
            setUserPolicies(timeOffPolicies.policies!);
            timeOffPolicies.approverId == loggedUserId && checkApproverPermission();
        }
        fetchPolicies();
    }, [userId, isRequestFormOpen])

    const checkApproverPermission = async () => {
        const allowed = await isAllowed(PermissionsEnum.AllowTimeOffApproverPermission);
        setIsUserApprover(allowed);
    }

    return (
        <>
            {(isUserApprover || userId === getUserIdFromStorage())
                && < Grid container padding={SPACING_LARGE} >
                    {/* Labels */}
                    < Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <BeachAccessIcon color="primary" fontSize="large" sx={{ marginBottom: SPACING_SMALL }} />
                        <Typography variant="h5" marginBottom={SPACING_SMALL}>{t(TranslationKeyEnum.timeOffTab)}</Typography>
                        <Typography variant="body2" marginBottom={SPACING_SMALL} width="70%">{t(TranslationKeyEnum.timeOffInformation)}</Typography>
                        <Box width={SPACING_EXTRA_LARGE} border={LINE_BORDER} marginBottom={SPACING_SMALL} />
                        <Box display="flex" flexDirection="column">
                            <Button variant="contained" size="small" sx={{ marginBottom: SPACING_SMALL, maxWidth: "30vh", textTransform: getTextTransformationAccordingToLanguage() }} onClick={() => setIsRequestFormOpen(true)}>
                                {isUserApprover || (userId !== getUserIdFromStorage()) ? t(TranslationKeyEnum.addTimeOffDays) : t(TranslationKeyEnum.requestTimeOff)}
                            </Button>
                            <Button variant="outlined" size="small" sx={{ marginBottom: SPACING_SMALL, maxWidth: "26vh", textTransform: getTextTransformationAccordingToLanguage() }} onClick={() => setIsOpenMyRequests(true)}>{t(TranslationKeyEnum.viewAllTimeOffRequests)}</Button>
                            {isOpenMyRequests && <TimeOffRequests isOpen={isOpenMyRequests} setIsOpen={setIsOpenMyRequests} isForUser={userId === getUserIdFromStorage()} userId={userId!} isJustForAdmin={false}/>}
                        </Box>
                    </Grid >
                    {/* Time-Off Cards */}
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} display="flex" flexDirection="column" alignItems="center">
                        {showListOfUserPolicies(userPolicies, userId!, true)}
                    </Grid>
                </Grid >}
            {
                isRequestFormOpen && <CreateTimeOffRequestDialog userId={userId!} isOpen={isRequestFormOpen} setIsOpen={setIsRequestFormOpen} isForUser={isUserApprover || (userId !== getUserIdFromStorage())} />
            }
        </>
    )
}