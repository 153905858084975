import {useContext, useState} from 'react';
import PermissionContext from "./PermissionContext";

const usePermission = (permission: string) => {
    const [allowed, setAllowed] = useState<boolean>();
    const {isAllowed} = useContext(PermissionContext);

    isAllowed(permission).then((result) => {
        result !== allowed && setAllowed(result);
    });

    return allowed;
}

export default usePermission;