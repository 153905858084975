import { FormikValues, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { CreateUserRequest, StatusEnum } from '../../../API/identity-service';
import { TranslationKeyEnum } from '../../../features/translations/TranslationKeyEnum';
import { IMainInforFormProps } from './IMainInfoFormProps.';
import { IUserProps } from '../../common/interfaces/IUserProps';
import { useCreateEmployee } from '../../../API/identity-actions';
import { useMainInfoFormikForm } from './mainInfoFormikForm';
import { StyledTextField } from '../../StyledTextfield/StyledTextField';
import React from 'react';
import StepperFormFooter from '../StepperFormFooter/FormFooter';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Loader from '../../Loader/Loader';
import { Switch, FormControlLabel } from '@mui/material';

export function MainInfoForm({ handleMainFormSubmit }: IMainInforFormProps) {
    const { t } = useTranslation();
    const createEmployee = useCreateEmployee();
    const formikMainInfo = useMainInfoFormikForm();
    const [isFormLoading, setIsFormLoading] = React.useState(false);
    const [hasAccessToApp, setHasAccessToApp] = React.useState(true);

    const mainInfoForm: FormikValues = useFormik(formikMainInfo(handleSubmit));

    async function handleSubmit() {
        setIsFormLoading(true);
        const request = mainInfoForm.values as CreateUserRequest;
        const status = hasAccessToApp ? StatusEnum.Active : StatusEnum.AccessDisabled;
        request.status = status;
        let createUser: IUserProps = await createEmployee(request);
        setIsFormLoading(false);
        createUser && handleMainFormSubmit(createUser.userId, mainInfoForm.values.firstName, mainInfoForm.values.lastName, mainInfoForm.values.email, status);
    }

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHasAccessToApp(event.target.checked);
      };

    return (
        <form onSubmit={mainInfoForm.handleSubmit}>
            <StyledTextField
                fullWidth
                id="firstName"
                name="firstName"
                type="text"
                label={t(TranslationKeyEnum.firstName)}
                value={mainInfoForm.values.firstName}
                onChange={mainInfoForm.handleChange}
                error={mainInfoForm.touched.firstName && Boolean(mainInfoForm.errors.firstName)}
                helperText={mainInfoForm.touched.firstName && mainInfoForm.errors.firstName}
                margin="dense"
                disabled={isFormLoading}
                InputLabelProps={{ shrink: true }}                                
            />
            <StyledTextField
                fullWidth
                id="lastName"
                name="lastName"
                type="lastName"
                label={t(TranslationKeyEnum.lastName)}
                value={mainInfoForm.values.lastName}
                onChange={mainInfoForm.handleChange}
                error={mainInfoForm.touched.lastName && Boolean(mainInfoForm.errors.lastName)}
                helperText={mainInfoForm.touched.lastName && mainInfoForm.errors.lastName}
                margin="dense"
                disabled={isFormLoading}
                InputLabelProps={{ shrink: true }}                                    
            />
            <StyledTextField
                fullWidth
                id="email"
                name="email"
                type="email"
                label={t(TranslationKeyEnum.email)}
                value={mainInfoForm.values.email}
                onChange={mainInfoForm.handleChange}
                error={mainInfoForm.touched.email && Boolean(mainInfoForm.errors.email)}
                helperText={mainInfoForm.touched.email && mainInfoForm.errors.email}
                margin="dense"
                disabled={isFormLoading}
                InputLabelProps={{ shrink: true }}                                    
            />
            <FormControlLabel control={<Switch checked={hasAccessToApp} onChange={handleSwitchChange} />} label={t(TranslationKeyEnum.accessToApplication)} />
            {isFormLoading && <Loader size={50} />}
            <StepperFormFooter
                isFormLoading={isFormLoading}
                secondButtonLabel={t(TranslationKeyEnum.next)}
                secondButtonIcon={<KeyboardArrowRight />} />
        </form>
    )
}

export default MainInfoForm