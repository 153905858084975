import { CreateGroupRequest, GroupClient, GroupDto, ResponseMessage, UpdateGroupRequest, UserClient, UserWithGroupDto } from "../../API/time-off-service";
import { useTryCatchJsonByAction } from "../../utils/fetchUtils";

const groupClient = new GroupClient();
const userClient = new UserClient();
const getGroupsAction = (): Promise<GroupDto[]> => groupClient.getAll();
const getUserWithGroupsAction = (id: string): Promise<UserWithGroupDto> => userClient.getUserAndGroups(id);
const removeGroupAction = (id: string): Promise<ResponseMessage> => groupClient.remove(id);
const createGroupAction = (request: CreateGroupRequest) => groupClient.create(request);
const updateGroupAction = (request: UpdateGroupRequest) => groupClient.update(request);

export function useGetGroups(): () => Promise<GroupDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();

    async function getGroups(): Promise<GroupDto[]> {
        const bindedGetGroupsAction = getGroupsAction.bind(null);
        const groupsModel = await tryCatchAction(bindedGetGroupsAction);

        return groupsModel ? groupsModel.response : [];
    }

    return getGroups;
}

export function useGetUserWithGroups(): (id: string) => Promise<UserWithGroupDto> {
    const tryCatchAction = useTryCatchJsonByAction();

    async function getUserWithGroups(id: string): Promise<UserWithGroupDto> {
        const bindedGetGroupsAction = getUserWithGroupsAction.bind(null, id);
        const userWithGrups = await tryCatchAction(bindedGetGroupsAction);

        return userWithGrups ? userWithGrups.response : {};
    }

    return getUserWithGroups;
}

export function useRemoveGroup(): (id: string) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();

    async function removeGroup(id: string): Promise<boolean> {
        const bindedRemoveGroupAction = removeGroupAction.bind(null, id);
        const response = await tryCatchAction(bindedRemoveGroupAction);

        return response.success;
    }

    return removeGroup;
}

export function useCreateGroup(): (request: CreateGroupRequest) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();

    async function createGroup(request: CreateGroupRequest): Promise<boolean> {
        const bindedCreateGroupAction = createGroupAction.bind(null, request);
        const response = await tryCatchAction(bindedCreateGroupAction);

        return response.success;
    }

    return createGroup;
}

export function useUpdateGroup(): (request: UpdateGroupRequest) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();

    async function updateGroup(request: UpdateGroupRequest): Promise<boolean> {
        const bindedUpdateGroupAction = updateGroupAction.bind(null, request);
        const response = await tryCatchAction(bindedUpdateGroupAction);

        return response.success;
    }

    return updateGroup;
}