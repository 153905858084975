import React from 'react';
import FullCalendar from '@fullcalendar/react'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckIcon from '@mui/icons-material/Check';
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css'; // needs additional webpack config!
import "./calendar.css";
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { ICalendarProps } from './ICalendarProps'
import { TranslationKeyEnum } from "../../../features/translations/TranslationKeyEnum";
import { renderMonthAndYearAccordingToLanguage, renderWeekdayHeaderAccordingToLanguage, subtractDays, temporaryEventColor, temporaryEventId, temporaryEventTextColor } from './helpers';

export default (props: ICalendarProps) => {
    const { t } = useTranslation();
    const [renderKey, setRenderKey] = React.useState<string>(props.events.length.toString());

    const handleDateSelect = (selectInfo: { view: { calendar: any }; startStr: any; endStr: any; allDay: any }) => {
        let calendarApi = selectInfo.view.calendar

        // we are subtracting one day because fullcalendar module is setting +1 
        // day for the end date (it assumes that the date range ends on the 
        // "last day at 00:00:00")
        // quote from the documentation: "For example, if you have an all-day 
        // event that has an end of 2018-09-03, then it will span through 
        // 2018-09-02 and end before the start of 2018-09-03."
        props.selectDateRange!(new Date(selectInfo.startStr), subtractDays(new Date(selectInfo.endStr), 1));

        calendarApi.unselect() // clear date selection

        // remove previously set temporary event (if it exists)
        var temporaryEvent = calendarApi.getEventById(temporaryEventId);
        temporaryEvent && temporaryEvent.remove();

        calendarApi.addEvent({
            id: temporaryEventId,
            title: t(TranslationKeyEnum.selectedDateRange),
            start: selectInfo.startStr,
            end: selectInfo.endStr,
            allDay: selectInfo.allDay,
            color: temporaryEventColor,
            textColor: temporaryEventTextColor,
            display: 'background',
            classNames: ["selected-date-range"]
        })
    }

    interface EventElement {
        [key: string]: { tooltipText: string, icon: JSX.Element | string };
    }

    function renderEventContent(eventInfo: any) {
        const eventElements: EventElement = {
            "#FFF3E0": {
                tooltipText: t(TranslationKeyEnum.requestPending),
                icon: <div style={{ paddingInline: '0.5em' }}><AccessTimeIcon fontSize='small' /></div>
            },
            "#FECDD2": {
                tooltipText: eventInfo.event.title,
                icon: ""
            },
            "#CFD8DC": {
                tooltipText: t(TranslationKeyEnum.selectedDateRange),
                icon: ""
            },
            "APPROVED_REQUESTS": {
                tooltipText: t(TranslationKeyEnum.requestApproved),
                icon: <div style={{ paddingInline: '0.5em' }}><CheckIcon fontSize='small' /></div>
            },
        }

        const tooltipText = eventElements[eventInfo.event.backgroundColor] ? eventElements[eventInfo.event.backgroundColor].tooltipText : eventElements["APPROVED_REQUESTS"].tooltipText;
        const icon = eventElements[eventInfo.event.backgroundColor] ? eventElements[eventInfo.event.backgroundColor].icon : eventElements["APPROVED_REQUESTS"].icon;

        return (
            <Tooltip title={tooltipText} placement="top-start">
                <div style={{ display: 'flex', alignItems: "center" }}>
                    {icon}
                    {eventInfo.event.title}
                </div>
            </Tooltip>
        )
    }

    React.useEffect(() => {
        setRenderKey(Date.now().toString())
    }, [props.events])

    return (
        <FullCalendar
            key={renderKey}
            plugins={[dayGridPlugin, interactionPlugin, bootstrap5Plugin]}
            dayHeaderContent={renderWeekdayHeaderAccordingToLanguage}
            titleFormat={renderMonthAndYearAccordingToLanguage}
            themeSystem='bootstrap5'
            initialView='dayGridMonth'
            aspectRatio={1.35}
            headerToolbar={
                { start: 'prev,next,title', center: '', end: '' }
            }
            selectable={props.selectable ?? false}
            selectMirror
            dayMaxEvents
            weekends
            weekNumberCalculation='ISO'
            initialEvents={props.events}
            initialDate={props.initialDate}
            select={handleDateSelect}
        // eventContent={renderEventContent} @fullcalendar has a bug when rendering injected content
        />
    )
}

