import { FormikConfig, FormikValues } from "formik";
import { BasicTimeOffPolicyDto } from "../../../API/time-off-service";
import { usePolicyFormSchemas } from "./policyFormSchemas";

export function usePolicyFormikForm(): (policy: BasicTimeOffPolicyDto, handleSubmit: () => void) => FormikConfig<FormikValues> {
    const policyValidationSchema = usePolicyFormSchemas();
    
    function policyFormikForm(policy: BasicTimeOffPolicyDto, handleSubmit: () => void): FormikConfig<FormikValues> {
        return {
            initialValues: {
                name: policy.name ?? "",
                color: policy.color === undefined || policy.color === "Undefined" ? "" : policy.color,
                infiniteDays: policy.infiniteDays ?? false,
                allowNegativeBalance: policy.allowNegativeBalance ?? false,
                dependsOnCheck: policy.dependsOnPolicy == undefined || policy.dependsOnPolicy.length === 0 || policy.dependsOnPolicy === "00000000-0000-0000-0000-000000000000" ? false : true,
                dependsOnPolicy: policy.dependsOnPolicy ?? "",
                startDate: policy.startDate ?? new Date(),
                endDate: policy.endDate ?? new Date(),
            },
            validationSchema: policyValidationSchema,
            onSubmit: handleSubmit,
        };
    }
    return policyFormikForm;
}