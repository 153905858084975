export enum PermissionsEnum {
    ManageUsersPermission = 'ManageUsersPermission',
    ManageUserPermissionsPermission = 'ManageUserPermissionsPermission',
    ManageOfficePermission = 'ManageOfficePermission',
    ManagePositionPermission = 'ManagePositionPermission',
    ManageTimeOffPermission = 'ManageTimeOffPermission',
    AllowTimeOffApproverPermission = 'AllowTimeOffApproverPermission',
    AdminConsolePermission = 'AdminConsolePermission',
    ManageWorkOrderPermission = 'ManageWorkOrderPermission',
    ManageRoomPermission = 'ManageRoomPermission',
    ManageNewsFeedPermission = 'ManageNewsFeedPermission',
}