import React from 'react';
import NextTimeOffCard from './NextTimeOffCard/NextTimeOffCard';
import TimeOffPolicies from './TimeOffPolicies/TimeOffPolicies';
import CreateTimeOffRequestDialog from './CreateTimeOffRequestDialog/CreateTimeOffRequestDialog';
import YearCalendar from '../../components/YearCalendar/YearCalendar';
import { Grid, Box, Typography, Avatar, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IUserProps } from '../../components/common/interfaces/IUserProps';
import { TranslationKeyEnum } from '../../features/translations/TranslationKeyEnum';
import { LIGHT_BORDER, SPACING_MEDIUM, SPACING_SMALL, PRIMARY_BG_COLOR, BORDER_RADIUS_MEDIUM, CARD_BOX_SHADOW } from '../../utils/cssUtils';
import { useGetBankHolidaysByUser } from '../../components/AdminBankHolidays/actions';
import { RequestUserTableRow, useGetRequestsForUser } from '../PendingRequests/action';
import { BankHolidayWithUsersDto } from '../../API/time-off-service';
import { IYearCalendarEventProps } from '../../components/YearCalendar/IYearCalendarProps';
import { dateRange } from '../../utils/dateUtils';

const TIME_OFF_COLOR: string = "#9C27B0";
const BANK_HOLIDAY_COLOR: string = "#200c61";

export default function TimeOff({ userId, firstName, lastName, image }: IUserProps) {
    const { t } = useTranslation();
    const getBankHolidays = useGetBankHolidaysByUser();
    const getUserTimeOffHistory = useGetRequestsForUser();

    const [userTimeOffHistory, setUserTimeOffHistory] = React.useState<RequestUserTableRow[]>([]);
    const [bankHolidays, setBankHolidays] = React.useState<BankHolidayWithUsersDto[]>([]);
    const [isRequestFormOpen, setIsRequestFormOpen] = React.useState<boolean>(false);

    React.useEffect(() => {
        const fetchData = async () => {
            Promise.all([getBankHolidays(), getUserTimeOffHistory()]).then((values) => {
                setBankHolidays(values[0]);
                setUserTimeOffHistory(values[1]);
            })
        }
        fetchData();
    }, [])

    const handleOpenRequestForm = () => () => {
        setIsRequestFormOpen(true);
    };

    const getCalendarEvents = (): IYearCalendarEventProps[] => {
        let timeOffArray: IYearCalendarEventProps[] = userTimeOffHistory
            .flatMap(timeOff => dateRange(timeOff.startDate, timeOff.endDate))
            .map(event => { return { [event.toLocaleDateString()]: { label: t(TranslationKeyEnum.daysOff), color: TIME_OFF_COLOR } } });
        let bankHolidayArray: IYearCalendarEventProps[] = bankHolidays
            .flatMap(bankHoliday => dateRange(bankHoliday.startDate!, bankHoliday.endDate!))
            .map(event => { return { [event.toLocaleDateString()]: { label: t(TranslationKeyEnum.bankHolidays), color: BANK_HOLIDAY_COLOR } } });

        let timeOffDictionary: IYearCalendarEventProps = {};
        for (let timeOff of timeOffArray) {
            timeOffDictionary = Object.assign(timeOffDictionary, timeOff);
        }
        let bankHolidayDictionary: IYearCalendarEventProps = {};
        for (let bankHoliday of bankHolidayArray) {
            bankHolidayDictionary = Object.assign(bankHolidayDictionary, bankHoliday)
        }

        return [timeOffDictionary, bankHolidayDictionary];
    }

    return <>
        <Grid container borderRadius={BORDER_RADIUS_MEDIUM} boxShadow={CARD_BOX_SHADOW} sx={{ backgroundColor: PRIMARY_BG_COLOR }}>
            {/* User First and Last name and Request Time Off */}
            <Grid item lg={12} xl={12} md={12} sm={12} xs={12} borderBottom={LIGHT_BORDER} display="flex" position="relative" padding={SPACING_SMALL}>
                <Grid item margin={{ xl: "0 auto", lg: "0 auto", md: "0 auto" }} xl={6} lg={6} md={6} sm={6} xs={6} display="flex" alignItems="center" justifyContent="center">
                    <Avatar sx={{ width: SPACING_MEDIUM, height: SPACING_MEDIUM }} variant="rounded" src={image && `data:image/png;base64,${image}`} alt="user image" />
                    <Typography variant="body1" marginLeft={SPACING_SMALL}>{firstName} {lastName}</Typography>
                </Grid>
                <Grid item lg={6} xl={6} md={6} sm={6} xs={6} position="absolute" right="0" top="50%" paddingRight={SPACING_SMALL} sx={{ transform: "translateY(-50%)" }}>
                    <Button variant="contained" size="small" sx={{ textTransform: "capitalize" }} onClick={handleOpenRequestForm()}>{t(TranslationKeyEnum.requestTimeOff)}</Button>
                </Grid>
            </Grid>
            {/* Time Off Policies and Next Time Off */}
            <Grid item lg={6} xl={6} md={6} sm={12} xs={12}>
                <Box padding={`${SPACING_MEDIUM} 0`} >
                    <TimeOffPolicies userId={userId} isRequestFormOpen={isRequestFormOpen} />
                    <NextTimeOffCard />
                </Box>
            </Grid>
            {/* Calendar */}
            <Grid item lg={6} xl={6} md={6} sm={12} xs={12} sx={{ backgroundColor: '#EEEEEE' }}>
                <YearCalendar calendarEvents={getCalendarEvents()} />
            </Grid>
        </Grid>
        {
            isRequestFormOpen && <CreateTimeOffRequestDialog userId={userId} isOpen={isRequestFormOpen} setIsOpen={setIsRequestFormOpen} isForUser={false} />
        }
    </>
}