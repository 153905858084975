import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { TranslationKeyEnum } from "../../features/translations/TranslationKeyEnum";

const MAXIMUM_PASSWORD_LENGTH = 12;
const MINIMUM_PASSWORD_LENGTH = 6;

export function useLoginValidationSchemas() {
    const { t } = useTranslation();

    return Yup.object({
        email: Yup.string().email(t(TranslationKeyEnum.emailError)).required(t(TranslationKeyEnum.required)),
        password: Yup.string()
            .required(t(TranslationKeyEnum.required))
            .max(MAXIMUM_PASSWORD_LENGTH, t(TranslationKeyEnum.passwordMax, { maximumLength: MAXIMUM_PASSWORD_LENGTH }))
            .min(MINIMUM_PASSWORD_LENGTH,  t(TranslationKeyEnum.passwordMin, { minimumLength: MINIMUM_PASSWORD_LENGTH}))
    })
}