import { SortByEnum, UserClient, ItemsResponseModelOfEmployeeDto, OfficeDto, OfficeClient } from "../../API/user-management-service";
import { useTryCatchJsonByAction } from "../../utils/fetchUtils";

export function useSearchUsers() : (sortBy: SortByEnum | undefined, searchString: string | undefined, position: string | undefined, office: string | undefined, offset: number | undefined, limit: number | undefined) => Promise<ItemsResponseModelOfEmployeeDto> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function searchUsers(sortBy: SortByEnum | undefined, searchString: string | undefined, position: string | undefined, office: string | undefined, offset: number | undefined, limit: number | undefined): Promise<ItemsResponseModelOfEmployeeDto>{
        const bindedAction = searchAction.bind(null, sortBy, searchString, position, office);
        const searchUsers = await tryCatchAction(bindedAction);
        return searchUsers ? searchUsers.response : {};
    }
    return searchUsers;
}

const searchAction = async (sortBy: SortByEnum | undefined, searchString: string | undefined, position: string | undefined, office: string | undefined, offset: number | undefined, limit: number | undefined): Promise<ItemsResponseModelOfEmployeeDto> => {
    const client = new UserClient();
    return await client.searchUsers(sortBy, searchString, position, office, offset, limit);
}


export function useGetOfficesBasicInformation(): () => Promise<OfficeDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getOffices(): Promise<OfficeDto[]> {
        const bindedAction = getOfficesAction.bind(null);
        const getAllOffices = await tryCatchAction(bindedAction);
        return getAllOffices ? getAllOffices.response : [];
    }
    return getOffices;
}

export const getOfficesAction = async (): Promise<OfficeDto[]> => {
	const client = new OfficeClient();
	return await client.getOfficesBasicInformation();
}