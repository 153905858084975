import { FormikValues } from "formik";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { StatusEnum, UserClient } from "../../../API/identity-service";
import { UserClient as UserMngmtClient } from "../../../API/user-management-service"
import { IUserResponse, PositionDto, UpdateUserRequest, UserResponse } from "../../../API/user-management-service";
import { toBase64 } from "../../../features/toBase64";
import { useTryCatchJsonByAction } from "../../../utils/fetchUtils";
import { IApproverProps } from "./IApproverProps";
import { isDateYearOne } from "../../../utils/dateUtils";

export default class PositionProps {
    public name?: string;
    public startDate?: Date;
    public endDate?: Date | null;
    public isCurrentPosition?: boolean | null;

    public constructor(positionDto: PositionDto) {
        this.name = positionDto.name!;
        this.startDate = positionDto.startDate!;
        this.endDate = positionDto.endDate!;
        this.isCurrentPosition = isDateYearOne(positionDto.endDate!);
    }
}

const userClient = new UserClient();

export function handleAddPosition(formikForm: FormikValues, setIsReinitializeEnabled?: Dispatch<SetStateAction<boolean>>) {
    let positions: PositionProps[] = [new PositionDto({ name: "", startDate: new Date(), endDate: new Date() }), ...formikForm.values.positions];
    setIsReinitializeEnabled && setIsReinitializeEnabled(false);
    formikForm.setFieldValue("positions", positions);
}

export function useGetAllApproverIds(): () => Promise<string[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getAllApproverIds(): Promise<string[]> {
        const bindedAction = getAllApproverIdsAction.bind(null);
        const getUsersResponse = await tryCatchAction(bindedAction);
        return getUsersResponse ? getUsersResponse.response : [];
    }
    return getAllApproverIds;
}

const getAllApproverIdsAction = (): Promise<string[]> => {
    return userClient.getAllApproverIds();
}

export function handleRemovePosition(formikForm: FormikValues, positionIndex: number) {
    let positionsWithoutRemovedPosition = formikForm.values.positions.filter((p: PositionProps, index: number) => index !== positionIndex);
    formikForm.setFieldValue("positions", positionsWithoutRemovedPosition);
}

export function handleCurrentPositionSelect(formikForm: FormikValues, event: any, index: number, isEditForm: boolean, setIsReinitializeEnabled?: Dispatch<SetStateAction<boolean>>) {
    let isChecked = event.target.checked;

    for (let p of formikForm.values.positions) {
        if (p.isCurrentPosition || isDateYearOne(p.endDate)) {
            p.isCurrentPosition = false;
            p.endDate = p.startDate;
            break;
        }
    }
    isEditForm && setIsReinitializeEnabled && setIsReinitializeEnabled(false);
    formikForm.values.positions[index].isCurrentPosition = isChecked;
    formikForm.setFieldValue("positions", formikForm.values.positions);
}

export function handleImageUpload(formikForm: FormikValues, event: ChangeEvent<HTMLInputElement>) {
    let image = event.target.files && event.target.files[0];
    toBase64(image, (result: any) => {
        formikForm.setFieldValue("image", result);
        formikForm.setFieldValue("sizeOfImage", image?.size);
        formikForm.setFieldValue("formatOfImage", image?.type);
    });
    //reseting value so we can upload same image again
    event.target.value = "";
}

export function handlePositionDateChange(formikForm: FormikValues, selectedDate: Date, index: number, date: string, isEditForm: boolean = false, setIsReinitializeEnabled?: Dispatch<SetStateAction<boolean>>) {
    isEditForm && setIsReinitializeEnabled && setIsReinitializeEnabled(false);
    let changedPosition: PositionProps = formikForm.values.positions.find((p: PositionProps, positionIndex: number) => positionIndex === index);
    date === "startDate" ? changedPosition.startDate = new Date(selectedDate) : changedPosition.endDate = new Date(selectedDate);
    formikForm.values.positions[index] = changedPosition;
    formikForm.setFieldValue("positions", formikForm.values.positions);
    //checks if selected start date is after end date
    if (date === "startDate" && changedPosition.endDate && changedPosition.startDate && new Date(changedPosition.endDate) < new Date(changedPosition.startDate)) {
        //if start date is greater then end date we are setting end date to be equal to start date
        changedPosition.endDate = changedPosition.startDate;
        formikForm.values.positions[index] = changedPosition;
        formikForm.setFieldValue("positions", formikForm.values.positions);
    }
}

export function createMgmtUser(formikValues: any, createdUser?: IUserResponse): UpdateUserRequest {
    let user = new UpdateUserRequest();
    user.init({
        id: createdUser ? createdUser.userId : formikValues.id,
        email: createdUser ? createdUser.email : formikValues.email,
        firstName: createdUser ? createdUser.firstName : formikValues.firstName,
        lastName: createdUser ? createdUser.lastName : formikValues.lastName,
        phone: formikValues.phone,
        address: formikValues.address,
        birthdate: isDateYearOne(formikValues.birthdate) ? null : formikValues.birthdate,
        office: formikValues.office,
        image: formikValues.image,
        positions: formikValues.positions,
        status: formikValues.status,
        personalNo: formikValues.personalNo,
        passportNumber: formikValues.passportNumber,
        passportValidUntil: isDateYearOne(formikValues.passportValidUntil) ? null : formikValues.passportValidUntil,
        contractExpiration: isDateYearOne(formikValues.contractExpiration) ? null : formikValues.contractExpiration,
        idCardNumber: formikValues.idCardNumber,
        idCardValidUntil: isDateYearOne(formikValues.idCardValidUntil) ? null : formikValues.idCardValidUntil,
        doctorAppointment: isDateYearOne(formikValues.doctorAppointment) ? null : formikValues.doctorAppointment,
        certificateValidUntil: isDateYearOne(formikValues.certificateValidUntil) ? null : formikValues.certificateValidUntil,
        files: formikValues.files,
        filesToDelete: formikValues.filesToDelete,
        filesToAdd: formikValues.filesToAdd,
        medicalInsuranceNumber: formikValues.medicalInsuranceNumber,
        religiousDay: isDateYearOne(formikValues.religiousDay) ? null : formikValues.religiousDay,
    });
    return user;
}

export async function organizeEmployeesData(approvers: UserResponse[], setEmployeeDropdownData: Dispatch<SetStateAction<IApproverProps[]>>, setIsApproverDataLoading?: Dispatch<SetStateAction<boolean>>) {
    let organizedEmployeesData: IApproverProps[] = approvers.map((e: UserResponse) => { return { id: e.userId, fullname: `${e.firstName} ${e.lastName}` } as IApproverProps });
    setEmployeeDropdownData(organizedEmployeesData);
    setIsApproverDataLoading && setIsApproverDataLoading(false);
}

export function useGetApprovers(): (approverIds: string[]) => Promise<UserResponse[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleGetApprovers(approverIds: string[]): Promise<UserResponse[]> {
        const bindedAction = getApproversAction.bind(null, approverIds);
        const getApproversResponse = await tryCatchAction(bindedAction);
        return getApproversResponse ? getApproversResponse.response : [];
    }
    return handleGetApprovers;
}

const getApproversAction = (approverIds: string[]): Promise<UserResponse[]> => {
    const client = new UserMngmtClient();
    return client.getApproversByIds(approverIds);
}