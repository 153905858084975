import React from 'react';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import WorkOrdersHistory from '../../WorkOrders/WorkOrdersHistory/WorkOrdersHistory';
import UpcomingWorkOrders from '../../WorkOrders/UpcomingWorkOrders/UpcomingWorkOrders';
import PermissionContext from '../../Permission/PermissionContext';
import { Grid, Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LINE_BORDER, SPACING_EXTRA_LARGE, SPACING_LARGE, SPACING_SMALL } from '../../../utils/cssUtils';
import { TranslationKeyEnum } from '../../../features/translations/TranslationKeyEnum';
import { getTextTransformationAccordingToLanguage } from '../../../features/translations/helpers';
import { PermissionsEnum } from '../../Permission/PermisionEnum';
import { IWorkOrderInfoProps } from './WorkOrderCard/IWorkOrderInfoProps';
import { getUserIdFromStorage } from '../../../utils/storageUtils/storageUtils';
import { SingleUserMonthlySummaryDto } from '../../../API/work-order-service';

export default function WorkOrdersInfo({ userId }: IWorkOrderInfoProps) {
    const { t } = useTranslation();
    const { isAllowed } = React.useContext(PermissionContext);

    const [isSupervisor, setIsSupervisor] = React.useState<boolean>(false);
    const [isWorkOrdersHistoryOpen, setIsWorkOdersHistoryOpen] = React.useState(false);
    const [rerender, setRerender] = React.useState(false);

    React.useEffect(() => {
        const fetchSupervisorPermission = async () => {
            const allowed = await isAllowed(PermissionsEnum.ManageWorkOrderPermission);
            setIsSupervisor(allowed);
        }
        fetchSupervisorPermission();
    })

    return (
        <>
            {(isSupervisor || userId === getUserIdFromStorage())
                && <Grid container padding={SPACING_LARGE}>
                    {/* Labels */}
                    < Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <ContentPasteGoIcon color="primary" fontSize="large" sx={{ marginBottom: SPACING_SMALL }} />
                        <Typography variant="h5" marginBottom={SPACING_SMALL}>{t(TranslationKeyEnum.workOrdersTab)}</Typography>
                        <Typography variant="body2" marginBottom={SPACING_SMALL} width="70%">{t(TranslationKeyEnum.workOrdersInfo)}</Typography>
                        <Button
                            variant="outlined"
                            size="small"
                            sx={{ textTransform: getTextTransformationAccordingToLanguage(), marginBottom: SPACING_SMALL }}
                            onClick={() => setIsWorkOdersHistoryOpen(true)}
                        >
                            {t(TranslationKeyEnum.viewWorkOrderHistory)}
                        </Button>
                        {
                            isWorkOrdersHistoryOpen && <WorkOrdersHistory
                                isOpen={isWorkOrdersHistoryOpen}
                                setIsOpen={setIsWorkOdersHistoryOpen}
                                data={new SingleUserMonthlySummaryDto({ workOrders: [], workOrdersCountByType: {} })}
                                month={new Date()}
                                rerender={rerender}
                                setRerender={setRerender}
                            />
                        }
                        <Box width={SPACING_EXTRA_LARGE} border={LINE_BORDER} marginBottom={{ xl: "0", lg: "0", md: "0", sm: SPACING_LARGE, xs: SPACING_LARGE }} />
                    </Grid >
                    {/* Work Orders Tabs */}
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} display="flex" flexDirection="column" alignItems="center">
                        <UpcomingWorkOrders rerender={rerender} setRerender={setRerender} isForMe={userId === getUserIdFromStorage()} />
                    </Grid>
                </Grid >
            }
        </>
    )
}