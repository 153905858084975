class AppSettings {
	public getApiUrl = (): string => `https://${process.env.NODE_ENV === "production"
		? window.location.href.split('.')[0].split('//')[1] + '.'
		: ''}${process.env.REACT_APP_API_HOST}`;

	public getNotificationsApiUrl = (): string => `https://${process.env.NODE_ENV === "production"
		? window.location.href.split('.')[0].split('//')[1] + '.'
		: ''}${process.env.REACT_APP_API_NOTIFICATIONS_HOST}`;
}

export default new AppSettings();