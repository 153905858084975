import { CopyTimeOffPolicyRequest, CreateTimeOffPolicyRequest, UserDaysDto } from "../../../API/time-off-service";
import { ColorType } from "../../Buttons/IPalletteButton";

export interface IAddEditPolicyFormProps {
    open: boolean;
    onClose: () => void,
    onAddSubmit: (request: CreateTimeOffPolicyRequest) => void;
    onEditSubmit: (request: CreateTimeOffPolicyRequest) => void;
    policiesModel: IPolicyModel[];
    policyId: string;
    isAdd: boolean;
    isActivation: boolean;
    isCopyPolicy: boolean;
    onCopySubmit?: (request: CopyTimeOffPolicyRequest) => void;
    usersForCopyPolicy?: UserDaysDto[];
}

export interface IPolicyModel {
    name: string;
    id: string;
}

export interface IPolicyProps {
    id?: string;
    name?: string;
    startDate?: Date;
    endDate?: Date;
    isActive?: boolean;
    dependsOnPolicy?: string;
    allowNegativeBalance?: boolean;
    requiresApproval?: boolean;
    infiniteDays?: boolean;
    color?: string;
}

export const colors: ColorType[] = ['Undefined', 'Purple', 'LightGreen', 'LightBlue', 'Teal', 'Indigo', 'Lime'];