import React from 'react';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddIcon from '@mui/icons-material/Add';
import UserMonthlySummary from '../UserMonthlySummary/UserMonthlySummary';
import CreateEditWorkOrder from '../CreateEditWorkOrder/CreateEditWorkOrder';
import PermissionContext from '../../Permission/PermissionContext';
import dayjs from 'dayjs';
import { Box, Typography, Button, Tooltip, AvatarGroup, Avatar } from '@mui/material';
import { BORDER_PRIMARY_COLOR, LIGHT_BG_COLOR, LINE_WIDTH, PRIMARY_BG_COLOR, SPACING_EXTRA_SMALL, SPACING_SMALL } from '../../../utils/cssUtils';
import { useTranslation } from 'react-i18next';
import { TranslationKeyEnum } from '../../../features/translations/TranslationKeyEnum';
import { SPACING_MEDIUM } from '../../../utils/cssUtils';
import { StyledDataGrid } from '../../../components/StyledDataGrid/StyledDataGrid';
import { LanguageEnum } from '../../../features/translations/LanguageEnum';
import { srRS } from '../../../features/translations/DataGrid/srRS';
import { GridColDef } from '@mui/x-data-grid';
import { getTextTransformationAccordingToLanguage } from '../../../features/translations/helpers';
import { useGetTypesOfWork, useGetWorkOrders } from '../../../API/work-order-actions';
import { TypeOfWorkDto, UserWorkOrderDto, WorkOrderDto } from '../../../API/work-order-service';
import { PermissionsEnum } from '../../Permission/PermisionEnum';
import { workOrdersStatusSwitch } from '../common';

export default function ManageWorkOrders() {
    const { t, i18n } = useTranslation();
    const getWorkOrders = useGetWorkOrders();
    const getTypesOfWork = useGetTypesOfWork();
    const { isAllowed } = React.useContext(PermissionContext);

    const [rows, setRows] = React.useState<any[]>([]);
    const [workOrders, setWorkOrders] = React.useState<WorkOrderDto[]>([]);
    const [typesOfWork, setTypesOfWork] = React.useState<TypeOfWorkDto[]>([]);
    const [selectedWorkOrderId, setSelectedWorkOrderId] = React.useState<string>("");
    const [isUserMonthlySummaryOpen, setIsUserMonthlySummaryOpen] = React.useState<boolean>(false);
    const [isCreateWorkOrderOpen, setIsCreateWorkOrderOpen] = React.useState<boolean>(false);
    const [isWorkOrdersDataLoading, setIsWorkOrdersDataLoading] = React.useState<boolean>(true);
    const [rerender, setRerender] = React.useState<boolean>(false);
    const [isUserSupervisor, setIsUserSupervisor] = React.useState<boolean>(false);

    React.useEffect(() => {
        const fetchData = async () => {
            Promise.all([getWorkOrders(), getTypesOfWork()]).then(values => {
                setWorkOrders(values[0]);
                setTypesOfWork(values[1]);
                setIsWorkOrdersDataLoading(false);
            })
        };
        fetchData();
    }, [rerender]);

    React.useEffect(() => {
        workOrders.length && mapManageWorkOrdersTableProps(workOrders);
    }, [workOrders])

    React.useEffect(() => {
        const checkPermsission = async () => {
            setIsUserSupervisor(await isAllowed(PermissionsEnum.ManageWorkOrderPermission))
        }
        checkPermsission();
    }, [])

    const mapManageWorkOrdersTableProps = (allWorkOrders: WorkOrderDto[]) => {
        setRows(allWorkOrders.map(workOrder => {
            return {
                id: workOrder.id,
                name: workOrder.name,
                startDate: workOrder.startDate,
                typeOfWork: typesOfWork && typesOfWork.find(type => type.id === workOrder.typeOfWorkId)?.name,
                locationCode: workOrder.locationCode,
                assignees: workOrder?.userWorkOrders?.filter(user => user.isAssigned),
                status: workOrder.status
            }
        }));
    }

    const getFullnameOfAssignees = (assignees: UserWorkOrderDto[]): JSX.Element =>
        <Box>
            {assignees.map((assignee: UserWorkOrderDto) => <Typography variant="body2"> {`${assignee?.user?.firstName} ${assignee?.user?.lastName}`}</Typography>)}
        </Box>

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Id' },
        { field: 'name', headerName: t(TranslationKeyEnum.name), description: t(TranslationKeyEnum.name), flex: .1, minWidth: 80, sortable: false },
        { field: 'startDate', headerName: t(TranslationKeyEnum.startDate), valueFormatter: params => dayjs(params.value).format("DD/MM/YYYY"), description: t(TranslationKeyEnum.date), flex: 1, minWidth: 100 },
        { field: 'typeOfWork', headerName: t(TranslationKeyEnum.typeOfWork), description: t(TranslationKeyEnum.typeOfWork), flex: 1, minWidth: 120 },
        { field: 'locationCode', headerName: t(TranslationKeyEnum.locationLabel), description: t(TranslationKeyEnum.locationLabel), flex: 1, minWidth: 130 },
        {
            field: 'assignees', headerName: t(TranslationKeyEnum.assignedTo), description: t(TranslationKeyEnum.assignedTo), flex: 1, disableColumnMenu: true, minWidth: 140,
            renderCell: (cellValues) => {
                return <Tooltip title={getFullnameOfAssignees(cellValues.value)}>
                    <AvatarGroup max={3} sx={{ cursor: "pointer", '& .MuiAvatar-root': { width: 30, height: 30 } }}>
                        {
                            cellValues.value.map((assignee: UserWorkOrderDto) => {
                                return <Avatar
                                    alt={`${assignee?.user?.firstName} ${assignee?.user?.lastName}`}
                                    src={assignee?.user?.image && `data:image/png;base64,${assignee?.user?.image}`}
                                    sx={{ width: 30, height: 30 }}
                                >
                                    {`${assignee?.user?.firstName?.charAt(0)}${assignee?.user?.lastName?.charAt(0)}`}
                                </Avatar>
                            })
                        }
                    </AvatarGroup>
                </Tooltip>
            }
        },
        {
            field: 'status', headerName: t(TranslationKeyEnum.status), description: t(TranslationKeyEnum.status), flex: 1, disableColumnMenu: true, minWidth: 140, renderCell: (cellValues) => {
                return workOrdersStatusSwitch(cellValues.value);
            }
        },
        {
            field: 'Button', align: "right", description: "", flex: .200, disableColumnMenu: true, sortable: false, minWidth: 200,
            renderHeader: () => <Button size="small" variant="contained" sx={{ position: "absolute", right: SPACING_SMALL, backgroundColor: LIGHT_BG_COLOR }} onClick={() => setIsCreateWorkOrderOpen(true)}>
                <Box display="flex" gap=".8vh" >
                    <AddIcon fontSize="small" />
                    <Typography variant="body2" sx={{ textTransform: getTextTransformationAccordingToLanguage() }}>
                        {t(TranslationKeyEnum.newWorkOrder)}
                    </Typography>
                </Box>
            </Button>,
            renderCell: (props) => {
                return (
                    <Button color="primary" size="small" sx={{ textTransform: getTextTransformationAccordingToLanguage() }} onClick={() => { setSelectedWorkOrderId(props.id.toString()); setIsCreateWorkOrderOpen(true); }}>
                        <Box display="flex" gap=".8vh">
                            <Typography variant="body2">{t(TranslationKeyEnum.viewMore)}</Typography>
                            <ChevronRightIcon fontSize="small" />
                        </Box>
                    </Button>

                );
            }
        }
    ];

    return (
        <Box>
            <Box display="flex" flexDirection="column" alignItems="center" gap={SPACING_SMALL} marginBottom={SPACING_MEDIUM}>
                <ContentPasteGoIcon fontSize="large" sx={{ color: LIGHT_BG_COLOR }} />
                <Typography variant="h5">{t(TranslationKeyEnum.manageWorkOrders)}</Typography>
                <Typography variant="body2">{t(TranslationKeyEnum.manageWorkOrdersLabel)}</Typography>
                <Button variant="outlined" size="small" sx={{ textTransform: getTextTransformationAccordingToLanguage() }} onClick={() => setIsUserMonthlySummaryOpen(true)}>
                    {t(TranslationKeyEnum.viewUserMonthlySummary)}
                </Button>
                <Box width={LINE_WIDTH} border={BORDER_PRIMARY_COLOR} />
            </Box>
            <StyledDataGrid
                columnVisibilityModel={{
                    id: false,
                }}
                localeText={i18n.language === LanguageEnum.Serbian ? srRS.components.MuiDataGrid.defaultProps.localeText : undefined}
                hideFooter
                columns={columns}
                rows={rows}
                disableRowSelectionOnClick
                sx={{ backgroundColor: PRIMARY_BG_COLOR, height: "64vh" }}
                slotProps={{
                    cell: {
                        onBlur: () => setSelectedWorkOrderId(""),
                    },
                }}
                loading={isWorkOrdersDataLoading}
            />
            {
                isUserMonthlySummaryOpen && <UserMonthlySummary isOpen={isUserMonthlySummaryOpen} setIsOpen={setIsUserMonthlySummaryOpen} />
            }
            {
                isCreateWorkOrderOpen && <CreateEditWorkOrder isOpen={isCreateWorkOrderOpen} setIsOpen={setIsCreateWorkOrderOpen} isSupervisor={isUserSupervisor} workOrderId={selectedWorkOrderId} rerender={rerender} setRerender={setRerender} />
            }
        </Box>
    )
}