import React, { ChangeEvent } from 'react';
import DropdownErrorText from '../DropdownErrorText/DropdownErrorText';
import AddIcon from '@mui/icons-material/Add';
import AlertDialog from '../Dialogs/AlertDialog/AlertDialog';
import PositionProps, { createMgmtUser, handleAddPosition, handleCurrentPositionSelect, handleImageUpload, handlePositionDateChange, handleRemovePosition, organizeEmployeesData, useGetAllApproverIds, useGetApprovers } from "../common/CreateEditUserForms/actions";
import CancelIcon from '@mui/icons-material/Cancel';
import Loader from '../Loader/Loader';
import CloseIcon from '@mui/icons-material/Close';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import DescriptionIcon from '@mui/icons-material/Description';
import Switch from '@mui/material/Switch';
import ClearIcon from '@mui/icons-material/Clear';
import PermissionContext from '../../containers/Permission/PermissionContext';
import dayjs from 'dayjs';
import { Box, Dialog, Button, MenuItem, FormControl, InputLabel, FormControlLabel, OutlinedInput, Chip, Tooltip, Avatar, Checkbox, Typography, ListItemText, ClickAwayListener, TextFieldProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TranslationKeyEnum } from '../../features/translations/TranslationKeyEnum';
import { FieldArray, FormikValues, useFormik } from 'formik';
import { ERROR_MESSAGE as ERROR_MESSAGE_COLOR } from '../../styles/colors';
import { MenuProps } from '../CreateUserForm/PermissionsForm/permissionFormManuProps';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { OfficeResponse, PositionDto, PositionResponse, UpdateUserRequest, UserDocumentDto, UserResponse } from '../../API/user-management-service';
import { IApproverProps } from '../common/CreateEditUserForms/IApproverProps';
import { useEditUserFormikForm } from './editUserFormikForm';
import { useUpdateMgmtUser } from '../../API/user-actions';
import { useGetOffices } from '../../API/office-actions';
import { PolicyDto, StatusEnum } from '../../API/identity-service';
import { getPermissionsIds, useGetPermissions, useUpdateUserIdentity } from '../../API/identity-actions';
import { handleDatesChange, isDateYearOne } from '../../utils/dateUtils';
import { useUpdateTimeOffUser } from '../../API/time-off-actions';
import { useGetPositions } from '../../API/position-actions';
import { IEditFormProps } from './IEditFormProps';
import { IUpdateIdentityUserProps } from './IUpdateIdentityUserProps';
import { IUpdateTimeOffUserProps } from './IUpdateTimeOffUserProps';
import { addDays } from '../common/Calendar/helpers';
import { ADMIN_CONSOLE_INPUT_LABEL_WIDTH, ICON_COLOR, ICON_SIZE, SPACING_EXTRA_SMALL, SPACING_MEDIUM, SPACING_SMALL } from '../../utils/cssUtils';
import { StyledTextField } from '../StyledTextfield/StyledTextField';
import { uuidv4 } from '../../helpers/uuid';
import { StyledSelectField } from '../StyledSelect/StyledSelect';
import { FormHelperText } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { toBase64 } from '../../features/toBase64';
import { getDateOrNull } from '../../containers/TimeOff/helpers';
import { PermissionsEnum } from '../../containers/Permission/PermisionEnum';
import { useUpdateUserWorkOrder } from '../../API/work-order-actions';
import { BasicUpdateUserDto, IBasicUpdateUserDto } from '../../API/work-order-service';
import { BaseResponseModel, useErrorNotification, useSuccessNotificaiton } from '../../utils/fetchUtils'
import { getLanguage } from '../../features/translations/i18n';

// 2MB
const MAX_FILE_SIZE_IN_BYTES = 2097152;
const MIN_FILE_SIZE_IN_BYTES = 1;

function EditUserForm({ userId, open, onClose, rerender, setRerender, reinitializeEnabled, isForActivation }: IEditFormProps) {
  const { t } = useTranslation();
  const getApprovers = useGetApprovers();
  const getApproverIds = useGetAllApproverIds();
  const getOffices = useGetOffices();
  const getPermissions = useGetPermissions();
  const getPositions = useGetPositions();
  const updateMgmtUser = useUpdateMgmtUser(false);
  const updateUserIdentity = useUpdateUserIdentity();
  const updateTimeOffUser = useUpdateTimeOffUser(false);
  const updateWorkOrderUser = useUpdateUserWorkOrder(false);
  const { isAllowed } = React.useContext(PermissionContext);
  const showErrorNotification = useErrorNotification();
  const showSuccessNotification = useSuccessNotificaiton();
  const lang = getLanguage();

  const [permissions, setPermissions] = React.useState<PolicyDto[]>([]);
  const [offices, setOffices] = React.useState<OfficeResponse[]>([]);
  const [positions, setPositions] = React.useState<PositionResponse[]>([]);
  const [initialPositions, setInitialPosition] = React.useState<PositionProps[]>([]);
  const [employeeDropdownData, setEmployeeDropdownData] = React.useState<IApproverProps[]>([] as IApproverProps[]);
  const [isCurrentPositionUndefined, setIsCurrentPostionUndefined] = React.useState(false);
  const [isReinitializeEnabled, setIsReinitializeEnabled] = React.useState(reinitializeEnabled);
  const [isFormLoading, setIsFormLoading] = React.useState(true);
  const [isSelectDataLoading, setIsSelectDataLoading] = React.useState(true);
  const [isApproverDataLoading, setIsApproverDataLoading] = React.useState(true);
  const [isDateOfBirthOpen, setIsDateOfBirthOpen] = React.useState<boolean>(false);
  const [isPassportValidUntilOpen, setIsPassportValidUntilOpen] = React.useState<boolean>(false);
  const [isContractExpirationOpen, setIsContractExpirationOpen] = React.useState<boolean>(false);
  const [isIdCardValidUntilOpen, setIsIdCardValidUntilOpen] = React.useState<boolean>(false);
  const [isReligiousDayOpen, setIsReligiousDayOpen] = React.useState<boolean>(false);
  const [isDoctorAppointmentOpen, setIsDoctorAppointmentOpen] = React.useState<boolean>(false);
  const [isCertificateValidUntilOpen, setIsCertificateValidUntilOpen] = React.useState<boolean>(false);
  const [files, setFiles] = React.useState<File[]>([]);
  const [displayingFiles, setDisplayingFiles] = React.useState<UserDocumentDto[]>([]);
  const [filesToAdd, setFilesToAdd] = React.useState<UserDocumentDto[]>([]);
  const [filesToDelete, setFilesToDelete] = React.useState<string[]>([]);
  const [hasManageUsersPermission, setHasManageUsersPermission] = React.useState<boolean>(false);
  const [hasManageTimeOffPermission, setHasManageTimeOffPermission] = React.useState<boolean>(false);
  const [hasManageUserPermissionsPermission, setHasManageUserPermissionsPermission] = React.useState<boolean>(false);
  const [duplicatedFiles, setDuplicatedFiles] = React.useState<File[]>([]);

  const formikEditUser = useEditUserFormikForm(userId, setIsFormLoading, isForActivation, setInitialPosition);
  const formikEditUserForm: FormikValues = useFormik(formikEditUser(handleSubmit, isReinitializeEnabled));
  const { acceptedFiles, fileRejections, getRootProps } = useDropzone({
    maxSize: MAX_FILE_SIZE_IN_BYTES,
    minSize: MIN_FILE_SIZE_IN_BYTES,
    accept: {
      'image/jpeg': ['.jpeg', '.png', '.jpg', '.webp'],
      'application/pdf': ['.pdf', '.docs', '.docx', '.txt', '.xlsx', '.xml', '.csv']
    }
  });

  React.useEffect(() => {
    const choosedFiles = acceptedFiles.filter(accpetedFile =>
      !displayingFiles.some(displayedFile => accpetedFile.name === displayedFile.name)
    );
    const duplicatedAcceptedFiles = acceptedFiles.filter(accpetedFile =>
      displayingFiles.some(displayedFile => accpetedFile.name === displayedFile.name));

    let allFiles = [...files, ...choosedFiles];
    allFiles = getAndRemoveDuplicatesByProperty(allFiles, "name", duplicatedAcceptedFiles);
    setFiles(allFiles);

    let documents: UserDocumentDto[] = [];
    allFiles.map((file: File) => toBase64(file, (result: any) => {
      let userDocument = new UserDocumentDto()
      userDocument.base64 = result;
      userDocument.name = file.name;
      userDocument.id = result.id;
      documents.push(userDocument)
    }))
    setFilesToAdd(documents)
    formikEditUserForm.setFieldValue("filesToAdd", documents);
    setRerender(!rerender);
  }, [acceptedFiles])

  React.useEffect(() => {
    const fetchData = async () => {
      let allDocuments: UserDocumentDto[] = [];
      formikEditUserForm.values.files?.map((document: UserDocumentDto) => { allDocuments.push(new UserDocumentDto({ name: document.name, base64: document.base64, id: document.id })) });
      setDisplayingFiles(allDocuments);
      if (allDocuments.length)
        setIsReinitializeEnabled(false);
    }
    open && displayingFiles.length === 0 && fetchData();
  }, [formikEditUserForm.values.files.length])

  React.useEffect(() => {
    const fetchPermissions = async () => {
      setHasManageUsersPermission(await isAllowed(PermissionsEnum.ManageUsersPermission));
      setHasManageTimeOffPermission(await isAllowed(PermissionsEnum.ManageTimeOffPermission));
      setHasManageUserPermissionsPermission(await isAllowed(PermissionsEnum.ManageUserPermissionsPermission));
    }
    fetchPermissions();
  }, [])

  React.useEffect(() => {
    const fetchData = async () => {
      let approverIds = await getApproverIds();
      let approvers = await getApprovers(approverIds);
      return approvers && organizeEmployeesData(approvers, setEmployeeDropdownData, setIsApproverDataLoading);
    }
    hasManageTimeOffPermission && open && fetchData();
  }, [hasManageTimeOffPermission])

  React.useEffect(() => {
    const fetchData = async () => {
      Promise.all([getPermissions(), getOffices(), getPositions()]).then((values) => {
        setPermissions(values[0]);
        setOffices(values[1]);
        setPositions(values[2]);
        setIsSelectDataLoading(false);
      })
    }
    open && fetchData();
  }, [])

  React.useEffect(() => {
    !isFormLoading && !isApproverDataLoading && !!employeeDropdownData && setIsReinitializeEnabled(false)
  }, [isFormLoading, isApproverDataLoading, employeeDropdownData])

  async function handleSubmit() {
    return (formikEditUserForm.values.positions.some((p: PositionProps) => p.isCurrentPosition === true || isDateYearOne(p.endDate!)) === false)
      ? setIsCurrentPostionUndefined(true)
      : await handleUserUpdate();
  }

  // Removes duplicates from array by property name and set duplicates file value to local state
  const getAndRemoveDuplicatesByProperty = (allFiles: File[], property: keyof File, acceptedDuplicatedFiles: File[]) => {
    const uniqueFilesMap = new Map();
    const resultArray: File[] = [];
    const duplicatedItems: File[] = [];

    allFiles.forEach((item) => {
      if (uniqueFilesMap.has(item[property])) {
        duplicatedItems.push(item);
      } else {
        uniqueFilesMap.set(item[property], true);
        resultArray.push(item);
      }
    });

    let duplicatedFilesMergedArray = acceptedDuplicatedFiles.concat(duplicatedItems);
    setDuplicatedFiles(duplicatedFilesMergedArray ?? []);
    return resultArray;
  }

  const handleRemoveAddedDocument = (fileName: string) => {
    let updatedDocumentsForFields = files.filter(x => x.name !== fileName)
    setFiles(updatedDocumentsForFields)
    let updatedDocuments = filesToAdd.filter(x => x.name !== fileName);
    setFilesToAdd(updatedDocuments);
    formikEditUserForm.setFieldValue("filesToAdd", updatedDocuments);
    setRerender(!rerender);
  }

  const handleRemoveDocument = async (id: string): Promise<void> => {
    filesToDelete.push(id);
    formikEditUserForm.setFieldValue("filesToDelete", filesToDelete);
    let index = displayingFiles.findIndex(x => x.id === id);
    displayingFiles.splice(index, 1);
    setRerender(!rerender);
  }

  function checkForChangedUserIstances(mgmtUser: UpdateUserRequest, identityUser: IUpdateIdentityUserProps, timeOffUser: IUpdateTimeOffUserProps, workOrderUser: IBasicUpdateUserDto): Promise<BaseResponseModel>[] {
    let updateRequests: Promise<BaseResponseModel>[] = [];
    let permissionsIds = getPermissionsIds(formikEditUserForm.values.permissions, permissions);

    //if any of positions endDate is eqaul to 01/01/0001 we are setting it to null
    //when we send endDate that is equal to null to backend it is set to 01/01/0001 in the database
    //that is why we are changing endDate value to null insted of 01/01/0001
    //if we did not change endDate to null, even if user is not edited update API call will be called
    for (let position of formikEditUserForm.initialValues.positions as PositionProps[]) {
      if (isDateYearOne(position.endDate!)) {
        position.endDate = null;
        break;
      }
    }

    const initialMgmtUser = JSON.stringify(createMgmtUser(formikEditUserForm.initialValues));

    const initialIdentityUser = JSON.stringify({
      permissions: formikEditUserForm.initialValues.permissions,
      status: formikEditUserForm.initialValues.status
    });

    const initialTimeOffUser = JSON.stringify({
      approverId: formikEditUserForm.initialValues.approverId,
      yearsOfService: formikEditUserForm.initialValues.yearsOfService,
      status: formikEditUserForm.initialValues.status
    });

    const initialWorkOrderUser = JSON.stringify({
      id: formikEditUserForm.initialValues.id,
      status: formikEditUserForm.initialValues.status
    })

    handleCurrentPositionChange(mgmtUser);

    ((JSON.stringify(mgmtUser) !== initialMgmtUser) || (isForActivation)) && updateRequests.push(updateMgmtUser(mgmtUser));
    // if position that is already defined is changed we will not detect that change so we need this check
    if (!updateRequests.length) {
      let convertInitialPositionsToPositionDto: PositionDto[] = initialPositions.map((position: PositionProps) => {
        return new PositionDto({
          name: position.name, startDate: position.startDate, endDate: isDateYearOne(position.endDate!)
            ? undefined
            : position.endDate!
        })
      });
      ((JSON.stringify(mgmtUser.positions) !== JSON.stringify(convertInitialPositionsToPositionDto)) || (isForActivation)) && updateRequests.push(updateMgmtUser(mgmtUser));
    }
    ((JSON.stringify(workOrderUser) !== initialWorkOrderUser || isForActivation) && updateRequests.push(updateWorkOrderUser(workOrderUser as BasicUpdateUserDto)));
    ((JSON.stringify(identityUser) !== initialIdentityUser) || (isForActivation)) && updateRequests.push(updateUserIdentity(formikEditUserForm.initialValues.id, formikEditUserForm.values.status, permissionsIds));
    ((JSON.stringify(timeOffUser) !== initialTimeOffUser) || (isForActivation)) && updateRequests.push(updateTimeOffUser(
      {
        userId: formikEditUserForm.initialValues.id,
        firstName: formikEditUserForm.values.firstName,
        lastName: formikEditUserForm.values.lastName,
        status: formikEditUserForm.values.status
      } as UserResponse,
    formikEditUserForm.values.approverId,
    formikEditUserForm.values.yearsOfService));

    return updateRequests;
  }

  function handleCurrentPositionChange(user: UpdateUserRequest): UpdateUserRequest {
    for (let p of formikEditUserForm.values.positions) {
      if (p.isCurrentPosition || isDateYearOne(p.endDate)) {
        p.endDate = null;
        break;
      }
    }
    user.positions = formikEditUserForm.values.positions?.map((p: PositionDto) => { return new PositionDto({ name: p.name, startDate: p.startDate, endDate: p.endDate }) })
    return user;
  }

  async function handleUserUpdate() {
    const user = createMgmtUser(formikEditUserForm.values);
    const identityUser: IUpdateIdentityUserProps = {
      permissions: formikEditUserForm.values.permissions,
      status: formikEditUserForm.values.status
    };
    const timeOffUser: IUpdateTimeOffUserProps = {
      approverId: formikEditUserForm.values.approverId,
      yearsOfService: formikEditUserForm.values.yearsOfService,
      status: formikEditUserForm.values.status
    };

    const workOrderUser: IBasicUpdateUserDto = {
      id: formikEditUserForm.values.id,
      status: formikEditUserForm.values.status
    };

    Promise.all(checkForChangedUserIstances(user, identityUser, timeOffUser, workOrderUser)).then((results) => {
      const hasError = results.some((result) => !result.success);
      if (hasError) {
        var errorMsg = "";
        results.map(result => errorMsg += result.errors + "\n")
        showErrorNotification(errorMsg)
      }
      else {
        showSuccessNotification(t(TranslationKeyEnum.userUpdatedSuccessfully));
      }
      onClose();
      setRerender(!rerender);
    });
  }

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formikEditUserForm.setFieldValue("status", event.target.checked ? StatusEnum.Active : StatusEnum.AccessDisabled);
  };

  return (
    <Dialog data-test-id="dialog" onClose={onClose} open={open} sx={{ width: { xl: "50%", lg: "60%", md: "70%", sm: "80%", xs: "90%" }, margin: "0 auto" }}>
      <Box position="absolute" right="0" top={SPACING_SMALL} padding={SPACING_SMALL} sx={{ cursor: "pointer" }} onClick={() => onClose()}>
        <CloseIcon sx={{ color: ICON_COLOR }} />
      </Box>
      <Box padding={SPACING_MEDIUM}>
        <Typography variant="h5" marginBottom={SPACING_SMALL}>{t(TranslationKeyEnum.editUser)}</Typography>
        <form onSubmit={formikEditUserForm.handleSubmit} className="form">
          <Box display="flex" flexDirection="column" gap={SPACING_SMALL}>
            <StyledTextField
              fullWidth
              id="firstName"
              name="firstName"
              type="text"
              label={t(TranslationKeyEnum.firstName)}
              value={formikEditUserForm.values.firstName}
              onChange={formikEditUserForm.handleChange}
              error={formikEditUserForm.touched.firstName && Boolean(formikEditUserForm.errors.firstName)}
              helperText={formikEditUserForm.touched.firstName && formikEditUserForm.errors.firstName}
              disabled={isFormLoading || !hasManageUsersPermission}
              InputLabelProps={{ shrink: true }}
            />
            <StyledTextField
              fullWidth
              id="lastName"
              name="lastName"
              type="lastName"
              label={t(TranslationKeyEnum.lastName)}
              value={formikEditUserForm.values.lastName}
              onChange={formikEditUserForm.handleChange}
              error={formikEditUserForm.touched.lastName && Boolean(formikEditUserForm.errors.lastName)}
              helperText={formikEditUserForm.touched.lastName && formikEditUserForm.errors.lastName}
              disabled={isFormLoading || !hasManageUsersPermission}
              InputLabelProps={{ shrink: true }}
            />
            <StyledTextField
              fullWidth
              id="email"
              name="email"
              type="email"
              label={t(TranslationKeyEnum.email)}
              value={formikEditUserForm.values.email}
              disabled
              InputLabelProps={{ shrink: true }}
            />
            <FormControlLabel control={<Switch checked={formikEditUserForm.values.status === StatusEnum.Active} onChange={handleSwitchChange} />} label={t(TranslationKeyEnum.accessToApplication)} />
            {/* UPLOAD/REMOVE PHOTO SECTION */}
            <Box
              sx={{ marginTop: SPACING_EXTRA_SMALL }}
              display="flex"
              flexDirection="row"
              alignContent="center"
              gap={SPACING_EXTRA_SMALL}
            >
              <Box display="flex" gap={SPACING_SMALL} alignItems="center">
                <Avatar variant="rounded" src={formikEditUserForm.values.image && `data:image/png;base64,${formikEditUserForm.values.image}`} />
                <Button
                  variant="outlined"
                  component={formikEditUserForm.values.image === "" ? "label" : "button"}
                  size="small"
                  startIcon={formikEditUserForm.values.image !== "" ? <DeleteIcon /> : <UploadFileIcon />}
                  color={formikEditUserForm.values.image !== "" ? "error" : "primary"}
                  onClick={() => formikEditUserForm.values.image !== "" ? formikEditUserForm.setFieldValue("image", "") : {}}
                >
                  {formikEditUserForm.values.image !== "" ? t(TranslationKeyEnum.removeImage) : t(TranslationKeyEnum.uploadImage)}
                  <StyledTextField
                    id="image"
                    name="image"
                    title={t(TranslationKeyEnum.uploadImage)}
                    type="file"
                    hidden
                    label={t(TranslationKeyEnum.uploadImage)}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => e.target.files && handleImageUpload(formikEditUserForm, e)}
                    error={formikEditUserForm.touched.image && Boolean(formikEditUserForm.errors.image)}
                    helperText={formikEditUserForm.touched.image && formikEditUserForm.errors.image}
                    disabled={isFormLoading || !hasManageUsersPermission}
                  />
                </Button>
              </Box>
            </Box>
            {(formikEditUserForm.touched.image && Boolean(formikEditUserForm.errors.image)) && <FormHelperText sx={{ color: ERROR_MESSAGE_COLOR, marginTop: "-2%" }}>
              {formikEditUserForm.touched.image && formikEditUserForm.errors.image}
            </FormHelperText>}
            <Box display="flex" flexDirection={{ xl: "row", lg: "row", md: "row", sm: "column", xs: "column" }} gap={{ xl: SPACING_MEDIUM, lg: SPACING_MEDIUM, md: SPACING_MEDIUM, sm: "", xs: "" }}>
              <StyledTextField
                id="phone"
                name="phone"
                type="text"
                label={t(TranslationKeyEnum.phone)}
                value={formikEditUserForm.values.phone}
                onChange={formikEditUserForm.handleChange}
                error={formikEditUserForm.touched.phone && Boolean(formikEditUserForm.errors.phone)}
                helperText={formikEditUserForm.touched.phone && formikEditUserForm.errors.phone}
                margin="dense"
                disabled={isFormLoading || !hasManageUsersPermission}
                sx={{ width: { xl: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, lg: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, md: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, sm: "100%", xs: "100%" } }}
                InputLabelProps={{ shrink: true }}
              />
              <StyledTextField
                id="address"
                name="address"
                type="text"
                label={t(TranslationKeyEnum.address)}
                value={formikEditUserForm.values.address}
                onChange={formikEditUserForm.handleChange}
                error={formikEditUserForm.touched.address && Boolean(formikEditUserForm.errors.address)}
                helperText={formikEditUserForm.touched.address && formikEditUserForm.errors.address}
                margin="dense"
                disabled={isFormLoading || !hasManageUsersPermission}
                sx={{ width: { xl: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, lg: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, md: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, sm: "100%", xs: "100%" } }}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box display="flex" flexDirection={{ xl: "row", lg: "row", md: "row", sm: "column", xs: "column" }} gap={{ xl: SPACING_MEDIUM, lg: SPACING_MEDIUM, md: SPACING_MEDIUM, sm: SPACING_SMALL, xs: SPACING_SMALL }}>
              <FormControl data-test-id="officeDropdown" sx={{ width: { xl: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, lg: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, md: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, sm: "100%", xs: "100%" } }}>
                <InputLabel shrink sx={formikEditUserForm.errors.office && formikEditUserForm.touched.office && { color: ERROR_MESSAGE_COLOR }}>{t(TranslationKeyEnum.office)}</InputLabel>
                <StyledSelectField
                  id="office"
                  name="office"
                  label={t(TranslationKeyEnum.office)}
                  value={formikEditUserForm.values.office}
                  onChange={formikEditUserForm.handleChange}
                  error={formikEditUserForm.touched.office && Boolean(formikEditUserForm.errors.office)}
                  disabled={isFormLoading || !hasManageUsersPermission}
                  notched={true}
                  displayEmpty={true}
                  renderValue={() => formikEditUserForm.values.office ? formikEditUserForm.values.office : t(TranslationKeyEnum.selectOffice)}
                >
                  {
                    isSelectDataLoading ? <Box height={30}><Loader size={30} /></Box> : offices?.map((office: OfficeResponse) => <MenuItem value={office.name} key={office.officeId}>{office.name}</MenuItem>)
                  }
                </StyledSelectField>
                {formikEditUserForm.errors.office && formikEditUserForm.touched.office && <DropdownErrorText />}
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <ClickAwayListener onClickAway={() => setIsDateOfBirthOpen(false)}>
                  <Box data-test-id="birthdate" sx={{ width: { xl: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, lg: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, md: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, sm: "100%", xs: "100%" } }}>
                    <DatePicker
                      label={t(TranslationKeyEnum.dateOfBirth)}
                      value={isDateYearOne(new Date(formikEditUserForm.values.birthdate)) ? null : dayjs(isDateYearOne(new Date(formikEditUserForm.values.birthdate)) ? null : formikEditUserForm.values.birthdate)}
                      open={isDateOfBirthOpen}
                      onChange={(selectedDate) => { handleDatesChange(formikEditUserForm, getDateOrNull(selectedDate)!, "birthdate"); setIsDateOfBirthOpen(false); }}
                      slots={{ textField: StyledTextField as React.ElementType<TextFieldProps> }}
                      slotProps={{
                        textField: {
                          sx: { width: "100%", input: { cursor: "pointer" } },
                          autoComplete: "off",
                          onClick: () => setIsDateOfBirthOpen(!isDateOfBirthOpen),
                          error: formikEditUserForm.touched.birthdate && Boolean(formikEditUserForm.errors.birthdate),
                          helperText: formikEditUserForm.touched.birthdate && formikEditUserForm.errors.birthdate,
                        },
                      }}
                      format="DD/MM/YYYY"
                      disableFuture
                      disabled={isFormLoading || !hasManageUsersPermission}
                    />
                  </Box>
                </ClickAwayListener>
              </LocalizationProvider>
            </Box>
            <Box display="flex" flexDirection={{ xl: "row", lg: "row", md: "row", sm: "column", xs: "column" }} gap={{ xl: SPACING_MEDIUM, lg: SPACING_MEDIUM, md: SPACING_MEDIUM, sm: SPACING_SMALL, xs: SPACING_SMALL }}>
              <StyledTextField
                id="personalNo"
                name="personalNo"
                type="text"
                label={t(TranslationKeyEnum.personalNo)}
                value={formikEditUserForm.values.personalNo}
                onChange={formikEditUserForm.handleChange}
                error={formikEditUserForm.touched.personalNo && Boolean(formikEditUserForm.errors.personalNo)}
                helperText={formikEditUserForm.touched.personalNo && formikEditUserForm.errors.personalNo}
                disabled={isFormLoading || !hasManageUsersPermission}
                sx={{ width: { xl: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, lg: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, md: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, sm: "100%", xs: "100%" } }}
                InputLabelProps={{ shrink: true }}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <ClickAwayListener onClickAway={() => setIsContractExpirationOpen(false)}>
                  <Box id="contractExpiration" sx={{ width: { xl: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, lg: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, md: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, sm: "100%", xs: "100%" } }}>
                    <DatePicker
                      label={t(TranslationKeyEnum.contractExpiration)}
                      value={isDateYearOne(new Date(formikEditUserForm.values.contractExpiration)) ? null : dayjs(isDateYearOne(new Date(formikEditUserForm.values.contractExpiration)) ? null : formikEditUserForm.values.contractExpiration)}
                      open={isContractExpirationOpen}
                      onChange={(selectedDate) => { handleDatesChange(formikEditUserForm, getDateOrNull(selectedDate)!, "contractExpiration"); setIsContractExpirationOpen(false); }}
                      slots={{ textField: StyledTextField as React.ElementType<TextFieldProps> }}
                      slotProps={{
                        textField: {
                          sx: { width: "100%", input: { cursor: "pointer" } },
                          autoComplete: "off",
                          onClick: () => setIsContractExpirationOpen(!isContractExpirationOpen),
                          error: formikEditUserForm.touched.contractExpiration && Boolean(formikEditUserForm.errors.contractExpiration),
                          helperText: formikEditUserForm.touched.contractExpiration && formikEditUserForm.errors.contractExpiration,
                        },
                      }}
                      format="DD/MM/YYYY"
                      disabled={isFormLoading || !hasManageUsersPermission}
                    />
                  </Box>
                </ClickAwayListener>
              </LocalizationProvider>
            </Box>
            <Box display="flex" flexDirection={{ xl: "row", lg: "row", md: "row", sm: "column", xs: "column" }} gap={{ xl: SPACING_MEDIUM, lg: SPACING_MEDIUM, md: SPACING_MEDIUM, sm: "", xs: "" }}>
              <StyledTextField
                id="passportNumber"
                name="passportNumber"
                type="text"
                label={t(TranslationKeyEnum.passportNumber)}
                value={formikEditUserForm.values.passportNumber}
                onChange={formikEditUserForm.handleChange}
                error={formikEditUserForm.touched.passportNumber && Boolean(formikEditUserForm.errors.passportNumber)}
                helperText={formikEditUserForm.touched.passportNumber && formikEditUserForm.errors.passportNumber}
                disabled={isFormLoading || !hasManageUsersPermission}
                sx={{ width: { xl: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, lg: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, md: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, sm: "100%", xs: "100%" } }}
                InputLabelProps={{ shrink: true }}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <ClickAwayListener onClickAway={() => setIsPassportValidUntilOpen(false)}>
                  <Box id="passportValidUntil" sx={{ width: { xl: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, lg: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, md: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, sm: "100%", xs: "100%" } }}>
                    <DatePicker
                      label={t(TranslationKeyEnum.passportValidUntil)}
                      value={isDateYearOne(new Date(formikEditUserForm.values.passportValidUntil)) ? null : dayjs(isDateYearOne(new Date(formikEditUserForm.values.passportValidUntil)) ? null : formikEditUserForm.values.passportValidUntil)}
                      open={isPassportValidUntilOpen}
                      onChange={(selectedDate) => { handleDatesChange(formikEditUserForm, getDateOrNull(selectedDate)!, "passportValidUntil"); setIsPassportValidUntilOpen(false); }}
                      slots={{ textField: StyledTextField as React.ElementType<TextFieldProps> }}
                      slotProps={{
                        textField: {
                          sx: { width: "100%", input: { cursor: "pointer" } },
                          autoComplete: "off",
                          onClick: () => setIsPassportValidUntilOpen(!isPassportValidUntilOpen),
                          error: formikEditUserForm.touched.passportValidUntil && Boolean(formikEditUserForm.errors.passportValidUntil),
                          helperText: formikEditUserForm.touched.passportValidUntil && formikEditUserForm.errors.passportValidUntil,
                        },
                      }}
                      format="DD/MM/YYYY"
                      disabled={isFormLoading || !hasManageUsersPermission}
                    />
                  </Box>
                </ClickAwayListener>
              </LocalizationProvider>
            </Box>
            <Box display="flex" flexDirection={{ xl: "row", lg: "row", md: "row", sm: "column", xs: "column" }} gap={{ xl: SPACING_MEDIUM, lg: SPACING_MEDIUM, md: SPACING_MEDIUM, sm: "", xs: "" }}>
              <StyledTextField
                id="idCardNumber"
                name="idCardNumber"
                type="text"
                label={t(TranslationKeyEnum.idCardNumber)}
                value={formikEditUserForm.values.idCardNumber}
                onChange={formikEditUserForm.handleChange}
                error={formikEditUserForm.touched.idCardNumber && Boolean(formikEditUserForm.errors.idCardNumber)}
                helperText={formikEditUserForm.touched.idCardNumber && formikEditUserForm.errors.idCardNumber}
                disabled={isFormLoading || !hasManageUsersPermission}
                sx={{ width: { xl: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, lg: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, md: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, sm: "100%", xs: "100%" } }}
                InputLabelProps={{ shrink: true }}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <ClickAwayListener onClickAway={() => setIsIdCardValidUntilOpen(false)}>
                  <Box id="idCardValidUntil" sx={{ width: { xl: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, lg: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, md: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, sm: "100%", xs: "100%" } }}>
                    <DatePicker
                      label={t(TranslationKeyEnum.idCardValidUntil)}
                      value={isDateYearOne(new Date(formikEditUserForm.values.idCardValidUntil)) ? null : dayjs(isDateYearOne(new Date(formikEditUserForm.values.idCardValidUntil)) ? null : formikEditUserForm.values.idCardValidUntil)}
                      open={isIdCardValidUntilOpen}
                      onChange={(selectedDate) => { handleDatesChange(formikEditUserForm, getDateOrNull(selectedDate)!, "idCardValidUntil"); setIsIdCardValidUntilOpen(false); }}
                      slots={{ textField: StyledTextField as React.ElementType<TextFieldProps> }}
                      slotProps={{
                        textField: {
                          sx: { width: "100%", input: { cursor: "pointer" } },
                          autoComplete: "off",
                          onClick: () => setIsIdCardValidUntilOpen(!isIdCardValidUntilOpen),
                          error: formikEditUserForm.touched.idCardValidUntil && Boolean(formikEditUserForm.errors.idCardValidUntil),
                          helperText: formikEditUserForm.touched.idCardValidUntil && formikEditUserForm.errors.idCardValidUntil,
                        },
                      }}
                      format="DD/MM/YYYY"
                      disabled={isFormLoading || !hasManageUsersPermission}
                    />
                  </Box>
                </ClickAwayListener>
              </LocalizationProvider>
            </Box>
            <Box display="flex" flexDirection={{ xl: "row", lg: "row", md: "row", sm: "column", xs: "column" }} gap={{ xl: SPACING_MEDIUM, lg: SPACING_MEDIUM, md: SPACING_MEDIUM, sm: "", xs: "" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <ClickAwayListener onClickAway={() => setIsDoctorAppointmentOpen(false)}>
                  <Box id="doctorAppointment" sx={{ width: { xl: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, lg: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, md: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, sm: "100%", xs: "100%" } }}>
                    <DatePicker
                      label={t(TranslationKeyEnum.doctorAppointment)}
                      value={isDateYearOne(new Date(formikEditUserForm.values.doctorAppointment)) ? null : dayjs(isDateYearOne(new Date(formikEditUserForm.values.doctorAppointment)) ? null : formikEditUserForm.values.doctorAppointment)}
                      open={isDoctorAppointmentOpen}
                      onChange={(selectedDate) => { handleDatesChange(formikEditUserForm, getDateOrNull(selectedDate)!, "doctorAppointment"); setIsDoctorAppointmentOpen(false); }}
                      slots={{ textField: StyledTextField as React.ElementType<TextFieldProps> }}
                      slotProps={{
                        textField: {
                          sx: { width: "100%", input: { cursor: "pointer" } },
                          autoComplete: "off",
                          onClick: () => setIsDoctorAppointmentOpen(!isDoctorAppointmentOpen),
                          error: formikEditUserForm.touched.doctorAppointment && Boolean(formikEditUserForm.errors.doctorAppointment),
                          helperText: formikEditUserForm.touched.doctorAppointment && formikEditUserForm.errors.doctorAppointment,
                        },
                      }}
                      format="DD/MM/YYYY"
                      disabled={isFormLoading || !hasManageUsersPermission}
                    />
                  </Box>
                </ClickAwayListener>
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <ClickAwayListener onClickAway={() => setIsCertificateValidUntilOpen(false)}>
                  <Box id="certificateValidUntil" sx={{ width: { xl: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, lg: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, md: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, sm: "100%", xs: "100%" } }}>
                    <DatePicker
                      label={t(TranslationKeyEnum.certificateValidUntil)}
                      value={isDateYearOne(new Date(formikEditUserForm.values.certificateValidUntil)) ? null : dayjs(isDateYearOne(new Date(formikEditUserForm.values.certificateValidUntil)) ? null : formikEditUserForm.values.certificateValidUntil)}
                      open={isCertificateValidUntilOpen}
                      onChange={(selectedDate) => { handleDatesChange(formikEditUserForm, getDateOrNull(selectedDate)!, "certificateValidUntil"); setIsCertificateValidUntilOpen(false); }}
                      slots={{ textField: StyledTextField as React.ElementType<TextFieldProps> }}
                      slotProps={{
                        textField: {
                          sx: { width: "100%", input: { cursor: "pointer" } },
                          autoComplete: "off",
                          onClick: () => setIsCertificateValidUntilOpen(!isCertificateValidUntilOpen),
                          error: formikEditUserForm.touched.certificateValidUntil && Boolean(formikEditUserForm.errors.certificateValidUntil),
                          helperText: formikEditUserForm.touched.certificateValidUntil && formikEditUserForm.errors.certificateValidUntil,
                        },
                      }}
                      format="DD/MM/YYYY"
                      disabled={isFormLoading || !hasManageUsersPermission}
                    />
                  </Box>
                </ClickAwayListener>
              </LocalizationProvider>
            </Box>
            {/* //nova polja */}
            <Box display="flex" flexDirection={{ xl: "row", lg: "row", md: "row", sm: "column", xs: "column" }} gap={{ xl: SPACING_MEDIUM, lg: SPACING_MEDIUM, md: SPACING_MEDIUM, sm: "", xs: "" }}>
              <StyledTextField
                id="medicalInsuranceNumber"
                name="medicalInsuranceNumber"
                type="text"
                label={t(TranslationKeyEnum.medicalInsuranceNumber)}
                value={formikEditUserForm.values.medicalInsuranceNumber}
                onChange={formikEditUserForm.handleChange}
                error={formikEditUserForm.touched.medicalInsuranceNumber && Boolean(formikEditUserForm.errors.medicalInsuranceNumber)}
                helperText={formikEditUserForm.touched.medicalInsuranceNumber && formikEditUserForm.errors.medicalInsuranceNumber}
                disabled={isFormLoading || !hasManageUsersPermission}
                sx={{ width: { xl: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, lg: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, md: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, sm: "100%", xs: "100%" } }}
                InputLabelProps={{ shrink: true }}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <ClickAwayListener onClickAway={() => setIsReligiousDayOpen(false)}>
                  <Box id="religiousDay" sx={{ width: { xl: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, lg: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, md: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, sm: "100%", xs: "100%" } }}>
                    <DatePicker
                      label={t(TranslationKeyEnum.religiousDay)}
                      value={isDateYearOne(new Date(formikEditUserForm.values.religiousDay)) ? null : dayjs(isDateYearOne(new Date(formikEditUserForm.values.religiousDay)) ? null : formikEditUserForm.values.religiousDay)}
                      open={isReligiousDayOpen}
                      onChange={(selectedDate) => { handleDatesChange(formikEditUserForm, getDateOrNull(selectedDate)!, "religiousDay"); setIsReligiousDayOpen(false); }}
                      slots={{ textField: StyledTextField as React.ElementType<TextFieldProps> }}
                      slotProps={{
                        textField: {
                          sx: { width: "100%", input: { cursor: "pointer" } },
                          autoComplete: "off",
                          onClick: () => setIsReligiousDayOpen(!isReligiousDayOpen),
                          error: formikEditUserForm.touched.religiousDay && Boolean(formikEditUserForm.errors.religiousDay),
                          helperText: formikEditUserForm.touched.religiousDay && formikEditUserForm.errors.religiousDay,
                        },
                      }}
                      format="DD/MM/YYYY"
                      disabled={isFormLoading || !hasManageUsersPermission}
                    />
                  </Box>
                </ClickAwayListener>
              </LocalizationProvider>
            </Box>
            <Box border="1px dashed #c4c4c4" sx={{ pointerEvents: isFormLoading ? "none" : "" }}>
              <Box display="flex" flexDirection="column" gap=".5vh" justifyContent="center" alignItems="center" padding={SPACING_SMALL} {...getRootProps({ className: 'dropzone' })}>
                <DriveFolderUploadIcon />
                <Typography>{t(TranslationKeyEnum.dragAndDrop)}</Typography>
                <Typography variant="body2" color="gray">({t(TranslationKeyEnum.maxFileSize)})</Typography>
                <Typography variant="body2" color="gray">({t(TranslationKeyEnum.acceptedExtensions)})</Typography>
                <Button variant="contained" size="small" disabled={isFormLoading}>
                  {t(TranslationKeyEnum.upload)}
                </Button>
              </Box>
            </Box>
            <Box width="100%">
              {
                displayingFiles?.map(file =>
                  <Box display="flex" padding=".5vh" alignItems="center" gap={SPACING_EXTRA_SMALL}>
                    <DescriptionIcon />
                    <Tooltip title={file.name} placement="top-start">
                      <Typography width="95%" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" sx={{ cursor: "pointer" }}>{file.name}</Typography>
                    </Tooltip>
                    <Tooltip title={t(TranslationKeyEnum.remove)}>
                      <ClearIcon fontSize="small" sx={{ width: "5%", cursor: "pointer" }} onClick={() => handleRemoveDocument(file.id!)} />
                    </Tooltip>
                  </Box>
                )
              }
              {
                files?.map(file =>
                  <Box display="flex" padding=".5vh" alignItems="center" gap={SPACING_EXTRA_SMALL}>
                    <DescriptionIcon />
                    <Tooltip title={file.name} placement="top-start">
                      <Typography width="95%" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" sx={{ cursor: "pointer" }}>{file.name}</Typography>
                    </Tooltip>
                    <Tooltip title={t(TranslationKeyEnum.remove)}>
                      <ClearIcon fontSize="small" sx={{ width: "5%", cursor: "pointer" }} onClick={() => handleRemoveAddedDocument(file.name)} />
                    </Tooltip>
                  </Box>
                )
              }
            </Box>
            {
              fileRejections?.map(rejectedDocument => {
                return <Typography variant="body2" sx={{ color: ERROR_MESSAGE_COLOR, marginTop: "-1vh" }}>
                  *{t(TranslationKeyEnum.fileValidationMessage, { fileName: rejectedDocument.file.name })}
                </Typography>
              })
            }
            {
              duplicatedFiles?.map(duplicatedFile => {
                return <Typography variant="body2" sx={{ color: ERROR_MESSAGE_COLOR, marginTop: "-1vh" }}>
                  *{t(TranslationKeyEnum.duplicatefileValidationMessage, { fileName: duplicatedFile.name })}
                </Typography>
              })
            }
            <Typography variant="h5" marginTop={SPACING_SMALL}>{t(TranslationKeyEnum.positions)}</Typography>
            <FieldArray
              validateOnChange={false}
              name="positions"
              render={() => {
                return <>
                  {
                    formikEditUserForm.values.positions.map((position: PositionProps, index: number) => (
                      <Box key={uuidv4()}>
                        <Box display="flex" flexDirection={{ xl: "row", lg: "row", md: "row", sm: "column", xs: "column" }} gap={SPACING_EXTRA_SMALL}>
                          <FormControl data-test-id="positionNameDropdown" sx={{ width: { xl: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, lg: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, md: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, sm: "100%", xs: "100%" } }}>
                            <InputLabel
                              shrink
                              sx={formikEditUserForm.errors.positions
                                && formikEditUserForm.touched.positions
                                && formikEditUserForm.errors.positions[index]
                                && formikEditUserForm.errors.positions[index].name
                                && formikEditUserForm.touched.positions[index]
                                && formikEditUserForm.touched.positions[index].name
                                && { color: ERROR_MESSAGE_COLOR }} >
                              {t(TranslationKeyEnum.position)}
                            </InputLabel>
                            <StyledSelectField
                              id={formikEditUserForm.values.positions[index].name}
                              name={formikEditUserForm.values.positions[index].name}
                              label={t(TranslationKeyEnum.position)}
                              value={formikEditUserForm.values.positions[index].name !== "" ? formikEditUserForm.values.positions[index] : ""}
                              disabled={isFormLoading || !hasManageUsersPermission}
                              notched={true}
                              displayEmpty={true}
                              renderValue={() => formikEditUserForm.values.positions[index].name ? formikEditUserForm.values.positions[index].name : t(TranslationKeyEnum.selectPosition)}
                              onChange={(event: any) => formikEditUserForm.values.positions[index].name = event.target.value}
                              error={formikEditUserForm.errors.positions && formikEditUserForm.touched.positions && formikEditUserForm.errors.positions[index] && formikEditUserForm.errors.positions[index].name && formikEditUserForm.touched.positions[index] && formikEditUserForm.touched.positions[index].name}
                            >
                              {
                                isSelectDataLoading ? <Box height={30}><Loader size={30} /></Box> : positions?.map((p: PositionProps) => <MenuItem value={p.name} key={p.name}>{p.name}</MenuItem>)
                              }
                            </StyledSelectField>
                            {formikEditUserForm.errors.positions && formikEditUserForm.touched.positions && formikEditUserForm.errors.positions[index] && formikEditUserForm.errors.positions[index].name && formikEditUserForm.touched.positions[index] && formikEditUserForm.touched.positions[index].name && <DropdownErrorText marginLeft="10%" />}
                          </FormControl>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label={t(TranslationKeyEnum.startDate)}
                              value={dayjs(formikEditUserForm.values.positions[index].startDate)}
                              onChange={(selectedDate) => handlePositionDateChange(formikEditUserForm, getDateOrNull(selectedDate)!, index, "startDate", true, setIsReinitializeEnabled)}
                              slots={{ textField: StyledTextField as React.ElementType<TextFieldProps> }}
                              slotProps={{
                                textField: {
                                  sx: { width: { xl: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, lg: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, md: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, sm: "100%", xs: "100%" }, input: { cursor: "pointer" } },
                                  autoComplete: "off",
                                },
                              }}
                              format="DD/MM/YYYY"
                              disabled={isFormLoading || !hasManageUsersPermission}
                            />
                            <Box display="flex" flexDirection="column" width={{ xl: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, lg: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, md: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, sm: "100%", xs: "100%" }}>
                              <DatePicker
                                label={t(TranslationKeyEnum.endDate)}
                                value={dayjs(formikEditUserForm.values.positions[index].endDate) as unknown as Date}
                                onChange={(selectedDate) => handlePositionDateChange(formikEditUserForm, getDateOrNull(selectedDate)!, index, "endDate", true, setIsReinitializeEnabled)}
                                slots={{ textField: StyledTextField as React.ElementType<TextFieldProps> }}
                                slotProps={{
                                  textField: {
                                    sx: { input: { cursor: "pointer" } },
                                    autoComplete: "off",
                                    error: false
                                  },
                                }}
                                format="DD/MM/YYYY"
                                shouldDisableDate={(day: Date) => formikEditUserForm.values.positions[index].startDate > addDays(new Date(day), 1)}
                                disabled={isDateYearOne(position.endDate!) || !!position.isCurrentPosition || isFormLoading || !hasManageUsersPermission}
                              />
                              <FormControlLabel
                                disabled={isFormLoading || !hasManageUsersPermission}
                                control={<Checkbox size='small'
                                  checked={isDateYearOne(position.endDate!) || position.isCurrentPosition || false}
                                  onClick={(event) => handleCurrentPositionSelect(formikEditUserForm, event, index, true, setIsReinitializeEnabled)} />}
                                label={t(TranslationKeyEnum.currentPosition)}
                              />
                            </Box>
                            {
                              hasManageUsersPermission && !isFormLoading && formikEditUserForm.values.positions.length && index === 0 &&
                              <Tooltip title={t(TranslationKeyEnum.addPosition)}>
                                <AddIcon
                                  sx={{ cursor: "pointer", fontSize: ICON_SIZE, color: ICON_COLOR, margin: { xl: `${SPACING_EXTRA_SMALL} 0 0 0`, lg: `${SPACING_EXTRA_SMALL} 0 0 0`, md: `${SPACING_EXTRA_SMALL} 0 0 0`, sm: "0 auto", xs: "0 auto" } }}
                                  onClick={() => handleAddPosition(formikEditUserForm)}
                                />
                              </Tooltip>
                            }
                            {
                              hasManageUsersPermission && !isFormLoading && formikEditUserForm.values.positions.length > 1 && index !== 0 &&
                              <Tooltip title={t(TranslationKeyEnum.removePosition)}>
                                <CancelIcon
                                  sx={{ cursor: "pointer", fontSize: ICON_SIZE, color: ICON_COLOR, margin: { xl: `${SPACING_EXTRA_SMALL} 0 0 0`, lg: `${SPACING_EXTRA_SMALL} 0 0 0`, md: `${SPACING_EXTRA_SMALL} 0 0 0`, sm: "0 auto", xs: "0 auto" } }}
                                  onClick={() => handleRemovePosition(formikEditUserForm, index)}
                                />
                              </Tooltip>
                            }
                          </LocalizationProvider>
                        </Box>
                        {/* No position defined as current position */}
                        <AlertDialog dialogOpen={isCurrentPositionUndefined} setDialogOpen={setIsCurrentPostionUndefined} title={t(TranslationKeyEnum.noCurrentPositionDefinedLabel)} description={t(TranslationKeyEnum.noCurrentPositionDefinedDescription)} />
                      </Box>
                    ))
                  }
                </>;
              }}
            />
            <Typography variant="h5">{t(TranslationKeyEnum.permissions)}</Typography>
            <FormControl data-test-id="permissionDropdown" fullWidth margin="dense">
              <InputLabel shrink>{t(TranslationKeyEnum.selectEmployeePermissions)}</InputLabel>
              <StyledSelectField
                id="permissions"
                name="permissions"
                notched={true}
                displayEmpty={true}
                multiple
                value={formikEditUserForm.values.permissions}
                onChange={formikEditUserForm.handleChange}
                error={formikEditUserForm.touched.permissions && Boolean(formikEditUserForm.errors.permissions)}
                input={<OutlinedInput label={t(TranslationKeyEnum.selectEmployeePermissions)} />}
                disabled={isFormLoading || !hasManageUserPermissionsPermission}
                renderValue={(selected: any): React.ReactNode => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value: string) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {
                  isSelectDataLoading
                    ? <Box height={30}><Loader size={30} /></Box>
                    : permissions?.map((permission: PolicyDto) => (
                      <MenuItem key={permission.id} value={JSON.parse(permission.title!)[lang]}>
                        <Tooltip title={JSON.parse(permission.description!)[lang]} placement="top">
                          <Box display="flex" alignItems="center" width="100%">
                            <Checkbox checked={formikEditUserForm.values.permissions.indexOf(JSON.parse(permission.title!)[lang]) > -1} />
                            <ListItemText primary={JSON.parse(permission.title!)[lang]} />
                          </Box>
                        </Tooltip>
                      </MenuItem>
                    ))
                }
              </StyledSelectField>
            </FormControl>
            {formikEditUserForm.touched.permissions && formikEditUserForm.errors.permissions && <DropdownErrorText />}
            {isFormLoading && <Loader size={50} />}
            <Typography variant="h5">{t(TranslationKeyEnum.approver)}</Typography>
            <Box display="flex" flexDirection={{ xl: "row", lg: "row", md: "row", sm: "column", xs: "column" }} gap={{ xl: SPACING_MEDIUM, lg: SPACING_MEDIUM, md: SPACING_MEDIUM, sm: "", xs: "" }}>
              <FormControl data-test-id="approverDropdown" margin="dense" sx={{ width: { xl: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, lg: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, md: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, sm: "100%", xs: "100%" } }}>
                <InputLabel
                  shrink
                  sx={formikEditUserForm.errors.approverId
                    && formikEditUserForm.touched.approverId
                    && { color: ERROR_MESSAGE_COLOR }}
                >
                  {t(TranslationKeyEnum.selectApprover)}
                </InputLabel>
                <StyledSelectField
                  id="approverId"
                  name="approverId"
                  label={t(TranslationKeyEnum.selectApprover)}
                  value={formikEditUserForm.values.approverId}
                  onChange={formikEditUserForm.handleChange}
                  error={formikEditUserForm.touched.approverId && Boolean(formikEditUserForm.errors.approverId)}
                  disabled={isFormLoading || !hasManageTimeOffPermission}
                  notched={true}
                  displayEmpty={true}
                  renderValue={() =>
                    formikEditUserForm.values.approverId
                      ? employeeDropdownData.some((e: IApproverProps) => e.id === formikEditUserForm.values.approverId) ? employeeDropdownData.find((e: IApproverProps) => e.id === formikEditUserForm.values.approverId)!.fullname : t(TranslationKeyEnum.selectApprover)
                      : t(TranslationKeyEnum.selectApprover)
                  }
                >
                  {
                    isApproverDataLoading ? <Box height={30}><Loader size={30} /></Box> : employeeDropdownData.map((s: IApproverProps) => <MenuItem value={s.id} key={s.id}>{s.fullname}</MenuItem>)
                  }
                </StyledSelectField>
                {formikEditUserForm.errors.approverId && formikEditUserForm.touched.approverId && <DropdownErrorText />}
              </FormControl>
              <StyledTextField
                sx={{ width: { xl: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, lg: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, md: ADMIN_CONSOLE_INPUT_LABEL_WIDTH, sm: "100%", xs: "100%" } }}
                id="yearsOfService"
                name="yearsOfService"
                type="number"
                label={t(TranslationKeyEnum.yearsOfService)}
                value={formikEditUserForm.values.yearsOfService && formikEditUserForm.values.yearsOfService}
                onChange={formikEditUserForm.handleChange}
                error={formikEditUserForm.touched.yearsOfService && Boolean(formikEditUserForm.errors.yearsOfService)}
                helperText={formikEditUserForm.touched.yearsOfService && formikEditUserForm.errors.yearsOfService}
                margin="dense"
                disabled={isFormLoading || !hasManageTimeOffPermission}
                InputProps={{
                  inputProps: { min: 0 },
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'space-between' }}>
              <Button color="inherit" onClick={onClose}>
                {t(TranslationKeyEnum.cancel)}
              </Button>
              <Button data-test-id="submit" type="submit" variant="contained">{t(TranslationKeyEnum.submit)}</Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
}

export default EditUserForm;