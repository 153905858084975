import { useTranslation } from 'react-i18next';
import { FieldArray, FormikValues, useFormik } from 'formik'
import { InputLabel, Tooltip, Button, FormControl, Select, MenuItem, Avatar, TextField, Checkbox, FormControlLabel, Box, Typography, ClickAwayListener, TextFieldProps } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { TranslationKeyEnum } from '../../../features/translations/TranslationKeyEnum';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { IAdditionalInfoFormProps } from './IAdditionalInfoFormProps';
import { ERROR_MESSAGE } from '../../../styles/colors';
import { OfficeResponse, PositionResponse } from '../../../API/user-management-service';
import { useAdditionalInfoFormikForm } from './additionalInfoFormikForm';
import { useGetOffices } from '../../../API/office-actions';
import { useGetPositions } from '../../../API/position-actions';
import { useUpdateMgmtUser } from '../../../API/user-actions';
import { handleDatesChange } from '../../../utils/dateUtils';
import { addDays } from '../../common/Calendar/helpers';
import { ADMIN_CONSOLE_INPUT_LABEL_WIDTH, ICON_COLOR, ICON_SIZE, SPACING_EXTRA_SMALL, SPACING_MEDIUM, SPACING_SMALL } from '../../../utils/cssUtils';
import { StyledTextField } from '../../StyledTextfield/StyledTextField';
import { StyledSelectField } from '../../StyledSelect/StyledSelect';
import React, { ChangeEvent } from 'react';
import PositionProps, { createMgmtUser, handleAddPosition, handleCurrentPositionSelect, handleImageUpload, handlePositionDateChange, handleRemovePosition } from '../../common/CreateEditUserForms/actions';
import DropdownErrorText from '../../DropdownErrorText/DropdownErrorText';
import AddIcon from '@mui/icons-material/Add';
import StepperFormFooter from '../StepperFormFooter/FormFooter';
import CancelIcon from '@mui/icons-material/Cancel';
import AlertDialog from '../../Dialogs/AlertDialog/AlertDialog';
import Loader from '../../Loader/Loader';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { uuidv4 } from '../../../helpers/uuid';
import dayjs from 'dayjs';
import { getDateOrNull } from '../../../containers/TimeOff/helpers';

const ERROR_MESSAGE_COLOR = ERROR_MESSAGE;

function AdditionalInfoForm({ user, goToNextStep }: IAdditionalInfoFormProps) {
    const { t } = useTranslation();
    const getOffices = useGetOffices();
    const getPositions = useGetPositions();
    const updateEmployee = useUpdateMgmtUser(true);
    const formikAdditionalInfo = useAdditionalInfoFormikForm();
    const additionalInfoForm: FormikValues = useFormik(formikAdditionalInfo(handleSubmit));

    const [positions, setPositions] = React.useState<PositionResponse[]>([]);
    const [offices, setOffices] = React.useState<OfficeResponse[]>([]);
    const [isCurrentPostionUndefined, setIsCurrentPostionUndefined] = React.useState(false);
    const [isFormLoading, setIsFormLoading] = React.useState(false);
    const [isSelectDataLoading, setIsSelectDataLoading] = React.useState(true);

    const [isDateOfBirthDateOpen, setIsDateOfBirthDateOpen] = React.useState(false);

    React.useEffect(() => {
        const fetchData = async () => {
            Promise.all([getPositions(), getOffices()]).then((values) => {
                setPositions(values[0]);
                setOffices(values[1]);
                setIsSelectDataLoading(false);
            });
        }
        fetchData();
    }, [])

    async function handleSubmit() {
        if (additionalInfoForm.values.positions.some((p: PositionProps) => p.isCurrentPosition) === false) {
            return setIsCurrentPostionUndefined(true);
        }

        //Setting null to current position endDate
        additionalInfoForm.values.positions.filter((p: PositionProps) => p.isCurrentPosition)[0].endDate = null;
        additionalInfoForm.values.status = user.status;
        let employee = createMgmtUser(additionalInfoForm.values, user);

        setIsFormLoading(true);
        let update = await updateEmployee(employee);
        setIsFormLoading(false);
        update && goToNextStep();
    }

    return (
        <form onSubmit={additionalInfoForm.handleSubmit}>
            <Box display="flex" gap={SPACING_SMALL} margin={`${SPACING_MEDIUM} 0`}>
                <Avatar variant="rounded" src={additionalInfoForm.values.image && `data:image/png;base64,${additionalInfoForm.values.image}`} />
                <Button
                    variant='outlined'
                    size="small"
                    component="label"
                    startIcon={additionalInfoForm.values.image !== "" ? <DeleteIcon /> : <UploadFileIcon />}
                    color={additionalInfoForm.values.image !== "" ? "error" : "primary"}
                    onClick={() => additionalInfoForm.values.image !== "" ? additionalInfoForm.setFieldValue("image", "") : {}}>
                    <Box>{additionalInfoForm.values.image !== "" ? t(TranslationKeyEnum.removeImage) : t(TranslationKeyEnum.uploadImage)}</Box>
                    <TextField
                        name="image"
                        title={t(TranslationKeyEnum.uploadImage)}
                        type="file"
                        hidden
                        label={t(TranslationKeyEnum.uploadImage)}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => e.target.files && handleImageUpload(additionalInfoForm, e)}
                        error={additionalInfoForm.touched.image && Boolean(additionalInfoForm.errors.image)}
                        helperText={additionalInfoForm.touched.image && additionalInfoForm.errors.image}
                        margin="dense"
                        disabled={isFormLoading}
                    />
                </Button>
            </Box>
            <Box display="flex" justifyContent="space-between">
                <StyledTextField
                    id="phone"
                    name="phone"
                    type="text"
                    label={t(TranslationKeyEnum.phone)}
                    value={additionalInfoForm.values.phone}
                    onChange={additionalInfoForm.handleChange}
                    sx={{ width: ADMIN_CONSOLE_INPUT_LABEL_WIDTH }}
                    InputLabelProps={{ shrink: true }}
                    error={additionalInfoForm.touched.phone && Boolean(additionalInfoForm.errors.phone)}
                    helperText={additionalInfoForm.touched.phone && additionalInfoForm.errors.phone}
                    disabled={isFormLoading}
                />
                <StyledTextField
                    id="address"
                    name="address"
                    type="text"
                    label={t(TranslationKeyEnum.address)}
                    value={additionalInfoForm.values.address}
                    onChange={additionalInfoForm.handleChange}
                    sx={{ width: ADMIN_CONSOLE_INPUT_LABEL_WIDTH }}
                    InputLabelProps={{ shrink: true }}
                    error={additionalInfoForm.touched.address && Boolean(additionalInfoForm.errors.address)}
                    helperText={additionalInfoForm.touched.address && additionalInfoForm.errors.address}
                    disabled={isFormLoading}
                />
            </Box>
            <Box display="flex" justifyContent="space-between" margin={`${SPACING_SMALL} 0`}>
                <FormControl data-test-id="officeDropdown" sx={{ width: ADMIN_CONSOLE_INPUT_LABEL_WIDTH }}>
                    <InputLabel shrink sx={additionalInfoForm.errors.office && additionalInfoForm.touched.office && { color: ERROR_MESSAGE_COLOR }}>{t(TranslationKeyEnum.office)}</InputLabel>
                    <StyledSelectField
                        id="office"
                        name="office"
                        label={t(TranslationKeyEnum.office)}
                        value={additionalInfoForm.values.office}
                        onChange={additionalInfoForm.handleChange}
                        error={additionalInfoForm.touched.office && Boolean(additionalInfoForm.errors.office)}
                        disabled={isFormLoading}
                        notched={true}
                        displayEmpty={true}
                        renderValue={() =>
                            additionalInfoForm.values.office ? additionalInfoForm.values.office : t(TranslationKeyEnum.selectOffice)
                        }
                    >
                        {
                            isSelectDataLoading ? <Box height={30}><Loader size={30} /></Box> : offices?.map((office: OfficeResponse) => <MenuItem value={office.name} key={office.officeId}>{office.name}</MenuItem>)
                        }
                    </StyledSelectField>
                    {additionalInfoForm.errors.office && additionalInfoForm.touched.office && <DropdownErrorText />}
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <ClickAwayListener onClickAway={() => setIsDateOfBirthDateOpen(false)}>
                        <Box data-test-id="birthdate" width={ADMIN_CONSOLE_INPUT_LABEL_WIDTH}>
                            <DatePicker
                                label={t(TranslationKeyEnum.dateOfBirth)}
                                value={dayjs(additionalInfoForm.values.birthdate)}
                                onChange={(selectedDate) => { handleDatesChange(additionalInfoForm, getDateOrNull(selectedDate)!, "birthdate"); setIsDateOfBirthDateOpen(false) }}
                                open={isDateOfBirthDateOpen}
                                slots={{ textField: StyledTextField as React.ElementType<TextFieldProps> }}
                                slotProps={{
                                    textField: {
                                        sx: { width: "100%", input: { cursor: "pointer" } },
                                        error: additionalInfoForm.touched.birthdate && Boolean(additionalInfoForm.errors.birthdate),
                                        helperText: additionalInfoForm.touched.birthdate && additionalInfoForm.errors.birthdate,
                                        autoComplete: "off",
                                        onClick: () => setIsDateOfBirthDateOpen(!isDateOfBirthDateOpen)
                                    },
                                }}
                                format="DD/MM/YYYY"
                                disabled={isFormLoading}
                                disableFuture
                            />
                        </Box>
                    </ClickAwayListener>
                </LocalizationProvider>
            </Box>
            <Typography variant="h5">{t(TranslationKeyEnum.positions)}</Typography>
            <FieldArray
                validateOnChange={false}
                name="positions"
                render={() => {
                    return <>
                        {
                            additionalInfoForm.values.positions?.map((position: PositionProps, index: number) => (
                                <Box key={uuidv4()} margin={`${SPACING_SMALL} 0`}>
                                    <Box display="flex" gap={SPACING_EXTRA_SMALL}>
                                        <FormControl sx={{ width: "34%" }}>
                                            <InputLabel
                                                shrink
                                                sx={additionalInfoForm.errors.positions &&
                                                    additionalInfoForm.touched.positions &&
                                                    additionalInfoForm.errors.positions[index] &&
                                                    additionalInfoForm.errors.positions[index].name &&
                                                    additionalInfoForm.touched.positions[index] &&
                                                    additionalInfoForm.touched.positions[index].name &&
                                                    { color: ERROR_MESSAGE_COLOR }} >
                                                {t(TranslationKeyEnum.position)}
                                            </InputLabel>
                                            <Select
                                                id={additionalInfoForm.values.positions[index]}
                                                name="positionName"
                                                data-test-id="positionNameDropdown"
                                                label={t(TranslationKeyEnum.position)}
                                                value={additionalInfoForm.values.positions[index].name !== "" ? additionalInfoForm.values.positions[index] : ""}
                                                renderValue={() => additionalInfoForm.values.positions[index].name ? additionalInfoForm.values.positions[index].name : t(TranslationKeyEnum.selectPosition)}
                                                notched={true}
                                                displayEmpty={true}
                                                onChange={(event) => {
                                                    additionalInfoForm.values.positions[index].name = event.target.value;
                                                    additionalInfoForm.setFieldValue("positions", additionalInfoForm.values.positions);
                                                }}
                                                error={additionalInfoForm.errors.positions && additionalInfoForm.touched.positions && additionalInfoForm.errors.positions[index] && additionalInfoForm.errors.positions[index].name && additionalInfoForm.touched.positions[index] && additionalInfoForm.touched.positions[index].name}
                                                disabled={isFormLoading}
                                            >
                                                {
                                                    isSelectDataLoading ? <Box height={30}><Loader size={30} /></Box> : positions?.map((p: PositionProps) => <MenuItem value={p.name} key={p.name}>{p.name}</MenuItem>)
                                                }
                                            </Select>
                                            {additionalInfoForm.errors.positions && additionalInfoForm.touched.positions && additionalInfoForm.errors.positions[index] && additionalInfoForm.errors.positions[index].name && additionalInfoForm.touched.positions[index] && additionalInfoForm.touched.positions[index].name && <DropdownErrorText marginLeft="10%" />}
                                        </FormControl>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Box id="startDate" width="32%">
                                                <DatePicker
                                                    label={t(TranslationKeyEnum.startDate)}
                                                    value={dayjs(additionalInfoForm.values.positions[index].startDate)}
                                                    onChange={(selectedDate) => handlePositionDateChange(additionalInfoForm, getDateOrNull(selectedDate)!, index, "startDate")}
                                                    slots={{ textField: StyledTextField as React.ElementType<TextFieldProps> }}
                                                    slotProps={{
                                                        textField: {
                                                            sx: { input: { cursor: "pointer" } },
                                                            autoComplete: "off",
                                                        },
                                                    }}
                                                    format="DD/MM/YYYY"
                                                    disabled={isFormLoading}
                                                />
                                            </Box>
                                            <Box id="endDate" display="flex" flexDirection="column" width="32%">
                                                <DatePicker
                                                    label={t(TranslationKeyEnum.endDate)}
                                                    value={dayjs(additionalInfoForm.values.positions[index].endDate) as unknown as Date}
                                                    onChange={(selectedDate) => handlePositionDateChange(additionalInfoForm, selectedDate!, index, "endDate")}
                                                    slots={{ textField: StyledTextField as React.ElementType<TextFieldProps> }}
                                                    slotProps={{
                                                        textField: {
                                                            sx: { input: { cursor: "pointer" } },
                                                            autoComplete: "off",
                                                        },
                                                    }}
                                                    format="DD/MM/YYYY"
                                                    disabled={!!position.isCurrentPosition || isFormLoading}
                                                    shouldDisableDate={(day: Date) => additionalInfoForm.values.positions[index].startDate > addDays(new Date(day), 1)}
                                                />
                                                <FormControlLabel
                                                    control={<Checkbox size='small' data-test-id="currentPosition" disabled={isFormLoading} checked={!!position.isCurrentPosition} />}
                                                    label={t(TranslationKeyEnum.currentPosition)}
                                                    onClick={(event) => handleCurrentPositionSelect(additionalInfoForm, event, index, false)}
                                                />
                                            </Box>
                                        </LocalizationProvider>
                                        {
                                            additionalInfoForm.values.positions.length && index === 0 && isFormLoading === false &&
                                            <Tooltip title={t(TranslationKeyEnum.addPosition)}>
                                                <AddIcon sx={{ cursor: "pointer", fontSize: ICON_SIZE, marginTop: SPACING_EXTRA_SMALL, color: ICON_COLOR }} onClick={() => handleAddPosition(additionalInfoForm)} />
                                            </Tooltip>
                                        }
                                        {
                                            additionalInfoForm.values.positions.length && index !== 0 && isFormLoading === false &&
                                            <Tooltip title={t(TranslationKeyEnum.removePosition)}>
                                                <CancelIcon sx={{ cursor: "pointer", fontSize: ICON_SIZE, marginTop: SPACING_EXTRA_SMALL, color: ICON_COLOR }} onClick={() => handleRemovePosition(additionalInfoForm, index)} />
                                            </Tooltip>
                                        }
                                    </Box>
                                    {/* No position defined as current position */}
                                    {isCurrentPostionUndefined && <AlertDialog dialogOpen={isCurrentPostionUndefined} setDialogOpen={setIsCurrentPostionUndefined} title={t(TranslationKeyEnum.noCurrentPositionDefinedLabel)} description={t(TranslationKeyEnum.noCurrentPositionDefinedDescription)} />}
                                </Box>
                            ))
                        }
                    </>;
                }}
            />
            {isFormLoading && <Loader size={50} />}
            <StepperFormFooter isFormLoading={isFormLoading} secondButtonLabel={t(TranslationKeyEnum.next)} secondButtonIcon={<KeyboardArrowRight />} />
        </form>
    );
}

export default AdditionalInfoForm;