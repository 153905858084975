import { IApproverProps } from "../components/common/CreateEditUserForms/IApproverProps";
import { BaseResponseModel, useTryCatchJsonByAction } from "../utils/fetchUtils";
import { BasicTimeOffPolicyDto, CopyTimeOffPolicyRequest, CreateTimeOffPolicyRequest, DeactivateUserRequest, GroupClient, GroupDto, PolicyClient, RequestClient, RequestDto, ResolveBulkTimeOffRequests, ResolveTimeOffRequest, SingleUpdateUserPolicyDaysRequest, TimeOffSummaryDto, TimeOffSummaryFilter, UpdateTimeOffPolicyRequest, UserClient, UserDto, UserWithReservedDateRangesDto } from "./time-off-service";
import { ResponseMessage, UserResponse } from "./user-management-service";

const timeOffUserClient = new UserClient();
const timeOffPolicyClient = new PolicyClient();
const requestClient = new RequestClient();
const groupClient = new GroupClient();

export function useGetGroup(): (id: string) => Promise<GroupDto> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getGroup(id: string): Promise<RequestDto> {
        const bindedAction = getGroupAction.bind(null, id);
        let getGroupResponse = await tryCatchAction(bindedAction);
        return getGroupResponse.response;
    }

    return getGroup;
}

const getGroupAction = (id: string): Promise<GroupDto> => {
    return groupClient.get(id);
}

export function useGetGroupFromExternalView(): (id: string) => Promise<GroupDto> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getGroupFromExternalView(id: string): Promise<RequestDto> {
        const bindedAction = getGroupFromExternalViewAction.bind(null, id);
        let getGroupResponse = await tryCatchAction(bindedAction);
        return getGroupResponse.response;
    }

    return getGroupFromExternalView;
}

const getGroupFromExternalViewAction = (id: string): Promise<GroupDto> => {
    return groupClient.getFromExternalView(id);
}

export function useResolveRequest(): (request: ResolveTimeOffRequest) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();

    async function resolveRequest(request: ResolveTimeOffRequest): Promise<boolean> {
        const bindedResolveRequestAction = resolveRequestAction.bind(null, request);
        const result = await tryCatchAction(bindedResolveRequestAction);
        return result.success;
    }

    return resolveRequest;
}

const resolveRequestAction = (request: ResolveTimeOffRequest): Promise<ResponseMessage> => requestClient.resolveRequest(request);

export function useResolveRequests(): (request: ResolveBulkTimeOffRequests) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();

    async function resolveRequests(request: ResolveBulkTimeOffRequests): Promise<boolean> {
        const bindedResolveRequestsAction = resolveRequestsAction.bind(null, request);
        const result = await tryCatchAction(bindedResolveRequestsAction);
        return result.success;
    }

    return resolveRequests;

}
const resolveRequestsAction = (request: ResolveBulkTimeOffRequests): Promise<ResponseMessage> => requestClient.bulkResolve(request);

export function useGetRequest(): (id: string) => Promise<RequestDto> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getRequest(id: string): Promise<RequestDto> {
        const bindedAction = getRequestAction.bind(null, id);
        let getBankHolidaysReponse = await tryCatchAction(bindedAction);
        return getBankHolidaysReponse.response;
    }

    return getRequest;
}

const getRequestAction = (id: string): Promise<RequestDto> => {
    return requestClient.get(id);
}

export function useGetUsersApprovedRequests(): (numberOfRequests: number) => Promise<RequestDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getUsersApprovedRequests(numberOfRequests: number): Promise<RequestDto[]> {
        const bindedAction = getUserApprovedRequestAction.bind(null, numberOfRequests);
        let getBankHolidaysReponse = await tryCatchAction(bindedAction);
        return getBankHolidaysReponse.response;
    }

    return getUsersApprovedRequests;
}

const getUserApprovedRequestAction = (numberOfRequests: number): Promise<RequestDto[]> => {
    return requestClient.getApprovedUsersRequests(numberOfRequests);
}

export function useGetTimeOffUser(): (id: string) => Promise<UserDto> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getTimeOffUser(id: string): Promise<UserDto> {
        const bindedAction = getUserAction.bind(null, id);
        const getUserResponse = await tryCatchAction(bindedAction);
        return getUserResponse ? getUserResponse.response : [];
    }
    return getTimeOffUser;
}

const getUserAction = (id: string): Promise<UserDto> => {
    return timeOffUserClient.getById(id);
}

export function useUpdateTimeOffUser(showMessage: boolean = true): (user: UserResponse, approverId: IApproverProps, yearsOfService: number) => Promise<BaseResponseModel> {
    const tryCatchAction = useTryCatchJsonByAction(showMessage);
    async function handleUpdateTimeOffUser(user: UserResponse, approverId: IApproverProps, yearsOfService: number): Promise<BaseResponseModel> {
        const bindedAction = updateTimeOffUser.bind(null, user, approverId, yearsOfService);
        const updateApproverAndYearsOfWork = await tryCatchAction(bindedAction);
        return { success: updateApproverAndYearsOfWork.success, errors: updateApproverAndYearsOfWork.errors}
    }
    return handleUpdateTimeOffUser;
}

function updateTimeOffUser(user: UserResponse, approver: IApproverProps, yearsOfService: number): Promise<ResponseMessage> {
    let request: any = {
        id: user.userId,
        firstName: user.firstName,
        lastName: user.lastName,
        approverId: approver,
        yearsOfService: yearsOfService,
        status: user.status,
    };
    return timeOffUserClient.update(request);
}

export function useCreatePolicy(): (request: CreateTimeOffPolicyRequest) => Promise<ResponseMessage> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function createPolicy(request: CreateTimeOffPolicyRequest): Promise<ResponseMessage> {
        const bindedAction = createPolicyAction.bind(null, request);
        let createPolicyResponse = await tryCatchAction(bindedAction);
        return createPolicyResponse.response;
    }

    return createPolicy;
}

const createPolicyAction = (request: CreateTimeOffPolicyRequest): Promise<ResponseMessage> => {
    return timeOffPolicyClient.create(request)
}

export function useGetPolicy(): (policyId: string) => Promise<BasicTimeOffPolicyDto> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getPolicy(policyId: string): Promise<BasicTimeOffPolicyDto> {
        const bindedAction = getPolicyAction.bind(null, policyId);
        let getPolicyResponse = await tryCatchAction(bindedAction);
        return getPolicyResponse.response;
    }

    return getPolicy;
}

const getPolicyAction = (policyId: string): Promise<BasicTimeOffPolicyDto> => {
    return timeOffPolicyClient.getPolicyById(policyId)
}

export function useGetPolicyForActivation(): (policyId: string) => Promise<BasicTimeOffPolicyDto> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getPolicyForActivation(policyId: string): Promise<BasicTimeOffPolicyDto> {
        const bindedAction = getPolicyActionForActivation.bind(null, policyId);
        let getPolicyResponse = await tryCatchAction(bindedAction);
        return getPolicyResponse.response;
    }

    return getPolicyForActivation;
}

const getPolicyActionForActivation = (policyId: string): Promise<BasicTimeOffPolicyDto> => {
    return timeOffPolicyClient.getForActivation(policyId)
}

export function useGetUsersWithReservedDates(): (userIds: string[]) => Promise<UserWithReservedDateRangesDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getUsersWithReservedDates(userIds: string[]): Promise<UserWithReservedDateRangesDto[]> {
        const bindedAction = getUsersWithReservedDatesAction.bind(null, userIds);
        const getUsersResponse = await tryCatchAction(bindedAction);
        return getUsersResponse.success ? getUsersResponse.response : [];
    }
    return getUsersWithReservedDates;
}

const getUsersWithReservedDatesAction = (userIds: string[]): Promise<UserWithReservedDateRangesDto[]> => timeOffUserClient.getWithReservedDates(userIds);

export function useGetUsersWithReservedDatesFromExternalView(): (userIds: string[]) => Promise<UserWithReservedDateRangesDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getUsersWithReservedDatesFromExternalView(userIds: string[]): Promise<UserWithReservedDateRangesDto[]> {
        const bindedAction = getUsersWithReservedDatesFromExternalViewAction.bind(null, userIds);
        const getUsersResponse = await tryCatchAction(bindedAction);
        return getUsersResponse.success ? getUsersResponse.response : [];
    }
    return getUsersWithReservedDatesFromExternalView;
}

const getUsersWithReservedDatesFromExternalViewAction = (userIds: string[]): Promise<UserWithReservedDateRangesDto[]> => timeOffUserClient.getWithReservedDatesFromExternalView(userIds);

export function useUpdatePolicy(): (request: UpdateTimeOffPolicyRequest) => Promise<ResponseMessage> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function updatePolicy(request: UpdateTimeOffPolicyRequest): Promise<ResponseMessage> {
        const bindedAction = updatePolicyAction.bind(null, request);
        let createPolicyResponse = await tryCatchAction(bindedAction);
        return createPolicyResponse.response;
    }

    return updatePolicy;
}

const updatePolicyAction = (request: UpdateTimeOffPolicyRequest): Promise<ResponseMessage> => {
    return timeOffPolicyClient.update(request)
}

export function useUpdateUserPolicyDays(): (request: SingleUpdateUserPolicyDaysRequest) => Promise<ResponseMessage> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function updatePolicy(request: SingleUpdateUserPolicyDaysRequest): Promise<ResponseMessage> {
        const bindedAction = updateUserPolicyDaysAction.bind(null, request);
        let createPolicyResponse = await tryCatchAction(bindedAction);
        return createPolicyResponse.response;
    }

    return updatePolicy;
}

const updateUserPolicyDaysAction = (request: SingleUpdateUserPolicyDaysRequest): Promise<ResponseMessage> => timeOffPolicyClient.updateUserPolicyDays(request)

export function useDeactivateUser(): (id: string) => Promise<ResponseMessage> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function deactivateUser(id: string): Promise<ResponseMessage> {
        const bindedAction = deactivateUserAction.bind(null, id);
        const deactivateResponse = await tryCatchAction(bindedAction);
        return deactivateResponse.response;
    }
    return deactivateUser;
}

const deactivateUserAction = (id: string): Promise<ResponseMessage> => {
    return timeOffUserClient.deactivateUser(new DeactivateUserRequest({ id: id }));
}

export function useGetMonthlyTimeOffSummary(): (filter: TimeOffSummaryFilter) => Promise<TimeOffSummaryDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getMonthlyTimeOffSummary(filter: TimeOffSummaryFilter): Promise<TimeOffSummaryDto[]> {
        const bindedAction = getMonthlyTimeOffSummaryAction.bind(null, filter);
        const getMonthlyTimeOffSummaryResponse = await tryCatchAction(bindedAction);
        return getMonthlyTimeOffSummaryResponse.success ? getMonthlyTimeOffSummaryResponse.response : [];
    }

    return getMonthlyTimeOffSummary;
}

const getMonthlyTimeOffSummaryAction = (filter: TimeOffSummaryFilter): Promise<TimeOffSummaryDto[]> => requestClient.getTimeOffSummary(filter);

export function useGetPolicyNames(): () => Promise<string[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleGetTypesOfWork(): Promise<string[]> {
        const bindedAction = getPolicyNamesAction.bind(null);
        const getPolicyNamesActionResponse = await tryCatchAction(bindedAction);
        return getPolicyNamesActionResponse.success ? getPolicyNamesActionResponse.response : [];
    }
    return handleGetTypesOfWork;
}

const getPolicyNamesAction = (): Promise<string[]> => timeOffPolicyClient.getPolicyNames();

export function useCopyPolicy(): (request: CopyTimeOffPolicyRequest) => Promise<ResponseMessage> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function copyPolicy(request: CopyTimeOffPolicyRequest): Promise<ResponseMessage> {
        const bindedAction = copyPolicyAction.bind(null, request);
        let copyPolicyResponse = await tryCatchAction(bindedAction);
        return copyPolicyResponse.response;
    }

    return copyPolicy;
}

const copyPolicyAction = (request: CopyTimeOffPolicyRequest): Promise<ResponseMessage> => {
    return timeOffPolicyClient.copyPolicy(request)
}