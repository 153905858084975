import React from 'react';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import i18n from '../../features/translations/i18n';
import { useTranslation } from 'react-i18next'
import { IDeactivatedMeetingRoomsProps } from './IDeactivatedMeetingRoomsProps'
import { ROWS_PER_PAGE_OPTIONS } from '../common/Constants/PaginationConstants';
import { GridActionsCellItem, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { TranslationKeyEnum } from '../../features/translations/TranslationKeyEnum';
import { Tooltip, Box, Typography } from '@mui/material';
import { SPACING_SMALL } from '../../utils/cssUtils';
import { StyledDataGrid } from '../StyledDataGrid/StyledDataGrid';
import { LanguageEnum } from '../../features/translations/LanguageEnum';
import { srRS } from '../../features/translations/DataGrid/srRS';
import { RoomDto, StatusEnum, UpdateStatusRoomRequest } from '../../API/work-order-service';
import { useActivateMeetingRoom, useGetMeetingRoomsByStatus } from '../../API/work-order-actions';

export default function DeactivatedMeetingRooms({ rerenderMeetingRooms, setRerenderMeetingRooms }: IDeactivatedMeetingRoomsProps) {
    const { t } = useTranslation();
    const getDeactivatedMeetingRooms = useGetMeetingRoomsByStatus();
    const activateMeetingRoom = useActivateMeetingRoom();

    const [page, setPage] = React.useState<number>(0);
    const [pageSize, setPageSize] = React.useState<number>(ROWS_PER_PAGE_OPTIONS[0]);
    const [rows, setRows] = React.useState<RoomDto[]>([]);
    const [isDeactivatedMeetingRoomsDataLoading, setIsDeactivatedMeetingRoomsDataLoading] = React.useState<boolean>(true);
    const [rerender, setRerender] = React.useState<boolean>(false);

    const handlePaginationModelChange = (model: GridPaginationModel) => {
        setPage(model.page);
        setPageSize(model.pageSize);
    }

    React.useEffect(() => {
        const fetchData = async () => {
            let getMeetingRooms = await getDeactivatedMeetingRooms(StatusEnum.Inactive);
            if (!getMeetingRooms) return;
            setRows(getMeetingRooms);
            setIsDeactivatedMeetingRoomsDataLoading(false);
        }
        fetchData();
    }, [rerender, rerenderMeetingRooms])

    const handleActivateMeetingRoom = async (params: RoomDto) => {
        let activate = await activateMeetingRoom(new UpdateStatusRoomRequest({ id: params.id }));
        if (!activate) return;
        setRerender(!rerender);
        setRerenderMeetingRooms(!rerenderMeetingRooms);
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Id', width: 130 },
        { field: 'name', headerName: t(TranslationKeyEnum.name), width: 130, editable: true, flex: 1 },
        {
            field: 'actions', type: "actions", sortable: false, width: 80, minWidth: 80, flex: .3, align: "right", renderCell: (params: any) => {
                return <Box sx={{ display: 'flex', marginLeft: "auto", flexDirection: 'row', justifyContent: 'space-around' }}>
                    <Tooltip title={t(TranslationKeyEnum.activate)}><GridActionsCellItem icon={<RestartAltIcon />} label={t(TranslationKeyEnum.delete)} onClick={() => handleActivateMeetingRoom(params)} /></Tooltip>
                </Box>
            }
        }
    ];

    return (
        <Box marginTop={SPACING_SMALL}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">{t(TranslationKeyEnum.deactivatedMeetingRooms)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <StyledDataGrid
                        columnVisibilityModel={{
                            id: false,
                        }}
                        localeText={i18n.language === LanguageEnum.Serbian ? srRS.components.MuiDataGrid.defaultProps.localeText : undefined}
                        autoHeight={true}
                        rows={rows}
                        columns={columns}
                        paginationModel={{ page, pageSize }}
                        onPaginationModelChange={handlePaginationModelChange}
                        pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
                        pagination
                        hideFooterSelectedRowCount={true}
                        loading={isDeactivatedMeetingRoomsDataLoading}
                        disableRowSelectionOnClick
                    />
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}