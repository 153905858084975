import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TranslationKeyEnum } from '../../features/translations/TranslationKeyEnum';
import { LIGHT_BG_COLOR, SPACING_MEDIUM, SPACING_SMALL } from '../../utils/cssUtils';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { IForgotPasswordDialogProps } from './IForgotPasswordDialogProps';
import { useForgotPasswordAction } from './action';

export default function ForgotPasswordDialog(props: IForgotPasswordDialogProps) {
    const { t } = useTranslation();
    const { onClose, open } = props;
    const [email, setEmail] = React.useState<string>("");
    const forgotPassword = useForgotPasswordAction();

    const handlePasswordDialogClose = () => {
        onClose();
    };

    const handleForgotPasswordSubmit = () => {
        forgotPassword(email);
        handlePasswordDialogClose();
    };

    return (
        <Dialog open={open} onClose={handlePasswordDialogClose} fullWidth={true} maxWidth={'xs'}>
            <DialogTitle>{t(TranslationKeyEnum.forgotPasswordPlaceholder)}</DialogTitle>
            <DialogContent>
                <form onSubmit={handleForgotPasswordSubmit}>
                    <Box display="flex" flexDirection="column">
                    <TextField
                        id="standard-error-helper-text"
                        type="email"
                        fullWidth
                        autoFocus
                        margin="dense"
                        variant="standard"
                        label={t(TranslationKeyEnum.email)}
                        placeholder={t(TranslationKeyEnum.emailPlaceholder)}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        />
                    </Box>
                    <Button fullWidth type="submit" variant="contained" sx={{ backgroundColor: LIGHT_BG_COLOR, marginTop: SPACING_MEDIUM }}>
                        {t(TranslationKeyEnum.submit)}
                    </Button>
                    <Button fullWidth onClick={handlePasswordDialogClose} sx={{ color: LIGHT_BG_COLOR, marginTop: SPACING_SMALL }}>
                        {t(TranslationKeyEnum.cancel)}
                    </Button>
                </form>
            </DialogContent>
        </Dialog>
    )
}