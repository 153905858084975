import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { TranslationKeyEnum } from "../../../features/translations/TranslationKeyEnum";

const MINIMAL_INPUT_LENGTH = 2;

export function useUserMainInfoSchema() {
    const { t } = useTranslation();

    return Yup.object({
        email: Yup.string().email(t(TranslationKeyEnum.emailError)).required(t(TranslationKeyEnum.required)),
        firstName: Yup.string().min(MINIMAL_INPUT_LENGTH, t(TranslationKeyEnum.minimumInputLength, {fieldName: t(TranslationKeyEnum.firstName), minimumLength: MINIMAL_INPUT_LENGTH})).required(t(TranslationKeyEnum.required)),
        lastName: Yup.string().min(MINIMAL_INPUT_LENGTH, t(TranslationKeyEnum.minimumInputLength, {fieldName: t(TranslationKeyEnum.lastName), minimumLength: MINIMAL_INPUT_LENGTH})).required(t(TranslationKeyEnum.required)),
    })
}