import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import i18n from '../../features/translations/i18n';
import { GridActionsCellItem, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { LanguageEnum } from '../../features/translations/LanguageEnum';
import { ROWS_PER_PAGE_OPTIONS } from '../common/Constants/PaginationConstants';
import { srRS } from '../../features/translations/DataGrid/srRS';
import { TranslationKeyEnum } from '../../features/translations/TranslationKeyEnum';
import { useTranslation } from 'react-i18next';
import { IPositionTableProps } from './IPositionTableProps';
import { Tooltip, Box } from '@mui/material';
import { useGetDeactivatedPositions } from './actions';
import { SPACING_SMALL } from '../../utils/cssUtils';
import { StyledDataGrid } from '../StyledDataGrid/StyledDataGrid';

export default function DeactivatedPositions(props: { rerender: boolean }) {
    const [page, setPage] = React.useState<number>(0);
    const [pageSize, setPageSize] = React.useState<number>(ROWS_PER_PAGE_OPTIONS[0]);
    const { t } = useTranslation();
    const getDeactivatedPositions = useGetDeactivatedPositions();
    const [rows, setRows] = React.useState<IPositionTableProps[]>([]);
    const [isDeactivatedPositionsLoading, setIsDeactivatedPositionsLoading] = React.useState<boolean>(true);

    const handlePaginationModelChange = (model: GridPaginationModel) => {
        setPage(model.page);
        setPageSize(model.pageSize);
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Id', width: 130 },
        { field: 'name', headerName: t(TranslationKeyEnum.name), width: 130, editable: true, flex: 1.5 },
        {
            field: 'actions', type: "actions", sortable: false, width: 100, minWidth: 100, flex: .5, getActions: (params: any) => {
                return [
                    <Tooltip title={t(TranslationKeyEnum.activate)}><GridActionsCellItem icon={<RestartAltIcon />} label={t(TranslationKeyEnum.delete)} onClick={() => { }} /></Tooltip>
                ]
            }
        }
    ];

    React.useEffect(() => {
        const fetchData = async () => {
            let getUsers = await getDeactivatedPositions();
            setIsDeactivatedPositionsLoading(false);
            setRows(getUsers);
        }
        fetchData();
    }, [props.rerender]);

    return (
        <Box marginTop={SPACING_SMALL}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="h5">{t(TranslationKeyEnum.deactivatedPositions)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <StyledDataGrid
                        columnVisibilityModel={{
                            id: false,
                        }}
                        localeText={i18n.language === LanguageEnum.Serbian ? srRS.components.MuiDataGrid.defaultProps.localeText : undefined}
                        autoHeight={true}
                        rows={rows}
                        columns={columns}
                        paginationModel={{ page, pageSize }}
                        onPaginationModelChange={handlePaginationModelChange}
                        pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
                        pagination
                        hideFooterSelectedRowCount={true}
                        disableRowSelectionOnClick
                        loading={isDeactivatedPositionsLoading}
                    />
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}
