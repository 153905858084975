import { GridLocaleText } from "@mui/x-data-grid/models/api/gridLocaleTextApi";
import { getGridLocalization, Localization } from "@mui/x-data-grid/utils/getGridLocalization";
import { srRS as srRSCore } from '@mui/material/locale';

const GRID_DEFAULT_LOCALE_TEXT: GridLocaleText = {
    // Root
    noRowsLabel: 'Nema redova',
    noResultsOverlayLabel: 'No results found.',
    // Density selector toolbar button text
    toolbarDensity: 'Density',
    toolbarDensityLabel: 'Density',
    toolbarDensityCompact: 'Compact',
    toolbarDensityStandard: 'Standard',
    toolbarDensityComfortable: 'Comfortable',
    // Columns selector toolbar button text
    toolbarColumns: 'Kolone',
    toolbarColumnsLabel: 'Izaberi kolone',
    // Filters toolbar button text
    toolbarFilters: 'Filters',
    toolbarFiltersLabel: 'Show filters',
    toolbarFiltersTooltipHide: 'Hide filters',
    toolbarFiltersTooltipShow: 'Show filters',
    toolbarFiltersTooltipActive: count => count !== 1 ? `${count} active filters` : `${count} active filter`,
    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: 'Search…',
    toolbarQuickFilterLabel: 'Search',
    toolbarQuickFilterDeleteIconLabel: 'Očisti',
    // Export selector toolbar button text
    toolbarExport: 'Export',
    toolbarExportLabel: 'Export',
    toolbarExportCSV: 'Preuzmi kao CSV',
    toolbarExportPrint: 'Print',
    toolbarExportExcel: 'Download as Excel',
    // Columns panel text
    columnsPanelTextFieldLabel: 'Nađi kolonu',
    columnsPanelTextFieldPlaceholder: 'Naziv kolone',
    columnsPanelDragIconLabel: 'Reorder column',
    columnsPanelShowAllButton: 'Prikaži sve',
    columnsPanelHideAllButton: 'Sakrij sve',
    // Filter panel text
    filterPanelAddFilter: 'Add filter',
    filterPanelDeleteIconLabel: 'Izbriši',
    // TODO v6: rename to filterPanelOperator
    filterPanelOperatorAnd: 'And',
    filterPanelOperatorOr: 'Or',
    filterPanelColumns: 'Kolone',
    filterPanelInputLabel: 'vrednost',
    filterPanelInputPlaceholder: 'Vrednost filtera',
    // Filter operators text
    filterOperatorContains: 'sadrži',
    filterOperatorEquals: 'jednako',
    filterOperatorStartsWith: 'počinje sa',
    filterOperatorEndsWith: 'završava se sa',
    filterOperatorIs: 'is',
    filterOperatorNot: 'is not',
    filterOperatorAfter: 'is after',
    filterOperatorOnOrAfter: 'is on or after',
    filterOperatorBefore: 'is before',
    filterOperatorOnOrBefore: 'is on or before',
    filterOperatorIsEmpty: 'je prazno',
    filterOperatorIsNotEmpty: 'nije prazno',
    filterOperatorIsAnyOf: 'bilo koji od',
    // Filter values text
    filterValueAny: 'any',
    filterValueTrue: 'true',
    filterValueFalse: 'false',
    // Column menu text
    columnMenuLabel: 'Meni',
    columnMenuShowColumns: 'Prikaži kolone',
    columnMenuManageColumns: 'Upravljaj kolonama',
    columnMenuFilter: 'Filtriraj',
    columnMenuHideColumn: 'Sakrij',
    columnMenuUnsort: 'Poništi sortiranje',
    columnMenuSortAsc: 'Sortiraj po rastućem',
    columnMenuSortDesc: 'Sortiraj po opadajućem',
    // Column header text
    columnHeaderFiltersTooltipActive: count => count !== 1 ? `${count} aktivnih filtera` : `${count} aktivan filter`,
    columnHeaderFiltersLabel: 'Prikaži filtere',
    columnHeaderSortIconLabel: 'Sortiraj',
    // Rows selected footer text
    footerRowSelected: count => count !== 1 ? `${count.toLocaleString()} izabrano` : `${count.toLocaleString()} red izabran`,
    // Total row amount footer text
    footerTotalRows: 'Total Rows:',
    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount, totalCount) => `${visibleCount.toLocaleString()} of ${totalCount.toLocaleString()}`,
    // Checkbox selection text
    checkboxSelectionHeaderName: 'Checkbox selection',
    checkboxSelectionSelectAllRows: 'Select all rows',
    checkboxSelectionUnselectAllRows: 'Unselect all rows',
    checkboxSelectionSelectRow: 'Select row',
    checkboxSelectionUnselectRow: 'Unselect row',
    // Boolean cell text
    booleanCellTrueLabel: 'yes',
    booleanCellFalseLabel: 'no',
    // Actions cell more text
    actionsCellMore: 'more',
    // Column pinning text
    pinToLeft: 'Pin to left',
    pinToRight: 'Pin to right',
    unpin: 'Unpin',
    // Tree Data
    treeDataGroupingHeaderName: 'Group',
    treeDataExpand: 'see children',
    treeDataCollapse: 'hide children',
    // Grouping columns
    groupingColumnHeaderName: 'Group',
    groupColumn: name => `Group by ${name}`,
    unGroupColumn: name => `Stop grouping by ${name}`,
    // Master/detail
    detailPanelToggle: 'Detail panel toggle',
    expandDetailPanel: 'Expand',
    collapseDetailPanel: 'Collapse',
    // Used core components translation keys
    MuiTablePagination: {},
    // Row reordering text
    rowReorderingHeaderName: 'Row reordering',
    // Aggregation
    aggregationMenuItemHeader: 'Aggregation',
    aggregationFunctionLabelSum: 'sum',
    aggregationFunctionLabelAvg: 'avg',
    aggregationFunctionLabelMin: 'min',
    aggregationFunctionLabelMax: 'max',
    aggregationFunctionLabelSize: 'size',
    filterPanelRemoveAll: 'Ukloni sve',
    filterPanelLogicOperator: "",
    filterPanelOperator: 'Operator',
    "filterOperator=": "",
    "filterOperator!=": "",
    "filterOperator>": "",
    "filterOperator>=": "",
    "filterOperator<": "",
    "filterOperator<=": "",
    headerFilterOperatorContains: "",
    headerFilterOperatorEquals: "",
    headerFilterOperatorStartsWith: "",
    headerFilterOperatorEndsWith: "",
    headerFilterOperatorIs: "",
    headerFilterOperatorNot: "",
    headerFilterOperatorAfter: "",
    headerFilterOperatorOnOrAfter: "",
    headerFilterOperatorBefore: "",
    headerFilterOperatorOnOrBefore: "",
    headerFilterOperatorIsEmpty: "",
    headerFilterOperatorIsNotEmpty: "",
    headerFilterOperatorIsAnyOf: "",
    "headerFilterOperator=": "",
    "headerFilterOperator!=": "",
    "headerFilterOperator>": "",
    "headerFilterOperator>=": "",
    "headerFilterOperator<": "",
    "headerFilterOperator<=": ""
};

export const srRS: Localization = getGridLocalization(GRID_DEFAULT_LOCALE_TEXT, srRSCore);