import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import useDebounce from "../../helpers/hooks";
import { Dialog, Typography, Box, Button } from "@mui/material";
import { ICreateArticleDialogProps } from './ICreateArticleDialogProps';
import { StyledTextField } from '../StyledTextfield/StyledTextField';
import { useTranslation } from 'react-i18next';
import { TranslationKeyEnum } from '../../features/translations/TranslationKeyEnum';
import { ICON_COLOR, SPACING_EXTRA_SMALL, SPACING_SMALL } from '../../utils/cssUtils';
import { useArticleFormikForm } from './articleFormikForm';
import { FormikValues, useFormik } from 'formik';
import { useCreateArticle, useGetArticle, useUpdateArticle } from '../../API/newsFeedActions';
import { ArticleDto, CreateArticleRequest, UpdateArticleDto } from '../../API/user-management-service';
import { MAX_BODY_LENGTH } from './useArticleSchema';

export default function CreateEditArticleDialog({ articleId, isOpen, setIsOpen, rerender, setRerender }: ICreateArticleDialogProps) {
  const { t } = useTranslation();
  const createArticle = useCreateArticle();
  const updateArticle = useUpdateArticle();
  const getArticle = useGetArticle();
  
  const [article, setArticle] = React.useState<ArticleDto>();
  const [isArticleDataLoading, setIsArticleDataLoading] = React.useState<boolean>(false);
  
  const formikArticle = useArticleFormikForm();
  const articleForm: FormikValues = useFormik(formikArticle(() => handleSubmit(), article && article));
  const bodyDebouncedValue = useDebounce<string>(articleForm.values.body, 300);

  React.useEffect(() => {
    let fetchArticle = async () => {
      if (!articleId) return;
      setIsArticleDataLoading(true);
      let articleResponse = await getArticle(articleId);
      if (!articleResponse) return;
      setArticle(articleResponse);
      setIsArticleDataLoading(false);
    }
    fetchArticle();
  }, [articleId])

  async function handleSubmit() {
    let request = articleForm.values.id ? new UpdateArticleDto() : new CreateArticleRequest();
    if (request instanceof UpdateArticleDto) {
      request.id = articleForm.values.id;
    }
    request.title = articleForm.values.title;
    request.description = articleForm.values.description;
    request.body = articleForm.values.body;
    request instanceof UpdateArticleDto ? await updateArticle(request) : await createArticle(request)
    setRerender(!rerender);
    setIsOpen(false);
  }

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <Box padding={SPACING_SMALL} width="30vw">
        <Box position="absolute" right="0" top=".1vh" padding={SPACING_SMALL} sx={{ cursor: "pointer" }} onClick={() => setIsOpen(false)}>
          <CloseIcon sx={{ color: ICON_COLOR }} />
        </Box>
        <Typography variant="h5" marginBottom={SPACING_SMALL}>{articleId ? t(TranslationKeyEnum.editArticle) : t(TranslationKeyEnum.addArticle)}</Typography>
        <form onSubmit={articleForm.handleSubmit}>
          <Box display="flex" flexDirection="column" gap={SPACING_EXTRA_SMALL}>
            <StyledTextField
              id="title"
              name="title"
              type="text"
              label={t(TranslationKeyEnum.articleTitle)}
              value={articleForm.values.title}
              onChange={articleForm.handleChange}
              error={articleForm.touched.title && Boolean(articleForm.errors.title)}
              helperText={articleForm.touched.title && articleForm.errors.title}
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled={isArticleDataLoading}
            />
            <StyledTextField
              id="description"
              name="description"
              type="text"
              label={t(TranslationKeyEnum.description)}
              value={articleForm.values.description}
              onChange={articleForm.handleChange}
              error={articleForm.touched.description && Boolean(articleForm.errors.description)}
              helperText={articleForm.touched.description && articleForm.errors.description}
              InputLabelProps={{ shrink: true }}
              fullWidth
              multiline
              rows={2}
              disabled={isArticleDataLoading}
            />
            <StyledTextField
              id="body"
              name="body"
              type="text"
              label={t(TranslationKeyEnum.articleText)}
              value={articleForm.values.body}
              onChange={articleForm.handleChange}
              error={articleForm.touched.body && Boolean(articleForm.errors.body)}
              helperText={
                articleForm.touched.body && articleForm.errors.body
                  ? articleForm.errors.body
                  : `${bodyDebouncedValue.length ? bodyDebouncedValue.length : "0"}/${MAX_BODY_LENGTH}`
              }
              InputLabelProps={{ shrink: true }}
              fullWidth
              multiline
              rows={5}
              disabled={isArticleDataLoading}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: SPACING_EXTRA_SMALL }}>
            <Button color="inherit" onClick={() => setIsOpen(false)}>
              {t(TranslationKeyEnum.cancel)}
            </Button>
            <Button type="submit" variant="contained" disabled={isArticleDataLoading}>{articleId ? t(TranslationKeyEnum.save) : t(TranslationKeyEnum.submit)}</Button>
          </Box>
        </form>
      </Box>
    </Dialog>
  )
}