import { AuthenticateRequest, AuthenticateResponse, UserClient } from "../../API/identity-service";
import { useTryCatchJsonByAction } from "../../utils/fetchUtils";
import { getIncomingURLFromStorage, removeIncomingURLFromStorage, setUserToStorage } from "../../utils/storageUtils/storageUtils";
import { routeEnum } from "../../routes/routeEnum";
import { useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction } from "react";
import * as Sentry from "@sentry/react";

const loginAction = (loginRequest: AuthenticateRequest): Promise<AuthenticateResponse> => {
	const client = new UserClient();
	return client.authenticate(loginRequest);
}

export function useHandleLoginFormSubmit(setIsLoginFormLoading: Dispatch<SetStateAction<boolean>>): (credentials: AuthenticateRequest) => Promise<void> {
	const navigate = useNavigate();
	const tryCatchAction = useTryCatchJsonByAction();

	async function handleLogin(credentials: AuthenticateRequest) {
		setIsLoginFormLoading(true);
		const bindedAction = loginAction.bind(null, credentials);
		const loginResponse = await tryCatchAction(bindedAction);
		!loginResponse.success && setIsLoginFormLoading(false);
		const { id, email, jwtToken } = loginResponse?.response;
		if (!id || !email || !jwtToken) return;

		setUserToStorage(id, email, jwtToken);

		Sentry.setUser({
			id: id,
			email: email
		});

		setIsLoginFormLoading(false);

		let getIncomingURL = getIncomingURLFromStorage();
		removeIncomingURLFromStorage();
		getIncomingURL ? navigate(getIncomingURL) : navigate(`/${routeEnum.HOME}`)
	}
	return handleLogin;
}