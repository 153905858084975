import { srRS } from '../../features/translations/DataGrid/srRS';
import { LanguageEnum } from '../../features/translations/LanguageEnum';
import { GridEventListener, GridPaginationModel, GridRowModesModel, GridRowParams, MuiBaseEvent, MuiEvent } from '@mui/x-data-grid'
import { IEditableDataGridProps } from "./IEditableDataGridProps";
import { ROWS_PER_PAGE_OPTIONS } from '../common/Constants/PaginationConstants';
import { StyledDataGrid } from '../StyledDataGrid/StyledDataGrid';
import i18n from '../../features/translations/i18n'
import React from 'react';

function EditableDataGrid({ rows, columns, page, setPage, setRows, setId, rowModesModel, setRowModesModel, processRowUpdate, onProcessRowUpdateError, toolbar, isLoading }: IEditableDataGridProps) {
    const [pageSize, setPageSize] = React.useState(ROWS_PER_PAGE_OPTIONS[0]);

    const handlePaginationModelChange = (model: GridPaginationModel) => {
        setPage(model.page);
        setPageSize(model.pageSize);
    }

    const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => event.defaultMuiPrevented = true;

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params: GridRowParams, event: MuiEvent<MuiBaseEvent>) => event.defaultMuiPrevented = true;

    const rowModesModelChange = (newModel: GridRowModesModel) => setRowModesModel(newModel);

    return (
        <StyledDataGrid
            columnVisibilityModel={{
                id: false,
            }}
            localeText={i18n.language === LanguageEnum.Serbian ? srRS.components.MuiDataGrid.defaultProps.localeText : undefined}
            autoHeight={true}
            rows={rows}
            columns={columns}
            pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
            hideFooterSelectedRowCount={true}
            onCellClick={(selectedCell) => setId(selectedCell.id.toString())}
            paginationModel={{ page, pageSize }}
            onPaginationModelChange={handlePaginationModelChange}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={rowModesModelChange}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={onProcessRowUpdateError}
            slots={{ toolbar: toolbar }} // toolbar for add position button
            slotProps={{ toolbar: { setRows, setRowModesModel } }}
            disableRowSelectionOnClick
            loading={isLoading}
        />
    )
}

export default EditableDataGrid