import React from 'react';
import UpcomingWorkOrders from './UpcomingWorkOrders/UpcomingWorkOrders';
import MonthlySummary from './MonthlySummary/MonthlySummary';
import YearCalendar from '../../components/YearCalendar/YearCalendar';
import CreateEditWorkOrder from './CreateEditWorkOrder/CreateEditWorkOrder';
import PermissionContext from '../Permission/PermissionContext';
import { Grid, Typography, Avatar } from '@mui/material';
import { LIGHT_BORDER, SPACING_MEDIUM, SPACING_SMALL, PRIMARY_BG_COLOR, BORDER_RADIUS_MEDIUM, CARD_BOX_SHADOW, SPACING_EXTRA_SMALL } from '../../utils/cssUtils';
import { IUserProps } from '../../components/common/interfaces/IUserProps';
import { IYearCalendarEventProps } from '../../components/YearCalendar/IYearCalendarProps';
import { WorkOrderDto } from '../../API/work-order-service';
import { useGetMyWorkOrders } from '../../API/work-order-actions';
import { useTranslation } from 'react-i18next';
import { TranslationKeyEnum } from '../../features/translations/TranslationKeyEnum';
import { PermissionsEnum } from '../Permission/PermisionEnum';
import { dateRange } from '../../utils/dateUtils';

const WORK_ORDERS_COLOR: string = "#9C27B0";

interface IWorkOrderCalendarProps {
    id: string,
    locationCode: string,
    date: Date
}

export default function WorkOrders({ firstName, lastName, image }: IUserProps) {
    const { t } = useTranslation();
    const { isAllowed } = React.useContext(PermissionContext);
    const getMyWorkOrders = useGetMyWorkOrders();

    const [myWorkOrders, setMyWorkOrders] = React.useState<WorkOrderDto[]>([])
    const [workOrderId, setWorkOrderId] = React.useState<string>("");
    const [rerender, setRerender] = React.useState(false);
    const [isEditWorkOrderFormOpen, setIsEditWorkOrderFormOpen] = React.useState<boolean>(false);
    const [isSupervisor, setIsSupervisor] = React.useState<boolean>(false);

    React.useEffect(() => {
        let fetchData = async () => {
            Promise.all([getMyWorkOrders(), isAllowed(PermissionsEnum.ManageWorkOrderPermission)])
                .then((values) => {
                    setMyWorkOrders(values[0]);
                    setIsSupervisor(values[1]);
                })
        }
        fetchData();
    }, [rerender])

    const openWorkOrderForm = (id: string): void => {
        const workOrder = myWorkOrders.find(workOrder => workOrder.id === id);
        if (workOrder) {
            setWorkOrderId(workOrder.id!);
            setIsEditWorkOrderFormOpen(true);
        }
    };

    const mapWorkOrderCalendarObject = (workOrder: WorkOrderDto): IWorkOrderCalendarProps[] => {
        const dates = dateRange(workOrder?.startDate!, workOrder?.endDate!);
        return dates.map(date => {
            return {
                id: workOrder.id!,
                locationCode: workOrder.locationCode!,
                date: date
            }
        })
    }

    const getCalendarEvents = (): IYearCalendarEventProps[] => {
        let workOrdersArray: IYearCalendarEventProps[] = myWorkOrders
            .flatMap(workOrder => mapWorkOrderCalendarObject(workOrder))
            .map(event => {
                return {
                    [event.date.toLocaleDateString()]: {
                        label: t(TranslationKeyEnum.workOrdersTab),
                        color: WORK_ORDERS_COLOR,
                        clickEvent: () => openWorkOrderForm(event.id!),
                        tooltipText: event.locationCode
                    }
                }
            }
            )

        let workOrdersDictionary: IYearCalendarEventProps = {};
        for (let workOrder of workOrdersArray) {
            workOrdersDictionary = Object.assign(workOrdersDictionary, workOrder)
        }
        return [workOrdersDictionary];
    }

    return (
        <Grid container borderRadius={BORDER_RADIUS_MEDIUM} boxShadow={CARD_BOX_SHADOW} sx={{ backgroundColor: PRIMARY_BG_COLOR }}>
            {/* User Avatar & Fullname */}
            <Grid item lg={12} xl={12} md={12} sm={12} xs={12} borderBottom={LIGHT_BORDER} display="flex" position="relative" padding={SPACING_SMALL}>
                <Grid item margin="0 auto" xl={6} lg={6} md={6} sm={6} xs={6} display="flex" alignItems="center" justifyContent="center">
                    <Avatar sx={{ width: SPACING_MEDIUM, height: SPACING_MEDIUM }} variant="rounded" src={image && `data:image/png;base64,${image}`} alt="user image" />
                    <Typography variant="body1" marginLeft={SPACING_SMALL}>{firstName} {lastName}</Typography>
                </Grid>
            </Grid>
            {/* Upcoming Work Orders & Montlhy Summary */}
            <Grid item lg={6} xl={6} md={6} sm={12} xs={12} padding={`${SPACING_MEDIUM} ${SPACING_EXTRA_SMALL}`}>
                <UpcomingWorkOrders rerender={rerender} setRerender={setRerender} isForMe={true} />
                <MonthlySummary rerender={rerender} setRerender={setRerender} />
            </Grid>
            {/* Calendar */}
            <Grid item lg={6} xl={6} md={6} sm={12} xs={12} sx={{ backgroundColor: '#EEEEEE' }}>
                <YearCalendar calendarEvents={getCalendarEvents()} />
            </Grid>
            {
                isEditWorkOrderFormOpen &&
                <CreateEditWorkOrder
                    isOpen={isEditWorkOrderFormOpen}
                    setIsOpen={setIsEditWorkOrderFormOpen}
                    isSupervisor={isSupervisor}
                    workOrderId={workOrderId}
                    rerender={rerender} setRerender={setRerender} />
            }
        </Grid>
    )
}