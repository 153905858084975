import { createTheme } from '@mui/material/styles';
import { SCROLLER_BG_COLOR, SCROLLER_BORDER, SCROLLER_BORDER_RADIUS, SCROLLER_COLOR, SCROLLER_WIDTH } from '../utils/cssUtils';

export const defaultTheme = () => {
    const themeObject = {
        components: {
            MuiSvgIcon: {
                styleOverrides: {
                    root: {}
                }
            },
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        '*::-webkit-scrollbar': {
                            backgroundColor: SCROLLER_BG_COLOR,
                            width: SCROLLER_WIDTH,
                        },
                        '*::-webkit-scrollbar-track': {
                            backgroundColor: SCROLLER_BG_COLOR,
                        },
                        '*::-webkit-scrollbar-thumb': {
                            backgroundColor: SCROLLER_COLOR,
                            borderRadius: SCROLLER_BORDER_RADIUS,
                            border: SCROLLER_BORDER,
                        },
                        '*::webkit-scrollbar-button': {
                            display: "none",
                        }
                    }
                }
            },
            MuiGrid: {
                styleOverrides: {
                    root: {
                        '*::-webkit-scrollbar': {
                            backgroundColor: SCROLLER_BG_COLOR,
                            width: SCROLLER_WIDTH,
                        },
                        '*::-webkit-scrollbar-track': {
                            backgroundColor: SCROLLER_BG_COLOR,
                        },
                        '*::-webkit-scrollbar-thumb': {
                            backgroundColor: SCROLLER_COLOR,
                            borderRadius: SCROLLER_BORDER_RADIUS,
                            border: SCROLLER_BORDER,
                        },
                        '*::webkit-scrollbar-button': {
                            display: "none",
                        }
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        '*::-webkit-scrollbar': {
                            backgroundColor: SCROLLER_BG_COLOR,
                            width: SCROLLER_WIDTH,
                        },
                        '*::-webkit-scrollbar-track': {
                            backgroundColor: SCROLLER_BG_COLOR,
                        },
                        '*::-webkit-scrollbar-thumb': {
                            backgroundColor: SCROLLER_COLOR,
                            borderRadius: SCROLLER_BORDER_RADIUS,
                            border: SCROLLER_BORDER,
                        },
                        '*::webkit-scrollbar-button': {
                            display: "none",
                        }
                    }
                }
            },
            MuiDialog: {
                styleOverrides: {
                    root: {
                        '*::-webkit-scrollbar': {
                            backgroundColor: SCROLLER_BG_COLOR,
                            width: SCROLLER_WIDTH,
                        },
                        '*::-webkit-scrollbar-track': {
                            backgroundColor: SCROLLER_BG_COLOR,
                        },
                        '*::-webkit-scrollbar-thumb': {
                            backgroundColor: SCROLLER_COLOR,
                            borderRadius: SCROLLER_BORDER_RADIUS,
                            border: SCROLLER_BORDER,
                        },
                        '*::webkit-scrollbar-button': {
                            display: "none",
                        }
                    }
                }
            }
        }
    }
    return createTheme(themeObject);
}