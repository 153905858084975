import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { TranslationKeyEnum } from "../../features/translations/TranslationKeyEnum";
import { numbersRegEx } from "../common/CreateEditUserForms/numbersRegEx";
import { UserAddOrEditValidation } from "../common/Constants/UserAddOrEditValidationConstants";

const MINIMUM_INPUT_LENGTH = 2;
const MINIMUM_YEARS_OF_SERVICE_VALUE = 0;

export function useEditUserValidationSchema() {
    const { t } = useTranslation();

    return Yup.object({
        firstName: Yup.string().min(MINIMUM_INPUT_LENGTH, t(TranslationKeyEnum.minimumInputLength, { fieldName: t(TranslationKeyEnum.firstName), minimumLength: MINIMUM_INPUT_LENGTH })).required(t(TranslationKeyEnum.required)),
        lastName: Yup.string().min(MINIMUM_INPUT_LENGTH, t(TranslationKeyEnum.minimumInputLength, { fieldName: t(TranslationKeyEnum.lastName), minimumLength: MINIMUM_INPUT_LENGTH })).required(t(TranslationKeyEnum.required)),
        phone: Yup.string()
            .max(UserAddOrEditValidation.MAXIMUM_PHONE_LENGTH, t(TranslationKeyEnum.maximumInputLength, { fieldName: t(TranslationKeyEnum.phone), maximumLength: UserAddOrEditValidation.MAXIMUM_PHONE_LENGTH }))
            .min(UserAddOrEditValidation.MINIMUM_PHONE_LENGTH, t(TranslationKeyEnum.minimumInputLength, { fieldName: t(TranslationKeyEnum.phone), minimumLength: UserAddOrEditValidation.MINIMUM_PHONE_LENGTH }))
            .matches(numbersRegEx, t(TranslationKeyEnum.invalidPhoneNumber))
            .required(t(TranslationKeyEnum.required)),
        address: Yup.string()
            .max(UserAddOrEditValidation.MAXIMUM_ADDRESS_LENGTH, t(TranslationKeyEnum.maximumInputLength, { fieldName: t(TranslationKeyEnum.address), maximumLength: UserAddOrEditValidation.MAXIMUM_ADDRESS_LENGTH }))
            .min(UserAddOrEditValidation.MINIMUM_ADDRESS_LENGTH, t(TranslationKeyEnum.minimumInputLength, { fieldName: t(TranslationKeyEnum.address), minimumLength: UserAddOrEditValidation.MINIMUM_ADDRESS_LENGTH }))
            .required(t(TranslationKeyEnum.required)),
        office: Yup.string().required(t(TranslationKeyEnum.required)),
        positions: Yup.array().of(Yup.object().shape({
            name: Yup.string().required(t(TranslationKeyEnum.required)),
            startDate: Yup.date().required(t(TranslationKeyEnum.required)),
            endDate: Yup.date(),
        })),
        birthdate: Yup.date().max(new Date(), t(TranslationKeyEnum.invalidDateOfBirth)).required(t(TranslationKeyEnum.required)),
        approverId: Yup.string().required(t(TranslationKeyEnum.required)),
        yearsOfService: Yup.number()
            .positive()
            .min(MINIMUM_YEARS_OF_SERVICE_VALUE, t(TranslationKeyEnum.minimumInputValue, { fieldName: t(TranslationKeyEnum.yearsOfService), minimumLength: MINIMUM_YEARS_OF_SERVICE_VALUE }))
            .required(t(TranslationKeyEnum.required)),
        image: Yup.mixed()
            .test("FILE_FORMAT", t(TranslationKeyEnum.invalidImageFormat), (value, testContext) => {
                return !value || (value && UserAddOrEditValidation.SUPPORTED_FILE_FORMATS.includes(testContext.parent.formatOfImage));
            })
            .test("MAXIMUM_FILE_SIZE", t(TranslationKeyEnum.maximumImageSize), (value, testContext) => {
                return (!value || value && (testContext.parent.sizeOfImage) <= UserAddOrEditValidation.MAXIMUM_FILE_SIZE);
            }),
    })
}