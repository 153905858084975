import { FormikValues } from "formik";
import { getLanguageLocale } from "../features/translations/i18n";

export const dateOptions: Intl.DateTimeFormatOptions = {
  month: "2-digit",
  day: "2-digit"
};


export const handleDatesChange = (form: FormikValues, selectedDate: Date, property: string) => form.setFieldValue(property, selectedDate);

export const getMonthNameAndYear = (date: Date): string => {
  let month = date.toLocaleString(getLanguageLocale(), { month: 'long' });
  let year = date.getFullYear();
  return `${month} ${year}`;
}

export const compareDates = (date: Date, dateToCompare: string | Date): boolean => {
  let startDateWithRestartedTime = new Date(dateToCompare);
  //restarting hours, minutes and seconds so we can have clear comparison 
  startDateWithRestartedTime.setHours(0, 0, 0);
  return startDateWithRestartedTime > date
}

export const getDayAndMonthName = (date: Date): string => {
  let day = date.getDate();
  let month = date.toLocaleString(getLanguageLocale(), { month: 'long' });
  let monthWithFirstLetterUpperCase = month.charAt(0).toUpperCase() + month.slice(1);
  return `${day}. ${monthWithFirstLetterUpperCase}`;
}

export function isDateYearOne(date: Date): boolean {
  let selectedDate = new Date(date);
  return selectedDate && selectedDate.getFullYear() === 1;
}

export const getShortDateAndDayOfTheWeek = (date: Date): string => {
  let languageLocale = getLanguageLocale();
  let day = date.getDate();
  let month = date.toLocaleString(languageLocale, { month: 'long' });
  let dayOfTheWeek = date.toLocaleDateString(languageLocale, { weekday: 'long' });

  return `${day}. ${month} (${dayOfTheWeek})`;
}

/**
* Takes all dates between two dates
*/
export const dateRange = (startDate: Date, endDate: Date, increment = 1): Date[] => {
  const dateArray: Date[] = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    dateArray.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + increment);
  }

  return dateArray;
};