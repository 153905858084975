import React from 'react'
import FullCalendar from '@fullcalendar/react'
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css'; // needs additional webpack config!
import '../common/Calendar/calendar.css';
import { ICalendarEvent } from '../common/Calendar/ICalendarProps';
import { Box, InputLabel, MenuItem, Button, Typography, Dialog, FormControl, CircularProgress, TextFieldProps, Avatar } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { TranslationKeyEnum } from '../../features/translations/TranslationKeyEnum';
import { ICON_COLOR, LIGHT_BG_COLOR, SPACING_EXTRA_SMALL, SPACING_LARGE, SPACING_SMALL } from '../../utils/cssUtils';
import { StyledSelectField } from '../StyledSelect/StyledSelect';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { StyledTextField } from '../StyledTextfield/StyledTextField';
import { DateSelectArg, EventClickArg } from '@fullcalendar/core';
import { getDateOrNull } from '../../containers/TimeOff/helpers';
import { renderDaysSpanMonthAndYearAccordingToLanguage, renderWeekdayHeaderAccordingToLanguage, temporaryEventId } from '../common/Calendar/helpers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useGetCalendarEventsForRoom } from './actions';
import { useCreateReservation, useCreateReservationFromExternalView, useDeleteReservation, useDeleteReservationFromExternalView, useGetRoomsByIds, useGetRoomsByStatus, useUpdateReservation, useUpdateReservationFromExternalView } from '../../API/work-order-actions';
import { CreateReservationRequest, ReservationEventDto, RoomDto, StatusEnum, UpdateReservationRequest } from '../../API/work-order-service';
import { getTextTransformationAccordingToLanguage } from '../../features/translations/helpers';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeEnum } from '../../routes/routeEnum';
import { IMeetingCalendarProps } from './IMeetingCalendarProps';
import dayjs from 'dayjs';
import companyLogo from "../../assets/images/ant.png"
import { getRoomIdsFromStorage } from '../../utils/storageUtils/storageUtils';
import { compareDates } from '../../utils/dateUtils';
import useAutoReload from '../../helpers/useAutoReload';

export default function MeetingCalendar({ meetingId, roomId, resetMeetingIdValue }: IMeetingCalendarProps) {
    let calendarRef = React.createRef<FullCalendar>()
    let { pathname } = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isExternalView = pathname.slice(1) === routeEnum.MEETING_ROOMS_VIEW;
    const isRegularView = pathname.slice(1) === routeEnum.MEETING_ROOMS;
    const isMobile = pathname.slice(1) === routeEnum.MEETING_ROOMS_MOBILE;

    const [startDate, setStartDate] = React.useState<Date | null>(null);
    const [, setStartDateError] = React.useState<boolean>(false);
    const [endDate, setEndDate] = React.useState<Date | null>(null);
    const [, setEndDateError] = React.useState<boolean>(false);

    const getRoomsByStatus = useGetRoomsByStatus();
    const getRoomsByIds = useGetRoomsByIds();
    const [room, setRoom] = React.useState<RoomDto>(new RoomDto());
    const [rooms, setRooms] = React.useState<RoomDto[]>([]);
    const [isRoomDataLoading, setIsRoomDataLoading] = React.useState<boolean>(true);

    const getReservations = useGetCalendarEventsForRoom(isExternalView);
    const createReservation = useCreateReservation();
    const updateReservation = useUpdateReservation();
    const deleteReservation = useDeleteReservation();
    const createReservationFromExternalView = useCreateReservationFromExternalView();
    const updateReservationFromExternalView = useUpdateReservationFromExternalView();
    const deleteReservationFromExternalView = useDeleteReservationFromExternalView();

    const [events, setEvents] = React.useState<ICalendarEvent[]>([]);
    const [renderKey, setRenderKey] = React.useState<string>(events.length.toString());

    const [reservationId, setReservationId] = React.useState<string>("");
    const [title, setTitle] = React.useState<string>("");
    const [description, setDescription] = React.useState<string>("");
    const [organizer, setOrganizer] = React.useState<string>("");
    const [reservations, setResevations] = React.useState<ReservationEventDto[]>([]);
    const [openReservationModal, setOpenReservationModal] = React.useState(false);
    
    const handleOpenReservationModal = (selection: DateSelectArg) => {
        setOpenReservationModal(true);
        setStartDate(selection.start)
        setEndDate(selection.end)
    }
    const handleCloseReservationModal = () => {
        setOpenReservationModal(false);
        setReservationId('');
        setTitle('');
        setDescription('');
        setOrganizer('');
        meetingId && navigate(`/${routeEnum.CALENDAR}`);
        resetMeetingIdValue && resetMeetingIdValue();
    }

    const selectNextRoom = () => {
        const index = rooms.indexOf(room);
        setRoom(rooms[index < rooms.length - 1 ? index + 1 : 0])
    }
    const selectPreviousRoom = () => {
        const index = rooms.indexOf(room);
        setRoom(rooms[index > 0 ? index - 1 : rooms.length - 1])
    }

    const submitCreate = async () => {
        const request = new CreateReservationRequest({
            title: title,
            description: description,
            roomId: room.id,
            startTime: startDate!,
            endTime: endDate!
        });
        const success = isExternalView ? await createReservationFromExternalView(request) : await createReservation(request);
        if (success) {
            fetchReservations();
            handleCloseReservationModal();
        }
    }

    const submitUpdate = async () => {
        const entity = new UpdateReservationRequest({
            id: reservationId,
            title: title,
            description: description,
            roomId: room.id,
            startTime: startDate!,
            endTime: endDate!
        });
        entity.id = reservationId; // issue with nswag generated client
        const success = isExternalView ? await updateReservationFromExternalView(entity) : await updateReservation(entity);
        if (success) {
            fetchReservations();
            handleCloseReservationModal();
        }
    }

    const submitDelete = async () => {
        const success = isExternalView ? await deleteReservationFromExternalView(reservationId) : await deleteReservation(reservationId);
        if (success) {
            fetchReservations();
            handleCloseReservationModal();
        }
    }

    const handleStartDatePick = (newValue: Date | dayjs.Dayjs | null): void => {
        if (newValue?.toString() === "Invalid Date") return;
        newValue?.toString() !== "Invalid Date" && setStartDateError(false);
        if (!endDate || (endDate && getDateOrNull(newValue)! > endDate)) setEndDate(getDateOrNull(newValue));
        setStartDate(getDateOrNull(newValue));

        const newStartDate = getDateOrNull(newValue)
        if (endDate && newStartDate! > endDate) {
            setEndDateError(false);
            setEvents([...events.filter(e => e.id !== temporaryEventId)])
        }
    }

    const handleEndDatePick = (newValue: Date | dayjs.Dayjs | null): void => {
        if (newValue?.toString() === "Invalid Date") return;
        newValue?.toString() !== "Invalid Date" && setEndDateError(false);
        setEndDate(getDateOrNull(newValue));
    }

    const fetchReservations = async () => {
        const [calendarEvents, reservationList] = await getReservations(room.id!);
        setEvents(calendarEvents);
        setResevations(reservationList);
    }

    React.useEffect(() => {
        room && fetchReservations();
    }, [room])

    React.useEffect(() => {
        setRenderKey(Date.now().toString())
    }, [events])

    React.useEffect(() => {
        const fetchRooms = async () => {
            let fetchedRooms = isExternalView ? await getRoomsByIds(getRoomIdsFromStorage()) : await getRoomsByStatus(StatusEnum.Active);
            if (!fetchedRooms) return;
            setRooms(fetchedRooms);
            setRoom(roomId ? fetchedRooms.find(r => r.id === roomId)! : fetchedRooms[0])
            setIsRoomDataLoading(false);
        };
        fetchRooms();
    }, [])

    React.useEffect(() => {
        if (!meetingId || !reservations) return;
        handleOpenEditModal(meetingId);
    }, [meetingId, reservations])

    useAutoReload(isExternalView, 600000); // Reloads every 10 minutes if in external view

    const handleOpenEditModal = (id: string): void => {
        const reservation = reservations.find(r => r.id === id)
        setOpenReservationModal(true);
        setReservationId(id);
        setTitle(reservation?.title!);
        setDescription(reservation?.description!);
        setOrganizer(reservation?.organizer!);
        setStartDate(reservation?.startTime!)
        setEndDate(reservation?.endTime!)
    }

    const handleCreateMeetingRoom = (): void => {
        setOpenReservationModal(true);
        setStartDate(new Date())
        let addOneHourToEndDate = new Date();
        addOneHourToEndDate.setHours(addOneHourToEndDate.getHours() + 1)
        setEndDate(addOneHourToEndDate);
    }

    return <>
        <Box padding={isRegularView ? "24px" : ""}>
            <Box display="flex" alignItems="center" justifyContent={isMobile || isExternalView || isRegularView ? "space-evenly" : "flex-end"}
                marginBottom={SPACING_EXTRA_SMALL}
                marginTop={isExternalView || isRegularView ? SPACING_SMALL : ""}
                gap={SPACING_SMALL}>
                {isExternalView && <>
                    <Box display="flex">
                        <Avatar sx={{ marginRight: SPACING_SMALL, marginTop: "auto", marginBottom: "auto" }} src={companyLogo} />
                        <Typography variant="h6" margin="auto 0">{t(TranslationKeyEnum.companyName)}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="center" gap={SPACING_SMALL} width={{ xl: "30%", lg: "30%", md: "40%", sm: "50%", xs: "100%" }}>
                        <ChevronLeftIcon color="action" sx={{ cursor: "pointer" }} onClick={selectPreviousRoom} />
                        <Typography variant="h5" textTransform="capitalize" textAlign="center" width={{ xl: "12vw", lg: "16vw", md: "20vw", sm: "30vw", xs: "30ww" }}>
                            {room && room.name}
                        </Typography>
                        <ChevronRightIcon color="action" sx={{ cursor: "pointer" }} onClick={selectNextRoom} />
                    </Box>
                </>}
                {!isExternalView && <FormControl size="small" sx={{ width: { xl: "16vw", lg: "18vw", md: "20vw", sm: "30vw", xs: "40vw" } }}>
                    <InputLabel shrink>{t(TranslationKeyEnum.meetingRooms)}</InputLabel>
                    <StyledSelectField
                        id="room"
                        name="room"
                        label={t(TranslationKeyEnum.meetingRooms)}
                        value={room}
                        onChange={(event) => setRoom(rooms.find(room => room.id === event.target.value)!)}
                        notched={true}
                        displayEmpty={true}
                        renderValue={() => room?.name
                            ? rooms.find((g: RoomDto) => room.id === g.id)?.name
                            : <InputLabel sx={{ color: "#C8C8C8" }}>{t(TranslationKeyEnum.selectRoom)}</InputLabel>
                        }
                    >
                        {
                            isRoomDataLoading ? <Box display="flex" justifyContent="center"><CircularProgress /></Box> : rooms?.map((group: RoomDto) => <MenuItem value={group.id} key={group.id}>{group.name}</MenuItem>)
                        }
                    </StyledSelectField>
                </FormControl>}
                <Button startIcon={<AddIcon />} variant="contained" size="small" sx={{ backgroundColor: LIGHT_BG_COLOR, textTransform: getTextTransformationAccordingToLanguage() }} onClick={() => handleCreateMeetingRoom()}>
                    {t(TranslationKeyEnum.addMeeting)}
                </Button>
            </Box>
            <div className={isExternalView ? 'meetingRoomsCalendarExternalView' : 'meetingRoomsCalendarRegularView'}>
                <FullCalendar
                    ref={calendarRef}
                    key={renderKey}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrap5Plugin, listPlugin]}
                    dayHeaderContent={renderWeekdayHeaderAccordingToLanguage}
                    titleFormat={renderDaysSpanMonthAndYearAccordingToLanguage}   
                    themeSystem='bootstrap5'
                    initialView={isMobile ? 'listWeek' : 'timeGridWeek'}
                    aspectRatio={1.35}
                    headerToolbar={
                        { start: 'prev,next,title', center: '', end: 'filter' }
                    }
                    select={function (selection) {
                        handleOpenReservationModal(selection);
                    }}
                    eventClick={(arg: EventClickArg) => handleOpenEditModal(arg.event.id)}
                    selectable={true}
                    selectMirror
                    dayMaxEvents
                    weekends
                    weekNumberCalculation='ISO'
                    initialEvents={events}
                    initialDate={new Date()}
                    firstDay={new Date().getDay()}
                    height={isExternalView ? "90vh" : "76vh"}
                    allDaySlot={false}
                    slotMinTime={"08:00:00"}
                    slotMaxTime={"19:00:00"}
                    locale={"en-gb"}
                />
            </div>
        </Box>
        <Dialog
            open={openReservationModal}
            onClose={handleCloseReservationModal}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: { xl: "30vw", lg: "40vw", md: "50vw", sm: "70vw", xs: "80vw" },
                    }
                },
            }}
        >
            <Box padding={SPACING_SMALL}>
                {
                    !isMobile
                    && <Box sx={{ position: "absolute", right: SPACING_SMALL, display: "flex", gap: SPACING_EXTRA_SMALL }}>
                        {
                            reservationId && <DeleteIcon sx={{ color: ICON_COLOR, cursor: "pointer" }} onClick={submitDelete} />
                        }
                        <CloseIcon sx={{ color: ICON_COLOR, cursor: "pointer" }} onClick={handleCloseReservationModal} />
                    </Box>
                }
                <Typography variant="h5" marginBottom={SPACING_LARGE} id="parent-modal-title">{t(TranslationKeyEnum.reserveRoom)}</Typography>
                <StyledTextField
                    id="meeting-room"
                    name="meeting-room"
                    size='small'
                    label={t(TranslationKeyEnum.meetingRoom)}
                    fullWidth
                    value={room ? room.name : ''}
                    disabled
                    sx={{ marginBottom: SPACING_SMALL }}
                    InputLabelProps={{ shrink: true }}
                />
                <StyledTextField
                    id="title"
                    name="title"
                    size='small'
                    label={t(TranslationKeyEnum.title)}
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.currentTarget.value)}
                    sx={{ marginBottom: SPACING_SMALL }}
                    InputLabelProps={{ shrink: true }}
                />
                <StyledTextField
                    id="description"
                    name="description"
                    size='small'
                    label={t(TranslationKeyEnum.description)}
                    multiline
                    rows={isExternalView || isRegularView ? 2 : 3}
                    fullWidth
                    value={description}
                    onChange={(e) => setDescription(e.currentTarget.value)}
                    sx={{ marginBottom: SPACING_SMALL }}
                    InputLabelProps={{ shrink: true }}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box display="flex" justifyContent="space-between" gap={1} marginBottom={SPACING_SMALL}>
                        <MobileDateTimePicker
                            label={t(TranslationKeyEnum.startingFrom)}
                            format="DD/MM/YYYY HH:mm"
                            value={dayjs(startDate)}
                            onChange={(newValue) => { handleStartDatePick(newValue); }}
                            closeOnSelect={true}
                            slots={{ textField: StyledTextField as React.ElementType<TextFieldProps> }}
                            slotProps={{
                                textField: {
                                    sx: { svg: { color: LIGHT_BG_COLOR }, input: { cursor: "pointer" } },
                                    InputLabelProps: { shrink: true },
                                    autoComplete: "off",
                                },
                            }}
                            onError={(error) => error !== null && setStartDateError(true)}
                        />
                        <MobileDateTimePicker
                            label={t(TranslationKeyEnum.ending)}
                            format="DD/MM/YYYY HH:mm"
                            value={dayjs(endDate) as unknown as Date}
                            onChange={(newValue) => { handleEndDatePick(newValue); }}
                            closeOnSelect={true}
                            slots={{ textField: StyledTextField as React.ElementType<TextFieldProps> }}
                            slotProps={{
                                textField: {
                                    sx: { svg: { color: LIGHT_BG_COLOR }, input: { cursor: "pointer" } },
                                    InputLabelProps: { shrink: true },
                                    autoComplete: "off",
                                },
                            }}
                            shouldDisableDate={(day: Date) => compareDates(day, startDate!)}
                            onError={(error) => error !== null && setEndDateError(true)}
                        />
                    </Box>
                </LocalizationProvider>
                {reservationId !== '' && <StyledTextField
                    id="organizer"
                    name="organizer"
                    size='small'
                    label={t(TranslationKeyEnum.meetingOrganizer)}
                    fullWidth
                    value={organizer}
                    disabled
                    sx={{ marginBottom: SPACING_SMALL }}
                    InputLabelProps={{ shrink: true }}
                />}
                <Box display="flex" justifyContent={isMobile && reservationId ? "space-between" : "flex-end"}>
                    {
                        isMobile && reservationId && <Button variant="contained" color="error" onClick={submitDelete}>{t(TranslationKeyEnum.delete)}</Button>
                    }
                    <Button type="submit" variant="contained" sx={{ backgroundColor: LIGHT_BG_COLOR }} onClick={reservationId !== '' ? submitUpdate : submitCreate} >{t(TranslationKeyEnum.submit)}</Button>
                </Box>
            </Box>
        </Dialog>
    </>;
}