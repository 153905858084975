import { IUserStorageProps } from "./IUserStorageProps";

const ID = 'id';
const EMAIL = 'email';
const TOKEN = 'jwtToken';
const INCOMING_URL = 'incomingURL';
const REFRESH_TOKEN_ACTION = 'REFRESH_TOKEN_ACTION';
const X_API_KEY = 'X_API_KEY';
const ROOM_IDS = 'ROOM_IDS';
const GROUP_ID = 'GROUP_ID';

export enum RefreshTokenAction {
	IN_PROGRESS = 'IN_PROGRESS',
	IDLE = 'IDLE'
}

export const isRefreshTokenActionInProgress = (): boolean => localStorage.getItem(REFRESH_TOKEN_ACTION) === RefreshTokenAction.IN_PROGRESS;

export const setRefreshTokenActionToStorage = (status: RefreshTokenAction): void => localStorage.setItem(REFRESH_TOKEN_ACTION, status);

export const getApiKeyFromStorage = (): string | null => localStorage.getItem(X_API_KEY);

export const setTokenToStorage = (token: string): void => localStorage.setItem(TOKEN, token);

export const getTokenFromStorage = (): string | null => localStorage.getItem(TOKEN);

export const getUserIdFromStorage = (): string | null => localStorage.getItem(ID);

export const setUserToStorage = (id: string, email: string, token: string): void => {
	localStorage.setItem(ID, id);
	localStorage.setItem(EMAIL, email);
	localStorage.setItem(TOKEN, token);
}

export const getUserFromStorage = (): IUserStorageProps | null => {
	const id = localStorage.getItem(ID);
	const email = localStorage.getItem(EMAIL);
	const token = localStorage.getItem(TOKEN);

	return id && email && token ? createStorageUser(id, email, token) : null;
}

export const removeUserFromStorage = (): void => {
	localStorage.removeItem(ID);
	localStorage.removeItem(EMAIL);
	localStorage.removeItem(TOKEN);
}

export const setIncomingURLToStorage = (incomingURL: string) => localStorage.setItem(INCOMING_URL, incomingURL);

export const getIncomingURLFromStorage = (): string | null => localStorage.getItem(INCOMING_URL);

export const removeIncomingURLFromStorage = (): void => localStorage.removeItem(INCOMING_URL);

export const getRoomIdsFromStorage = (): string[] => (localStorage.getItem(ROOM_IDS) ?? "").split(',');

export const getGroupIdFromStorage = (): string | null => localStorage.getItem(GROUP_ID);

function createStorageUser(id: string, email: string, token: string): IUserStorageProps {
	const user = {
		id: id,
		email: email,
		token: token
	};
	return user;
}