import TimeOffCard from "./TimeOffCard/TimeOffCard";
import { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import { BankHolidayDto, GroupDataDto, TimeOffPolicyVM, UserClient } from "../../API/time-off-service";
import { TranslationKeyEnum } from "../../features/translations/TranslationKeyEnum";
import { useTryCatchJsonByAction } from "../../utils/fetchUtils";
import { getUserIdFromStorage } from "../../utils/storageUtils/storageUtils";
import { subtractDays } from "../../components/common/Calendar/helpers";
import { ICalendarEvent } from "../../components/common/Calendar/ICalendarProps";
import { DateRange } from "../../components/common/DateRange/DateRange";
import { GroupDto } from "../../API/time-off-service";
import { Box, Skeleton } from '@mui/material';
import { INFO_CARD_HEIGTH, INFO_CARD_MIN_HEIGTH, INFO_CARD_MIN_WIDTH, INFO_CARD_WIDTH, INFO_CARD_WIDTH_SM, INFO_CARD_WIDTH_XS, SPACING_MEDIUM } from "../../utils/cssUtils";

export const getDateOrNull = (date: Date | Dayjs | null): Date | null => date
    ? typeof (date as unknown as Dayjs).toDate === 'function'
        ? (date as unknown as Dayjs).toDate()
        : date as Date
    : null;

const userClient = new UserClient();
// this will be refactored to read from cache
const getBankHolidays = (userId: string): Promise<BankHolidayDto[]> => userClient.getUserBankHolidays();
export const getBankHolidaysForUser = (userId: string): Promise<BankHolidayDto[]> => userClient.getUserBankHolidays2(userId);

export function useGetNumberOfBusinessDaysExcludingBankHolidays(): (range: DateRange, userId?: string) => Promise<number> {
    const tryCatchAction = useTryCatchJsonByAction();

    const getNumberOfBusinessDaysExcludingBankHolidays = async (range: DateRange, userId?: string): Promise<number> => {
        const bindedGetBankHolidaysAction = !userId ? getBankHolidays.bind(null) : getBankHolidaysForUser.bind(null, userId);
        const bankHolidayModel = await tryCatchAction(bindedGetBankHolidaysAction);
        var numberOfDays = range.numberOfBusinessDaysInBetween();
        for (const holiday of bankHolidayModel.response as BankHolidayDto[]) {
            // Bank Holidays are ordered in a descending way so if the Start Date of
            // the range is later than the End date of the current Bank Holiday that
            // means that all Bank Holidays that come after won't be overlapping
            // with this date range
            if (range.startDate > holiday.endDate!) break;

            var holidayRange = new DateRange(holiday.startDate!, holiday.endDate!);

            // If two date ranges aren't overlapping, there is no need to calculate
            // the number of business days between them
            if (range.isOverlapped(holidayRange))
                numberOfDays -= range.numberOfBusinessDaysThatOverlapWith(holidayRange);
        }
        return numberOfDays;
    }

    return getNumberOfBusinessDaysExcludingBankHolidays;
}

function isEqual(groupId: string, eventGroupId: string) {
    return eventGroupId === groupId;
}

export const checkIfContainsGroup = (eventGroupId: string, groups: GroupDataDto[]) => {
    return groups.some(group => isEqual(group.groupId!, eventGroupId))
}

export const isOverlappedWithRequestDateRanges = (currentSelection: DateRange, events: ICalendarEvent[], groups: GroupDataDto[]): boolean => {
    const numberOfOverlappingRequests: Record<string, number> = {};

    for (let i = 0; i < events.length; i++) {
        if (events[i].groupIds && currentSelection.isOverlapped(new DateRange(new Date(events[i].start), subtractDays(new Date(events[i].end), 1))) && events[i].userId && events[i].userId !== getUserIdFromStorage()) {
            for (let j = 0; j < events[i].groupIds!.length; j++) {
                if (checkIfContainsGroup(events[i].groupIds![j], groups!)) {
                    !numberOfOverlappingRequests[events[i].groupIds![j]] && (numberOfOverlappingRequests[events[i].groupIds![j]] = 0);
                    (numberOfOverlappingRequests[events[i].groupIds![j]] = (numberOfOverlappingRequests[events[i].groupIds![j]] || 0) + 1);
                }
            }
        }
    }
    for (let i = 0; i < groups!.length; i++) {
        if (groups[i].numberOfUsersInGroup! - numberOfOverlappingRequests[groups[i].groupId!] <= groups[i].mustBePresentInGroup!)
            return true;
    }
    return false;
}

export const checkIfContainsGroupForGroupDto = (eventGroupId: string, groups: GroupDto[]) => {
    return groups.some(group => isEqual(group.id!, eventGroupId))
}

export const isUserRequestOverlappedWithRequestDateRanges = (currentSelection: DateRange, events: ICalendarEvent[], groups: GroupDto[], userId: string): boolean => {
    const numberOfOverlappingRequests: Record<string, number> = {};

    for (let i = 0; i < events.length; i++) {
        if (events[i].groupIds && currentSelection.isOverlapped(new DateRange(new Date(events[i].start), subtractDays(new Date(events[i].end), 1))) && events[i].userId && events[i].userId !== userId) {
            for (let j = 0; j < events[i].groupIds!.length; j++) {
                if (checkIfContainsGroupForGroupDto(events[i].groupIds![j], groups!)) {
                    !numberOfOverlappingRequests[events[i].groupIds![j]] && (numberOfOverlappingRequests[events[i].groupIds![j]] = 0);
                    (numberOfOverlappingRequests[events[i].groupIds![j]] = (numberOfOverlappingRequests[events[i].groupIds![j]] || 0) + 1);
                }
            }
        }
    }
    for (let i = 0; i < groups!.length; i++) {
        if (groups[i].members?.length! - numberOfOverlappingRequests[groups[i].id!] <= groups[i].mustBePresent!)
            return true;
    }
    return false;
}

export const isOverlappedWithMyRequests = (currentSelection: DateRange, events: ICalendarEvent[]): boolean => {
    const isOverlappedWithWithMyRequest = (event: ICalendarEvent) => event.userId!
        && event.userId === getUserIdFromStorage()
        && currentSelection.isOverlapped(new DateRange(new Date(event.start), subtractDays(new Date(event.end), 1)));

    return events.some(isOverlappedWithWithMyRequest);
}

export const isOverlappedWithUserRequests = (currentSelection: DateRange, events: ICalendarEvent[], userId: string): boolean => {
    const isOverlappedWithWithMyRequest = (event: ICalendarEvent) => event.userId
        && event.userId === userId
        && currentSelection.isOverlapped(new DateRange(new Date(event.start), subtractDays(new Date(event.end), 1)));

    return events.some(isOverlappedWithWithMyRequest);
}

/**Calculates an returns potential state of available days in policy after request with these days would be made. */
export const getNumberOfPotentialRemainderDaysInPolicy = (policy: TimeOffPolicyVM, numberOfSelectedBusinessDays: number): number => {
    return policy.totalDays! - policy.usedDays! - policy.reservedDays! - numberOfSelectedBusinessDays;
}

export const areDatesOutOfRangeForPolicyPair = (parentPolicy: TimeOffPolicyVM, policy: TimeOffPolicyVM, startDate: Date, endDate: Date): boolean => {
    return startDate! > policy!.endDate!
        || endDate! > policy!.endDate!
        || startDate! < parentPolicy!.startDate!
        || endDate! < parentPolicy!.startDate!;
}

export const isThereOnlyOnePolicy = (parentPolicy: TimeOffPolicyVM | null, childPolicy: TimeOffPolicyVM | null): boolean => {
    return (!parentPolicy && !childPolicy)
        || (!!parentPolicy && parentPolicy!.totalDays! - parentPolicy!.usedDays! - parentPolicy!.reservedDays! === 0)
        || (!!childPolicy && childPolicy!.totalDays! - childPolicy!.usedDays! - childPolicy!.reservedDays! === 0)
}

export const gerParentAndChildPolicyPair = (parentPolicy: TimeOffPolicyVM | null, policy: TimeOffPolicyVM, childPolicy: TimeOffPolicyVM | null): [TimeOffPolicyVM, TimeOffPolicyVM] => [parentPolicy ?? policy, parentPolicy === null ? childPolicy! : policy]

/**
 * Determines if the request range is overlapping with parent policy
 * @param policy Parent policy
 * @param startDate Start date of selected date range (request)
 * @param endDate End date of selected date range (request)
 * @returns 0 => out of range, before the start of parent policy (invalid),
 * 1 => the request is in parent policy range and maybe overlapped with parent-child overlap,
 * 2 => the request is overlapping with parent and child,
 * 3 => out of range at the end,
 * -1 => there is a use-case that isn't covered
 */
export const isInParentRange = (policy: TimeOffPolicyVM, startDate: Date, endDate: Date): number => {
    // if the request is before, or partially overlapped at the start, invalid
    if (startDate! < policy.startDate!) {
        return 0;
    }
    // if the request is completely in range, further checks are needed on the parent, and maybe on a child
    if (startDate! >= policy.startDate! && startDate! <= policy.endDate! && endDate! <= policy.endDate!) {
        return 1;
    }
    // if the request is partially overlapped at the end, further checks are needed on the parent and child
    if (startDate! >= policy.startDate! && startDate! <= policy.endDate! && endDate! > policy.endDate!) {
        return 2;
    }
    // completely out of range on the end, single check on child needed
    if (startDate! > policy.endDate!) {
        return 3;
    }

    // something is missing
    return -1;
}

export function useValidatePolicyRange(): (policy: TimeOffPolicyVM, startDate: Date, endDate: Date) => string | null {
    const { t } = useTranslation();

    /**
     * Checks if there are the policy is valid in the selected date range.
     * If policy passes validation it returns null, else it returns an error message for display.
     * @param policy 
     * @param startDate Start date of selected date range
     * @param endDate End date of selected date range
     * @returns NULL if valid, error message if not valid
     */
    const validatePolicyRange = (policy: TimeOffPolicyVM, startDate: Date, endDate: Date): string | null => {
        return areDatesOutOfRangeForPolicyPair(policy, policy, startDate, endDate)
            ? t(TranslationKeyEnum.policyValidRange, { startDate: (policy!.startDate!.toLocaleDateString('en-GB').replace(/\//g, "-")), endDate: (policy!.endDate!.toLocaleDateString('en-GB').replace(/\//g, "-")) })
            : null;
    }

    return validatePolicyRange;
}

export function useValidateSinglePolicy(): (policy: TimeOffPolicyVM, numberOfDays: number, startDate: Date, endDate: Date) => string | null {
    const { t } = useTranslation();

    /**
     * Checks if there are enough available days and if the policy is valid in the selected date range.
     * If policy passes validation it returns null, else it returns an error message for display.
     * @param policy 
     * @param numberOfDays Number of selected days.
     * @param startDate Start date of selected date range
     * @param endDate End date of selected date range
     * @returns NULL if valid, error message if not valid
     */
    const validateSinglePolicy = (policy: TimeOffPolicyVM, numberOfDays: number, startDate: Date, endDate: Date): string | null => {
        return getNumberOfPotentialRemainderDaysInPolicy(policy, numberOfDays) < 0
            ? t(TranslationKeyEnum.noAvailableDays)
            : areDatesOutOfRangeForPolicyPair(policy, policy, startDate, endDate)
                ? t(TranslationKeyEnum.policyValidRange, { startDate: (policy!.startDate!.toLocaleDateString('en-GB').replace(/\//g, "-")), endDate: (policy!.endDate!.toLocaleDateString('en-GB').replace(/\//g, "-")) })
                : null;
    }

    return validateSinglePolicy;
}

export function useValidatePartialPolicy(): (policy: TimeOffPolicyVM, startDate: Date, endDate: Date) => Promise<boolean> {
    const getNumberOfBusinessDaysExcludingBankHolidays = useGetNumberOfBusinessDaysExcludingBankHolidays();

    /**
     * Validates are there enough available days in the overlapping part of the policy
     * @param policy 
     * @param startDate Start date of selected date range
     * @param endDate End date of selected date range
     * @returns TRUE if there is enough days, otherwise FALSE
     */
    const validatePartialPolicy = async (policy: TimeOffPolicyVM, startDate: Date, endDate: Date): Promise<boolean> => {
        const policyDateRange = new DateRange(policy.startDate!, policy.endDate!);
        const selectedDateRange = new DateRange(startDate, endDate);
        const overlappingDateRange = policyDateRange.getOverlappingDateRange(selectedDateRange);
        return getNumberOfPotentialRemainderDaysInPolicy(policy, await getNumberOfBusinessDaysExcludingBankHolidays(overlappingDateRange)) >= 0;
    }

    return validatePartialPolicy;
}

export const showListOfUserPolicies = (userPolicies: TimeOffPolicyVM[], userId: string, isForUser: boolean) => {
    return userPolicies && userPolicies.map((policy: TimeOffPolicyVM) => {
        return <Box marginBottom={SPACING_MEDIUM} display="flex" justifyContent="center">
            <TimeOffCard policy={policy} policyName={policy.name!} totalDays={policy.totalDays!} usedDays={policy.usedDays!} reservedDays={policy.reservedDays!} endDate={policy.endDate!} isDaysInfinity={policy.infiniteDays!!} userId={userId} isForUser={isForUser} />
        </Box>
    })
}

export const showListOfUserPoliciesSkeleton = () => Array.from(Array(4)).map((_) => (
    <Box marginBottom={SPACING_MEDIUM} display="flex" justifyContent="center">
        <Skeleton variant="rectangular"
            sx={{
                height: INFO_CARD_HEIGTH,
                minHeight: INFO_CARD_MIN_HEIGTH,
                width: { xl: INFO_CARD_WIDTH, lg: INFO_CARD_WIDTH, md: INFO_CARD_WIDTH, sm: INFO_CARD_WIDTH_SM, xs: INFO_CARD_WIDTH_XS },
                minWidth: INFO_CARD_MIN_WIDTH
            }}
        />
    </Box>
))