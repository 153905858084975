import DoneIcon from '@mui/icons-material/Done';
import { Box } from '@mui/material';
import { BORDER_RADIUS_SMALL } from '../../../../utils/cssUtils';

export default function RequestDoneIcon() {
  return (
    <Box sx={{
      backgroundColor: "green", borderRadius: "50%", position: "relative", width: "64px", height: "64px",
      '&::after': {
        content: "''",
        width: "10",
        height: "10",
        position: "absolute",
        left: '14px',
        bottom: "-6px",
        borderLeft: "20px solid green",
        borderRight: "12px solid transaprent",
        borderTop: "10px solid green",
        borderBottom: "10px solid transparent",
        borderRadius: BORDER_RADIUS_SMALL
      }
    }}>
      <DoneIcon fontSize="large" sx={{ color: "white", marginTop: "14px", marginLeft: "14px" }} />
    </Box>
  )
}