export const UserAddOrEditValidation = {
    MAXIMUM_FILE_SIZE: 2 * 1024 * 1024,
    SUPPORTED_FILE_FORMATS: [
        "image/jpg",
        "image/jpeg",
        "image/png",
        "image/webp"
    ],
    MINIMUM_PHONE_LENGTH: 9,
    MAXIMUM_PHONE_LENGTH: 13,
    MINIMUM_ADDRESS_LENGTH: 5,
    MAXIMUM_ADDRESS_LENGTH: 255,
};