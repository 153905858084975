import { indigo, lightBlue, lightGreen, lime, purple, teal } from "@mui/material/colors";
import { BankHolidayDto, RequestBasicVM } from "../../../API/time-off-service";
import { CARD_BOX_SHADOW } from "../../../utils/cssUtils";
import { CalendarItemType, ICalendarEvent } from "./ICalendarProps";
import { CalendarUserDto, CalendarWorkOrderDto, ReservationEventDto } from "../../../API/work-order-service";
import { DayHeaderContentArg } from "@fullcalendar/core";
import { getLanguage } from "../../../features/translations/i18n";
import { VerboseFormattingArg } from "@fullcalendar/core/internal";

const bankHolidayColor = '#FECDD2';

// we are adding one day because fullcalendar module is setting +1 
// day for the end date (it assumes that the date range ends on the 
// "last day at 00:00:00")
// quote from the documentation: "For example, if you have an all-day 
// event that has an end of 2018-09-03, then it will span through 
// 2018-09-02 and end before the start of 2018-09-03."
export const mapBankHolidayDTOsToCalendarEvents = (source: BankHolidayDto[]): ICalendarEvent[] => {
    return source.map(h => ({
        id: h.id,
        title: h.name,
        start: toStringYYYYMMDD(h.startDate!),
        end: toStringYYYYMMDD(addDays(h.endDate!, 1)),
        display: 'background',
        color: bankHolidayColor,
        textColor: '#fff'
    } as ICalendarEvent));
}

export const mapRequestBasicVMsToCalendarEvents = (source: RequestBasicVM[]): ICalendarEvent[] => {
    return source.map(r => ({
        id: r.id,
        title: r.userFullName,
        start: toStringYYYYMMDD(r.startDate!),
        end: toStringYYYYMMDD(addDays(r.endDate!, 1)),
        color: colourPairs[r.statusColor!].backgroundColor,
        textColor: colourPairs[r.statusColor!].textColor,
        groupIds: r.userGroupIds,
        userId: r.userId,
        status: r.status,
        classNames: ["all-events"],
    } as ICalendarEvent));
}

export const mapWorkOrdersVMsToCalendarEvents = (source: CalendarWorkOrderDto[], type?: CalendarItemType): ICalendarEvent[] => {
    return source.map(wo => ({
        id: wo.id,
        title: `${wo.name}`,
        description: { locationCode: wo.locationCode, typeOfWork: wo.typeOfWork?.name },
        start: toStringYYYYMMDD(wo.startDate!),
        end: toStringYYYYMMDD(addDays(wo.endDate!, 1)),
        color: colourPairs["Purple"].backgroundColor,
        textColor: colourPairs["Purple"].textColor,
        classNames: ["all-events", "weekCalendarOverrides"],
        type: type
    } as ICalendarEvent));
}

export const mapUsersVMsToCalendarEvents = (source: CalendarUserDto[], type?: CalendarItemType): ICalendarEvent[] => {
    return source.map(user => ({
        id: user.workOrderId,
        userId: user.userId,
        title: `${user.displayName}`,
        start: toStringYYYYMMDD(user.start!),
        end: toStringYYYYMMDD(addDays(user.end!, 1)),
        color: colourPairs["Purple"].backgroundColor,
        textColor: colourPairs["Purple"].textColor,
        classNames: ["all-events", "weekCalendarOverrides"],
        type: type
    } as ICalendarEvent));
}

export const mapReservationVMsToCalendarEvents = (source: ReservationEventDto[]): ICalendarEvent[] => {
    return source.map(reservation => ({
        id: reservation.id,
        title: `${reservation.title}-${reservation.organizer}`,
        start: toStringYYYYMMDDHHmm(reservation.startTime!),
        end: toStringYYYYMMDDHHmm(reservation.endTime!),
        color: colourPairs["Purple"].backgroundColor,
        textColor: colourPairs["Purple"].textColor,
        classNames: ["all-events"]
    } as ICalendarEvent));
}

export const addDays = (date: Date, days: number): Date => {
    let copiedDate = new Date(date.getTime());
    copiedDate.setDate(copiedDate.getDate() + days);
    return copiedDate;
}

export const subtractDays = (date: Date, days: number): Date => {
    let copiedDate = new Date(date.getTime());
    copiedDate.setDate(copiedDate.getDate() - days);
    return copiedDate;
}

export const toStringYYYYMMDD = (date: Date): string => {
    const year = date.getFullYear();
    // adds 0 before day and month if they are single digit
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export const toStringDDMMYYYY = (date: Date): string => {
    const year = date.getFullYear();
    // adds 0 before day and month if they are single digit
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${day}-${month}-${year}`;
}

export const toStringDDMMYYYYWithDotsFormat = (date: Date): string => {
    const year = date.getFullYear();
    // adds 0 before day and month if they are single digit
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${day}.${month}.${year}`;
}

export const toStringYYYYMMDDHHmm = (date: Date): string => {
    const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
    const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
    const hours = new Intl.DateTimeFormat('en', { hour: '2-digit', hourCycle: "h24" }).format(date).slice(0, 2);
    const minutes = new Intl.DateTimeFormat('en', { minute: '2-digit' }).format(date);
    return `${year}-${month}-${day}T${hours}:${minutes === '0' ? '00' : minutes}:00`;
}

/**Default style for displaying calendar component inside a dialog */
export const defaultStyle = { height: '70vh', width: '60vw', boxShadow: CARD_BOX_SHADOW };

interface ColorPair {
    [key: string]: { backgroundColor: string, textColor: string, dot?: string };
}

export const colourPairs: ColorPair = {
    "Purple": {
        backgroundColor: '#F3E5F5',
        textColor: '#4A148C',
        dot: purple[100],
    },
    "LightGreen": {
        backgroundColor: '#F1F8E9',
        textColor: '#1B5E20',
        dot: lightGreen[100],
    },
    "LightBlue": {
        backgroundColor: '#E1F5FE',
        textColor: '#01579B',
        dot: lightBlue[100],
    },
    "Teal": {
        backgroundColor: '#E0F2F1',
        textColor: '#004D40',
        dot: teal[100],
    },
    "Indigo": {
        backgroundColor: '#E8EAF6',
        textColor: '#1A237E',
        dot: indigo[100],
    },
    "Lime": {
        backgroundColor: '#F0F4C3',
        textColor: '#827717',
        dot: lime[100],
    },
    "Pending": {
        backgroundColor: '#FFF3E0',
        textColor: '#E65100',
    },
    "Highlight": {
        backgroundColor: '#CFD8DC',
        textColor: '#607D8B',
    },
    "Undefined": {
        backgroundColor: '#fff',
        textColor: '#fff',
    },
};

/**Id for temporary event in calendar */
export const temporaryEventId = 'temporary-id-for-selected-event';
export const temporaryEventColor = colourPairs["Highlight"].backgroundColor;
export const temporaryEventTextColor = colourPairs["Highlight"].textColor;

export const renderWeekdayHeaderAccordingToLanguage = (arg: DayHeaderContentArg): JSX.Element => {
    const weekDaysArray = customWeekDayNamesDictionary[getLanguage()];
    const weekDayName = weekDaysArray[arg.date.getDay()];
    return (
        <div className="custom-weekday-header">
            {weekDayName}
        </div>
    )
}

export const renderMonthAndYearAccordingToLanguage = (info: VerboseFormattingArg): string => {
    const [month, year] = getMonthAndYearAccordingToLanguage(info);
    return `${month} ${year}`;
}

export const renderDaysSpanMonthAndYearAccordingToLanguage = (info: VerboseFormattingArg): string => {
    // displayed days in week without first day in days span
    const REMAINING_DAYS_IN_WEEK_VIEW = 6;
    // month and year of first day in days span
    const [month, year] = getMonthAndYearAccordingToLanguage(info);
    // number of days in month of the first day in days span
    const startDayMonthDays = daysInMonth(info.date.month, info.date.year);
    // first day in a days span
    const startDay = info.date.day;
    // second day in a days span
    const endDay = startDay + REMAINING_DAYS_IN_WEEK_VIEW;
    // if end day is greater then number of month of the first day in days span we move it to the next month
    if (endDay > startDayMonthDays) {
        // calculating date of the second day in next month
        const endDayInNextMonth = REMAINING_DAYS_IN_WEEK_VIEW - (startDayMonthDays - startDay);
        
        // gets end day month index and name so we can display it in days span
        const monthNamesArray = customMonthNamesDictionary[getLanguage()];
        const endDayMonthIndex = info.date.month === 11 ? 0 : info.date.month + 1;
        const endDayMonthName: string = monthNamesArray[endDayMonthIndex];

        // formatting strings for first and end day in days span
        const startDayAndMonth = `${startDay} ${month}`;
        const endDayAndMonth = `${endDayInNextMonth} ${endDayMonthName}`
        return endDayMonthIndex === 0
            // if end day month is January we increase year by 1 and displaying it in end day data
            ? `${startDayAndMonth} ${year} - ${endDayAndMonth} ${Number(year) + 1}`
            : `${startDayAndMonth} - ${endDayAndMonth} ${year}`;
    }
    return `${startDay} - ${endDay} ${month} ${year}`
}

const daysInMonth = (month: number, year: number): number => {
    return new Date(year, month + 1, 0).getDate();
}

const getMonthAndYearAccordingToLanguage = (info: VerboseFormattingArg): string[] => {
    const monthNamesArray = customMonthNamesDictionary[getLanguage()];
    const month: string = monthNamesArray[info.date.month];
    const year: string = info.date.year.toString();
    return [month, year];
}

const customWeekDayNamesDictionary: { [key: string]: string[] } = {
    "SR": ["Nedelja", "Ponedeljak", "Utorak", "Sreda", "Četvrtak", "Petak", "Subota"],
    "EN": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
};

const customMonthNamesDictionary: { [key: string]: { [key: number]: string } } = {
    "SR": {
        0: "Januar",
        1: "Februar",
        2: "Mart",
        3: "April",
        4: "Maj",
        5: "Jun",
        6: "Jul",
        7: "Avgust",
        8: "Septembar",
        9: "Oktobar",
        10: "Novembar",
        11: "Decembar"
    },
    "EN": {
        0: "January",
        1: "February",
        2: "March",
        3: "April",
        4: "May",
        5: "June",
        6: "July",
        7: "August",
        8: "September",
        9: "October",
        10: "November ",
        11: "December"
    }
}