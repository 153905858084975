import * as Sentry from "@sentry/react";
import PositionProps from "../common/CreateEditUserForms/actions";
import { FormikConfig, FormikValues } from "formik";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useGetIdentityUser, useGetIdentityUserForActivation } from "../../API/identity-actions";
import { useGetTimeOffUser } from "../../API/time-off-actions";
import { UserDto } from "../../API/time-off-service";
import { useGetUser, useGetUserForActivation } from "../../API/user-actions";
import { IUserProps } from "../common/interfaces/IUserProps";
import { useEditUserValidationSchema } from "./userEditUserVaidationSchema";
import { PolicyDto, UserDto as IdentityUserDto } from "../../API/identity-service";
import { PositionDto, StatusEnum } from "../../API/user-management-service";
import { getLanguage } from "../../features/translations/i18n";

export function useEditUserFormikForm(userId: string, setIsFormLoading: Dispatch<SetStateAction<boolean>>, isForActivation: boolean, setInitialPosition: Dispatch<SetStateAction<PositionProps[]>>)
    : (handleSubmit: () => void, isReinitializeEnabled: boolean) => FormikConfig<FormikValues> {
    const [user, setUser] = useState({} as IUserProps);
    const [identityUser, setIdentityUser] = useState<IdentityUserDto>({} as IdentityUserDto)
    const [timeOffUser, setTimeOffUser] = useState({} as UserDto)
    const [permissionsNames, setPermissionNames] = useState<(string | undefined)[]>([]);

    const editUserValidationSchema = useEditUserValidationSchema();
    const getEmployeeActivation = useGetUserForActivation();
    const getEmployee = useGetUser();
    const getIdentityEmplooyeeActivation = useGetIdentityUserForActivation();
    const getIdentityEmplooyee = useGetIdentityUser();
    const getTimeOffEmployee = useGetTimeOffUser();

    const getImageType = (imageBase64: string) => {
        const key = imageBase64.charAt(0);
        switch (key) {
            case '/':
                return 'image/jpg'
            case 'i':
                return 'image/png'
            case 'R':
                return 'image/gif'
            case 'U':
                return 'image/webp'
            default:
                return ''
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const actions = isForActivation
                ? [getEmployeeActivation(userId), getIdentityEmplooyeeActivation(userId), getTimeOffEmployee(userId)]
                : [getEmployee(userId), getIdentityEmplooyee(userId), getTimeOffEmployee(userId)];

            Promise.all(actions).then((values) => {
                setUser(values[0] as IUserProps);
                setIdentityUser(values[1] as IdentityUserDto);
                setTimeOffUser(values[2] as UserDto);
                setIsFormLoading(false);
            });
        }
        fetchData();
    }, [userId]);

    useEffect(() => {
        if (identityUser.policies) {
            let lang = getLanguage();
            let permissions = identityUser.policies.map((p: PolicyDto) => { return JSON.parse(p.title!)[lang] });
            setPermissionNames(permissions);
        }
    }, [identityUser?.policies])

    // Set initial position values because Formik is changing the initial values inside EditUserForm
    useEffect(() => {
        user?.positions?.length && setInitialPosition(user.positions.map(position => new PositionProps(position)))
    }, [user.positions])

    function editUserFormikForm(handleSubmit: () => void, isReinitializeEnabled: boolean): FormikConfig<FormikValues> {
        try {
            let formikValues: FormikConfig<FormikValues> = {
                initialValues: {
                    id: user?.userId ? user.userId : "",
                    email: user?.email ? user.email : "",
                    firstName: user?.firstName ? user.firstName : "",
                    lastName: user?.lastName ? user.lastName : "",
                    phone: user?.phone ? user.phone : "",
                    address: user?.address ? user.address : "",
                    birthdate: user?.birthdate ? user.birthdate : new Date(),
                    yearsOfService: timeOffUser?.yearsOfService ?? 0,
                    approverId: timeOffUser.approverId ? timeOffUser.approverId : "",
                    selectedPermissions: user?.selectedPermissions ? user.selectedPermissions : "",
                    image: user?.image ? user.image : "",
                    sizeOfImage: user?.image ? user.image.length : "",
                    formatOfImage: user?.image ? getImageType(user.image) : "",
                    office: user.office ? user.office : "",
                    permissions: identityUser?.policies ? permissionsNames && permissionsNames : [],
                    positions: user.positions && user.positions.length ? user.positions.map(position => new PositionProps(position)) : [new PositionDto({ name: "", startDate: new Date(), endDate: new Date() })],
                    status: user?.status && user.status === StatusEnum.Active ? StatusEnum.Active : StatusEnum.AccessDisabled,
                    personalNo: user?.personalNo ? user.personalNo : "",
                    passportNumber: user?.passportNumber ? user.passportNumber : "",
                    passportValidUntil: user?.passportValidUntil ? user.passportValidUntil : new Date(),
                    contractExpiration: user?.contractExpiration ? user.contractExpiration : new Date(),
                    idCardNumber: user?.idCardNumber ? user.idCardNumber : "",
                    idCardValidUntil: user?.idCardValidUntil ? user.idCardValidUntil : new Date(),
                    doctorAppointment: user?.doctorAppointment ? user.doctorAppointment : new Date(),
                    certificateValidUntil: user?.certificateValidUntil ? user.certificateValidUntil : new Date(),
                    files: user?.files && user.files.length ? user.files : [],
                    filesToDelete: user?.filesToDelete && user.filesToDelete.length ? user.filesToDelete : [],
                    filesToAdd: user?.filesToAdd && user.filesToAdd.length ? user.filesToAdd : [],
                    medicalInsuranceNumber: user?.medicalInsuranceNumber ? user.medicalInsuranceNumber : "",
                    religiousDay: user?.religiousDay ? user.religiousDay : "",
                },
                validationSchema: editUserValidationSchema,
                onSubmit: handleSubmit,
                enableReinitialize: isReinitializeEnabled,
            }
            return formikValues;
        } catch (error: any) {
            Sentry.captureException(error);
        }
        let formikValues2: FormikConfig<FormikValues> = {
            initialValues: {},
            validationSchema: editUserValidationSchema,
            onSubmit: handleSubmit,
            enableReinitialize: isReinitializeEnabled,
        }
        return formikValues2;
    }

    return editUserFormikForm;
}