import WorkOffIcon from '@mui/icons-material/WorkOff';
import { IDoneWorkOrdersTableProps } from './IDoneWorkOrdersTableProps'
import { Box, Typography } from '@mui/material';
import { BORDER_RADIUS_EXTRA_LARGE, LIGHT_BORDER, SPACING_EXTRA_SMALL, SPACING_SMALL } from '../../../utils/cssUtils';
import { useTranslation } from 'react-i18next';
import { TranslationKeyEnum } from '../../../features/translations/TranslationKeyEnum';

export default function DoneWorkOrdersTable({ doneWorkOrders, tableSxProps }: IDoneWorkOrdersTableProps) {
    const { t } = useTranslation();
    return (
        doneWorkOrders?.length
            ? <Box border={LIGHT_BORDER} borderRadius={BORDER_RADIUS_EXTRA_LARGE} sx={tableSxProps}>
                {
                    doneWorkOrders.map((order, index) => {
                        return <Box
                            display="flex"
                            justifyContent="space-between"
                            borderBottom={index === doneWorkOrders.length - 1 ? "" : LIGHT_BORDER}
                            padding={SPACING_SMALL} key={index}
                        >
                            <Typography variant="body1">{order.name.charAt(0).toUpperCase() + order.name.slice(1)}</Typography>
                            <Typography variant="body1">x{order.times}</Typography>
                        </Box>
                    })
                }
            </Box>
            : <Box display="flex" flexDirection="column" alignItems="center" gap={SPACING_EXTRA_SMALL}>
                <WorkOffIcon color="action" />
                <Typography variant="body2" align="center" color="grey">{t(TranslationKeyEnum.noDoneWorkOrdersThisMonth)}</Typography>
            </Box>
    )
}
