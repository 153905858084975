export const TranslationKeyEnum = {
    panosLabel: 'panosLabel',
    signIn: 'signIn',
    email: 'email',
    emailPlaceholder: 'emailPlaceholder',
    emailError: 'emailError',
    password: 'password',
    passwordPlaceholder: 'passwordPlaceholder',
    passwordMax: 'passwordMax',
    passwordMin: 'passwordMin',
    passwordMustMatch: 'passwordMustMatch',
    passwordMustntMatch: 'passwordMustntMatch',
    currentPassword: 'currentPassword',
    currentPasswordPlaceholder: 'currentPasswordPlaceholder',
    newPassword: 'newPassword',
    newPasswordPlaceholder: 'newPasswordPlaceholder',
    confirmNewPassword: 'confirmNewPassword',
    confirmNewPasswordPlaceholder: 'confirmNewPasswordPlaceholder',
    firstName: 'firstName',
    lastName: 'lastName',
    name: 'name',
    phone: 'phone',
    invalidPhoneNumber: 'invalidPhoneNumber',
    address: 'address',
    dateOfBirth: 'dateOfBirth',
    invalidDateOfBirth: 'invalidDateOfBirth',
    startWorkingDate: 'startWorkingDate',
    role: 'role',
    actions: 'actions',
    status: 'status',
    required: 'required',
    submit: 'submit',
    home: 'home',
    people: 'people',
    logout: 'logout',
    copyEmail: 'copyEmail',
    emailCopied: 'emailCopied',
    searchEmployee: 'searchEmployee',
    office: 'office',
    offices: 'offices',
    position: 'position',
    positions: 'positions',
    employees: 'employees',
    employeesPageDescription: 'employeesPageDescription',
    noUsers: 'noUsers',
    noOffice: 'noOffice',
    noUsersInOffice: 'noUsersInOffice',
    resetFilters: 'resetFilters',
    loggedIn: 'loggedIn',
    somethingWentWrong: 'somethingWentWrong',
    somethingWrongWithRequest: 'somethingWrongWithRequest',
    userUnauthorizedError: 'userUnauthorizedError',
    userForbiddenError: 'userForbiddenError',
    actionCompleted: 'actionCompleted',
    adminConsole: 'adminConsole',
    adminConsoleDescription: 'adminConsoleDescription',
    mainInfo: 'mainInfo',
    additionalInfo: 'additionalInfo',
    permissions: 'permissions',
    vacation: 'vacation',
    back: 'back',
    next: 'next',
    add: 'add',
    earlierPositions: 'earlierPositions',
    positionName: 'positionName',
    workStartDate: 'workStartDate',
    workEndDate: 'workEndDate',
    startDate: 'startDate',
    endDate: 'endDate',
    addOffice: 'addOffice',
    addPosition: 'addPosition',
    removePosition: 'removePosition',
    selectEmployeePermissions: 'selectEmployeePermissions',
    selectApprover: 'selectApprover',
    selectPolicy: 'selectPolicy',
    selectColor: 'selectColor',
    yearsOfService: 'yearsOfService',
    edit: 'edit',
    delete: 'delete',
    save: 'save',
    numberOfEmployees: 'numberOfEmployees',
    confirmDelete: 'confirmDelete',
    deleteEmployeeDescription: 'deleteEmployeeDescription',
    deleteOfficeDescription: 'deleteOfficeDescription',
    deletePositionDescription: 'deletePositionDescription',
    deletePolicyDescription: 'deletePolicyDescription',
    deleteUserFromPolicy: 'deleteUserFromPolicy',
    deleteGroupDescription: 'deleteGroupDescription',
    deleteBankHolidayDescription: 'deleteBankHolidayDescription',
    uploadImage: 'uploadImage',
    removeImage: 'removeImage',
    invalidImageFormat: 'invalidImageFormat',
    maximumImageSize: 'maximumImageSize',
    ok: 'ok',
    yesRemove: 'yesRemove',
    cancel: 'cancel',
    calendar: 'calendar',
    viewCalendar: 'viewCalendar',
    selectedDateRange: 'selectedDateRange',
    timeOffTab: 'timeOffTab',
    timeOff: 'timeOff',
    workOrder: "workOrder",
    workOrdersTab: 'workOrdersTab',
    workOrdersTabContent: 'workOrdersTabContent',
    pendingRequestsTab: 'pendingRequestsTab',
    pendingRequestsPageDescription: 'pendingRequestsPageDescription',
    close: 'close',
    officeNameAndAddressEmpty: 'officeNameAndAddressEmpty',
    finishOfficeCreation: 'finishOfficeCreation',
    positionNameEmpty: 'positionNameEmpty',
    samePositionName: 'samePositionName',
    currentPosition: 'currentPosition',
    noCurrentPositionDefinedLabel: 'noCurrentPositionDefinedLabel',
    noCurrentPositionDefinedDescription: 'noCurrentPositionDefinedDescription',
    createRequest: 'createRequest',
    policy: 'policy',
    policies: 'policies',
    comment: 'comment',
    description: 'description',
    numberOfBusinessDays: 'numberOfBusinessDays',
    zeroWorkDaysSelected: 'zeroWorkDaysSelected',
    wantToProceed: 'wantToProceed',
    groupRequestsOverlap: 'groupRequestsOverlap',
    usersGroupRequestsOverlap: 'usersGroupRequestsOverlap',
    noAvailableDays: 'noAvailableDays',
    alreadySelectedDates: 'alreadySelectedDates',
    dependantPoliciesInfo: `dependantPoliciesInfo`,
    policyValidRange: `policyValidRange`,
    addedDays: 'addedDays',
    policySummary: 'policySummary',
    changedBy: 'changedBy',
    daysChange: 'daysChange',
    daysChangePolicySummary: 'daysChangePolicySummary',
    availableDays: 'availableDays',
    totalDays: 'totalDays',
    usedDays: 'usedDays',
    modificationDate: 'modificationDate',
    pendingRequests: 'pendingRequests',
    pendingRequestsTimeOff: 'pendingRequestsTimeOff',
    myRequests: 'myRequests',
    open: 'open',
    leaveRequest: 'leaveRequest',
    cancelRequest: 'cancelRequest',
    minimumInputLength: 'minimumInputLength',
    maximumInputLength: 'maximumInputLength',
    minimumInputValue: 'minimumInputValue',
    policyLoading: 'policyLoading',
    addUser: 'addUser',
    addEmployee: 'addEmployee',
    users: 'users',
    groups: 'groups',
    groupsLoading: 'groupsLoading',
    addOrChangePolicy: 'addOrChangePolicy',
    addPolicy: 'addPolicy',
    policyName: 'policyName',
    policyDependsOn: 'policyDependsOn',
    policyAllowNegative: 'policyAllowNegative',
    policyInfiniteDays: 'policyInfiniteDays',
    policyStartDate: 'policyStartDate',
    policyEndDate: 'policyEndDate',
    policyColor: 'policyColor',
    groupHasMembers: 'groupHasMembers',
    addGroup: 'addGroup',
    editGroup: 'editGroup',
    editUser: 'editEmployee',
    mustBePresentLabel: 'mustBePresentLabel',
    mustBePresentError: 'mustBePresentError',
    requirement: 'requirement',
    bankHolidays: 'bankHolidays',
    bankHolidaysLoading: 'bankHolidaysLoading',
    editBankHoliday: 'editBankHoliday',
    addBankHoliday: 'addBankHoliday',
    phoneNumber: 'phoneNumber',
    birthdate: 'birthdate',
    dateOfEmployment: 'dateOfEmployment',
    notifications: 'notifications',
    markAllAsRead: 'markAllAsRead',
    requestNotExist: 'requestNotExist',
    allNotifications: 'allNotifications',
    dashboard: 'dashboard',
    search: 'search',
    you: 'you',
    company: 'company',
    companyName: 'companyName',
    language: 'language',
    welcomeBack: 'welcomeBack',
    upcomingTimeOff: 'upcomingTimeOff',
    upcomingTimeOffToday: 'upcomingTimeOffToday',
    upcomingTimeOffNextDays: 'upcomingTimeOffNextDays',
    upcomingTimeOffLinkLabel: 'upcomingTimeOffLinkLabel',
    whatIsNew: 'whatIsNew',
    workDay: 'workDay',
    workDays: 'workDays',
    workDaysRequestHistory: 'workDaysRequestHistory',
    nextTimeOff: 'nextTimeOff',
    timeOffCardDescription: 'timeOffCardDescription',
    viewAllTimeOffRequests: 'viewAllTimeOffRequests',
    noPendingNotifications: 'noPendingNotifications',
    notificationsUnavailable: 'notificationsUnavailable',
    remove: 'remove',
    userLabel: 'userLabel',
    locationLabel: 'locationLabel',
    hireDateLabel: 'hireDateLabel',
    all: "all",
    approver: 'approver',
    workInformation: 'workInformation',
    basicInformation: 'basicInformation',
    personalInformation: 'personalInformation',
    basicPersonalInformation: 'basicPersonalInformation',
    timeOffInformation: 'timeOffInformation',
    addTimeOffDays: 'addTimeOffDays',
    daysPerPolicy: 'daysPerPolicy',
    expiresOn: 'expiresOn',
    date: 'date',
    available: 'available',
    employeeAvailable: 'employeeAvailable',
    today: 'today',
    taken: 'taken',
    workOrdersInfo: 'workOrdersInfo',
    finished: 'finished',
    canceled: 'canceled',
    days: 'days',
    daysRequested: 'daysRequested',
    viewRequest: 'viewRequest',
    viewCancellationRequest: 'viewCancellationRequest',
    upcomingHolidays: 'upcomingHolidays',
    loginGreetings: 'loginGreetings',
    forgotPassword: 'forgotPassword',
    forgotPasswordPlaceholder: 'forgotPasswordPlaceholder',
    login: 'login',
    sayCongrats: 'sayCongrats',
    requestTimeOff: 'requestTimeOff',
    changePassword: 'changePassword',
    createRequestLabel: 'createRequestLabel',
    createRequestForUserLabel: 'createRequestForUserLabel',
    typeOfAbsence: 'typeOfAbsence',
    startingFrom: 'startingFrom',
    ending: "ending",
    policyNotEnoughDays: 'policyNotEnoughDays',
    selectTypeOfAbsence: 'selectTypeOfAbsence',
    selectOffice: 'selectOffice',
    selectPosition: 'selectPosition',
    requestOverlaps: 'requestOverlaps',
    requestWorkDays: 'requestWorkDays',
    hooray: 'hooray',
    successfullRequest: 'successfullRequest',
    done: 'done',
    invalidStartDate: 'invalidStartDate',
    invalidEndDate: 'invalidEndDate',
    userRequestedTimeOff: 'userRequestedTimeOff',
    userRequestedTimeOffCancellationFirstPart: 'userRequestedTimeOffCancellationFirstPart',
    userRequestedTimeOffCancellationSecondPart: 'userRequestedTimeOffCancellationSecondPart',
    userRequestedTimeOffCancellationThirdPart: 'userRequestedTimeOffCancellationThirdPart',
    decline: 'decline',
    approve: 'approve',
    approveCancellation: 'approveCancellation',
    relatedRequest: 'relatedRequest',
    newsFeed: 'newsFeed',
    requestPending: 'requestPending',
    requestApproved: 'requestApproved',
    requestAlreadyResolved: 'requestAlreadyResolved',
    numberOfDaysTaken: 'numberOfDaysTaken',
    viewTimeOffMonthlySummary: 'viewTimeOffMonthlySummary',
    viewPolicySummary: 'viewPolicySummary',
    deactivatedUsers: 'deactivatedUsers',
    deactivatedPositions: 'deactivatedPositions',
    deactivatedPolicies: 'deactivatedPolicies',
    activate: 'activate',
    numberOfWorkingDays: 'numberOfWorkingDays',
    timeOffPolicy: 'timeOffPolicy',
    removeRequest: 'removeRequest',
    cancellationRequest: 'cancellationRequest',
    removeRequestText: 'removeRequestText',
    cancellationRequestText: 'cancellationRequestText',
    removeApproval: 'removeApproval',
    cancellationRequestApproval: 'cancellationRequestApproval',
    myTimeOffRequests: 'myTimeOffRequests',
    employeeTimeOffRequests: 'employeeTimeOffRequests',
    allTimeOffRequests: 'allTimeOffRequests',
    profile: "profile",
    noTimeOffs: "noTimeOffs",
    typeOfWork: 'typeOfWork',
    goingWith: 'goingWith',
    monthlySummary: 'monthlySummary',
    monthlySummaryDescription: 'monthlySummaryDescription',
    noDoneWorkOrdersThisMonth: 'noDoneWorkOrdersThisMonth',
    noWorkOrdersThisMonth: 'noWorkOrdersThisMonth',
    viewWorkOrderHistory: 'viewWorkOrderHistory',
    viewUserMonthlySummary: 'viewUserMonthlySummary',
    manageWorkOrders: 'manageWorkOrders',
    manageWorkOrdersLabel: 'manageWorkOrdersLabel',
    viewMore: 'viewMore',
    newWorkOrder: 'newWorkOrder',
    summary: 'summary',
    selectGroup: 'selectGroup',
    workOrdersTypes: 'workOrdersTypes',
    addworkOrderType: 'addworkOrderType',
    finishWorkOrderTypeCreation: 'finishWorkOrderTypeCreation',
    deleteWorkOrderTypeDescription: 'deleteWorkOrderTypeDescription',
    workOrderNumber: 'workOrderNumber',
    workOrderNumberPlaceholder: 'workOrderNumberPlaceholder',
    selectTypeOfWork: 'selectTypeOfWork',
    locationCode: 'locationCode',
    coordinates: 'coordinates',
    supervisors: 'supervisors',
    selectSupervisors: 'selectSupervisors',
    createWorkOrderLabel: 'createWorkOrderLabel',
    assignedTo: 'assignedTo',
    mapLinkLabel: 'mapLinkLabel',
    selectAssignees: 'selectAssignees',
    workOrderReceived: 'workOrderReceived',
    deviceToBeChanged: 'deviceToBeChanged',
    orderSentBy: 'orderSentBy',
    deviceOnLocation: 'deviceOnLocation',
    createWorkOrder: 'createWorkOrder',
    updateWorkOrder: 'updateWorkOrder',
    mustBeCompletedBy: 'mustBeCompletedBy',
    sameWorkOrderTypeName: 'sameWorkOrderTypeName',
    typeOfWorkNameEmpty: 'typeOfWorkNameEmpty',
    deactivatedTypesOfWork: 'deactivatedTypesOfWork',
    requestComment: 'requestComment',
    responseComment: 'responseComment',
    city: 'city',
    employeeDoesNotHaveApproverAssigned: 'employeeDoesNotHaveApproverAssigned',
    at: 'at',
    noNextWorkOrders: 'noNextWorkOrders',
    daysOff: 'daysOff',
    regularyPolicy: 'regularPolicy',
    filters: 'filters',
    displayInformation: 'displayInformation',
    showWorkOrders: 'showWorkOrders',
    default: 'default',
    showUsersWithWorkOrder: 'showUsersWithWorkOrder',
    showUsersWithWorkOrderDescription: 'showUsersWithWorkOrderDescription',
    showAvailableUsers: 'showAvailableUsers',
    showAvailableUsersDescription: 'showAvailableUsersDescription',
    showAllUsers: 'showAllUsers',
    showAllUsersDescription: 'showAllUsersDescription',
    saveFilters: 'saveFilters',
    currentSelectedFilter: 'currentSelectedFilter',
    meetingRooms: 'meetingRooms',
    finishMeetingRoomCreation: 'finishMeetingRoomCreation',
    addMeetingRoom: 'addMeetingRoom',
    deactivatedMeetingRooms: 'deactivatedMeetingRooms',
    meetingRoomNameEmpty: 'meetingRoomNameEmpty',
    sameMeetingRoomName: 'sameMeetingRoomName',
    approvesTimeOff: 'approvesTimeOff',
    selectRoom: 'selectRoom',
    reserveRoom: 'reserveRoom',
    meetingRoom: 'meetingRoom',
    meetingOrganizer: 'meetingOrganizer',
    title: 'title',
    addMeeting: 'addMeeting',
    personalNo: 'personalNo',
    passportNumber: 'passportNumber',
    passportValidUntil: 'passportValidUntil',
    contractExpiration: 'contractExpiration',
    idCardNumber: 'idCardNumber',
    idCardValidUntil: 'idCardValidUntil',
    doctorAppointment: 'doctorAppointment',
    certificateValidUntil: 'certificateValidUntil',
    dragAndDrop: 'dragAndDrop',
    medicalInsuranceNumber: 'medicalInsuranceNumber',
    religiousDay: 'religiousDay',
    employee: 'employee',
    newVersionPopup: 'newVersionPopup',
    scheduledMeetings: 'scheduledMeetings',
    noscheduledMeetings: 'noscheduledMeetings',
    upcomingBirthdays: 'upcomingBirthdays',
    noUpcomingBirthdays: 'noUpcomingBirthdays',
    additionalPersonalInformation: 'additionalPersonalInformation',
    basicInformationTitle: 'basicInformationTitle',
    positionsInCompany: 'positionsInCompany',
    present: 'present',
    documents: 'documents',
    copyPolicy: 'copyPolicy',
    accessToApplication: 'accessToApplication',
    copyBankHoliday: 'copyBankHoliday',
    addArticle: 'addArticle',
    articleTitle: 'articleTitle',
    articleText: 'articleText',
    deleteArticleDescription: 'deleteArticleDescription',
    editArticle: 'editArticle',
    userUpdatedSuccessfully: 'userUpdatedSuccessfully',
    upload: 'upload',
    maxFileSize: 'maxFileSize',
    acceptedExtensions: 'acceptedExtensions',
    fileValidationMessage: 'fileValidationMessage',
    duplicatefileValidationMessage: 'duplicatefileValidationMessage',
    openMap: 'openMap',
};