import { IRemoveRequestAlert } from "./IRemoveRequestAlertProps";
import { Typography, Dialog } from "@mui/material";
import { Box } from "@mui/system";
import Button from '@mui/material/Button';
import { CancelTimeOffRequest, RequestStatusEnum } from "../../../API/time-off-service";
import { RequestUserTableRow, useApprovedCancelRequest, useCancelRequest, useGetRequestsForUser } from "../../PendingRequests/action"
import React from "react";
import { SPACING_EXTRA_SMALL, SPACING_SMALL } from "../../../utils/cssUtils";
import { TranslationKeyEnum } from "../../../features/translations/TranslationKeyEnum";
import { useTranslation } from "react-i18next";

export default (props: IRemoveRequestAlert) => {
    const cancelRequest = useCancelRequest();
    const getRequestsforUser = useGetRequestsForUser();
    const [requestsForUserLoading, setRequestsForUserLoading] = React.useState<boolean>(true);
    const [requestsForUser, setRequestsForUser] = React.useState<RequestUserTableRow[]>([]);
    const cancelApprovedRequest = useApprovedCancelRequest();
    const { t } = useTranslation();

    const refreshRequestsForUser = async () => {
        setRequestsForUserLoading(true)
        setRequestsForUser(await getRequestsforUser())
        setRequestsForUserLoading(false)
    }

    const handleCancelRequest = async (request: CancelTimeOffRequest): Promise<void> => {
        if (props.requestStatus === RequestStatusEnum.Approved ? await cancelApprovedRequest(request) : await cancelRequest(request)) {
            await refreshRequestsForUser()
        }
        props.setIsOpen(false);
    }

    return (
        <Dialog open={props.isOpen} onClose={() => props.setIsOpen(false)} maxWidth="sm" >
            <Box display = "flex" flexDirection="column" gap={2} sx={{padding: SPACING_SMALL}}>
                <Box>
                    <Typography variant="h6">{props.title}</Typography>
                </Box>
                <Box >
                    <Typography variant="body2">{props.question}</Typography>
                </Box>
                <Box marginLeft="auto">
                    <Button color="inherit" onClick={() => props.setIsOpen(false)}>{t(TranslationKeyEnum.cancel)}</Button>
                    <Button color={props.buttonColor} variant="contained" onClick={() => handleCancelRequest({ id: props.cellValues.id, relatedRequestId: props.cellValues.row.relatedId } as CancelTimeOffRequest)}>{props.affirmationMessage}</Button>
                </Box>
            </Box>
        </Dialog >
    );
}