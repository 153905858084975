import { useState } from "react";
import { MainInfoForm } from "./MainInfoForm/MainInfoForm";
import { ICreateUserFormProps } from "./ICreateUserFormProps";
import { Stepper, Step, StepLabel, Box, Dialog, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import { TranslationKeyEnum } from "../../features/translations/TranslationKeyEnum";
import { UserResponse } from "../../API/user-management-service";
import { ICON_COLOR, SPACING_EXTRA_SMALL, SPACING_SMALL } from "../../utils/cssUtils";
import CloseIcon from '@mui/icons-material/Close';
import AdditionalInfoForm from "./AdditionalInfoForm/AdditionalInfoForm";
import PermissionsForm from "./PermissionsForm/PermissionsForm";
import VacationForm from "./VacationForm/VacationForm";
import { StatusEnum } from "../../API/identity-service";

const CREATE_USER_FORM_FIRST_STEP = 0;

function CreateUserFormDialog({ isCreateUserFormOpen, closeCreateUserForm, rerender, setRerender }: ICreateUserFormProps) {
    const { t } = useTranslation();
    const CREATE_USER_STEPS = [t(TranslationKeyEnum.mainInfo), t(TranslationKeyEnum.additionalInfo), t(TranslationKeyEnum.permissions), t(TranslationKeyEnum.vacation)];

    const [user, setUser] = useState({} as UserResponse);
    const [activeStep, setActiveStep] = useState(CREATE_USER_FORM_FIRST_STEP);

    async function handleMainFormSubmit(userId: string, firstName: string, lastName: string, email: string, status: StatusEnum) {
        setUser({ userId: userId, firstName: firstName, lastName: lastName, email: email, status: status } as UserResponse);
        handleNext();
    }

    function handleNext() {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    function handleCloseCreateUserForm() {
        setRerender(!rerender);
        closeCreateUserForm();
        setActiveStep(CREATE_USER_FORM_FIRST_STEP);
    }

    const getMatchingStepHeader = (step: number): string => {
        switch (step) {
            case 0: return t(TranslationKeyEnum.mainInfo)
            case 1: return t(TranslationKeyEnum.additionalInfo)
            case 2: return t(TranslationKeyEnum.permissions)
            case 3: return t(TranslationKeyEnum.vacation)
            default: return t(TranslationKeyEnum.addEmployee)
        }
    } 

    function showMatchingForm(activeStep: number) {
        switch (activeStep) {
            case 0:
                return <MainInfoForm handleMainFormSubmit={handleMainFormSubmit}/>
            case 1:
                return <AdditionalInfoForm user={user} goToNextStep={handleNext}/>
            case 2:
                return <PermissionsForm user={user} goToNextStep={handleNext}/>
            case 3:
                return <VacationForm user={user} closeCreateUserForm={handleCloseCreateUserForm} />
            default:
                break;
        }
    }

    return (
        <Dialog open={isCreateUserFormOpen}>
            <Box position="absolute" right="0" padding={SPACING_SMALL} sx={{ cursor: "pointer" }} onClick={closeCreateUserForm}>
                <CloseIcon sx={{ color: ICON_COLOR }} />
            </Box>
            <Box width={{xl: "50vw", lg: "50vw", md: "60vw", sm: "70vw", xs: "90vw"}} display="flex" flexDirection="column" gap={SPACING_SMALL} padding={SPACING_SMALL}>
                <Typography variant="h5" paddingLeft={SPACING_EXTRA_SMALL}>{getMatchingStepHeader(activeStep)}</Typography>
                <Stepper activeStep={activeStep}>
                    {CREATE_USER_STEPS.map((label) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: {
                            optional?: React.ReactNode;
                        } = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {showMatchingForm(activeStep)}
            </Box>
        </Dialog>
    )
}

export default CreateUserFormDialog;