import { useTryCatchJsonByAction } from "../utils/fetchUtils";
import { OfficeClient, OfficeRequest, OfficeResponse, ResponseMessage, UpdateOfficeRequest } from "./user-management-service";

const officeClient = new OfficeClient();

export function useGetOffices(): () => Promise<OfficeResponse[]> {
	const tryCatchAction = useTryCatchJsonByAction();
	async function getOffices(): Promise<OfficeResponse[]> {
		const bindedAction = getOfficesAction.bind(null);
		const getOffices = await tryCatchAction(bindedAction);
		return getOffices ? getOffices.response : [];
	}
	return getOffices;
}

const getOfficesAction = (): Promise<OfficeResponse[]> => {
	return officeClient.getAll();
}

export function useCreateOffice(): (office: OfficeRequest) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleCreateOffice(office: OfficeRequest): Promise<boolean> {
        const bindedAction = createOfficeAction.bind(null, office);
        const createOffice = await tryCatchAction(bindedAction);        
        return createOffice.success;
    }
    return handleCreateOffice;
}

const createOfficeAction = (position: OfficeRequest): Promise<ResponseMessage> => {
    return officeClient.insert(position);
}

export function useUpdateOffice(): (office: UpdateOfficeRequest) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleUpdateOffice(office: UpdateOfficeRequest): Promise<boolean> {
        const bindedAction = updateOfficeAction.bind(null, office);
        const updateOffice = await tryCatchAction(bindedAction);        
        return updateOffice.success;
    }
    return handleUpdateOffice;
}

const updateOfficeAction = (office: UpdateOfficeRequest): Promise<ResponseMessage> => {
    return officeClient.update(office);
}

export function useDeleteOffice(): (officeId: string) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function deleteOffice(officeId: string): Promise<boolean> {
        const bindedAction = deleteOfficeAction.bind(null, officeId);
        let getDeleteResponse = await tryCatchAction(bindedAction);
        return getDeleteResponse.success;
    }

    return deleteOffice;
}

const deleteOfficeAction = (officeId: string): Promise<ResponseMessage> => {
    return officeClient.remove(officeId);
}