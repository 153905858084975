import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import i18n from "../../features/translations/i18n";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";
import { TranslationKeyEnum } from "../../features/translations/TranslationKeyEnum";
import { Stack } from "@mui/material";
import { GridColDef, GridPaginationModel, GridRowSelectionModel } from "@mui/x-data-grid";
import { useGetAllUsers } from "../../API/user-actions";
import { Box } from "@mui/material";
import { IAddUserFormProps } from "./IAddUserFormProps";
import { IEmployeesTableProps } from "../AdminEmployees/IEmployeesTableProps";
import { ROWS_PER_PAGE_OPTIONS } from "../common/Constants/PaginationConstants";
import { LanguageEnum } from "../../features/translations/LanguageEnum";
import { srRS } from "../../features/translations/DataGrid/srRS";
import { StyledDataGrid } from "../StyledDataGrid/StyledDataGrid";
import { StyledTextField } from '../StyledTextfield/StyledTextField';
import { ICON_COLOR, SPACING_SMALL } from '../../utils/cssUtils';

export default function AddUserFormDialog(props: IAddUserFormProps) {
    const { t } = useTranslation();
    const { onClose, open, onSubmit, policyUsers, numberOfDays, onNumberChange, infiniteDays } = props;
    const [users, setUsers] = React.useState<IEmployeesTableProps[]>();
    const getUsers = useGetAllUsers();
    const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const [page, setPage] = React.useState<number>(0);
    const [pageSize, setPageSize] = React.useState<number>(ROWS_PER_PAGE_OPTIONS[0]);
    const [isUsersDataLoading, setIsUSerDataLoading] = React.useState<boolean>(true);

    const handlePaginationModelChange = (model: GridPaginationModel) => {
        setPage(model.page);
        setPageSize(model.pageSize);
    }

    const handleClose = () => {
        onClose();
    };

    const handleSubmit = () => {
        onSubmit(selectionModel as string[]);
    };

    React.useEffect(() => {
        const fetchData = async () => {
            let allUsers = await getUsers();
            allUsers = allUsers ? allUsers.filter(x => !policyUsers.includes(x.id ?? "")) : [];
            setUsers(allUsers);
            setIsUSerDataLoading(false);
        }
        fetchData();
    }, [policyUsers])

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID' },
        { field: 'firstName', headerName: t(TranslationKeyEnum.firstName), flex: 1 },
        { field: 'lastName', headerName: t(TranslationKeyEnum.lastName), flex: 1 },
        { field: 'position', headerName: t(TranslationKeyEnum.position), flex: 1 },
    ];

    return (
        <Box>
            <Dialog open={open} onClose={handleClose} maxWidth={'lg'} fullWidth={true}>
                <Box position="absolute" right="0" padding={SPACING_SMALL} sx={{ cursor: "pointer" }} onClick={handleClose}>
                    <CloseIcon sx={{ color: ICON_COLOR }} />
                </Box>
                <DialogTitle>{t(TranslationKeyEnum.addUser)}</DialogTitle>
                <DialogContent>
                    <Stack spacing={1}>
                        <caption>{t(TranslationKeyEnum.users)}</caption>
                        <StyledDataGrid
                            columnVisibilityModel={{
                                id: false,
                            }}
                            localeText={i18n.language === LanguageEnum.Serbian ? srRS.components.MuiDataGrid.defaultProps.localeText : undefined}
                            rows={users ?? []}
                            autoHeight={true}
                            columns={columns}
                            paginationModel={{ page, pageSize }}
                            onPaginationModelChange={handlePaginationModelChange}
                            pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
                            checkboxSelection
                            onRowSelectionModelChange={(newSelectionModel) => { setSelectionModel(newSelectionModel) }}
                            rowSelectionModel={selectionModel}
                            loading={isUsersDataLoading}
                        />
                        {!infiniteDays && <StyledTextField
                            label={t(TranslationKeyEnum.daysChange)}
                            variant="outlined"
                            style={{ width: "26%", marginTop: SPACING_SMALL }}
                            type={'number'}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={numberOfDays}
                            onChange={(e) => onNumberChange(e.target.value)}
                        />}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t(TranslationKeyEnum.cancel)}</Button>
                    <Button onClick={handleSubmit}>{t(TranslationKeyEnum.ok)}</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}