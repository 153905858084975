import NotificationCenter from '../../components/NotificationCenter/NotificationCenter';
import UpcomingTimeOff from './UpcomingTimeOff/UpcomingTimeOff';
import WelcomeBackCard from './WelcomeBackCard';
import ScheduledMeetings from './Cards/ScheduledMeetings';
import UpcomingBirthdays from './Cards/UpcomingBirthdays';
import { Grid } from '@mui/material';
import { WorkOrdersCard } from './Cards/WorkOrdersCard';
import { SPACING_EXTRA_SMALL } from '../../utils/cssUtils';
import { NewsFeed } from './Cards/NewsFeed';
import { UpcomingHolidaysCard } from './Cards/UpcomingHolidaysCard';

export default function Home() {
    return (
        <Grid container>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12} padding={SPACING_EXTRA_SMALL}>
                <WelcomeBackCard />
                <WorkOrdersCard />
                <ScheduledMeetings />
                <UpcomingBirthdays />
            </Grid>
            {/* Upcoming Events, Notifications, News Feed */}
            <Grid item xl={8} lg={8} md={6} sm={6} xs={12} >
                {/* Upcoming Events & Notification Grid */}
                <Grid container >
                    {/* Upcoming Events Grid */}
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12} padding={SPACING_EXTRA_SMALL}>
                        <UpcomingTimeOff />
                        <UpcomingHolidaysCard />
                    </Grid>
                    {/* Notifcations Grid */}
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12} padding={SPACING_EXTRA_SMALL}>
                        <NotificationCenter />
                    </Grid>
                </Grid>
                {/* News Feed Grid */}
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} padding={SPACING_EXTRA_SMALL}>
                    <NewsFeed />
                </Grid>
            </Grid>
        </Grid>

    )
}