import React from 'react';
import Avatar from '@mui/material/Avatar';
import EditPasswordDialog from '../../EditPassword/EditPasswordDialog';
import PersonIcon from '@mui/icons-material/Person';
import { Grid, Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BORDER_RADIUS_MEDIUM, LIGHT_BORDER, CARD_BOX_SHADOW, LINE_BORDER, SPACING_EXTRA_LARGE, SPACING_LARGE, SPACING_MEDIUM, SPACING_SMALL, APP_BG_COLOR } from '../../../utils/cssUtils';
import { TranslationKeyEnum } from '../../../features/translations/TranslationKeyEnum';
import { useGetTimeOffUser } from '../../../API/time-off-actions';
import { useGetApprover } from '../../../API/user-actions';
import { IUserProps } from '../../../components/common/interfaces/IUserProps';
import { UserDto } from '../../../API/time-off-service';
import { IWorkInfo } from './IWorkInfo';
import { StyledReadonlyTextField } from '../../../components/StyledReadonlyTextField/StyledReadonlyTextField';
import { getTextTransformationAccordingToLanguage } from '../../../features/translations/helpers';
import { getUserIdFromStorage } from '../../../utils/storageUtils/storageUtils';
import { PositionDto } from '../../../API/user-management-service';
import { getDayAndMonthName, getMonthNameAndYear, isDateYearOne } from '../../../utils/dateUtils';
import { toStringDDMMYYYY } from '../../../components/common/Calendar/helpers';

export default function WorkInfo({ id, firstName, lastName, image, position, email, office, phoneNumber, dateOfBirth, positions }: IWorkInfo) {
    const { t } = useTranslation();
    const getApprover = useGetApprover();
    const getTimeOffUser = useGetTimeOffUser();

    const [timeOffUser, setTimeOffUser] = React.useState<UserDto>();
    const [approver, setApprover] = React.useState<IUserProps>();
    const [isPasswordDialogOpen, setIsPasswordDialogOpen] = React.useState(false);

    const handlePasswordDialogOpen = () => {
        setIsPasswordDialogOpen(true);
    };

    const handlePasswordDialogClose = () => {
        setIsPasswordDialogOpen(false);
    };

    React.useEffect(() => {
        const fetchUser = async () => {
            if (!id) return;
            let user = await getTimeOffUser(id);
            if (!user) return;
            setTimeOffUser(user as UserDto);
        }
        fetchUser();
    }, [id])

    React.useEffect(() => {
        const fetchUser = async () => {
            if (!timeOffUser?.approverId) return;
            let user = await getApprover(timeOffUser.approverId);
            if (!user) return;
            setApprover(user);
        }
        fetchUser();
    }, [timeOffUser])

    const getApproverFullName = (firstName: string | undefined, lastName: string | undefined): string => {
        return (firstName && lastName) ? `${firstName} ${lastName}` : "";
    }

    return (
        <>
            {/* Image & Fullname */}
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} display="flex" justifyContent="center" alignItems="center" borderBottom={LIGHT_BORDER} padding={SPACING_SMALL}>
                <Box display="flex" >
                    <Avatar sx={{ width: SPACING_MEDIUM, height: SPACING_MEDIUM }} variant="rounded" src={image && `data:image/png;base64,${image}`} alt="user image" />
                    <Typography variant="body1" marginLeft={SPACING_SMALL} display="flex" alignItems="center">{firstName} {lastName}</Typography>
                </Box>
            </Grid>
            {/* Work Information */}
            <Grid container padding={SPACING_LARGE}>
                {/* Labels */}
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <PersonIcon color="primary" fontSize="large" sx={{ marginBottom: SPACING_SMALL }} />
                    <Typography variant="h5" marginBottom={SPACING_SMALL}>{t(TranslationKeyEnum.basicInformationTitle)}</Typography>
                    <Typography variant="body2" marginBottom={SPACING_SMALL}>{t(TranslationKeyEnum.basicInformation)}</Typography>
                    <Box width={SPACING_EXTRA_LARGE} border={LINE_BORDER} marginBottom={SPACING_SMALL} />
                    {
                        id === getUserIdFromStorage() &&
                        <Button variant="contained" sx={{ textTransform: getTextTransformationAccordingToLanguage() }} onClick={handlePasswordDialogOpen}>
                            {t(TranslationKeyEnum.changePassword)}
                        </Button>
                    }
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12} display="flex" justifyContent="center" marginTop={SPACING_LARGE}>
                    <Box width="90%" minWidth="50%" display="flex" justifyContent="center" borderRadius={BORDER_RADIUS_MEDIUM} boxShadow={CARD_BOX_SHADOW} sx={{ backgroundColor: APP_BG_COLOR }}
                        marginTop={{ xl: "0", lg: "0", md: "0", sm: SPACING_LARGE, xs: SPACING_LARGE }}>
                        <Avatar sx={{ width: "12vh", height: "12vh", borderRadius: "16px", transform: "translateY(-50%)", position: "absolute" }} src={image && `data:image/png;base64,${image}`} alt="user image" />
                        <Box marginTop={SPACING_LARGE} width="90%">
                            {/* Fullname and role */}
                            <Typography variant="h6" textAlign="center">{firstName} {lastName}</Typography>
                            <Typography variant="body1" textAlign="center" color="gray">{position}</Typography>
                            {/* Approver, Email & Location */}
                            <Box marginTop={SPACING_MEDIUM}>
                            </Box>
                            <Box display="flex" flexDirection="column">
                                <StyledReadonlyTextField
                                    id="office"
                                    label={t(TranslationKeyEnum.office)}
                                    value={office}
                                    inputProps={{ disabled: true }}
                                    sx={{ marginBottom: SPACING_MEDIUM }}
                                />
                                <StyledReadonlyTextField
                                    id="approver"
                                    label={t(TranslationKeyEnum.approvesTimeOff)}
                                    value={getApproverFullName(approver?.firstName, approver?.lastName)}
                                    inputProps={{ disabled: true }}
                                    sx={{ marginBottom: SPACING_MEDIUM }}
                                />
                                <StyledReadonlyTextField
                                    id="email"
                                    label={t(TranslationKeyEnum.email)}
                                    value={email}
                                    inputProps={{ disabled: true }}
                                    sx={{ marginBottom: SPACING_MEDIUM }}
                                />
                                <StyledReadonlyTextField
                                    id="phoneNumber"
                                    label={t(TranslationKeyEnum.phoneNumber)}
                                    value={phoneNumber}
                                    inputProps={{ readOnly: true, disabled: true }}
                                    sx={{ marginBottom: SPACING_MEDIUM }}
                                />
                                <StyledReadonlyTextField
                                    id="dateOfBirth"
                                    label={t(TranslationKeyEnum.dateOfBirth)}
                                    value={getDayAndMonthName(new Date(dateOfBirth))}
                                    inputProps={{ readOnly: true, disabled: true }}
                                    sx={{ marginBottom: SPACING_MEDIUM }}
                                />
                                <Box marginBottom="5px">
                                    <Typography variant="h5">{t(TranslationKeyEnum.positionsInCompany)}: </Typography>
                                </Box>
                                <Box marginBottom="5px">
                                    {
                                        positions && positions.map((position: PositionDto) => {
                                            return <Typography data-test-id="position">{position.name}  {toStringDDMMYYYY(new Date(position.startDate!))} - {(!(isDateYearOne(position.endDate!))) ? getMonthNameAndYear(new Date(position.endDate!)) : t(TranslationKeyEnum.present)}</Typography>
                                        })
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            {/* Change Password */}
            {
                isPasswordDialogOpen && <EditPasswordDialog open={isPasswordDialogOpen} onClose={handlePasswordDialogClose} />
            }
        </>
    )
}