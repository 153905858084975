import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { TranslationKeyEnum } from "../../features/translations/TranslationKeyEnum";

export function useAddEditGroupFormValidationSchema() {
    const { t } = useTranslation();

    return Yup.object({
        membersCount: Yup.number(),
        name: Yup.string().required(t(TranslationKeyEnum.required)),
        mustBePresent: Yup
            .number()
            .test('isLess', t(TranslationKeyEnum.mustBePresentError), (value, testContext) => testContext.parent.membersCount === 0 || testContext.parent.membersCount > value!)
    })
}