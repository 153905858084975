import CalendarIcon from '../CalendarIcon/CalendarIcon'
import { Box, Typography, Tooltip } from '@mui/material'
import { MONTH_AND_DATE_MINI_CALENDAR_MIN_WIDTH, SPACING_EXTRA_SMALL, SPACING_SMALL } from '../../../utils/cssUtils'
import { useTranslation } from 'react-i18next'
import { TranslationKeyEnum } from '../../../features/translations/TranslationKeyEnum'
import { IOneDayTimeOffCard } from './IOneDayTimeOffCard'

export default function OneDayTimeOffCard({ startDate, policyName }: IOneDayTimeOffCard) {
    const { t } = useTranslation();

    return (
        <Box display="flex" flexDirection="row" gap={{ xl: SPACING_EXTRA_SMALL, lg: SPACING_SMALL, md: SPACING_SMALL, sm: SPACING_EXTRA_SMALL, xs: SPACING_EXTRA_SMALL }}>
            <Box minWidth={MONTH_AND_DATE_MINI_CALENDAR_MIN_WIDTH} width={{ xl: "20%", lg: "24%", md: "30%", sm: "24%", xs: "28%" }}>
                <CalendarIcon date={startDate} />
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" width={{ xl: "80%", lg: "76%", md: "70%", sm: "76%", xs: "72%" }} >
                <Tooltip title={policyName} placement="top-start">
                    <Typography variant="h6" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" sx={{ cursor: "pointer" }}>
                        {policyName}
                    </Typography>
                </Tooltip>
                <Typography variant="body2">1 {t(TranslationKeyEnum.workDay)} </Typography>
            </Box>
        </Box>
    )
}