import { FormikConfig, FormikValues } from "formik";
import { useArticleSchema } from "./useArticleSchema";
import { ArticleDto } from "../../API/user-management-service";

export function useArticleFormikForm(): (handleSubmit: () => void, article?: ArticleDto) => FormikConfig<FormikValues> {
    const articleValidationSchema = useArticleSchema();
    function additionalInfoFormikForm(handleSubmit: () => void, article?: ArticleDto): FormikConfig<FormikValues> {
        return {
            initialValues: {
                id: article?.id ?? "",
                title: article?.title ?? "",
                description: article?.description ?? "",
                body: article?.body ?? "",
            },
            validationSchema: articleValidationSchema,
            onSubmit: handleSubmit,
            enableReinitialize: true,
        };
    }
    return additionalInfoFormikForm;
}