import { useGetDeactivatedUsers as useGetDeactivatedUsersAction, useGetUsers } from "../../API/user-actions";
import { UserResponse } from "../../API/user-management-service";
import { isDateYearOne } from "../../utils/dateUtils";
import { IEmployeesTableProps } from "./IEmployeesTableProps";

export function useGetActiveUsers(): () => Promise<IEmployeesTableProps[]> {
    const getUsers = useGetUsers();
    async function getActiveUsers(): Promise<IEmployeesTableProps[]> {
        const allEmployees: UserResponse[] = await getUsers();
        const data = mapTableEmployees(allEmployees);
        return data ? data : [];
    }
    return getActiveUsers;
}

export function useGetDeactivatedUsers(): () => Promise<IEmployeesTableProps[]> {
    const getDeactivatedUsersAction = useGetDeactivatedUsersAction();
    async function getDeactivatedUsers(): Promise<IEmployeesTableProps[]> {
        const deactivatedUsers: UserResponse[] = await getDeactivatedUsersAction();
        const data = mapTableEmployees(deactivatedUsers);
        return data ? data : [];
    }
    return getDeactivatedUsers;
}

const mapTableEmployees = (employees: UserResponse[]): IEmployeesTableProps[] => {
    return employees?.map((emp: UserResponse) => {
        return {
            id: emp.userId,
            image: emp.image,
            firstName: emp.firstName,
            lastName: emp.lastName,
            email: emp.email,
            currentPosition: emp.positions?.find(x => x.endDate == null || isDateYearOne(x.endDate))?.name,
            role: emp.role,
            office: emp.office
        } as IEmployeesTableProps;
    })
}