import ConstructionIcon from '@mui/icons-material/Construction';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import CancelIcon from '@mui/icons-material/Cancel';
import { Status } from "../../API/work-order-service"
import { Chip } from '@mui/material';

export const workOrdersStatusSwitch = (value: string) => {
    switch (value) {
        case Status.ToDo:
            // if status is ToDo or InProgress we put sapce between those two words
            return <Chip label={value?.match(/[A-Z][a-z]+|[0-9]+/g)?.join(" ")} variant="outlined" sx={{ borderColor: "black" }} icon={<ConstructionIcon />} />;
        case Status.InProgress:
            return <Chip label={value?.match(/[A-Z][a-z]+|[0-9]+/g)?.join(" ")} variant="outlined" sx={{ color: "purple", borderColor: "purple" }} icon={<AccessTimeIcon style={{ color: "purple" }} />} />;
        case Status.Done:
            return <Chip label={value} variant="outlined" color="success" icon={<CheckCircleIcon />} />
        case Status.Invoiced:
            return <Chip label={value} variant="outlined" color="primary" icon={<PriceCheckIcon />} />
        case Status.Cancelled:
            return <Chip label={value} variant="outlined" color="error" icon={<CancelIcon />} />
        default:
            break;
    }
}