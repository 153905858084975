import * as React from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import ReadOnlyCalendar from '../../components/ReadOnlyCalendar/ReadOnlyCalendar';
import ReadOnlyWeekCalendar from '../../components/ReadOnlyCalendar/ReadOnlyWeekCalendar';
import MeetingCalendar from '../../components/MeetingCalendar/MeetingCalendar';
import { TabPanel } from '../../components/common/TabPanel/TabPanel';
import { useParams } from 'react-router-dom';
import { TranslationKeyEnum } from '../../features/translations/TranslationKeyEnum';
import { useTranslation } from 'react-i18next';

export default function CalendarContainer() {
    const { t } = useTranslation();
    const params = useParams();
    const [value, setValue] = React.useState(0);
    const [meetingId, setMeetingId] = React.useState("");
    const [roomId, setRoomId] = React.useState("");

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        params.tab && setValue(parseInt(params.tab))
        params.meetingId && setMeetingId(params.meetingId);
        params.roomId && setRoomId(params.roomId);
    }, [params])

    return (
        <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="icon position tabs example"
                >
                    <Tab icon={<BeachAccessIcon />} iconPosition="start" label={t(TranslationKeyEnum.timeOffTab)} />
                    <Tab icon={<ContentPasteGoIcon />} iconPosition="start" label={t(TranslationKeyEnum.workOrdersTab)} />
                    <Tab icon={<MeetingRoomIcon />} iconPosition="start" label={t(TranslationKeyEnum.meetingRooms)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <ReadOnlyCalendar />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ReadOnlyWeekCalendar numberOfDaysDisplayed={7} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <MeetingCalendar meetingId={meetingId && meetingId} roomId={roomId} resetMeetingIdValue={() => setMeetingId("")} />
            </TabPanel>
        </Box>
    );
}
