import { Box, Checkbox, FormControl, ListItemText, MenuItem, Select, Tooltip } from '@mui/material'
import { InputLabel } from '@mui/material';
import { IPermissionFormProps } from './IPermissionFormProps';
import { MenuProps } from './permissionFormManuProps';
import { TranslationKeyEnum } from '../../../features/translations/TranslationKeyEnum';
import { useTranslation } from 'react-i18next';
import { FormikValues, useFormik } from 'formik';
import { usePermissionInfoFormikForm } from './permissionFormikForm';
import { PolicyDto, StatusEnum } from '../../../API/identity-service';
import { getPermissionsIds, useGetPermissions, useUpdateIdentityUser } from '../../../API/identity-actions';
import { SPACING_SMALL } from '../../../utils/cssUtils';
import { getLanguage } from '../../../features/translations/i18n';
import React from 'react';
import DropdownErrorText from '../../DropdownErrorText/DropdownErrorText';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import StepperFormFooter from '../StepperFormFooter/FormFooter';
import Loader from '../../Loader/Loader';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

function PermissionsForm({ user, goToNextStep }: IPermissionFormProps) {
    const { t } = useTranslation();
    const getPermissions = useGetPermissions();
    const updateUserPermissions = useUpdateIdentityUser();
    const lang = getLanguage();

    const [permissions, setPermissions] = React.useState<PolicyDto[]>([]);
    const [isPermissionsDataLoading, setIsPermissionsDataLoading] = React.useState(true);
    const [isFormLoading, setIsFormLoading] = React.useState(false);

    const formikPermissionInfo = usePermissionInfoFormikForm();
    const permissionInfoForm: FormikValues = useFormik(formikPermissionInfo(handleSubmit))

    React.useEffect(() => {
        const fetchData = async () => {
            let fetchPermissions = await getPermissions();
            fetchPermissions && setPermissions(fetchPermissions);
            setIsPermissionsDataLoading(false);
        }
        fetchData();
    }, [])

    async function handleSubmit() {
        let permissionsIds = getPermissionsIds(permissionInfoForm.values.permissions, permissions);
        setIsFormLoading(true);
        let assignPolicy = user.userId && (await updateUserPermissions(user.userId, permissionsIds, user.status ?? StatusEnum.Active));
        setIsFormLoading(false);
        assignPolicy && goToNextStep();
    }

    return (
        <form onSubmit={permissionInfoForm.handleSubmit}>
            <FormControl data-test-id="permissionDropdown" sx={{ margin: `${SPACING_SMALL} 0`, width: "100%" }}>
                <InputLabel>{t(TranslationKeyEnum.selectEmployeePermissions)}</InputLabel>
                <Select
                    id="permissions"
                    labelId="permissions"
                    name="permissions"
                    multiple
                    value={permissionInfoForm.values.permissions}
                    onChange={permissionInfoForm.handleChange}
                    error={permissionInfoForm.touched.permissions && Boolean(permissionInfoForm.errors.permissions)}
                    input={<OutlinedInput id="select-multiple-chip" label={t(TranslationKeyEnum.selectEmployeePermissions)} />}
                    renderValue={selected => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value: string) => (
                                <Chip key={value} label={value} />
                            ))
                            }
                        </Box>
                    )}
                    MenuProps={MenuProps}
                    disabled={isFormLoading}
                >
                    {
                        isPermissionsDataLoading
                            ? <Box height={30}><Loader size={30} /></Box>
                            : permissions?.map((permission: PolicyDto) => (
                                <MenuItem key={permission.id} value={JSON.parse(permission.title!)[lang]}>
                                    <Tooltip title={JSON.parse(permission.description!)[lang]} placement="top">
                                        <Box display="flex" alignItems="center" width="100%">
                                            <Checkbox checked={permissionInfoForm.values.permissions.indexOf(JSON.parse(permission.title!)[lang]) > -1} />
                                            <ListItemText primary={JSON.parse(permission.title!)[lang]} />
                                        </Box>
                                    </Tooltip>
                                </MenuItem>
                            ))}
                </Select>
            </FormControl>
            {permissionInfoForm.touched.permissions && Boolean(permissionInfoForm.errors.permissions) && <DropdownErrorText marginLeft="17%" />}
            {isFormLoading && <Loader size={50} />}
            <StepperFormFooter isFormLoading={isFormLoading} secondButtonLabel={t(TranslationKeyEnum.next)} secondButtonIcon={<KeyboardArrowRight />} />
        </form>
    )
}

export default PermissionsForm;