import styled from "@emotion/styled";
import { DataGrid } from "@mui/x-data-grid";

export const StyledDataGrid = styled(DataGrid)({
    "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
        display: "none",
    },
    "& .MuiDataGrid-columnHeader:focus-within": {
        outline: "none",
    },
    "& .MuiDataGrid-cell:focus-within": {
        outline: "none",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: "bold",
    },
});