import { RequestStatusEnum } from "../../../API/time-off-service";

export enum CalendarItemType {
    Avaiable = "Avaiable",
    TimeOff = "TimeOff",
    WorkOrder = "WorkOrder",
}

export interface ICalendarProps {
    initialDate?: string,
    events: ICalendarEvent[],
    selectable?: boolean,
    selectDateRange?: (start: Date, end: Date) => void
}

export interface ICalendarEvent {
    id: string,
    title: string,
    start: string,
    end: string,
    color: string,
    groupIds?: string[],
    userId?: string,
    status?: RequestStatusEnum;
    textColor?: string,
    display?: string,
    classNames?: string[],
    type?: CalendarItemType,
}