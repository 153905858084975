import Login from "../containers/Login/Login";
import AppContainer from "../containers/AppContainer/AppContainer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MeetingCalendar from "../components/MeetingCalendar/MeetingCalendar";
import { routeEnum } from "./routeEnum";
import ReadOnlyExternalViewCalendar from "../components/ReadOnlyCalendar/ReadOnlyExternalViewCalendar";

function AppRouter() {
  return (
	<>
		<BrowserRouter>
			<Routes>
          	 	<Route index element={<Login/>}/>
				<Route path="/*" element={<AppContainer/>}/>
				<Route path={`/${[routeEnum.MEETING_ROOMS_VIEW]}`} element={<MeetingCalendar/>}/>
				<Route path={`/${[routeEnum.WAREHOUSE_VIEW]}`} element={<ReadOnlyExternalViewCalendar numberOfDaysDisplayed={7}/>}/>
    		</Routes>
		</BrowserRouter>
	</>
	)
}

export default AppRouter;