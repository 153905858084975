import { useCreatePosition, useDeletePosition, useUpdatePosition } from "../../API/position-actions";
import { useTranslation } from "react-i18next";
import { TranslationKeyEnum } from "../../features/translations/TranslationKeyEnum";
import { Typography, Box, Tooltip, Button } from "@mui/material";
import { GridActionsCellItem, GridColDef, GridRowId, GridRowModes, GridRowModesModel, GridToolbarContainer } from '@mui/x-data-grid';
import { PositionResponse, PositionStatusEnum } from "../../API/user-management-service";
import { IPositionTableProps } from "./IPositionTableProps";
import { useErrorNotification } from "../../utils/fetchUtils";
import { useGetActivePositions } from "./actions";
import { CARD_BOX_SHADOW, SPACING_EXTRA_SMALL } from "../../utils/cssUtils";
import { getTextTransformationAccordingToLanguage } from '../../features/translations/helpers';
import React from "react";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import EditableDataGrid from "../EditableDataGrid/EditableDataGrid";
import ConfirmDialog from "../Dialogs/ConfirmDialog/ConfirmDialog";
import DeactivatedPositions from "./DeactivatedPositions";

const FIRST_PAGE = 0;
const MINIMAL_POSIITON_NAME_LENGHT = 0;
const EMPTY_POSITION_ID = '';
const EMPTY_POSITION_NAME = '';

function AdminPositions() {
    const { t } = useTranslation();
    const getPositions = useGetActivePositions();
    const createPosition = useCreatePosition();
    const updatePosition = useUpdatePosition();
    const deletePosition = useDeletePosition();
    const showErrorNotification = useErrorNotification();

    const [id, setId] = React.useState("");
    const [page, setPage] = React.useState(FIRST_PAGE);
    const [rows, setRows] = React.useState<IPositionTableProps[]>([]);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [editedRow, setEditedRow] = React.useState<IPositionTableProps>();
    const [rerender, setRerender] = React.useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
    const [isDeleteApproved, setIsDeleteApproved] = React.useState(false);
    const [isPositionsDataLoading, setIsPositionsDataLoading] = React.useState(true);

    React.useEffect(() => {
        const fetchData = async () => {
            setRows(await getPositions());
            setIsPositionsDataLoading(false);
        }
        fetchData();
    }, [rerender]);

    React.useEffect(() => {
        const deleteData = async () => {
            await deletePosition(id.toString());
            setRerender(!rerender);
            // setting id to empty string so we are not accessing id of the deleted position which will cause error
            setId(EMPTY_POSITION_ID);
            // setting value to false so user can try to delete another position, otherwise nothing will happen because value of isDeleteApproved is true
            setIsDeleteApproved(false);
        }
        isDeleteApproved && deleteData();
    }, [isDeleteApproved]);

    const AddToolbar = () => {
        const handleClick = () => {
            // returning user to first page because we are adding new created position to start of the array
            page > FIRST_PAGE && setPage(FIRST_PAGE);
            // checking if there is some position being created because only one position can be created at a time
            // if there is no position being created we are adding one empty position and setting its mode to edit
            if (rows.every((position: IPositionTableProps) => position.name !== EMPTY_POSITION_NAME)) {
                setRows((oldRows) => [{ id: EMPTY_POSITION_ID, name: EMPTY_POSITION_NAME, status: PositionStatusEnum.Active, isNew: true }, ...oldRows,]);
                setRowModesModel((oldModel) => ({ ...oldModel, [EMPTY_POSITION_ID]: { mode: GridRowModes.Edit, fieldToFocus: 'name' } }));
            } else showErrorNotification(t(TranslationKeyEnum.positionNameEmpty));
        }

        return (
            <GridToolbarContainer sx={{ padding: SPACING_EXTRA_SMALL }}>
                <Tooltip title={t(TranslationKeyEnum.add)}>
                    <Button variant="contained" size="medium" startIcon={<AddIcon />} sx={{ marginLeft: "auto", textTransform: getTextTransformationAccordingToLanguage() }} onClick={handleClick} disabled={isPositionsDataLoading}>
                        {t(TranslationKeyEnum.addPosition)}
                    </Button>
                </Tooltip>
            </GridToolbarContainer>
        );
    }

    const handleEditClick = (id: GridRowId) => () => {
        // we are finding edited position so later on we can compare it to edited values
        let editedPosition = rows.find((position: IPositionTableProps) => position.id === id);
        setEditedRow(editedPosition);
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleCancelClick = (id: GridRowId) => () => {
        // checking if focused position id is empty if it is we are removing that item from data grid
        if (id === EMPTY_POSITION_ID) {
            let removeEmptyIdPosition = rows.filter((row: IPositionTableProps) => row.id !== EMPTY_POSITION_ID);
            setRows(removeEmptyIdPosition);
        }
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });
    };

    const processRowCreateOrUpdate = async (position: PositionResponse) => {
        // poistion name validation
        if (position.name === editedRow?.name) return showErrorNotification(t(TranslationKeyEnum.samePositionName));
        if (position.name.length === MINIMAL_POSIITON_NAME_LENGHT) {
            // removing added position if validation is not satisfied
            // if we did not remove it user can edit or delete that position and error will occure because that position have empty id
            let removeEmptyIdPosition = rows.filter((row: IPositionTableProps) => row.id !== EMPTY_POSITION_ID);
            setRows(removeEmptyIdPosition);
            return showErrorNotification(t(TranslationKeyEnum.positionNameEmpty));
        }

        // checks if position id is empty so we can make coresponding action, if id is empty that means that position is still being created
        let createOrUpdate = position.id === EMPTY_POSITION_ID ? await createPosition(position) : await updatePosition(position);
        if (!createOrUpdate) {
            // if we got error message from backend we are removing edited row from data grid
            // otherwise field will be shown in data grid with empty name values and mode setted to view
            let removeEmptyIdPosition = rows.filter((row: IPositionTableProps) => row.id !== EMPTY_POSITION_ID);
            return setRows(removeEmptyIdPosition);
        };
        setRerender(!rerender);
        // adding added or updated row to data grid
        const updatedRow = { ...position, isNew: false };
        setRows(rows.map((row) => (row.id === position.id ? updatedRow : row)));
        return updatedRow;
    };

    // removing edited element from data grid if error occurs
    const onProcessRowUpdateError = () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Id', width: 130 },
        { field: 'name', headerName: t(TranslationKeyEnum.name), width: 130, editable: true, flex: 1.5 },
        {
            field: 'actions', type: "actions", sortable: false, width: 100, minWidth: 100, align: "right", flex: .5, getActions: (params: any) => {
                const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <Tooltip title={t(TranslationKeyEnum.save)}><GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(params.id)} /></Tooltip>,
                        <Tooltip title={t(TranslationKeyEnum.cancel)}><GridActionsCellItem icon={<CancelIcon />} label="Cancel" onClick={handleCancelClick(params.id)} /></Tooltip>
                    ]
                }
                return [
                    <Tooltip title={t(TranslationKeyEnum.edit)}><GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={handleEditClick(params.id)} /></Tooltip>,
                    <Tooltip title={t(TranslationKeyEnum.delete)}><GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => setIsDeleteDialogOpen(true)} /></Tooltip>
                ]
            }
        }
    ];

    return (
        <>
            <Box sx={{ boxShadow: CARD_BOX_SHADOW }}>
                {
                    isDeleteDialogOpen &&
                    <ConfirmDialog
                        dialogOpen={isDeleteDialogOpen}
                        setDialogOpen={setIsDeleteDialogOpen}
                        title={t(TranslationKeyEnum.confirmDelete)}
                        description={t(TranslationKeyEnum.deletePositionDescription)}
                        isActionConfirmed={setIsDeleteApproved}
                    />
                }
                <Box>
                    <Typography variant="h5" sx={{ padding: SPACING_EXTRA_SMALL }}>{t(TranslationKeyEnum.positions)}</Typography>
                </Box>
                <EditableDataGrid
                    rows={rows}
                    columns={columns}
                    page={page}
                    setPage={setPage}
                    setRows={setRows}
                    setId={setId}
                    rowModesModel={rowModesModel}
                    setRowModesModel={setRowModesModel}
                    processRowUpdate={processRowCreateOrUpdate}
                    onProcessRowUpdateError={onProcessRowUpdateError}
                    toolbar={AddToolbar}
                    isLoading={isPositionsDataLoading}
                />
            </Box>
            <DeactivatedPositions rerender={rerender} />
        </>
    )
}

export default AdminPositions