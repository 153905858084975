import React from "react";
import { FormikConfig, FormikValues } from "formik";
import { userWorkOrderValidationSchema } from "./userWorkOrderValidationSchema";
import { WorkOrderDto } from "../../../API/work-order-service";
import { useGetWorkOrder } from "../../../API/work-order-actions";
import { isDateYearOne } from "../../../utils/dateUtils";

export function useWorkOrderForm(workOrderId: string): (handleSubmit: () => void) => FormikConfig<FormikValues> {
    const getWorkOrder = useGetWorkOrder();
    const [workOrder, setWorkOrder] = React.useState<WorkOrderDto>();
    const [isReinitializeEnabled, setIsReinitializedEnabled] = React.useState<boolean>(false);

    React.useEffect((): void => {
        const fetchWorkOrders = async () => {
            setIsReinitializedEnabled(true);
            let workOrder = await getWorkOrder(workOrderId);
            if (!workOrder) return;
            setWorkOrder(workOrder);
        }
        workOrderId && fetchWorkOrders();
    }, [])

    const workOrderValidationSchema = userWorkOrderValidationSchema();
    function workOrderFomikForm(handleSubmit: () => void): FormikConfig<FormikValues> {
        return {
            initialValues: {
                id: workOrder?.id ? workOrder.id : "",
                workOrderNumber: workOrder?.name ? workOrder.name : "",
                typeOfWorkId: workOrder?.typeOfWorkId ? workOrder.typeOfWorkId : "",
                locationCode: workOrder?.locationCode ? workOrder.locationCode : "",
                address: workOrder?.address ? workOrder.address : "",
                city: workOrder?.city ? workOrder.city : "",
                coordinates: workOrder?.coordinates ? workOrder.coordinates : "",
                supervisors: workOrder?.userWorkOrders && workOrder?.userWorkOrders?.length ? workOrder.userWorkOrders.filter(user => user?.isSupervisor).map(userWorkOrder => JSON.stringify(userWorkOrder.user)) : [],
                status: workOrder?.status ? workOrder.status : "",
                assignees: workOrder?.userWorkOrders && workOrder?.userWorkOrders?.length ? workOrder.userWorkOrders.filter(user => user?.isAssigned) : [],
                received: workOrder?.received && isDateYearOne(workOrder?.received) ? undefined : workOrder?.received,
                deviceToBeChanged: workOrder?.deviceToBeChanged ? workOrder.deviceToBeChanged : "",
                startDate: workOrder?.startDate ? workOrder.startDate : new Date(),
                endDate: workOrder?.endDate ? workOrder.endDate : new Date(),
                orderSentBy: workOrder?.orderSentBy ? workOrder.orderSentBy : "",
                deviceOnLocation: workOrder?.deviceOnLocation ? workOrder.deviceOnLocation : "",
                description: workOrder?.description ? workOrder.description : "",
            },
            enableReinitialize: isReinitializeEnabled,
            validationSchema: workOrderValidationSchema,
            onSubmit: handleSubmit
        };
    }
    return workOrderFomikForm;
}