import React from "react";
import EditableDataGrid from "../EditableDataGrid/EditableDataGrid";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import ConfirmDialog from "../Dialogs/ConfirmDialog/ConfirmDialog";
import DeactivatedMeetingRooms from "./DeactivatedMeetingRooms";
import { useTranslation } from "react-i18next";
import { TranslationKeyEnum } from "../../features/translations/TranslationKeyEnum";
import { Typography, Box, Tooltip, Button } from "@mui/material";
import { GridActionsCellItem, GridColDef, GridRowId, GridRowModes, GridRowModesModel, GridToolbarContainer } from '@mui/x-data-grid';
import { useErrorNotification } from "../../utils/fetchUtils";
import { CARD_BOX_SHADOW, SPACING_EXTRA_SMALL } from "../../utils/cssUtils";
import { getTextTransformationAccordingToLanguage } from '../../features/translations/helpers';
import { useCreateMeetingRoom, useDeactivateMeetingRoom, useGetMeetingRoomsByStatus, useUpdateMeetingRoom } from "../../API/work-order-actions";
import { CreateRoomRequest, RoomDto, StatusEnum, UpdateStatusRoomRequest } from "../../API/work-order-service";

const FIRST_PAGE = 0;
const EMPTY_MEETING_ROOM_ID = '';
const EMPTY_MEETING_ROOM_NAME = '';

export default function AdminMeetingRooms() {
    const { t } = useTranslation();

    const getMeetingRooms = useGetMeetingRoomsByStatus();
    const createRoom = useCreateMeetingRoom();
    const updateRoom = useUpdateMeetingRoom();
    const showErrorNotification = useErrorNotification();
    const deactivateMeetingRoom = useDeactivateMeetingRoom();

    const [id, setId] = React.useState(EMPTY_MEETING_ROOM_ID);
    const [page, setPage] = React.useState(FIRST_PAGE);
    const [rows, setRows] = React.useState<RoomDto[]>([]);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [editedRow, setEditedRow] = React.useState<RoomDto>();
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
    const [isDeleteApproved, setIsDeleteApproved] = React.useState(false);
    const [isMeetingRoomsDataLoading, setIsMeetingRoomDataLoading] = React.useState(true);
    const [rerender, setRerender] = React.useState(false);

    React.useEffect(() => {
        const fetchData = async () => {
            let allMeetingRooms = await getMeetingRooms(StatusEnum.Active);
            if (!allMeetingRooms) return;
            setRows(allMeetingRooms);
            setIsMeetingRoomDataLoading(false);
        }
        fetchData();
    }, [rerender])

    React.useEffect(() => {
        const deleteData = async () => {
            await deactivateMeetingRoom(new UpdateStatusRoomRequest({ id: id }));
            setRerender(!rerender);
            // set id to empty string so we are not accessing id of the deleted meeting room which will cause error
            setId(EMPTY_MEETING_ROOM_ID);
            // set value to false so user can try to delete another room, otherwise nothing will happen because value of isDeleteApproved is true
            setIsDeleteApproved(false);
        }
        isDeleteApproved && deleteData();
    }, [isDeleteApproved])

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Id', width: 130 },
        { field: 'name', headerName: t(TranslationKeyEnum.name), width: 130, editable: true, flex: 1 },
        {
            field: 'actions', type: "actions", sortable: false, width: 80, minWidth: 80, flex: .3, align: "right", getActions: (params: any) => {
                const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
                return isInEditMode
                    ? [<Tooltip title={t(TranslationKeyEnum.save)}><GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(params.id)} /></Tooltip>,
                    <Tooltip title={t(TranslationKeyEnum.cancel)}><GridActionsCellItem icon={<CancelIcon />} label="Cancel" onClick={handleCancelClick(params.id)} /></Tooltip>]
                    : [<Tooltip title={t(TranslationKeyEnum.edit)}><GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={handleEditClick(params.id)} /></Tooltip>,
                    <Tooltip title={t(TranslationKeyEnum.delete)}><GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => setIsDeleteDialogOpen(true)} /></Tooltip>]
            }
        }
    ];

    const handleEditClick = (id: GridRowId) => () => {
        let editedType = rows.find((meetingRoom: RoomDto) => meetingRoom.id === id);
        setEditedRow(editedType);
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    }

    const handleSaveClick = (id: GridRowId) => () => setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });

    const handleCancelClick = (id: GridRowId) => () => {
        // checks if focused meeting room id is empty if it is we are removing that item from data grid
        if (id === EMPTY_MEETING_ROOM_ID) {
            let removeEmptyIdType = rows.filter((row: RoomDto) => row.id !== EMPTY_MEETING_ROOM_NAME);
            setRows(removeEmptyIdType);
        }
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });
    };

    const AddToolbar = () => {
        const handleClick = () => {
            // returning user to first page because we are adding new created meeting room to start of the array
            page > FIRST_PAGE && setPage(FIRST_PAGE);
            // checks if there is some meeting room being created because only one meeting room can be created at a time
            // if there is no meeting room being created we are adding one empty meeting room and set its mode to edit
            if (rows.every((meetingRoom: RoomDto) => meetingRoom.name !== EMPTY_MEETING_ROOM_NAME)) {
                setRows((oldRows) => [new RoomDto({ id: EMPTY_MEETING_ROOM_ID, name: EMPTY_MEETING_ROOM_NAME }), ...oldRows]);
                setRowModesModel((oldModel) => ({ ...oldModel, [EMPTY_MEETING_ROOM_ID]: { mode: GridRowModes.Edit, fieldToFocus: 'name' } }));
            } else showErrorNotification(t(TranslationKeyEnum.finishMeetingRoomCreation));
        }

        return (
            <GridToolbarContainer sx={{ padding: SPACING_EXTRA_SMALL }}>
                <Tooltip title={t(TranslationKeyEnum.add)}>
                    <Button variant="contained" size="medium" startIcon={<AddIcon />} sx={{ marginLeft: "auto", textTransform: getTextTransformationAccordingToLanguage() }} onClick={handleClick} disabled={isMeetingRoomsDataLoading}>
                        {t(TranslationKeyEnum.addMeetingRoom)}
                    </Button>
                </Tooltip>
            </GridToolbarContainer>
        );
    }

    const processRowCreateOrUpdate = async (meetingRoom: RoomDto) => {
        if (meetingRoom.name === editedRow?.name) return showErrorNotification(t(TranslationKeyEnum.sameMeetingRoomName));
        if (meetingRoom.name === EMPTY_MEETING_ROOM_NAME) {
            // removing added meeting room if validation is not satisfied
            // if we did not remove it user can edit or delete that meeting room and error will occure because that meeting room have empty id
            let removeEmptyRoomId = rows.filter((row: RoomDto) => row.id !== EMPTY_MEETING_ROOM_ID);
            setRows(removeEmptyRoomId);
            return showErrorNotification(t(TranslationKeyEnum.meetingRoomNameEmpty))
        }

        let createOrUpdate = meetingRoom.id === EMPTY_MEETING_ROOM_ID
            ? await createRoom(new CreateRoomRequest({ name: meetingRoom.name }))
            : await updateRoom(meetingRoom);

        if (!createOrUpdate) {
            // if we got error message from backend we are removing edited row from data grid
            // otherwise field will be shown in data grid with empty name values and mode set to view
            let removeEmptyIdType = rows.filter((row: RoomDto) => row.id !== EMPTY_MEETING_ROOM_ID);
            return setRows(removeEmptyIdType);
        };
        setRerender(!rerender);
        // adding added or updated row to data grid
        const updatedRow = new RoomDto({ ...meetingRoom });
        setRows(rows.map((row) => (row.id === meetingRoom.id ? updatedRow : row)));
        return updatedRow;
    };

    // removing edited element from data grid if error occurs
    const onProcessRowUpdateError = () => setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });

    return (
        <Box>
            <Box sx={{ boxShadow: CARD_BOX_SHADOW }}>
                {
                    isDeleteDialogOpen &&
                    <ConfirmDialog
                        dialogOpen={isDeleteDialogOpen}
                        setDialogOpen={setIsDeleteDialogOpen}
                        title={t(TranslationKeyEnum.confirmDelete)}
                        description={t(TranslationKeyEnum.deleteWorkOrderTypeDescription)}
                        isActionConfirmed={setIsDeleteApproved}
                    />
                }
                <Box>
                    <Typography variant="h5" sx={{ padding: SPACING_EXTRA_SMALL }}>{t(TranslationKeyEnum.meetingRooms)}</Typography>
                </Box>
                <EditableDataGrid
                    rows={rows}
                    columns={columns}
                    page={page}
                    setPage={setPage}
                    setRows={setRows}
                    setId={setId}
                    rowModesModel={rowModesModel}
                    setRowModesModel={setRowModesModel}
                    processRowUpdate={processRowCreateOrUpdate}
                    onProcessRowUpdateError={onProcessRowUpdateError}
                    toolbar={AddToolbar}
                    isLoading={isMeetingRoomsDataLoading}
                />
            </Box>
            <DeactivatedMeetingRooms rerenderMeetingRooms={rerender} setRerenderMeetingRooms={setRerender} />
        </Box>
    )
}
