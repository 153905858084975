import { FormikConfig, FormikValues } from "formik";
import { BankHolidayWithUsersDto } from "../../API/time-off-service";
import { useAddEditBankHolidayFormValidationSchema } from "./addEditBankHolidayFormValidationSchema";

export function useAddEditBankHolidayFormikForm(): (bankHoliday: BankHolidayWithUsersDto, handleSubmit: () => void) => FormikConfig<FormikValues> {
    const addEditBankHolidayFormValidationSchema = useAddEditBankHolidayFormValidationSchema();
    function addEditBankHolidayFormikForm(bankHoliday: BankHolidayWithUsersDto, handleSubmit: () => void): FormikConfig<FormikValues> {
        return {
            initialValues: {
                id: bankHoliday.id,
                name: bankHoliday.name,
                startDate: bankHoliday.startDate,
                endDate: bankHoliday.endDate,
            },
            validationSchema: addEditBankHolidayFormValidationSchema,
            onSubmit: handleSubmit,
        };
    }
    return addEditBankHolidayFormikForm;
}