import { FormikConfig, FormikValues } from "formik";
import { useVacationInfoValidationSchema } from "./useVacationFormValidationSchema";

export function useVacationInfoFormikForm(): (handleSubmit: () => void) => FormikConfig<FormikValues> {
    const vacationInfoValidatioSchema = useVacationInfoValidationSchema();
    function vacationInfoFormikForm(handleSubmit: () => void): FormikConfig<FormikValues> {
        return {
            initialValues: {
                yearsOfService: 0,
                approver: undefined
            },
            validationSchema: vacationInfoValidatioSchema,
            onSubmit: handleSubmit
        };
    }
    return vacationInfoFormikForm;
}