import { FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TranslationKeyEnum } from '../../features/translations/TranslationKeyEnum';
import { IDropdownErrorTextProps } from './IDropdownErrorTextProps';
import { ERROR_MESSAGE } from '../../styles/colors';

const ERROR_MESSAGE_COLOR = ERROR_MESSAGE;

function DropdownErrorText({ marginLeft }: IDropdownErrorTextProps) {
  const { t } = useTranslation();
  return (
    <FormHelperText sx={{ color: ERROR_MESSAGE_COLOR, ml: marginLeft ? marginLeft : "3%"}}>{t(TranslationKeyEnum.required)}</FormHelperText>
  )
}

export default DropdownErrorText