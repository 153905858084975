import * as Yup from "yup";

export function userWorkOrderValidationSchema() {
    return Yup.object({
        workOrderNumber: Yup.string(),
        typeOfWorkId: Yup.string().required(),
        locationCode: Yup.string().required(),
        address: Yup.string(),
        city: Yup.string(),
        coordinates: Yup.string(),
        supervisors: Yup.array(),
        status: Yup.string(),
        assignees: Yup.array(),
        received: Yup.date(),
        deviceToBeChanged: Yup.string(),
        startDate: Yup.date(),
        orderSentBy: Yup.string(),
        deviceOnLocation: Yup.string(),
        endDate: Yup.date(),
        description: Yup.string(),
    })
}