import React from 'react';
import { TimeOffPolicyVM } from '../../../API/time-off-service';
import { useGetPolicies } from '../actions';
import { showListOfUserPolicies, showListOfUserPoliciesSkeleton } from '../helpers';
import { ITimeOffPolicies } from './ITimeOffPolicies';

export default function TimeOffPolicies({ userId, isRequestFormOpen }: ITimeOffPolicies) {
    const getPolicies = useGetPolicies(userId);

    const [userPolicies, setUserPolicies] = React.useState<TimeOffPolicyVM[]>([]);
    const [userPoliciesLoading, setUserPoliciesLoading] = React.useState<boolean>(true);

    React.useEffect(() => {
        const fetchPolicies = async () => {
            setUserPoliciesLoading(true);
            if (!userId) return setUserPoliciesLoading(true);
            let timeOffPolicies = await getPolicies();
            if (!timeOffPolicies) return setUserPoliciesLoading(true);
            setUserPolicies(timeOffPolicies.policies!);
            setUserPoliciesLoading(false);
        }
        fetchPolicies();
    }, [userId, isRequestFormOpen])

    return <>
        {userPoliciesLoading ? showListOfUserPoliciesSkeleton() : showListOfUserPolicies(userPolicies, userId, false)}
    </>
}
