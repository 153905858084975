import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css'; // needs additional webpack config!
import '../common/Calendar/calendar.css';
import { Box, Typography, Skeleton, Avatar } from '@mui/material';
import { useGetAllUsersEvents } from './actions';
import { ICalendarEvent } from '../common/Calendar/ICalendarProps';
import { IReadOnlyWeekCalendarProps } from './IReadOnlyWeekCalendarProps';
import { SPACING_EXTRA_SMALL, SPACING_SMALL } from '../../utils/cssUtils';
import { useTranslation } from 'react-i18next';
import { TranslationKeyEnum } from '../../features/translations/TranslationKeyEnum';
import { subtractDays } from '../common/Calendar/helpers';
import { usePrevious } from '../../helpers/usePrevious';
import companyLogo from "../../assets/images/ant.png"
import { useGetGroupFromExternalView } from '../../API/time-off-actions';
import { getGroupIdFromStorage } from '../../utils/storageUtils/storageUtils';
import useAutoReload from '../../helpers/useAutoReload';

type DateObject = { selectedStartDate: Date; selectedEndDate: Date; };

export default function ReadOnlyWeekCalendar({ numberOfDaysDisplayed }: IReadOnlyWeekCalendarProps) {
    const calendarRef = React.createRef<FullCalendar>();
    const { t } = useTranslation();
    const getGroup = useGetGroupFromExternalView();
    const getAllUsers = useGetAllUsersEvents(true);

    const [selectedStartDate, setSelectedStartDate] = React.useState<Date>(new Date());
    const [selectedEndDate, setSelectedEndDate] = React.useState<Date>(new Date());
    const prevDates: DateObject | undefined = usePrevious<DateObject>({ selectedStartDate, selectedEndDate });
    const [events, setEvents] = React.useState<ICalendarEvent[]>([]);
    const [renderKey, setRenderKey] = React.useState<string>(events.length.toString());
    const [userIds, setUserIds] = React.useState<string[]>([]);
    const [isDataLoading, setIsDataLoading] = React.useState<boolean>(false);

    const fetchData = async () => {
        setIsDataLoading(true)
        const calendarEvents = await getAllUsers(userIds, true, selectedStartDate, subtractDays(new Date(selectedEndDate), 1));
        setEvents(calendarEvents);
        setIsDataLoading(false)
    }

    useAutoReload(true, 600000); // Reloads every 10 minutes

    React.useEffect(() => {
        setSelectedStartDate(calendarRef.current?.getApi().view.currentStart!);
        setSelectedEndDate(calendarRef.current?.getApi().view.currentEnd!)
    }, [calendarRef.current])

    React.useEffect(() => {
        const fetchGroups = async () => {
            let group = await getGroup(getGroupIdFromStorage()!);
            if (!group) return;
            group && group.members && setUserIds(group.members!.map(user => user.id!))
        };
        fetchGroups();
    }, [])

    React.useEffect(() => {
        fetchData();
    }, [userIds])

    React.useEffect(() => {
        prevDates && prevDates.selectedStartDate.toString() !== selectedStartDate.toString() && fetchData();
    }, [selectedStartDate])

    React.useEffect(() => {
        setRenderKey(Date.now().toString())
    }, [events])

    return (
        <div className='external-calendar-view'>
            <Box display="flex" flexDirection="column" gap={SPACING_SMALL}  style={{ margin: '50px 15px 15px 15px' }}>
                <Box display="flex" alignItems="center" justifyContent="space-evenly" gap={SPACING_SMALL}>
                    <Box display="flex">
                        <Avatar sx={{ marginRight: SPACING_SMALL, marginTop: "auto", marginBottom: "auto" }} src={companyLogo} />
                        <Typography variant="h6" margin="auto 0">{t(TranslationKeyEnum.companyName)}</Typography>
                    </Box>
                </Box>
                {
                    isDataLoading
                        ? <Box>
                            <Skeleton variant="rectangular" width="100%" height="8vh" sx={{ marginBottom: SPACING_EXTRA_SMALL }}></Skeleton>
                            <Skeleton variant="rectangular" width="100%" height="60vh"></Skeleton>
                        </Box>
                        :
                        <FullCalendar
                            ref={calendarRef}
                            key={renderKey}
                            plugins={[dayGridPlugin, interactionPlugin, bootstrap5Plugin]}
                            themeSystem='bootstrap5'
                            initialView='dayGridWeek'
                            aspectRatio={1.35}
                            headerToolbar={
                                { start: 'prev,next,title', center: '', end: '' }
                            }
                            selectable={false}
                            selectMirror
                            dayMaxEvents
                            weekends
                            weekNumberCalculation='ISO'
                            initialEvents={events}
                            initialDate={selectedStartDate}
                            views={{
                                dayGridWeek: {
                                    type: 'dayGrid',
                                    duration: { days: numberOfDaysDisplayed }
                                }
                            }}
                            contentHeight="auto"
                            datesSet={(dates) => { setSelectedStartDate(dates.start); setSelectedEndDate(dates.end) }}
                        />
                }
            </Box>
        </div>
    )
}