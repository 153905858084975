import { ErrorResponseModel } from "../API/user-management-service"
import { SnackbarKey, useSnackbar } from 'notistack';
import { TranslationKeyEnum } from "../features/translations/TranslationKeyEnum";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { RefreshTokenAction, getIncomingURLFromStorage, getTokenFromStorage, isRefreshTokenActionInProgress, removeUserFromStorage, setIncomingURLToStorage, setRefreshTokenActionToStorage, setUserToStorage } from "./storageUtils/storageUtils";
import { UserClient } from "../API/identity-service";
import { FileResponse } from "../API/time-off-service";

interface StatusErrorMessage {
	[key: number]: string;
}

export type BaseResponseModel = {
	success: boolean
	errors?: any
	response?: any
}

const TIMEOUT_IN_MILISECONDS = 500;
const NUMBER_OF_STEPS_LIMIT = 5;

const refreshAndStoreToken = async (): Promise<boolean> => {
	try {
		const client = new UserClient();
		const user = await client.refreshToken();
		setUserToStorage(user.id!, user.email!, user.jwtToken!);
		return true;
	} catch (error) {
		return false;
	}
}

export function useTryCatchJsonByAction(showMessage: boolean = true): (fetchFunction: (...args: any[]) => Promise<any | ErrorResponseModel>) => Promise<BaseResponseModel> {
	const showSuccessNotification = useSuccessNotificaiton();
	const showErrorNotification = useErrorNotification();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();

	const statusErrorMessages: StatusErrorMessage = {
		0: t(TranslationKeyEnum.somethingWentWrong), // An unexpected server error occurred.
		500: t(TranslationKeyEnum.somethingWentWrong),
		502: t(TranslationKeyEnum.somethingWentWrong),
		400: t(TranslationKeyEnum.somethingWrongWithRequest),
		401: t(TranslationKeyEnum.userUnauthorizedError),
		403: t(TranslationKeyEnum.userForbiddenError),
	}

	function getErrorMessage(error: any, statusErrorMessages: Record<number, string>) {
		const errorMessage =
		  serverErrorCodes.includes(error.status)
			? statusErrorMessages[error.status]
			: getErrorMessageOrNull(error) ?? statusErrorMessages[error.status];
	  
		return errorMessage;
	}

	const serverErrorCodes: number[] = [0, 500, 502, 400, 401, 403];

	const checkIfTokenChanged = async (token: string | null, stepTimeout: number, stepNumber: number): Promise<boolean> => {
		if (stepNumber > NUMBER_OF_STEPS_LIMIT || stepNumber <= 0) {
			return false
		} else if (token !== getTokenFromStorage() && !isRefreshTokenActionInProgress()) {
			return true;
		} else if (isRefreshTokenActionInProgress()) {
			await new Promise(r => setTimeout(r, stepTimeout)); // wait for refresh token task to finish
			return checkIfTokenChanged(token, stepTimeout / stepNumber, stepNumber++)
		} else {
			return false;
		}
	}

	const retryAction = async (fetchFunction: (...args: any[]) => Promise<any | ErrorResponseModel>): Promise<BaseResponseModel> => {
		try {
			const fetchPromise = fetchFunction();
			const response = await fetchPromise;
			showSuccessNotification(response.message);
			return { success: true, response };
		} catch (error: any) {
			showErrorNotification(serverErrorCodes.includes(error.status) ? statusErrorMessages[error.status] : getErrorMessageOrNull(error) ?? statusErrorMessages[error.status]);
			return { success: false, response: null };
		}
	}

	async function tryCatchJsonByAction(fetchFunction: (...args: any[]) => Promise<any | ErrorResponseModel>): Promise<BaseResponseModel> {
		const fetchPromise = fetchFunction();
		const token = getTokenFromStorage();

		try {
			const response = await fetchPromise;
			showMessage && showSuccessNotification(response.message);
			setRefreshTokenActionToStorage(RefreshTokenAction.IDLE);
			return { success: true, response };
		}
		catch (error: any) {
			if (error.status === 401) {
				if (token) {
					if (isRefreshTokenActionInProgress() && (await checkIfTokenChanged(token, TIMEOUT_IN_MILISECONDS, 1))) {
						return await retryAction(fetchFunction);
					}

					setRefreshTokenActionToStorage(RefreshTokenAction.IN_PROGRESS);
					if (await refreshAndStoreToken()) {
						setRefreshTokenActionToStorage(RefreshTokenAction.IDLE);
						return await retryAction(fetchFunction);
					}
				}

				setRefreshTokenActionToStorage(RefreshTokenAction.IDLE);
				!getIncomingURLFromStorage() && setIncomingURLToStorage(location.pathname);
				removeUserFromStorage();
				navigate("/");
				return { success: false, response: null, errors: getErrorMessage(error, statusErrorMessages) };
			}
			if (error.status === 0 && !getTokenFromStorage()) {
				showErrorNotification(getErrorMessage(error, statusErrorMessages));
				!getIncomingURLFromStorage() && setIncomingURLToStorage(location.pathname);
				removeUserFromStorage();
				navigate("/");
				return { success: false, response: null, errors: getErrorMessage(error, statusErrorMessages) };
			}
			if (error.status === 403 || error.status === 401 || error.status === 400) {
				showErrorNotification(getErrorMessageOrNull(error) ?? statusErrorMessages[error.status]);
				return { success: false, response: null, errors: getErrorMessage(error, statusErrorMessages) };
			}
			let errorMessageText = null;
			try { errorMessageText = getErrorMessageOrNull(error) }
			catch { errorMessageText = t(TranslationKeyEnum.somethingWentWrong) }
			showMessage && showErrorNotification(serverErrorCodes.includes(error.status) ? statusErrorMessages[error.status] : errorMessageText ?? statusErrorMessages[error.status]);

			return { success: false, response: null, errors: getErrorMessage(error, statusErrorMessages) };
		}
	}

	return tryCatchJsonByAction;
}

const getErrorMessageOrNull = (error: any): string | null => {
	return (error && error.response && Array.isArray(error.response) && error.response.errors && error.response.errors[Object.keys(error.response.errors)[0]])
		|| (error.response && error.response.errors[Object.keys(error.response.errors)[0]])
		|| (error.errors && error.errors[Object.keys(error.errors)[0]])
		|| (error.message ?? null)
}

export function useSuccessNotificaiton(): (message?: string) => SnackbarKey | null {
	const { enqueueSnackbar } = useSnackbar();

	function showSuccessNotification(message?: string): SnackbarKey | null {
		return message ? enqueueSnackbar(message, { variant: "success" }) : null;
	}
	return showSuccessNotification;
}

export function useWarningNotification(): (message?: string) => SnackbarKey | null {
	const { enqueueSnackbar } = useSnackbar();

	function showWarningNotification(message?: string): SnackbarKey | null {
		return message ? enqueueSnackbar(message, {variant: "warning"}) : null;
	}
	return showWarningNotification;
}

export function useErrorNotification(): (message: string) => SnackbarKey {
	const { enqueueSnackbar } = useSnackbar();

	function showErrorNotification(message: string): SnackbarKey {
		return enqueueSnackbar(message, { variant: "error" });
	}
	return showErrorNotification;
}

export const download = (file: FileResponse, fileName: string) => {
	let blobURL = (window.URL && window.URL.createObjectURL) ? window.URL.createObjectURL(file.data) : window.webkitURL.createObjectURL(file.data);
	let tempLink = document.createElement('a');
	tempLink.style.display = 'none';
	tempLink.href = blobURL;
	tempLink.setAttribute('download', file.fileName ?? fileName);

	// Safari thinks _blank anchors are pop-ups. We only want to set a _blank
	// target if the browser does not support the HTML5 download attribute.
	// This allows you to download files in desktop safari if pop-up blocking
	// is enabled.
	if (typeof tempLink.download === 'undefined') {
		tempLink.setAttribute('target', '_blank');
	}

	document.body.appendChild(tempLink);
	tempLink.click();

	// Fixes "webkit blob resource error 1"
	setTimeout(function () {
		document.body.removeChild(tempLink);
		window.URL.revokeObjectURL(blobURL);
	}, 200)
}