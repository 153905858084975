import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TranslationKeyEnum } from '../../features/translations/TranslationKeyEnum';
import { SPACING_MEDIUM, SPACING_SMALL } from '../../utils/cssUtils';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { IEditPasswordProps } from './IEditPasswordProps';
import { useEditPasswordFormValidationSchema } from './editPasswordFormValidationSchema';
import { IEditPasswordDialogProps } from './IEditPasswordDialogProps';
import { useUpdatePasswordAction } from './actions';

export default function EditPasswordDialog(props: IEditPasswordDialogProps) {
    const { t } = useTranslation();
    const { onClose, open } = props;
    const updateUserPassword = useUpdatePasswordAction();

    const handlePasswordDialogClose = () => {
        formik.resetForm();
        onClose();
    };

    const handlePasswordChangeSubmit = () => {
        updateUserPassword(formik.values.currentPassword, formik.values.newPassword);
        handlePasswordDialogClose();
    };

    const editPasswordFormValidationSchema = useEditPasswordFormValidationSchema();
    const formik = useFormik({
        initialValues: {
            currentPassword: "",
            newPassword: "",
            newPasswordConfirmation: "",
        } as IEditPasswordProps,
        validationSchema: editPasswordFormValidationSchema,
        onSubmit: handlePasswordChangeSubmit
    })

    return (
        <Dialog open={open} onClose={handlePasswordDialogClose} fullWidth={true} maxWidth={'xs'}>
            <DialogTitle>{t(TranslationKeyEnum.changePassword)}</DialogTitle>
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <Box display="flex" flexDirection="column">
                        <TextField
                            autoFocus
                            margin="dense"
                            id="currentPassword"
                            label={t(TranslationKeyEnum.currentPassword)}
                            type="password"
                            fullWidth
                            variant="standard"
                            placeholder={t(TranslationKeyEnum.currentPasswordPlaceholder)}
                            value={formik.values.currentPassword}
                            onChange={formik.handleChange}
                            error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
                            helperText={formik.touched.currentPassword && formik.errors.currentPassword}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="newPassword"
                            label={t(TranslationKeyEnum.newPassword)}
                            type="password"
                            fullWidth
                            variant="standard"
                            placeholder={t(TranslationKeyEnum.newPasswordPlaceholder)}
                            value={formik.values.newPassword}
                            onChange={formik.handleChange}
                            error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                            helperText={formik.touched.newPassword && formik.errors.newPassword}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="newPasswordConfirmation"
                            label={t(TranslationKeyEnum.confirmNewPassword)}
                            type="password"
                            fullWidth
                            variant="standard"
                            placeholder={t(TranslationKeyEnum.confirmNewPasswordPlaceholder)}
                            value={formik.values.newPasswordConfirmation}
                            onChange={formik.handleChange}
                            error={formik.touched.newPasswordConfirmation && Boolean(formik.errors.newPasswordConfirmation)}
                            helperText={formik.touched.newPasswordConfirmation && formik.errors.newPasswordConfirmation}
                        />
                    </Box>
                    <Button fullWidth type="submit" variant="contained" sx={{ backgroundColor: "#2196F3", marginTop: SPACING_MEDIUM }}>
                        {t(TranslationKeyEnum.submit)}
                    </Button>
                    <Button fullWidth onClick={handlePasswordDialogClose} sx={{ color: "#2196F3", marginTop: SPACING_SMALL }}>
                        {t(TranslationKeyEnum.cancel)}
                    </Button>
                </form>
            </DialogContent>
        </Dialog>
    )
}