export const TRANSLATIONS_EN = {
    panosLabel: 'Panos Intranet',
    signIn: 'Sign In',
    email: 'E-mail',
    emailPlaceholder: 'Enter E-mail',
    emailError: 'Invalid E-mail address',
    password: 'Password',
    passwordMustMatch: 'Passwords must match',
    passwordMustntMatch: 'Password must not match your current password',
    passwordPlaceholder: 'Enter Password',
    passwordMax: 'Maximum password length is {{maximumLength}} characters',
    passwordMin: 'Minimum password length is {{minimumLength}} characters',
    currentPassword: 'Current password',
    currentPasswordPlaceholder: 'Please enter your current password',
    newPassword: 'New password',
    newPasswordPlaceholder: 'Please enter a new password',
    confirmNewPassword: 'Confirm new password',
    confirmNewPasswordPlaceholder: 'Please confirm your password',
    firstName: 'First Name',
    lastName: 'Last Name',
    name: 'Name',
    phone: 'Phone',
    invalidPhoneNumber: 'Phone number format is invalid',
    address: 'Address',
    dateOfBirth: 'Date of birth',
    invalidDateOfBirth: 'Please select date that is not in the future',
    startWorkingDate: 'Started working date',
    role: 'Role',
    actions: 'Actions',
    status: 'Status',
    required: 'Required',
    submit: 'Submit',
    home: 'Home',
    people: 'People',
    logout: 'Log out',
    copyEmail: 'Copy E-mail',
    emailCopied: 'E-mail copied!',
    searchEmployee: 'Enter Users First or Last name...',
    office: 'Office',
    offices: 'Offices',
    position: 'Position',
    positions: 'Positions',
    employees: 'Employees',
    employeesPageDescription: 'Here are all the employees at your company.',
    noUsers: 'There are no Employees with these characteristics',
    noOffice: 'There is no Office created yet',
    noUsersInOffice: 'There are no Employees with selected fiters in this office',
    resetFilters: 'Reset All Filters',
    loggedIn: 'Logged in successfully!',
    somethingWentWrong: "Something went wrong",
    somethingWrongWithRequest: "Something is wrong with a request",
    userUnauthorizedError: 'You need to be authenticated to access this resource',
    userForbiddenError: 'You do not have permission to access this resource',
    actionCompleted: 'Action completed successfully',
    adminConsole: 'Admin Console',
    adminConsoleDescription: 'This is your admin console, be careful what you touch',
    mainInfo: 'Main Info',
    additionalInfo: 'Additional Info',
    permissions: 'Permissions',
    vacation: 'Vacation',
    back: 'Back',
    next: 'Next',
    add: 'Add',
    earlierPositions: 'Earlier Positions',
    positionName: 'Position name',
    workStartDate: 'Start date',
    workEndDate: 'End date',
    startDate: 'Start date',
    endDate: 'End date',
    addOffice: 'Add office',
    addPosition: 'Add position',
    removePosition: 'Remove position',
    selectEmployeePermissions: 'Select employee permissions',
    selectApprover: 'Select approver',
    selectPolicy: 'Select policy',
    selectColor: 'Select color',
    yearsOfService: 'Years of service',
    edit: 'Edit',
    delete: 'Delete',
    save: 'Save',
    numberOfEmployees: 'Number of employees',
    confirmDelete: 'Are you sure?',
    deleteEmployeeDescription: 'You are about to remove {{fullname}}. Are you sure about this? You can always restore the user later if you wish so.',
    deleteOfficeDescription: 'Office cannot be deleted while there are users assigned to it',
    deletePositionDescription: 'Position cannot be deleted while there are users assigned to it.',
    deletePolicyDescription: 'Policy cannot be deleted while there are users assigned to it.',
    deleteUserFromPolicy: 'User will be removed from the policy.',
    deleteGroupDescription: 'Group cannot be deleted while there are users assigned to it.',
    deleteBankHolidayDescription: 'Bank holiday cannot be deleted while there are users assigned to it.',
    uploadImage: 'Upload image',
    removeImage: 'Remove image',
    invalidImageFormat: 'Selected image format is not supported. Supported formats are .jpg, .jpeg, .png .webp.',
    maximumImageSize: 'File exceeds size limit. Image must not be larger than 2 MB.',
    ok: 'OK',
    yesRemove: 'Yes, remove',
    cancel: 'Cancel',
    calendar: 'Calendar',
    viewCalendar: 'View calendar',
    selectedDateRange: 'Selected Date Range',
    timeOffTab: 'Time off',
    timeOff: 'Time Off',
    workOrder: 'Work Order',
    workOrdersTab: 'Work orders',
    workOrdersTabContent: 'Currently there are no upcoming work orders',
    pendingRequestsTab: 'Pending requests',
    pendingRequestsPageDescription: 'Here are pending requests at your company.',
    close: 'Close',
    officeNameAndAddressEmpty: 'Please populate both name and address',
    finishOfficeCreation: 'Please finish creation of the office, then try to add another one',
    positionNameEmpty: 'Position name cannot be empty',
    samePositionName: 'Position name cannot be the same as before editing',
    currentPosition: 'Current position',
    noCurrentPositionDefinedLabel: 'There is no current position defined',
    noCurrentPositionDefinedDescription: 'Please select one of the positions as the current position and try again',
    createRequest: 'Create request',
    policy: 'Policy',
    policies: 'Policies',
    comment: 'Comment',
    description: 'Description',
    numberOfBusinessDays: 'Number of business days',
    zeroWorkDaysSelected: 'You need to select more than 0 working days',
    wantToProceed: 'Are you sure you want to proceed?',
    groupRequestsOverlap: 'Requested dates overlap with people from your group.',
    usersGroupRequestsOverlap: 'Requested dates overlap with people from your group.',
    noAvailableDays: 'You do not have enough available days on selected policy.',
    alreadySelectedDates: 'You have already requested time-off for the selected dates.',
    dependantPoliciesInfo: 'If selected dates are in range of both policies, time-off will first be taken from {{parent}} policy and then from the {{child}} policy.',
    policyValidRange: 'Selected policy is valid from {{startDate}} to {{endDate}}.',
    addedDays: 'Number of days added',
    policySummary: 'Policy Summary',
    changedBy: 'Changed by',
    daysChange: 'Number of days',
    daysChangePolicySummary: 'Change in days',
    availableDays: 'Available days',
    totalDays: 'Total days',
    usedDays: 'Used days',
    modificationDate: 'Modification date',
    pendingRequests: 'Pending requests',
    pendingRequestsTimeOff: 'Pending Requests for Time Off',
    myRequests: 'My requests',
    open: 'Open',
    leaveRequest: 'Leave request',
    cancelRequest: 'Cancel request',
    minimumInputLength: 'Minimum input lenght for {{fieldName}} field is {{minimumLength}} characters',
    maximumInputLength: 'Maximum input lenght for {{fieldName}} field is {{maximumLength}} characters',
    minimumInputValue: 'Minimum input value for {{fieldName}} field is {{minimumLength}}',
    policyLoading: 'Loading policy...',
    addUser: 'Add user',
    addEmployee: 'Add new employee',
    users: 'Users',
    groups: 'Groups',
    groupsLoading: 'Loading groups...',
    groupHasMembers: 'Group cannot be deleted as long as it has members',
    addGroup: 'Add group',
    editGroup: 'Edit group',
    editEmployee: 'Edit Employee',
    mustBePresentLabel: 'Number of members who must be present',
    mustBePresentError: 'Number of members who must be present must be less than total number of members',
    requirement: 'Requirement',
    addOrChangePolicy: 'Add/Change policy',
    addPolicy: 'Add policy',
    policyName: 'Policy name',
    policyDependsOn: 'Depends on',
    policyAllowNegative: 'Allow available days to be negative',
    policyInfiniteDays: 'Policy has no limit (i.e. sick leave)',
    policyStartDate: 'Start date',
    policyEndDate: 'End date',
    policyColor: 'Color',
    bankHolidays: 'Bank Holidays',
    bankHolidaysLoading: 'Loading Bank Holidays...',
    editBankHoliday: 'Edit Bank Holiday',
    addBankHoliday: 'Add Bank Holiday',
    phoneNumber: 'Phone number',
    birthdate: 'Birthdate',
    dateOfEmployment: 'Date of employment',
    notifications: 'NOTIFICATIONS & INBOX',
    markAllAsRead: 'Mark all as read',
    requestNotExist: 'Request does not exist',
    allNotifications: "That's all your notifications from the last 30 days",
    dashboard: 'Dashboard',
    search: 'Search',
    you: 'You',
    company: 'Company',
    companyName: 'AntyPlanner',
    language: 'Language',
    welcomeBack: 'Welcome back, {{firstName}}',
    upcomingTimeOff: 'Upcoming time off',
    upcomingTimeOffToday: 'Today',
    upcomingTimeOffNextDays: 'Next 7 days',
    upcomingTimeOffLinkLabel: "See who’s out this week",
    whatIsNew: "Here is what’s new at {{companyName}}",
    workDay: 'work day',
    workDays: 'work days',
    workDaysRequestHistory: 'Work days',
    nextTimeOff: 'Next Time Off',
    timeOffCardDescription: 'This list represents your upcoming time off.',
    viewAllTimeOffRequests: 'View all time off requests',
    noPendingNotifications: 'There’s nothing pending, go grab a coffee. You deserve it.',
    notificationsUnavailable: 'Notification service is currently unavailable.',
    remove: 'Remove',
    userLabel: 'User',
    locationLabel: 'Location',
    hireDateLabel: 'Hire date',
    all: "All",
    approver: 'Approver',
    workInformation: 'Work Information',
    basicInformation: 'Basic information about user’s role',
    personalInformation: 'Personal Information',
    basicPersonalInformation: 'Personal information about the user',
    timeOffInformation: 'How many days a user has left, as well as a history of their days off',
    addTimeOffDays: 'Add Time Off Days to User',
    daysPerPolicy: 'Total days',
    expiresOn: 'Expires on',
    date: 'Date',
    available: 'Available',
    employeeAvailable: 'Available',
    today: 'Today',
    taken: 'Taken',
    workOrdersInfo: 'Information about Work Orders and its days taken',
    finished: 'finished',
    canceled: 'canceled',
    days: 'days',
    daysRequested: 'Days Requested',
    viewRequest: 'View request',
    viewCancellationRequest: 'View Cancellation Request',
    upcomingHolidays: 'Upcoming holidays',
    loginGreetings: "Hello who's this?",
    forgotPassword: 'Forgot password?',
    forgotPasswordPlaceholder: 'If your account exists we will send you temporary password to your email address',
    login: 'log in',
    sayCongrats: 'Say Congrats',
    requestTimeOff: 'request time off',
    changePassword: 'Change password',
    createRequestLabel: 'This request will be notified to all those who have permission to approve your time off, including your time off supervisor {{approverFullname}}',
    createRequestForUserLabel: 'Time-off request for {{userFullname}} will be created and automatically approved. Selected number of days will be deducted from available days',
    typeOfAbsence: 'Type of absence',
    startingFrom: 'Start date',
    ending: 'End date',
    policyNotEnoughDays: "You don’t have enough days on selected policy.",
    selectTypeOfAbsence: 'Select type of absence',
    selectOffice: 'Select office',
    selectPosition: 'Select position',
    requestOverlaps: 'This absence request overlaps with other employees in your group',
    requestWorkDays: 'request {{workDays}} work days',
    hooray: 'Hooray!',
    successfullRequest: 'You have successfully requested your time off ({{numberOfDays}} working days). You can now sit back, relax and wait for your approval.',
    done: 'done',
    invalidStartDate: 'Invaid Start Date',
    invalidEndDate: 'Invalid End Date',
    userRequestedTimeOff: '{{fullname}} has requested time-off',
    userRequestedTimeOffCancellationFirstPart: '{{fullname}} wants to',
    userRequestedTimeOffCancellationSecondPart: 'cancel',
    userRequestedTimeOffCancellationThirdPart: 'their request',
    decline: 'decline',
    approve: 'approve',
    approveCancellation: 'Approve Cancellation',
    relatedRequest: 'This request have two policies combined together because there were not enough days on the selected policy',
    newsFeed: 'News Feed',
    requestPending: 'Request Pending',
    requestApproved: 'Request Approved',
    requestAlreadyResolved: 'Request already resolved',
    numberOfDaysTaken: 'Number of days taken',
    viewTimeOffMonthlySummary: 'View time-off monthly summary',
    viewPolicySummary: 'View Policy Summary',
    deactivatedUsers: 'Deactivated Users',
    deactivatedPositions: 'Deactivated Positions',
    deactivatedPolicies: 'Deactivated Policies',
    activate: 'Activate',
    numberOfWorkingDays: 'Number of working days',
    timeOffPolicy: 'Time-off policy',
    removeRequest: 'Remove request?',
    cancellationRequest: 'Cancellation request?',
    removeRequestText: 'You are about to remove the request. Are you sure about this?',
    cancellationRequestText: 'Cancellation request will be sent to your supervisor. Do you wish to proceed?',
    removeApproval: 'Yes, remove',
    cancellationRequestApproval: 'Yes, send cancellation request',
    myTimeOffRequests: 'My Time Off Requests',
    employeeTimeOffRequests: 'Employees Time Off Requests',
    allTimeOffRequests: 'All Time Off Requests',
    profile: 'Profile',
    noTimeOffs: "We've got a full house and ready to roll!",
    typeOfWork: 'Type of work',
    goingWith: 'Going with',
    monthlySummary: 'Monthly summary',
    monthlySummaryDescription: 'This list represents the amount of work you have done this month.',
    viewWorkOrderHistory: 'View work order history',
    noDoneWorkOrdersThisMonth: 'Nothing to display for this month, check your history for more information',
    noWorkOrdersThisMonth: 'Here were no work orders this month. Go grab a coffee and relax.',
    viewUserMonthlySummary: 'View monthly user summary',
    manageWorkOrders: 'Manage Work Orders',
    manageWorkOrdersLabel: 'Here are all work orders in your company.',
    viewMore: 'View more',
    newWorkOrder: 'New work order',
    summary: 'Summary',
    selectGroup: 'Select group',
    workOrdersTypes: 'Work orders types',
    addworkOrderType: 'Add work order type',
    finishWorkOrderTypeCreation: 'Please finish creation of the work order type, then try to add another one',
    deleteWorkOrderTypeDescription: 'You are about to remove selected work order type. Are you sure about this?',
    workOrderNumber: 'Work order number',
    workOrderNumberPlaceholder: 'Work order number will be autogenerated',
    selectTypeOfWork: 'Select type of work',
    locationCode: 'Location code',
    coordinates: 'Coordinates',
    supervisors: 'Supervisors',
    selectSupervisors: 'Select supervisors',
    createWorkOrderLabel: 'This work order will be notified to all those who have been included.',
    assignedTo: 'Assigned to',
    mapLinkLabel: 'A link to maps will be generated here.',
    selectAssignees: 'Select assignees',
    workOrderReceived: 'Work order received',
    deviceToBeChanged: 'Device to be changed',
    orderSentBy: 'Order sent by',
    deviceOnLocation: 'Device on location',
    createWorkOrder: 'Create work order',
    updateWorkOrder: 'Update work order',
    mustBeCompletedBy: 'Must be completed by',
    sameWorkOrderTypeName: 'Type of work name cannot be the same as before editing',
    typeOfWorkNameEmpty: 'Type of work name cannot be empty',
    deactivatedTypesOfWork: 'Deactivated types of work',
    requestComment: 'Request comment',
    responseComment: 'Response comment',
    city: 'City',
    employeeDoesNotHaveApproverAssigned: 'Employee does not have Approver assigned',
    at: 'at',
    noNextWorkOrders: 'There is no next work orders for you, have a rest.',
    daysOff: 'Days Off',
    regularPolicy: 'Regular policy',
    filters: 'Filters',
    displayInformation: 'Display information',
    showWorkOrders: 'Show work orders',
    default: 'Default',
    showUsersWithWorkOrder: 'Show Users that currently have a work order',
    showUsersWithWorkOrderDescription: 'Display Users that have active Work Orders on given days.',
    showAvailableUsers: 'Show Available Users',
    showAvailableUsersDescription: 'Display Users that do not have Work Order or time-off assigned on a given day.',
    showAllUsers: 'Show all users',
    showAllUsersDescription: 'Display all the Users and their work status for each day.',
    saveFilters: 'save filters',
    currentSelectedFilter: 'Current selected filter',
    meetingRooms: 'Meeting rooms',
    finishMeetingRoomCreation: 'Please finish creation of the meeting room, then try to add another one',
    addMeetingRoom: 'Add meeting room',
    deactivatedMeetingRooms: 'Deactivated meeting rooms',
    meetingRoomNameEmpty: 'Meeting room name cannot be empty',
    sameMeetingRoomName: 'Meeting room name cannot be the same as before editing',
    approvesTimeOff: 'Approves Time-off',
    selectRoom: 'Select meeting room',
    reserveRoom: 'Reserve a meeting room',
    meetingRoom: 'Meeting room',
    meetingOrganizer: 'Organizer',
    title: 'Title',
    addMeeting: 'Add meeting',
    personalNo: 'Personal number',
    passportNumber: 'Passport number',
    passportValidUntil: 'Passport valid until',
    contractExpiration: 'Contract expiration',
    idCardNumber: 'Id card number',
    idCardValidUntil: 'Id card valid until',
    doctorAppointment: 'Doctor appointment',
    certificateValidUntil: 'Certificate valid until',
    dragAndDrop: 'Drag and Drop',
    medicalInsuranceNumber: 'Medical Insurance Number',
    religiousDay: 'Religious day',
    employee: 'Employee',
    newVersionPopup: 'Got it!',
    scheduledMeetings: 'scheduled meetings',
    noscheduledMeetings: 'There is no scheduled meetings.',
    upcomingBirthdays: 'upcoming birthdays',
    noUpcomingBirthdays: 'There is no upcoming birthdays.',
    additionalPersonalInformation: 'Additional informations about the user',
    basicInformationTitle: 'Basic information',
    positionsInCompany: 'Positions in company',
    present: 'present',
    documents: 'Documents',
    copyPolicy: 'Copy Policy',
    accessToApplication: 'Allow login',
    copyBankHoliday: 'Copy Bank Holiday',
    addArticle: 'Add Article',
    articleTitle: 'Title',
    articleText: 'Text',
    deleteArticleDescription: 'You are about to remove selected article. Are you sure about this?',
    editArticle: 'Edit article',
    userUpdatedSuccessfully: 'User was updated successfully',
    upload: 'Upload',
    maxFileSize: 'Max file size is 2MB',
    acceptedExtensions: 'Accepted extensions: .jpeg, .png, .jpg, .webp, .pdf, .docs, .docx, .txt, .xlsx, .xml and .csv',
    fileValidationMessage: 'File {{fileName}} size or extension is unsupported.',
    duplicatefileValidationMessage: 'File {{fileName}} is already uploaded or selected.',
    openMap: 'Open map',
};