export enum routeEnum {
    HOME = 'home',
    EMPLOYEES = 'employees',
    CALENDAR = 'calendar',
    ADMIN = 'admin',
    TIMEOFF = 'timeoff',
    PENDINGREQUESTS = 'pending-requests',
    PROFILE = 'profile',
    WORK_ORDERS = 'work-orders',
    MANAGE_WORK_ORDERS = 'manage-work-orders',
    MEETING_ROOMS = 'meeting-rooms',
    MEETING_ROOMS_VIEW = 'meeting-rooms-view',
    MEETING_ROOMS_MOBILE = 'meeting-rooms-mobile',
    WAREHOUSE_VIEW = 'warehouse-view',
}