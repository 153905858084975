import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import CelebrationIcon from '@mui/icons-material/Celebration'
import CircularProgress from '@mui/material/CircularProgress';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { Avatar, AvatarGroup, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useGetAllRequests } from "./action";
import { RequestWithUserImagesDto } from "../../../API/time-off-service";
import { TranslationKeyEnum } from "../../../features/translations/TranslationKeyEnum";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { PRIMARY_BG_COLOR, CARD_BOX_SHADOW, CARD_HEIGHT, BORDER_RADIUS_MEDIUM, SPACING_SMALL, CARD_HEADER_HEIGHT, LIGHT_BORDER, AVATAR_SIZES, CARD_BODY_HEIGHT, CARD_FOOTER_HEIGHT, MIN_CARD_HEADER_HEIGHT, MIN_CARD_BODY_HEIGHT, MIN_CARD_FOOTER_HEIGHT, MIN_CARD_HEIGHT, SPACING_MEDIUM, SPACING_EXTRA_SMALL, DASHBOARD_ICON_SIZE } from "../../../utils/cssUtils";
import { getTextTransformationAccordingToLanguage } from '../../../features/translations/helpers';

const UpcomingTimeOff = () => {
    const { t } = useTranslation();
    const getRequest = useGetAllRequests();
    const [requests, setRequests] = React.useState<RequestWithUserImagesDto[]>([]);
    const [todayRequestsFirstRow, setTodayRequestsFirstRow] = React.useState<RequestWithUserImagesDto[]>([]);
    const [todayRequestsSecondRow, setTodayRequestsSecondRow] = React.useState<RequestWithUserImagesDto[]>([]);
    const [requestsFirstRow, setRequestsFirstRow] = React.useState<RequestWithUserImagesDto[]>([]);
    const [requestsSecondRow, setRequestsSecondRow] = React.useState<RequestWithUserImagesDto[]>([]);
    const [isRequestDataLoading, setIsRequestDataLoading] = React.useState<boolean>(true);

    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setUTCHours(24, 0, 0, 0);
    const lastDay = new Date();
    //added hours(24*7) so it can represent the last(7th) day
    lastDay.setUTCHours(168, 0, 0, 0);


    React.useEffect(() => {
        const fetchData = async () => {
            let requests = await getRequest();
            if (!requests) return;
            setRequests(requests);
            setIsRequestDataLoading(false);
        }
        fetchData();
    }, [])


    React.useEffect(() => {
        let firstRow: RequestWithUserImagesDto[] = [];
        let secondRow: RequestWithUserImagesDto[] = [];
        let todayFirstRow: RequestWithUserImagesDto[] = [];
        let todaySecondRow: RequestWithUserImagesDto[] = [];

        requests && requests.forEach((request) => {
            const userIdsInFirstRow = firstRow.map(x => x.userId);

            if (!userIdsInFirstRow.includes(request.userId) && isAbsenceInNext6Days(request)) {
                if (firstRow.length < 3) {
                    firstRow.push(request);
                } else {
                    secondRow.push(request);
                }
            }

            if (isTodayAbsence(request)) {
                if (todayFirstRow.length < 3) {
                    todayFirstRow.push(request);
                }
                else {
                    todaySecondRow.push(request);
                }
            }
        });

        setTodayRequestsFirstRow(todayFirstRow);
        setTodayRequestsSecondRow(todaySecondRow);
        setRequestsFirstRow(firstRow);
        setRequestsSecondRow(secondRow);
    }, [requests])

    function isTodayAbsence(event: RequestWithUserImagesDto): boolean {
        today.setHours(0, 0, 0, 0);
        event.startDate!.setHours(0, 0, 0, 0);
        event.endDate!.setHours(0, 0, 0, 0);
        return event.startDate!.toISOString() === today.toISOString() || (event.startDate!.toISOString() < today.toISOString() && event.endDate!.toISOString() >= today.toISOString());
    }

    function isAbsenceInNext6Days(event: RequestWithUserImagesDto): boolean {
        return event.startDate!.toISOString() === tomorrow.toISOString() || event.startDate!.toISOString() < tomorrow.toISOString() && event.endDate!.toISOString() >= tomorrow.toISOString() ||
            event.startDate!.toISOString() > tomorrow.toISOString() && event.startDate!.toISOString() <= lastDay.toISOString();
    }

    const getUsersInitials = (firstName: string, lastName: string): string => {
        return firstName && `${firstName.charAt(0)}${lastName.charAt(0)}`;
    }

    return (
        <Grid container sx={{ height: CARD_HEIGHT, minHeight: MIN_CARD_HEIGHT, borderRadius: BORDER_RADIUS_MEDIUM, boxShadow: CARD_BOX_SHADOW, backgroundColor: PRIMARY_BG_COLOR, marginBottom: SPACING_SMALL }}>
            {/* Card Header */}
            <Grid item width="100%" height={CARD_HEADER_HEIGHT} minHeight={MIN_CARD_HEADER_HEIGHT} display="flex" alignItems="center" justifyContent="center" borderBottom={LIGHT_BORDER}>
                <Typography variant="subtitle2" textAlign="center" sx={{ textTransform: "uppercase" }}>
                    {t(TranslationKeyEnum.upcomingTimeOff)} ({requests && requests.length})
                </Typography>
            </Grid>
            {/* Card Body */}
            <Grid container borderBottom={LIGHT_BORDER} height={CARD_BODY_HEIGHT} minHeight={MIN_CARD_BODY_HEIGHT} padding={`${SPACING_SMALL} ${SPACING_MEDIUM}`}>
                {
                    isRequestDataLoading
                        // Request data loading
                        ? <CircularProgress sx={{ margin: "auto" }} />
                        : requests?.length
                            ? <>
                                <Grid item width="50%">
                                    <Box display="flex" flexDirection="column" alignItems="left" justifyContent="left" gap=".6vh">
                                        <Typography variant="body2">{t(TranslationKeyEnum.upcomingTimeOffToday)}</Typography>
                                        <Grid>
                                            <Stack direction="row">
                                                <AvatarGroup max={3} >
                                                    {
                                                        todayRequestsFirstRow?.length
                                                            ? todayRequestsFirstRow.map((request, index) => {
                                                                return <Tooltip key={index} title={`${request.firstName} ${request.lastName}`}>
                                                                    <Avatar key={index} src={request.image && `data:image/png;base64,${request.image}`} sx={AVATAR_SIZES}>{getUsersInitials(request.firstName ?? "", request.lastName ?? "")}</Avatar>
                                                                </Tooltip>
                                                            })
                                                            : <HowToRegIcon fontSize="large" color="action" />
                                                    }
                                                </AvatarGroup>
                                            </Stack>
                                        </Grid>
                                        <Grid display={{ xl: "inherit", lg: "inherit", md: "inherit", sm: "none", xs: "none" }}>
                                            <Stack direction="row">
                                                <AvatarGroup max={3}>
                                                    {todayRequestsSecondRow && todayRequestsSecondRow.map((request, index) => {
                                                        return <Tooltip key={index} title={`${request.firstName} ${request.lastName}`}>
                                                            <Avatar key={index} src={request.image && `data:image/png;base64,${request.image}`} sx={AVATAR_SIZES}>{getUsersInitials(request.firstName ?? "", request.lastName ?? "")}</Avatar>
                                                        </Tooltip>
                                                    })}
                                                </AvatarGroup>
                                            </Stack>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item width="50%" display="flex" justifyContent="center" alignItems="flex-start">
                                    <Box display="flex" flexDirection="column" alignItems="left" justifyContent="left" gap=".6vh">
                                        <Typography variant="body2">{t(TranslationKeyEnum.upcomingTimeOffNextDays)}</Typography>
                                        <Grid>
                                            <Stack direction="row">
                                                <AvatarGroup max={3}>
                                                    {
                                                        requestsFirstRow?.length
                                                            ? requestsFirstRow.map((request, index) => {
                                                                return <Tooltip key={index} title={`${request.firstName} ${request.lastName}`}>
                                                                    <Avatar key={index} src={request.image && `data:image/png;base64,${request.image}`} sx={AVATAR_SIZES}>{getUsersInitials(request.firstName ?? "", request.lastName ?? "")}</Avatar>
                                                                </Tooltip>
                                                            })
                                                            : <HowToRegIcon fontSize="large" color="action" />
                                                    }
                                                </AvatarGroup>
                                            </Stack>
                                        </Grid>
                                        <Grid display={{ xl: "inherit", lg: "inherit", md: "inherit", sm: "none", xs: "none" }}>
                                            <Stack direction="row">
                                                <AvatarGroup max={3}>
                                                    {requestsSecondRow && requestsSecondRow.map((request, index) => {
                                                        return <Tooltip key={index} title={`${request.firstName} ${request.lastName}`}>
                                                            <Avatar key={index} src={request.image && `data:image/png;base64,${request.image}`} sx={AVATAR_SIZES}>{getUsersInitials(request.firstName ?? "", request.lastName ?? "")}</Avatar>
                                                        </Tooltip>
                                                    })}
                                                </AvatarGroup>
                                            </Stack>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </>
                            // No next time offs
                            : <Box margin="auto">
                                <Box display="flex" justifyContent="center" marginBottom={SPACING_EXTRA_SMALL}>
                                    <CelebrationIcon color="action" sx={{ fontSize: DASHBOARD_ICON_SIZE }} />
                                </Box>
                                <Typography variant="body2" textAlign="center" color="gray" padding={`0 ${SPACING_SMALL}`}>
                                    {t(TranslationKeyEnum.noTimeOffs)}
                                </Typography>
                            </Box>
                }
            </Grid>
            {/* Card Footer */}
            <Box width="100%" height={CARD_FOOTER_HEIGHT} minHeight={MIN_CARD_FOOTER_HEIGHT} display="flex" alignItems="center" justifyContent="center">
                <Button variant="text" sx={{ textTransform: getTextTransformationAccordingToLanguage() }} size="small" component={Link} to="/calendar">
                    {t(TranslationKeyEnum.upcomingTimeOffLinkLabel)}
                    <Link to="/calendar" style={{ display: "flex", marginLeft: "1vh" }}>
                        <ArrowForwardIcon fontSize="small" color="primary" />
                    </Link>
                </Button>
            </Box>
        </Grid>
    )
}

export default UpcomingTimeOff;