import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { TranslationKeyEnum } from "../../../features/translations/TranslationKeyEnum";

export function usePolicyFormSchemas() {
    const { t } = useTranslation();
    const MINIMUM_INPUT_LENGTH = 2;

    return Yup.object().shape({
        name: Yup.string().min(MINIMUM_INPUT_LENGTH, t(TranslationKeyEnum.minimumInputLength, { fieldName: t(TranslationKeyEnum.firstName), minimumLength: MINIMUM_INPUT_LENGTH })).required(t(TranslationKeyEnum.required)),
        infiniteDays: Yup.boolean().optional(),
        allowNegativeBalance: Yup.boolean().optional(),
        dependsOnCheck: Yup.boolean().optional(),
        dependsOnPolicy: Yup.string().when('dependsOnCheck', {
            is: true,
            then: schema => schema.required(t(TranslationKeyEnum.required)),
        })
        .when('dependsOnCheck', {
            is: false,
            then: schema => schema
        }),
        startDate: Yup.date()
            .required(t(TranslationKeyEnum.required)),
        endDate: Yup.date()
            .required(t(TranslationKeyEnum.required)),
        color: Yup.string()
            .min(MINIMUM_INPUT_LENGTH, t(TranslationKeyEnum.minimumInputLength, { fieldName: t(TranslationKeyEnum.policyColor), minimumLength: MINIMUM_INPUT_LENGTH }))
            .required(t(TranslationKeyEnum.required)),
    })
}