import { FormikConfig, FormikValues } from "formik";
import { useRequestTimeOffSchema } from "./requestTimeOffSchema";

export function useRequestTimeOffFormikForm(): (handleSubmit: () => void) => FormikConfig<FormikValues> {
    const requestTimeOffSchema = useRequestTimeOffSchema();
    function requestTimeOffFormikForm(handleSubmit: () => void): FormikConfig<FormikValues> {
        return {
            initialValues: {
                policy: "",
                comment: "",
            },
            validationSchema: requestTimeOffSchema,
            onSubmit: handleSubmit
        };
    }
    return requestTimeOffFormikForm;
}