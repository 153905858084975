import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import AppRouter from "./routes/AppRouter";
import { SnackbarProvider } from 'notistack'
import { useBeforeUnload } from "react-router-dom";
import { removeIncomingURLFromStorage } from "./utils/storageUtils/storageUtils";

const NOTIFICATION_DURATION_TIME = 2500;

function App() {
  useBeforeUnload(() =>  removeIncomingURLFromStorage());
  
  return <SnackbarProvider autoHideDuration={NOTIFICATION_DURATION_TIME}>
    <AppRouter />
  </SnackbarProvider>
}

export default App;