import { INotificationResponseModel } from "../components/NotificationCenter/INotificationResponseModel";
import { useTryCatchJsonByAction } from "../utils/fetchUtils";
import { NotificationClient, NotificationDto } from "./notification-service";

const notificationClient = new NotificationClient();

export function useGetNotifications(): (userId: string) => Promise<INotificationResponseModel> {
    const tryCatchAction = useTryCatchJsonByAction(false);
    async function handleGetNotifications(userId: string): Promise<INotificationResponseModel> {
        const bindedAction = getNotificationsAction.bind(null, userId);
        const getPermissionsResponse = await tryCatchAction(bindedAction);
        return getPermissionsResponse.success
            ? { notifications: getPermissionsResponse.response, success: getPermissionsResponse.success }
            : { notifications: [], success: getPermissionsResponse.success };
    }
    return handleGetNotifications;
}

const getNotificationsAction = (userId: string): Promise<NotificationDto[]> => {
    return notificationClient.getAll(userId);
}

export function useUpdateNotification(): (notification: NotificationDto) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleUpdateNotification(notification: NotificationDto): Promise<boolean> {
        const bindedAction = updateNotificationAction.bind(null, notification);
        const response = await tryCatchAction(bindedAction);
        return response.success;
    }
    return handleUpdateNotification;
}

const updateNotificationAction = (notification: NotificationDto): Promise<boolean> => {
    return notificationClient.update(notification);
}

export function useMarkAllAsReadAction(): (notificationIds: string[]) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleMarkAllAsRead(notificationIds: string[]): Promise<boolean> {
        const bindedAction = markAllAsReadAction.bind(null, notificationIds);
        const response = await tryCatchAction(bindedAction);
        return response.success;
    }
    return handleMarkAllAsRead;
}

const markAllAsReadAction = (notificationIds: string[]): Promise<boolean> => {
    return notificationClient.markAllAsRead(notificationIds);
}