import React from 'react';
import WorkInfo from './WorkInfo/WorkInfo';
import WorkOrdersInfo from './WorkOrdersInfo/WorkOrdersInfo';
import TimeOffInfo from './TimeOffInfo/TimeOffInfo';
import AdditionalInfo from './AdditionalInfo/AdditionalInfo';
import PermissionContext from '../Permission/PermissionContext';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useGetUser } from '../../API/user-actions';
import { IUserProps } from '../../components/common/interfaces/IUserProps';
import { PRIMARY_BG_COLOR, BORDER_RADIUS_MEDIUM, CARD_BOX_SHADOW } from '../../utils/cssUtils';
import { getUserCurrentPosition } from '../../components/common/UserProfile/helpers';
import { getUserIdFromStorage } from '../../utils/storageUtils/storageUtils';
import { IEmployeeProfile } from './IEmployeeProfile';
import { PermissionsEnum } from '../Permission/PermisionEnum';
import { UserDto } from '../../API/time-off-service';
import { useGetTimeOffUser } from '../../API/time-off-actions';
import { isDateYearOne } from '../../utils/dateUtils';

export default function EmployeeProfile({ isLoggedUserProfile }: IEmployeeProfile) {
  const params = useParams();
  const getUser = useGetUser();
  const getTimeOffUser = useGetTimeOffUser();
  const { isAllowed } = React.useContext(PermissionContext);

  const [timeOffUser, setTimeOffUser] = React.useState<UserDto>();
  const [id, setId] = React.useState<string>("");
  const [user, setUser] = React.useState<IUserProps>();

  const [hasManageUsersPermission, setHasManageUsersPermission] = React.useState<boolean>(false);

  React.useEffect(() => {
    const fetchPermissions = async () => {
      setHasManageUsersPermission(await isAllowed(PermissionsEnum.ManageUsersPermission));
    }

    fetchPermissions();
  }, [])

  React.useEffect(() => {
    const fetchUser = async () => {
      isLoggedUserProfile ? setId(getUserIdFromStorage()!) : setId(params.id!);
      if (!id) return;
      let user = await getUser(id);
      let timeOffUser = await getTimeOffUser(id);
      if (!timeOffUser) return;
      setTimeOffUser(timeOffUser);
      if (!user) return;
      setUser(user);
    }
    fetchUser();
  }, [id, isLoggedUserProfile])

  // Info is visible if the user is on his own profile or the user is the user's approver and any of the additional info fields have value
  const shouldAdditionalInfoBeVisible = (user: IUserProps): boolean => {
    if ((hasManageUsersPermission || user.userId === getUserIdFromStorage() || timeOffUser?.approverId === getUserIdFromStorage())
      && (user.personalNo
        || user.passportNumber
        || user.idCardNumber
        || user.medicalInsuranceNumber
        || user.files.length
        || !isDateYearOne(new Date(user.passportValidUntil))
        || !isDateYearOne(new Date(user.contractExpiration))
        || !isDateYearOne(new Date(user.idCardValidUntil))
        || !isDateYearOne(new Date(user.doctorAppointment))
        || !isDateYearOne(new Date(user.contractExpiration))
        || !isDateYearOne(new Date(user.religiousDay)))) return true;
    return false;
  }

  return (
    <Grid container boxShadow={CARD_BOX_SHADOW} borderRadius={BORDER_RADIUS_MEDIUM} sx={{ backgroundColor: PRIMARY_BG_COLOR }}>
      {
        user && <>
          <WorkInfo id={user.userId} firstName={user.firstName} lastName={user.lastName} image={user.image} position={getUserCurrentPosition(user.positions)!} email={user.email} office={user.office} phoneNumber={user.phone} dateOfBirth={user.birthdate} positions={user.positions} />
          {
            shouldAdditionalInfoBeVisible(user)
            && <AdditionalInfo
              personalNo={user.personalNo}
              passportNumber={user.passportNumber}
              passportValidUntil={user.passportValidUntil}
              contractExpiration={user.contractExpiration}
              idCardNumber={user.idCardNumber}
              idCardValidUntil={user.idCardValidUntil}
              doctorAppointment={user.doctorAppointment}
              certificateValidUntil={user.certificateValidUntil}
              medicalInsuranceNumber={user.medicalInsuranceNumber}
              religiousDay={user.religiousDay}
              documents={user.files}
            />
          }
          <TimeOffInfo userId={id} />
          <WorkOrdersInfo userId={id} />
        </>
      }
    </Grid>
  )
}