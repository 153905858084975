import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TranslationKeyEnum } from '../../features/translations/TranslationKeyEnum';
import { CARD_BOX_SHADOW, SPACING_SMALL, PRIMARY_BG_COLOR, LINE_BORDER, BORDER_RADIUS_MEDIUM, CARD_HEIGHT, SPACING_MEDIUM, MIN_CARD_HEIGHT, LINE_WIDTH } from '../../utils/cssUtils';
import { useAtom } from 'jotai';
import { userAtom } from '../AppContainer/AppContainer';
import { getPropertyFromJwtToken } from '../../helpers/getPropertyFromJwtToken';

export default function WelcomeBackCard() {
    const { t } = useTranslation();
    const [user] = useAtom(userAtom);

    return <Box display="flex" alignItems="center" justifyContent="center"
        sx={{ height: CARD_HEIGHT, minHeight: MIN_CARD_HEIGHT, padding: `0 ${SPACING_MEDIUM}`, borderRadius: BORDER_RADIUS_MEDIUM, boxShadow: CARD_BOX_SHADOW, backgroundColor: PRIMARY_BG_COLOR, marginBottom: SPACING_SMALL }}>
        <Box height={CARD_HEIGHT} display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={SPACING_MEDIUM}>
            <Typography variant="h5" textAlign="center">{t(TranslationKeyEnum.welcomeBack, { firstName: user.firstName })}</Typography>
            <Typography variant="body2" textAlign="center">
                {t(TranslationKeyEnum.whatIsNew, { companyName: getPropertyFromJwtToken("companyName") })}
            </Typography>
            <Box width={LINE_WIDTH} margin="0 auto" border={LINE_BORDER} />
        </Box>
    </Box>
}
