import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { TRANSLATIONS_SR } from "./sr/translations";
import { TRANSLATIONS_EN } from "./en/translations";
import { languages, LanguageType } from "./LanguageTypes";

const KEY = "lang";

export const getLanguage = (): string => (JSON.parse(localStorage.getItem(KEY)!) ?? languages[0] as LanguageType).language;
export const getLanguageLocale = (): string => (JSON.parse(localStorage.getItem(KEY)!) ?? languages[0] as LanguageType).locale;

const getDefaultLanguage = (): LanguageType => {
  //if there is no language already set up in storage we return first one in language array as default otherwise we return already selected language
  return localStorage.getItem(KEY) === null ? languages[0] : languages.find((lan: LanguageType) => lan.language === getLanguage())!;
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      SR: {
        translation: TRANSLATIONS_SR
      },
      EN: {
        translation: TRANSLATIONS_EN
      }
    }
  });

const defaultLanguage = getDefaultLanguage();
i18n.changeLanguage(defaultLanguage.language);
localStorage.setItem(KEY, JSON.stringify(defaultLanguage));

export default i18n;