import { Box, Typography } from '@mui/material';
import {
    BORDER_RADIUS_LARGE, CARD_BOX_SHADOW, LIGHT_BORDER, MONTH_AND_DATE_MINI_CALENDAR_BACKGROUND, MONTH_AND_DATE_MINI_CALENDAR_BODY_HEIGTH, MONTH_AND_DATE_MINI_CALENDAR_BODY_MIN_HEIGTH,
    MONTH_AND_DATE_MINI_CALENDAR_HEADER_HEIGTH, MONTH_AND_DATE_MINI_CALENDAR_HEADER_MIN_HEIGTH, MONTH_AND_DATE_MINI_CALENDAR_HEIGTH, MONTH_AND_DATE_MINI_CALENDAR_MIN_HEIGTH,
    MONTH_AND_DATE_MINI_CALENDAR_MIN_WIDTH, MONTH_AND_DATE_MINI_CALENDAR_WIDTH
} from '../../../utils/cssUtils';
import { ICalendarIconProps } from './CalendarIconProps';

export default function CalendarIcon({ date }: ICalendarIconProps) {
    return (
        <Box
            width={MONTH_AND_DATE_MINI_CALENDAR_WIDTH}
            minWidth={MONTH_AND_DATE_MINI_CALENDAR_MIN_WIDTH}
            height={MONTH_AND_DATE_MINI_CALENDAR_HEIGTH}
            minHeight={MONTH_AND_DATE_MINI_CALENDAR_MIN_HEIGTH}
            borderRadius={BORDER_RADIUS_LARGE}
            border={LIGHT_BORDER}
            boxShadow={CARD_BOX_SHADOW}
        >
            {/* Card header */}
            <Box
                height={MONTH_AND_DATE_MINI_CALENDAR_HEADER_HEIGTH}
                minHeight={MONTH_AND_DATE_MINI_CALENDAR_HEADER_MIN_HEIGTH}
                borderRadius={`${BORDER_RADIUS_LARGE} ${BORDER_RADIUS_LARGE} 0px 0px`}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ background: MONTH_AND_DATE_MINI_CALENDAR_BACKGROUND }}
            >
                <Typography variant="caption" color="white">
                    {date?.toLocaleString('default', { month: 'short' }).toLocaleUpperCase()}
                </Typography>
            </Box>
            {/* Card body */}
            <Typography
                height={MONTH_AND_DATE_MINI_CALENDAR_BODY_HEIGTH}
                minHeight={MONTH_AND_DATE_MINI_CALENDAR_BODY_MIN_HEIGTH}
                variant="h4"
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                {date?.getDate()}
            </Typography>
        </Box>
    )
}