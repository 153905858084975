import { DayOfWeek } from "./DayOfWeek";

const MILLIS_PER_SECOND = 1000;
const MILLIS_PER_MINUTE = MILLIS_PER_SECOND * 60;   //     60,000
const MILLIS_PER_HOUR = MILLIS_PER_MINUTE * 60;     //  3,600,000
const MILLIS_PER_DAY = MILLIS_PER_HOUR * 24;        // 86,400,000

export class DateRange {
    public startDate: Date;
    public endDate: Date;

    public constructor(startDate: Date, endDate: Date) {
        this.startDate = startDate;
        this.endDate = endDate;
    }

    /**Determines whether the date range is overlapping with provided date range */
    public isOverlapped(other: DateRange): boolean {
        return this.startDate <= other.endDate && other.startDate <= this.endDate;
    }

    /**Calculates the number of total days in the date range. */
    private numberOfDaysInBetween(): number {
        // setting up hours, so that the whole days are included into calculation
        this.startDate.setHours(0, 0, 0);
        this.endDate.setHours(23, 59, 59);

        const intervalTotalDays: number = (this.endDate.getTime() - this.startDate.getTime()) / MILLIS_PER_DAY;

        return intervalTotalDays > 0 ? Math.ceil(intervalTotalDays) : 0;
    }

    /**Calculates the number of business days (exclude weekends) in the date range. */
    public numberOfBusinessDaysInBetween(): number {
        const totalDays: number = this.numberOfDaysInBetween();
        const startDateDayOfWeek = this.startDate.getDay();
        const endDateDayOfWeek = this.endDate.getDay();

        // if only the weekend is selected
        if (totalDays === 2
            && startDateDayOfWeek === DayOfWeek.Saturday
            && endDateDayOfWeek === DayOfWeek.Sunday)
            return 0;

        // formula for estimating work days in date range
        var calcBusinessDays = 1 + Math.floor((totalDays * 5 - (startDateDayOfWeek - endDateDayOfWeek) * 2) / 7);

        // correction for weekends duo to DayOfWeek enumeration
        if (startDateDayOfWeek === DayOfWeek.Sunday) calcBusinessDays--;
        if (endDateDayOfWeek === DayOfWeek.Saturday) calcBusinessDays--;

        return calcBusinessDays;
    }

    /**Calculates the number of total overlapping days between the date range and provided date range. */
    private numberOfDaysThatOverlapWith(other: DateRange): number {
        return this.getOverlappingDateRange(other).numberOfDaysInBetween();
    }

    public numberOfBusinessDaysThatOverlapWith(other: DateRange): number {
        return this.getOverlappingDateRange(other).numberOfBusinessDaysInBetween();
    }

    /**Determines the date range that is overlapping. */
    public getOverlappingDateRange(other: DateRange): DateRange {
        const maxStartDate = this.startDate > other.startDate ? this.startDate : other.startDate;
        const minEndDate = this.endDate < other.endDate ? this.endDate : other.endDate;

        return new DateRange(maxStartDate, minEndDate);
    }
}