import { BankHolidayDto, BasicTimeOffPolicyDto, PolicyClient, RequestBasicVM, RequestClient, UserClient } from "../../API/time-off-service";
import { useGetUsersWithReservedDates, useGetUsersWithReservedDatesFromExternalView, useGetUsersWithWorkOrdersForCalendar, useGetUsersWithWorkOrdersForCalendarFromExternalView, useGetWorkOrdersForCalendar } from "../../API/work-order-actions";
import { CalendarUserDto } from "../../API/work-order-service";
import * as TimeOffActions from "../../API/time-off-actions";
import { useTryCatchJsonByAction } from "../../utils/fetchUtils";
import { addDays, mapBankHolidayDTOsToCalendarEvents, mapRequestBasicVMsToCalendarEvents, mapUsersVMsToCalendarEvents, mapWorkOrdersVMsToCalendarEvents, subtractDays, toStringYYYYMMDD } from "../common/Calendar/helpers";
import { CalendarItemType, ICalendarEvent } from "../common/Calendar/ICalendarProps";
import { useTranslation } from "react-i18next";
import { TranslationKeyEnum } from "../../features/translations/TranslationKeyEnum";
import { dateRange } from "../../utils/dateUtils";

export interface ICalendarEventsPair {
    availableEvents: ICalendarEvent[],
    timeOffEvents: ICalendarEvent[],
}

const userClient = new UserClient();
const getBankHolidays = (): Promise<BankHolidayDto[]> => userClient.getUserBankHolidays();
const getBankHolidaysForUser = (userId: string): Promise<BankHolidayDto[]> => userClient.getUserBankHolidays2(userId);

const requestClient = new RequestClient();
const getRequestsForCalendar = (): Promise<RequestBasicVM[]> => requestClient.getAllForCalendarDisplay();
const getRequestsForCalendarFromExternalView = (): Promise<RequestBasicVM[]> => requestClient.getAllForCalendarDisplayFromExternalView();

const policyClient = new PolicyClient();
const getBasicPolicies = (): Promise<BasicTimeOffPolicyDto[]> => policyClient.getAllBasic();

export function useGetCalendarEvents(): (userId?: string) => Promise<ICalendarEvent[]> {
    const tryCatchAction = useTryCatchJsonByAction();

    async function getCalendarEvents(userId?: string): Promise<ICalendarEvent[]> {
        const bindedGetBankHolidaysAction = !userId ? getBankHolidays.bind(null) : getBankHolidaysForUser.bind(null, userId);
        const bankHolidayModel = await tryCatchAction(bindedGetBankHolidaysAction);

        const bindedGetRequestsForCalendarAction = getRequestsForCalendar.bind(null);
        const requestModel = await tryCatchAction(bindedGetRequestsForCalendarAction);

        const holidayEvents = bankHolidayModel.response
            ? mapBankHolidayDTOsToCalendarEvents(bankHolidayModel.response as BankHolidayDto[])
            : [];

        const requestEvents = requestModel.response
            ? mapRequestBasicVMsToCalendarEvents(requestModel.response as RequestBasicVM[])
            : [];
        return holidayEvents.concat(requestEvents);
    }

    return getCalendarEvents;
}

export function useGetTimeOffRequests(): (userId?: string) => Promise<ICalendarEvent[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getTimeOffRequests(userId?: string): Promise<ICalendarEvent[]> {
        const bindedGetRequestsForCalendarAction = getRequestsForCalendar.bind(null);
        const requestModel = await tryCatchAction(bindedGetRequestsForCalendarAction);
        const requestEvents = requestModel.response
            ? mapRequestBasicVMsToCalendarEvents(requestModel.response as RequestBasicVM[])
            : [];
        return requestEvents;
    }

    return getTimeOffRequests;
}

export function useGetTimeOffRequestsFromExternalView(): (userId?: string) => Promise<ICalendarEvent[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getTimeOffRequests(userId?: string): Promise<ICalendarEvent[]> {
        const bindedGetRequestsForCalendarAction = getRequestsForCalendarFromExternalView.bind(null);
        const requestModel = await tryCatchAction(bindedGetRequestsForCalendarAction);
        const requestEvents = requestModel.response
            ? mapRequestBasicVMsToCalendarEvents(requestModel.response as RequestBasicVM[])
            : [];
        return requestEvents;
    }

    return getTimeOffRequests;
}

export function useGetWeekCalendarEventsForAllWorkOrders(): (userIds: string[]) => Promise<ICalendarEvent[]> {
    const getWorkOrdersForCalendar = useGetWorkOrdersForCalendar();

    async function getWeekcalendarEventsForAllWorkOrders(userIds: string[]): Promise<ICalendarEvent[]> {
        const workOrders = await getWorkOrdersForCalendar(userIds);
        const workOrdersEvents = workOrders && mapWorkOrdersVMsToCalendarEvents(workOrders, CalendarItemType.WorkOrder)
        return workOrdersEvents;
    }

    return getWeekcalendarEventsForAllWorkOrders;
}

export function useGetWeekCalendarEventsForUsersWithWorkOrders(externalView: boolean): (userIds: string[], isLocationTypeNeeded: boolean) => Promise<ICalendarEvent[]> {
    const getUsersForCalendar = useGetUsersWithWorkOrdersForCalendar();
    const getUsersForCalendarFromExternalView = useGetUsersWithWorkOrdersForCalendarFromExternalView();
    const fetchUsers = externalView ? getUsersForCalendarFromExternalView : getUsersForCalendar;

    async function getWeekcalendarEventsForUsersWithWorkOrders(userIds: string[], isLocationTypeNeeded: boolean): Promise<ICalendarEvent[]> {
        const users = await fetchUsers(userIds, isLocationTypeNeeded);
        const usersEvents = users && mapUsersVMsToCalendarEvents(users, CalendarItemType.WorkOrder);
        return usersEvents;
    }

    return getWeekcalendarEventsForUsersWithWorkOrders;
}

export function useGetWeekCalendarEventsForAvailableUsers(externalView: boolean, includeTimeOffEvents: boolean): (userIds: string[]) => Promise<ICalendarEvent[]> {
    const { t } = useTranslation();
    const getUsersWithReservedDates = useGetUsersWithReservedDates();
    const getTimeOffUsersWithReservedDates = TimeOffActions.useGetUsersWithReservedDates();

    const getUsersWithReservedDatesFromExternalView = useGetUsersWithReservedDatesFromExternalView();
    const getTimeOffUsersWithReservedDatesFromExternalView = TimeOffActions.useGetUsersWithReservedDatesFromExternalView();

    const fetchWorkOrderUsers = externalView ? getUsersWithReservedDatesFromExternalView : getUsersWithReservedDates;
    const fetchTimeOffUsers = externalView ? getTimeOffUsersWithReservedDatesFromExternalView : getTimeOffUsersWithReservedDates;

    const availabilityRangeStart = new Date(); // min value
    availabilityRangeStart.setFullYear(availabilityRangeStart.getFullYear() - 1);
    availabilityRangeStart.setHours(0, 0, 0);
    const availabilityRangeEnd = new Date(); // max value
    availabilityRangeEnd.setFullYear(availabilityRangeEnd.getFullYear() + 1);
    availabilityRangeEnd.setHours(23, 59, 59);

    function getAvailableRanges(reservations: CalendarUserDto[], displayName: string): CalendarUserDto[] {
        let availableRanges: CalendarUserDto[] = [];

        let currentRangeStart = availabilityRangeStart;

        reservations.forEach(reservation => {
            if (currentRangeStart < reservation.start!) {
                const availableRange = new CalendarUserDto({
                    displayName: displayName,
                    start: addDays(currentRangeStart, 1), // so that we don't overlap with (potential) next event from the same user
                    end: subtractDays(reservation.start!, 1) // so that we don't overlap with (potential) next event from the same user
                });
                availableRanges.push(availableRange);
            }

            currentRangeStart = reservation.end!;
        });

        if (currentRangeStart < availabilityRangeEnd) {
            const availableRange = new CalendarUserDto({
                displayName: displayName,
                start: addDays(currentRangeStart, 1), // so that we don't overlap with (potential) next event from the same user
                end: availabilityRangeEnd
            });
            availableRanges.push(availableRange);
        }

        return availableRanges;
    }

    function getMergedReservations(reservations: CalendarUserDto[]): CalendarUserDto[] {
        if (reservations === null || reservations === undefined || reservations.length == 0) return [] as CalendarUserDto[];

        reservations.sort((a, b) => {
            const dateA = new Date(a.start!);
            const dateB = new Date(b.start!);
            return dateA.getTime() - dateB.getTime();
        });

        var mergedReservations: CalendarUserDto[] = [reservations[0]];
        for (let i = 0; i < reservations.length; i++) {
            var currentReservation = reservations[i];
            var lastMergedReservation = mergedReservations[mergedReservations.length - 1];

            if (currentReservation.start! <= lastMergedReservation.end! || currentReservation.start! <= addDays(lastMergedReservation.end!, 1)) {
                lastMergedReservation.end! = (currentReservation.end! > lastMergedReservation.end! ? currentReservation.end! : lastMergedReservation.end!);
            }
            else {
                mergedReservations.push(currentReservation);
            }
        }

        return mergedReservations;
    }

    async function getWeekcalendarEventsForAvailableUsers(userIds: string[]): Promise<ICalendarEvent[]> {
        const users = await fetchWorkOrderUsers(userIds);
        let availableEvents: CalendarUserDto[][] = [];
        let dictionary: { [key: string]: CalendarUserDto[] } = {}
        users.forEach(user => {
            dictionary[user.id!] = user.reservations!;
        });

        const timeOffUsers = await fetchTimeOffUsers(userIds);
        timeOffUsers.forEach(user => {
            availableEvents.push(getAvailableRanges(getMergedReservations(user.reservations!.concat(dictionary[user.id!])), user.fullName!))
        });

        //take all dates in event date range and create separate event for each one
        const eventsArray = availableEvents
            .flatMap(eventArr => eventArr)
            .flatMap(event => {
                const eventDateRange = dateRange(event.start!, event.end!);
                return eventDateRange.map(date => new CalendarUserDto({
                    displayName: event.displayName,
                    start: new Date(date),
                    end: new Date(date)
                }));
            });

        let timeOffEventsResult: CalendarUserDto[] = [];
        if (includeTimeOffEvents) {
            timeOffUsers.forEach(user => {
                timeOffEventsResult = timeOffEventsResult.concat(user.reservations!.map(r => new CalendarUserDto({
                    userId: user.id,
                    displayName: user.fullName,
                    start: r.start,
                    end: r.end
                })));
            });
        }

        const availableEventsResult = timeOffUsers && mapUsersVMsToCalendarEvents(eventsArray, CalendarItemType.Avaiable)
        return availableEventsResult.concat(mapUsersVMsToCalendarEvents(timeOffEventsResult, CalendarItemType.TimeOff));
    }

    return getWeekcalendarEventsForAvailableUsers;
}


export function useGetAllUsersEvents(externalView: boolean): (userIds: string[], isLocationTypeNeeded: boolean, startDate: Date, endDate: Date) => Promise<ICalendarEvent[]> {
    const { t } = useTranslation();
    const getWeekCalendarEventsForUsersWithWorkOrders = useGetWeekCalendarEventsForUsersWithWorkOrders(externalView);
    const getWeekCalendarEventsForAvailableUser = useGetWeekCalendarEventsForAvailableUsers(externalView, true);

    async function getAllUsersEvents(userIds: string[], isLocationTypeNeeded: boolean, startDate: Date, endDate: Date): Promise<ICalendarEvent[]> {
        const WORK_ORDER_EVENT_COLOR = "#f3e5f5";
        const TIME_OFF_EVENT_COLOR = "#f1f8e9";
        const AVAILABLE_EVENT_COLOR = "#e1f5fe";
        const WORK_ORDER_EVENT_TEXT_COLOR = "#4A148C";
        const TIME_OFF_EVENT_TEXT_COLOR = "#1B5E20";
        const AVAILABLE_EVENT_TEXT_COLOR = "#01579B";

        const startToEndDateRange: Date[] = dateRange(startDate, endDate);
        const events: ICalendarEvent[] = [];

        const [workOrderEvents, availableAndTimeOffEvents] = await Promise.all([
            getWeekCalendarEventsForUsersWithWorkOrders(userIds, false),
            getWeekCalendarEventsForAvailableUser(userIds)
        ]);

        const availableEvents: ICalendarEvent[] = [];
        const timeOffEvents: ICalendarEvent[] = [];

        availableAndTimeOffEvents.forEach(event => {
            if (event.type === CalendarItemType.Avaiable) {
                availableEvents.push(event)
            } else {
                timeOffEvents.push(event)
            }
        });

        const resetHours = (date: Date): Date => {
            const newDate = new Date(date);
            newDate.setHours(0, 0, 0);
            return newDate;
        }

        const doesEventForThisUserAlreadyExists = (allEvents: ICalendarEvent[], currentEvent: ICalendarEvent, date: Date): boolean => {
            const eventObject = allEvents.find(
                (event: ICalendarEvent) =>
                    event.userId === currentEvent.userId &&
                    event.start === toStringYYYYMMDD(date)
            );
            return !!eventObject;
        }

        const datesWithResetedHours = (currentEvent: ICalendarEvent): Date[] => {
            const endDateWithSubstractedDay = subtractDays(new Date(currentEvent.end), 1);
            const startDateWithResetedHours = resetHours(new Date(currentEvent.start));
            const endDateWithResetedHours = resetHours(new Date(endDateWithSubstractedDay));
            return [startDateWithResetedHours, endDateWithResetedHours]
        }

        const addEvent = (currentEvent: ICalendarEvent, title: string, backgroundColor: string, textColor: string, date: Date) => {
            events.push({
                id: currentEvent.id,
                userId: currentEvent.userId,
                title: title,
                start: toStringYYYYMMDD(date),
                end: toStringYYYYMMDD(date),
                color: backgroundColor,
                textColor: textColor,
                classNames: ["all-events", "weekCalendarOverrides"],
                type: currentEvent.type
            });
        };

        startToEndDateRange.forEach(date => {
            workOrderEvents.forEach((workOrderEvent: ICalendarEvent) => {
                const [start, end] = datesWithResetedHours(workOrderEvent);
                if (date >= start && date <= end && !doesEventForThisUserAlreadyExists(events, workOrderEvent, date)) {
                    addEvent(workOrderEvent, workOrderEvent.title, WORK_ORDER_EVENT_COLOR, WORK_ORDER_EVENT_TEXT_COLOR, date);
                }
            });

            availableEvents.forEach(availableEvent => {
                const [start, end] = datesWithResetedHours(availableEvent);
                if (date >= start && date <= end) {
                    addEvent(availableEvent, availableEvent.title + "\n" + t(TranslationKeyEnum.employeeAvailable), AVAILABLE_EVENT_COLOR, AVAILABLE_EVENT_TEXT_COLOR, date);
                }
            });

            timeOffEvents.forEach((timeOffEvent) => {
                const [start, end] = datesWithResetedHours(timeOffEvent);
                if (date >= start && date <= end && !doesEventForThisUserAlreadyExists(events, timeOffEvent, date) && (!userIds.length || userIds.includes(timeOffEvent.userId!))) {
                    addEvent(timeOffEvent, timeOffEvent.title + "\n" + t(TranslationKeyEnum.timeOff), TIME_OFF_EVENT_COLOR, TIME_OFF_EVENT_TEXT_COLOR, date);
                }
            });
        });
        return events;
    }
    return getAllUsersEvents;
}

export function useGetPolicies(): () => Promise<BasicTimeOffPolicyDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();

    async function getPolicies(): Promise<BasicTimeOffPolicyDto[]> {
        const bindedGetPoliciesAction = getBasicPolicies.bind(null);
        const policiesModel = await tryCatchAction(bindedGetPoliciesAction);

        return policiesModel.response ? policiesModel.response : []
    }

    return getPolicies;
}