import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import DownloadIcon from '@mui/icons-material/Download';
import { APP_BG_COLOR, BORDER_RADIUS_MEDIUM, CARD_BOX_SHADOW, LINE_BORDER, SPACING_EXTRA_LARGE, SPACING_LARGE, SPACING_MEDIUM, SPACING_SMALL } from '../../../utils/cssUtils';
import { Grid, Box, Typography, Tooltip, Button } from '@mui/material';
import { TranslationKeyEnum } from '../../../features/translations/TranslationKeyEnum';
import { StyledReadonlyTextField } from '../../../components/StyledReadonlyTextField/StyledReadonlyTextField';
import { useTranslation } from 'react-i18next';
import { IAdditionalInfo } from './IAdditionalInfo';
import { toStringDDMMYYYY } from '../../../components/common/Calendar/helpers';
import { UserDocumentDto } from '../../../API/user-management-service';
import { useGetDocument } from '../../../API/user-actions';
import { download } from '../../../utils/fetchUtils';
import { isDateYearOne } from '../../../utils/dateUtils';

export default function AdditionalInfo({ personalNo, passportNumber, passportValidUntil, contractExpiration, idCardNumber, idCardValidUntil, doctorAppointment, certificateValidUntil, medicalInsuranceNumber, religiousDay, documents }: IAdditionalInfo) {
    const { t } = useTranslation();
    const getDocument = useGetDocument();

    const handleDocumentDownload = async (id: string, name: string): Promise<void> => {
        let file = await getDocument(id, name);
        if(!file) return;
        download(file, name);
    }

    return (
        < Grid container padding={SPACING_LARGE} >
            {/* Labels */}
            < Grid item xl={6} lg={6} md={6} sm={12} xs={12} >
                <PersonAddAlt1Icon color="primary" fontSize="large" sx={{ marginBottom: SPACING_SMALL, marginLeft: ".8vh" }} />
                <Typography variant="h5" marginBottom={SPACING_SMALL}>{t(TranslationKeyEnum.additionalInfo)}</Typography>
                <Typography variant="body2" marginBottom={SPACING_SMALL}>{t(TranslationKeyEnum.additionalPersonalInformation)}</Typography>
                <Box width={SPACING_EXTRA_LARGE} border={LINE_BORDER} />
            </Grid >
            {/* Additional Information */}
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} display="flex" justifyContent="center">
                <Box width="90%" minWidth="50%" display="flex" justifyContent="center" borderRadius={BORDER_RADIUS_MEDIUM} boxShadow={CARD_BOX_SHADOW} sx={{ backgroundColor: APP_BG_COLOR }}
                    marginTop={{ xl: "0", lg: "0", md: "0", sm: SPACING_LARGE, xs: SPACING_LARGE }} padding={`${SPACING_LARGE} 0`}>
                    <Box display="flex" flexDirection="column" width="90%">
                        {personalNo && <StyledReadonlyTextField
                            id="personalNo"
                            label={t(TranslationKeyEnum.personalNo)}
                            value={personalNo}
                            inputProps={{ readOnly: true, disabled: true }}
                            sx={{ marginBottom: SPACING_MEDIUM }}
                        />}
                        {passportNumber && <StyledReadonlyTextField
                            id="passportNumber"
                            label={t(TranslationKeyEnum.passportNumber)}
                            value={passportNumber}
                            inputProps={{ readOnly: true, disabled: true }}
                            sx={{ marginBottom: SPACING_MEDIUM }}
                        />}
                        {passportValidUntil && !isDateYearOne(new Date(passportValidUntil)) && <StyledReadonlyTextField
                            id="passportValidUntil"
                            label={t(TranslationKeyEnum.passportValidUntil)}
                            value={toStringDDMMYYYY(new Date(passportValidUntil))}
                            inputProps={{ readOnly: true, disabled: true }}
                            sx={{ marginBottom: SPACING_MEDIUM }}
                        />}
                        {contractExpiration && !isDateYearOne(new Date(contractExpiration)) && <StyledReadonlyTextField
                            id="contractExpiration"
                            label={t(TranslationKeyEnum.contractExpiration)}
                            value={toStringDDMMYYYY(new Date(contractExpiration))}
                            inputProps={{ readOnly: true, disabled: true }}
                            sx={{ marginBottom: SPACING_MEDIUM }}
                        />}
                        {idCardNumber && <StyledReadonlyTextField
                            id="idCardNumber"
                            label={t(TranslationKeyEnum.idCardNumber)}
                            value={idCardNumber}
                            inputProps={{ readOnly: true, disabled: true }}
                            sx={{ marginBottom: SPACING_MEDIUM }}
                        />}
                        {idCardValidUntil && !isDateYearOne(new Date(idCardValidUntil)) && <StyledReadonlyTextField
                            id="idCardValidUntil"
                            label={t(TranslationKeyEnum.idCardValidUntil)}
                            value={toStringDDMMYYYY(new Date(idCardValidUntil))}
                            inputProps={{ readOnly: true, disabled: true }}
                            sx={{ marginBottom: SPACING_MEDIUM }}
                        />}
                        {doctorAppointment && !isDateYearOne(new Date(doctorAppointment)) && <StyledReadonlyTextField
                            id="doctorAppointment"
                            label={t(TranslationKeyEnum.doctorAppointment)}
                            value={toStringDDMMYYYY(new Date(doctorAppointment))}
                            inputProps={{ readOnly: true, disabled: true }}
                            sx={{ marginBottom: SPACING_MEDIUM }}
                        />}
                        {certificateValidUntil && !isDateYearOne(new Date(certificateValidUntil)) && <StyledReadonlyTextField
                            id="certificateValidUntil"
                            label={t(TranslationKeyEnum.certificateValidUntil)}
                            value={toStringDDMMYYYY(new Date(certificateValidUntil))}
                            inputProps={{ readOnly: true, disabled: true }}
                            sx={{ marginBottom: SPACING_MEDIUM }}
                        />}
                        {medicalInsuranceNumber && <StyledReadonlyTextField
                            id="medicalInsuranceNumber"
                            label={t(TranslationKeyEnum.medicalInsuranceNumber)}
                            value={medicalInsuranceNumber}
                            inputProps={{ readOnly: true, disabled: true }}
                            sx={{ marginBottom: SPACING_MEDIUM }}
                        />}
                        {religiousDay && !isDateYearOne(new Date(religiousDay)) && <StyledReadonlyTextField
                            id="religiousDay"
                            label={t(TranslationKeyEnum.religiousDay)}
                            value={toStringDDMMYYYY(new Date(religiousDay))}
                            inputProps={{ readOnly: true, disabled: true }}
                            sx={{ marginBottom: SPACING_MEDIUM }}
                        />}
                        {
                            !!documents?.length
                            && <Box display="flex" flexDirection="column" gap={SPACING_SMALL}>
                                <Typography variant="body1">{t(TranslationKeyEnum.documents)}:</Typography>
                                {
                                    documents.map((document: UserDocumentDto) => {
                                        return <Box display="flex" alignItems="center" sx={{ cursor: "pointer" }}>
                                            <Tooltip title={document.name} placement="top">
                                                <Typography variant="body2" width="90%" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" >{document.name}</Typography>
                                            </Tooltip>
                                            <Button sx={{ width: "10%", marginLeft: "auto", color: "#212529" }} onClick={() => handleDocumentDownload(document.id!, document.name!)}>
                                                <DownloadIcon  />
                                            </Button>
                                        </Box>
                                    })
                                }
                            </Box>
                        }
                    </Box>
                </Box>
            </Grid>
        </Grid >
    )
}