import React from 'react'
import CreateEditArticleDialog from './CreateEditArticleDialog';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from '../Dialogs/ConfirmDialog/ConfirmDialog';
import i18n from '../../features/translations/i18n';
import { Tooltip, Typography, Box, Button } from "@mui/material";
import { CARD_BOX_SHADOW, SPACING_EXTRA_SMALL } from '../../utils/cssUtils';
import { StyledDataGrid } from '../StyledDataGrid/StyledDataGrid';
import { getTextTransformationAccordingToLanguage } from '../../features/translations/helpers';
import { useTranslation } from 'react-i18next';
import { TranslationKeyEnum } from '../../features/translations/TranslationKeyEnum';
import { LanguageEnum } from '../../features/translations/LanguageEnum';
import { srRS } from '../../features/translations/DataGrid/srRS';
import { ROWS_PER_PAGE_OPTIONS } from '../common/Constants/PaginationConstants';
import { GridActionsCellItem, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { useDeleteArticle, useGetArticles } from '../../API/newsFeedActions';
import { ArticleDto } from '../../API/user-management-service';

export default function AdminNewsFeed() {
  const { t } = useTranslation();
  const getArticles = useGetArticles();
  const deleteArticle = useDeleteArticle();

  const [rows, setRows] = React.useState<ArticleDto[]>([]);
  const [id, setId] = React.useState<string>("");
  const [isArticleFormOpen, setIsArticleFormOpen] = React.useState<boolean>(false);
  const [isArticleDataLoading, setIsArticleDataLoading] = React.useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(false);
  const [isDeleteApproved, setIsDeleteApproved] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(ROWS_PER_PAGE_OPTIONS[0]);
  const [rerender, setRerender] = React.useState<boolean>(false);

  React.useEffect(() => {
    let fetchData = async () => {
      setIsArticleDataLoading(true);
      let articles = await getArticles();
      if (!articles) return;
      setRows(articles);
      setIsArticleDataLoading(false);
    }
    fetchData();
  }, [rerender])

  React.useEffect(() => {
    if (id === "" || !isDeleteApproved) return;
    const deleteArticleFunction = async () => {
      await deleteArticle(id);
      setIsDeleteApproved(false);
      setRerender(!rerender);
      setId("");
    }
    deleteArticleFunction();
  }, [isDeleteApproved]);

  React.useEffect(() => {
    setId("");
  }, [isArticleFormOpen])

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    setPage(model.page);
    setPageSize(model.pageSize);
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 130, minWidth: 130 },
    { field: 'title', headerName: t(TranslationKeyEnum.articleTitle), width: 100, minWidth: 100, flex: 1 },
    { field: 'description', headerName: t(TranslationKeyEnum.description), width: 100, minWidth: 100, flex: 1 },
    { field: 'body', headerName: t(TranslationKeyEnum.articleText), width: 100, minWidth: 100, flex: 1 },
    {
      field: 'actions', headerName: '', sortable: false, width: 80, minWidth: 80, flex: .3, renderCell: (cellProps) => {
        return <Box>
          <Tooltip title={t(TranslationKeyEnum.edit)}>
            <GridActionsCellItem icon={<EditIcon />} label={t(TranslationKeyEnum.edit)} onClick={() => { setId(cellProps.id.toString()); setIsArticleFormOpen(true) }} />
          </Tooltip>
          <Tooltip title={t(TranslationKeyEnum.delete)}>
            <GridActionsCellItem icon={<DeleteIcon />} label={t(TranslationKeyEnum.delete)} onClick={() => { setId(cellProps.id.toString()); setIsDeleteDialogOpen(true) }} />
          </Tooltip>
        </Box>
      }
    },
  ];

  return (
    <>
      <Box sx={{ boxShadow: CARD_BOX_SHADOW }}>
        <Box padding={SPACING_EXTRA_SMALL} sx={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: 'space-between', gap: SPACING_EXTRA_SMALL }}>
          <Typography variant="h5">{t(TranslationKeyEnum.newsFeed)}</Typography>
          <Tooltip title={t(TranslationKeyEnum.add)}>
            <Button variant="contained" startIcon={<AddIcon />} sx={{ textTransform: getTextTransformationAccordingToLanguage() }} onClick={() => setIsArticleFormOpen(true)}>
              {t(TranslationKeyEnum.addArticle)}
            </Button>
          </Tooltip>
        </Box>
        <StyledDataGrid
          columnVisibilityModel={{
            id: false,
          }}
          localeText={i18n.language === LanguageEnum.Serbian ? srRS.components.MuiDataGrid.defaultProps.localeText : undefined}
          autoHeight={true}
          rows={rows}
          columns={columns}
          paginationModel={{ page, pageSize }}
          onPaginationModelChange={handlePaginationModelChange}
          pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
          pagination
          hideFooterSelectedRowCount={true}
          loading={isArticleDataLoading}
          disableRowSelectionOnClick
        />
      </Box>
      {
        isArticleFormOpen
        && <CreateEditArticleDialog
          articleId={id}
          isOpen={isArticleFormOpen}
          setIsOpen={setIsArticleFormOpen}
          rerender={rerender}
          setRerender={setRerender}
        />
      }
      {
        isDeleteDialogOpen &&
        <ConfirmDialog
          dialogOpen={isDeleteDialogOpen}
          setDialogOpen={setIsDeleteDialogOpen}
          title={t(TranslationKeyEnum.confirmDelete)}
          description={t(TranslationKeyEnum.deleteArticleDescription)}
          isActionConfirmed={setIsDeleteApproved}
        />
      }
    </>
  )
}