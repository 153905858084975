import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import CalendarIcon from '../../TimeOff/CalendarIcon/CalendarIcon';
import { Grid, Box, Typography } from '@mui/material';
import { PRIMARY_BG_COLOR, SPACING_SMALL, CARD_BOX_SHADOW, BORDER_RADIUS_MEDIUM, CARD_HEIGHT, MIN_CARD_HEIGHT, CARD_FULL_BODY_HEIGHT, CARD_HEADER_HEIGHT, MIN_CARD_HEADER_HEIGHT, LIGHT_BORDER, MIN_CARD_FULL_BODY_HEIGHT, SPACING_EXTRA_SMALL } from '../../../utils/cssUtils';
import { useTranslation } from 'react-i18next';
import { TranslationKeyEnum } from '../../../features/translations/TranslationKeyEnum';
import { BirthdayEventDto } from '../../../API/user-management-service';
import { useGetNextBirthdays } from '../../../API/user-actions';

const NUMBER_OF_BIRTHDAYS = 2;

export default function UpcomingBirthdays() {
    const { t } = useTranslation();
    const getNextBirthdays = useGetNextBirthdays();

    const [upcomingBirthdays, setUpcomingBirthdays] = React.useState<BirthdayEventDto[]>([]);
    const [isUsersDataLoading, setIsUsersDataLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        const fetchData = async () => {
            setIsUsersDataLoading(true);
            const nextBirthdays = await getNextBirthdays(NUMBER_OF_BIRTHDAYS)
            if (!nextBirthdays) return;
            setUpcomingBirthdays(nextBirthdays);
            setIsUsersDataLoading(false);
        }
        fetchData();
    }, [])

    return (
        <Box sx={{ height: CARD_HEIGHT, minHeight: MIN_CARD_HEIGHT, borderRadius: BORDER_RADIUS_MEDIUM, boxShadow: CARD_BOX_SHADOW, backgroundColor: PRIMARY_BG_COLOR, marginBottom: SPACING_SMALL, borderBottom: LIGHT_BORDER }}>
            {/* Card Header */}
            <Box height={CARD_HEADER_HEIGHT} minHeight={MIN_CARD_HEADER_HEIGHT} display="flex" alignItems="center" justifyContent="center" borderBottom={LIGHT_BORDER}>
                <Typography sx={{ textTransform: "uppercase" }} variant="subtitle2">
                    {t(TranslationKeyEnum.upcomingBirthdays)}
                </Typography>
            </Box>
            {/* Card Body */}
            <Grid container height={CARD_FULL_BODY_HEIGHT} minHeight={MIN_CARD_FULL_BODY_HEIGHT}>
                {
                    isUsersDataLoading
                        ? <CircularProgress sx={{ margin: "auto" }} />
                        : upcomingBirthdays?.length
                            ? <Box display="flex" width="100%">
                                {
                                    upcomingBirthdays.map((upcomingBirthday, index) => {
                                        return <Box
                                            width="50%" display="flex"
                                            flexDirection="column"
                                            justifyContent="center"
                                            alignItems="center"
                                            gap={SPACING_EXTRA_SMALL}
                                            borderRight={index === 0 ? LIGHT_BORDER : ""}
                                        >
                                            <CalendarIcon date={new Date(upcomingBirthday.birthdate!)} />
                                            <Typography variant="body2">{upcomingBirthday.fullName}</Typography>
                                        </Box>
                                    })
                                }
                            </Box>
                            : <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" margin="auto" gap={SPACING_EXTRA_SMALL}>
                                <EventBusyIcon color="action" />
                                <Typography variant="body2" align="center" color="grey">{t(TranslationKeyEnum.noUpcomingBirthdays)}</Typography>
                            </Box>
                }
            </Grid>
        </Box>
    )
}