import { BankHolidayBasicDto, BankHolidayClient, BankHolidayRequest, BankHolidayWithUsersDto, BankHolidayDto,  ResponseMessage } from "../../API/time-off-service";
import { useTryCatchJsonByAction } from "../../utils/fetchUtils";

const holidayClient = new BankHolidayClient();
const getBankHolidaysAction = (): Promise<BankHolidayWithUsersDto[]> => holidayClient.getAllWithUsers();
const getBankHolidaysByUserAction = (): Promise<BankHolidayWithUsersDto[]> => holidayClient.getBankHolidaysByUser();
const getTwoMostRecentBankHolidaysAction = (): Promise<BankHolidayDto[]> => holidayClient.getTwoMostRecent();
const removeBankHolidayAction = (id: string): Promise<ResponseMessage> => holidayClient.remove(id);
const createBankHolidayAction = (request: BankHolidayRequest) => holidayClient.create(request);
const updateBankHolidayAction = (request: BankHolidayBasicDto) => holidayClient.update(request);

export function useGetBankHolidaysByUser(): () => Promise<BankHolidayWithUsersDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();

    async function bindedGetBankHolidaysByUserAction(): Promise<BankHolidayWithUsersDto[]> {
        const bindedGetBankHolidaysByUserAction = getBankHolidaysByUserAction.bind(null);
        const holidaysModel = await tryCatchAction(bindedGetBankHolidaysByUserAction);
        
        return holidaysModel ? holidaysModel.response : [];
    }

    return bindedGetBankHolidaysByUserAction;
}

export function useGetBankHolidays(): () => Promise<BankHolidayWithUsersDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();

    async function getBankHolidays(): Promise<BankHolidayWithUsersDto[]> {
        const bindedGetBankHolidaysAction = getBankHolidaysAction.bind(null);
        const holidaysModel = await tryCatchAction(bindedGetBankHolidaysAction);

        return holidaysModel ? holidaysModel.response : [];
    }

    return getBankHolidays;
}

export function useGetTwoMostRecentBankHolidays(): () => Promise<BankHolidayDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();

    async function getTwoMostRecentBankHolidays(): Promise<BankHolidayDto[]> {
        const bindedGetTwoMostRecentBankHolidaysAction = getTwoMostRecentBankHolidaysAction.bind(null);
        const holidaysModel = await tryCatchAction(bindedGetTwoMostRecentBankHolidaysAction);

        return holidaysModel ? holidaysModel.response : [];
    }

    return getTwoMostRecentBankHolidays;
}

export function useRemoveBankHoliday(): (id: string) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();

    async function removeBankHoliday(id: string): Promise<boolean> {
        const bindedRemoveBankHolidayAction = removeBankHolidayAction.bind(null, id);
        const response = await tryCatchAction(bindedRemoveBankHolidayAction);

        return response.success;
    }

    return removeBankHoliday;
}

export function useCreateBankHoliday(): (request: BankHolidayRequest) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();

    async function createBankHoliday(request: BankHolidayRequest): Promise<boolean> {
        const bindedCreateGroupAction = createBankHolidayAction.bind(null, request);
        const response = await tryCatchAction(bindedCreateGroupAction);

        return response.success;
    }

    return createBankHoliday;
}

export function useUpdateBankHoliday(): (request: BankHolidayBasicDto) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();

    async function updateBankHoliday(request: BankHolidayBasicDto): Promise<boolean> {
        const bindedUpdateGroupAction = updateBankHolidayAction.bind(null, request);
        const response = await tryCatchAction(bindedUpdateGroupAction);

        return response.success;
    }

    return updateBankHoliday;
}