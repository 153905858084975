import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import RequestDoneIcon from './RequestDoneIcon';
import { Box, Typography, Button } from '@mui/material';
import { IRequestCreatedAlert } from './IRequestCreatedAlert';
import { BORDER_RADIUS_MEDIUM, DARK_BOX_SHADOW, LIGHT_BG_COLOR, SPACING_MEDIUM, SPACING_SMALL, APP_BG_COLOR } from '../../../../utils/cssUtils';
import { useTranslation } from 'react-i18next';
import { TranslationKeyEnum } from '../../../../features/translations/TranslationKeyEnum';

export default function RequestCreatedAlert({ setIsOpen, numberOfDays, setIsRequestCreated }: IRequestCreatedAlert) {
    const { t } = useTranslation();
    return <Box width={{ xl: "26vw", lg: "30vw", md: "36vw", sm: "46vw", xs: "70vw" }} boxShadow={DARK_BOX_SHADOW} borderRadius={BORDER_RADIUS_MEDIUM} sx={{ backgroundColor: APP_BG_COLOR }}>
        <Box width="70px" height="70px" position="fixed" boxShadow={DARK_BOX_SHADOW} borderRadius={BORDER_RADIUS_MEDIUM} marginLeft={SPACING_MEDIUM} sx={{ backgroundColor: APP_BG_COLOR, transform: "translateY(-50%)" }}>
            <Box display="flex" justifyContent="center">
                <BeachAccessIcon color="action" fontSize="large" sx={{ marginTop: "16px" }} />
            </Box>
        </Box>
        <Box padding={SPACING_MEDIUM}>
            <Box display="flex" justifyContent="center" margin={`${SPACING_SMALL} 0`}>
                <RequestDoneIcon />
            </Box>
            <Typography variant="h6" textAlign="center" marginBottom={SPACING_SMALL}>{t(TranslationKeyEnum.hooray)}</Typography>
            <Typography variant="body2" color="gray" textAlign="center">{t(TranslationKeyEnum.successfullRequest, { numberOfDays: numberOfDays })}</Typography>
            <Button variant="contained" sx={{ margin: `${SPACING_MEDIUM} 0`, float: "right", backgroundColor: LIGHT_BG_COLOR }}
                onClick={() => { setIsOpen(false); setIsRequestCreated(false); }}>
                {t(TranslationKeyEnum.done)}
            </Button>
        </Box>
    </Box>
}