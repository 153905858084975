import { FormikConfig, FormikValues } from "formik";
import { useUserMainInfoSchema } from "./useMainInfoValidationSchema";

export function useMainInfoFormikForm(): (handleSubmit: () => void) => FormikConfig<FormikValues> {
    const mainInfoValidationSchema = useUserMainInfoSchema();
    function mainInfoFormikForm(handleSubmit: () => void): FormikConfig<FormikValues> {
        return {
            initialValues: {
                firstName: "",
                lastName: "",
                email: "",
            },
            validationSchema: mainInfoValidationSchema,
            onSubmit: handleSubmit
        };
    }
    return mainInfoFormikForm;
}