import React from "react";
import { EventPolicySummaryDto } from "../../../API/time-off-service";
import { useGetPolicyEvents } from "../actions";
import { ISummaryFormProps } from "./IPolicyEventsSummaryProps";
import { useTranslation } from "react-i18next";
import { TranslationKeyEnum } from "../../../features/translations/TranslationKeyEnum";
import { DataGrid, GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { LanguageEnum } from "../../../features/translations/LanguageEnum";
import { srRS } from "../../../features/translations/DataGrid/srRS";
import { Dialog, Grid, Typography } from '@mui/material';
import { useAtom } from "jotai";
import { summaryData } from "../../AppContent/AppContent";
import { BORDER_RADIUS_MEDIUM, BORDER_RADIUS_SMALL, PAGE_SHADOW, PRIMARY_BG_COLOR, SPACING_MEDIUM, SPACING_SMALL } from "../../../utils/cssUtils";
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import Chip from '@mui/material/Chip';
import { ROWS_PER_PAGE_OPTIONS } from '../../../components/common/Constants/PaginationConstants';
import { ISummaryFormStoreProps } from "./ISummaryFormStoreProps";

export default (props: ISummaryFormProps) => {
  const [events, setEvents] = React.useState<EventPolicySummaryDto[]>([]);
  const getPolicyEvents = useGetPolicyEvents(props.isForUser === true ? props.userId : "");
  const [summaryDataAtom, setSummaryDataAtom] = useAtom<ISummaryFormStoreProps>(summaryData)
  const { t, i18n } = useTranslation();
  const [page, setPage] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(ROWS_PER_PAGE_OPTIONS[0]);
  const [isPolicySummaryDataLoading, setIsPolicySummaryDataLoading] = React.useState<boolean>(true);

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    setPage(model.page);
    setPageSize(model.pageSize);
  }

  React.useEffect(() => {
    const fetchData = async () => {
      let allEvents = await getPolicyEvents(summaryDataAtom.policy.id!);
      if (!allEvents) return;
      setEvents(allEvents);
      setIsPolicySummaryDataLoading(false)
    }
    props.isOpen && fetchData();
  }, [summaryDataAtom.policy.id!])

  const columns: GridColDef[] = [
    {
      field: 'requestComment',
      headerName: t(TranslationKeyEnum.requestComment),
      description: t(TranslationKeyEnum.requestComment),
      minWidth: 140,
      maxWidth: 190,
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.value} placement="bottom-start">
            <div>{cellValues.value}</div>
          </Tooltip>
        );
      }
    },
    {
      field: 'responseComment',
      headerName: t(TranslationKeyEnum.responseComment),
      description: t(TranslationKeyEnum.responseComment),
      minWidth: 150,
      maxWidth: 190,
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.value} placement="bottom-start">
            <div>{cellValues.value}</div>
          </Tooltip>
        );
      }
    },
    {
      field: 'changedBy',
      headerName: t(TranslationKeyEnum.changedBy),
      description: t(TranslationKeyEnum.changedBy),
      flex: 1,
      minWidth: 150,
      maxWidth: 150,
      renderCell: (cellValues) => {
        return (
          <Box >
            <Tooltip title={cellValues.value}>
              <div>{cellValues.value}</div>
            </Tooltip>
          </Box>
        );
      }
    },
    {
      field: 'daysChange',
      headerName: t(TranslationKeyEnum.daysChangePolicySummary),
      description: t(TranslationKeyEnum.daysChangePolicySummary),
      flex: 1,
      minWidth: 160,
      maxWidth: 160,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              color: cellValues.value < 0 ? "red" : "green",
            }}
          >
            {cellValues.value}
          </div>
        );
      }
    },
    {
      field: 'availableDays',
      headerName: t(TranslationKeyEnum.availableDays),
      description: t(TranslationKeyEnum.availableDays),
      minWidth: 180,
      maxWidth: 180,
      flex: 1
    },
    {
      field: 'totalDays',
      headerName: t(TranslationKeyEnum.totalDays),
      description: t(TranslationKeyEnum.totalDays),
      minWidth: 150,
      maxWidth: 150,
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <Chip key={cellValues.value} label={cellValues.value} icon={<BeachAccessIcon />} />
        );
      }
    },
    {
      field: 'changedAt',
      headerName: t(TranslationKeyEnum.modificationDate),
      description: t(TranslationKeyEnum.modificationDate),
      valueGetter: ({ value }) => value.toLocaleDateString('en-GB'),
      flex: 1,
      minWidth: 130,
      maxWidth: 130,
    },
  ];

  const handleClosePolicySummary = (): void => {
    props.setIsOpen(false);
  }

  return (
    <Dialog onClose={handleClosePolicySummary} open={props.isOpen} maxWidth="md">
      <Grid container>
        <Grid container height="70vh" width="80vw" sx={{ padding: SPACING_SMALL }} >
          <Grid item height="3%" xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box height="100%" display="flex" flexDirection="column" borderRadius={BORDER_RADIUS_SMALL}>
              <Typography variant="body2" marginTop=".5vh .5vh" fontSize="4vh" marginLeft=".5vh">{t(TranslationKeyEnum.policySummary)}</Typography>
            </Box>
          </Grid>
          <Grid item marginTop={SPACING_SMALL} height="85%" xl={12} lg={12} md={12} sm={12} xs={12} sx={{ backgroundColor: PRIMARY_BG_COLOR }} borderRadius={BORDER_RADIUS_MEDIUM}>
            <Box height="100%" boxShadow={"none"}>
              <DataGrid
                sx={{ boxShadow: PAGE_SHADOW }}
                rows={events}
                columns={columns}
                paginationModel={{ page, pageSize }}
                onPaginationModelChange={handlePaginationModelChange}
                pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
                pagination
                hideFooterSelectedRowCount={true}
                localeText={i18n.language === LanguageEnum.Serbian ? srRS.components.MuiDataGrid.defaultProps.localeText : undefined}
                loading={isPolicySummaryDataLoading}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}