import { Button, Dialog, DialogActions, Typography, Box } from '@mui/material';
import { IConfirmDialogProps } from './IConfirmDialogProps';
import { useTranslation } from 'react-i18next';
import { TranslationKeyEnum } from '../../../features/translations/TranslationKeyEnum';
import { DARK_BOX_SHADOW, SPACING_EXTRA_SMALL, SPACING_SMALL } from '../../../utils/cssUtils';

export default function ConfirmDialog({ dialogOpen, setDialogOpen, title, description, isActionConfirmed }: IConfirmDialogProps) {
    const { t } = useTranslation();

    const handleClose = () => {
        isActionConfirmed(true);
        setDialogOpen(false);
    };

    return (
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} sx={{display: "flex", justifyContent: "center"}}>
                <Box  sx={{width: {xl: "30vw", lg: "30vw", md: "40vw", sm: "50vw", xs: "60vw" }, padding: `${SPACING_EXTRA_SMALL} ${SPACING_SMALL}`, boxShadow: DARK_BOX_SHADOW}}>
                <Typography variant="h6">{title}</Typography>
                {
                    description && <Typography variant="body2" sx={{margin: `${SPACING_SMALL} 0`}}>{description}</Typography>
                }
                <DialogActions>
                    <Button variant="text" sx={{color: "black"}} onClick={() => setDialogOpen(false)}>{t(TranslationKeyEnum.cancel)}</Button>
                    <Button variant="contained" color="error" onClick={handleClose} autoFocus>{t(TranslationKeyEnum.yesRemove)}</Button>
                </DialogActions>
                </Box>
            </Dialog>
    );
}