import Typography from '@mui/material/Typography';
import CalendarIcon from "../../../containers/TimeOff/CalendarIcon/CalendarIcon";
import { Grid, Tooltip } from '@mui/material';
import { IUpcomingBankHolidaysProps } from './UpcomingBankHolidaysProps';
import { CARD_BODY_HEIGHT, LIGHT_BORDER, MIN_CARD_BODY_HEIGHT, SPACING_SMALL } from '../../../utils/cssUtils';

export default function UpcomingBankHolidaysIcon({ date, title, isFirstBankHoliday }: IUpcomingBankHolidaysProps) {

    return (
        <Grid
            xl={6} lg={6} md={6} sm={6} xs={6}
            height={CARD_BODY_HEIGHT}
            minHeight={MIN_CARD_BODY_HEIGHT}
            borderRight={isFirstBankHoliday ? LIGHT_BORDER : ""}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap={SPACING_SMALL}
            sx={{ cursor: "pointer" }}
        >
            <CalendarIcon date={date} />
            <Tooltip title={title}>
                <Typography variant="body2" textAlign="center" width="8vw" minWidth="100px" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
                    {title}
                </Typography>
            </Tooltip>
        </Grid>
    );
}