import { Grid, Box } from "@mui/material";
import { AssignPolicyRequest, CopyTimeOffPolicyRequest, CreateTimeOffPolicyRequest, DeleteTimeOffPolicyRequest, IUnassignPolicyRequest, SingleUpdateUserPolicyDaysRequest, TimeOffPolicyUserDto, UnassignPolicyRequest, UpdateTimeOffPolicyRequest, UserDaysDto } from "../../API/time-off-service";
import { useAssignPolicy, useDeleteTimeOffPolicy, useGetPoliciesWithUsers, useUnassignUserFromPolicy } from "../../containers/TimeOff/actions";
import { GridColDef, GridActionsCellItem, GridRowModesModel, GridRowModes, GridRowId } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { TranslationKeyEnum } from "../../features/translations/TranslationKeyEnum";
import { IPolicyUserTableProps } from "./IPolicyUserTableProps";
import { CARD_BOX_SHADOW, PRIMARY_BG_COLOR, SPACING_EXTRA_SMALL } from "../../utils/cssUtils";
import { useCopyPolicy, useCreatePolicy, useUpdatePolicy, useUpdateUserPolicyDays } from "../../API/time-off-actions";
import { IPolicyModel } from "./AddEditPolicyForm/IAddEditPolicyFormProps";
import { getTextTransformationAccordingToLanguage } from '../../features/translations/helpers';
import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import ConfirmDialog from "../Dialogs/ConfirmDialog/ConfirmDialog";
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import AccordionSkeleton from "../common/AccordionSkeleton/AccordionSkeleton";
import EditIcon from '@mui/icons-material/Edit';
import AddUserFormDialog from "./AddUserFormDialog";
import Stack from '@mui/material/Stack';
import AddEditPolicyFormDialog from "./AddEditPolicyForm/AddEditPolicyFormDialog";
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import DeactivatedPolicies from "./DeactivatedPolicies";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import EditableDataGrid from "../EditableDataGrid/EditableDataGrid";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const FIRST_PAGE = 0;
const EMPTY_POLICY_ID = '';

export default function AdminPolicies() {
    const [policies, setPolicies] = React.useState<TimeOffPolicyUserDto[]>();
    const [tableData, setTableData] = React.useState<Map<string, IPolicyUserTableProps[]>>();
    const { t } = useTranslation();
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const [approveDelete, setApproveDelete] = React.useState(false);
    const [isAddUserFormOpen, setIsAddUserFormOpen] = React.useState(false);
    const [totalDays, setTotalDays] = React.useState(0);
    const [policyId, setPolicyId] = React.useState<string>("");
    const [policy, setPolicy] = React.useState<TimeOffPolicyUserDto>();
    const [rerender, setRerender] = React.useState(false);
    const [isAddPolicyFormOpen, setIsAddPolicyFormOpen] = React.useState(false);
    const [isEditPolicyFormOpen, setIsEditPolicyFormOpen] = React.useState(false);
    const [basePoliciesModel, setBasePoliciesModel] = React.useState<IPolicyModel[]>();
    const [deletePolicyDialogOpen, setDeletePolicyDialogOpen] = React.useState(false);
    const [approveDeletePolicy, setApproveDeletePolicy] = React.useState(false);
    const [expandAccordion, setExpandAccordion] = React.useState(false);
    const [policiesLoading, setPoliciesLoading] = React.useState<boolean>(false);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [rows, setRows] = React.useState<IPolicyUserTableProps[]>([]);
    const [page, setPage] = React.useState(FIRST_PAGE);
    const [id, setId] = React.useState(EMPTY_POLICY_ID);
    const [isCopyPolicy, setIsCopyPolicy] = React.useState<boolean>(false);
    const [usersForCopyPolicy, setUsersForCopyPolicy] = React.useState<UserDaysDto[]>([])

    const getPolicies = useGetPoliciesWithUsers();
    const assignPolicy = useAssignPolicy();
    const unassignUserFromPolicy = useUnassignUserFromPolicy();
    const createPolicy = useCreatePolicy();
    const deletePolicy = useDeleteTimeOffPolicy();
    const updatePolicy = useUpdatePolicy();
    const updateUserPolicyDays = useUpdateUserPolicyDays();
    const copyPolicy = useCopyPolicy();

    React.useEffect(() => {
        const fetchData = async () => {
            setPoliciesLoading(true);
            setPolicies(await getPolicies());
            setRerender(false);
            setPoliciesLoading(false);
            setApproveDeletePolicy(false);
        }
        fetchData();
    }, [rerender]);

    React.useEffect(() => {
        approveDelete && handleDelete();
    }, [approveDelete])

    React.useEffect(() => {
        setTableData(mapTableUsers());
        setTotalDays(0);
        let tempArray: IPolicyModel[] = [];
        policies?.map(x => tempArray.push({ id: x.id, name: x.timeOffPolicyName } as IPolicyModel));
        setBasePoliciesModel(tempArray);
    }, [policies])

    const handleEditClick = (id: GridRowId) => () => setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });

    const handleSaveClick = (id: GridRowId) => () => setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });

    const handleCancelClick = (id: GridRowId) => () => setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });

    const processRowCreateOrUpdate = async (policy: IPolicyUserTableProps) => {
        let Update = await updateUserPolicyDays(createUpdatePolicyRequestObject(policy));
        if (!Update) {
            let removeEmptyIdPolicy = rows.filter((row: IPolicyUserTableProps) => row.id !== EMPTY_POLICY_ID);
            return setRows(removeEmptyIdPolicy);
        };
        setRerender(!rerender);

        const updatedRow = { ...policy, isNew: false };
        setRows(rows.map((row) => (row.id === policy.id ? updatedRow : row)));
        return updatedRow;
    };

    const onProcessRowUpdateError = () => setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });

    function createUpdatePolicyRequestObject(policy: IPolicyUserTableProps): SingleUpdateUserPolicyDaysRequest {
        let updatePolicyeObject = new SingleUpdateUserPolicyDaysRequest();

        updatePolicyeObject.updateComment = "Modified in Admin console";
        updatePolicyeObject.totalDays = policy.numberOfDays;
        updatePolicyeObject.userId = policy.id;
        updatePolicyeObject.policyId = policyId;
        return updatePolicyeObject;
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Id', width: 130, minWidth: 100 },
        {
            field: 'fullName', headerName: t(TranslationKeyEnum.userLabel), description: t(TranslationKeyEnum.userLabel), minWidth: 300, flex: 1, renderCell: (cellValues) => {
                return (
                    <Box sx={{ display: 'flex', alignItems: "center", flexDirection: 'row', justifyContent: 'space-between', cursor: "pointer" }} >
                        <Avatar variant="rounded" sx={{ width: 30, height: 30 }} src={cellValues.row.image && `data:image/png;base64,${cellValues.row.image}`} />
                        <Typography paddingLeft={2} variant="body2" textAlign="center">{cellValues.row.firstName} {cellValues.row.lastName}</Typography>
                    </Box>
                );
            }
        },
        { field: 'numberOfDays', headerName: t(TranslationKeyEnum.totalDays), minWidth: 100, flex: 1, editable: true },
        {
            field: 'actions', type: 'actions', sortable: false, minWidth: 100, flex: 1, getActions: (params: any) => {

                const isInEditMode = (rowModesModel[params.row.id]?.mode === GridRowModes.Edit);
                return isInEditMode
                    ? [
                        <Tooltip title={t(TranslationKeyEnum.save)}><GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(params.row.id)} /></Tooltip>,
                        <Tooltip title={t(TranslationKeyEnum.cancel)}><GridActionsCellItem icon={<CancelIcon />} label="Cancel" onClick={handleCancelClick(params.row.id)} /></Tooltip>
                    ]
                    : [
                        <Tooltip title={t(TranslationKeyEnum.edit)}><GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={handleEditClick(params.row.id)} /></Tooltip>,
                        <Tooltip title={t(TranslationKeyEnum.delete)}><GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => setDeleteDialogOpen(true)} /></Tooltip>
                    ]
            }
        },
    ];

    function mapTableUsers(): Map<any, any> {
        return new Map(policies?.map(i => [i.id, i.users?.map(userDto => {
            return {
                id: userDto.user?.id,
                firstName: userDto.user?.firstName,
                lastName: userDto.user?.lastName,
                numberOfDays: userDto.totalDays
            } as IPolicyUserTableProps
        })]));
    }

    async function handleDelete(): Promise<void> {
        await unassignUserFromPolicy(new UnassignPolicyRequest({ policyId, userId: id } as IUnassignPolicyRequest));
        setApproveDelete(false);
        setRerender(true);
    }

    const handleCloseAddUserDialog = () => {
        setIsAddUserFormOpen(false);
        setPolicyId("");
        setRerender(true);
    };

    const handleCloseAddPolicyDialog = () => {
        setIsAddPolicyFormOpen(false);
        setIsEditPolicyFormOpen(false);
        setPolicyId("");
        setIsCopyPolicy(false);
        setUsersForCopyPolicy([]);
    };

    const handleSubmitUserDialog = (users: string[]) => {
        assignPolicy(new AssignPolicyRequest({ policyId, users, totalDays }))
        setIsAddUserFormOpen(false);
        setPolicyId("");
        setRerender(true);
    };

    async function handleSubmitPolicyDialog(request: CreateTimeOffPolicyRequest) {
        await createPolicy(request);
        setIsAddPolicyFormOpen(false);
        setIsEditPolicyFormOpen(false);
        setPolicyId("");
        setRerender(true);
    };

    async function handleUpdatePolicyDialog(request: UpdateTimeOffPolicyRequest) {
        await updatePolicy(request);
        setIsEditPolicyFormOpen(false);
        setPolicyId("");
        setRerender(true);
    };

    async function handleCopyPolicyDialog(request: CopyTimeOffPolicyRequest){
        await copyPolicy(request);
        setIsEditPolicyFormOpen(false);
        setPolicyId("");
        setRerender(true);
        setIsCopyPolicy(false);
        setUsersForCopyPolicy([]);
    }

    const handleNumberChange = (number: string) => {
        setTotalDays(parseInt(number));
    }

    function handleDeletePolicyDialog() {
        let requestModel = new DeleteTimeOffPolicyRequest();
        requestModel.id = policyId ?? "";
        deletePolicy(requestModel);
        setPolicyId("");
        setRerender(true);
    };

    React.useEffect(() => {
        approveDeletePolicy && handleDeletePolicyDialog();
    }, [approveDeletePolicy])

    React.useEffect(() => {
        !isCopyPolicy && setPolicyId("");
    }, [isAddPolicyFormOpen])

    return (
        <>
            <Box sx={{ boxShadow: CARD_BOX_SHADOW, backgroundColor: PRIMARY_BG_COLOR }}>
                <Box padding={SPACING_EXTRA_SMALL} sx={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: 'space-between', gap: SPACING_EXTRA_SMALL }}>
                    <Typography variant="h5">{t(TranslationKeyEnum.policies)}</Typography>
                    <Tooltip title={t(TranslationKeyEnum.add)}>
                        <Button variant="contained" startIcon={<AddIcon />} sx={{ textTransform: getTextTransformationAccordingToLanguage() }} onClick={() => { setExpandAccordion(!expandAccordion); setIsAddPolicyFormOpen(true) }}>
                            {t(TranslationKeyEnum.addPolicy)}
                        </Button>
                    </Tooltip>
                </Box>
                {
                    policies ? policies!.map((policy: TimeOffPolicyUserDto) =>
                        <Accordion key={policy.id} sx={{ background: 'none' }} expanded={policyId === policy.id && expandAccordion}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ pointerEvents: "auto" }} onClick={(e) => { setExpandAccordion(!expandAccordion); setPolicyId(policy.id!) }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{ pointerEvents: "none" }}
                            >
                                <Grid container>
                                    <Grid item md={10}>
                                        <Typography>{policy.timeOffPolicyName}</Typography>
                                    </Grid>
                                    <Grid item md={2}>
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            spacing={0.5}
                                        >
                                            <Tooltip title={t(TranslationKeyEnum.add)}>
                                                <IconButton>
                                                    <AddIcon
                                                        sx={{ pointerEvents: "auto" }} onClick={(e) => {
                                                            e.stopPropagation();
                                                            setPolicyId(policy.id!);
                                                            setPolicy(policy)
                                                            setIsAddUserFormOpen(true);
                                                        }}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={t(TranslationKeyEnum.copyPolicy)}>
                                                <IconButton>
                                                    <ContentCopyIcon
                                                        sx={{ pointerEvents: "auto" }} onClick={(e) => {
                                                            e.stopPropagation();
                                                            setIsCopyPolicy(true);
                                                            setPolicyId(policy.id!);
                                                            setUsersForCopyPolicy(policy.users!);
                                                            setIsEditPolicyFormOpen(true);
                                                        }}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={t(TranslationKeyEnum.edit)}>
                                                <IconButton>
                                                    <EditIcon
                                                        sx={{ pointerEvents: "auto" }} onClick={(e) => {
                                                            e.stopPropagation();
                                                            setPolicyId(policy.id!);
                                                            setIsEditPolicyFormOpen(true);
                                                        }}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={t(TranslationKeyEnum.delete)}>
                                                <IconButton>
                                                    <DeleteIcon
                                                        sx={{ pointerEvents: "auto" }} onClick={(e) => {
                                                            e.stopPropagation();
                                                            setPolicyId(policy.id!);
                                                            setDeletePolicyDialogOpen(true);
                                                        }}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                {(policyId === policy.id) && <EditableDataGrid
                                    rows={tableData?.get(policy.id!) ?? []}
                                    columns={columns}
                                    page={page}
                                    setPage={setPage}
                                    setRows={setRows}
                                    setId={setId}
                                    rowModesModel={rowModesModel}
                                    setRowModesModel={setRowModesModel}
                                    processRowUpdate={processRowCreateOrUpdate}
                                    onProcessRowUpdateError={onProcessRowUpdateError}
                                    toolbar={() => <></>}
                                    isLoading={policiesLoading}
                                />
                                }
                            </AccordionDetails>
                        </Accordion>) : <AccordionSkeleton title={t(TranslationKeyEnum.policyLoading)} />
                }
            </Box>
            {
                isAddUserFormOpen &&
                <AddUserFormDialog
                    open={isAddUserFormOpen}
                    onClose={handleCloseAddUserDialog}
                    onSubmit={handleSubmitUserDialog}
                    policyUsers={policy?.users?.map(x => x.user?.id!) ?? []}
                    numberOfDays={totalDays}
                    onNumberChange={handleNumberChange}
                    infiniteDays={policy!.infiniteDays!}
                />
            }
            {
                (isAddPolicyFormOpen || isEditPolicyFormOpen) &&
                <AddEditPolicyFormDialog
                    open={isAddPolicyFormOpen || isEditPolicyFormOpen}
                    onClose={isAddPolicyFormOpen ? handleCloseAddPolicyDialog : handleCloseAddPolicyDialog}
                    onAddSubmit={handleSubmitPolicyDialog}
                    onEditSubmit={handleUpdatePolicyDialog}
                    policiesModel={basePoliciesModel ?? []}
                    policyId={(isAddPolicyFormOpen || !policyId) ? "" : policyId}
                    isAdd={isAddPolicyFormOpen}
                    isActivation={false}
                    isCopyPolicy={isCopyPolicy}
                    onCopySubmit={handleCopyPolicyDialog}
                    usersForCopyPolicy={usersForCopyPolicy}
                />
            }
            {
                deletePolicyDialogOpen &&
                <ConfirmDialog
                    dialogOpen={deletePolicyDialogOpen}
                    setDialogOpen={setDeletePolicyDialogOpen}
                    title={t(TranslationKeyEnum.confirmDelete)}
                    description={t(TranslationKeyEnum.deletePolicyDescription)}
                    isActionConfirmed={setApproveDeletePolicy}
                />
            }
            {
                deleteDialogOpen &&
                <ConfirmDialog
                    dialogOpen={deleteDialogOpen}
                    setDialogOpen={setDeleteDialogOpen}
                    title={t(TranslationKeyEnum.confirmDelete)}
                    description={t(TranslationKeyEnum.deleteUserFromPolicy)}
                    isActionConfirmed={setApproveDelete}
                />
            }
            <DeactivatedPolicies rerender={rerender} setRerender={setRerender} basePoliciesModel={basePoliciesModel ?? []} />
        </>
    )
}