import { deepPurple, red } from "@mui/material/colors";

export const NOTIFICATION_WARNING_BG_COLOR = deepPurple[50];
export const NOTIFICATION_ERROR_BG_COLOR = red[100];

export const APP_TOP_AND_BOTTOM_PADDING: string = "1.5%";
export const APP_LEFT_AND_RIGHT_PADDING: string = "5%";
export const APP_BG_COLOR: string = "#FAFAFA";
export const PRIMARY_BG_COLOR: string = "#FFFFFF";
export const SPACING_EXTRA_SMALL: string = "1.4vh";
export const SPACING_SMALL: string = "2vh";
export const SPACING_MEDIUM: string = "4vh";
export const SPACING_LARGE: string = "6vh";
export const SPACING_EXTRA_LARGE: string = "8vh";
export const BORDER_RADIUS_SMALL: string = "4px";
export const BORDER_RADIUS_MEDIUM: string = "6px";
export const BORDER_RADIUS_LARGE: string = "8px";
export const BORDER_RADIUS_EXTRA_LARGE: string = "12px";
export const CARD_BOX_SHADOW: string = "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)";
export const LIGHT_BOX_SHADOW: string = "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)";
export const DARK_BOX_SHADOW = "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);";
export const LIGHT_BORDER: string = "1px solid rgba(0, 0, 0, 0.12)";
export const LINE_BORDER: string = "1px solid #2196F3"
export const LINE_WIDTH: string = "4vw"
export const TEXT_SECONDARY: string = "rgba(0, 0, 0, 0.6)";
export const BORDER_PRIMARY_COLOR: string = "1px solid #1976d2"
export const PAGE_SHADOW: string = "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)";
export const LIGHT_BG_COLOR: string = "#2196F3";
export const AVATAR_SIZES: {} = { height: 36, width: 36 }
export const CARD_HEIGHT: string = "36vh";
export const MIN_CARD_HEIGHT: string = "230px"
export const CARD_HEADER_HEIGHT: string = "8vh";
export const MIN_CARD_HEADER_HEIGHT: string = "50px";
export const CARD_FOOTER_HEIGHT: string = "8vh";
export const MIN_CARD_FOOTER_HEIGHT: string = "50px";
export const CARD_BODY_HEIGHT: string = "20vh";
export const MIN_CARD_BODY_HEIGHT: string = "130px";
export const CARD_FULL_BODY_HEIGHT: string = "28vh";
export const MIN_CARD_FULL_BODY_HEIGHT: string = "180px";
export const DOUBLE_CARD_SIZE: string = "74vh"; // one card + space between cards + second card
export const MIN_DOUBLE_CARD_SIZE: string = "460px";
export const DOUBLE_CARD_BODY_SIZE: string = "58vh"; // one body card size+ space between cards + second body card size
export const MIN_DOBULE_CARD_BODY_SIZE: string = "360px";
export const MONTH_AND_DATE_MINI_CALENDAR_WIDTH: string = "8vh";
export const MONTH_AND_DATE_MINI_CALENDAR_MIN_WIDTH: string = "50px";
export const MONTH_AND_DATE_MINI_CALENDAR_HEIGTH: string = "8vh";
export const MONTH_AND_DATE_MINI_CALENDAR_MIN_HEIGTH: string = "50px";
export const MONTH_AND_DATE_MINI_CALENDAR_HEADER_HEIGTH: string = "2.4vh";
export const MONTH_AND_DATE_MINI_CALENDAR_HEADER_MIN_HEIGTH: string = "16px";
export const MONTH_AND_DATE_MINI_CALENDAR_BODY_HEIGTH: string = "5.6vh";
export const MONTH_AND_DATE_MINI_CALENDAR_BODY_MIN_HEIGTH: string = "34px";
export const MONTH_AND_DATE_MINI_CALENDAR_BACKGROUND: string = "#D83B01";
export const DIALOG_TITLE_FONT_SIZE: string = "1.5rem";
export const ICON_SIZE: string = "2rem";
export const ICON_COLOR: string = "rgba(0, 0, 0, 0.56)";
export const ADMIN_CONSOLE_INPUT_LABEL_WIDTH: string = "48%";
export const SCROLLER_COLOR: string = "#BABAC0";
export const SCROLLER_BG_COLOR: string = "#FFFFFF";
export const SCROLLER_WIDTH: string = "16px";
export const SCROLLER_BORDER_RADIUS: string = "16px";
export const SCROLLER_BORDER: string = "4px solid #FFFFFF";
export const INFO_CARD_HEIGTH: string = "24vh";
export const INFO_CARD_MIN_HEIGTH: string = "120px";
export const INFO_CARD_WIDTH: string = "24vw";
export const INFO_CARD_WIDTH_SM: string = "50vw";
export const INFO_CARD_WIDTH_XS: string = "70vw";
export const INFO_CARD_MIN_WIDTH: string = "240px";
export const WORK_ORDER_CARD_MIN_HEIGTH: string = "160px";
export const DASHBOARD_ICON_SIZE: string = "28px";