import React from 'react'
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import CloseIcon from '@mui/icons-material/Close';
import HighlightedCalendar from '../../../components/ReadOnlyCalendar/HighlightedCalendar';
import CalendarSkeleton from '../../../components/common/Calendar/CalendarSkeleton';
import { IResolveTimeOffDialog } from './IResolveTimeOffDialog'
import { Grid, Box, Typography, Dialog, TextField, Button } from '@mui/material';
import { BORDER_RADIUS_MEDIUM, DARK_BOX_SHADOW, SPACING_LARGE, SPACING_SMALL, APP_BG_COLOR, NOTIFICATION_WARNING_BG_COLOR, BORDER_RADIUS_SMALL, SPACING_EXTRA_LARGE, ICON_COLOR } from "../../../utils/cssUtils";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTranslation } from 'react-i18next';
import { TranslationKeyEnum } from '../../../features/translations/TranslationKeyEnum';
import { ICalendarEvent } from '../../../components/common/Calendar/ICalendarProps';
import { useGetPolicy, useGetRequest, useResolveRequest, useResolveRequests } from '../../../API/time-off-actions';
import { GroupDataDto, RequestDto, RequestStatusEnum, ResolveBulkTimeOffRequests, ResolveTimeOffRequest } from '../../../API/time-off-service';
import { PolicyDto } from '../../../API/identity-service';
import { getDateOrNull, isUserRequestOverlappedWithRequestDateRanges } from '../helpers';
import { useNavigate } from 'react-router-dom';
import { routeEnum } from '../../../routes/routeEnum';
import { StyledTextField } from '../../../components/StyledTextfield/StyledTextField';
import { useGetCalendarEvents } from '../../../components/ReadOnlyCalendar/actions';
import { DateRange } from '../../../components/common/DateRange/DateRange';
import { useGetUserWithGroups } from '../../../components/AdminGroups/actions';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';

export default function ResolveTimeOffRequestDialog({ id, isRequestResolved, setIsRequestResolved }: IResolveTimeOffDialog) {
    const { t } = useTranslation();
    const resolveRequest = useResolveRequest();
    const resolveRequests = useResolveRequests();
    const getUserWithGroups = useGetUserWithGroups();
    const getRequest = useGetRequest();
    const getPolicy = useGetPolicy();
    const getCalendarEvents = useGetCalendarEvents();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [userGroups, setUserGroups] = React.useState<GroupDataDto[]>([]);
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [events, setEvents] = React.useState<ICalendarEvent[]>([]);
    const [requestToResolve, setRequestToResolve] = React.useState<RequestDto>();
    const [relatedRequest, setRelatedRequest] = React.useState<RequestDto>();
    const [requestPolicy, setRequestPolicy] = React.useState<PolicyDto>();
    const [relatedRequestPolicy, setRelatedRequestPolicy] = React.useState<PolicyDto>();
    const [isOverlapping, setIsOverlapping] = React.useState<boolean>(false);
    const [resolveRequestComment, setResolveRequestComment] = React.useState<string>("");
    const [isDataLoaded, setIsDataLoaded] = React.useState<boolean>(false);

    const [userId, setUserId] = React.useState<string>("");

    React.useEffect(() => {
        // gets request that needs to be resolved, it's policy and groups
        const fetchRequest = async (): Promise<void> => {
            let request = await getRequest(id);
            // checks if request does not exists or has been resolved
            if (!request || (request && (request.status !== RequestStatusEnum.Pending && request.status !== RequestStatusEnum.CancellationPending))) {
                request && enqueueSnackbar(t(TranslationKeyEnum.requestAlreadyResolved), { variant: "info" })
                navigate(`/${routeEnum.PENDINGREQUESTS}`);
                return;
            };

            setIsOpen(true);
            setRequestToResolve(request);
            setUserId(request.user?.id!);

            // gets related request if there is any and it's request policy
            if (request.relatedToRequestId) {
                let _relatedRequest = await getRequest(request.relatedToRequestId);
                let getRelatedPolicy = await getPolicy(_relatedRequest.policyId!);
                setRelatedRequestPolicy(getRelatedPolicy);
                setRelatedRequest(_relatedRequest);
            }

            //gets request policy and user's groups
            Promise.all([getPolicy(request.policyId!), userId !== "" && getUserWithGroups(userId)]).then((values) => {
                setRequestPolicy(values[0]);
                values[1] && setUserGroups(values[1].groups!);
            });
            setIsDataLoaded(true);
        }
        fetchRequest();
    }, [])

    React.useEffect((): void => {
        const fetchData = async (): Promise<void> => setEvents(await getCalendarEvents());
        fetchData();
    }, [])

    React.useEffect(() => {
        const checkIsReqeustOverlapping = async (): Promise<void> => {
            const selectedStartDate = getDateOrNull(getStartDateValue());
            const selectedEndDate = getDateOrNull(getEndDateValue());
            let overlapping = isUserRequestOverlappedWithRequestDateRanges(new DateRange(selectedStartDate!, selectedEndDate!), events, userGroups, userId);;
            setIsOverlapping(overlapping);
        }
        checkIsReqeustOverlapping();
    }, [requestPolicy])

    const getPolicyName = (): string => requestPolicy
        ? relatedRequestPolicy
            ? `${relatedRequestPolicy.name} ${requestPolicy.name}`
            : requestPolicy.name!
        : "";

    const getStartDateValue = (): Date => {
        if (relatedRequest) return new Date(relatedRequest.startDate!);
        return new Date(requestToResolve?.startDate!);
    }

    const getEndDateValue = (): Date => requestToResolve ? new Date(requestToResolve?.endDate!) : new Date();

    const handleApproveRequest = (): Promise<void> => {
        const newStatus = requestToResolve!.status === RequestStatusEnum.Pending ? RequestStatusEnum.Approved : RequestStatusEnum.Cancelled;
        return requestToResolve?.relatedToRequestId ? resolveTimeOffRequests(newStatus) : resolveTimeOffRequest(newStatus);
    }

    const handleRejectRequest = (): Promise<void> => requestToResolve?.relatedToRequestId ? resolveTimeOffRequests(RequestStatusEnum.Rejected) : resolveTimeOffRequest(RequestStatusEnum.Rejected);

    // resolve request
    const resolveTimeOffRequest = async (status: RequestStatusEnum): Promise<void> => {
        const request = new ResolveTimeOffRequest({
            id: requestToResolve?.id,
            requestComment: requestToResolve?.requestComment,
            responseComment: resolveRequestComment ? resolveRequestComment : requestPolicy?.name,
            status: status
        });
        let resolve = await resolveRequest(request);
        if (resolve) handleCloseRequestForm(true);
    }

    // resolve related requests
    const resolveTimeOffRequests = async (status: RequestStatusEnum): Promise<void> => {
        const request = new ResolveBulkTimeOffRequests();
        request.init({
            id: requestToResolve?.id,
            dependentRequestId: requestToResolve?.relatedToRequestId,
            requestComment: requestToResolve?.requestComment,
            responseComment: resolveRequestComment ? resolveRequestComment : "",
            status: status
        });
        let resolve = await resolveRequests(request);
        if (resolve) handleCloseRequestForm(true);
    }

    const handleCloseRequestForm = (isResolved?: boolean): void => {
        isResolved && setIsRequestResolved(!isRequestResolved);
        setIsOpen(false);
        navigate(`/${routeEnum.PENDINGREQUESTS}`);
    }

    const renderTitle = () => requestToResolve?.status === RequestStatusEnum.Pending
        ? <Typography variant="h6">
            {t(TranslationKeyEnum.userRequestedTimeOff, { fullname: `${requestToResolve?.user?.firstName} ${requestToResolve?.user?.lastName}` })}
        </Typography>
        : <Box style={{ display: 'flex' }}>
            <Typography variant="h6">
                {t(TranslationKeyEnum.userRequestedTimeOffCancellationFirstPart, { fullname: `${requestToResolve?.user?.firstName} ${requestToResolve?.user?.lastName}` })}
                <Typography variant="h6" color="red" display="inline"> {t(TranslationKeyEnum.userRequestedTimeOffCancellationSecondPart)} </Typography>
                {t(TranslationKeyEnum.userRequestedTimeOffCancellationThirdPart)}
            </Typography>
        </Box>

    return (
        <Dialog onClose={() => handleCloseRequestForm()} open={isOpen}>
            <Box width="80%" height="90vh" maxHeight="calc(100% - 50px)" position="fixed" top="0" left="0" marginTop={SPACING_EXTRA_LARGE} marginLeft="10%" sx={{ backgroundColor: APP_BG_COLOR, overflowY: "auto" }} borderRadius={BORDER_RADIUS_MEDIUM}>
                {/* Time Off Corner Icon */}
                <Box width="70px" height="70px" position="fixed" boxShadow={DARK_BOX_SHADOW} borderRadius={BORDER_RADIUS_MEDIUM} marginLeft={SPACING_LARGE} sx={{ backgroundColor: APP_BG_COLOR, transform: "translateY(-50%)" }}>
                    <Box display="flex" justifyContent="center">
                        <BeachAccessIcon color="action" fontSize="large" sx={{ marginTop: "16px" }} />
                    </Box>
                </Box>
                <Box position="absolute" right="0" padding={SPACING_SMALL} sx={{ cursor: "pointer" }} onClick={() => handleCloseRequestForm()}>
                    <CloseIcon sx={{ color: ICON_COLOR }} />
                </Box>
                {/* Request data */}
                <Grid container padding={SPACING_LARGE} spacing={4} >
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        {requestToResolve && renderTitle()}
                        <TextField
                            id="policy"
                            name="policy"
                            label={t(TranslationKeyEnum.typeOfAbsence)}
                            fullWidth
                            value={getPolicyName()}
                            disabled
                            sx={{ marginTop: SPACING_SMALL }}
                        />
                        <TextField
                            id="description"
                            name="description"
                            label={t(TranslationKeyEnum.description)}
                            multiline
                            rows={3}
                            fullWidth
                            value={requestToResolve?.requestComment || ""}
                            disabled
                            sx={{ marginTop: SPACING_SMALL }}
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box display="flex" justifyContent="space-between" sx={{ marginTop: SPACING_SMALL }}>
                                <DatePicker
                                    label={t(TranslationKeyEnum.startingFrom)}
                                    format="DD/MM/YYYY"
                                    value={dayjs(getStartDateValue())}
                                    onChange={() => { }}
                                    slots={{ textField: TextField }}
                                    slotProps={{
                                        textField: {
                                            sx: { width: "48%", svg: { color: "action" } },
                                        },
                                    }}
                                    disabled
                                />
                                <DatePicker
                                    label={t(TranslationKeyEnum.ending)}
                                    format="DD/MM/YYYY"
                                    value={dayjs(getEndDateValue())}
                                    onChange={() => { }}
                                    slots={{ textField: TextField }}
                                    slotProps={{
                                        textField: {
                                            sx: { width: "48%", svg: { color: "action" } },
                                        },
                                    }}
                                    disabled
                                />
                            </Box>
                        </LocalizationProvider>
                        {/* Overlapping warning */}
                        {
                            isOverlapping && <Box borderRadius={BORDER_RADIUS_SMALL} padding={SPACING_SMALL} marginTop={SPACING_SMALL} sx={{ backgroundColor: NOTIFICATION_WARNING_BG_COLOR }}>
                                <Typography variant="body2" textAlign="center">{t(TranslationKeyEnum.requestOverlaps)}</Typography>
                            </Box>
                        }
                        {/* Related request warning */}
                        {
                            requestToResolve && relatedRequest && <Box borderRadius={BORDER_RADIUS_SMALL} padding={SPACING_SMALL} marginTop={SPACING_SMALL} sx={{ backgroundColor: NOTIFICATION_WARNING_BG_COLOR }}>
                                <Typography variant="body2" textAlign="center">{t(TranslationKeyEnum.relatedRequest)}</Typography>
                            </Box>
                        }
                        <StyledTextField
                            id="comment"
                            name="comment"
                            label={t(TranslationKeyEnum.comment)}
                            multiline
                            rows={3}
                            fullWidth
                            sx={{ marginTop: SPACING_SMALL }}
                            onChange={(e) => setResolveRequestComment(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        />
                        <Box display="flex" justifyContent="flex-end" marginTop={SPACING_SMALL} gap={4}>
                            <Button type="submit" variant="contained" color="error" onClick={handleRejectRequest} disabled={isDataLoaded === false}>
                                {t(TranslationKeyEnum.decline)}
                            </Button>
                            <Button type="submit" variant="contained" color="success" onClick={handleApproveRequest} disabled={isDataLoaded === false}>
                                {t(requestToResolve && requestToResolve.status === RequestStatusEnum.Pending ? TranslationKeyEnum.approve : TranslationKeyEnum.approveCancellation)}
                            </Button>
                        </Box>
                    </Grid>
                    {/* Calendar */}
                    <Grid item xl={8} lg={8} md={8} display={{ xl: "block", lg: "block", md: "block", sm: "none", xs: "none" }}>
                        {requestToResolve ?
                            <HighlightedCalendar highlightedEventIds={requestToResolve.relatedToRequestId ? [requestToResolve.id!, requestToResolve.relatedToRequestId!] : [requestToResolve.id!]} userId={requestToResolve.userId!} setEvents={setEvents} />
                            :
                            <CalendarSkeleton />
                        }
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    );
}