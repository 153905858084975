import AppContent from "../AppContent/AppContent"
import PermissionProvider from "../Permission/PermissionProvider";
import Navbar from "../Navbar/Navbar";
import { useEffect, useState } from "react";
import { IUserProps } from "../../components/common/interfaces/IUserProps";
import { getUserIdFromStorage } from "../../utils/storageUtils/storageUtils";
import { useGetUser } from "../../API/user-actions";
import { Grid } from '@mui/material';
import { useGetUserPermissions } from "../../API/identity-actions";
import { APP_BG_COLOR, APP_LEFT_AND_RIGHT_PADDING, APP_TOP_AND_BOTTOM_PADDING, SPACING_EXTRA_SMALL } from "../../utils/cssUtils";
import { atom, useSetAtom } from "jotai";
import { StatusEnum } from "../../API/identity-service";

export const userAtom = atom({
  userId: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  address: "",
  birthdate: "",
  image: "",
  role: "",
  office: "",
  yearsOfService: 0,
  approver: {},
  selectedPermissions: [],
  positions: [],
  status: StatusEnum.Active,
  personalNo: "",
  passportNumber: "",
  passportValidUntil: "",
  contractExpiration: "",
  idCardNumber: "",
  idCardValidUntil: "",
  doctorAppointment: "",
  certificateValidUntil: "",
  files: [],
  filesToDelete: [],
  filesToAdd: [],
  medicalInsuranceNumber: "",
  religiousDay: "",
} as IUserProps);

const AppContainer = () => {
  const setUser = useSetAtom(userAtom);
  const [userPermissions, setUserPermissions] = useState<string[]>();

  const userId = getUserIdFromStorage();
  const fetchUser = useGetUser();
  const fetchPermissions = useGetUserPermissions();

  useEffect(() => {
    const getUser = async () => {
      let user = userId && (await fetchUser(userId));
      let userPermissions = userId && (await fetchPermissions());
      if (!user || !userPermissions) {
        return;
      }
      setUser(user);
      setUserPermissions(userPermissions);
    }
    getUser();
  }, [])

  const fetchPermission = () => async (permission: string): Promise<boolean> => {
    if (userPermissions) return userPermissions!.includes(permission);
    const userPermissionsResult = await fetchPermissions();
    setUserPermissions(userPermissionsResult);
    return userPermissionsResult && userPermissionsResult.includes(permission);
  }

  return (
    <Grid container padding={`${APP_TOP_AND_BOTTOM_PADDING} ${APP_LEFT_AND_RIGHT_PADDING}`} minHeight="100vh" sx={{ backgroundColor: APP_BG_COLOR }}>
      <PermissionProvider fetchPermission={fetchPermission()}>
        {/* Navigation Grid */}
        <Grid item xl={3} lg={3} md={3} sm={12} xs={12} padding={SPACING_EXTRA_SMALL}>
          <Navbar />
        </Grid>
        {/* App Grid */}
        <Grid item xl={9} lg={9} md={9} sm={12} xs={12} padding={SPACING_EXTRA_SMALL} zIndex={0}>
          <AppContent />
        </Grid>
      </PermissionProvider>
    </Grid>
  )
}

export default AppContainer;