import { useEffect, useState } from "react";

import Calendar from "../common/Calendar/Calendar";
import { ICalendarEvent } from "../common/Calendar/ICalendarProps";
import { useGetCalendarEvents } from "./actions";
import { colourPairs } from "../common/Calendar/helpers";
import CalendarSkeleton from "../common/Calendar/CalendarSkeleton";


export default (props: {
    highlightedEventIds: string[],
    userId: string, // id of the user whose bank holidays will be displayed
    setEvents: (request: ICalendarEvent[]) => void
}): JSX.Element => {
    const [events, setEvents] = useState<ICalendarEvent[]>([]);
    const [initialDate, setInitialDate] = useState<string | undefined>(undefined);
    const getCalendarEvents = useGetCalendarEvents();

    useEffect(() => {
        const fetchData = async () => {
            const events = await getCalendarEvents(props.userId);
            let date: string | null = null;
            for (const event of events) {
                if (props.highlightedEventIds.includes(event.id)) {
                    event.color = colourPairs["Highlight"].backgroundColor;
                    event.textColor = colourPairs["Highlight"].backgroundColor;
                    event.display = "background";
                    event.classNames = ["selected-date-range"]
                    date = date === null ? event.start : date;
                }
            }
            setInitialDate(date ?? undefined);
            setEvents(events);
            props.setEvents(events);
        }
        fetchData();
    }, [])

    return events.length > 0 ? <div><Calendar initialDate={initialDate} events={events} /></div> : <CalendarSkeleton />
}