import React, { PropsWithChildren } from 'react';
import usePermission from "./usePermission";

type Props = {
    permission: string;
};

// This component is meant to be used everywhere a restriction based on user permission is needed
const Restricted: React.FunctionComponent<PropsWithChildren<Props>> = ({permission, children}) => {
    // We "connect" to the provider thanks to the PermissionContext
    const allowed = usePermission(permission);

    // If the user has that permission, render the children
    return allowed ? <>{children}</> : <></>;
};

export default Restricted;