import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import i18n from '../../features/translations/i18n';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useTranslation } from 'react-i18next';
import { GridActionsCellItem, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { LanguageEnum } from '../../features/translations/LanguageEnum';
import { ROWS_PER_PAGE_OPTIONS } from '../common/Constants/PaginationConstants';
import { srRS } from '../../features/translations/DataGrid/srRS';
import { Avatar, Tooltip, Box } from '@mui/material';
import { TranslationKeyEnum } from '../../features/translations/TranslationKeyEnum';
import { useGetDeactivatedUsers } from './actions';
import { IEmployeesTableProps } from './IEmployeesTableProps';
import { SPACING_SMALL } from '../../utils/cssUtils';
import { StyledDataGrid } from '../StyledDataGrid/StyledDataGrid';
import EditUserForm from '../EditUserForm/EditUserForm';
import { Dispatch, SetStateAction } from 'react';

export default function DeactivatedUsers(props: { rerender: boolean, setRerender: Dispatch<SetStateAction<boolean>> }) {
    const [page, setPage] = React.useState<number>(0);
    const [pageSize, setPageSize] = React.useState<number>(ROWS_PER_PAGE_OPTIONS[0]);
    const { t } = useTranslation();
    const getDeactivatedUsers = useGetDeactivatedUsers();
    const [rows, setRows] = React.useState<IEmployeesTableProps[]>([]);
    const [isDeactivatedUsersDataLoading, setIsDeactivatedUsersDataLoading] = React.useState<boolean>(true);
    const [isEditUserFormOpen, setIsEditUserFormOpen] = React.useState(false);
    const [id, setId] = React.useState<string>("");

    const handlePaginationModelChange = (model: GridPaginationModel) => {
        setPage(model.page);
        setPageSize(model.pageSize);
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Id', width: 130, minWidth: 130 },
        {
            field: 'fullName', headerName: t(TranslationKeyEnum.userLabel), description: t(TranslationKeyEnum.userLabel), flex: 1, renderCell: (cellValues) => {
                return (
                    <Box sx={{ display: 'flex', alignItems: "center", flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Avatar variant="rounded" sx={{ width: 30, height: 30 }} src={cellValues.row.image && `data:image/png;base64,${cellValues.row.image}`} />
                        <Typography paddingLeft={2} variant="body2" textAlign="center">{cellValues.row.firstName} {cellValues.row.lastName}</Typography>
                    </Box>
                );
            }
        },
        {
            field: 'email', headerName: t(TranslationKeyEnum.email), description: t(TranslationKeyEnum.email), width: 150, minWidth: 100, flex: 1.5, renderCell: (cellValues) => {
                return (
                    <Typography variant="body2" textAlign="center" color="primary">{cellValues.value}</Typography>
                );
            }
        },
        { field: 'office', headerName: t(TranslationKeyEnum.office), width: 100, minWidth: 100, flex: 1 },
        { field: 'currentPosition', headerName: t(TranslationKeyEnum.position), width: 100, minWidth: 100, flex: 1 },
        {
            field: 'actions', headerName: '', sortable: false, width: 50, minWidth: 50, flex: 1, renderCell: () => {
                return <Box sx={{ display: 'flex', marginLeft: "auto", flexDirection: 'row', justifyContent: 'space-around' }}>
                    <Tooltip title={t(TranslationKeyEnum.activate)}><GridActionsCellItem icon={<RestartAltIcon />} label={t(TranslationKeyEnum.delete)} onClick={() => setIsEditUserFormOpen(true)} /></Tooltip>
                </Box>
            }
        },
    ];

    React.useEffect(() => {
        const fetchData = async () => {
            let getUsers = await getDeactivatedUsers();
            if (!getUsers) return;
            setRows(await getDeactivatedUsers());
            setIsDeactivatedUsersDataLoading(false);
        }
        fetchData();
    }, [props.rerender]);

    return (
        <>
            <Box marginTop={SPACING_SMALL}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="h5">{t(TranslationKeyEnum.deactivatedUsers)}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <StyledDataGrid
                            columnVisibilityModel={{
                                id: false,
                            }}
                            localeText={i18n.language === LanguageEnum.Serbian ? srRS.components.MuiDataGrid.defaultProps.localeText : undefined}
                            autoHeight={true}
                            rows={rows}
                            columns={columns}
                            paginationModel={{ page, pageSize }}
                            onPaginationModelChange={handlePaginationModelChange}
                            pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
                            pagination
                            hideFooterSelectedRowCount={true}
                            loading={isDeactivatedUsersDataLoading}
                            disableRowSelectionOnClick
                            onCellClick={(selectedCell) => setId(selectedCell.id.toString())}
                        />
                    </AccordionDetails>
                </Accordion>
            </Box>
            {isEditUserFormOpen && <EditUserForm userId={id} open={isEditUserFormOpen} onClose={() => setIsEditUserFormOpen(!isEditUserFormOpen)} rerender={props.rerender} setRerender={props.setRerender} reinitializeEnabled={true} isForActivation={true} />}
        </>
    );
}