import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import i18n from '../../features/translations/i18n';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { GridActionsCellItem, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { LanguageEnum } from '../../features/translations/LanguageEnum';
import { ROWS_PER_PAGE_OPTIONS } from '../common/Constants/PaginationConstants';
import { srRS } from '../../features/translations/DataGrid/srRS';
import { TranslationKeyEnum } from '../../features/translations/TranslationKeyEnum';
import { useTranslation } from 'react-i18next';
import { Tooltip, Box } from '@mui/material';
import { useGetDeactivatedPolicies } from '../../containers/TimeOff/actions';
import { BasicTimeOffPolicyDto, UpdateTimeOffPolicyRequest } from '../../API/time-off-service';
import { SPACING_SMALL } from '../../utils/cssUtils';
import { StyledDataGrid } from '../StyledDataGrid/StyledDataGrid';
import { Dispatch, SetStateAction } from "react";
import AddEditPolicyFormDialog from './AddEditPolicyForm/AddEditPolicyFormDialog';
import { IPolicyModel } from './AddEditPolicyForm/IAddEditPolicyFormProps';
import { useUpdatePolicy } from '../../API/time-off-actions';

export default function DeactivatedPolicies(props: { rerender: boolean, setRerender: Dispatch<SetStateAction<boolean>>, basePoliciesModel: IPolicyModel[] }) {
    const [page, setPage] = React.useState<number>(0);
    const [pageSize, setPageSize] = React.useState<number>(ROWS_PER_PAGE_OPTIONS[0]);
    const { t } = useTranslation();
    const getDeactivatedPolicies = useGetDeactivatedPolicies();
    const [rows, setRows] = React.useState<BasicTimeOffPolicyDto[]>([]);
    const [isDeactivatedPolicesLoading, setIsDeactivatedPoliciesLoading] = React.useState<boolean>(true);
    const [activatePolicyDialogOpen, setActivatePolicyDialogOpen] = React.useState(false);
    const [policyId, setPolicyId] = React.useState<string>("");

    const updatePolicy = useUpdatePolicy();
    const handlePaginationModelChange = (model: GridPaginationModel) => {
        setPage(model.page);
        setPageSize(model.pageSize);
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Id', width: 130 },
        { field: 'name', headerName: t(TranslationKeyEnum.name), width: 130, editable: true, flex: 1.5 },
        {
            field: 'actions', type: "actions", sortable: false, width: 100, minWidth: 100, flex: .5, getActions: (params: any) => {
                return [
                    <Tooltip title={t(TranslationKeyEnum.activate)}><GridActionsCellItem icon={<RestartAltIcon />} label={t(TranslationKeyEnum.delete)} onClick={() => { setPolicyId(params.id); setActivatePolicyDialogOpen(true); }} /></Tooltip>
                ]
            }
        }
    ];

    React.useEffect(() => {
        const fetchData = async () => {
            let getUsers = await getDeactivatedPolicies();
            if (!getUsers) return;
            setRows(getUsers);
            setIsDeactivatedPoliciesLoading(false);
        }
        fetchData();
    }, [props.rerender]);

    const handleCloseDialog = () => {
        setActivatePolicyDialogOpen(false);
        setPolicyId("");
    };

    async function handleUpdatePolicyDialog(request: UpdateTimeOffPolicyRequest) {
        await updatePolicy(request);
        setActivatePolicyDialogOpen(false);
        setPolicyId("");
        props.setRerender(true);
    };

    return (
        <>
            <Box marginTop={SPACING_SMALL}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="h5">{t(TranslationKeyEnum.deactivatedPolicies)}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <StyledDataGrid
                            columnVisibilityModel={{
                                id: false,
                            }}
                            localeText={i18n.language === LanguageEnum.Serbian ? srRS.components.MuiDataGrid.defaultProps.localeText : undefined}
                            autoHeight={true}
                            rows={rows}
                            columns={columns}
                            paginationModel={{ page, pageSize }}
                            onPaginationModelChange={handlePaginationModelChange}
                            pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
                            pagination
                            hideFooterSelectedRowCount={true}
                            disableRowSelectionOnClick
                            loading={isDeactivatedPolicesLoading}
                        />
                    </AccordionDetails>
                </Accordion>
            </Box>
            {
                activatePolicyDialogOpen &&
                <AddEditPolicyFormDialog
                    open={activatePolicyDialogOpen}
                    onClose={handleCloseDialog}
                    onAddSubmit={() => {}} onEditSubmit={handleUpdatePolicyDialog}
                    policiesModel={props.basePoliciesModel} policyId={policyId}
                    isAdd={false}
                    isActivation={true}
                    isCopyPolicy={false}
                />
            }
        </>

    );
}
