import { useTranslation } from 'react-i18next';
import { TranslationKeyEnum } from '../../features/translations/TranslationKeyEnum';
import { LanguageEnum } from '../../features/translations/LanguageEnum';
import { srRS } from '../../features/translations/DataGrid/srRS';
import { Tooltip, Typography, Box } from "@mui/material";
import { GridActionsCellItem, GridPaginationModel, GridColDef } from '@mui/x-data-grid';
import { IEmployeesTableProps } from './IEmployeesTableProps';
import { CARD_BOX_SHADOW, SPACING_EXTRA_SMALL } from '../../utils/cssUtils';
import { ROWS_PER_PAGE_OPTIONS } from '../common/Constants/PaginationConstants';
import { useGetActiveUsers } from './actions';
import { useDeactivateUser } from '../../API/time-off-actions';
import { getTextTransformationAccordingToLanguage } from '../../features/translations/helpers';
import { useGetUser } from '../../API/user-actions';
import { StyledDataGrid } from '../StyledDataGrid/StyledDataGrid';
import React from 'react';
import i18n from '../../features/translations/i18n';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ConfirmDialog from '../Dialogs/ConfirmDialog/ConfirmDialog';
import CreateUserFormDialog from '../CreateUserForm/CreateUserFormDialog';
import EditUserForm from '../EditUserForm/EditUserForm';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import DeactivatedUsers from './DeactivatedUsers';

function AdminEmployees() {
    const { t } = useTranslation();
    const getUser = useGetUser();
    const getActiveUsers = useGetActiveUsers();
    const deactivateEmployee = useDeactivateUser();

    const [id, setId] = React.useState<string>("");
    const [selectedUserFullname, setSelectedUserFullname] = React.useState<string>("");
    const [rows, setRows] = React.useState<IEmployeesTableProps[]>([]);
    const [isCreateUserFormOpen, setIsCreateUserFormOpen] = React.useState(false);
    const [isEditUserFormOpen, setIsEditUserFormOpen] = React.useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
    const [isDeleteApproved, setIsDeleteApproved] = React.useState(false);
    const [rerender, setRerender] = React.useState(false);
    const [page, setPage] = React.useState<number>(0);
    const [pageSize, setPageSize] = React.useState<number>(ROWS_PER_PAGE_OPTIONS[0]);
    const [isActiveUsersDataLoading, setIsActiveUsersDataLoading] = React.useState<boolean>(true);

    const handlePaginationModelChange = (model: GridPaginationModel) => {
        setPage(model.page);
        setPageSize(model.pageSize);
    }

    React.useEffect(() => {
        const fetchData = async () => {
            let users = await getActiveUsers();
            if (!users) return;
            setRows(await getActiveUsers());
            setIsActiveUsersDataLoading(false)
        }
        fetchData();
    }, [rerender]);

    React.useEffect(() => {
        if (id === "" || !isDeleteApproved) return;
        const deactivateUserData = async () => {
            await deactivateEmployee(id);
            setIsDeleteApproved(false);
            setRerender(!rerender);
        }
        deactivateUserData();
    }, [isDeleteApproved]);

    React.useEffect(() => {
        const fetchUserFullname = async () => {
            if (!id) return;
            let user = await getUser(id);
            if (!user) return;
            setSelectedUserFullname(`${user.firstName} ${user.lastName}`);
        }
        fetchUserFullname();
    }, [id])

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Id', width: 130, minWidth: 130 },
        {
            field: 'fullName', headerName: t(TranslationKeyEnum.userLabel), description: t(TranslationKeyEnum.userLabel), flex: 1.5, renderCell: (cellValues) => {
                return (
                    <Box sx={{ display: 'flex', alignItems: "center", flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Avatar variant="rounded" sx={{ width: 30, height: 30 }} src={cellValues.row.image && `data:image/png;base64,${cellValues.row.image}`} />
                        <Typography paddingLeft={2} variant="body2" textAlign="center">{cellValues.row.firstName} {cellValues.row.lastName}</Typography>
                    </Box>
                );
            }
        },
        {
            field: 'email', headerName: t(TranslationKeyEnum.email), description: t(TranslationKeyEnum.email), width: 150, minWidth: 100, flex: 1.5, renderCell: (cellValues) => {
                return (
                    <Typography variant="body2" textAlign="center" color="primary">{cellValues.value}</Typography>
                );
            }
        },
        { field: 'office', headerName: t(TranslationKeyEnum.office), width: 100, minWidth: 100, flex: 1 },
        { field: 'currentPosition', headerName: t(TranslationKeyEnum.position), width: 100, minWidth: 100, flex: 1 },
        {
            field: 'actions', headerName: '', sortable: false, width: 80, minWidth: 80, flex: .3, renderCell: (cellValues) => {
                return <Box>
                    <Tooltip title={t(TranslationKeyEnum.edit)}>
                        <GridActionsCellItem icon={<EditIcon name={`edit-${cellValues.row.email}`} />} label={t(TranslationKeyEnum.edit)} onClick={() => setIsEditUserFormOpen(true)} />
                    </Tooltip>
                    <Tooltip title={t(TranslationKeyEnum.delete)}><GridActionsCellItem icon={<DeleteIcon />} label={t(TranslationKeyEnum.delete)} onClick={() => setIsDeleteDialogOpen(true)} /></Tooltip>
                </Box>
            }
        },
    ];

    return (
        <>
            <Box sx={{ boxShadow: CARD_BOX_SHADOW }}>
                <Box padding={SPACING_EXTRA_SMALL} sx={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: 'space-between', gap: SPACING_EXTRA_SMALL }}>
                    <Typography variant="h5">{t(TranslationKeyEnum.employees)}</Typography>
                    <Tooltip title={t(TranslationKeyEnum.add)}>
                        <Button data-test-id="createEmployee" variant="contained" startIcon={<AddIcon />} sx={{ textTransform: getTextTransformationAccordingToLanguage() }} onClick={() => setIsCreateUserFormOpen(true)}>
                            {t(TranslationKeyEnum.addEmployee)}
                        </Button>
                    </Tooltip>
                </Box>
                <StyledDataGrid
                    columnVisibilityModel={{
                        id: false,
                    }}
                    localeText={i18n.language === LanguageEnum.Serbian ? srRS.components.MuiDataGrid.defaultProps.localeText : undefined}
                    autoHeight={true}
                    rows={rows}
                    columns={columns}
                    paginationModel={{ page, pageSize }}
                    onPaginationModelChange={handlePaginationModelChange}
                    pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
                    pagination
                    hideFooterSelectedRowCount={true}
                    onCellClick={(selectedCell) => setId(selectedCell.id.toString())}
                    loading={isActiveUsersDataLoading}
                    disableRowSelectionOnClick
                />
            </Box>
            <DeactivatedUsers rerender={rerender} setRerender={setRerender}/>
            {isCreateUserFormOpen && <CreateUserFormDialog isCreateUserFormOpen={isCreateUserFormOpen} closeCreateUserForm={() => setIsCreateUserFormOpen(false)} rerender={rerender} setRerender={setRerender} />}
            {isEditUserFormOpen && <EditUserForm userId={id} open={isEditUserFormOpen} onClose={() => setIsEditUserFormOpen(!isEditUserFormOpen)} rerender={rerender} setRerender={setRerender} reinitializeEnabled={true} isForActivation={false}/>}
            {
                isDeleteDialogOpen &&
                <ConfirmDialog
                    dialogOpen={isDeleteDialogOpen}
                    setDialogOpen={setIsDeleteDialogOpen}
                    title={t(TranslationKeyEnum.confirmDelete)}
                    description={t(TranslationKeyEnum.deleteEmployeeDescription, { fullname: selectedUserFullname })}
                    isActionConfirmed={setIsDeleteApproved}
                />
            }
        </>
    )
}

export default AdminEmployees;