import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import AddEditGroupForm from "./AddEditGroupForm";
import Button from '@mui/material/Button';
import AccordionSkeleton from "../common/AccordionSkeleton/AccordionSkeleton";
import ConfirmDialog from "../Dialogs/ConfirmDialog/ConfirmDialog";
import { getTextTransformationAccordingToLanguage } from '../../features/translations/helpers';
import { GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import { Grid, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CreateGroupRequest, GroupDto, UpdateGroupRequest } from "../../API/time-off-service";
import { srRS } from "../../features/translations/DataGrid/srRS";
import { LanguageEnum } from "../../features/translations/LanguageEnum";
import { TranslationKeyEnum } from "../../features/translations/TranslationKeyEnum";
import { IPolicyUserTableProps } from "../AdminPolicies/IPolicyUserTableProps";
import { useCreateGroup, useGetGroups, useRemoveGroup, useUpdateGroup } from "./actions";
import { SPACING_EXTRA_SMALL } from "../../utils/cssUtils";
import { ROWS_PER_PAGE_OPTIONS } from "../common/Constants/PaginationConstants";
import { StyledDataGrid } from "../StyledDataGrid/StyledDataGrid";

export default () => {
    const [groups, setGroups] = React.useState<GroupDto[]>();
    const [tableData, setTableData] = React.useState<Map<string, IPolicyUserTableProps[]>>();
    const [page, setPage] = React.useState<number>(0);
    const [pageSize, setPageSize] = React.useState<number>(ROWS_PER_PAGE_OPTIONS[0]);

    const handlePaginationModelChange = (model: GridPaginationModel) => {
        setPage(model.page);
        setPageSize(model.pageSize);
    }

    const { t, i18n } = useTranslation();

    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false);
    const [groupId, setGroupId] = React.useState<string | undefined>();
    const [approveDelete, setApproveDelete] = React.useState(false);

    const [formOpen, setFormOpen] = React.useState(false);

    const getGroups = useGetGroups();
    const removeGroup = useRemoveGroup();
    const createGroup = useCreateGroup();
    const updateGroup = useUpdateGroup();

    const handleDelete = (id: string) => {
        setDeleteDialogOpen(true);
        setGroupId(id);
    }

    const resetForm = async () => {
        setGroups(await getGroups())
        setGroupId(undefined)
        setFormOpen(false)
    }

    const handleCreateGroup = async (request: CreateGroupRequest): Promise<boolean> => {
        if (await createGroup(request)) {
            await resetForm();
            return true;
        }
        return false;
    }

    const handleUpdateGroup = async (request: UpdateGroupRequest): Promise<boolean> => {
        if (await updateGroup(request)) {
            await resetForm();
            return true;
        }
        return false;
    }

    React.useEffect(() => {
        const removeGroupAndFetchData = async () => {
            if (approveDelete && (await removeGroup(groupId!))) {
                setGroups(await getGroups())
                setApproveDelete(false)
            } else if (approveDelete) {
                setApproveDelete(false)
            }
            setGroupId(undefined)
        }
        removeGroupAndFetchData();
    }, [approveDelete])

    React.useEffect(() => {
        const fetchData = async () => setGroups(await getGroups());
        fetchData();
    }, []);


    React.useEffect(() => {
        setTableData(mapTableUsers());
    }, [groups])


    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Id', width: 130, minWidth: 100 },
        { field: 'firstName', headerName: t(TranslationKeyEnum.firstName), minWidth: 150, flex: 1 },
        { field: 'lastName', headerName: t(TranslationKeyEnum.lastName), minWidth: 150, flex: 1 },
    ];

    function mapTableUsers(): Map<any, any> {
        return new Map(groups?.map(i => [i.id, i.members?.map(member => {
            return {
                id: member?.id,
                firstName: member?.firstName,
                lastName: member?.lastName,
            } as IPolicyUserTableProps
        })]));
    }

    return (
        <>
            <Box padding={SPACING_EXTRA_SMALL} sx={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: 'space-between', gap: SPACING_EXTRA_SMALL }}>
                <Typography variant="h5">{t(TranslationKeyEnum.groups)}</Typography>
                <Tooltip title={t(TranslationKeyEnum.add)}>
                    <Button variant="contained" startIcon={<AddIcon />} sx={{ textTransform: getTextTransformationAccordingToLanguage() }} onClick={() => setFormOpen(true)}>
                        {t(TranslationKeyEnum.addGroup)}
                    </Button>
                </Tooltip>
            </Box>
            {
                groups
                    ? groups!.map((group: GroupDto) =>
                        <Accordion key={group.id}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ pointerEvents: "auto" }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{ pointerEvents: "none" }}
                            >
                                <Grid container>
                                    <Grid item sm={2} md={7}>
                                        <Typography>{group.name}</Typography>
                                    </Grid>
                                    <Grid item sm={2} md={3}>{`${t(TranslationKeyEnum.requirement)}: ${group.mustBePresent}/${group.members?.length}`}</Grid>
                                    <Grid item sm={2} md={2}>
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            spacing={0.5}
                                        >
                                            <Tooltip title={t(TranslationKeyEnum.edit)}>
                                                <IconButton>
                                                    <EditIcon
                                                        sx={{ pointerEvents: "auto" }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setGroupId(group.id);
                                                            setFormOpen(true);
                                                        }}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={t(TranslationKeyEnum.delete)}>
                                                <IconButton>
                                                    <DeleteIcon
                                                        sx={{ pointerEvents: "auto" }} onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleDelete(group.id!);
                                                        }}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <StyledDataGrid
                                    columnVisibilityModel={{
                                        id: false,
                                    }}
                                    localeText={i18n.language === LanguageEnum.Serbian ? srRS.components.MuiDataGrid.defaultProps.localeText : undefined}
                                    autoHeight={true}
                                    paginationModel={{ page, pageSize }}
                                    onPaginationModelChange={handlePaginationModelChange}
                                    pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
                                    pagination
                                    hideFooterSelectedRowCount={true}
                                    columns={columns}
                                    rows={tableData?.get(group.id!) ?? []}
                                    disableRowSelectionOnClick
                                />
                            </AccordionDetails>
                        </Accordion>)
                    : <AccordionSkeleton title={t(TranslationKeyEnum.groupsLoading)} />
            }
            <AddEditGroupForm
                close={() => {
                    setFormOpen(false)
                    setGroupId(undefined)
                }}
                group={groups?.find(g => g.id === groupId)}
                open={formOpen}
                submit={groupId ? handleUpdateGroup : handleCreateGroup}
            />
            {deleteDialogOpen &&
                <ConfirmDialog
                    dialogOpen={deleteDialogOpen}
                    setDialogOpen={setDeleteDialogOpen}
                    title={t(TranslationKeyEnum.confirmDelete)}
                    description={t(TranslationKeyEnum.deleteGroupDescription)}
                    isActionConfirmed={setApproveDelete}
                />
            }
        </>
    )
}