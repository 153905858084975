import React from 'react';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import UpcomingBankHolidaysIcon from "../../../components/AdminBankHolidays/UpcomingBankHolidaysIcon/UpcomingBankHolidaysIcon";
import { Grid, Box, Button } from '@mui/material';
import { useGetTwoMostRecentBankHolidays } from "../../../components/AdminBankHolidays/actions";
import { BankHolidayDto } from '../../../API/time-off-service';
import { Link } from 'react-router-dom';
import { PRIMARY_BG_COLOR, SPACING_SMALL, CARD_BOX_SHADOW, LIGHT_BORDER, BORDER_RADIUS_MEDIUM, CARD_HEIGHT, CARD_HEADER_HEIGHT, CARD_FOOTER_HEIGHT, CARD_BODY_HEIGHT, MIN_CARD_HEIGHT, MIN_CARD_HEADER_HEIGHT, MIN_CARD_FOOTER_HEIGHT, MIN_CARD_BODY_HEIGHT, SPACING_EXTRA_SMALL } from '../../../utils/cssUtils';
import { TranslationKeyEnum } from "../../../features/translations/TranslationKeyEnum";
import { useTranslation } from 'react-i18next';
import { getTextTransformationAccordingToLanguage } from '../../../features/translations/helpers';

export const UpcomingHolidaysCard = () => {
    const { t } = useTranslation();
    const [twoMostRecentBankHolidays, setTwoMostRecentBankHolidays] = React.useState<BankHolidayDto[]>([]);
    const getTwoMostRecentBankHolidays = useGetTwoMostRecentBankHolidays();

    React.useEffect(() => {
        const fetchTwoMostRecentBankHolidays = async () => {  
            let holidays = await getTwoMostRecentBankHolidays();
            holidays && setTwoMostRecentBankHolidays(holidays);
        }
        fetchTwoMostRecentBankHolidays();
    }, []);

    return (
        <Box sx={{ height: CARD_HEIGHT, minHeight: MIN_CARD_HEIGHT, borderRadius: BORDER_RADIUS_MEDIUM, boxShadow: CARD_BOX_SHADOW, backgroundColor: PRIMARY_BG_COLOR }}>
            {/* Card Header */}
            <Box height={CARD_HEADER_HEIGHT} minHeight={MIN_CARD_HEADER_HEIGHT} display="flex" alignItems="center" justifyContent="center" borderBottom={LIGHT_BORDER}>
                <Typography sx={{ textTransform: "uppercase" }} variant="subtitle2">
                    {t(TranslationKeyEnum.upcomingHolidays)}
                </Typography>
            </Box>
            {/* Card Body */}
            <Grid container borderBottom={LIGHT_BORDER} height={CARD_BODY_HEIGHT} minHeight={MIN_CARD_BODY_HEIGHT}>
                {
                    twoMostRecentBankHolidays && twoMostRecentBankHolidays.map(bankHoliday => {                   
                        let isFirstBankHoliday = twoMostRecentBankHolidays[0] === bankHoliday;
                        return <UpcomingBankHolidaysIcon isFirstBankHoliday={isFirstBankHoliday} title={bankHoliday.name!} date={bankHoliday.startDate!} />
                    })
                }
            </Grid>    
            {/* Card Footer */}
            <Box height={CARD_FOOTER_HEIGHT} minHeight={MIN_CARD_FOOTER_HEIGHT} display="flex" alignItems="center" justifyContent="center">
                <Button variant="text" sx={{ textTransform: getTextTransformationAccordingToLanguage()}} size="small" component={Link} to="/calendar">
                    {t(TranslationKeyEnum.viewCalendar)}
                    <Link to="/calendar" style={{ display: "flex", marginLeft: "1vh" }}>
                        <ArrowForwardIcon fontSize="small" color="primary" />
                    </Link>
                </Button>
            </Box>
        </Box>
    );
}