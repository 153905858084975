import React from 'react';
import WorkOrderCard from '../../EmployeeProfile/WorkOrdersInfo/WorkOrderCard/WorkOrderCard';
import { Box, Skeleton } from '@mui/material';
import { CARD_HEIGHT, INFO_CARD_MIN_WIDTH, INFO_CARD_WIDTH, INFO_CARD_WIDTH_SM, INFO_CARD_WIDTH_XS, SPACING_MEDIUM, WORK_ORDER_CARD_MIN_HEIGTH } from '../../../utils/cssUtils';
import { useGetNextWorkOrders } from '../../../API/work-order-actions';
import { getUserIdFromStorage } from '../../../utils/storageUtils/storageUtils';
import { BasicWorkOrderDto } from '../../../API/work-order-service';
import { IUpcomingWorkOrdersProps } from './IUpcomingWorkOrdersProps';
import { useParams } from 'react-router-dom';

export default function UpcomingWorkOrders({ rerender, setRerender, isForMe }: IUpcomingWorkOrdersProps) {
    const getNextWorkOrders = useGetNextWorkOrders();
    const params = useParams();

    const [nextWorkOrders, setNextWorkOrders] = React.useState<BasicWorkOrderDto[]>([]);
    const [isDataLoading, setIsDataLoading] = React.useState<boolean>(true);

    React.useEffect(() => {
        const fetchData = async () => {
            let userId = getUserIdFromStorage()!;
            if(!userId && isForMe) return;
            let nextWorkOrdersRequest = await getNextWorkOrders(isForMe ? userId : params.id!, 2);
            if (!nextWorkOrdersRequest) return;
            setNextWorkOrders(nextWorkOrdersRequest);
            setIsDataLoading(false);
        };
        fetchData();
    }, [rerender])

    return (
        <Box display="flex" flexDirection="column" alignItems="center" gap={SPACING_MEDIUM}>
            {
                isDataLoading
                    ? <Box display="flex" flexDirection="column" gap={SPACING_MEDIUM}>
                        <Skeleton variant="rectangular" height={CARD_HEIGHT} sx={{ width: { xl: INFO_CARD_WIDTH, lg: INFO_CARD_WIDTH, md: INFO_CARD_WIDTH, sm: INFO_CARD_WIDTH_SM, xs: INFO_CARD_WIDTH_XS }, minHeight: { WORK_ORDER_CARD_MIN_HEIGTH }, minWidth: { INFO_CARD_MIN_WIDTH } }} />
                        <Skeleton variant="rectangular" height={CARD_HEIGHT} sx={{ width: { xl: INFO_CARD_WIDTH, lg: INFO_CARD_WIDTH, md: INFO_CARD_WIDTH, sm: INFO_CARD_WIDTH_SM, xs: INFO_CARD_WIDTH_XS }, minHeight: { WORK_ORDER_CARD_MIN_HEIGTH }, minWidth: { INFO_CARD_MIN_WIDTH } }} />
                    </Box>
                    : nextWorkOrders?.map((workOrder: BasicWorkOrderDto) => {
                        return <WorkOrderCard
                            id={workOrder.id}
                            startDate={workOrder.startDate}
                            endDate={workOrder.endDate}
                            locationCode={workOrder.locationCode}
                            typeOfWork={workOrder.typeOfWork}
                            goingWith={workOrder?.userWorkOrders?.filter(x => x.isAssigned)}
                            rerender={rerender}
                            setRerender={setRerender}
                        />
                    })
            }
        </Box>
    )
}