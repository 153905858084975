import React from 'react'
import WeekCalendarFilltersDialog from './WeekCalendarFilltersDialog/WeekCalendarFilltersDialog';
import FullCalendar from '@fullcalendar/react'
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import PermissionContext from '../../containers/Permission/PermissionContext';
import CreateEditWorkOrder from '../../containers/WorkOrders/CreateEditWorkOrder/CreateEditWorkOrder';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css'; // needs additional webpack config!
import '../common/Calendar/calendar.css';
import { Box, Button, Typography, Tooltip, FormControl, InputLabel, CircularProgress, MenuItem, Skeleton } from '@mui/material';
import { useGetAllUsersEvents, useGetWeekCalendarEventsForAllWorkOrders, useGetWeekCalendarEventsForAvailableUsers, useGetWeekCalendarEventsForUsersWithWorkOrders } from './actions';
import { CalendarItemType, ICalendarEvent } from '../common/Calendar/ICalendarProps';
import { IReadOnlyWeekCalendarProps } from './IReadOnlyWeekCalendarProps';
import { GroupDto } from '../../API/time-off-service';
import { LIGHT_BG_COLOR, SPACING_EXTRA_SMALL, SPACING_SMALL } from '../../utils/cssUtils';
import { getTextTransformationAccordingToLanguage } from '../../features/translations/helpers';
import { PermissionsEnum } from '../../containers/Permission/PermisionEnum';
import { useTranslation } from 'react-i18next';
import { TranslationKeyEnum } from '../../features/translations/TranslationKeyEnum';
import { StyledSelectField } from '../StyledSelect/StyledSelect';
import { useGetGroups } from '../AdminGroups/actions';
import { renderMonthAndYearAccordingToLanguage, renderWeekdayHeaderAccordingToLanguage, subtractDays } from '../common/Calendar/helpers';
import { usePrevious } from '../../helpers/usePrevious';
import { EventClickArg } from '@fullcalendar/core';

export enum FilterOptionEnum {
    ShowAllUsers = "show-all-users",
    ShowAll = "show-all",
    ShowBusyUsers = "show-busy-users",
    ShowFreeUsers = "show-free-users",
}

type FetchFilter = {
    [key in FilterOptionEnum]: (userIds: string[], isLocationTypeNeeded: boolean, startDate: Date, endDate: Date) => Promise<ICalendarEvent[]>;
};

type DateObject = { selectedStartDate: Date; selectedEndDate: Date; };

export default function ReadOnlyWeekCalendar({ numberOfDaysDisplayed }: IReadOnlyWeekCalendarProps) {
    const calendarRef = React.createRef<FullCalendar>();
    const { t } = useTranslation();
    const { isAllowed } = React.useContext(PermissionContext);
    const getGroups = useGetGroups();

    const getAllUsersEvents = useGetAllUsersEvents(false);
    const getAllCalendarEventsForAllWorkOrders = useGetWeekCalendarEventsForAllWorkOrders();
    const getUsersWithWorkOrdersForCalendar = useGetWeekCalendarEventsForUsersWithWorkOrders(false);
    const getAvaliableUsers = useGetWeekCalendarEventsForAvailableUsers(false, false);

    const [workOrderId, setWorkOrderId] = React.useState<string>("");
    const [selectedStartDate, setSelectedStartDate] = React.useState<Date>(new Date());
    const [selectedEndDate, setSelectedEndDate] = React.useState<Date>(new Date());
    const prevDates: DateObject | undefined = usePrevious<DateObject>({ selectedStartDate, selectedEndDate });
    const [group, setGroup] = React.useState<GroupDto>(new GroupDto());
    const [groups, setGroups] = React.useState<GroupDto[]>([]);
    const [isGroupDataLoading, setIsGroupDataLoading] = React.useState<boolean>(true);
    const [events, setEvents] = React.useState<ICalendarEvent[]>([]);
    const [renderKey, setRenderKey] = React.useState<string>(events.length.toString());
    const [userIds, setUserIds] = React.useState<string[]>([]);
    const [open, setOpen] = React.useState(false);
    const [openWorkOderForm, setOpenWorkOrderForm] = React.useState<boolean>(false);
    const [filter, setFilter] = React.useState(FilterOptionEnum.ShowAllUsers);
    const [selectedFilter, setSelectedFilter] = React.useState(FilterOptionEnum.ShowAllUsers);
    const [isUserSupervisor, setIsUserSupervisor] = React.useState<boolean>(false);
    const [rerender, setRerender] = React.useState<boolean>(false);
    const [isFilterDataLoading, setIsFilterDataLoading] = React.useState<boolean>(false);

    const fetchEvents: FetchFilter = {
        [FilterOptionEnum.ShowAllUsers]: getAllUsersEvents,
        [FilterOptionEnum.ShowAll]: getAllCalendarEventsForAllWorkOrders,
        [FilterOptionEnum.ShowBusyUsers]: getUsersWithWorkOrdersForCalendar,
        [FilterOptionEnum.ShowFreeUsers]: getAvaliableUsers,
    }

    const allUsersGroup = new GroupDto({
        id: "all-users-group",
        name: "All users",
        members: []
    });

    const fetchData = async () => {
        setIsFilterDataLoading(true)
        const calendarEvents = await fetchEvents[selectedFilter](userIds, true, selectedStartDate, subtractDays(new Date(selectedEndDate), 1));
        setEvents(calendarEvents);
        setIsFilterDataLoading(false)
    }

    React.useEffect(() => {
        setSelectedStartDate(calendarRef.current?.getApi().view.currentStart!);
        setSelectedEndDate(calendarRef.current?.getApi().view.currentEnd!)
    }, [calendarRef.current])

    React.useEffect(() => {
        const fetchGroups = async () => {
            let allGroups = await getGroups();
            if (!allGroups) return;
            setGroups([allUsersGroup].concat(allGroups));
            setIsGroupDataLoading(false);
        };
        fetchGroups();
    }, [])

    React.useEffect(() => {
        fetchData();
    }, [userIds, selectedFilter, rerender])

    React.useEffect(() => {
        prevDates && prevDates.selectedStartDate.toString() !== selectedStartDate.toString() && fetchData();
    }, [selectedStartDate])

    React.useEffect(() => {
        setRenderKey(Date.now().toString())
    }, [events])

    React.useEffect(() => {
        group && group.members && setUserIds(group.members!.map(user => user.id!))
    }, [group])

    React.useEffect(() => {
        const checkPermsission = async () => {
            setIsUserSupervisor(await isAllowed(PermissionsEnum.ManageWorkOrderPermission))
        }
        checkPermsission();
    }, [])

    const getCurrentFilterLabel = (filter: string): string => {
        const filterLabels: { [key: string]: string } = {
            [FilterOptionEnum.ShowAllUsers]: t(TranslationKeyEnum.showAllUsers),
            [FilterOptionEnum.ShowAll]: t(TranslationKeyEnum.showWorkOrders),
            [FilterOptionEnum.ShowBusyUsers]: t(TranslationKeyEnum.showUsersWithWorkOrder),
            [FilterOptionEnum.ShowFreeUsers]: t(TranslationKeyEnum.showAvailableUsers),
        };

        return filterLabels[filter] || "";
    };
    
    return (
        <>
            <Box display="flex" flexDirection="column" gap={SPACING_SMALL}>
                <Box display="flex" alignItems="center" justifyContent="flex-end" gap={SPACING_SMALL}>
                    <Tooltip title={t(TranslationKeyEnum.currentSelectedFilter)}>
                        <Typography variant="body2" color="grey" sx={{ cursor: "pointer" }}>{getCurrentFilterLabel(selectedFilter)}</Typography>
                    </Tooltip>
                    <Box display="flex" alignItems="center" color={LIGHT_BG_COLOR} gap={SPACING_EXTRA_SMALL} sx={{ cursor: "pointer" }} onClick={() => setOpen(true)}>
                        <FilterListIcon />
                        <Typography variant="body2">{t(TranslationKeyEnum.filters)}</Typography>
                    </Box>
                    <FormControl size="small" sx={{ width: "10vw" }}>
                        <InputLabel shrink>{t(TranslationKeyEnum.groups)}</InputLabel>
                        <StyledSelectField
                            id="group"
                            name="group"
                            label={t(TranslationKeyEnum.groups)}
                            value={group}
                            onChange={(event) => setGroup(groups.find(group => group.id === event.target.value)!)}
                            fullWidth
                            notched={true}
                            displayEmpty={true}
                            renderValue={() => group?.name ?
                                groups.find((g: GroupDto) => group.id === g.id)?.name
                                :
                                <InputLabel sx={{ color: "#C8C8C8" }}>{t(TranslationKeyEnum.selectGroup)}</InputLabel>
                            }
                        >
                            {
                                isGroupDataLoading ? <Box display="flex" justifyContent="center"><CircularProgress /></Box> : groups?.map((group: GroupDto) => <MenuItem value={group.id} key={group.id}>{group.name}</MenuItem>)
                            }
                        </StyledSelectField>
                    </FormControl>
                    {
                        isUserSupervisor
                        && <Button
                            variant="contained"
                            size="small"
                            startIcon={<AddIcon />}
                            sx={{ backgroundColor: LIGHT_BG_COLOR, textTransform: getTextTransformationAccordingToLanguage() }}
                            onClick={() => setOpenWorkOrderForm(true)}
                        >
                            {t(TranslationKeyEnum.newWorkOrder)}
                        </Button>
                    }
                </Box>
                {
                    isFilterDataLoading
                        ? <Box>
                            <Skeleton variant="rectangular" width="100%" height="8vh" sx={{ marginBottom: SPACING_EXTRA_SMALL }}></Skeleton>
                            <Skeleton variant="rectangular" width="100%" height="60vh"></Skeleton>
                        </Box>
                        :
                        <FullCalendar
                            ref={calendarRef}
                            key={renderKey}
                            plugins={[dayGridPlugin, interactionPlugin, bootstrap5Plugin]}
                            dayHeaderContent={renderWeekdayHeaderAccordingToLanguage}
                            titleFormat={renderMonthAndYearAccordingToLanguage}
                            themeSystem='bootstrap5'
                            initialView='dayGridWeek'
                            aspectRatio={1.35}
                            headerToolbar={
                                { start: 'prev,next,title', center: '', end: '' }
                            }
                            selectable={false}
                            selectMirror
                            dayMaxEvents
                            weekends
                            weekNumberCalculation='ISO'
                            initialEvents={events}
                            initialDate={selectedStartDate}
                            views={{
                                dayGridWeek: {
                                    type: 'dayGrid',
                                    duration: { days: numberOfDaysDisplayed }
                                }
                            }}
                            contentHeight="auto"
                            eventDidMount={(info) => {
                                // If a description exists add as second line to title
                                const a = info.el.getElementsByClassName('fc-event-title');
                                if (info.event.extendedProps.desccription != '' && typeof info.event.extendedProps.description !== 'undefined') {
                                    if (info.event.extendedProps.description.locationCode) {
                                        a[0].innerHTML =
                                            info.event.title
                                            + '<br>'
                                            + '<span>'
                                            + info.event.extendedProps.description.locationCode
                                            + '<br>'
                                            + info.event.extendedProps.description.typeOfWork
                                            + '</span>';
                                    }
                                }
                            }
                            }
                            eventClick={(props: EventClickArg) => {
                                let currentEvent = events.find(e => e.id === props.event._def.publicId);
                                if (currentEvent?.type === CalendarItemType.WorkOrder) {
                                    setWorkOrderId(props.event._def.publicId);
                                    setOpenWorkOrderForm(true);
                                }
                            }}
                            datesSet={(dates) => { setSelectedStartDate(dates.start); setSelectedEndDate(dates.end) }}
                        />
                }
            </Box>
            <WeekCalendarFilltersDialog
                open={open}
                setOpen={setOpen}
                filter={filter}
                setFilter={setFilter}
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
            />
            {
                openWorkOderForm && <CreateEditWorkOrder
                    isOpen={openWorkOderForm}
                    setIsOpen={setOpenWorkOrderForm}
                    isSupervisor={isUserSupervisor}
                    workOrderId={workOrderId}
                    rerender={rerender}
                    setRerender={setRerender}
                    handleCloseWorkOrderForm={() => setWorkOrderId("")}
                />
            }
        </>
    )
}