import React from 'react'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import OneDayTimeOff from '../OneDayTimeOffCard/OneDayTimeOffCard';
import MultipleDaysTimeOffCard from '../MultipleDaysTimeOffCard/MultipleDaysTimeOffCard';
import TimeOffRequests from '../RequestTimeOff/TimeOffRequests';
import { Box, Typography, Button } from '@mui/material'
import { BORDER_RADIUS_MEDIUM, CARD_BOX_SHADOW, INFO_CARD_MIN_WIDTH, INFO_CARD_WIDTH, INFO_CARD_WIDTH_SM, INFO_CARD_WIDTH_XS, LIGHT_BORDER, SPACING_SMALL } from '../../../utils/cssUtils';
import { getUserIdFromStorage } from '../../../utils/storageUtils/storageUtils';
import { useGetUsersApprovedRequests } from '../../../API/time-off-actions';
import { RequestDto } from '../../../API/time-off-service';
import { TranslationKeyEnum } from '../../../features/translations/TranslationKeyEnum';
import { useTranslation } from 'react-i18next';
import { getTextTransformationAccordingToLanguage } from '../../../features/translations/helpers';

const OPEN_MY_REQUESTS_URL_PARAM = "openMyRequests"
const NUMBER_OF_REQUESTS = 3;

export default function NextTimeOffCard() {
    const { t } = useTranslation();
    const getUsersApprovedRequests = useGetUsersApprovedRequests();

    const [userId, setUserId] = React.useState("");
    const [userTimeOffs, setUserTimeOffs] = React.useState<RequestDto[]>([]);
    const [isOpenMyRequests, setIsOpenMyRequests] = React.useState<boolean>(false);

    React.useEffect(() => {
        let id = getUserIdFromStorage();
        if (!id) return;
        setUserId(id!);
    }, [])

    React.useEffect(() => {
        let params = new URLSearchParams(window.location.search);
        let isOpen = params.get(OPEN_MY_REQUESTS_URL_PARAM);
        setIsOpenMyRequests(!!isOpen);
    }, [])

    const handleRelatedRequests = (requests: RequestDto[]) => {
        requests.map((request, index) => {
            if (request.relatedToRequestId) {
                requests[index - 1].endDate = requests[index].endDate;
                requests.splice(index, 1);
            }
        })
    }

    React.useEffect(() => {
        const fetchData = async () => {
            let requests = await getUsersApprovedRequests(NUMBER_OF_REQUESTS);
            if (!requests) return;
            handleRelatedRequests(requests);
            setUserTimeOffs(requests);
        }
        fetchData();
    }, [])

    return <Box width={{ xl: INFO_CARD_WIDTH, lg: INFO_CARD_WIDTH, md: INFO_CARD_WIDTH, sm: INFO_CARD_WIDTH_SM, xs: INFO_CARD_WIDTH_XS }} minWidth={INFO_CARD_MIN_WIDTH} margin="0 auto">
        <Box display="flex">
            <CalendarTodayIcon />
            <Typography marginLeft={SPACING_SMALL} variant="h6" marginTop="-.7%" fontWeight="bold">{t(TranslationKeyEnum.nextTimeOff)}</Typography>
        </Box>
        <Typography variant="body1" marginTop={SPACING_SMALL} marginBottom={SPACING_SMALL}>{t(TranslationKeyEnum.timeOffCardDescription)}</Typography>
        <Box boxShadow={CARD_BOX_SHADOW} borderColor="#dedede" borderRadius={BORDER_RADIUS_MEDIUM}>
            {
                userTimeOffs && userTimeOffs.map((timeOff: RequestDto) => {
                    return <Box padding={SPACING_SMALL} borderBottom={LIGHT_BORDER}>
                        {
                            ((timeOff.startDate?.getMonth() === timeOff.endDate?.getMonth()) && (timeOff.startDate?.getDate() === timeOff.endDate?.getDate())) ?
                                <OneDayTimeOff startDate={timeOff.startDate!} policyName={timeOff.policy?.name!} />
                                :
                                <MultipleDaysTimeOffCard userId={userId} startDate={timeOff.startDate!} endDate={timeOff.endDate!} policyName={timeOff.policy?.name!} />
                        }
                    </Box>
                })
            }
        </Box>
        <Button variant="outlined" size="small" sx={{ marginTop: SPACING_SMALL, textTransform: getTextTransformationAccordingToLanguage() }} onClick={() => setIsOpenMyRequests(true)}>{t(TranslationKeyEnum.viewAllTimeOffRequests)}</Button>
        {isOpenMyRequests && <TimeOffRequests isOpen={isOpenMyRequests} setIsOpen={setIsOpenMyRequests} isForUser={true} isJustForAdmin={false}/>}
    </Box>
}