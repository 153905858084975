import { useTryCatchJsonByAction } from "../utils/fetchUtils";
import { ResponseMessage } from "./identity-service";
import { PositionClient, PositionRequest, PositionResponse, UpdatePositionRequest } from "./user-management-service";

const positionClient = new PositionClient();

export function useGetPositions(): () => Promise<PositionResponse[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getPositions(): Promise<PositionResponse[]> {
        const bindedAction = getPositionsAction.bind(null);
        const getAllPositions = await tryCatchAction(bindedAction);
        return getAllPositions ? getAllPositions.response : [];
    }
    return getPositions;
}

const getPositionsAction = (): Promise<PositionResponse[]> => {
    return positionClient.getAll();
}

export function useGetDeactivatedPositionsAction(): () => Promise<PositionResponse[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getPositions(): Promise<PositionResponse[]> {
        const bindedAction = getDeactivatedPositionsAction.bind(null);
        const getDeactivatedPositions = await tryCatchAction(bindedAction);
        return getDeactivatedPositions ? getDeactivatedPositions.response : [];
    }
    return getPositions;
}

const getDeactivatedPositionsAction = (): Promise<PositionResponse[]> => {
    return positionClient.getDeactivated();
}

export function useCreatePosition(): (position: PositionRequest) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleCreatePosition(position: PositionRequest): Promise<boolean> {
        const bindedAction = createPositionAction.bind(null, position);
        const createPosition = await tryCatchAction(bindedAction);        
        return createPosition.success;
    }
    return handleCreatePosition;
}

const createPositionAction = (position: PositionRequest): Promise<ResponseMessage> => {
    return positionClient.insert(position);
}

export function useUpdatePosition(): (position: UpdatePositionRequest) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleUpdatePosition(position: UpdatePositionRequest): Promise<boolean> {
        const bindedAction = updatePositionAction.bind(null, position);
        const updatePosition = await tryCatchAction(bindedAction);        
        return updatePosition.success;
    }
    return handleUpdatePosition;
}

const updatePositionAction = (position: UpdatePositionRequest): Promise<ResponseMessage> => {
    return positionClient.update(position);
}

export function useDeletePosition(): (positionId: string) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function deletePosition(positionId: string): Promise<boolean> {
        const bindedAction = deletePositionAction.bind(null, positionId);
        let getDeleteResponse = await tryCatchAction(bindedAction);
        return getDeleteResponse.success;
    }
    return deletePosition;
}

const deletePositionAction = (positionId: string): Promise<ResponseMessage> => {
    return positionClient.remove(positionId);
}