import { FormikConfig, FormikValues } from "formik";
import { useUserAdditionalInfoSchemas } from "./userAdditionalInfoValidationSchema";
import { PositionDto } from "../../../API/user-management-service";

export function useAdditionalInfoFormikForm(): (handleSubmit: () => void) => FormikConfig<FormikValues> {
    const additionalInfoValidatioSchema = useUserAdditionalInfoSchemas();
    function additionalInfoFormikForm(handleSubmit: () => void): FormikConfig<FormikValues> {
        return {
            initialValues: {
                phone: "",
                address: "",
                office: "",
                positions: [new PositionDto({ name: "", startDate: new Date(), endDate: new Date() })],
                image: "",
                birthdate: new Date(),
                startWorkingDate: new Date(),
                sizeOfImage: "",
                formatOfImage: "",
            },
            validationSchema: additionalInfoValidatioSchema,
            onSubmit: handleSubmit
        };
    }
    return additionalInfoFormikForm;
}