import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import NoMeetingRoomIcon from '@mui/icons-material/NoMeetingRoom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Grid, Box, Typography, Button } from '@mui/material';
import { PRIMARY_BG_COLOR, SPACING_SMALL, CARD_BOX_SHADOW, LIGHT_BORDER, BORDER_RADIUS_MEDIUM, CARD_HEIGHT, CARD_HEADER_HEIGHT, MIN_CARD_HEIGHT, MIN_CARD_HEADER_HEIGHT, SPACING_EXTRA_SMALL, LIGHT_BG_COLOR, CARD_BODY_HEIGHT, MIN_CARD_BODY_HEIGHT, CARD_FOOTER_HEIGHT, MIN_CARD_FOOTER_HEIGHT } from '../../../utils/cssUtils';
import { useTranslation } from 'react-i18next';
import { TranslationKeyEnum } from '../../../features/translations/TranslationKeyEnum';
import { useGetNextReservations } from '../../../API/work-order-actions';
import { ReservationEventDto } from '../../../API/work-order-service';
import { getLanguageLocale } from '../../../features/translations/i18n';
import { useNavigate } from 'react-router-dom';
import { routeEnum } from '../../../routes/routeEnum';
import { getTextTransformationAccordingToLanguage } from '../../../features/translations/helpers';
import { Link } from 'react-router-dom';

const NUMBER_OF_MEETINGS = 2;
const MEETING_ROOMS_TAB_INDEX = 2;

export default function ScheduledMeetings() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const getNextMeetings = useGetNextReservations();

    const [nextMeetings, setNextMeetings] = React.useState<ReservationEventDto[]>([]);
    const [isMeetingsDataLoading, setIsMeetingsDataLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        const fetchData = async () => {
            setIsMeetingsDataLoading(true);
            let nextMeetings = await getNextMeetings(NUMBER_OF_MEETINGS);
            if (!nextMeetings) return;
            setNextMeetings(nextMeetings);
            setIsMeetingsDataLoading(false);
        };
        fetchData();
    }, []);

    const getWeekdayDateAndTimeRange = (meeting: ReservationEventDto): string => {
        let languageLocale = getLanguageLocale();
        let weekday = new Date(meeting.startTime!).toLocaleDateString(languageLocale, { weekday: 'long' });
        let weekdayFirstCharacterUppercase = weekday.charAt(0).toUpperCase() + weekday.slice(1);
        let day = new Date(meeting.startTime!).getDate();
        let month = new Date(meeting.startTime!).getMonth().toString().padStart(2, "0");
        let startTime = new Date(meeting.startTime!).getHours().toString().padStart(2, '0') + ":" + new Date(meeting.startTime!).getMinutes().toString().padStart(2, "0");
        let endTime = new Date(meeting.endTime!).getHours().toString().padStart(2, "0") + ":" + new Date(meeting.endTime!).getMinutes().toString().padStart(2, "0");
        return `${weekdayFirstCharacterUppercase} ${day}.${month}. ${startTime}-${endTime}`;
    }

    return (
        <Box sx={{ height: CARD_HEIGHT, minHeight: MIN_CARD_HEIGHT, borderRadius: BORDER_RADIUS_MEDIUM, boxShadow: CARD_BOX_SHADOW, backgroundColor: PRIMARY_BG_COLOR, marginBottom: SPACING_SMALL, borderBottom: LIGHT_BORDER }}>
            {/* Card Header */}
            <Box height={CARD_HEADER_HEIGHT} minHeight={MIN_CARD_HEADER_HEIGHT} display="flex" alignItems="center" justifyContent="center" borderBottom={LIGHT_BORDER}>
                <Typography sx={{ textTransform: "uppercase" }} variant="subtitle2">
                    {t(TranslationKeyEnum.scheduledMeetings)}
                </Typography>
            </Box>
            {/* Card Body */}
            <Grid container height={CARD_BODY_HEIGHT} minHeight={MIN_CARD_BODY_HEIGHT}>
                {
                    isMeetingsDataLoading
                        ? <CircularProgress sx={{ margin: "auto" }} />
                        : nextMeetings?.length
                            ? nextMeetings?.map((meeting, index) => {
                                return <Box
                                    width="100%"
                                    height="50%"
                                    padding={SPACING_EXTRA_SMALL}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    borderBottom={index === 0 ? LIGHT_BORDER : ""}
                                    color="grey"
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => navigate(`/${routeEnum.CALENDAR}/${MEETING_ROOMS_TAB_INDEX}/${meeting.id}/${meeting.roomId}`)}
                                >
                                    <Box width="86%">
                                        <Box display="flex">
                                            <Box color={LIGHT_BG_COLOR} maxWidth="50%" sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{meeting.title}</Box>
                                            &nbsp;∙&nbsp;
                                            <Typography width="50%" sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{meeting.organizer}</Typography>
                                        </Box>
                                        <Typography sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{getWeekdayDateAndTimeRange(meeting)}&nbsp;∙&nbsp;{meeting?.room?.name}</Typography>
                                    </Box>
                                    <ArrowForwardIcon sx={{ width: "14%" }} fontSize="medium" color="primary" />
                                </Box>
                            })
                            : <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" margin="auto" gap={SPACING_EXTRA_SMALL}>
                                <NoMeetingRoomIcon color="action" />
                                <Typography variant="body2" align="center" color="grey">{t(TranslationKeyEnum.noscheduledMeetings)}</Typography>
                            </Box>
                }
            </Grid>
            {/* Card Footer */}
            <Box width="100%" height={CARD_FOOTER_HEIGHT} minHeight={MIN_CARD_FOOTER_HEIGHT} display="flex" alignItems="center" justifyContent="center" borderTop={LIGHT_BORDER}>
                <Button variant="text" sx={{ textTransform: getTextTransformationAccordingToLanguage() }} size="small" component={Link} to={`/calendar/${MEETING_ROOMS_TAB_INDEX}`}>
                    {t(TranslationKeyEnum.viewCalendar)}
                    <Link to="/calendar" style={{ display: "flex", marginLeft: "1vh" }}>
                        <ArrowForwardIcon fontSize="small" color="primary" />
                    </Link>
                </Button>
            </Box>
        </Box>
    )
}