import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { TranslationKeyEnum } from "../../features/translations/TranslationKeyEnum";

const MINIMUM_PASSWORD_LENGTH = 6;
const MAXIMUM_PASSWORD_LENGTH = 12;

export function useEditPasswordFormValidationSchema() {
    const { t } = useTranslation();

    return Yup.object({
        currentPassword: Yup.string().required(t(TranslationKeyEnum.required))
            .min(MINIMUM_PASSWORD_LENGTH, t(TranslationKeyEnum.passwordMin, { minimumLength: MINIMUM_PASSWORD_LENGTH }))
            .max(MAXIMUM_PASSWORD_LENGTH, t(TranslationKeyEnum.passwordMax, { maximumLength: MAXIMUM_PASSWORD_LENGTH })),
        newPassword: Yup.string().required(t(TranslationKeyEnum.required))
            .min(MINIMUM_PASSWORD_LENGTH, t(TranslationKeyEnum.passwordMin, { minimumLength: MINIMUM_PASSWORD_LENGTH }))
            .max(MAXIMUM_PASSWORD_LENGTH, t(TranslationKeyEnum.passwordMax, { maximumLength: MAXIMUM_PASSWORD_LENGTH }))
            .notOneOf([Yup.ref('currentPassword'), null], t(TranslationKeyEnum.passwordMustntMatch)),
        newPasswordConfirmation: Yup.string().required(t(TranslationKeyEnum.required))
            .min(MINIMUM_PASSWORD_LENGTH, t(TranslationKeyEnum.passwordMin, { minimumLength: MINIMUM_PASSWORD_LENGTH }))
            .max(MAXIMUM_PASSWORD_LENGTH, t(TranslationKeyEnum.passwordMax, { maximumLength: MAXIMUM_PASSWORD_LENGTH }))
            .oneOf([Yup.ref('newPassword'), ""], t(TranslationKeyEnum.passwordMustMatch)),
    })
}