import { GridPaginationModel } from "@mui/x-data-grid";
import { Grid, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BankHolidayBasicDto, BankHolidayRequest, BankHolidayWithUsersDto } from "../../API/time-off-service";
import { srRS } from "../../features/translations/DataGrid/srRS";
import { LanguageEnum } from "../../features/translations/LanguageEnum";
import { TranslationKeyEnum } from "../../features/translations/TranslationKeyEnum";
import { IPolicyUserTableProps } from "../AdminPolicies/IPolicyUserTableProps";
import { useCreateBankHoliday, useGetBankHolidays, useRemoveBankHoliday, useUpdateBankHoliday } from "./actions";
import { SPACING_EXTRA_SMALL } from "../../utils/cssUtils";
import { ROWS_PER_PAGE_OPTIONS } from "../common/Constants/PaginationConstants";
import { getTextTransformationAccordingToLanguage } from '../../features/translations/helpers';
import { StyledDataGrid } from "../StyledDataGrid/StyledDataGrid";
import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Stack from '@mui/material/Stack';
import AccordionSkeleton from "../common/AccordionSkeleton/AccordionSkeleton";
import ConfirmDialog from "../Dialogs/ConfirmDialog/ConfirmDialog";
import AddEditBankHolidayForm from "./AddEditBankHolidayForm";
import Button from '@mui/material/Button';
import { toStringDDMMYYYYWithDotsFormat } from "../common/Calendar/helpers";

export default () => {
    const [bankHolidays, setBankHolidays] = React.useState<BankHolidayWithUsersDto[]>();
    const [tableData, setTableData] = React.useState<Map<string, IPolicyUserTableProps[]>>();
    const [page, setPage] = React.useState<number>(0);
    const [pageSize, setPageSize] = React.useState<number>(ROWS_PER_PAGE_OPTIONS[0]);
    const [isCopyBankHoliday, setIsCopyBankHoliday] = React.useState<boolean>(false);

    const handlePaginationModelChange = (model: GridPaginationModel) => {
        setPage(model.page);
        setPageSize(model.pageSize);
    }

    const { t, i18n } = useTranslation();

    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false);
    const [bankHolidayId, setBankHolidayId] = React.useState<string | undefined>();
    const [approveDelete, setApproveDelete] = React.useState(false);

    const [formOpen, setFormOpen] = React.useState(false);

    const getBankHolidays = useGetBankHolidays();
    const removeBankHoliday = useRemoveBankHoliday();
    const createBankHoliday = useCreateBankHoliday();
    const updateBankHoliday = useUpdateBankHoliday();

    const resetForm = async () => {
        setBankHolidays(await getBankHolidays());
        setBankHolidayId(undefined);
        setFormOpen(false);
        setIsCopyBankHoliday(false);
    }

    const handleCreateBankHoliday = async (request: BankHolidayRequest): Promise<boolean> => {
        if (await createBankHoliday(request)) {
            await resetForm();
            return true;
        }
        return false;
    }

    const handleUpdateBankHoliday = async (request: BankHolidayBasicDto): Promise<boolean> => {
        if (await updateBankHoliday(request)) {
            await resetForm();
            return true;
        }
        return false;
    }

    React.useEffect(() => {
        const removeBankHolidayAndFetchData = async () => {
            if (approveDelete && (await removeBankHoliday(bankHolidayId!))) {
                setBankHolidays(await getBankHolidays())
                setApproveDelete(false)
            } else if (approveDelete) {
                setApproveDelete(false)
            }
            setBankHolidayId(undefined)
        }
        removeBankHolidayAndFetchData();
    }, [approveDelete])

    React.useEffect(() => {
        const fetchData = async () => setBankHolidays(await getBankHolidays());
        fetchData();
    }, []);


    React.useEffect(() => {
        setTableData(mapTableUsers());
    }, [bankHolidays])


    const columns = [
        { field: 'id', headerName: 'Id', width: 130, minWidth: 100 },
        { field: 'firstName', headerName: t(TranslationKeyEnum.firstName), minWidth: 150, flex: 1 },
        { field: 'lastName', headerName: t(TranslationKeyEnum.lastName), minWidth: 150, flex: 1 },
    ];

    function mapTableUsers(): Map<any, any> {
        return new Map(bankHolidays?.map(i => [i.id, i.users?.map(user => {
            return {
                id: user?.id,
                firstName: user?.firstName,
                lastName: user?.lastName,
            } as IPolicyUserTableProps
        })]));
    }

    const handleCloseBankHolidayForm = () => {
        setFormOpen(false);
        // in copy bank holiday case we need to subtract one year because we add it while opening form
        if (isCopyBankHoliday) {
            let bankHoliday = bankHolidays?.find(bH => bH.id === bankHolidayId);
            bankHoliday!.startDate! = new Date(bankHoliday!.startDate!.setFullYear(bankHoliday!.startDate!.getFullYear() - 1));
            bankHoliday!.endDate! = new Date(bankHoliday!.endDate!.setFullYear(bankHoliday!.endDate!.getFullYear() - 1));
            setIsCopyBankHoliday(false);
        }
        setBankHolidayId(undefined);       
    }

    return (
        <Box>
            <Box padding={SPACING_EXTRA_SMALL} sx={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: 'space-between', gap: SPACING_EXTRA_SMALL }}>
                <Typography variant="h5">{t(TranslationKeyEnum.bankHolidays)}</Typography>
                <Tooltip title={t(TranslationKeyEnum.add)}>
                    <Button variant="contained" startIcon={<AddIcon />} sx={{ textTransform: getTextTransformationAccordingToLanguage() }} onClick={() => setFormOpen(true)}>
                        {t(TranslationKeyEnum.addBankHoliday)}
                    </Button>
                </Tooltip>
            </Box>
            {
                bankHolidays ? bankHolidays!.map((holiday: BankHolidayWithUsersDto) =>
                    <Accordion sx={{ background: 'none' }} key={holiday.id}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ pointerEvents: "auto" }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{ pointerEvents: "none" }}
                        >
                            <Grid container>
                                <Grid item sm={2} md={10}>
                                    <Box display="flex">
                                        <Typography>{holiday.name}</Typography>
                                        &nbsp;∙&nbsp;
                                        <Typography>{toStringDDMMYYYYWithDotsFormat(holiday.startDate!)}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={2} md={2}>
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        spacing={0.5}
                                    >
                                        <Tooltip title={t(TranslationKeyEnum.copyBankHoliday)}>
                                            <IconButton>
                                                <ContentCopyIcon
                                                    sx={{ pointerEvents: "auto" }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setBankHolidayId(holiday.id);
                                                        setFormOpen(true);
                                                        setIsCopyBankHoliday(true);
                                                    }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={t(TranslationKeyEnum.edit)}>
                                            <IconButton>
                                                <EditIcon
                                                    sx={{ pointerEvents: "auto" }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setBankHolidayId(holiday.id);
                                                        setFormOpen(true);
                                                    }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={t(TranslationKeyEnum.delete)}>
                                            <IconButton>
                                                <DeleteIcon
                                                    sx={{ pointerEvents: "auto" }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setBankHolidayId(holiday.id);
                                                        setDeleteDialogOpen(true)
                                                    }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <StyledDataGrid
                                columnVisibilityModel={{
                                    id: false,
                                }}
                                localeText={i18n.language === LanguageEnum.Serbian ? srRS.components.MuiDataGrid.defaultProps.localeText : undefined}
                                autoHeight={true}
                                paginationModel={{ page, pageSize }}
                                onPaginationModelChange={handlePaginationModelChange}
                                pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
                                pagination
                                hideFooterSelectedRowCount={true}
                                columns={columns}
                                rows={tableData?.get(holiday.id!) ?? []}
                                disableRowSelectionOnClick
                            />
                        </AccordionDetails>
                    </Accordion>) : <AccordionSkeleton title={t(TranslationKeyEnum.bankHolidaysLoading)} />
            }
            <AddEditBankHolidayForm
                close={handleCloseBankHolidayForm}
                bankHoliday={bankHolidays?.find(g => g.id === bankHolidayId)}
                open={formOpen}
                submitCreate={handleCreateBankHoliday}
                submitUpdate={handleUpdateBankHoliday}
                isCopyBankHoliday={isCopyBankHoliday}
            />
            {
                deleteDialogOpen &&
                <ConfirmDialog
                    dialogOpen={deleteDialogOpen}
                    setDialogOpen={setDeleteDialogOpen}
                    title={t(TranslationKeyEnum.confirmDelete)}
                    description={t(TranslationKeyEnum.deleteBankHolidayDescription)}
                    isActionConfirmed={setApproveDelete}
                />
            }
        </Box>
    )
}