import { FormikConfig, FormikValues } from "formik";
import { GroupDto } from "../../API/time-off-service";
import { useAddEditGroupFormValidationSchema } from "./addEditGroupFormValidationSchema";

export function useAddEditGroupFormikForm(): (group: GroupDto, handleSubmit: () => void) => FormikConfig<FormikValues> {
    const addEditGroupFormValidationSchema = useAddEditGroupFormValidationSchema();
    function addEditGroupFormikForm(group: GroupDto, handleSubmit: () => void): FormikConfig<FormikValues> {
        return {
            initialValues: {
                id: group.id,
                name: group.name,
                mustBePresent: group.mustBePresent,
                membersCount: group.members ? group.members?.length : 0,
            },
            validationSchema: addEditGroupFormValidationSchema,
            onSubmit: handleSubmit
        };
    }
    return addEditGroupFormikForm;
}