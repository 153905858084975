import { red, pink, purple, deepPurple, indigo, blue, lightBlue, cyan, teal, green, lightGreen, lime, yellow, amber, orange, deepOrange, brown, grey, blueGrey } from '@mui/material/colors';

export const ERROR_MESSAGE = "#d32f2f";

const DEFAULT_COLOR_SHADE = 400;
const COLOR_SHADE = 500;
const LIGHT_COLOR_SHADE = 700;

export const avatarBgColors = [grey[DEFAULT_COLOR_SHADE], red[COLOR_SHADE], pink[COLOR_SHADE], purple[COLOR_SHADE], deepPurple[COLOR_SHADE], indigo[COLOR_SHADE], blue[COLOR_SHADE], lightBlue[COLOR_SHADE], cyan[COLOR_SHADE],
teal[COLOR_SHADE], green[COLOR_SHADE], lightGreen[COLOR_SHADE], lime[LIGHT_COLOR_SHADE], amber[COLOR_SHADE], orange[COLOR_SHADE], deepOrange[COLOR_SHADE], brown[COLOR_SHADE], grey[COLOR_SHADE], blueGrey[COLOR_SHADE],
yellow[LIGHT_COLOR_SHADE]];