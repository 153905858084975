import { RequestClient, RequestWithUserImagesDto } from "../../../API/time-off-service";
import { useTryCatchJsonByAction } from "../../../utils/fetchUtils";

const requestClient = new RequestClient();
const getRequests = (): Promise<RequestWithUserImagesDto[]> => requestClient.getForNextSevenDays();

export function useGetAllRequests(): () => Promise<RequestWithUserImagesDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();

    async function getAllRequests(): Promise<RequestWithUserImagesDto[]> {
        const bindedGetAllRequestsAction = getRequests.bind(null);
        const requestModel = await tryCatchAction(bindedGetAllRequestsAction);

        return requestModel.response as RequestWithUserImagesDto[];
    }

    return getAllRequests;
}