import HomeIcon from '@mui/icons-material/Home';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ForumIcon from '@mui/icons-material/Forum';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import { Box, Typography, Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getNavbarMenuItemBackground } from '../helpers';
import { SPACING_EXTRA_SMALL, SPACING_LARGE, SPACING_MEDIUM, SPACING_SMALL, TEXT_SECONDARY } from '../../../utils/cssUtils';
import { routeEnum } from '../../../routes/routeEnum';
import { TranslationKeyEnum } from '../../../features/translations/TranslationKeyEnum';
import { INavbarItems } from './INavbarItems';
import { useAtom } from 'jotai';
import { userAtom } from '../../AppContainer/AppContainer';
import { getTextTransformationAccordingToLanguage } from '../../../features/translations/helpers';

export default function NavbarItems({ currentTab, setCurrentTab, hasApproverPermission, hasWorkOrdersPermission, setIsHamburgerMenuOpen, isUserMenuOpen, setIsUserMenuOpen, setIsLanguageMenuOpen, isMobile }: INavbarItems) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [user] = useAtom(userAtom);
    const urlString = window.location.pathname.split('/');
    const PAGE_NAME_INDEX = 1;
    setCurrentTab(urlString[PAGE_NAME_INDEX]);

    return (
        <>
            {/* YOU */}
            <Box marginTop={SPACING_MEDIUM} marginLeft={SPACING_SMALL}>
                <Typography variant="subtitle2" marginBottom={SPACING_SMALL} sx={{ pointerEvents: "none", color: TEXT_SECONDARY }} fontWeight="bold">
                    {t(TranslationKeyEnum.you).toUpperCase()}
                </Typography>
                {/* Dashboard */}
                <Box display="flex" marginBottom={SPACING_SMALL} sx={{ cursor: "pointer" }} onClick={() => { setCurrentTab(routeEnum.HOME); navigate(routeEnum.HOME); setIsHamburgerMenuOpen && setIsHamburgerMenuOpen(false); }}>
                    <HomeIcon sx={{ marginRight: SPACING_EXTRA_SMALL }} color={getNavbarMenuItemBackground(currentTab, routeEnum.HOME)} />
                    <Typography color={getNavbarMenuItemBackground(currentTab, routeEnum.HOME)} variant="body1">{t(TranslationKeyEnum.dashboard)}</Typography>
                </Box>
                {/* Profile */}
                <Box display="flex" marginBottom={SPACING_SMALL} sx={{ cursor: "pointer" }} onClick={() => { setCurrentTab(routeEnum.PROFILE); navigate(routeEnum.PROFILE); setIsHamburgerMenuOpen && setIsHamburgerMenuOpen(false); }}>
                    <PersonIcon sx={{ marginRight: SPACING_EXTRA_SMALL }} color={getNavbarMenuItemBackground(currentTab, routeEnum.PROFILE)} />
                    <Typography color={getNavbarMenuItemBackground(currentTab, routeEnum.PROFILE)} variant="body1">{t(TranslationKeyEnum.profile)}</Typography>
                </Box>
                {/* Time Off */}
                <Box display="flex" marginBottom={SPACING_EXTRA_SMALL} sx={{ cursor: "pointer" }} onClick={() => { setCurrentTab(routeEnum.TIMEOFF); navigate(routeEnum.TIMEOFF); setIsHamburgerMenuOpen && setIsHamburgerMenuOpen(false); }}>
                    <BeachAccessIcon sx={{ marginRight: SPACING_EXTRA_SMALL }} color={getNavbarMenuItemBackground(currentTab, routeEnum.TIMEOFF)} />
                    <Typography variant="body1" color={getNavbarMenuItemBackground(currentTab, routeEnum.TIMEOFF)} textTransform={getTextTransformationAccordingToLanguage()}>
                        {t(TranslationKeyEnum.timeOffTab)}
                    </Typography>
                </Box>
                {/* Work Orders */}
                <Box display="flex" marginBottom={SPACING_EXTRA_SMALL} sx={{ cursor: "pointer" }} onClick={() => { setCurrentTab(routeEnum.WORK_ORDERS); navigate(routeEnum.WORK_ORDERS); setIsHamburgerMenuOpen && setIsHamburgerMenuOpen(false); }}>
                    <ContentPasteGoIcon sx={{ marginRight: SPACING_EXTRA_SMALL }} color={getNavbarMenuItemBackground(currentTab, routeEnum.WORK_ORDERS)} />
                    <Typography variant="body1" color={getNavbarMenuItemBackground(currentTab, routeEnum.WORK_ORDERS)} textTransform={getTextTransformationAccordingToLanguage()}>
                        {t(TranslationKeyEnum.workOrdersTab)}
                    </Typography>
                </Box>
            </Box>
            {/* COMPANY */}
            <Box marginTop="10%" marginLeft={SPACING_SMALL}>
                <Typography variant="subtitle2" marginBottom={SPACING_SMALL} sx={{ pointerEvents: "none", color: TEXT_SECONDARY }} fontWeight="bold">
                    {t(TranslationKeyEnum.company).toUpperCase()}
                </Typography>
                {/* Employees */}
                <Box data-test-id="employees" display="flex" marginBottom={SPACING_SMALL} sx={{ cursor: "pointer" }} onClick={() => { setCurrentTab(routeEnum.EMPLOYEES); navigate(routeEnum.EMPLOYEES); setIsHamburgerMenuOpen && setIsHamburgerMenuOpen(false); }}>
                    <SupervisorAccountIcon sx={{ marginRight: SPACING_EXTRA_SMALL }} color={getNavbarMenuItemBackground(currentTab, routeEnum.EMPLOYEES)} />
                    <Typography variant="body1" color={getNavbarMenuItemBackground(currentTab, routeEnum.EMPLOYEES)}>{t(TranslationKeyEnum.employees)}</Typography>
                </Box>
                {/* Calendar */}
                {
                    // we are hiding calendar for mobile devices
                    !isMobile &&
                    <Box display="flex" marginBottom={SPACING_SMALL} sx={{ cursor: "pointer" }} onClick={() => { setCurrentTab(routeEnum.CALENDAR); navigate(routeEnum.CALENDAR); setIsHamburgerMenuOpen && setIsHamburgerMenuOpen(false); }}>
                        <CalendarTodayIcon sx={{ marginRight: SPACING_EXTRA_SMALL }} color={getNavbarMenuItemBackground(currentTab, routeEnum.CALENDAR)} />
                        <Typography variant="body1" color={getNavbarMenuItemBackground(currentTab, routeEnum.CALENDAR)}>{t(TranslationKeyEnum.calendar)}</Typography>
                    </Box>
                }
                {/* Pending requests */}
                {
                    hasApproverPermission
                    && <Box display="flex" marginBottom={SPACING_SMALL} sx={{ cursor: "pointer" }} onClick={() => { setCurrentTab(routeEnum.PENDINGREQUESTS); navigate(routeEnum.PENDINGREQUESTS); setIsHamburgerMenuOpen && setIsHamburgerMenuOpen(false); }}>
                        <ForumIcon sx={{ marginRight: SPACING_EXTRA_SMALL }} color={getNavbarMenuItemBackground(currentTab, routeEnum.PENDINGREQUESTS)} />
                        <Typography variant="body1" color={getNavbarMenuItemBackground(currentTab, routeEnum.PENDINGREQUESTS)} textTransform={getTextTransformationAccordingToLanguage()}>
                            {t(TranslationKeyEnum.pendingRequests)}
                        </Typography>
                    </Box>
                }
                {/* Manage Work Orders */}
                {
                    hasWorkOrdersPermission
                    && <Box display="flex" marginBottom={SPACING_SMALL} sx={{ cursor: "pointer" }} onClick={() => { setCurrentTab(routeEnum.MANAGE_WORK_ORDERS); navigate(routeEnum.MANAGE_WORK_ORDERS); setIsHamburgerMenuOpen && setIsHamburgerMenuOpen(false); }}>
                        <ContentPasteGoIcon sx={{ marginRight: SPACING_EXTRA_SMALL }} color={getNavbarMenuItemBackground(currentTab, routeEnum.MANAGE_WORK_ORDERS)} />
                        <Typography variant="body1" color={getNavbarMenuItemBackground(currentTab, routeEnum.MANAGE_WORK_ORDERS)}>{t(TranslationKeyEnum.manageWorkOrders)}</Typography>
                    </Box>
                }
                {/* Meeting rooms */}
                {
                    <Box display="flex" marginBottom={SPACING_EXTRA_SMALL} sx={{ cursor: "pointer" }} onClick={() => { setCurrentTab(routeEnum.MEETING_ROOMS); navigate(isMobile ? routeEnum.MEETING_ROOMS_MOBILE : routeEnum.MEETING_ROOMS); setIsHamburgerMenuOpen && setIsHamburgerMenuOpen(false); }}>
                        <MeetingRoomIcon sx={{ marginRight: SPACING_EXTRA_SMALL }} color={getNavbarMenuItemBackground(currentTab, routeEnum.MEETING_ROOMS)} />
                        <Typography variant="body1" color={getNavbarMenuItemBackground(currentTab, routeEnum.MEETING_ROOMS)} textTransform={getTextTransformationAccordingToLanguage()}>
                            {t(TranslationKeyEnum.meetingRooms)}
                        </Typography>
                    </Box>
                }
            </Box>
            {/* User Info and User Navigation */}
            <Box display="flex" marginTop={SPACING_LARGE} marginLeft={SPACING_EXTRA_SMALL} sx={{ cursor: "pointer" }} onClick={() => { setIsUserMenuOpen(!isUserMenuOpen); setIsLanguageMenuOpen(false); }}>
                <Avatar variant="rounded" sx={{ width: "32px", height: "32px", marginRight: SPACING_SMALL }} alt="user avatar" src={user.image && `data:image/png;base64,${user?.image}`} />
                <Box display="flex" margin="auto 0">
                    <Typography variant="body1" marginRight={SPACING_MEDIUM}>{user.firstName} {user.lastName}</Typography>
                    <ExpandMoreIcon color="action" data-test-id="expandMoreIcon" />
                </Box>
            </Box>
        </>
    )
}