import MenuIcon from '@mui/icons-material/Menu';
import companyLogo from "../../../assets/images/ant.png"
import { Box, Typography, Avatar } from '@mui/material';
import { SPACING_EXTRA_LARGE, SPACING_EXTRA_SMALL, SPACING_SMALL } from '../../../utils/cssUtils';
import { routeEnum } from '../../../routes/routeEnum';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TranslationKeyEnum } from '../../../features/translations/TranslationKeyEnum';
import { INavbarHeader } from './INavbarHeader';


export default function NavbarHeader({ isHamburgerMenuOpen, setIsHamburgerMenuOpen, setCurrentTab, isMobile }: INavbarHeader) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Box
            display="flex"
            marginLeft={isMobile ? SPACING_EXTRA_SMALL : ""}
            paddingTop={isMobile ? SPACING_SMALL : ""}
            onClick={() => { setCurrentTab(routeEnum.HOME); navigate(routeEnum.HOME) }}
        >
            <Box display="flex">
                <Avatar sx={{ marginRight: SPACING_SMALL, marginTop: "auto", marginBottom: "auto" }} src={companyLogo} />
                <Typography variant="h6" margin="auto 0">{t(TranslationKeyEnum.companyName)}</Typography>
            </Box>
            <Box marginLeft="auto">
                {
                    !isMobile
                    && <MenuIcon
                        fontSize="large"
                        sx={{
                            display: { xl: "none", lg: "none", md: "none", sm: "flex", xs: "flex" },
                            height: SPACING_EXTRA_LARGE
                        }}
                        onClick={(event) => { setIsHamburgerMenuOpen(!isHamburgerMenuOpen); event.stopPropagation(); }} />
                }
            </Box>
        </Box>
    )
}
