import { IUserProps } from "../components/common/interfaces/IUserProps";
import { getLanguage } from "../features/translations/i18n";
import { BaseResponseModel, useTryCatchJsonByAction } from "../utils/fetchUtils";
import { AssignPoliciesToUserRequest, CreateUserRequest, CreateUserResponse, PolicyClient, PolicyDto, ResponseMessage, StatusEnum, UserClient, UserDto } from "./identity-service";

interface PermissionValues {
	[key: string]: string;
}

const userClient = new UserClient();
const policyClient = new PolicyClient();

export function useGetPermissions():() => Promise<PolicyDto[]>{
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleGetPermissions(): Promise<PolicyDto[]>{
        const bindedAction = getPermissionsAction.bind(null);
        const getPermissionsResponse = await tryCatchAction(bindedAction);
        return getPermissionsResponse ? getPermissionsResponse.response : [];
    }
    return handleGetPermissions;
}

const getPermissionsAction = (): Promise<PolicyDto[]> => {
    return policyClient.getAll();
}

export function useCreateEmployee(): (mainInfo: CreateUserRequest) => Promise<IUserProps> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function createEmployee(mainInfo: CreateUserRequest): Promise<IUserProps> {
        const bindedAction = createEmployeeAction.bind(null, mainInfo);
        let createEmployeeResponse = await tryCatchAction(bindedAction);
        return createEmployeeResponse ? createEmployeeResponse.response : {};
    }
    return createEmployee;
}

const createEmployeeAction = (mainInfo: CreateUserRequest): Promise<CreateUserResponse> => {
    return userClient.createUser(mainInfo);
}

export function useGetIdentityUserForActivation(): (id: string) => Promise<UserDto> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getUserForActivation(id: string): Promise<UserDto> {
        const bindedAction = getIdentityUserForActivationAction.bind(null, id);
        let getActivationUser = await tryCatchAction(bindedAction);
        return getActivationUser ? getActivationUser.response : {};
    }
    return getUserForActivation;
}

const getIdentityUserForActivationAction = (id: string): Promise<UserDto> => userClient.getUserForActivation(id);

export function useGetIdentityUser(): (id: string) => Promise<UserDto> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function createEmployee(id: string): Promise<UserDto> {
        const bindedAction = getUserAction.bind(null, id);
        let getUser = await tryCatchAction(bindedAction);
        return getUser ? getUser.response : {};
    }
    return createEmployee;
}

const getUserAction = (id: string): Promise<UserDto> => {
    return userClient.getById(id);
}

export function getPermissionsIds(selectedPermissions: string[], permissions: PolicyDto[]): string[] {
    let lang = getLanguage();
    let permissionDictionary: PermissionValues = Object.assign({}, ...permissions.map((p: PolicyDto) => ({[JSON.parse(p.title!)[lang]]: p.id})));
    return selectedPermissions.map((p: string) => permissionDictionary[p]);
}

export function useUpdateUserIdentity(): (id: string, status: StatusEnum, permissionsIds: string[]) => Promise<BaseResponseModel>{
    const tryCatchAction = useTryCatchJsonByAction(false);
    async function handleUpdateUserIdentity(id: string, status: StatusEnum, permissionsIds: string[]): Promise<BaseResponseModel>{
        const bindedAction = updateUserIdentity.bind(null, id, status, permissionsIds);
        const updateUserIdentityResponse = await tryCatchAction(bindedAction);
        return {success: updateUserIdentityResponse.success, errors: updateUserIdentityResponse.errors}; 
    }
    return handleUpdateUserIdentity;
}

const updateUserIdentity = (id: string, status: StatusEnum, permissionsIds: string[]): Promise<ResponseMessage> => {
    let request = new AssignPoliciesToUserRequest({
        userId: id,
        policyIds: permissionsIds,
    });
    return userClient.update(id, status, request);
} 

export function useUpdateIdentityUser():(userId: string, permissionsIds: string[], userStatus: StatusEnum) => Promise<boolean>{
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleUpdateIdetityUser(userId: string, permissionsIds: string[], userStatus: StatusEnum): Promise<boolean>{
        const bindedAction = updateIdentityUser.bind(null, userId, permissionsIds, userStatus);
        const updatePermissionsResponse = await tryCatchAction(bindedAction);
        return updatePermissionsResponse.success;
    }
    return handleUpdateIdetityUser;
}

const updateIdentityUser = (userId: string, permissionsIds: string[], userStatus: StatusEnum): Promise<ResponseMessage> => {
    let request = new AssignPoliciesToUserRequest({
        userId: userId,
        policyIds: permissionsIds,
    });
    return policyClient.assignPoliciesToUser(request);
}

export function useGetUserPermissions(): () => Promise<string[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getUserPermissions(): Promise<string[]> {
        const bindedAction = getUserPermissionsAction.bind(null);
        const getUserPermissions = await tryCatchAction(bindedAction);
        return getUserPermissions.response;
    }
    return getUserPermissions;
}

const getUserPermissionsAction = (): Promise<string[]> => {
    return userClient.getUserPermissions();
}