import "../../features/translations/i18n";
import { Grid, Box, Typography } from '@mui/material';
import { GridColDef } from "@mui/x-data-grid";
import { TranslationKeyEnum } from "../../features/translations/TranslationKeyEnum";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { BORDER_PRIMARY_COLOR, BORDER_RADIUS_MEDIUM, LINE_WIDTH, PAGE_SHADOW, PRIMARY_BG_COLOR, SPACING_EXTRA_SMALL, SPACING_SMALL } from "../../utils/cssUtils";
import { useNavigate, useParams } from "react-router";
import { RequestTableVM, useGetRequest, useGetRequests } from "./action";
import { routeEnum } from "../../routes/routeEnum";
import { StyledDataGrid } from "../../components/StyledDataGrid/StyledDataGrid";
import ForumIcon from '@mui/icons-material/Forum';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import ResolveTimeOffRequestDialog from "../TimeOff/ResolveTimeOffRequestDialog/ResolveTimeOffRequestDialog";
import { RequestStatusEnum } from "../../API/time-off-service";
import React from "react";
import { getUserIdFromStorage } from "../../utils/storageUtils/storageUtils";
import { getTextTransformationAccordingToLanguage } from '../../features/translations/helpers';
import TimeOffRequests from "../TimeOff/RequestTimeOff/TimeOffRequests";
import TimeOffMonthlySummary from "../TimeOff/TimeOffMonthlySummary/TimeOffMonthlySummary";

export default function PendingRequests() {
    const [approverId, setApproverId] = React.useState("");
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const getRequests = useGetRequests();
    const getRequest = useGetRequest();

    const [requestsLoading, setRequestsLoading] = useState<boolean>(true);
    const [requests, setRequests] = useState<RequestTableVM[]>([]);
    const [isRequestResolved, setIsRequestResolved] = useState<boolean>(false);
    const [isViewAllRequestsOpen, setIsViewAllRequestsOpen] = React.useState<boolean>(false);
    const [isTimeOffMonthlySummaryOpen, setIsTimeOffMonthlySummaryOpen] = React.useState<boolean>(false);

    useEffect(() => {
        const fetchRequest = async () => {
            let request = await getRequest(params.id!);
            setApproverId(request.user?.approverId!)
        }

        params.id && fetchRequest();
    }, [params.id])

    useEffect(() => {
        const fetchData = async () => {
            await refreshRequestsForApprover()
        }
        fetchData();
    }, [isRequestResolved])

    const refreshRequestsForApprover = async () => {
        setRequestsLoading(true);
        setRequests(await getRequests());
        setRequestsLoading(false);
    }

    const columns: GridColDef[] = [
        {
            field: 'User', headerName: t(TranslationKeyEnum.userLabel), description: t(TranslationKeyEnum.userLabel), flex: 1, disableColumnMenu: true, sortable: false, renderCell: (cellValues) => {
                return (
                    <Box sx={{ display: 'flex', alignItems: "center", flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Avatar variant="square" sx={{ width: 30, height: 30 }} src={cellValues.row.image && `data:image/png;base64,${cellValues.row.image}`} />
                        <Typography paddingLeft={2} variant="body2" textAlign="center">{cellValues.row.firstName} {cellValues.row.lastName}</Typography>
                    </Box>
                );
            }
        },
        {
            field: 'Days Requested', headerName: t(TranslationKeyEnum.daysRequested), description: t(TranslationKeyEnum.daysRequested), flex: 1, disableColumnMenu: true, sortable: false, renderCell: (cellValues) => {
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Typography variant="body2" textAlign="center" paddingRight={2}>{cellValues.row.startDate.toLocaleDateString('en-GB')}</Typography>
                        <ArrowForwardIcon fontSize="small"></ArrowForwardIcon>
                        <Typography variant="body2" textAlign="left" paddingLeft={2}>{cellValues.row.endDate.toLocaleDateString('en-GB')}</Typography>
                        <Typography variant="body2" textAlign="center" paddingLeft={1}>({cellValues.row.numberOfDays} {t(TranslationKeyEnum.workDays)})</Typography>
                    </Box>
                );
            }
        },
        {
            field: 'Button', headerName: "", align: "right", description: "", flex: 1, disableColumnMenu: true, sortable: false, renderCell: (cellValues) => {
                return (
                    <Button color={cellValues.row.status === RequestStatusEnum.Pending ? "primary" : "error"} variant="outlined" size="small" sx={{ textTransform: "capitalize" }} onClick={() => navigate(`/${routeEnum.PENDINGREQUESTS}/${cellValues.row.relatedId ? cellValues.row.relatedId : cellValues.row.id}`)}>
                        {t(cellValues.row.status === RequestStatusEnum.Pending ? TranslationKeyEnum.viewRequest : TranslationKeyEnum.viewCancellationRequest)}
                    </Button>
                );
            }
        }
    ];

    return <Grid container>
        <Grid container height="90vh">
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={SPACING_EXTRA_SMALL}>
                    <ForumIcon fontSize="large" color="primary" />
                    <Typography variant="h5" textAlign="center">{t(TranslationKeyEnum.pendingRequestsTimeOff)} ({requests.length})</Typography>
                    <Typography variant="body2" textAlign="center">{t(TranslationKeyEnum.pendingRequestsPageDescription)}</Typography>
                    <Button variant="outlined" size="small" sx={{ textTransform: getTextTransformationAccordingToLanguage() }} onClick={() => setIsViewAllRequestsOpen(true)}>
                        {t(TranslationKeyEnum.viewAllTimeOffRequests)}
                    </Button>
                    <Button variant="outlined" size="small" sx={{ textTransform: getTextTransformationAccordingToLanguage() }} onClick={() => setIsTimeOffMonthlySummaryOpen(true)}>
                        {t(TranslationKeyEnum.viewTimeOffMonthlySummary)}
                    </Button>
                    <Box width={LINE_WIDTH} border={BORDER_PRIMARY_COLOR} />
                </Box>
            </Grid>
            <Grid item marginTop={SPACING_SMALL} height={{ xl: "50vh", lg: "46vh", md: "44vh", sm: "42vh", xs: "40vh" }} xl={12} lg={12} md={12} sm={12} xs={12} sx={{ backgroundColor: PRIMARY_BG_COLOR }} borderRadius={BORDER_RADIUS_MEDIUM}>
                <Box height="100%">
                    <StyledDataGrid
                        sx={{ border: 0, boxShadow: PAGE_SHADOW }}
                        rows={requests}
                        columns={columns}
                        loading={requestsLoading}
                        disableRowSelectionOnClick
                        hideFooter={true}
                    />
                </Box>
            </Grid>
        </Grid>
        {
            params.id && approverId === getUserIdFromStorage() && <ResolveTimeOffRequestDialog id={params.id} isRequestResolved={isRequestResolved} setIsRequestResolved={setIsRequestResolved} />
        }
        {
            isViewAllRequestsOpen && <TimeOffRequests isOpen={isViewAllRequestsOpen} setIsOpen={setIsViewAllRequestsOpen} isForUser={false} userId={getUserIdFromStorage()!} isJustForAdmin={true} />
        }
        {
            isTimeOffMonthlySummaryOpen && <TimeOffMonthlySummary isOpen={isTimeOffMonthlySummaryOpen} setIsOpen={setIsTimeOffMonthlySummaryOpen} />
        }
    </Grid>
}