import { useTranslation } from 'react-i18next';
import { defaultTheme } from '../../../styles/themes';
import { Button, Dialog, DialogActions, DialogTitle, ThemeProvider, DialogContent, DialogContentText } from '@mui/material';
import { IAlertDialogProps } from './IAlertDialogProps'
import { TranslationKeyEnum } from '../../../features/translations/TranslationKeyEnum';

export default function AlertDialog({ dialogOpen, setDialogOpen, title, description = ""}: IAlertDialogProps) {
    const { t } = useTranslation();

    return (
    <ThemeProvider theme={defaultTheme()}>
            <Dialog open={dialogOpen} >
                <DialogTitle>{title}</DialogTitle>
                {
                    description && <DialogContent>
                        <DialogContentText>{description}</DialogContentText>
                    </DialogContent>
                }
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)} autoFocus>{t(TranslationKeyEnum.ok)}</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
  )
}