import { BaseResponseModel, useTryCatchJsonByAction } from "../utils/fetchUtils";
import { StatusEnum } from "./time-off-service";
import { BasicUpdateUserDto, BasicWorkOrderDto, CalendarUserDto, CalendarWorkOrderDto, CreateReservationRequest, CreateRoomRequest, CreateTypeOfWorkRequest, CreateWorkOrderRequest, MonthlyUserSummaryDto, MonthlyUserSummaryFilter, ReservationClient, ReservationEventDto, ResponseMessage, RoomClient, RoomDto, SingleUserMonthlySummaryDto, TypeOfWorkClient, TypeOfWorkDto, UpdateReservationRequest, UpdateStatusRoomRequest, UpdateStatusTypeOfWorkRequest, UpdateWorkOrderRequest, UserClient, UserDto, UserWithReservedDateRangesDto, WorkOrderClient, WorkOrderDto } from "./work-order-service";

const workOrderClient = new WorkOrderClient();
const typesOfWorkClient = new TypeOfWorkClient();
const userClient = new UserClient();
const roomClient = new RoomClient();
const reservationClient = new ReservationClient();

export function useGetNextWorkOrders(): (userId: string, numberOfWorkOrders: number) => Promise<BasicWorkOrderDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getNextWorkOrders(userId: string, numberOfWorkOrders: number): Promise<BasicWorkOrderDto[]> {
        const bindedAction = getNextWorkOrdersAction.bind(null, userId, numberOfWorkOrders);
        const getNextWorkOrdersResponse = await tryCatchAction(bindedAction);
        return getNextWorkOrdersResponse.success ? getNextWorkOrdersResponse.response : [];
    }
    return getNextWorkOrders;
}

const getNextWorkOrdersAction = (userId: string, numberOfWorkOrders: number): Promise<BasicWorkOrderDto[]> => workOrderClient.getNextWorkOrders(userId, numberOfWorkOrders);

export function useGetMonthlySummary(): (filter: MonthlyUserSummaryFilter) => Promise<MonthlyUserSummaryDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getMonthlySummary(filter: MonthlyUserSummaryFilter): Promise<BasicWorkOrderDto[]> {
        const bindedAction = getMonthlySummaryAction.bind(null, filter);
        const getMonthlySummaryResponse = await tryCatchAction(bindedAction);
        return getMonthlySummaryResponse.success ? getMonthlySummaryResponse.response : [];
    }
    return getMonthlySummary;
}

const getMonthlySummaryAction = (filter: MonthlyUserSummaryFilter): Promise<MonthlyUserSummaryDto[]> => userClient.getMonthlySummary(filter);

export function useGetMonthlySummaryForMe(): (filter: MonthlyUserSummaryFilter) => Promise<SingleUserMonthlySummaryDto> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getMonthlySummaryForMe(filter: MonthlyUserSummaryFilter): Promise<SingleUserMonthlySummaryDto> {
        const bindedAction = getMonthlySummaryForMeAction.bind(null, filter);
        const getMonthlySummaryResponse = await tryCatchAction(bindedAction);
        return getMonthlySummaryResponse.success ? getMonthlySummaryResponse.response : [];
    }
    return getMonthlySummaryForMe;
}

const getMonthlySummaryForMeAction = (filter: MonthlyUserSummaryFilter): Promise<SingleUserMonthlySummaryDto> => userClient.getMonthlySummaryForMe(filter);

export function useGetMonthlySummaryForUser(): (filter: MonthlyUserSummaryFilter) => Promise<SingleUserMonthlySummaryDto> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getMonthlySummaryForUser(filter: MonthlyUserSummaryFilter): Promise<SingleUserMonthlySummaryDto> {
        const bindedAction = getMonthlySummaryForUserAction.bind(null, filter);
        const getMonthlySummaryResponse = await tryCatchAction(bindedAction);
        return getMonthlySummaryResponse.success ? getMonthlySummaryResponse.response : [];
    }
    return getMonthlySummaryForUser;
}

const getMonthlySummaryForUserAction = (filter: MonthlyUserSummaryFilter): Promise<SingleUserMonthlySummaryDto> => userClient.getMonthlySummaryForUser(filter);

export function useCreateWorkOrders(): (workOrder: CreateWorkOrderRequest) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleCreateWorkOrder(workOrder: CreateWorkOrderRequest): Promise<boolean> {
        const bindedAction = createWorkOrderAction.bind(null, workOrder);
        const createWorkOrderResponse = await tryCatchAction(bindedAction);
        return createWorkOrderResponse.success;
    }
    return handleCreateWorkOrder;
}

const updateUserWorkOrder = (user: BasicUpdateUserDto): Promise<ResponseMessage> => {
    return userClient.update(user);
}

export function useUpdateUserWorkOrder(showMessage: boolean = true): (user: BasicUpdateUserDto) => Promise<BaseResponseModel>{
    const tryCatchAction = useTryCatchJsonByAction(showMessage);
    async function handleUpdateUserWorkOrder(user: BasicUpdateUserDto): Promise<BaseResponseModel>{
        const bindedAction = updateUserWorkOrder.bind(null, user);
        const updateUserWorkOrderResponse = await tryCatchAction(bindedAction);
        return { success: updateUserWorkOrderResponse.success, errors: updateUserWorkOrderResponse.errors }
    }
    return handleUpdateUserWorkOrder;
}
export function useGetAll(): () => Promise<UserDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getGetAllByStatus(): Promise<UserDto[]> {
        const bindedAction = getGetAllAction.bind(null);
        const getEmployeesResponse = await tryCatchAction(bindedAction);
        return getEmployeesResponse.success ? getEmployeesResponse.response : [];
    }
    return getGetAllByStatus;
}

const getGetAllAction = (): Promise<UserDto[]> => userClient.getAll();

export function useGetEmployeesByWorkOrderId(): (workOrderId: string) => Promise<UserDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getGetEmployeesByWorkOrderId(workOrderId: string): Promise<UserDto[]> {
        const bindedAction = getGetEmployeesByWorkOrderIdAction.bind(null, workOrderId);
        const getEmployeesResponse = await tryCatchAction(bindedAction);
        return getEmployeesResponse.success ? getEmployeesResponse.response : [];
    }
    return getGetEmployeesByWorkOrderId;
}

const getGetEmployeesByWorkOrderIdAction = (workOrderId: string): Promise<UserDto[]> => userClient.getByWorkOrderId(workOrderId);


const getAllEmployeesAction = (): Promise<UserDto[]> => userClient.getAll();

export function useGetAllEmployeesAction(): () => Promise<UserDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getAllEmployees(): Promise<UserDto[]> {
        const bindedAction = getAllEmployeesAction.bind(null);
        const getAllEmployeesResponse = await tryCatchAction(bindedAction);
        return getAllEmployeesResponse.success ? getAllEmployeesResponse.response : [];
    }
    return getAllEmployees;
}


export function useGetUsersWithWorkOrdersForCalendar(): (userIds: string[], isLocationTypeNeeded: boolean) => Promise<CalendarUserDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getUsersForCalendar(userIds: string[], isLocationTypeNeeded: boolean): Promise<CalendarUserDto[]> {
        const bindedAction = getUsersWithWorkOrdersForCalendarAction.bind(null, userIds, isLocationTypeNeeded);
        const getUsersResponse = await tryCatchAction(bindedAction);
        return getUsersResponse.success ? getUsersResponse.response : [];
    }
    return getUsersForCalendar;
}

const getUsersWithWorkOrdersForCalendarAction = (userIds: string[], isLocationTypeNeeded: boolean): Promise<CalendarUserDto[]> => userClient.getWithWorkOrdersForCalendar(userIds, isLocationTypeNeeded);

export function useGetUsersWithWorkOrdersForCalendarFromExternalView(): (userIds: string[], isLocationTypeNeeded: boolean) => Promise<CalendarUserDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getUsersForCalendarFromExternalView(userIds: string[], isLocationTypeNeeded: boolean): Promise<CalendarUserDto[]> {
        const bindedAction = getUsersWithWorkOrdersForCalendarFromExternalViewAction.bind(null, userIds, isLocationTypeNeeded);
        const getUsersResponse = await tryCatchAction(bindedAction);
        return getUsersResponse.success ? getUsersResponse.response : [];
    }
    return getUsersForCalendarFromExternalView;
}

const getUsersWithWorkOrdersForCalendarFromExternalViewAction = (userIds: string[], isLocationTypeNeeded: boolean): Promise<CalendarUserDto[]> => userClient.getWithWorkOrdersForCalendarFromExternalView(userIds, isLocationTypeNeeded);

export function useGetUsersWithReservedDates(): (userIds: string[]) => Promise<UserWithReservedDateRangesDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getUsersWithReservedDates(userIds: string[]): Promise<UserWithReservedDateRangesDto[]> {
        const bindedAction = getUsersWithReservedDatesAction.bind(null, userIds);
        const getUsersResponse = await tryCatchAction(bindedAction);
        return getUsersResponse.success ? getUsersResponse.response : [];
    }
    return getUsersWithReservedDates;
}

const getUsersWithReservedDatesAction = (userIds: string[]): Promise<UserWithReservedDateRangesDto[]> => userClient.getWithReservedDates(userIds, true);

export function useGetUsersWithReservedDatesFromExternalView(): (userIds: string[]) => Promise<UserWithReservedDateRangesDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getUsersWithReservedDatesFromExternalView(userIds: string[]): Promise<UserWithReservedDateRangesDto[]> {
        const bindedAction = getUsersWithReservedDatesFromExternalViewAction.bind(null, userIds);
        const getUsersResponse = await tryCatchAction(bindedAction);
        return getUsersResponse.success ? getUsersResponse.response : [];
    }
    return getUsersWithReservedDatesFromExternalView;
}

const getUsersWithReservedDatesFromExternalViewAction = (userIds: string[]): Promise<UserWithReservedDateRangesDto[]> => userClient.getWithReservedDatesFromExternalView(userIds, true);

export function useGetWorkOrders(): () => Promise<WorkOrderDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getWorkOrders(): Promise<WorkOrderDto[]> {
        const bindedAction = getWorkOrdersAction.bind(null);
        const getWorkOrdersResponse = await tryCatchAction(bindedAction);
        return getWorkOrdersResponse.success ? getWorkOrdersResponse.response : [];
    }
    return getWorkOrders;
}

const getWorkOrdersAction = (): Promise<WorkOrderDto[]> => workOrderClient.getAll();

export function useGetMyWorkOrders(): () => Promise<WorkOrderDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getMyWorkOrders(): Promise<WorkOrderDto[]> {
        const bindedAction = getMyWorkOrdersAction.bind(null);
        const getMyWorkOrdersResponse = await tryCatchAction(bindedAction);
        return getMyWorkOrdersResponse.success ? getMyWorkOrdersResponse.response : [];
    }
    return getMyWorkOrders;
}

const getMyWorkOrdersAction = (): Promise<WorkOrderDto[]> => workOrderClient.getMyWorkOrders();

export function useGetWorkOrdersForCalendar(): (userIds: string[]) => Promise<CalendarWorkOrderDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getWorkOrdersForCalendar(userIds: string[]): Promise<CalendarWorkOrderDto[]> {
        const bindedAction = getWorkOrdersForCalendarAction.bind(null, userIds);
        const getWorkOrdersResponse = await tryCatchAction(bindedAction);
        return getWorkOrdersResponse.success ? getWorkOrdersResponse.response : [];
    }
    return getWorkOrdersForCalendar;
}

const getWorkOrdersForCalendarAction = (userIds: string[]): Promise<CalendarWorkOrderDto[]> => workOrderClient.getCalendarWorkOrders(userIds, true);

export function useGetAllReservations(): () => Promise<ReservationEventDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getAllReservations(): Promise<ReservationEventDto[]> {
        const bindedAction = getAllReservationsAction.bind(null);
        const reservationsResult = await tryCatchAction(bindedAction);
        return reservationsResult.success ? reservationsResult.response : [];
    }
    return getAllReservations;
}

const getAllReservationsAction = (): Promise<ReservationEventDto[]> => reservationClient.getAll();

export function useGetRoomsByStatus(): (status: StatusEnum) => Promise<RoomDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getRoomsByStatus(status: StatusEnum): Promise<RoomDto[]> {
        const bindedAction = getRoomsByStatusAction.bind(null, status);
        const roomsResult = await tryCatchAction(bindedAction);
        return roomsResult.success ? roomsResult.response : [];
    }
    return getRoomsByStatus;
}

const getRoomsByStatusAction = (status: StatusEnum): Promise<RoomDto[]> => roomClient.getByStatus(status);

export function useGetRoomsByIds(): (ids: string[]) => Promise<RoomDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getRoomsByIds(ids: string[]): Promise<RoomDto[]> {
        const bindedAction = getRoomsByIdsAction.bind(null, ids);
        const roomsResult = await tryCatchAction(bindedAction);
        return roomsResult.success ? roomsResult.response : [];
    }
    return getRoomsByIds;
}

const getRoomsByIdsAction = (ids: string[]): Promise<RoomDto[]> => roomClient.getAllByIds(ids);

export function useGetReservationsByRoom(): (id: string) => Promise<ReservationEventDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getReservationsByRoom(id: string): Promise<ReservationEventDto[]> {
        const bindedAction = getReservationsByRoomAction.bind(null, id);
        const reservationsResult = await tryCatchAction(bindedAction);
        return reservationsResult.success ? reservationsResult.response : [];
    }
    return getReservationsByRoom;
}

const getReservationsByRoomAction = (id: string): Promise<ReservationEventDto[]> => reservationClient.getByRoom(id);

export function useGetReservationsByRoomFromExternalView(): (id: string) => Promise<ReservationEventDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getReservationsByRoom(id: string): Promise<ReservationEventDto[]> {
        const bindedAction = getReservationsByRoomFromExternalViewAction.bind(null, id);
        const reservationsResult = await tryCatchAction(bindedAction);
        return reservationsResult.success ? reservationsResult.response : [];
    }
    return getReservationsByRoom;
}

const getReservationsByRoomFromExternalViewAction = (id: string): Promise<ReservationEventDto[]> => reservationClient.getByRoomFromExternalView(id);

export function useGetNextReservations(): (numberOfReservations: number) => Promise<ReservationEventDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getNextReservations(numberOfReservations: number): Promise<ReservationEventDto[]> {
        const bindedAction = getNextReservationsAction.bind(null, numberOfReservations);
        const reservationsResult = await tryCatchAction(bindedAction);
        return reservationsResult.success ? reservationsResult.response : [];
    }
    return getNextReservations;
}

const getNextReservationsAction = (numberOfReservations: number): Promise<ReservationEventDto[]> => reservationClient.getNext(numberOfReservations);

export function useCreateReservation(): (reservation: CreateReservationRequest) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleCreateReservation(reservation: CreateReservationRequest): Promise<boolean> {
        const bindedAction = createReservationAction.bind(null, reservation);
        const response = await tryCatchAction(bindedAction);
        return response.success;
    }
    return handleCreateReservation;
}

const createReservationAction = (reservation: CreateReservationRequest): Promise<ResponseMessage> => reservationClient.create(reservation);

export function useCreateReservationFromExternalView(): (reservation: CreateReservationRequest) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleCreateReservation(reservation: CreateReservationRequest): Promise<boolean> {
        const bindedAction = createReservationFromExternalViewAction.bind(null, reservation);
        const response = await tryCatchAction(bindedAction);
        return response.success;
    }
    return handleCreateReservation;
}

const createReservationFromExternalViewAction = (reservation: CreateReservationRequest): Promise<ResponseMessage> => reservationClient.createFromExternalView(reservation);

export function useUpdateReservation(): (reservation: UpdateReservationRequest) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleUpdateReservation(reservation: UpdateReservationRequest): Promise<boolean> {
        const bindedAction = updateReservationAction.bind(null, reservation);
        const response = await tryCatchAction(bindedAction);
        return response.success;
    }
    return handleUpdateReservation;
}

const updateReservationAction = (reservation: UpdateReservationRequest): Promise<ResponseMessage> => reservationClient.update(reservation);

export function useUpdateReservationFromExternalView(): (reservation: UpdateReservationRequest) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleUpdateReservation(reservation: UpdateReservationRequest): Promise<boolean> {
        const bindedAction = updateReservationFromExternalViewAction.bind(null, reservation);
        const response = await tryCatchAction(bindedAction);
        return response.success;
    }
    return handleUpdateReservation;
}

const updateReservationFromExternalViewAction = (reservation: UpdateReservationRequest): Promise<ResponseMessage> => reservationClient.updateFromExternalView(reservation);

export function useDeleteReservation(): (id: string) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleDeleteReservation(id: string): Promise<boolean> {
        const bindedAction = deleteReservationAction.bind(null, id);
        const response = await tryCatchAction(bindedAction);
        return response.success;
    }
    return handleDeleteReservation;
}

const deleteReservationAction = (id: string): Promise<ResponseMessage> => reservationClient.remove(id);

export function useDeleteReservationFromExternalView(): (id: string) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleDeleteReservation(id: string): Promise<boolean> {
        const bindedAction = deleteReservationFromExternalViewAction.bind(null, id);
        const response = await tryCatchAction(bindedAction);
        return response.success;
    }
    return handleDeleteReservation;
}

const deleteReservationFromExternalViewAction = (id: string): Promise<ResponseMessage> => reservationClient.removeFromExternalView(id);

export function useGetWorkOrder(): (id: string) => Promise<WorkOrderDto> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getWorkOrder(id: string): Promise<WorkOrderDto> {
        const bindedAction = getWorkOrderAction.bind(null, id);
        const getDeactivatedPositions = await tryCatchAction(bindedAction);
        return getDeactivatedPositions.success ? getDeactivatedPositions.response : [];
    }
    return getWorkOrder;
}

const getWorkOrderAction = (id: string): Promise<WorkOrderDto> => workOrderClient.get(id);

export function useCreateWorkOrder(): (workOrder: CreateWorkOrderRequest) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleCreateWorkOrder(workOrder: CreateWorkOrderRequest): Promise<boolean> {
        const bindedAction = createWorkOrderAction.bind(null, workOrder);
        const createWorkOrderResponse = await tryCatchAction(bindedAction);
        return createWorkOrderResponse.success;
    }
    return handleCreateWorkOrder;
}

const createWorkOrderAction = (workOrder: CreateWorkOrderRequest): Promise<ResponseMessage> => workOrderClient.create(workOrder);

export function useUpdateWorkOrder(): (workOrder: UpdateWorkOrderRequest) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleUpdateWorkOrder(workOrder: UpdateWorkOrderRequest): Promise<boolean> {
        const bindedAction = updateWorkOrderAction.bind(null, workOrder);
        const updateWorkOrderResponse = await tryCatchAction(bindedAction);
        return updateWorkOrderResponse.success;
    }
    return handleUpdateWorkOrder;
}

const updateWorkOrderAction = (workOrder: UpdateWorkOrderRequest): Promise<ResponseMessage> => workOrderClient.update(workOrder);

export function useGetTypeOfWork(): (id: string) => Promise<TypeOfWorkDto> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getTypeOfWork(id: string): Promise<TypeOfWorkDto> {
        const bindedAction = getTypeOfWorkAction.bind(null, id);
        const getTypeOfWorkResponse = await tryCatchAction(bindedAction);
        return getTypeOfWorkResponse.success ? getTypeOfWorkResponse.response : [];
    }
    return getTypeOfWork;
}

const getTypeOfWorkAction = (id: string): Promise<TypeOfWorkDto> => typesOfWorkClient.get(id);

export function useGetTypesOfWork(): () => Promise<TypeOfWorkDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleGetTypesOfWork(): Promise<TypeOfWorkDto[]> {
        const bindedAction = getGetTypesOfWorkAction.bind(null);
        const getTypesOfWorkResponse = await tryCatchAction(bindedAction);
        return getTypesOfWorkResponse.success ? getTypesOfWorkResponse.response : [];
    }
    return handleGetTypesOfWork;
}

const getGetTypesOfWorkAction = (): Promise<TypeOfWorkDto[]> => typesOfWorkClient.getAll();

export function useGetTypesOfWorkByStatus(): (status: StatusEnum) => Promise<TypeOfWorkDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleGetTypesOfWorkByStatus(status: StatusEnum): Promise<TypeOfWorkDto[]> {
        const bindedAction = getGetTypesOfWorkByStatusAction.bind(null, status);
        const getTypesOfWorkResponse = await tryCatchAction(bindedAction);
        return getTypesOfWorkResponse.success ? getTypesOfWorkResponse.response : [];
    }
    return handleGetTypesOfWorkByStatus;
}

const getGetTypesOfWorkByStatusAction = (status: StatusEnum): Promise<TypeOfWorkDto[]> => typesOfWorkClient.getByStatus(status);

export function useCreateTypeOfWork(): (typeOfWork: CreateTypeOfWorkRequest) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleCreateTypeOfWork(typeOfWork: CreateTypeOfWorkRequest): Promise<boolean> {
        const bindedAction = createTypeOfWorkAction.bind(null, typeOfWork);
        const createTypeOFworkResponse = await tryCatchAction(bindedAction);
        return createTypeOFworkResponse.success;
    }
    return handleCreateTypeOfWork;
}

const createTypeOfWorkAction = (typeOfWork: CreateTypeOfWorkRequest): Promise<ResponseMessage> => typesOfWorkClient.create(typeOfWork);

export function useUpdateTypeOfWork(): (typeOfWork: TypeOfWorkDto) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleUpdateTypeOfWork(typeOfWork: TypeOfWorkDto): Promise<boolean> {
        const bindedAction = updateTypeOfWorkAction.bind(null, typeOfWork);
        const updateTypeOFworkResponse = await tryCatchAction(bindedAction);
        return updateTypeOFworkResponse.success;
    }
    return handleUpdateTypeOfWork;
}

const updateTypeOfWorkAction = (typeOfWork: TypeOfWorkDto): Promise<ResponseMessage> => typesOfWorkClient.update(typeOfWork);

export function useDeactivateTypeOfWork(): (typeOfWorkId: UpdateStatusTypeOfWorkRequest) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleDeactivateTypeOfWork(typeOfWorkId: UpdateStatusTypeOfWorkRequest): Promise<boolean> {
        const bindedAction = deleteTypeOfWorkAction.bind(null, typeOfWorkId);
        const deactivateResponse = await tryCatchAction(bindedAction);
        return deactivateResponse.success;
    }
    return handleDeactivateTypeOfWork;
}

const deleteTypeOfWorkAction = (typeOfWorkId: UpdateStatusTypeOfWorkRequest): Promise<ResponseMessage> => typesOfWorkClient.deactivate(typeOfWorkId);

export function useActivateTypeOfWork(): (typeOfWorkId: UpdateStatusTypeOfWorkRequest) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleActivateTypeOfWork(typeOfWorkId: UpdateStatusTypeOfWorkRequest): Promise<boolean> {
        const bindedAction = activateTypeOfWorkAction.bind(null, typeOfWorkId);
        const activateResponse = await tryCatchAction(bindedAction);
        return activateResponse.success;
    }
    return handleActivateTypeOfWork;
}

const activateTypeOfWorkAction = (typeOfWorkId: UpdateStatusTypeOfWorkRequest): Promise<ResponseMessage> => typesOfWorkClient.activate(typeOfWorkId);

export function useGetMeetingRoomsByStatus(): (status: StatusEnum) => Promise<RoomDto[]> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function getMeetingRooms(status: StatusEnum): Promise<RoomDto[]> {
        const bindedAction = getMeetingRoomsAction.bind(null, status);
        const getMeetingRoomsResponse = await tryCatchAction(bindedAction);
        return getMeetingRoomsResponse.success ? getMeetingRoomsResponse.response : [];
    }
    return getMeetingRooms;
}

const getMeetingRoomsAction = (status: StatusEnum): Promise<RoomDto[]> => roomClient.getByStatus(status);

export function useCreateMeetingRoom(): (meetingRoom: CreateRoomRequest) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleCreateMeetingRoom(meetingRoom: CreateRoomRequest): Promise<boolean> {
        const bindedAction = createMeetingRoomAction.bind(null, meetingRoom);
        const createMeetingRoomResponse = await tryCatchAction(bindedAction);
        return createMeetingRoomResponse.success;
    }
    return handleCreateMeetingRoom;
}

const createMeetingRoomAction = (meetingRoom: CreateRoomRequest): Promise<ResponseMessage> => roomClient.create(meetingRoom);

export function useUpdateMeetingRoom(): (meetingRoom: RoomDto) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleUpdateMeetingRoom(meetingRoom: RoomDto): Promise<boolean> {
        const bindedAction = updateMeetingRoomAction.bind(null, meetingRoom);
        const updateMeetingRoomResponse = await tryCatchAction(bindedAction);
        return updateMeetingRoomResponse.success;
    }
    return handleUpdateMeetingRoom;
}

const updateMeetingRoomAction = (meetingRoom: RoomDto): Promise<ResponseMessage> => roomClient.update(meetingRoom);

export function useActivateMeetingRoom(): (roomId: UpdateStatusRoomRequest) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleActivateTypeOfWork(roomId: UpdateStatusRoomRequest): Promise<boolean> {
        const bindedAction = activateMeetingRoomAction.bind(null, roomId);
        const activateResponse = await tryCatchAction(bindedAction);
        return activateResponse.success;
    }
    return handleActivateTypeOfWork;
}
const activateMeetingRoomAction = (roomId: UpdateStatusRoomRequest): Promise<ResponseMessage> => roomClient.activate(roomId);

export function useDeactivateMeetingRoom(): (roomId: UpdateStatusRoomRequest) => Promise<boolean> {
    const tryCatchAction = useTryCatchJsonByAction();
    async function handleDeactivateTypeOfWork(roomId: UpdateStatusRoomRequest): Promise<boolean> {
        const bindedAction = deactivateMeetingRoomAction.bind(null, roomId);
        const deactivateResponse = await tryCatchAction(bindedAction);
        return deactivateResponse.success;
    }
    return handleDeactivateTypeOfWork;
}

const deactivateMeetingRoomAction = (roomId: UpdateStatusRoomRequest): Promise<ResponseMessage> => roomClient.deactivate(roomId);