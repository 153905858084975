import { useGetDeactivatedPositionsAction, useGetPositions } from "../../API/position-actions";
import { PositionResponse } from "../../API/user-management-service";
import { IPositionTableProps } from "./IPositionTableProps";

export function useGetActivePositions(): () => Promise<IPositionTableProps[]> {
    const getPositions = useGetPositions();
    async function getActivePositions(): Promise<IPositionTableProps[]> {
        const activePositions: PositionResponse[] = await getPositions();
        const data = mapTablePositions(activePositions);
        return data ? data : [];
    }
    return getActivePositions;
}

export function useGetDeactivatedPositions(): () => Promise<IPositionTableProps[]> {
    const getDeactivatedPositionsAction = useGetDeactivatedPositionsAction();
    async function getDeactivatedPositions(): Promise<IPositionTableProps[]> {
        const deactivatedPositions: PositionResponse[] = await getDeactivatedPositionsAction();
        const data = mapTablePositions(deactivatedPositions);
        return data ? data : [];
    }
    return getDeactivatedPositions;
}

const mapTablePositions = (positions: IPositionTableProps[]): IPositionTableProps[] => {
    return positions?.map(position => {
        return { id: position.id, name: position.name } as IPositionTableProps;
    });
}