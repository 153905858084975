import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { TranslationKeyEnum } from "../../features/translations/TranslationKeyEnum";

const MAX_TITLE_LENGTH = 100;
const MIN_TITLE_LENGTH = 2;
const MAX_DESCRIPTION_LENGTH = 200;
const MIN_DESCRIPTION_LENGTH = 2;
export const MAX_BODY_LENGTH = 500;
const MIN_BODY_LENGTH = 2;

export function useArticleSchema() {
    const { t } = useTranslation();

    return Yup.object({
        title: Yup.string()
            .max(MAX_TITLE_LENGTH, t(TranslationKeyEnum.maximumInputLength, { fieldName: t(TranslationKeyEnum.articleText), maximumLength: MAX_TITLE_LENGTH }))
            .min(MIN_TITLE_LENGTH, t(TranslationKeyEnum.minimumInputLength, { fieldName: t(TranslationKeyEnum.articleText), minimumLength: MIN_TITLE_LENGTH }))
            .required(t(TranslationKeyEnum.required)),
        description: Yup.string()
            .max(MAX_DESCRIPTION_LENGTH, t(TranslationKeyEnum.maximumInputLength, { fieldName: t(TranslationKeyEnum.description), maximumLength: MAX_DESCRIPTION_LENGTH }))
            .min(MIN_DESCRIPTION_LENGTH, t(TranslationKeyEnum.minimumInputLength, { fieldName: t(TranslationKeyEnum.description), minimumLength: MIN_DESCRIPTION_LENGTH }))
            .required(t(TranslationKeyEnum.required)),
        body: Yup.string()
            .max(MAX_BODY_LENGTH, t(TranslationKeyEnum.maximumInputLength, { fieldName: t(TranslationKeyEnum.articleText), maximumLength: MAX_BODY_LENGTH }))
            .min(MIN_BODY_LENGTH, t(TranslationKeyEnum.minimumInputLength, { fieldName: t(TranslationKeyEnum.articleText), minimumLength: MIN_BODY_LENGTH }))
            .required(t(TranslationKeyEnum.required)),
    })
}